import React from 'react';
import styled from 'styled-components';

import emptystatesdrawings from '_img/drawings/index';
import { Empty } from 'antd';

// confident
// martini
// blushing
// shootingstar
// desertisland
// lighthouse
// paperplanet
// weightlifter

const EmptyStateDiv = styled.div`
    margin-top: 2em;
    text-align: center;
    display: ${props => props.$disable && 'none'};

    .ant-empty.ant-empty-normal {
        .ant-empty-image, .ant-empty-description {
            color: ${props => props.$fullOpacity ? props.theme.TextColor : 'inherit'};
        }
    }

    img {
        max-height: 80px;
        max-width: 80px;
    }
    p {
        font-size: 1.1em;
        margin-top: 1em;
        font-style: italic;
    }
`

const EmptyState = ({ disable, drawing, message, extra, fullOpacity, ...rest }) => {
    if (drawing) {
        return (
            <EmptyStateDiv $fullOpacity={fullOpacity} className="nw-empty-state" disable={disable} {...rest}>
                <img src={emptystatesdrawings[drawing]} alt={message} />
                <p>{message}</p>
                {extra
                    ?
                    <div style={{ paddingTop: "1em" }}>{extra}</div>
                    :
                    <></>
                }
            </EmptyStateDiv>
        )
    } else {
        return (
            <EmptyStateDiv $fullOpacity={fullOpacity} className="nw-empty-state" $disable={disable} {...rest}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={message || false} />
                {extra
                ?
                    <div style={{ paddingTop: "1em" }}>{extra}</div>
                :
                    <></>
                }
            </EmptyStateDiv>
        )
    }

}

export default EmptyState