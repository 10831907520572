import React, { useContext, useState } from "react";
import { Row, Col } from 'antd';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { getMoment } from "Libs/NwMoment";
import Axios from 'axios';

import {
    NWInput,
    NWSwitch,
    NWDatePicker
} from "Components/Gui/NwForm/NwFormItems";
import { NwSaveButton, NwCancelButton } from "Components/Gui/NwButton";
import NwDrawer from 'Components/Gui/NwDrawer';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { datePickerBeforeOnChange } from "Libs/NwMoment";
import { PackageContext } from "../PackageContext";

const PackageEditDrawer = ({ onClose }) => {
    const [touchForm, setTouchForm] = useState(false);

    const packageContextValue = useContext(PackageContext);
    const { groupedPackage, afterUpdatePackage} = packageContextValue;

    const initialValues = {
        Title: "",
        ProjectID: null,
        IsInteractive: true,
        ExpirationDate: null,
    };
    
    if (groupedPackage) {
        initialValues.Title = groupedPackage.Name;
        initialValues.ProjectID = groupedPackage.ProjectID;
        initialValues.IsInteractive = groupedPackage.isInteractive;
        initialValues.ExpirationDate = groupedPackage.ExpirationDate;
    }
    
    const savePackage = (values, setSubmitting) => {
        const updatedPack = {};
        updatedPack.ID = groupedPackage.ID;
        updatedPack.ExpirationDate = values.ExpirationDate;
        updatedPack.ProjectID = values.ProjectID;
        updatedPack.isInteractive = values.IsInteractive;
        updatedPack.Name = values.Title;
        setSubmitting(true);
        Axios.put(`/packages`, updatedPack)
        .then(() => {
            afterUpdatePackage() 
            setTouchForm(false);
            setSubmitting(false);
            onClose();           
        })
        .catch(error => {
            setSubmitting(false)
            //TODO: manage error on api call, may be by adding an ErrorMessage to the state and a condition to show it in render.
            console.log("ant : Update Package error => ", error);
        });
    };

    return (
        <NwDrawer 
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            title='Edit Package' 
            onClose={onClose}>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Title: Yup.string().required("Content is required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    savePackage(values, setSubmitting);
                }}
            >
                {({ values, isSubmitting, handleSubmit }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit} 
                        layout="vertical">
                        {/* <NwDivider>Package Info</NwDivider> */}
                        <Field
                            component={NWInput}
                            label="Package Title"
                            name="Title"
                            type="text"
                            required
                            value={values.Title} />
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWSwitch}
                                    label="Is Interactive"
                                    name="IsInteractive"
                                    defaultChecked={values.IsInteractive ? true : false}
                                    checked={Boolean(values.IsInteractive)} />
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWDatePicker}
                                    label="Expiration Date"
                                    name="ExpirationDate"
                                    format="MMM DD, YYYY"
                                    style={{ width: "100%" }}
                                    value={values.ExpirationDate ? getMoment(values.ExpirationDate) : null}
                                    manipulateValue={datePickerBeforeOnChange}
                                />
                            </Col>
                        </Row>
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    )
}

export default PackageEditDrawer;

