import React, { useState } from "react";
import { Input, Row, Col, Badge } from "antd";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import Axios from 'axios';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwDropDownMenu from "Components/Gui/NwDropDownMenu";
import WithCharCounter from "Components/Gui/WithCharCounter";
import { getUrlDocument, getFileTypeIcons } from "Libs/NwUtils";
import CreatedBy from 'Components/CreatedBy/CreatedBy';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import NwDrawer from 'Components/Gui/NwDrawer';
import NwDivider from "Components/Gui/NwDivider";

const { TextArea } = Input;
const TextAreaWithCharCounter = WithCharCounter(TextArea);

const DocumentCard = styled.div`
    border: ${props => props.theme.DefaultBorder};
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 8px;

    .content {
        width: calc(100% - 24px);
    }

    .description {
        font-size: .85rem;
        opacity: 0.7;
    }

    .title {
        width: 100%;    
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    .ant-badge-count {
        margin-left: 8px;
        background-color: ${props => props.theme.PrimaryColor};
    }

    .ant-divider {
        cursor: pointer;
    }
`;

const Container = styled(Row)`
    padding-top: 16px;
`;

const EventDocuments = ({ event, type, updateEvent, showDocumentsByDefault }) => {
    const [editingMode, setEditingMode] = useState(false);
    const [itemToModify, setItemToModify] = useState(null);
    const [showDocuments, setShowDocuments] = useState(showDocumentsByDefault ? true : false);
    //const Documents = event.Documents ? event.Documents : [];
    const docstorender = (type === 'eventGroup') ? event.GroupDocuments : event.Documents
    const [touchForm, setTouchForm] = useState(false);

    let initialValues = {
        Title: "",
        Notes: ""
    };

    const handleShowDocuments = () => {
        setShowDocuments(prevShow => !prevShow);
    };

    if (itemToModify) {
        const index = docstorender.findIndex(el => el.ID === itemToModify);
        if (index > -1) {
            const Title = docstorender[index].Title;
            const Notes = docstorender[index].Notes || "";
            initialValues = { Title, Notes };
        }
    }

    const editDocument = (title, notes, setStatus, setSubmitting) => {
        setSubmitting(true);
        //let index = -1;
        // if (itemToModify) {
        //     console.log("item to modify", itemToModify);
        //     console.log("Documents", Documents);
        //     index = Documents.findIndex(el => el.ID === itemToModify);
        //     console.log("index", index);
        // }
        // if (index > -1) {
        Axios.put(`/documents/${itemToModify}`, [
            { name: "Title", value: title, changed: true },
            { name: "Notes", value: notes, changed: true }
        ])
            .then(() => {
                setTouchForm(false);
                setItemToModify(null);
                setSubmitting(false);
                setEditingMode(false);
                updateEvent();
            })
            .catch(error => {
                //TODO: manage error on api call, may be by adding an ErrorMessage to the state and a condition to show it in render.
                console.log("errore", error);
            });
        //}
    };

    const deleteDocument = docId => {
        Axios.delete(`/documents/?ids=${docId}`)
            .then(() => {
                updateEvent();
            })
            .catch(error => {
                console.log("Errore", error);
                //TODO: manage error on api call, may be by adding an ErrorMessage to the state and a condition to show it in render.
            });
    };


    const onOpenDocument = document => {
        window.open(getUrlDocument(document), "_blank");
    };

    const onEditDocument = document => {
        setItemToModify(document.ID);
        setEditingMode(true);
    }

    const getDescription = (document) => {

        return (<>
            <CreatedBy object={document} short textonly />
            {/* 
            <br />
            <NwObjectCounter count={document.Comments.length} label="comments" onClick={onOpenDocumentComments} /> */}
        </>)
    }

    const actions = (document) => {
        return [
            <NwDropDownMenu
                key={`event-${document.ID}`}
                actions={[
                    { name: "", key: "edit", pinned: true, icon: light("pen"), action: (() => onEditDocument(document)) },
                    { name: "", key: "download", pinned: true, icon: light("cloud-download"), action: (() => onOpenDocument(document)) },
                    { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => deleteDocument(document.ID)) }
                ]}
            />
        ];
    }

    const renderDocumentForm = () => {
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Title: Yup.string().required("Content is required")
                })}
                onSubmit={({ Title, Notes }, { setStatus, setSubmitting }) => {
                    setStatus();
                    editDocument(Title, Notes, setStatus, setSubmitting);
                }}>
                {({ errors, status, touched, isSubmitting, values, setFieldValue, handleSubmit }) => (
                    <NwForm
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit}
                        layout="vertical">
                        <div className="form-group">
                            <label htmlFor="Title">Edit document</label>
                            <Input
                                autoComplete='off'
                                value={values.Title}
                                onChange={e => setFieldValue("Title", e.target.value)}
                                name="Title"
                                className={
                                    "form-control" +
                                    (errors.Title && touched.Title ? " is-invalid" : "")
                                }
                            />
                            <ErrorMessage
                                name="Title"
                                className="invalid-feedback"
                                render={msg => <div>{msg}</div>}
                            />
                            <br />
                        </div>
                        <br />
                        <div className="form-group">
                            <TextAreaWithCharCounter
                                autoComplete='off'
                                value={values.Notes}
                                onChange={e => setFieldValue("Notes", e.target.value)}
                                name="Notes"
                                className={
                                    "form-control" +
                                    (errors.Notes && touched.Notes ? " is-invalid" : "")
                                }
                                maxLength={2000}
                                autoSize={{ minRows: 5, maxRows: 10 }}
                            />
                            <ErrorMessage
                                name="Notes"
                                className="invalid-feedback"
                                render={msg => <div>{msg}</div>}
                            />
                        </div>
                        <br />
                        <div className="form-group">
                            <NwFormButtonsBar
                                left={
                                    <NwCancelButton
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            setTouchForm(false);
                                            setEditingMode(false);
                                            setItemToModify(null);
                                        }}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        htmlType="submit"
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                    />
                                }
                            />
                        </div>
                        {status && (
                            <div className={"alert alert-danger"}>
                                <React.Fragment>{status.msg}</React.Fragment>
                            </div>
                        )}
                    </NwForm>
                )}
            </Formik>
        )
    }

    return (
        <>
            {editingMode &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Edit Document'
                    onClose={() => setEditingMode(false)}>
                    {renderDocumentForm()}
                </NwDrawer>
            }
            {(docstorender && docstorender.length !== 0) &&
                <Wrapper>
                    <NwDivider dark onClick={handleShowDocuments} icon={showDocuments ? light('chevron-down') : light('chevron-right')}>
                        Documents<Badge count={docstorender.length} />
                    </NwDivider>
                    {showDocuments &&
                        <Container gutter={16}>
                            {docstorender.map(document => {
                                return (
                                    <Col span={12} key={`document-${document.ID}`}>
                                        <DocumentCard>
                                            <FlexContainer mg='0 0 8px' alignItems='center'>
                                                <NwIcon style={{ fontSize: 24 }} icon={getFileTypeIcons(document.Extension)} />
                                                <FlexContainer className='content' mg='0 8px' column alignItems='flex-start'>
                                                    <div className='title'>{document.Title}</div>
                                                    <div className='description'>{getDescription(document)}</div>
                                                </FlexContainer>
                                            </FlexContainer>
                                            <FlexContainer justifyContent='flex-end'>
                                                {actions(document)}
                                            </FlexContainer>
                                        </DocumentCard>
                                    </Col>
                                )
                            })}
                        </Container>
                    }
                </Wrapper>
            }
        </>
    );
};

export default EventDocuments;
