import React, { useContext } from "react";
import NwSelect from 'Components/Gui/NwForm/CustomSelect';
import { ListContext } from "Contexts/ListContext";

const PaymentTermSelector = ({value, onChange, onBlur, allowClear}) => {
    const { cachedList } = useContext(ListContext);
    
    const getTermLabel = (term) => {
        let stringTerms = term.Code;
        if (term.Name !== term.Code) {
            stringTerms = `${stringTerms} - ${term.Name}`;
        }
        return stringTerms;
    }
    
    const options = cachedList.paymentTerms.items.filter(pt => !pt.Disabled).map(item => ({
        label: getTermLabel(item), value: item.Code, key: item.Code
    }))
    //const defaultTerm = cachedList.paymentTerms.items.find(pt => pt.IsDefault);
    //const defaultValue = defaultTerm ? defaultTerm.Code : null;
    
    return (
        <NwSelect
            width="100%"
            mode="single"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            options={options}
            allowClear={allowClear}
            placeholder="Select Payment Term"
            getPopupContainer={triggerNode => triggerNode.parentNode}
        />
    );
}
export default PaymentTermSelector;