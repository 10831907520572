import React, { useState, useEffect, useCallback, useContext } from "react";
import styled from "styled-components";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Table, Checkbox, Typography } from "antd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import TransactionDetailDrawer from 'Components/Transactions/TransactionsDrawer/TransactionDetailDrawer';

import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import { NwButton, NwLinkButton } from "Components/Gui/NwButton";
import NwIcon from "Components/Gui/NwIcon";
import AddManualDrawer from "Components/Invoices/InvoicePage/Drawers/AddManualDrawer";
import GroupEditDrawer from "Components/Invoices/InvoicePage/Drawers/GroupEditDrawer";
import GroupDrawer from "Components/Invoices/InvoicePage/Drawers/GroupDrawer";
import { useSortRows, useUnGroupRows, useDeleteRow } from "Hooks/Invoices/UseInvoices";
import Money from "Components/Gui/Money";
import { ListContext } from "Contexts/ListContext";
import { showDateNoTime } from "Libs/NwMoment";
import useConfigurations from "Hooks/UseConfigurations";
import ChangeAccountDrawer from "Components/Invoices/InvoicePage/Drawers/ChangeAccountCodeDrawer";


const Text = Typography.Text;

const Wrapper = styled.div`
    .invoice-lines {
        /* height: 100%; */
        margin: .25rem 0 0 0;

        .statements-table {
            margin-bottom: auto;
            tr.drop-over-downward td {
                border-bottom: 2px dashed ${props => props.theme.PrimaryColor};
            }
            tr.drop-over-upward td {
                border-top: 2px dashed ${props => props.theme.PrimaryColor};
            }

            .ant-table {
                .ant-table-container {
                    .ant-table-content {
                        .ant-table-tbody {
                            .ant-table-cell {
                                box-sizing: border-box;
                                vertical-align: top;
                                padding: .75rem 1rem .5rem 1rem;

                                &.sort-rows-cell {
                                    width: 50px;
                                }

                                &.model-name-cell {
                                    .model-name-cell-wrapper {
                                        display: flex;
                                        flex-direction: row;
                                        
                                        .check-box {
                                            min-width: 2.25rem;
                                        }
                                        
                                        .model-name-and-family {
                                            display: block;
                                            flex: 1 1 0;
                                            small {
                                                text-transform: uppercase;
                                                display: block;
                                            }
                                            p {
                                                margin-bottom: .25rem;
                                            }
                                        }
                                    }
                                }

                                &.row-description-cell {
                                    .row-description-cell-wrapper {
                                        min-height: 5rem;
                                        small {
                                            text-transform: uppercase;
                                            display: block;
                                        }
                                        p {
                                            margin-bottom: .25rem;
                                        }
                                    }
                                }

                                .invoice-row-amount {
                                    text-align: right;
                                    font-weight: bold;
                                    padding-bottom: .5rem;
                                }
                                .invoice-row-taxes {
                                    .invoice-row-tax {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: flex-end;
                                        padding-bottom: .25rem;
                                        small {
                                            display: block;
                                            font-size: ${props => props.theme.FontSizeXSm};
                                            color: ${props => props.theme.TextColorMuted};
                                            line-height: .8em;
                                        }
                                        div {
                                            display: flex;
                                            flex-direction: row;
                                            justify-content: center;
                                            align-items: flex-end;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const InvoicePageRows = ({ invoiceData, onTransactionsUpdated, locked }) => {
    const [showGroupDrawer, setShowGroupDrawer] = useState(false);
    const [showManualDrawer, setShowManualDrawer] = useState(false);
    const [showTransactionDrawer, setShowTransactionDrawer] = useState(false);
    const [showEditGroupDrawer, setShowEditGroupDrawer] = useState(false);
    const [showChangeAccountCodeDrawer, setShowChangeAccountCodeDrawer] = useState(null);

    const configurations = useConfigurations();

    const { GetFamilyName } = useContext(ListContext);

    const [transaction, setTransaction] = useState();
    const [dataSource, setDataSource] = useState([]);

    const { mutateAsync: onSortRows } = useSortRows();
    const { mutateAsync: onUnGroupRows } = useUnGroupRows();
    const { mutateAsync: onDeleteRow } = useDeleteRow();

    const accounts = [];
    const schemas = [];
    if (configurations && configurations.COA) {
        const COAjs = JSON.parse(configurations.COA);
        if (COAjs.accounts && COAjs.accounts.length > 0) {
            accounts.push(...COAjs.accounts);
        }
        if (COAjs.schemas && COAjs.schemas.length > 0) {
            schemas.push(...COAjs.schemas);
        }
    }
    const enableCOA = accounts.length > 0;

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            onSortEnd(dragIndex, hoverIndex);
        },
        [dataSource],
    );

    useEffect(() => {
        if (invoiceData) {
            updateDataSource(invoiceData);
        }
    }, [invoiceData]);

    const updateDataSource = (invoiceData) => {
        const usedIDs = [];
        const _dataSource = invoiceData.Rows.map((item, index) => {
            const statement = item.Statement;
            if (!statement) return {
                key: index,
                index: item.ID,
                Guid: item.GroupGuid,
                Text: item.Text,
                Amount: "",
                Taxes: [],
                checked: false,
                disabled: true,
                RowIds: [{ ID: item.ID, Type: "Text" }],
                Type: "Text",
            }
            //const amount = Math.abs(statement.AmountDef ? statement.AmountDef : statement.Amount);
            const amount = statement.AmountDef ? statement.AmountDef : statement.Amount
            let taxes = [];
            const {
                VatAmount, VatCode, VatDef, VatPerc,
                TaxAmount, TaxCode, TaxDef, TaxPerc,
                TaxAmount2, TaxCode2, TaxDef2, TaxPerc2
            } = statement;
            if (statement.TaxPerc)
                //taxes.push(`${TaxCode} ${TaxPerc}% (${Dinero({ amount: Math.round(Math.abs(TaxAmount ? TaxAmount : TaxDef) * 100), currency: invoiceData.Currency }).toFormat(statementFormat)})`);
                taxes.push(
                    {
                        Type: "Tax",
                        Amount: TaxAmount ? TaxAmount : TaxDef,
                        Code: TaxCode,
                        Perc: TaxPerc
                    }
                )
            if (statement.TaxPerc2)
                //taxes.push(`${TaxCode2} ${TaxPerc2}% (${Dinero({ amount: Math.round(Math.abs(TaxAmount2 ? TaxAmount2 : TaxDef2) * 100), currency: invoiceData.Currency }).toFormat(statementFormat)})`);
                taxes.push(
                    {
                        Type: "Tax",
                        Amount: TaxAmount2 ? TaxAmount2 : TaxDef2,
                        Code: TaxCode2,
                        Perc: TaxPerc2
                    }
                )
            if (statement.VatPerc)
                //taxes.push(`VAT ${VatCode} ${VatPerc}% (${Dinero({ amount: Math.round(Math.abs(VatAmount ? VatAmount : VatDef) * 100), currency: invoiceData.Currency }).toFormat(statementFormat)})`);
                taxes.push(
                    {
                        Type: "Vat",
                        Amount: VatAmount ? VatAmount : VatDef,
                        Code: VatCode,
                        Perc: VatPerc
                    }
                )
            return {
                key: index,
                index: item.ID,
                Guid: item.GroupGuid,
                Description: statement.Description,
                Text: item.Text,
                Amount: amount,
                Taxes: taxes,
                checked: false,
                disabled: false,
                RowIds: [{ ID: item.ID, Type: "Statement" }],
                Type: "Statement",
                ModelID: statement.Transaction ? statement.Transaction.ModelID : null,
                ModelFullName: (statement.Transaction && statement.Transaction.ModelID) ? (statement.Transaction.ModelName + ' ' + statement.Transaction.ModelSurname) : null,
                Transaction: statement.Transaction,
                isCommission: statement.isCommission,
                isClientAdvance: statement.isClientAdvance,
                RefDate: statement.RefDate,
                AccountCode: statement.AccountCode,
            };
        });
        console.log("dataSource", _dataSource);
        const _groupedSource = _dataSource.map((row) => {
            if (!row.Guid) return row;
            if (usedIDs.filter(guid => guid === row.Guid).length === 0) {
                usedIDs.push(row.Guid);
                const group = _dataSource.filter(el => el.Guid === row.Guid);
                return {
                    ...row,
                    Amount: group.reduce((prev, cur) => prev + cur.Amount, 0),
                    disabled: true,
                    Text: row.Text,
                    RowIds: group.map(row => ({
                        ID: row.index,
                        Type: row.Type
                    })),
                    ModelID: null,
                    ModelFullName: null
                }
            }
            return null;
        }).filter(el => el !== null);
        console.log("groupedSource", _groupedSource);
        setDataSource([..._groupedSource]);
    }

    const onShowTransactionDrawer = (record) => {
        setTransaction(record.Transaction);
        setShowTransactionDrawer(true);
    }

    const onCloseTransactionDrawer = () => {
        setShowTransactionDrawer(false);
        setTransaction();
    }

    const handleTransactionCreatedOrUpdated = (ProjectID) => {
        onTransactionsUpdated(ProjectID)
    }

    const onSortEnd = async (oldIndex, newIndex) => {
        if (oldIndex !== newIndex) {
            let _dataSource = [...dataSource];
            if (oldIndex < newIndex) {
                _dataSource.splice(newIndex + 1, 0, dataSource[oldIndex]);
                _dataSource.splice(oldIndex, 1);
            } else {
                _dataSource.splice(newIndex, 0, dataSource[oldIndex]);
                _dataSource.splice(oldIndex + 1, 1);
            }
            const sortedRows = [];
            _dataSource.map((item) => item.RowIds.map(item => sortedRows.push({
                RowId: item.ID,
                RowType: item.Type
            })))
            try {
                await onSortRows({
                    ID: invoiceData.ID,
                    data: sortedRows
                })
                // setDataSource([..._dataSource]);
            } catch (e) {

            } finally {

            }
        }
    }

    const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
        const type = 'row';
        const dropRef = React.useRef(null)
        const dragRef = React.useRef(null)
        const [{ isOver, dropClassName }, drop] = useDrop({
            accept: type,
            collect: monitor => {
                const { index: dragIndex } = monitor.getItem() || {};
                if (dragIndex === index) {
                    return {};
                }
                return {
                    isOver: monitor.isOver(),
                    dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
                };
            },
            drop: item => {
                moveRow(item.index, index);
            },
        });
        const [, drag, preview] = useDrag({
            type,
            item: { index, type: type },
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
        });
        preview(drop(dropRef))
        drag(dragRef)

        if (!restProps.hasOwnProperty("data-row-key")) {
            return (
                <tr
                    key={index}
                    ref={dropRef}
                    className={`${className}${isOver ? dropClassName : ''}`}
                    {...restProps}
                />
            );
        } else {
            return (
                <tr
                    key={index}
                    ref={dropRef}
                    className={`${className}${isOver ? dropClassName : ''}`}
                    data-row-key={restProps["data-row-key"]}
                >
                    {restProps.children}
                    {!locked &&
                        <td className="ant-table-cell sort-rows-cell" ref={dragRef} style={{ cursor: "move" }}><NwIcon icon={light("bars")} /></td>
                    }
                </tr>
            );
        }
    };

    const onCheckChange = (record) => {
        let _dataSource = [...dataSource];
        for (let i = 0; i < _dataSource.length; i++)
            if (_dataSource[i].index === record.index) {
                _dataSource[i].checked = !_dataSource[i].checked;
                break;
            }
        setDataSource([..._dataSource]);
    }

    const onUnGroup = async (record) => {
        try {
            await onUnGroupRows({
                ID: invoiceData.ID,
                data: {
                    GroupGuid: record.Guid
                }
            })
        } catch (e) {

        } finally {

        }
    }

    const onDelete = async (record) => {
        try {
            await onDeleteRow({
                data: {
                    InvoiceID: invoiceData.ID,
                    RowID: record.index
                },
                type: record.Type
            });
        } catch (e) {

        } finally {

        }
    }

    const translateFamilyName = ((record) => {
        const familyCode = record.Transaction.Family
        const familyName = GetFamilyName(familyCode)
        switch (familyCode) {
            case "JobFee":
                if (record.isCommission) {
                    return "Agency Fee"
                }
                if (record.isClientAdvance) {
                    return "Client Advance"
                }
                return familyName;
            default:
                return familyName;
        }
    })

    const invertAmount = invoiceData.TypeName !== "Client";

    const changeAccountCode = (ID) => {
        setShowChangeAccountCodeDrawer(ID);
    }

    const renderStatements = () => {
        const columns = [
            {
                title: (
                    <div style={{ marginLeft: "2rem" }}>Description</div>
                ),
                dataIndex: 'Description',
                key: 'Description',
                className: "model-name-cell",
                width: 300,
                render: (_, record) => {
                    return (
                        <div className="model-name-cell-wrapper">
                            <div className="check-box">
                                <Checkbox disabled={record.disabled || locked} checked={record.checked} onChange={() => onCheckChange(record)} />
                            </div>
                            <div className="model-name-and-family">
                            {record.ModelFullName && (invoiceData.TypeName !== "Model") &&
                                <p><strong>{record.ModelFullName.toUpperCase()}</strong></p>
                            }
                            {record.Transaction && record.Transaction.Family &&
                                    <small>{translateFamilyName(record)}</small>
                            }
                            </div>
                        </div>
                    )
                }
            },
            {
                title: '',
                dataIndex: 'Details',
                key: 'Details',
                className: "row-description-cell",
                render: (_, record) => (
                    <div className="row-description-cell-wrapper">
                        {record.Transaction &&
                        <p>{showDateNoTime(record.RefDate)} - <strong>{record.Transaction.Title}</strong></p>
                        }
                        {record.Text
                            ?
                            <p><em>{record.Text} (<NwLinkButton disabled={locked} onClick={() => setShowManualDrawer(record)} label="change" />)</em></p>
                            :
                            <p><em style={{ opacity: .6 }}>No text</em> (<NwLinkButton disabled={locked} onClick={() => setShowManualDrawer(record)} label="change" />)</p>
                        }
                        {enableCOA &&
                            <Text 
                                code 
                                onClick={locked ? null : () => { changeAccountCode(record)}} 
                                style={{cursor: locked ? "default" : "pointer"}}
                            >
                                account #: {record.AccountCode ? record.AccountCode : 'n.d.'}
                            </Text>
                        }
                    </div>
                )
            },
            {
                title: '',
                width: 50,
                dataIndex: 'Group',
                key: 'Group',
                render: (_, record) => (
                    <div>
                        {record.RowIds.length > 1 ?
                            <NwIcon icon={light("layer-group")} /> :
                            record.Type === "Text" ? <NwIcon icon={light("message-text")} /> : (null)}
                    </div>
                )
            },
            {
                title: 'Taxes',
                dataIndex: 'Taxes',
                key: 'Taxes',
                width: 160,
                render: (_, record) => (
                    <>
                        <div className="invoice-row-amount">
                            <Money amount={record.Amount} currency={invoiceData.Currency} invert={invertAmount} />
                        </div>
                        <div className="invoice-row-taxes">
                            {record.Taxes.map((tax, index) => (
                                <div className="invoice-row-tax" key={index}>
                                    <small>{tax.Code} ({tax.Perc}%)</small>
                                    <div><Money amount={tax.Amount} invert={invertAmount} currency={invoiceData.Currency} /></div>
                                </div>
                            ))}
                        </div>
                    </>
                )
            },
            {
                title: (<NwLinkButton disabled={locked} onClick={() => setShowManualDrawer(true)} label="add a text line" />),
                dataIndex: 'actions',
                key: 'actions',
                width: 100,
                align: 'right',
                render: (_, record) => {
                    if (record.Type === "Text") {
                        return (
                            <NwButton disabled={locked} size="small" icon={light("trash-alt")} onClick={() => onDelete(record)} />
                        )
                    } else {
                        const actions = [{
                            name: "",
                            key: "edit",
                            pinned: true,
                            disabled: locked,
                            icon: light("pen"), action: () => {
                                if (record.RowIds.length === 1) {
                                    onShowTransactionDrawer(record)
                                } else {
                                    setShowEditGroupDrawer(record)

                                }
                            }
                        }];
                        if (record.RowIds.length > 1) {
                            actions.push({
                                name: "Ungroup",
                                key: "Ungroup",
                                disabled: locked,
                                action: () => onUnGroup(record)
                            })
                        } else {
                            actions.push({
                                name: "Delete",
                                key: "delete",
                                type: "delete",
                                disabled: locked,
                                action: () => onDelete(record),
                            });
                        }
                        if (enableCOA) {
                            actions.push({
                                name: "Change Account Code",
                                key: "accountCode",
                                disabled: locked,
                                action: () => changeAccountCode(record),
                            });
                        }
                        return (
                            <NwDropDownMenu
                                size="small"
                                right={true}
                                actions={actions}
                            />
                        )
                    }
                },
            },
        ];

        const components = {
            body: {
                row: DraggableBodyRow,
            },
        };

        return (
            <DndProvider backend={HTML5Backend}>
                <Table
                    showHeader={false}
                    columns={columns}
                    dataSource={dataSource}
                    components={components}
                    onRow={(_, index) => ({
                        index,
                        moveRow,
                    })}
                    pagination={false}
                />
            </DndProvider>
        );
    }

    const renderGroupButton = () => {
        if (dataSource.filter(item => item.checked).length < 2) return (null);
        return (
            <div style={{ marginBottom: "1rem" }}>
                <NwLinkButton disabled={locked} onClick={() => setShowGroupDrawer(true)} label="group selected lines" />
            </div>
        )
    }

    return (
        <Wrapper>
            <div className="invoice-lines">
                <div className="statements-table">
                    {renderStatements()}
                </div>
                {renderGroupButton()}
            </div>
            {showGroupDrawer &&
                <GroupDrawer
                    onClose={() => setShowGroupDrawer(false)}
                    invoiceID={invoiceData.ID}
                    invoiceRowIDs={dataSource.filter(item => item.checked).map(item => item.index)}
                />
            }
            {showManualDrawer &&
                <AddManualDrawer
                    onClose={() => setShowManualDrawer(false)}
                    invoiceID={invoiceData.ID}
                    record={showManualDrawer === true ? null : showManualDrawer}
                />
            }
            {showTransactionDrawer &&
                <TransactionDetailDrawer
                    transaction={transaction}
                    projectId={transaction.Project ? transaction.Project.ID : null}
                    onClose={onCloseTransactionDrawer}
                    onUpdateTransaction={() => handleTransactionCreatedOrUpdated(transaction.Project.ID)}
                    onTransactionRemoved={() => {
                        onCloseTransactionDrawer();
                        handleTransactionCreatedOrUpdated(transaction.Project.ID);
                    }}
                />
            }
            {showEditGroupDrawer &&
                <GroupEditDrawer
                    invoiceData={invoiceData}
                    onClose={() => setShowEditGroupDrawer()}
                    record={showEditGroupDrawer}
                />
            }
            {showChangeAccountCodeDrawer &&
                <ChangeAccountDrawer
                    invoiceID={invoiceData.ID}
                    records={[ showChangeAccountCodeDrawer ]}
                    onClose={() => setShowChangeAccountCodeDrawer(null)}
                />
            }
        </Wrapper>
    )
}

export default InvoicePageRows;