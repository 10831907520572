import React, { useRef } from "react";
import { Input, Switch } from "antd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useStateValue } from "Contexts/StateProvider";
import NwIcon from 'Components/Gui/NwIcon';
import NwDivider from "Components/Gui/NwDivider";
import FilterTagSelector from 'Components/FilterTagSelector/FilterTagSelector';
import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";
import { useNavigate } from "react-router";
import { QuickFilterContainer } from "Components/Filters/FilterForm/FilterFormUI";
import { useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/SelectorContext";

const ModelQuickFilters = ({
    tags,
    onAfterChangeFilters,
    isMini,
    isMobile
}) => {

    const { selection } = useContactsSelector();
    const cs_dispatch = useContactsSelectorDispatch();
    const {
        quickFilters,
    } = selection;

    const [{ areaContext }] = useStateValue();
    const navigate = useNavigate();

    const nameRef = useRef();

    const sortOptions = [
        { label: 'Name', value: 'Name' },
        { label: 'ID', value: 'ID' },
        { label: 'Agency', value: 'MotherAgency' },
    ];

    const handleChangeFilter = (name, value) => {
        cs_dispatch({
            type: "ChangeQuickFilters",
            quickFilters: {
                ...quickFilters,
                [name]: value
            }
        })
        if (onAfterChangeFilters) {
            onAfterChangeFilters()
        }
    }

    return (
        <QuickFilterContainer $isMini={isMini}>
            {!isMini &&
                <NwDivider>quick search</NwDivider>
            }
            <div className="quick-filter-line">
                <Input
                    ref={nameRef}
                    allowClear
                    autoComplete="off"
                    placeholder="Filter by name"
                    prefix={<NwIcon icon={light("search")} />}
                    onChange={(e) => {
                        handleChangeFilter('nameFilter', e.target.value)
                    }}
                    value={quickFilters.nameFilter}
                />
            </div>
            <div className="quick-filter-line">
                <div className="quick-filter-item">
                    <Switch
                        size="small"
                        checked={quickFilters.onlymine}
                        onChange={(value) => {
                            handleChangeFilter('onlymine', value)
                        }}
                    />
                    <span className="after">mine only</span>
                </div>
                {(isMini && !isMobile) &&
                    <div className="quick-filter-item">
                        <Switch
                            size="small"
                            checked={quickFilters.showFeaturesQuickFilters}
                            disabled={!quickFilters.showFeaturesQuickFilters}
                            onChange={(val) => {
                                if (!val) {
                                    cs_dispatch({
                                        type: "ChangeQuickFilters",
                                        quickFilters: {
                                            ...quickFilters,
                                            showFeaturesQuickFilters: false,
                                            featuresQuickFilters: {}
                                        }
                                    })
                                    if (onAfterChangeFilters) {
                                        onAfterChangeFilters()
                                    }
                                }
                            }}
                        />
                        <span className={`after${quickFilters.showFeaturesQuickFilters ? ' highlighted' : ' muted'}`}>features filtered</span>
                    </div>
                }
            </div>
            {tags && tags.length > 0 &&
                <div className="quick-filter-line">
                    <div className="quick-filter-item">
                        <FilterTagSelector
                            tags={tags}
                            selectedOptions={quickFilters.tagFilter}
                            onChange={(_, _tagFilter) => {
                                handleChangeFilter('tagFilter', _tagFilter)
                            }}
                        />
                    </div>
                </div>
            }
            <div className="quick-filter-line">
                <div className="quick-filter-item">
                    <RadioButtonGroup
                        value={quickFilters.intown}
                        onChange={(value) => {
                            handleChangeFilter('intown', value)
                        }}
                        size='small'
                        options={[
                            { label: 'IN', value: 'in' },
                            { label: 'OUT', value: 'out' },
                            { label: 'ALL', value: 'all' },
                        ]}
                    />
                </div>
                <div className="quick-filter-item">
                    <RadioButtonGroup
                        value={quickFilters.gender}
                        size='small'
                        onChange={(value) => {
                            handleChangeFilter('gender', value)
                        }}
                        defaultValue='All'
                        options={[
                            { label: '', value: 'female', icon: light('venus') },
                            { label: '', value: 'male', icon: light('mars') },
                            { label: '', value: 'non-binary', icon: light('mars-and-venus') },
                            { label: '', value: 'all', icon: light('asterisk') }
                        ]}
                    />
                </div>
            </div>
            {areaContext.area !== 'accounting' && !isMini && !isMobile &&
                <div className="quick-filter-line">
                    <div className="quick-filter-item">
                        <Switch
                            size="small"
                            checked={quickFilters.showFeaturesQuickFilters}
                            onChange={(val) => {
                                cs_dispatch({
                                    type: "ChangeQuickFilters",
                                    quickFilters: {
                                        ...quickFilters,
                                        showFeaturesQuickFilters: val,
                                        featuresQuickFilters: {}
                                    }
                                })
                                if (onAfterChangeFilters) {
                                    onAfterChangeFilters()
                                }
                                if (!val) {
                                    navigate('/models')
                                }
                            }}
                        />
                        <span className="after">filter list on features</span>
                    </div>
                </div>
            }
            {(isMini && !isMobile) &&
                <div className="quick-filter-line">
                    <div className="quick-filter-item">
                        <span className="before">sort by</span>
                        <RadioButtonGroup
                            value={quickFilters.sort}
                            onChange={(value) => handleChangeFilter('sort', value)}
                            size='small'
                            options={sortOptions}
                        />
                    </div>
                </div>
            }
        </QuickFilterContainer>
    )
}

export default ModelQuickFilters;
