import { getMoment } from "Libs/NwMoment";
import React from "react";
import * as Yup from "yup";

import EditEvent from "Components/EventDrawer/EditEvent";
import {
    NWInput,
    NWContactDropdown,
    NWAddressSelector,
    NWTextAreaCharCounter,
    NWLongEventPeriodPicker
} from "Components/Gui/NwForm/NwFormItems";
// import { ListContext } from "Contexts/ListContext";
import { getNow, getTime, isAllDay } from "Libs/NwMoment";
import { validateAddress } from 'Libs/NwUtils';
import {
    useUpdateScoutingMeeting,
    useAddScoutingMeeting
} from 'Hooks/Event/UseScoutingMeeting';
import { getContactName } from "Hooks/Contact/UseContactsList";

const ScoutingMeetingForm = props => {
    const {
        scoutingMeetingsData,
        travelPlanId,
        viewMode,
        contactId,
        allDay,
        dateSelected,
        afterUpdate,
        onTouchForm,
        onCancel,
        onUpdateScoutingMeetingData
    } = props;
    
    const { mutateAsync: onAddScoutingMeeting } = useAddScoutingMeeting();
    const { mutateAsync: onUpdateScoutingMeeting } = useUpdateScoutingMeeting();

    const fields = [
        {
            component: NWContactDropdown,
            label: "Contact",
            activeTypes: ["cli", 'age'],
            activableTypes: [],
            name: "ContactID",
            Col: 24,
            onAfterChange: (values, setFieldValue, value) => {
                if (value) {
                    setFieldValue('Title', `meeting with ${getContactName(value)}`)
                }
            }
        },
        {
            component: NWLongEventPeriodPicker,
            label: 'Event Period',
            name: 'EventDate',
            singleDay: true,
            Col: 24
        },
        {
            component: NWInput,
            label: 'Title',
            name: 'Title',
            type: 'text',
            Col: 24
        },
        {
            component: NWAddressSelector,
            name: 'Address',
            validate: validateAddress,
            Col: 24
        },
        {
            component: NWTextAreaCharCounter,
            label: 'Description',
            name: 'Description',
            type: 'text',
            maxLength: 2000,
            Col: 24
        }
    ];

    const dataSource = contactId ? fields.filter(field => field.name !== 'ContactID') : [...fields];

    let initialValues = {
        TravelPlanID: travelPlanId,
        Title: scoutingMeetingsData ? scoutingMeetingsData.Title : '',
        Description: scoutingMeetingsData ? scoutingMeetingsData.Description : '',
        ContactID: scoutingMeetingsData ? scoutingMeetingsData.ContactID : (contactId ? contactId : ''),
        Address: scoutingMeetingsData ? {
            City: scoutingMeetingsData.City,
            Zip: scoutingMeetingsData.Zip,
            Country: scoutingMeetingsData.Country,
            County: scoutingMeetingsData.County,
            State: scoutingMeetingsData.State,
            Access: scoutingMeetingsData.Access,
            Street: scoutingMeetingsData.Street
        } : null,
        EventDate: {
            startDate: scoutingMeetingsData ? getMoment(scoutingMeetingsData.StartDate).startOf('day')
                : dateSelected ? getMoment(dateSelected).startOf('day')
                    : getNow(true),
            endDate: scoutingMeetingsData ? getMoment(scoutingMeetingsData.EndDate).startOf('day')
                : dateSelected ? getMoment(dateSelected).startOf('day')
                    : getNow(true),
            startTime: 
                (scoutingMeetingsData 
                ? 
                    getTime(getMoment(scoutingMeetingsData.StartDate))
                : 
                    (dateSelected 
                    ? 
                        (allDay 
                        ? 
                            [0,0]
                        :
                            getTime(dateSelected)
                        )
                    : 
                        [9,0]
                    )
                ),
            endTime: 
                (scoutingMeetingsData
                ?
                    getTime(getMoment(scoutingMeetingsData.EndDate))
                :
                    (dateSelected 
                    ?
                        (allDay 
                        ?
                            [23,59]
                        : 
                            getTime(dateSelected.clone().add(2, 'hours'))
                        )
                    :
                        [11,0]
                    )
                ),
            allDay: 
                scoutingMeetingsData 
                ? 
                    isAllDay(getMoment(scoutingMeetingsData.StartDate), getMoment(scoutingMeetingsData.EndDate)) 
                : 
                    allDay || false,
            dates: []
        },
    };

    const submitForm = async (values, setStatus, setSubmitting) => {
        const newScoutingMeetingData = {
            ...values,
            StartDate: getMoment(values.EventDate.startDate).hour(values.EventDate.startTime[0]).minute(values.EventDate.startTime[1]),
            EndDate: getMoment(values.EventDate.endDate).hour(values.EventDate.endTime[0]).minute(values.EventDate.endTime[1]),
            City: values.Address.City,
            Zip: values.Address.Zip,
            Country: values.Address.Country,
            County: values.Address.County,
            State: values.Address.State,
            Access: values.Address.Access,
            Street: values.Address.Street
        }

        if (!scoutingMeetingsData) {
            try {
                const response = await onAddScoutingMeeting({ scoutingMeetingData: newScoutingMeetingData });
                onTouchForm(false);
                if (afterUpdate) {
                    afterUpdate({
                        ...response,
                        TypeName: "Meeting"
                    });
                }
                onUpdateScoutingMeetingData(response.ID);
                onCancel();
            } catch (error) {
                console.log("ant : Create ScoutingMeeting Error", error);
            }
            setSubmitting(false);
        }
        else {
            newScoutingMeetingData.ID = scoutingMeetingsData.ID;
            try {
                await onUpdateScoutingMeeting({ scoutingMeetingData: newScoutingMeetingData });
                if (afterUpdate) {
                    afterUpdate();
                }
                onTouchForm(false);
                onUpdateScoutingMeetingData();
                if (!viewMode || viewMode === 'create') {
                    onCancel();
                }
            } catch (error) {
                console.log("ant : Update ScoutingMeeting Error", error);
            }
            setSubmitting(false);
        }
    };

    let validationSchema = Yup.object().shape({
        ContactID: Yup.string().required("Contact is required"),
        Title: Yup.string().required("Title is required"),
    });

    return (
        <EditEvent
            submitForm={submitForm}
            validationSchema={validationSchema}
            dataSource={dataSource}
            initialValues={initialValues}
            disableReinitialize   
            onTouchForm={onTouchForm}
            onCancel={onCancel} />
    );
};

export default ScoutingMeetingForm;
