import { getMoment } from "Libs/NwMoment";
import React from "react";
import * as Yup from "yup";

import EditEvent from "Components/EventDrawer/EditEvent";
import {
    NWInput,
    NWContactDropdown,
    NWProjectSelector,
    NWTextAreaCharCounter,
    NWLongEventPeriodPicker,
    NWLocationSelector
} from "Components/Gui/NwForm/NwFormItems";
import { getNow, getTime, isAllDay } from "Libs/NwMoment";
import { validateAddress } from 'Libs/NwUtils';
import {
    useUpdateMeeting,
    useAddMeeting
} from 'Hooks/Event/UseMeeting';
import { getContactName } from "Hooks/Contact/UseContactsList";
import { usePreviewProject } from 'Hooks/Project/UseProject';

const MeetingEventForm = props => {
    const { 
        meetingsData, 
        viewMode, 
        projectId, 
        contactId, 
        allDay, 
        dateSelected, 
        afterUpdate, 
        onCancel,
        onUpdateMeetingData,
        onTouchForm
    } = props;
    const { data: project } = usePreviewProject((meetingsData && meetingsData.ProjectID) || projectId); 
    const { mutateAsync: onAddMeeting } = useAddMeeting();
    const { mutateAsync: onUpdateMeeting } = useUpdateMeeting();

    const fields = [
        {
            component: NWContactDropdown,
            label: "Contact",
            activeTypes: ["cli"],
            activableTypes: [],
            name: "ContactID",
            Col: 24,
            onAfterChange: (values, setFieldValue, value) => {
                if (value) {
                    setFieldValue('Title', `meeting with ${getContactName(value)}`)
                }
            }
        },
        {
            component: NWLongEventPeriodPicker,
            label: 'Event Period',
            name: 'EventDate',
            singleDay: true,
            Col: 24
        },
        {
            component: NWInput,
            label: 'Title',
            name: 'Title',
            type: 'text',
            Col: 24
        },
        {
            component: NWLocationSelector,
            name: 'Address',
            eventType: 'meeting',
            projectCustomerId: project && project.CustomerID,
            defaultAddress: meetingsData ? {
                City: meetingsData.City,
                Zip: meetingsData.Zip,
                Country: meetingsData.Country,
                County: meetingsData.County,
                State: meetingsData.State,
                Access: meetingsData.Access,
                Street: meetingsData.Street
            } : null,
            validate: validateAddress,
            Col: 24
        },
        {
            component: NWTextAreaCharCounter,
            label: 'Description',
            name: 'Description',
            type: 'text',
            maxLength: 2000,
            Col: 24
        }
    ];

    const dataSource = contactId ? fields.filter(field => field.name !== 'ContactID') : [...fields];

    if (!meetingsData) {
        dataSource.splice(0, 0, {
            component: NWProjectSelector,
            name: 'ProjectID',
            readOnly: projectId ? true : false,
            Col: 24
        })
    }

    let initialValues = {
        Title: meetingsData ? meetingsData.Title : '',
        Description: meetingsData ? meetingsData.Description : '',
        ContactID: meetingsData ? meetingsData.ContactID : (contactId ? contactId : ''),
        Address: {
            Country: meetingsData ? meetingsData.Country : '',
            City: meetingsData ? meetingsData.City : '',
            County: meetingsData ? meetingsData.County : '',
            State: meetingsData ? meetingsData.State : '',
            Zip: meetingsData ? meetingsData.Zip : '',
            Street: meetingsData ? meetingsData.Street : '',
            Access: meetingsData ? meetingsData.Access : '',
            Phone: meetingsData ? meetingsData.PhoneNumber : ''
        },
        ProjectID: meetingsData && meetingsData.Project ? meetingsData.Project.ID : projectId,
        EventDate: {
            startDate: meetingsData ? getMoment(meetingsData.StartDate).startOf('day')
                : dateSelected ? getMoment(dateSelected).startOf('day')
                    : getNow(true),
            endDate: meetingsData ? getMoment(meetingsData.EndDate).startOf('day')
                : dateSelected ? getMoment(dateSelected).startOf('day')
                    : getNow(true),
            startTime:
                (meetingsData 
                ? 
                    getTime(getMoment(meetingsData.StartDate))
                : 
                    (dateSelected 
                    ? 
                        (allDay 
                        ? 
                            [0,0]
                        : 
                            getTime(dateSelected)
                        )
                    : 
                        [9,0]
                    )
                ),
            endTime: 
                (meetingsData 
                    ? 
                        getTime(getMoment(meetingsData.EndDate))
                    :
                        (dateSelected 
                        ?
                            (allDay 
                            ?
                                [23,59]
                            :
                                getTime(dateSelected.clone().add(2, 'hours'))
                            ) 
                        : 
                            [11,0]
                        )
                ),
            allDay: meetingsData ? isAllDay(getMoment(meetingsData.StartDate), getMoment(meetingsData.EndDate)) : allDay || false,
            dates: []
        },
    };

    const submitForm = async (values, setStatus, setSubmitting) => {
        const newMeetingData = {
            ...values,
            StartDate: getMoment(values.EventDate.startDate).hour(values.EventDate.startTime[0]).minute(values.EventDate.startTime[1]),
            EndDate: getMoment(values.EventDate.endDate).hour(values.EventDate.endTime[0]).minute(values.EventDate.endTime[1]),
            City: values.Address.City,
            Zip: values.Address.Zip,
            Country: values.Address.Country,
            County: values.Address.County,
            State: values.Address.State,
            Access: values.Address.Access,
            Street: values.Address.Street
        }

        if (!meetingsData) {
            try {
                const response = await onAddMeeting({ meetingData: newMeetingData });
                onTouchForm(false);
                if (afterUpdate) {
                    afterUpdate();
                }
                onUpdateMeetingData(response.ID);
                onCancel();
            } catch (error) {
                console.log("ant : Create Meeting Error", error);
            }
            setSubmitting(false);
        }
        else {
            newMeetingData.ID = meetingsData.ID;
            try {
                await onUpdateMeeting({ meetingData: newMeetingData });
                onTouchForm(false);
                if (afterUpdate) {
                    afterUpdate();
                }
                onUpdateMeetingData();
                if (!viewMode || viewMode === 'create') {
                    onCancel();
                }
            } catch (error) {
                console.log("ant : Update Meeting Error", error);
            }
            setSubmitting(false);
        }
    };

    let validationSchema = Yup.object().shape({
        ContactID: Yup.string().required("Contact is required"),
        Title: Yup.string().required("Title is required"),

    });

    return (
        <EditEvent
            submitForm={submitForm}
            validationSchema={validationSchema}
            dataSource={dataSource}
            initialValues={initialValues}
            disableReinitialize
            onTouchForm={onTouchForm}
            onCancel={onCancel} />
    );
};

export default MeetingEventForm;
