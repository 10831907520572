import { Table, Typography } from 'antd';
import styled from 'styled-components';


const { Text } = Typography;

export const StyledTable = styled(Table)`
    .select-media {
        background: white;
        padding: 0.5rem 1rem;
        margin-bottom: 0.5rem;
        color: #000;
        cursor: pointer;
        user-select: none;
    }
    .ant-table-tbody {
        tr {
            td {
                cursor: pointer;
                vertical-align: top;
                
                &.cover-image {
                    padding: .5rem 1rem;

                    @media only screen and (max-width: 720px) {
                        padding: .5rem 0;
                    }
                }
                &.preview-trigger {
                    padding: 4px 0;
                    border-left: ${props => props.theme.DefaultBorder};
                    vertical-align: middle;
                    font-size: 1.25rem;
                    min-width: 2.5em;
                }
                &.no-pointer {
                    cursor: default;
                }
                &.ant-table-row-expand-icon-cell {
                    display: none;
                }
            }
            &.ant-table-row-selected {
                td {
                    background-color: ${props => props.theme.SelectedBackgroundColor};
                }
            }
            &:last-child {
                td {
                    border-bottom: none;
                }
            }
            &.ant-table-expanded-row {
                background: ${props => props.theme.LayoutBodyBackground} !important;
                td {
                    background: none;
                }
            }
        }
    }

    .ant-table-thead {
        display: ${props => props.breakpoints < 4 && 'none'};
    }
`

export const FeaturesContainer = styled.div`
    margin-top: .15rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: ${props => props.$smaller ? ".75rem" : "1rem"};

    dl {
        display: inline-block;
        margin-right: .5em;
        margin-bottom: 0;
        white-space: nowrap;
        line-height: 1em;

        dt {
            display: inline-block;
            padding: 0;
        }
        dd {
            display: inline-block;
            padding: 0 .25em;
        }

        &:after {
            content: '•'
        }

        &:last-of-type {
            margin-right: 0;
            &:after {
                content: none;
            }
        }
    }
`

export const NameCell = styled.div`
    
    .name {
        //width: ${props => !props.$mobileView && '15em'};
        display: flex;
        justify-content: flex-start;
        align-items: center;

        h1 {
            font-size: 1.25em;
            line-height: 1.1em;
            margin: 0;
            color: ${props => props.$status === 'busy' ? props.theme.DangerColor :
                props.$status === 'no-busy' && props.theme.SuccessColor};
            text-transform: capitalize;
            margin-right: 8px;

            @media only screen and (max-width: 720px) {
                font-size: 1rem;
            }
        }
    }
    
    
    small {
        text-transform: uppercase;
    }

    p {
        margin-bottom: 0;
    }
    
    .detail {
        font-size: .8rem;
        margin-top: 4px;
    }
`

export const PackageActionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 5rem;
`

export const CalendarActions = styled.div`
    font-size: 1.5rem;
`
export const ModelCoverWrapper = styled.div`
    border: 2px solid ${props => props.selected ? props.theme.PrimaryColor : 'transparent'};
`

export const TagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 6em;
`

export const WallContainer = styled.div`
    margin-bottom: 40px;

    .add-models-as {
        padding: 1rem 2rem;
        border-bottom: ${props => props.theme.DefaultBorder};
    }

    .modeltable-card-wrapper {
        margin-top: 1rem;
    }
`;