export const MODELS_FILTERS = {
    filters: [
        {
            name: 'WorkingOn',
            label: 'Working On',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: 'period',
            type: 'period'
        },
        {
            name: 'NotWorkingOn',
            label: 'NOT working On',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: 'period',
            type: 'period'
        },
        {
            name: 'Booker',
            label: 'Booker',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: 'booker',
            type: 'booker-selector'
        },
        {
            name: 'Scouter',
            label: 'Scouter',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: 'booker',
            type: 'booker-selector'
        },
        {
            name: 'Tags',
            label: 'Tags',
            area: 'work',
            areaIndex: 1,
            defaultval: [],
            commontype: 'tag',
            type: 'models-tag'
        },
        {
            name: 'AddedSince',
            label: 'Added Since',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: 'date',
            type: 'date'
        },
        {
            name: 'AddedUntil',
            label: 'Added Until',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: 'date',
            type: 'date'
        },
        {
            name: 'InTown',
            label: 'In Town',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: null,
            type: 'intown-selector'
        },
        {
            name: 'Documents',
            label: 'Pictures and videos',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: null,
            type: 'document-filter'
        },
        {
            name: 'Playlist',
            label: 'Playlists',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: null,
            type: 'playlist-filter'
        },
        {
            name: 'Published',
            label: 'Published playlists',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: null,
            type: 'published-filter'
        },
        {
            name: 'AgencyID',
            label: 'Working with agency',
            area: 'agency',
            areaIndex: 2,
            defaultval: null,
            commontype: null,
            type: 'agency-selector'
        },
        {
            name: 'AgencyType',
            label: 'as:',
            description: 'agency relationship type',
            area: 'agency',
            areaIndex: 2,
            defaultval: null,
            commontype: null,
            type: 'agency-type-selector'
        },
        {
            name: 'AgencyNotIn',
            label: 'exclude condition',
            area: 'agency',
            areaIndex: 2,
            defaultval: null,
            commontype: null,
            type: 'boolean'
        },
        {
            name: 'Represented',
            label: 'Represented',
            area: 'agency',
            areaIndex: 2,
            defaultval: null,
            commontype: null,
            type: 'boolean'
        },
        
        {
            name: 'AgeSince',
            label: 'Age - From',
            area: 'demographic',
            areaIndex: 3,
            defaultval: null,
            commontype: null,
            type: 'number'
        },
        {
            name: 'AgeUntil',
            label: 'Age - To',
            description: 'inclusive',
            area: 'demographic',
            areaIndex: 3,
            defaultval: null,
            commontype: null,
            type: 'number'
        },
        
        {
            name: 'Nationalities',
            label: 'Nationality',
            area: 'demographic',
            areaIndex: 3,
            defaultval: [],
            commontype: 'array',
            type: 'countries-selector'
        },
        {
            name: 'Citizenships',
            label: 'Citizenship',
            area: 'demographic',
            areaIndex: 3,
            defaultval: [],
            commontype: 'array',
            type: 'countries-selector'
        },
        {
            name: 'Gender',
            label: 'Gender',
            area: 'demographic',
            areaIndex: 3,
            defaultval: null,
            commontype: null,
            type: 'gender'
        },
        {
            name: 'Countries',
            label: 'Country',
            description: 'from main address',
            area: 'demographic',
            areaIndex: 3,
            defaultval: [],
            commontype: 'array',
            type: 'countries-selector'
        },
        {
            name: 'Cities',
            label: 'City',
            description: 'from main address',
            area: 'demographic',
            areaIndex: 3,
            defaultval: [],
            commontype: 'array',
            type: 'cities-selector'
        },
    ]
};