import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getMoment } from "Libs/NwMoment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Row, Col, Statistic } from "antd";
import Axios from 'axios';

import { ListContext } from "Contexts/ListContext";
import { useStateValue } from 'Contexts/StateProvider';
import { forceReloadProject, useProject } from 'Hooks/Project/UseProject';
import { useAddTransaction } from 'Hooks/Transaction/UseTransaction';
import { onGetOrderedEventList } from 'Hooks/Project/UseProject';
import {
    NWContactDropdown,
    NWInput,
    NWSelect,
    NWDatePicker,
    NWTextAreaCharCounter,
    NWQuantitySelector,
    NWAmountInput
} from "Components/Gui/NwForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { validateAmount, getCurrency } from 'Libs/NwUtils';
import EventSelector from 'Components/EventSelector';
import EventModelSelector from 'Components/EventModelSelector';
//import { getGroupedEvents } from 'Components/EventDrawer/BookingEvent/bookingEventUtils';
import Switch from 'Components/Gui/CustomSwitch';
import AddEditUsageDrawer from 'Components/Project/ProjectTransactions/Usages/AddEditUsageDrawer';
import {
    TRANSACTION_FAMILIES,
    TRANSACTION_MODEL_OPTIONS,
    MODEL_FIELD_CONDITIONS,
    getContacts,
    prepareTransactionData,
    groupTransactionLabels,
    getInitialValues,
    isServiceToSet,
    getCostLabel,
} from './TransactionCreateFormUtils';
import { StyledRow, CostContainer } from 'Components/Project/ProjectTransactions/Transactions/ProjectTransactionsUI';
import FlexContainer from 'Components/Gui/FlexContainer';
import useConfigurations from 'Hooks/UseConfigurations';

const AddNewLink = styled.div`
    cursor: pointer;
    text-decoration: underline;
    color: ${props => props.theme.PrimaryColor};
`;

const TransactionForm = styled.div`
    display: ${props => props.show ? 'block' : 'none'};
`;

const UsageContainer = styled.div`
    margin-bottom: 24px;

    .ant-form-item {
        margin-bottom: 4px !important;
    }
`;

const TotalContainer = styled.div`
  background-color: ${props => props.theme.LayoutBodyBackground};
  border-top: ${props => props.theme.DefaultBorder};
  border-bottom: ${props => props.theme.DefaultBorder};
  text-align: right;
  padding: 0 3em;
  margin: 0 -24px;
`;

const TransactionCreateForm = ({
    family,
    plainMode,
    projectId,
    onTransactionCreated,
    onCancel,
    modelId,
    usageId,
    modelSelectionEnabled,
    event,
    isEventEditable,
    onTouchForm
}) => {
    const [{ areaContext }] = useStateValue();
    const { data: currentProject } = useProject(projectId);

    const events = onGetOrderedEventList(currentProject, areaContext, false, false);

    //   const orderedEventList = onGetOrderedEventList(currentProject, areaContext);
    //   const events = useMemo(() => {
    //     return getGroupedEvents(orderedEventList);
    //   }, [orderedEventList]);

    const { cachedList } = useContext(ListContext);
    const [selectedEvent, setSelectedEvent] = useState(event && event.elType !== 'Meeting' ? event : null);
    const transactionFamilies = cachedList.transactionFamilies.items;
    const [contacts, setContacts] = useState([]);
    const [showUsage, setShowUsage] = useState(usageId ? true : false);
    const [showNewUsage, setShowNewUsage] = useState(false);
    const { mutateAsync: onAddTransaction } = useAddTransaction();
    const [contactBillingInfo, setContactBillingIfo] = useState(null);
    const [modelBillingInfo, setModelBillingIfo] = useState(null);
    const [serviceBillingInfo, setServiceBillingIfo] = useState([]);
    const modelOption = TRANSACTION_MODEL_OPTIONS[family];

    const configurations = useConfigurations()

    const fetchBillingInfo = async (customerID, type, setFieldValue) => {
        try {
            const billingInfo = customerID ? await Axios.get(`/contacts/billinginfos/${customerID}`) : [];
            switch (type) {
                case 'customer':
                    setContactBillingIfo(billingInfo.filter(item => !item.Disabled));
                    break;
                case 'model':
                    setModelBillingIfo(billingInfo.filter(item => !item.Disabled));
                    break;
                case 'service':
                    setServiceBillingIfo(billingInfo.filter(item => !item.Disabled));
                    break;
                default: break;
            }
            if (setFieldValue) {
                const fieldName = type === 'customer' ? 'ClientBillingInfoID' : type === 'model' ? 'ModelBillingInfoID' : 'ServiceBillingInfoID';
                if (billingInfo.filter(item => !item.Disabled).length > 1) {
                    const defaultValue = billingInfo.find(item => item.isDefault);
                    setFieldValue(fieldName, customerID ? defaultValue.ID : undefined);
                } else {
                    setFieldValue(fieldName, undefined);
                }
            }
        } catch (error) {
            console.log('ant : Fetch Billing Info Error => ', error);
        }
    };

    useEffect(() => {
        const fetchDefaultCustomerBillingInfo = async () => {
            await fetchBillingInfo(currentProject.CustomerID, 'customer');
        };

        if (currentProject && currentProject.CustomerID) {
            fetchDefaultCustomerBillingInfo();
        }
    }, [currentProject]);

    useEffect(() => {
        const fetchDefaultModelBillingInfo = async () => {
            await fetchBillingInfo(modelId, 'model');
        };

        if (modelId) {
            fetchDefaultModelBillingInfo();
        }
    }, [modelId]);

    useEffect(() => {
        if (!currentProject) return;
        const contactsFromProjectAndEvents = getContacts(currentProject, events);
        setContacts(contactsFromProjectAndEvents);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProject, selectedEvent]);

    const LABELS = transactionFamilies.find(el => el.Code === family).TransactionLabels;
    let groupedLabels = groupTransactionLabels(LABELS);

    const initialValues = getInitialValues(family, LABELS, selectedEvent, currentProject, modelId, modelOption, usageId, contactBillingInfo, modelBillingInfo, configurations.defaultCurrency);

    const sendTransaction = async (values, setSubmitting) => {
        const data = prepareTransactionData(values, currentProject, selectedEvent, events);
        try {
            const response = await onAddTransaction({ data });
            onTouchForm(false);
            setSubmitting(false);
            onTransactionCreated(response);
        } catch (error) {
            setSubmitting(false);
            console.log("error", error);
        }
    };

    let validationSchema = Yup.object().shape({
        Title: Yup.string().required("Title is required"),
        Label: Yup.string().required("Label is required"),
        ClientID: Yup.string().required("Client is required"),
        ModelID: modelOption === MODEL_FIELD_CONDITIONS.Required ? Yup.number().required("Model is required") : undefined,
    });

    const handleEventChange = (event, setFieldValue) => {
        if (!event) {
            setSelectedEvent(null);
            setFieldValue('ModelID', undefined);
            return;
        }
        setSelectedEvent(event);
        setFieldValue('ModelID', undefined);
        if (event.EventClientID) {
            setFieldValue('ClientID', event.EventClientID);
        }
        if (event.DateCreated) {
            setFieldValue('WorkDate', getMoment(event.DateCreated));
        }

        if (isServiceToSet(event)) {
            setFieldValue('ServiceID', event.ServiceID);
        }
    }

    const onSelectLabel = (value, form) => {
        const label = LABELS.find(l => l.ID === value);
        if (!form.values.Title) {
            form.setFieldValue('Title', value);
        }
        if (family === TRANSACTION_FAMILIES.JobFee) {
            form.setFieldValue('Quantity', [form.values.Quantity[0], label.TypeQuantityName])
        }
        if (label.FixedTarif) {
            form.setFieldValue('Quantity', [1, label.TypeQuantityName])
            form.setFieldValue('Cost', [form.values.Cost[0], label.TotalFee])
        }
    }

    const onModelChange = (form, modelID) => {
        const { ModelID, Quantity, EventID } = form.values;
        fetchBillingInfo(modelID, 'model', form.setFieldValue);
        const event = events.find(event => event.uniqID === EventID);
        if (family === TRANSACTION_FAMILIES.JobFee && (Quantity[1] === 'Days' || Quantity[1] === 'Hours') && event && event.EventDates) {
            const eventDate = event.EventDates.find(e => e.EventDatesModels.find(m => m.ModelID === ModelID));
            form.setFieldValue('WorkDate', eventDate ? getMoment(eventDate.FromDate) : null);
        }
    }

    const handleAddNewUsage = () => {
        setShowNewUsage(true);
    }

    //   if (!currentProject) return <div />
    if (!currentProject || (modelId && !modelBillingInfo) || (currentProject.CustomerID && !contactBillingInfo)) return <div />;
    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    sendTransaction(values, setSubmitting);
                }}
            >
                {(form) => {
                    const label = LABELS.find(l => l.ID === form.values.Label);
                    // const disableDate = rangeDate => checkDate(form.values, events, rangeDate, family);
                    return (
                        <NwForm
                            onTouchForm={onTouchForm}
                            values={form.values}
                            noValidate
                            onFinish={form.handleSubmit}
                            layout="vertical"
                            disabled={form.isSubmitting}>
                            <TransactionForm show={!showNewUsage || (event ? false : true && !plainMode)}>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <EventSelector
                                            disabled={!isEventEditable}
                                            typesToExclude={["Meeting"]}
                                            label="EVENT"
                                            form={form}
                                            //events={orderedEventList}
                                            events={events}
                                            name="EventID"
                                            onChange={(event, setFieldValue) => handleEventChange(event, setFieldValue, form.values)}
                                        />
                                    </Col>
                                </Row>
                                <Field
                                    component={NWSelect}
                                    value={form.values.ClientID}
                                    label="Client"
                                    name="ClientID"
                                    disabled={contacts.length === 1}
                                    options={contacts.map(el => {
                                        return { key: el.ID, value: el.ID, label: el.Name };
                                    })}
                                    required
                                    onAfterChange={contactID => {
                                        fetchBillingInfo(contactID, 'customer', form.setFieldValue)
                                    }}
                                />
                                {contactBillingInfo && contactBillingInfo.length > 1 &&
                                    <Field
                                        component={NWSelect}
                                        value={form.values.ClientBillingInfoID}
                                        label="Client Billing Subject"
                                        name="ClientBillingInfoID"
                                        options={contactBillingInfo.map(el => {
                                            return { key: el.ID, value: el.ID, label: `${el.CompanyName}/${el.InvoiceType}` };
                                        })}
                                        required
                                    />
                                }
                                {modelOption !== MODEL_FIELD_CONDITIONS.Hidden && (
                                    <>
                                        <EventModelSelector
                                            form={form}
                                            event={selectedEvent}
                                            project={currentProject}
                                            modelId={modelId}
                                            onChange={onModelChange}
                                            disabled={!modelSelectionEnabled}
                                            required={modelOption === MODEL_FIELD_CONDITIONS.Required}
                                            withZeroOption={family === TRANSACTION_FAMILIES.JobExpense}
                                        />
                                        {modelBillingInfo && modelBillingInfo.length > 1 &&
                                            <Field
                                                component={NWSelect}
                                                value={form.values.ModelBillingInfoID}
                                                label="Model Billing Subject"
                                                name="ModelBillingInfoID"
                                                options={modelBillingInfo.map(el => {
                                                    return { key: el.ID, value: el.ID, label: `${el.CompanyName}/${el.InvoiceType}` };
                                                })}
                                                required
                                            />
                                        }
                                    </>
                                )}
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Field
                                            component={NWDatePicker}
                                            style={{ width: '100%' }}
                                            format="ddd D MMM YY"
                                            value={form.values.WorkDate}
                                            label="Date"
                                            name="WorkDate"
                                        // disabledDate={disableDate}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col lg={12} md={12} sm={12} xs={24}>
                                        <Field
                                            component={NWSelect}
                                            value={form.values.Label}
                                            label="Label"
                                            name="Label"
                                            optionType={'group'}
                                            onAfterChange={value => onSelectLabel(value, form)}
                                            options={groupedLabels}
                                            required
                                        />
                                    </Col>
                                    {family !== TRANSACTION_FAMILIES.JobFee && (
                                        <Col lg={12} md={12} sm={12} xs={24}>
                                            <Field
                                                component={NWContactDropdown}
                                                label="Service"
                                                name="ServiceID"
                                                activeTypes={['srv']}
                                                activableTypes={[]}
                                                value={form.values.ServiceID}
                                                onAfterChange={contactID => {
                                                    fetchBillingInfo(contactID, 'service', form.setFieldValue)
                                                }}
                                            />
                                        </Col>
                                    )}
                                </Row>
                                {serviceBillingInfo && serviceBillingInfo.length > 1 &&
                                    <Field
                                        component={NWSelect}
                                        value={form.values.ServiceBillingInfoID}
                                        label="Service Billing Subject"
                                        name="ServiceBillingInfoID"
                                        options={serviceBillingInfo.map(el => {
                                            return { key: el.ID, value: el.ID, label: `${el.CompanyName}/${el.InvoiceType}` };
                                        })}
                                        required
                                    />
                                }
                                {family === TRANSACTION_FAMILIES.JobFee &&
                                    <>
                                        <UsageContainer>
                                            <FlexContainer mg='0 0 8px'>
                                                <Switch
                                                    text='Usage'
                                                    checked={showUsage}
                                                    onChange={value => setShowUsage(value)} />
                                            </FlexContainer>
                                            {showUsage && currentProject.Usages.length > 0 &&
                                                <Field
                                                    component={NWSelect}
                                                    value={form.values.UsageID}
                                                    // label="Usage"
                                                    placeholder='Select an existing usage'
                                                    name="UsageID"
                                                    options={currentProject.Usages.map(el => {
                                                        const usageTypeNames = el.UsageTypes.map(usageType => usageType.Name).join(', ');
                                                        return { key: el.ID, value: el.ID, label: `${usageTypeNames} - ${el.Duration}` };
                                                    })}
                                                />
                                            }
                                            {showUsage &&
                                                <AddNewLink onClick={handleAddNewUsage}>add new usage info</AddNewLink>
                                            }
                                        </UsageContainer>
                                    </>
                                }
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Field
                                            component={NWInput}
                                            value={form.values.Title}
                                            label="Title"
                                            type="text"
                                            name="Title"
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Field
                                            component={NWTextAreaCharCounter}
                                            label="Description"
                                            name="Description"
                                            type="text"
                                            value={form.values.Description}
                                            maxLength={2000}
                                            autoSize={{ minRows: 3, maxRows: 10 }}
                                        />
                                    </Col>
                                </Row>
                                <CostContainer>
                                    <StyledRow gutter={16}>
                                        <Col lg={12} md={12} sm={12} xs={24}>
                                            <Field
                                                disabled={label.FixedTarif}
                                                component={NWAmountInput}
                                                value={form.values.Cost}
                                                validate={validateAmount}
                                                label={getCostLabel(family)}
                                                name="Cost"
                                            />
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={24}>
                                            <Field
                                                disabled={label.FixedTarif}
                                                component={NWQuantitySelector}
                                                value={form.values.Quantity}
                                                label="Quantity"
                                                name="Quantity"
                                            />
                                        </Col>
                                    </StyledRow>
                                    <TotalContainer>
                                        <Statistic title="Total" value={form.values.Cost[1] * form.values.Quantity[0]} prefix={getCurrency(form.values.Cost[0])} precision={2} />
                                    </TotalContainer>
                                </CostContainer>
                                <NwFormButtonsBar
                                    left={onCancel &&
                                        <NwCancelButton
                                            disabled={form.isSubmitting}
                                            onClick={() => {
                                                onTouchForm(false);
                                                onCancel();
                                            }}
                                        />
                                    }
                                    right={
                                        <NwSaveButton
                                            htmlType="submit"
                                            disabled={form.isSubmitting}
                                            loading={form.isSubmitting}
                                        />
                                    }
                                />
                            </TransactionForm>
                            {showNewUsage &&
                                <AddEditUsageDrawer
                                    plainMode={plainMode || (event ? true : false)}
                                    projectId={projectId}
                                    usageTypes={cachedList.usageTypes.items}
                                    onUpdate={async newUsage => {
                                        await forceReloadProject(projectId);
                                        form.setFieldValue('UsageID', newUsage.ID);
                                    }}
                                    onClose={() => setShowNewUsage(false)} />
                            }
                        </NwForm>
                    )
                }}
            </Formik>
        </>
    )
};

// TransactionCreateForm.propTypes = {
//     family: PropTypes.oneOf(Object.values(TRANSACTION_FAMILIES)).isRequired,
//     projectId: PropTypes.number.isRequired,
//     onTransactionCreated: PropTypes.func.isRequired,
//     onCancel: PropTypes.func.isRequired,
//     modelId: PropTypes.func,
//     modelSelectionEnabled: PropTypes.bool,
// };

export default TransactionCreateForm;

