import React, { useContext, useEffect } from 'react';
import Axios from 'axios';

import { UserContext } from "Contexts/UserContext";

const Interceptor = () => {
    const { ForceClientLogout } = useContext(UserContext)

    const NwApiParseError = (error) => {
        if (error.response) {
            if (!error.response.data) {
                if (error.config.url.indexOf('account/login') >= 0) {
                    return error.response
                } else {
                    //not handled by API (no token, for example)
                    if (error.response.status === 401 || error.response.status === 403) {
                        ForceClientLogout();
                    }
                }
            } else {
                const NwApiErrorCode = error.response.data.ErrorCode;
                switch (NwApiErrorCode) {
                    //case "LicenceError":
                    //case "AuthenticationError":
                    //    ForceClientLogout();
                    //    return null;
                    default:
                        let NwApiException = { errorCode: NwApiErrorCode, data: null, message: "Error" };
                        if (error.response.data.AdditionalData) {
                            NwApiException.data = error.response.data.AdditionalData;
                        }
                        if (error.response.data.Message) {
                            NwApiException.message = error.response.data.Message;
                        }
                        return NwApiException;
                }
            }
        } else if (error.request) {
            console.log("Axios - error no response", error.request);
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            return "UNDEFINED 2";
        } else {
            console.log("Axios - other error", error);
            alert("NWAPI: bug JS");
            // Something happened in setting up the request that triggered an Error
            return "UNDEFINED 3";
        }
    }

    useEffect(() => {
        Axios.interceptors.request.use(
            config => {
                const user = JSON.parse(sessionStorage.getItem("currentUser"))
            
                if (user && user.Token) {
                    config.headers['Authorization'] = 'Bearer ' + user.Token;
                }
    
                config.headers['Content-Type'] = 'application/json; charset=utf-8'
                return config;
            },
            error => {
                return Promise.reject(error)
        });
        
        Axios.interceptors.response.use((response) => {
            return response.data;
        }, function (error) {
            const nwError = NwApiParseError(error);
            return Promise.reject(nwError);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div />
    )
}

export default Interceptor;
