import React, { useState } from 'react';
import styled from 'styled-components';
import NwDrawer from 'Components/Gui/NwDrawer';
import useConfigurations from 'Hooks/UseConfigurations';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import ExchangeRateForm from './TransactionDetailForms/ExchangeRateForm';
import TransactionCommissionForm from './TransactionDetailForms/TransactionCommissionForm';
import TransactionTaxForm from './TransactionDetailForms/TransactionTaxForm';
import Money from 'Components/Gui/Money';
import NwLink from 'Components/Gui/NwLink';
import TransactionStatementDescriptionForm from './TransactionDetailForms/TransactionStatementDescriptionForm';
import { isLocked } from '../TransactionsUtils';
import StatementTaxesDetails from 'Components/ContactStatements/StatementTaxesDetails';
import TransactionContact from './TransactionDetailForms/TransactionContact';
import { useUserPermission } from 'Contexts/UserContext';

const TransactionStatementDetailsContainer = styled.div`
    .statement-details-columns {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .statement-details-column-left {
            flex: 1 1 0;
            p {
                margin: 0;
                font-size: ${props => props.theme.FontSizeSm};
            }
            h3 {
                span {
                    margin-left: 2rem;
                    font-size: ${props => props.theme.FontSizeSm};
                }
            }
        }
        .statement-details-column-right {
            text-align: right;

            p {
                margin: 0;
            }

            dl {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: .25rem;
                dt {
                    margin-right: .5rem;
                }
                dd {
                    margin-bottom: 0;
                    position: relative;
                    text-align: right;

                    &.currency-container {
                        min-width: 3rem;
                        padding-right: 0.25rem;
                    }
                    &.amount-container {
                        min-width: 5rem;
                    }
                    &.taxes-container {
                        min-width: 8rem;
                        max-width: 8rem;
                        white-space: break-spaces;
                        text-transform: uppercase;
                        font-size: .75rem;

                        svg {
                            font-size: 1.25em;
                        }
                    }
                    &.taxes-amount-container {
                        min-width: 8rem;
                    }
                }

                &.datalist-header {
                    margin-bottom: 0;
                    font-size: ${props => props.theme.FontSizeXSm};
                    color: ${props => props.theme.TextColorMuted};
                    text-transform: uppercase;

                    dd.taxes-container {
                        font-size: ${props => props.theme.FontSizeXSm};
                    }
                }
            }
        }
    }
`;

const TransactionStatementDetails = ({ transaction, type, transactionId, statements, onUpdateTransaction, onTouchForm }) => {

    const [editCommission, setEditCommission] = useState();
    const [editDescription, setEditDescription] = useState();
    const [editContact, setEditContact] = useState();
    const [touchForm, setTouchForm] = useState(false);
    const [statementForTaxes, setStatementForTaxes] = useState();
    const configuration = useConfigurations();

    const ViewAgencyFee = useUserPermission('ViewAgencyFee');
    const ViewAgencyCommission = useUserPermission('ViewAgencyCommission');
    const ViewMotherAgencyCommission = useUserPermission('ViewMotherAgencyCommission');
    const ChangeAgencyFee = useUserPermission('ChangeAgencyFee');
    const ChangeAgencyCommission = useUserPermission('ChangeAgencyCommission');
    const ChangeMotherAgencyCommission = useUserPermission('ChangeMotherAgencyCommission');
    const ViewTaxes = useUserPermission('ViewTaxes');

    const showcomm = (type === "model" && ViewAgencyCommission) || (type === "client" && ViewAgencyFee) || (type === "agency" && ViewMotherAgencyCommission);
    const editcomm = (type === "model" && ChangeAgencyCommission) || (type === "client" && ChangeAgencyFee) || (type === "agency" && ChangeMotherAgencyCommission);

    const transactionLockStatus = isLocked(transaction);

    const generalLocked = transactionLockStatus.locked;
    let statementLocked = false
    let descriptionsLocked = false
    if (generalLocked) {
        if (type === "model" && transactionLockStatus.modelLocked) {
            statementLocked = true
        }
        if (type === "agency" && transactionLockStatus.agencyLocked) {
            statementLocked = true
        }
        if (type === "client") {
            statementLocked = true
        }
    }
    if (transactionLockStatus.descriptionsLocked) {
        descriptionsLocked = true
    }

    const handleEditCommission = commission => () => {
        setEditCommission(commission);
    };

    const handleEditDescription = statement => () => {
        setEditDescription(statement);
    }

    const handleCloseEditForm = () => {
        setEditCommission();
    };

    const handleUpdateTax = tax => () => {
        setStatementForTaxes(tax);
    };

    let mainLine = null
    let commissionLine = null;
    let clientAdvanceLine = null;

    if (statements.length > 0) {
        mainLine = statements.find(item => !item.isCommission);
        commissionLine = statements.find(item => item.isCommission);
        clientAdvanceLine = statements.find(item => item.isClientAdvance);
    } else {
        mainLine = statements[0];
    }

    const statementCurrency = transaction.Currency;
    const isDefaultCurrency = statementCurrency === configuration.defaultCurrency;

    const getCommissionName = () => {
        switch (type) {
            case "client":
                return "Ag. Fee"
            case "agency":
                return "Mot. Ag. Comm."
            default:
                return 'Comm.'
        }
    }

    const getTotalName = () => {
        switch (type) {
            case "model":
                return "Net for model"
            default:
                return 'Total To Invoice'
        }
    }

    const getMainLineName = () => {
        if (transaction.Family === "JobFee") {
            switch (type) {
                case "client":
                    return "Fee"
                default:
                    return 'Gross'
            }
        } else {
            return 'Amount'
        }
    }

    const StatementDescription = () => {
        if (commissionLine && commissionLine.Description !== mainLine.Description) {
            if (descriptionsLocked || !editcomm) {
                return (
                    <>
                        <p>Fee Description in Statement<br />{mainLine.Description}</p>
                        <p>{getCommissionName()} Description in Statement<br />{commissionLine.Description}</p>
                    </>
                )
            }
            return (
                <>
                    <p><NwLink onClick={handleEditDescription(mainLine)}>Fee Description in Statement</NwLink><br />{mainLine.Description}</p>
                    <p><NwLink onClick={handleEditDescription(commissionLine)}>{getCommissionName()} Description in Statement</NwLink><br />{commissionLine.Description}</p>
                </>
            )
        }
        if (mainLine.Description !== transaction.Description) {
            if (descriptionsLocked || (type === "agency" && transaction.Family === "JobFee" && !ChangeMotherAgencyCommission)) {
                return (
                    <p>Description<br />{mainLine.Description}</p>
                )
            }
            return (
                <p><NwLink onClick={handleEditDescription(mainLine)}>Description</NwLink><br />{mainLine.Description}</p>
            )
        }
        if (commissionLine && !descriptionsLocked && editcomm) {
            return (
                <p>
                    <NwLink onClick={handleEditDescription(mainLine)}>Edit Fee Description in Statement</NwLink>
                    <br />
                    <NwLink onClick={handleEditDescription(commissionLine)}>Edit {getCommissionName()} Description in Statement</NwLink>
                </p>

            )
        }
        if (!descriptionsLocked && !(type === "agency" && transaction.Family === "JobFee" && (!ViewMotherAgencyCommission || !ChangeMotherAgencyCommission))) {
            return (
                <NwLink
                    small 
                    onClick={handleEditDescription(mainLine)}
                >
                    Edit Statement Description
                </NwLink>
            )
        } else {
            return (<></>)
        }
    }

    return (statements.length > 0 ?
        <TransactionStatementDetailsContainer>
            <div className="statement-details-columns">
                <div className="statement-details-column-left">
                    <h3>
                        {getContactName(mainLine.ContactID)}
                        &nbsp;
                        {(type === "agency" && transaction.Family === "JobFee" && !ViewMotherAgencyCommission)
                        ?
                            <span></span>
                        :
                            <NwLink disabled={generalLocked} onClick={() => setEditContact(true)}>change{type !== "client" && ' billing contact'}</NwLink>
                        }
                    </h3>
                    <StatementDescription />
                </div>
                <div className="statement-details-column-right">
                    {(type === "agency" && transaction.Family === "JobFee" && !ViewMotherAgencyCommission)
                        ?
                        <p></p>
                        :
                        <>
                            <dl className="datalist-header">
                                <dt></dt>
                                <dd className="currency-container"></dd>
                                <dd className="amount-container">amount</dd>
                                {ViewTaxes &&
                                    <dd className="taxes-container">taxes</dd>
                                }
                            </dl>
                            <dl>
                                {transaction.Family === "JobFee" && type === "agency"
                                    ?
                                    <dt>
                                        <NwLink disabled={statementLocked || !editcomm} onClick={handleEditCommission(mainLine)}>{getCommissionName()}</NwLink>
                                    </dt>
                                    :
                                    <dt>{getMainLineName()}</dt>
                                }
                                <dd className="currency-container">
                                    {!isDefaultCurrency
                                        ?
                                        <ExchangeRateForm
                                            disabled={statementLocked}
                                            asCurrency
                                            statement={mainLine}
                                            transaction={transaction}
                                        />
                                        :
                                        <span>{statementCurrency}</span>
                                    }
                                </dd>
                                <dd className="amount-container">
                                    {!isDefaultCurrency
                                        ?
                                        ((mainLine.ExchangeRate > 0)
                                            ?
                                            <Money hideCurrency redIfNegative amount={mainLine.AmountDef} currency={configuration.defaultCurrency} />
                                            :
                                            <Money hideCurrency highlightCurrency redIfNegative amount={mainLine.Amount} currency={statementCurrency} />
                                        )
                                        :
                                        <Money hideCurrency redIfNegative amount={mainLine.AmountDef} currency={configuration.defaultCurrency} />
                                    }
                                </dd>
                                {ViewTaxes &&
                                    <dd className="taxes-container">
                                        <StatementTaxesDetails
                                            disabled={statementLocked}
                                            statement={mainLine}
                                            onUpdate={handleUpdateTax}
                                            statementCurrency={statementCurrency}
                                        />
                                    </dd>
                                }
                            </dl>
                            {commissionLine && showcomm &&
                                <>
                                    <dl>
                                        <dt>
                                            {editcomm
                                                ?
                                                <NwLink
                                                    disabled={statementLocked}
                                                    onClick={handleEditCommission(commissionLine)}>
                                                    {getCommissionName()}
                                                </NwLink>
                                                :
                                                <span>{getCommissionName()}</span>
                                            }
                                        </dt>
                                        <dd className="currency-container">
                                            {!isDefaultCurrency
                                                ?
                                                <ExchangeRateForm
                                                    disabled={statementLocked}
                                                    asCurrency
                                                    statement={commissionLine}
                                                    transaction={transaction}
                                                />
                                                :
                                                <span>{statementCurrency}</span>
                                            }
                                        </dd>
                                        <dd className="amount-container">
                                            {!isDefaultCurrency
                                                ?
                                                ((commissionLine.ExchangeRate > 0)
                                                    ?
                                                    <Money hideCurrency redIfNegative amount={commissionLine.AmountDef} currency={configuration.defaultCurrency} />
                                                    :
                                                    <Money hideCurrency highlightCurrency redIfNegative amount={commissionLine.Amount} currency={statementCurrency} />
                                                )
                                                :
                                                <Money hideCurrency highlightCurrency redIfNegative amount={commissionLine.Amount} currency={statementCurrency} />
                                            }
                                        </dd>
                                        {ViewTaxes &&
                                            <dd className="taxes-container">
                                                <StatementTaxesDetails
                                                    disabled={statementLocked}
                                                    statement={commissionLine}
                                                    onUpdate={handleUpdateTax}
                                                    statementCurrency={statementCurrency}
                                                />
                                            </dd>
                                        }
                                    </dl>
                                </>
                            }
                            {clientAdvanceLine &&
                                <>
                                    <dl>
                                        <dt>
                                            Client Advance / Payroll
                                        </dt>
                                        <dd className="currency-container">
                                            <span>{statementCurrency}</span>
                                        </dd>
                                        <dd className="amount-container">
                                            <Money hideCurrency highlightCurrency redIfNegative amount={clientAdvanceLine.Amount} currency={statementCurrency} />
                                        </dd>
                                        {ViewTaxes &&
                                            <dd className="taxes-container">
                                                <StatementTaxesDetails
                                                    disabled={true}
                                                    statement={clientAdvanceLine}
                                                    onUpdate={handleUpdateTax}
                                                    statementCurrency={statementCurrency}
                                                />
                                            </dd>
                                        }
                                    </dl>
                                </>
                            }
                            {(commissionLine && showcomm)
                                ?
                                <>
                                    <dl>
                                        <dt>{getTotalName()}</dt>
                                        <dd className="currency-container">
                                            {!isDefaultCurrency
                                                ?
                                                ((mainLine.ExchangeRate > 0 && commissionLine.ExchangeRate > 0)
                                                    ?
                                                    <span>{configuration.defaultCurrency}</span>
                                                    :
                                                    ((mainLine.ExchangeRate === 0 && commissionLine.ExchangeRate === 0)
                                                        ?
                                                        <span>{statementCurrency}</span>
                                                        :
                                                        <span>&nbsp;</span>
                                                    )
                                                )
                                                :
                                                <span>{statementCurrency}</span>
                                            }
                                        </dd>
                                        <dd className="amount-container">
                                            {!isDefaultCurrency
                                                ?
                                                ((mainLine.ExchangeRate > 0 && commissionLine.ExchangeRate > 0)
                                                    ?
                                                    <Money hideCurrency highlightCurrency redIfNegative amounts={[mainLine.AmountDef, commissionLine.AmountDef]} currency={configuration.defaultCurrency} />
                                                    :
                                                    ((mainLine.ExchangeRate === 0 && commissionLine.ExchangeRate === 0)
                                                        ?
                                                        <Money hideCurrency highlightCurrency redIfNegative amounts={[mainLine.Amount, commissionLine.Amount]} currency={statementCurrency} />
                                                        :
                                                        <p>/</p>
                                                    )
                                                )
                                                :
                                                (clientAdvanceLine
                                                    ?
                                                    <Money hideCurrency highlightCurrency redIfNegative amounts={[mainLine.Amount, commissionLine.Amount, clientAdvanceLine.Amount]} currency={statementCurrency} />
                                                    :
                                                    <Money hideCurrency highlightCurrency redIfNegative amounts={[mainLine.Amount, commissionLine.Amount]} currency={statementCurrency} />
                                                )
                                            }
                                        </dd>
                                        {ViewTaxes &&
                                            <dd className="taxes-amount-container">
                                                {!isDefaultCurrency
                                                    ?
                                                    ((mainLine.ExchangeRate > 0 && commissionLine.ExchangeRate > 0)
                                                        ?
                                                        <Money invert hideCurrency highlightCurrency redIfNegative amounts={[(mainLine.AmountDef - mainLine.TaxedAmountDef), (mainLine.AmountDef - commissionLine.TaxedAmountDef)]} currency={configuration.defaultCurrency} />
                                                        :
                                                        ((mainLine.ExchangeRate === 0 && commissionLine.ExchangeRate === 0)
                                                            ?
                                                            <Money invert hideCurrency highlightCurrency redIfNegative amounts={[(mainLine.Amount - mainLine.TaxedAmount), (commissionLine.Amount - commissionLine.TaxedAmount)]} currency={statementCurrency} />
                                                            :
                                                            <p>/</p>
                                                        )
                                                    )
                                                    :
                                                    (clientAdvanceLine
                                                        ?
                                                        <Money invert hideCurrency highlightCurrency redIfNegative amounts={[(mainLine.Amount - mainLine.TaxedAmount), (commissionLine.Amount - commissionLine.TaxedAmount), (clientAdvanceLine.Amount - clientAdvanceLine.TaxedAmount)]} currency={statementCurrency} />
                                                        :
                                                        <Money invert hideCurrency highlightCurrency redIfNegative amounts={[(mainLine.Amount - mainLine.TaxedAmount), (commissionLine.Amount - commissionLine.TaxedAmount)]} currency={statementCurrency} />
                                                    )

                                                }
                                            </dd>
                                        }
                                    </dl>
                                    {(mainLine.TaxedAmount !== mainLine.Amount || commissionLine.TaxedAmount !== commissionLine.Amount) && ViewTaxes &&
                                        <dl>
                                            <dt>incl. Taxes</dt>
                                            <dd className="currency-container">
                                                {!isDefaultCurrency
                                                    ?
                                                    ((mainLine.ExchangeRate > 0 && commissionLine.ExchangeRate > 0)
                                                        ?
                                                        <span>{configuration.defaultCurrency}</span>
                                                        :
                                                        ((mainLine.ExchangeRate === 0 && commissionLine.ExchangeRate === 0)
                                                            ?
                                                            <span>{statementCurrency}</span>
                                                            :
                                                            <span>&nbsp;</span>
                                                        )
                                                    )
                                                    :
                                                    <span>{statementCurrency}</span>
                                                }
                                            </dd>
                                            <dd className="amount-container">
                                                {!isDefaultCurrency
                                                    ?
                                                    ((mainLine.ExchangeRate > 0 && commissionLine.ExchangeRate > 0)
                                                        ?
                                                        <Money hideCurrency highlightCurrency redIfNegative amounts={[mainLine.TaxedAmountDef, commissionLine.TaxedAmountDef]} currency={configuration.defaultCurrency} />
                                                        :
                                                        ((mainLine.ExchangeRate === 0 && commissionLine.ExchangeRate === 0)
                                                            ?
                                                            <Money hideCurrency highlightCurrency redIfNegative amounts={[mainLine.TaxedAmount, commissionLine.TaxedAmount]} currency={statementCurrency} />
                                                            :
                                                            <p>/</p>
                                                        )
                                                    )
                                                    :
                                                    (clientAdvanceLine
                                                        ?
                                                        <Money hideCurrency highlightCurrency redIfNegative amounts={[mainLine.TaxedAmount, commissionLine.TaxedAmount, clientAdvanceLine.TaxedAmount]} currency={statementCurrency} />
                                                        :
                                                        <Money hideCurrency highlightCurrency redIfNegative amounts={[mainLine.TaxedAmount, commissionLine.TaxedAmount]} currency={statementCurrency} />
                                                    )
                                                }
                                            </dd>
                                            <dd className="taxes-amount-container">
                                                &nbsp;
                                            </dd>
                                        </dl>
                                    }
                                </>
                                :
                                <>
                                    <dl>
                                        <dt>Total</dt>
                                        <dd className="currency-container">
                                            {!isDefaultCurrency && mainLine.ExchangeRate > 0
                                                ?
                                                <span>{configuration.defaultCurrency}</span>
                                                :
                                                <span>{statementCurrency}</span>
                                            }
                                        </dd>
                                        <dd className="amount-container">
                                            {!isDefaultCurrency && mainLine.ExchangeRate > 0
                                                ?
                                                <Money hideCurrency highlightCurrency redIfNegative amount={mainLine.AmountDef} currency={configuration.defaultCurrency} />
                                                :
                                                <Money hideCurrency highlightCurrency redIfNegative amount={mainLine.Amount} currency={statementCurrency} />
                                            }
                                        </dd>
                                        {ViewTaxes &&
                                            <dd className="taxes-amount-container">
                                                {!isDefaultCurrency && mainLine.ExchangeRate > 0
                                                    ?
                                                    <Money invert hideCurrency highlightCurrency redIfNegative amount={(mainLine.AmountDef - mainLine.TaxedAmountDef)} currency={configuration.defaultCurrency} />
                                                    :
                                                    <Money invert hideCurrency highlightCurrency redIfNegative amount={(mainLine.Amount - mainLine.TaxedAmount)} currency={statementCurrency} />
                                                }
                                            </dd>
                                        }
                                    </dl>
                                    {(mainLine.TaxedAmount !== mainLine.Amount) && ViewTaxes &&
                                        <dl>
                                            <dt>incl. Taxes</dt>
                                            <dd className="currency-container">
                                                {!isDefaultCurrency
                                                    ?
                                                    ((mainLine.ExchangeRate > 0)
                                                        ?
                                                        <span>{configuration.defaultCurrency}</span>
                                                        :
                                                        ((mainLine.ExchangeRate === 0)
                                                            ?
                                                            <span>{statementCurrency}</span>
                                                            :
                                                            <span>&nbsp;</span>
                                                        )
                                                    )
                                                    :
                                                    <span>{statementCurrency}</span>
                                                }
                                            </dd>
                                            <dd className="amount-container">
                                                {!isDefaultCurrency
                                                    ?
                                                    ((mainLine.ExchangeRate > 0)
                                                        ?
                                                        <Money hideCurrency highlightCurrency redIfNegative amount={mainLine.TaxedAmountDef} currency={configuration.defaultCurrency} />
                                                        :
                                                        ((mainLine.ExchangeRate === 0)
                                                            ?
                                                            <Money hideCurrency highlightCurrency redIfNegative amount={mainLine.TaxedAmount} currency={statementCurrency} />
                                                            :
                                                            <p>/</p>
                                                        )
                                                    )
                                                    :
                                                    <Money hideCurrency highlightCurrency redIfNegative amount={mainLine.TaxedAmount} currency={statementCurrency} />
                                                }
                                            </dd>
                                            <dd className="taxes-amount-container">
                                                &nbsp;
                                            </dd>
                                        </dl>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
            </div>
            {editContact &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Change Transaction Client'
                    onClose={() => setEditContact()}>
                    <TransactionContact
                        contactID={mainLine.ContactID}
                        contactType={type}
                        billingInfoID={mainLine.BillingInfoID}
                        transaction={transaction}
                        transactionIDs={[transactionId]}
                        transactionFamily={transaction.Family}
                        onChangeTransactionContact={() => setEditContact()}
                    />
                </NwDrawer>
            }
            {editDescription &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Edit Transaction'
                    onClose={() => {
                        setEditDescription();
                    }}>
                    <TransactionStatementDescriptionForm transaction={transaction} statement={editDescription} onUpdateTransaction={() => setEditDescription()} />
                </NwDrawer>
            }
            {editCommission &&
                <NwDrawer
                    title="Edit Commission"
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    onClose={() => {
                        setEditCommission(false);
                        setTouchForm(false);
                    }}>
                    <TransactionCommissionForm
                        type={type}
                        transaction={transaction}
                        statements={statements}
                        contactID={statements[0].ContactID}
                        commission={editCommission}
                        currency={statementCurrency}
                        transactionId={transactionId}
                        onCancel={handleCloseEditForm}
                        onUpdateTransaction={onUpdateTransaction}
                        onTouchForm={setTouchForm} />
                </NwDrawer>
            }
            {statementForTaxes &&
                <NwDrawer
                    title='Edit Tax'
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    onClose={() => {
                        setStatementForTaxes();
                        setTouchForm(false);
                    }}>
                    <TransactionTaxForm
                        statement={statementForTaxes}
                        transaction={transaction}
                        onUpdate={onUpdateTransaction}
                        onCancel={() => setStatementForTaxes()}
                        onTouchForm={setTouchForm} />
                </NwDrawer>
            }
        </TransactionStatementDetailsContainer>
        : <div />
    )
};

export default TransactionStatementDetails;