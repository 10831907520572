import React from "react";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";

const GenderSelector = ({ value, onChange, disabled, emptyValue }) => {
    
    const options = [
        { label: 'Female', value: 'Female', icon: light('venus') },
        { label: 'Male', value: 'Male', icon: light('mars') },
        { label: 'Non Binary', value: 'NonBinary', icon: light('mars-and-venus') }
    ];
    if (emptyValue) {
        options.unshift({ label: 'Any', value: null, icon: light('asterisk') });
    }

    return (
        <RadioButtonGroup
            style={{whiteSpace: "nowrap"}} 
            value={value} 
            disabled={disabled}
            onChange={onChange}
            defaultValue='Female'
            options={options} />
    );
};

export { GenderSelector };





