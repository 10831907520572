import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import { getMoment } from "Libs/NwMoment";
import styled from "styled-components";

import CustomSelect from 'Components/Gui/NwForm/CustomSelect';
import { getNow } from "Libs/NwMoment";

const FilterDateInputStyle = styled.div`
`

const FilterDateInput = ({ value, disabled, allowClear, onChange, style }) => {

    const [showDatePicker, setShowDatePicker] = useState(false);
    const filterValueDatePicker = () => {
        if (value) {
            if (typeof value == "string") {
                const match = value.match(/\{(\w+)\}/);
                if (!match) {
                    return getMoment(value);
                }
            } else {
                return getMoment(value);
            }
        }
        return null;
    };

    useEffect(() => {
        if (value) {
            if (typeof value == "string") {
                const match = value.match(/\{(\w+)\}/);
                if (!match) {
                    setShowDatePicker(true);
                }
            } else {
                setShowDatePicker(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterValueSelect = () => {
        if (value && typeof value === "string") {
            const match = value.match(/\{(\w+)\}/);
            if (match) {
                return value;
            } else {
                return "{others}";
            }
        } else if (value) {
            return "{others}";
        } else {
            return null;
        }
    };

    const handleSelectChange = val => {
        if (!val) {
            onChange();
            if (showDatePicker) {
                setShowDatePicker(false);
            }
        } else if (val === "{others}") {
            onChange(getNow());
            setShowDatePicker(true);
        } else {
            onChange(val);
        }
    };

    const handleDatePickerChange = date => {
        if (!date) {
            setShowDatePicker(false);
        }
        onChange(date ? getMoment(date) : "{today}");
    };

    const dateOptions = [
        { value: '{today}', label: 'Today' },
        { value: '{tomorrow}', label: 'Tomorrow' },
        { value: '{yesterday}', label: 'Yesterday' },
        { value: '{amonthago}', label: 'A month ago' },
        { value: '{aweekago}', label: 'A week ago' },
        { value: '{ayearago}', label: 'A year ago' },
        { value: '{others}', label: 'Choose Date' },
    ]
    

    return (
        <FilterDateInputStyle style={style}>
            <CustomSelect
                width="100%"
                value={filterValueSelect(value)}
                onChange={val => handleSelectChange(val)}
                disabled={disabled}
                allowClear={allowClear}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                options={dateOptions}
                placeholder="Select a date"
            />
            {
                showDatePicker &&
                <DatePicker
                    autoComplete='off'
                    style={{ width: "100%" }}
                    disabled={disabled}
                    format="MMM DD, YYYY"
                    value={filterValueDatePicker(value)}
                    onChange={val => handleDatePickerChange(val)}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                />
            }
        </FilterDateInputStyle>
    );
};

export default FilterDateInput;
