import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { NWInput, NWTextAreaCharCounter, NWSelect, NWDepartmentSelector, NWBookerSelector } from 'Components/Gui/NwForm/NwFormItems'; //NWEventDateTimePicker
import { NwCancelButton, NwSaveButton } from 'Components/Gui/NwButton';
//import FlexContainer from 'Components/Gui/FlexContainer';
import ProjectPrevisionalFees from 'Components/ProjectAddSelectForm/ProjectPrevisionalFees';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
//import { validateDates } from 'Libs/NwUtils';
import { ListContext } from 'Contexts/ListContext';
import { useAddProject } from 'Hooks/Project/UseProject';
import { useAddPrevisionalTransaction } from 'Hooks/Project/UsePrevisionalTransactions';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { UserContext } from 'Contexts/UserContext';
//import useUserDepartments from 'Hooks/UseUserDepartments';

const ProjectFormContainer = styled.div`
    small {
        text-transform: uppercase;
        color: ${props => props.theme.TextColorMuted};
    }
    p.main-client {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.25rem;
        margin-bottom: 1rem;
    }
`

const AddProjectForm = ({ customer, type, onSelectProject, onTouchForm, onCancel }) => {
    const { mutateAsync: addProject } = useAddProject();
    const { mutateAsync: addPrevisionalTransaction } = useAddPrevisionalTransaction();
    const { cachedList } = useContext(ListContext);
    const [transactions, setTransactions] = useState([]);
    const { currentUser } = useContext(UserContext);
    
    const initialValues = {
        Name: getContactName(customer.ID),
        Description: '',
        DepartmentCreated: currentUser.ActiveContext.depId,
        UserCreated: currentUser.UserID,
        Product: '',
        PrevisionalDate: null,
        CustomerID: customer.ID,
        ProjectCategoryID: undefined,
    };

    let validationSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required"),
        CustomerID: Yup.string().required("Customer is required"),
        UserCreated: Yup.string().required("Booker is required"),
    });

    const handleAddTransaction = transaction => {
        setTransactions(prevTransactions => [...prevTransactions, transaction]);
    };

    const submitForm = async (values, setStatus, setSubmitting) => {
        let newValues = {
            Name: values.Name ,
            Description: values.Description,
            Product: values.Product,
            CustomerID: values.CustomerID,
            ProjectCategoryID: values.ProjectCategoryID,
            DepartmentCreated: values.DepartmentCreated,
            UserCreated: values.UserCreated
        };        
        setSubmitting(true);
        try {
            const project = await addProject({ data: newValues });
            if (onTouchForm) {
                onTouchForm(false);
            }
            
            for (const transaction of transactions) {
                const transactionData = {
                    ...transaction,
                    ProjectID: project.ID 
                };
                await addPrevisionalTransaction({ transactionData });
            }
            setSubmitting(false);
            onSelectProject(project.ID);
        } catch (error) {
            console.log("errore", error);
            if (onTouchForm) {
                onTouchForm(false);
            }
            setSubmitting(false);
        }
    };

    const categories = useMemo(() => {
        let categories = [...cachedList.projectCategories.items];
        categories.sort((cat1, cat2) => cat1.Name.toLowerCase() > cat2.Name.toLowerCase() ? 1 : -1);
        return categories;
    }, [cachedList.projectCategories.items]);

    return (
        <ProjectFormContainer>
            <small>PROJECT MAIN CLIENT</small>
            <p className="main-client">{customer.N}</p>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                //enableReinitialize={true}
                onSubmit={(values, {setStatus, setSubmitting}) => {
                    setStatus();
                    submitForm(values, setStatus, setSubmitting);
                }}>
                {({ values, handleSubmit, isSubmitting }) => (
                    <>
                    <NwForm
                        values={values} 
                        onTouchForm={onTouchForm}
                        onFinish={handleSubmit} 
                        layout="horizontal">
                            
                        <Field
                            component={NWInput}
                            label="Title"
                            name="Name"
                            type="text"
                            value={values.Name}
                        />
                        <Field
                            component={NWInput}
                            label="Product"
                            name="Product"
                            type="text"
                            value={values.Product}
                        />
                        <Field
                            component={NWSelect}
                            label="Project Category"
                            name="ProjectCategoryID"
                            placeholder='Select a category'
                            value={values.ProjectCategoryID}                
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            options={
                                categories.map(category => {
                                    return { key: category.ID, label: category.Name, value: category.ID };
                                })}
                        />
                        <Field
                            component={NWDepartmentSelector}
                            label="Department"
                            name="DepartmentCreated"
                            value={values.DepartmentCreated}
                            activeContext
                        />
                        <Field
                            component={NWTextAreaCharCounter}
                            label="Description"
                            name="Description"
                            type="text"
                            value={values.Description}
                            maxLength={2000}
                            autoSize={{ minRows: 2, maxRows: 10 }}
                        />
                        <Field
                            component={NWBookerSelector}
                            label="Booker"
                            name="UserCreated"
                            disableClosable
                            required
                            onlyInNetworkAgency={currentUser.ActiveContext.nwAgId}
                            value={values.UserCreated}
                        />
                        
                        {type === 'Casting' && 
                            <ProjectPrevisionalFees 
                                onTouchForm={onTouchForm}
                                transactions={transactions} 
                                onAddTransaction={handleAddTransaction} />
                        }
                        <br/>
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        if (onTouchForm) {
                                            onTouchForm(false);
                                        }
                                        onCancel();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    //onClick={() => submitForm()}
                                    htmlType="submit"
                                    label='create' 
                                    disabled={isSubmitting} 
                                    loading={isSubmitting}
                                    loadingLabel='creating'
                                />
                            }
                        />
                    </NwForm>
                    </>
                )}
            </Formik>
        </ProjectFormContainer>
    )
};

export default AddProjectForm