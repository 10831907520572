import React from "react";
import { getMoment } from "Libs/NwMoment";
import * as Yup from "yup";

import EditEvent from "Components/EventDrawer/EditEvent";
import {
    NWInput,
    NWSelectTransport,
    NWContactDropdown,
    NWProjectSelector,
    NWTextAreaCharCounter,
    NWLocationDateSelector,
    NWSwitch
} from "Components/Gui/NwForm/NwFormItems";
import {
    useUpdateTravel,
    useAddTravel,
    useUpdateGroupedIndividualTravel,
    useUpdateGroupedCommonTravel
} from 'Hooks/Event/UseTravel';
import { getTime, isSameDate, timeArrayToString, isAllDay, getNow } from 'Libs/NwMoment';
import { getEndTime } from 'Libs/NwUtils';

const validateLocationDate = value => {
    let error;
    if (value.Place === '') {
        error = 'Place is required';
    }
    return error;
};

const validateForm = (values, props) => {
    const errors = {};

    if (values.Departure.Date > values.Arrival.Date) {
        errors.Arrival = 'Arrival date should be after than departure date';
    }
    return errors;
};

const TravelForm = ({ 
    afterUpdate, 
    allDay,
    dateSelected,
    editModelEvent,
    modelId, 
    onCancel,
    onTouchForm,
    onUpdateTravelData,
    projectId, 
    travelsData,
    viewMode, 
}) => {
    const { mutateAsync: onAddTravel } = useAddTravel();
    const { mutateAsync: onUpdateTravel } = useUpdateTravel();
    const { mutateAsync: onUpdateGroupedIndividualTravel } = useUpdateGroupedIndividualTravel();
    const { mutateAsync: onUpdateGroupedCommonTravel } = useUpdateGroupedCommonTravel();
    
    const submitForm = async (values, setStatus, setSubmitting) => {
        if (!travelsData) {
            //prepare data to create a new travel
            const newTravelData = {
                ...values,
                StartLocation: values.Departure.Place,
                StartDateTime: values.Departure.Date,
                ArrivalLocation: values.Arrival.Place,
                ArrivalDateTime: values.Arrival.Date
            }
            try {
                const newTravel = await onAddTravel({ travelData: newTravelData });
                if (afterUpdate){
                    afterUpdate({
                        ...newTravel,
                        TypeName: "Travel"
                    });
                }
                onTouchForm(false);
                onUpdateTravelData(newTravel.ID);
                onCancel();
            } catch (error) {
                console.log("ant : Create Travel Error", error);
            }
            setSubmitting(false);
        } 
        else {
            let travelToEdit = {}
            try {
                if (travelsData.GroupGuid) {
                    if (editModelEvent) {
                        //prepare data to update grouped travel - data for a single model
                        travelToEdit.ID = travelsData.ID
                        travelToEdit.Description = values.Description
                        travelToEdit.BookingReference = values.BookingReference
                        travelToEdit.TicketID = values.TicketID
                        travelToEdit.TransportReference = values.TransportReference
                        travelToEdit.ChangeOnAllModels = values.ChangeOnAllModels;
                        await onUpdateGroupedIndividualTravel({ travelData: travelToEdit })
                    } else {
                        //prepare data to update grouped travel - general data
                        travelToEdit.GroupGuid = travelsData.GroupGuid
                        travelToEdit.Title = values.Title
                        travelToEdit.ServiceID = values.ServiceID
                        travelToEdit.Transport = values.Transport
                        travelToEdit.StartLocation = values.Departure.Place
                        travelToEdit.StartDateTime = values.Departure.Date
                        travelToEdit.ArrivalLocation = values.Arrival.Place
                        travelToEdit.ArrivalDateTime = values.Arrival.Date
                        await onUpdateGroupedCommonTravel({ travelData: travelToEdit })
                    }
                } else {
                    travelToEdit.ID = travelsData.ID
                    travelToEdit.ModelID = travelsData.ModelID
                    travelToEdit.Title = values.Title
                    travelToEdit.Transport = values.Transport
                    travelToEdit.StartLocation = values.Departure.Place
                    travelToEdit.StartDateTime = values.Departure.Date
                    travelToEdit.ArrivalLocation = values.Arrival.Place
                    travelToEdit.ArrivalDateTime = values.Arrival.Date
                    travelToEdit.Description = values.Description
                    travelToEdit.ServiceID = values.ServiceID
                    travelToEdit.BookingReference = values.BookingReference
                    travelToEdit.TicketID = values.TicketID
                    travelToEdit.TransportReference = values.TransportReference
                    await onUpdateTravel({ travelData: travelToEdit })
                }
                onTouchForm(false);
                if (afterUpdate) {
                    afterUpdate();
                }
                onUpdateTravelData();
                if (!viewMode || viewMode === 'create') {
                    onCancel();
                }
            } catch (error) {
                console.log("ant : Update Travel Error", error);
            }
            setSubmitting(false);
        }
    };

    const departureDate = 
        (dateSelected 
        ? 
            (allDay 
            ? 
                getMoment(dateSelected).startOf('day') 
            : 
                getMoment(dateSelected)
            )
        : 
            getNow().hour(9).minute(0)
        )
        
    let initialValues = {
        Title: travelsData ? travelsData.Title : '',
        ModelID: travelsData ? travelsData.ModelID : modelId,
        StartDateTime: travelsData ? getMoment(travelsData.StartDateTime) : null,
        ArrivalDateTime: travelsData ? getMoment(travelsData.ArrivalDateTime) : null,
        BookingReference: travelsData ? travelsData.BookingReference : '',
        TicketID: travelsData ? travelsData.TicketID : '',
        TransportReference: travelsData ? travelsData.TransportReference : '',
        Transport: travelsData ? travelsData.Transport : 'Plane',
        ServiceID: travelsData ? travelsData.ServiceID : null,
        StartLocation: travelsData ? travelsData.StartLocation : '',
        ArrivalLocation: travelsData ? travelsData.ArrivalLocation : '',
        ProjectID: travelsData && travelsData.Project ? travelsData.Project.ID : projectId,
        Description: travelsData && travelsData.Description ? travelsData.Description : '',
        Departure: travelsData ? {
            Place: travelsData.StartLocation,
            Date: getMoment(travelsData.StartDateTime),
            allDay: isAllDay(travelsData.StartDateTime, travelsData.ArrivalDateTime) ? true : false,
            disableTime: isAllDay(travelsData.StartDateTime, travelsData.ArrivalDateTime) ? true : false
        } : {
            Place: '',
            Date: departureDate,
            allDay: dateSelected && allDay ? true : false,
            disableTime: dateSelected && allDay ? true : false
        },
        Arrival: travelsData ? {
            Place: travelsData.ArrivalLocation,
            Date: getMoment(travelsData.ArrivalDateTime),
            disableTime: isAllDay(travelsData.StartDateTime, travelsData.ArrivalDateTime) ? true : false,
            beforeTime: isSameDate(travelsData.StartDateTime, travelsData.ArrivalDateTime) && timeArrayToString(getTime(travelsData.StartDateTime)),
            beforeDate: getMoment(travelsData.StartDateTime)
        } : {
            Place: '',
            Date: 
                (dateSelected 
                ? 
                    (allDay
                    ? 
                        getMoment(dateSelected).endOf('day')
                    : 
                        getMoment(dateSelected).add(2, 'hours')
                    )
                :
                    getNow().hour(11).minute(0)
                ),
            disableTime: dateSelected && allDay ? true : false,
            beforeTime: null,
            beforeDate: getMoment(departureDate)
        },
        ChangeOnAllModels: true
    };

    let generalDataSource = [
        {
            component: NWSelectTransport,
            label: 'Transport',
            name: 'Transport',
            required: true
        },
        {
            component: NWContactDropdown,
            label: "Service",
            activeTypes: ["srv"],
            activableTypes: ["cli"],
            name: "ServiceID"
        },
        {
            component: NWLocationDateSelector,
            label: 'Departure',
            name: 'Departure',
            Col: 24,
            allDaySelectable: true,
            onAfterChange: (values, setFieldValue, value) => {
                if (values.Departure.allDay !== value.allDay) {
                    if (value.allDay) {
                        setFieldValue('Arrival', {
                            ...values.Arrival,
                            Date: getMoment(values.Arrival.Date).endOf('day'),
                            disableTime: true,
                        })
                        setFieldValue('Departure', {
                            ...values.Departure,
                            Date: getMoment(values.Departure.Date).startOf('day'),
                            disableTime: true,
                            allDay: true
                        })
                    } else {
                        setFieldValue('Arrival', {
                            ...values.Arrival,
                            Date: getMoment(values.Arrival.Date).hour(11).minute(0),
                            disableTime: false,
                            beforeTime: "09:00"
                        })
                        setFieldValue('Departure', {
                            ...values.Departure,
                            Date: getMoment(values.Departure.Date).hour(9).minute(0),
                            disableTime: false,
                            allDay: false,
                        })
                    }
                } else if (values.Departure.Date !== value.Date) {
                    if (!isSameDate(value.Date, values.Departure.Date)) {
                        console.log('ant : value date => ', value.Date.hour());
                        const endTime = getEndTime(getTime(value.Date), value.Date.hour() >= 22 ? [23, 59] : getTime(value.Date.clone().add(2, 'hours')), getTime(value.Date));

                        setFieldValue('Arrival', {
                            ...values.Arrival,
                            Date: 
                                (values.Departure.allDay
                                ?
                                    getMoment(value.Date).hour(23).minute(59)
                                :
                                    getMoment(value.Date).hour(parseInt(endTime / 60)).minute(endTime % 60)
                                )
                            ,
                            beforeTime: timeArrayToString(getTime(value.Date)),
                            beforeDate: value.Date
                        });
                    } else {
                        if (isSameDate(value.Date, values.Arrival.Date)) {
                            const endTime = getEndTime(getTime(values.Departure.Date), getTime(values.Arrival.Date), getTime(value.Date));
                            setFieldValue('Arrival', {
                                ...values.Arrival,
                                Place: values.Arrival.Place,
                                Date: getMoment(value.Date).hour(parseInt(endTime / 60)).minute(endTime % 60),
                                beforeTime: timeArrayToString(getTime(value.Date)),
                                beforeDate: value.Date
                            })
                        } else {
                            setFieldValue('Arrival', {
                                ...values.Arrival,
                                beforeDate: value.Date
                            })
                        }
                    }
                }
                if (values.Arrival.Place || value.Place) {
                    setFieldValue('Title', `${value.Place} to ${values.Arrival.Place}`)
                }
            },
            validate: validateLocationDate
        },
        {
            component: NWLocationDateSelector,
            label: 'Arrival',
            name: 'Arrival',
            Col: 24,
            onAfterChange: (values, setFieldValue, value) => {
                if (values.Arrival.Date !== value.Date) {
                    if (!isSameDate(value.Date, values.Departure.Date)) {
                        setFieldValue('Arrival', {
                            ...values.Arrival,
                            Date: value.Date,
                            beforeTime: null
                        });
                    }
                } 
                //if (!values.Title && (values.Arrival.Place || value.Place)) {
                if (values.Departure.Place || value.Place) {
                    setFieldValue('Title', `${values.Departure.Place} to ${value.Place}`)
                }
            },
            validate: validateLocationDate
        },
        {
            component: NWInput,
            label: 'Title',
            name: 'Title',
            type: 'text',
            Col: 24
        }
    ];

    let modelDataSource = [
        {
            component: NWInput,
            label: 'Ticket #',
            name: 'TicketID',
            type: 'text',
            Col: 8,
            maxWidth: 200
        },
        {
            component: NWInput,
            label: 'Booking #',
            name: 'BookingReference',
            type: 'text',
            Col: 8,
            maxWidth: 200
        },
        {
            component: NWInput,
            label: 'Flight or Train Code',
            name: 'TransportReference',
            type: 'text',
            Col: 8,
            maxWidth: 100
        },
        {
            component: NWTextAreaCharCounter,
            label: 'Description',
            name: 'Description',
            type: 'text',
            maxLength: 2000,
            Col: 24,
            autoSize: { minRows: 6, maxRows: 6 }
        },
    ];

    let dataSource = editModelEvent ? [...modelDataSource] : travelsData && travelsData.GroupGuid ? [...generalDataSource] : [...generalDataSource, ...modelDataSource]; 

    if (!travelsData) {
        dataSource.splice(0, 0, {
            component: NWProjectSelector,
            name: 'ProjectID',
            readOnly: projectId ? true : false,
            Col: 24
        });
    }

    if (!modelId && !travelsData) {
        dataSource.splice(1, 0, {
            component: NWContactDropdown,
            label: "Model",
            activeTypes: ["mod"],
            activableTypes: [],
            name: "ModelID",
            required: true,
            Col: 24
        });
    }

    if (editModelEvent) {
        dataSource.push({
            component: NWSwitch,
            text: 'apply these changes on all models in this travel',
            name: 'ChangeOnAllModels',
            Col: 24
        });
    }

    let validationSchema = editModelEvent ? null : Yup.object().shape({
        Title: Yup.string().required("Title is required"),
        Transport: Yup.string().required("Transport is required"),
        ModelID: Yup.string().required("Model is required"),
    });

    return (
        <EditEvent 
            submitForm={submitForm} 
            mg={0}
            validationSchema={validationSchema} 
            dataSource={dataSource} 
            initialValues={initialValues}
            onCancel={onCancel}
            validate={validateForm}
            disableReinitialize
            onTouchForm={onTouchForm}
        />
    );
};

export default React.memo(TravelForm);
