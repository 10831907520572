import React from 'react';
import { Input } from 'antd'; 
import styled from 'styled-components';

import FlexContainer from 'Components/Gui/FlexContainer';
import DateSelector from 'Components/Gui/NwForm/DateSelector';
import CustomSwitch from 'Components/Gui/CustomSwitch';

const Title = styled.div`
    min-width: 100px;
    margin-right: 8px;
`;

const LocationDateSelector = ({ value, allDaySelectable, onChange }) => {
    const handlePlaceChange = event => {
        onChange({
            ...value,
            Place: event.target.value,
            Date: value.Date
        });
    };

    const handleDateChange = date => {
        onChange({
            ...value,
            Date: date
        })
    };

    const handleChangeAllDay = allDay => {
        onChange({
            ...value,
            allDay: allDay
        });
    }

    return (
        <>
            <FlexContainer mg='0 4px 4px 0'>
                <Title>Place</Title>
                <Input autoComplete='off' value={value ? value.Place : ''} defaultValue='' onChange={handlePlaceChange} />
            </FlexContainer>
            <FlexContainer>
                <DateSelector 
                    text='Date/Time' 
                    labelMinWidth={100}
                    dateOnly={value.disableTime} 
                    beforeTime={value.beforeTime} 
                    value={value.Date} 
                    beforeDate={value.beforeDate}
                    onChange={handleDateChange} />
                &nbsp;
                {allDaySelectable && 
                    <CustomSwitch value={value.allDay} text='all day' onChange={handleChangeAllDay} />
                }
            </FlexContainer>
        </>
    );
};

export default LocationDateSelector;