import React, { useState } from 'react';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import EventDrawer from 'Components/EventDrawer/EventDrawer';
import AddEventDropdownContent from './AddEventDropdownContent';
import { Popover } from 'antd';
import { getMoment } from 'Libs/NwMoment';
import NwIcon from 'Components/Gui/NwIcon';


const Wrapper = styled.div`
`

const AddEventDropdown = ({
    date,
    modelID,
    onAddEvent
}) => {
    const [isOpenDrawer, setOpenDrawer] = useState(false);
    const [eventType, setEventType] = useState();
    const [open, setOpen] = useState(false);

    const afterRemove = () => {
        onAddEvent();
        setOpenDrawer(false);
        setEventType();
    };

    const handleCreateEvent = type => {
        setEventType(type);
        setOpen(false);
        setOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    return (
        <Wrapper>
            <Popover
                zIndex={9999}
                overlayClassName='folder-popover'
                placement='bottom'
                trigger='click'
                open={open}
                onOpenChange={handleOpenChange}
                content={
                    <AddEventDropdownContent
                        onCreateEvent={handleCreateEvent}
                        modelID={modelID}
                    />
                }
            >
                {open
                    ?
                    <NwIcon icon={light('times')} pointer />
                    :
                    <NwIcon icon={light('circle-plus')} pointer />
                }
            </Popover>
            {isOpenDrawer
                ?
                <EventDrawer
                    afterRemove={afterRemove}
                    afterUpdate={onAddEvent}
                    allDay={true}
                    dateSelected={getMoment(date)}
                    eventInfo={eventType.eventInfo}
                    eventType={eventType.type}
                    modelId={modelID}
                    onClose={handleCloseDrawer}
                    show={true}
                    viewMode='create'
                />
                :
                null
            }
        </Wrapper>
    )
}

export default AddEventDropdown 