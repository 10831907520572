import React from "react";
import styled from "styled-components";
import { Formik, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";

import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NWInput } from "Components/Gui/NwForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import {useEditContactConnectionItem, useAddContactConnectionItem} from 'Hooks/Contact/UseContactConnections';
import StyleVariables from "Components/Gui/StyleVariables";

const AddCCButton = styled.div`
  font-size: 14px;
  color: ${StyleVariables.PrimaryColor};
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
  margin-bottom: 40px;
`
const ErrorMessageStyle = styled.div`
  color: ${StyleVariables.DangerColor};
  margin: -10px 0 10px 0;
  font-size: .75rem;
  text-transform: uppercase;
`

const EmailForm = ({ email, connectionID, contactID, onCancel, onSave, onTouchForm }) => {
  const initialValues = {
    Address: "",
    CC: [{email: ""}]
  };

  const { mutateAsync: editEmail } = useEditContactConnectionItem() 
  const { mutateAsync: addEmail } = useAddContactConnectionItem()

  if (email) {
    initialValues.ID = email.ID
    initialValues.Address = email.Address
    initialValues.CC = (email.CC && email.CC.length > 0) ? email.CC.map(cc => ({email: cc})) : [{email: ""}]
  }

  const submitForm = async (values, setSubmitting) => {
    const ID = initialValues.ID;
    const data = {
      ConnectionID: connectionID,
      Address: values.Address,
      CC: values.CC.filter(cc => cc.email !== "").map(cc => cc.email)
    };
    if (ID) {
      data.ID = ID;
      await editEmail({itemType:"emails", data: data, contactID: contactID})
      if (onTouchForm) {
        onTouchForm(false);
      }
      setSubmitting(false)
      onSave()
    } else {
      const email = await addEmail({itemType:"emails", data: data, contactID: contactID})
      if (onTouchForm) {
        onTouchForm(false);
      }
      setSubmitting(false)
      onSave(email)
    }
  };

  return (
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          Address: Yup.string().required("Content is required").email("Invalid email format"),
          CC: Yup.array()
            .of(
              Yup.object().shape({
                email: Yup.string().email("Invalid email format"),
              })
            )
        })}
        validateOnBlur={true}
        validateOnChange={false}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          submitForm(values, setSubmitting);
        }}
        render={({ isSubmitting, values, handleSubmit, isValid }) => (
          <NwForm 
            values={values}
            onTouchForm={onTouchForm}
            noValidate 
            onFinish={handleSubmit} 
            layout="vertical">
            {/* Address */}
            <Field
              component={NWInput}
              label="E-mail address"
              name="Address"
              type="text"
              value={values.Address}
            />
            <FieldArray
              name="CC"
              render={arrayHelpers => (
                <div>
                  {(values.CC && values.CC.length > 0) &&
                    values.CC.map((cc, index) => (
                      <div key={index}>
                        <Field
                          label={index === 0 ? "E-mail CC" : ""}
                          component={NWInput}
                          name={`CC.${index}.email`}
                          type="text"
                        />
                        <ErrorMessageStyle>
                          <ErrorMessage name={`CC.${index}.email`}/>
                        </ErrorMessageStyle>
                      </div>
                    ))
                  }
                  <AddCCButton onClick={() => arrayHelpers.push({email : ""})}>+ add another cc address</AddCCButton>
                </div>
              )}
            >

            </FieldArray>
            <NwFormButtonsBar
              left={
                <NwCancelButton
                  disabled={isSubmitting}
                  onClick={() => {
                    onTouchForm(false);
                    onCancel();
                  }}
                />
              }
              right={
                <NwSaveButton
                    onClick={handleSubmit}
                    disabled={!isValid}
                    loading={isSubmitting}
                />
              }
            />
          </NwForm>
        )}
      />
  );
};

export default EmailForm;
