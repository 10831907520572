import React, { useEffect, useState } from 'react';
import { Row, Col, Alert } from 'antd';
import { Field } from "formik";
import { getMoment, isArrayTimesAllDay } from "Libs/NwMoment";
import styled from 'styled-components';
import { NWContactDropdown } from "Components/Gui/NwForm/NwFormItems";
import EventDateModel from 'Components/EventDrawer/BookingEvent/AddEventDate/EventDateModel';
import FlexContainer from 'Components/Gui/FlexContainer';
import CustomDatePicker from 'Components/EventDateTimePicker/CustomDatePicker';
import { timeStringToArray, toISODate } from 'Libs/NwMoment';
import SuggestedTimes from './SuggestedTimes';
import NwDivider from 'Components/Gui/NwDivider';
import { NwLinkButton } from 'Components/Gui/NwButton';
import TimeRangePicker from 'Components/EventDateTimePicker/TimeRangePicker';
import DoubleTimeRangePicker from 'Components/EventDateTimePicker/DoubleTimeRangePicker';

const FieldContainer = styled.div`
    margin-bottom: 16px;
    margin-top: 15px;
`;

const EndDateContainer = styled.div`
    .disabled-end-date {
        background: #d9d9d9;
        opacity: 0.7;
    }
`;

const TimeContainer = styled.div`
    height: 35px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    flex: 1 1 0;
`;

// const ErrorMessage = styled.div`
//     color: red;
// `;

const Container = styled.div`
    .ant-row {
        margin-bottom: 16px;
    }
`;

const DateTimeContainerStyle = count => {
    // if (count > 1) {
    //     return {
    //         border: `${StyleVariables.DefaultBorder}`,
    //         padding: 8,
    //         marginBottom: 8,
    //         borderRadius: 4
    //     }
    // }
    return {}
}

const getDate = date => {
    return date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

const EventDateTime = ({
    errorMessages,
    event,
    existingDates,
    onUpdateEventDate,
    periodCount,
    periodId,
    periodModels,
    setFieldValue,
    values,
}) => {
    const [disableEndDate, setDisableEndDate] = useState(true);

    const errorMessage = errorMessages && Array.isArray(errorMessages) && (errorMessages.length > periodId) && errorMessages[periodId] ? errorMessages[periodId] : null;
    
    const [doubleTime, setDoubleTime] = useState(false);
    const [allDay, setAllDay] = useState(true);

    const hasBreak = (values) => {
        if (!values.startBreakTime || !values.endBreakTime) {
            return false;
        }
        if ((values.startBreakTime[0] === 0 && values.startBreakTime[1] === 0) || (values.endBreakTime[0] === 0 && values.endBreakTime[1] === 0)) {
            return false;
        }
        return true;
    }
    
    useEffect(() => {
        if (values.EventTime.length === 1) {
            const isOriginallyDoubleTime = hasBreak(values.EventTime[0]);
            const isOriginallyAllDay = isArrayTimesAllDay(values.EventTime[0].startTime, values.EventTime[0].endTime);
            setDoubleTime(isOriginallyDoubleTime);
            setAllDay(isOriginallyAllDay);
        }
    }, []);

    const handleAddModel = async (setFieldValue, modelID) => {
        onUpdateEventDate(periodId, {
            dateModels: {
                ...periodModels.dateModels,
                [modelID]: {
                    checked: true,
                    optionNumber: (event.Confirmed === 2) ? 10 : null
                }
            },
            dateAvailableModels: [...periodModels.dateAvailableModels, modelID]
        })
        setFieldValue(`ModelID${periodId}`, null);
    };

    const handleModelChange = (modelId, checked, optionNumber) => {
        onUpdateEventDate(periodId, {
            dateModels: {
                ...periodModels.dateModels,
                [modelId]: {
                    ...periodModels.dateModels[modelId],
                    checked: checked,
                    optionNumber: checked ? optionNumber : periodModels.dateModels[modelId].defaultOptionNumber
                }
            },
            dateAvailableModels: [...periodModels.dateAvailableModels]
        })
    };

    const handleUpdateStartDate = date => {
        setFieldValue(`Periods[${periodId}]`, [date, date]);
        if (!disableEndDate) {
            setDisableEndDate(true);
        }
    };

    const handleUpdateEndDate = date => {
        setFieldValue(`Periods[${periodId}][1]`, date);
    }

    const handleClickEndDate = () => {
        if (disableEndDate) {
            setDisableEndDate(false);
        }
    };

    const disabledEndDate = date => {
        const startDate = values[`Periods`][periodId][0];
        const days = date.diff(startDate, 'days');
        if (days >= 0 && days <= 100) {
            if (existingDates.find(disabledDate => {
                let disabled = getDate(getMoment(disabledDate));
                const selectedDate = getDate(date);
                const start = getDate(startDate);
                if ((getMoment(disabledDate).year() === date.year()
                    && getMoment(disabledDate).month() === date.month()
                    && getMoment(disabledDate).date() === date.date())
                    || (start < disabled && selectedDate > disabled)) {
                    return true;
                }
                return false;
            })) {
                return true;
            }
            if (values.Periods.find((period, index) => periodId !== index && (
                (toISODate(period[0]) <= toISODate(date) && toISODate(period[1]) >= toISODate(date))
                || (toISODate(period[0]) >= toISODate(startDate) && toISODate(period[0]) <= toISODate(date))
            ))) {
                return true;
            }
            return false;
        }
        return true;
    }

    const disabledStartDate = date => {
        if (existingDates.find(disabledDate => getMoment(disabledDate).year() === date.year()
            && getMoment(disabledDate).month() === date.month()
            && getMoment(disabledDate).date() === date.date())) {
            return true;
        }
        if (values.Periods.find((period, index) => periodId !== index && toISODate(period[0]) <= toISODate(date)
            && toISODate(period[1]) >= toISODate(date))) {
            return true;
        }
        return false;
    };

    const onSetSuggestedTime = (time) => {
        console.log('onSetSuggestedTime', time);
        setFieldValue(`EventTime[${periodId}].startTime`, timeStringToArray(time.start));
        setFieldValue(`EventTime[${periodId}].endTime`, timeStringToArray(time.end));
        setFieldValue(`EventTime[${periodId}].startBreakTime`, timeStringToArray(time.start_break));
        setFieldValue(`EventTime[${periodId}].endBreakTime`, timeStringToArray(time.end_break));
        if (time.start_break && time.end_break) {
            setDoubleTime(true);
        } else {
            setDoubleTime(false);
        }
    }

    const switchToSingleTime = () => {
        setFieldValue(`EventTime[${periodId}].startBreakTime`, [0,0]);
        setFieldValue(`EventTime[${periodId}].endBreakTime`, [0,0]);
        setDoubleTime(false);
    }

    const switchToDoubleTime = () => {
        setFieldValue(`EventTime[${periodId}].startBreakTime`, [0,0]);
        setFieldValue(`EventTime[${periodId}].endBreakTime`, [0,0]);
        setDoubleTime(true);
    }

    return (
        <Container style={DateTimeContainerStyle(periodCount)}>
            <Row gutter={16}>
                <Col span={24}>
                    <FlexContainer>
                        <div>
                            <div>From</div>
                            <CustomDatePicker
                                label='Start Date'
                                name='startDate'
                                value={values[`Periods`][periodId][0]}
                                disabledRange={disabledStartDate}
                                onChange={handleUpdateStartDate} 
                            />
                        </div>
                        <EndDateContainer>
                            <div>To</div>
                            <div onClick={handleClickEndDate}>
                                <CustomDatePicker
                                    label='End Date'
                                    name='endDate'
                                    className={disableEndDate && 'disabled-end-date'}
                                    disabledRange={disabledEndDate}
                                    value={values[`Periods`][periodId][1]}
                                    onChange={handleUpdateEndDate} 
                                />
                            </div>
                        </EndDateContainer>
                    </FlexContainer>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TimeContainer>
                        {doubleTime
                        ?
                        <>
                            <DoubleTimeRangePicker
                                fields={{
                                    startTimeFieldName: `EventTime[${periodId}].startTime`,
                                    endTimeFieldName: `EventTime[${periodId}].endTime`,
                                    startBreakFieldName: `EventTime[${periodId}].startBreakTime`,
                                    endBreakFieldName: `EventTime[${periodId}].endBreakTime`
                                }}
                                values={{
                                    startTime: values.EventTime[periodId].startTime,
                                    endTime: values.EventTime[periodId].endTime,
                                    startBreak: values.EventTime[periodId].startBreakTime,
                                    endBreak: values.EventTime[periodId].endBreakTime
                                }}
                                methods={{
                                    setFieldValue: setFieldValue
                                }}
                            />
                            <NwLinkButton label="single" onClick={switchToSingleTime} />
                        </>
                        :
                        <>
                            <TimeRangePicker
                                fields={{
                                    startTimeFieldName: `EventTime[${periodId}].startTime`,
                                    endTimeFieldName: `EventTime[${periodId}].endTime`,
                                    allDayFieldName: 'allDay'
                                }}
                                values={{
                                    startTime: values.EventTime[periodId].startTime,
                                    endTime: values.EventTime[periodId].endTime
                                }}
                                methods={{
                                    setFieldValue: setFieldValue,
                                    onSetAllDay: setAllDay
                                }}
                            />
                            {!allDay &&
                                <NwLinkButton label="dual time slot" onClick={switchToDoubleTime} />
                            }
                        </>
                        }
                    </TimeContainer>
                    {errorMessage &&
                        <Alert type="error" message={errorMessage} />
                    }
                </Col>
            </Row>
            <Row>
                <SuggestedTimes eventData={event} onSetSuggestedTime={onSetSuggestedTime} />
            </Row>
            {values[`Time`][periodId] && values[`Periods`][periodId] &&
                <>
                     <NwDivider>Models</NwDivider>
                    <FieldContainer>
                        {periodModels.dateAvailableModels.map(modelId => {
                            return (
                                <EventDateModel
                                    key={modelId}
                                    modelId={modelId}
                                    models={periodModels.dateModels}
                                    eventType={event.Type}
                                    eventId={event.ID}
                                    period={values[`Periods`][periodId]}
                                    onChange={handleModelChange}
                                />
                            )
                        })}
                    </FieldContainer>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Field
                                component={NWContactDropdown}
                                name={`ModelID${periodId}`}
                                activeTypes={['mod']}
                                value={values[`ModelID${periodId}`]}
                                exclusions={periodModels.dateAvailableModels.map(modelId => modelId)}
                                onAfterChange={value => handleAddModel(setFieldValue, value)}
                            />
                        </Col>
                    </Row>
                </>
            }
        </Container>
    );
};

export default EventDateTime;