import React from "react";

import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";

const PrivacyLevelSelector = ({
    defaultValue = 1,
    disabled, 
    hasDepartment, 
    hasNetworkAgency, 
    hasPersonal = true,
    hasPublic = true,
    onChange, 
    value, 
}) => {

    const privacyoptions = []
    if (hasPublic) {
        privacyoptions.push({ label: 'Public', value: 'Public' })
    }
    if (hasNetworkAgency) {
        privacyoptions.push({ label: 'Network Agency', value: 'NetworkAgency' })
    }
    if (hasDepartment) {
        privacyoptions.push({ label: 'Department', value: 'Department' })
    }
    if (hasPersonal) {
        privacyoptions.push({ label: 'Personal', value: 'User' })
    }

    return (
        <RadioButtonGroup 
            disabled={disabled} 
            value={value} 
            defaultValue={defaultValue} 
            onChange={onChange}
            options={[ ...privacyoptions ]} />
    );
};

export default PrivacyLevelSelector;





