import React, { useState, useEffect } from 'react';
import { Tabs, Row, Col, Tag, Popover } from 'antd';
import styled from 'styled-components';
import '@ant-design/compatible/assets/index.css';
import Axios from 'axios';
import * as Yup from "yup";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getMoment } from "Libs/NwMoment";

import { NwButton, NwCancelButton } from 'Components/Gui/NwButton';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwDivider from 'Components/Gui/NwDivider';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import ConnectionDetailForm from 'Components/Connection/ConnectionDetailForm';

import { Field, Formik } from "formik";
import {
    NWTextAreaCharCounter,
    NWInput,
    NWSwitch,
    NWDatePicker,
    NWProjectSelector,
    NWSignatureSelector
} from 'Components/Gui/NwForm/NwFormItems';

import EmailSearch from 'Components/SendDrawer/EmailSearch';
import MailingLists from 'Components/SendDrawer/MailingLists';
import StyleVariables from 'Components/Gui/StyleVariables';
//import useComponentDimensions from 'Hooks/UseComponentDimensions';

import { SendDrawerContainer, SendDrawerTabs } from 'Components/SendDrawer/SendDrawerContainer';
import Folders from 'Components/SendDrawer/Folders';
//import useDimensions from 'Hooks/UseDimensions';
import { useUpdatePackage } from 'Hooks/Packages/UsePackages';
import PackageShareLink from 'Components/PackageDrawer/PackageShareLink';
import { datePickerBeforeOnChange, getNow } from "Libs/NwMoment";

const { TabPane } = Tabs;

const TagHover = styled(Tag)`
    user-select: none;
    cursor: pointer;
    &:hover {
        background-color: ${StyleVariables.PrimaryColor} !important;
    }
`;
const PopoverContent = styled.div`
    color: white;
    .email-item {
        padding: 5px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        .email-text {
            margin-right: 20px;
        }
        .remove-email {
            margin-left: auto;
            cursor: pointer;
            user-select: none;
        }
    }
`;

const SendDrawer = ({
    // searchBox = true,
    afterSend,
    currentPackage,
    existingEmails,
    hasMessage = false,
    hasSubject = false,
    isSingleSend = false,
    mailingLists = true,
    messageDefault = "",
    newContact = true,
    onCancel,
    onRemoveRecipients,
    onSelectMultipleRecipients,
    onSelectRecipient,
    onSend,
    onUpdatePackage,
    recipientsBox = false,
    sendBox = false,
    subjectDefault = "",
    suggestions = [],
    textsBox = false,
    twoColumns
}) => {


    // const divRef = useRef(null);
    //const { height } = useDimensions(divRef)
    const { mutateAsync: onUpdatePackagewithProject } = useUpdatePackage()
    const [selectedEmails, setSelectedEmails] = useState([])
    const [drawerShareLink, setDrawerShareLink] = useState(null)

    useEffect(() => {
        if (existingEmails && existingEmails.length) {
            setSelectedEmails([...existingEmails])
        }
        else {
            setSelectedEmails([])
        }
    }, [existingEmails]);

    const [recommendEmails, setRecommendEmails] = useState(suggestions);

    const [tab, setTab] = useState('search');
    // const [touchForm, setTouchForm] = useState(false);

    const handleUpdateRecommendEmails = emails => {
        setRecommendEmails(emails);
    };

    const handleGetRecommendEmails = async filter => {
        try {
            const response = await Axios.get(`/connections/emails/search/${filter}`);
            setRecommendEmails(response);
        } catch (error) {
            console.log('ant : Get recommend emails error => ', error);
        }
    };

    const handleAddEmail = async value => {
        //const addedEmail = recommendEmails.find(email => email.Email === value.Email)
        if (recipientsBox) {
            const isduplicate = (selectedEmails.length > 0) && selectedEmails.find(email => email.Email === value.Email)
            if (!isduplicate) {
                if (onSelectRecipient) {
                    await onSelectRecipient(value)
                    if (tab === 'new-contact') {
                        setTab('search')
                    }
                } else {
                    //only update internal state
                    setSelectedEmails([...selectedEmails, value])
                }
            }
        } else {
            await onSelectRecipient(value)
            if (tab === 'new-contact') {
                setTab('search')
            }
        }
    };

    const handleAddMailingList = async emails => {
        if (recipientsBox && selectedEmails.length > 0) {
            emails = emails.filter(item => !selectedEmails.find(email => email.Email === item.Email))
        }
        if (emails.length) {
            try {
                if (onSelectMultipleRecipients) {
                    await onSelectMultipleRecipients(emails)
                } else {
                    setSelectedEmails([...selectedEmails, ...emails])
                    //console.log("no method passed for multiple items selection")
                }
            } catch (error) {
                console.log('ant : Add Multi Recipients Error => ', error)
            }
        }
    }

    const handleRemoveEmail = async email => {
        //if (DrawerRecipientsBox.onRemove) {
        if (onRemoveRecipients) {
            //await DrawerRecipientsBox.onRemove(email)
            await onRemoveRecipients(email)
        } else {
            //only update internal state
            setSelectedEmails([...selectedEmails.filter((item) => item.Email !== email)])
        }
    }

    const handleCreateConnection = connection => {
        handleAddEmail({
            ...connection.Emails[0],
            Email: connection.Emails[0].Address
        });
    };

    const handleShareLink = () => {
        //TODO: api call to set package as sent
        setDrawerShareLink(true)
    }

    const handleConnection = (connection, connectionIds) => {
        if (connectionIds.EmailID) {
            const email = connection.Emails.find(email => email.ID === connectionIds.EmailID);
            if (email) {
                handleAddEmail({
                    ...email,
                    Email: email.Address
                });
            }
        }
    };

    const handleDrawerSubmit = async (values, setSubmitting) => {
        setSubmitting(true);
        try {
            if (isSingleSend) {
                await onUpdatePackagewithProject({
                    data: {
                        ID: currentPackage.ID,
                        Guid: currentPackage.Guid,
                        ProjectID: values.ProjectID,
                        isInteractive: values.IsInteractive,
                        ExpirationDate: values.ExpirationDate,
                        Name: currentPackage.Name
                    }
                });
            }
            await onSend(values.Subject, values.Message, selectedEmails, values.SignatureID)
            // setTouchForm(false)
            setSubmitting(false)
            afterSend()
        } catch (error) {
            setSubmitting(false)
            afterSend()
        }
    }

    const initialValues = isSingleSend ? {
        Subject: subjectDefault,
        Message: messageDefault,
        ProjectID: null,
        IsInteractive: true,
        ExpirationDate: getNow().add(1, 'months').format(),
        SignatureID: null
    } : {
        Subject: subjectDefault,
        Message: messageDefault
    };

    const onRemoveCC = async (recipient, email) => {
        try {
            await Axios.put('/PackageRecipients/RemoveCC', {
                ID: recipient.ID,
                CC: email,
            });
            onUpdatePackage();
        } catch (e) {

        } finally {

        }
    }

    return (
        <>
            <SendDrawerContainer $isSingleSend={isSingleSend} $twoColumns={twoColumns}>
                <div className="search-section">
                    <SendDrawerTabs
                        onChange={setTab}
                        activeKey={tab}
                    >
                        <TabPane
                            key={'search'}
                            tab='Search'>
                            <EmailSearch
                                options={recommendEmails}
                                selectedItems={selectedEmails}
                                addable={true}
                                onSetOptions={handleUpdateRecommendEmails}
                                onGetOptions={handleGetRecommendEmails}
                                onAddItem={handleAddEmail}
                                fullHeight={!recipientsBox || twoColumns}
                            />
                        </TabPane>
                        {mailingLists &&
                            <TabPane
                                key='mailing-lists'
                                tab='Mailing Lists'>
                                <MailingLists
                                    onAddEmail={handleAddEmail}
                                    onAddAllEmails={handleAddMailingList}
                                    addAllButton={recipientsBox}
                                    selectedItems={selectedEmails}
                                    fullHeight={!recipientsBox}
                                />
                            </TabPane>
                        }
                        <TabPane
                            key='folders'
                            tab='Folders'>
                            <Folders
                                onAddEmail={handleAddEmail}
                                onAddAllEmails={handleAddMailingList}
                                addAllButton={recipientsBox}
                                selectedItems={selectedEmails}
                                fullHeight={!recipientsBox}
                            />
                        </TabPane>
                        {newContact &&
                            <TabPane
                                key='new-contact'
                                tab='Create New Contact'>
                                <FlexContainer pd='16px'>
                                    <ConnectionDetailForm
                                        recipientMode
                                        existingConnections={[]}
                                        connectionSubTypeID={1}
                                        showCustomerSelector
                                        // contactID={project && project.CustomerID}
                                        connectionType="People"
                                        onTouchForm={() => { }}
                                        onCreate={handleCreateConnection}
                                        onCancel={() => {
                                            // setIsAddConnection(false);
                                        }}
                                        onSave={id => {
                                            // setIsAddConnection(false);
                                        }}
                                        onAddConnection={handleConnection}
                                    />
                                </FlexContainer>
                            </TabPane>
                        }
                    </SendDrawerTabs>
                </div>
                <div className="recipients-and-email-details-section">
                    {tab !== 'new-contact' && recipientsBox &&
                        <div className="recipients-section">
                            <NwDivider>Recipients [{selectedEmails.length}]</NwDivider>
                            <div className="recipients-list">
                                {selectedEmails.map((recipient, index) => (
                                    <Popover
                                        key={`email-${index}`}
                                        overlayClassName="recipients-popover"
                                        content={
                                            <PopoverContent>
                                                <div>CCs:</div>
                                                {recipient.CC && recipient.CC.map((email, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="email-item"
                                                            style={{ borderTop: index ? "1px dashed white" : "none" }}
                                                        >
                                                            <div className="email-text">{email}</div>
                                                            <div className="remove-email" onClick={() => onRemoveCC(recipient, email)}>X</div>
                                                        </div>
                                                    )
                                                })}
                                            </PopoverContent>
                                        }
                                    >
                                        <TagHover visible color={StyleVariables.PrimaryDark} closable onClose={() => handleRemoveEmail(recipient.Email)}>
                                            {recipient.Email}{(recipient.CC && recipient.CC.length > 0) && ` (+${recipient.CC.length} CC)`}
                                        </TagHover>
                                    </Popover>
                                    // <Tag key={`email-${index}`} closable visible color={StyleVariables.PrimaryDark} onClose={() => handleRemoveEmail(recipient.Email)}>{recipient.Email}{(recipient.CC && recipient.CC.length > 0) && ` (+${recipient.CC.length} CC)`}</Tag>
                                ))}
                            </div>
                        </div>
                    }
                    {tab !== 'new-contact' && (textsBox || sendBox) &&
                        <div className="email-details-section">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={(values, { setSubmitting }) => {
                                    handleDrawerSubmit(values, setSubmitting)
                                }}
                                validationSchema={Yup.object().shape({
                                    Subject: Yup.string().required("Content is required")
                                })}>
                                {({ isSubmitting, values, handleSubmit }) => (
                                    <NwForm
                                        values={values}
                                        // onTouchForm={setTouchForm}
                                        onTouchForm={() => { }}
                                        onFinish={handleSubmit}
                                        layout="vertical">
                                        {isSingleSend && <div>
                                            <NwDivider style={{ width: "calc(100% + 60px)", left: "-30px" }}>Package details</NwDivider>
                                            <Field
                                                component={NWProjectSelector}
                                                showSecondLevel
                                                name="ProjectID"
                                                value={values.ProjectID} />
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <Field
                                                        component={NWSwitch}
                                                        label="Is Interactive"
                                                        name="IsInteractive"
                                                        defaultChecked={values.IsInteractive ? true : false}
                                                        checked={Boolean(values.IsInteractive)} />
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <Field
                                                        component={NWDatePicker}
                                                        label="Expiration Date"
                                                        name="ExpirationDate"
                                                        format="MMM DD, YYYY"
                                                        style={{ width: "100%" }}
                                                        value={values.ExpirationDate ? getMoment(values.ExpirationDate) : null}
                                                        manipulateValue={datePickerBeforeOnChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>}
                                        <NwDivider style={{ width: "calc(100% + 60px)", left: "-30px" }}>E-mail details</NwDivider>
                                        {hasSubject &&
                                            <Field
                                                label="SUBJECT"
                                                component={NWInput}
                                                name="Subject"
                                                type="text"
                                                placeholder='Subject'
                                                id='Subject'
                                                value={values.Subject}
                                            />
                                        }
                                        {hasMessage &&
                                            <Field
                                                component={NWTextAreaCharCounter}
                                                label="MESSAGE"
                                                name="Message"
                                                type="text"
                                                placeholder='Message'
                                                value={values.Message}
                                                autoSize={{ minRows: 3, maxRows: 3 }}
                                            />
                                        }
                                        <Field
                                            component={NWSignatureSelector}
                                            label="SIGNATURE"
                                            name="SignatureID"
                                            value={values.SignatureID}
                                        />
                                        <div className="button-bar">
                                            <NwFormButtonsBar
                                                left={
                                                    <NwCancelButton onClick={onCancel} />
                                                }
                                                right={
                                                    <>
                                                        <NwButton
                                                            primary
                                                            icon={light("arrow-up-from-square")}
                                                            label="Share link"
                                                            disabled={selectedEmails.length === 0}
                                                            loading={isSubmitting}
                                                            onClick={handleShareLink}
                                                            style={{ marginRight: ".5rem" }}
                                                        />
                                                        <NwButton
                                                            primary
                                                            htmlType="submit"
                                                            icon={light("paper-plane")}
                                                            label="Send"
                                                            disabled={selectedEmails.length === 0}
                                                            loading={isSubmitting}
                                                        />
                                                    </>
                                                }
                                            />
                                        </div>
                                    </NwForm>)}
                            </Formik>
                        </div>
                    }
                </div>
            </SendDrawerContainer>
            {drawerShareLink &&
                <PackageShareLink
                    currentPackage={currentPackage}
                    onClose={() => setDrawerShareLink(false)}
                />
            }
        </>
    )
};

export default SendDrawer;