import React, { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import NwDrawer from "Components/Gui/NwDrawer";
import { NWInput, NWTextAreaCharCounter } from 'Components/Gui/NwForm/NwFormItems';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { useUpdateInvoice } from "Hooks/Invoices/UseInvoices";

const EditInvoiceTextsDrawer = (props) => {
    const { onClose, invoiceData } = props;
    const [touchForm, setTouchForm] = useState(false);
    const { mutateAsync: onUpdateInvoice } = useUpdateInvoice();

    const initialValues = {
        InvoiceID: invoiceData.ID,
        InvoiceName: invoiceData.Name,
        HeaderNote: invoiceData.HeaderNote?invoiceData.HeaderNote:"",
        AddressNote: invoiceData.AddressNote?invoiceData.AddressNote:"",
        FooterNote: invoiceData.FooterNote?invoiceData.FooterNote:"",
    }

    const saveInvoice = async (values, setSubmitting) => {
        setSubmitting(true);
        try {
            await onUpdateInvoice({
                data: {
                    ...values,
                    Name: values.InvoiceName
                }
            });
            onClose();
        } catch (e) {

        } finally {
            setSubmitting(false);
        }
    }

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title="Edit Invoice Details"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    saveInvoice(values, setSubmitting);
                }}
                validationSchema={Yup.object().shape({
                    InvoiceName: Yup.string().required('Invoice Name is required'),
                })}
            >
                {({ isSubmitting, values, handleSubmit, }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit} 
                    >
                        <Field
                            component={NWInput}
                            label="Invoice Name"
                            name="InvoiceName"
                            maxLength={50}
                            type="text"
                            value={values.InvoiceName}
                        />
                        <Field
                            component={NWTextAreaCharCounter}
                            label="Description"
                            name="HeaderNote"
                            maxLength={600}
                            type="text"
                            value={values.HeaderNote}
                        />
                        <Field
                            component={NWTextAreaCharCounter}
                            label="Address Additional Text"
                            name="AddressNote"
                            maxLength={600}
                            type="text"
                            value={values.AddressNote}
                        />
                        <Field
                            component={NWTextAreaCharCounter}
                            label="Terms Additional Text"
                            name="FooterNote"
                            maxLength={600}
                            type="text"
                            value={values.FooterNote}
                        />
                        <br/><br/>
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    );
}

export default EditInvoiceTextsDrawer;