import React, { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { getMoment } from "Libs/NwMoment";

import NwDrawer from "Components/Gui/NwDrawer";
import { NWInput, NWDatePicker } from 'Components/Gui/NwForm/NwFormItems';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { useInvoiceConfirm } from "Hooks/Invoices/UseInvoices";
import Axios from "axios";
import styled from "styled-components";
import { ListContext } from "Contexts/ListContext";
import { useContext } from "react";
import { UserContext } from "Contexts/UserContext";
import { Alert } from "antd";
import { forceReloadTransaction } from "Hooks/Transaction/UseTransaction";
import { datePickerBeforeOnChange } from "Libs/NwMoment";

const Wrapper = styled.div`
    input.read-only {
        background-color: ${props => props.theme.LayoutBodyBackground} !important;
    }
    .error-message-container {
        margin-top: 2rem;
    }
`;

const InvoiceConfirmDrawer = ({ onClose, invoiceID }) => {
    const [touchForm, setTouchForm] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const { mutateAsync: onInvoiceConfirm } = useInvoiceConfirm();
    
    const userContext = useContext(UserContext)
    const { currentUser } = userContext
    
    const { cachedList } = useContext(ListContext)
    
    const accGroup = cachedList.accountingGroups.items.find(accgroup => accgroup.ID === currentUser.ActiveContext.accGroupId)
    const invoicenumbermethod = accGroup.ProgressiveNumberAlgorithm
    const isNumberManual = invoicenumbermethod.indexOf("Manual") > -1

    const initialValues = {
        Number: "",
        Date: ""
    }

    const saveInvoiceConfirmation = async (values, setSubmitting) => {
        setSubmitting(true);
        try {
            await onInvoiceConfirm({
                data: {
                    InvoiceID: invoiceID,
                    Number: values.Number,
                    Date: values.Date
                }
            });
            forceReloadTransaction()
            onClose();
        } catch (e) {
            setErrorMessage(e.message)
        } finally {
            setSubmitting(false);
        }
    }

    const getInvoiceNumber = async (date, setFieldValue) => {
        try {
            const response = await Axios.get(`/ProgressiveNumber/Suggest?DateTime=${date}`);
            setFieldValue("Number", response);
        } catch (e) {
        }
    }

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title="Invoice Confirmation"
        >
            <Wrapper>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        saveInvoiceConfirmation(values, setSubmitting);
                    }}
                    validationSchema={Yup.object().shape({
                        Number: Yup.string().required('A number/string is required'),
                        Date: Yup.string().required('A date is required'),
                    })}>
                    {({ isSubmitting, values, setFieldValue, handleSubmit, }) => (
                        <NwForm
                            values={values}
                            onTouchForm={setTouchForm}
                            onFinish={handleSubmit}
                        >
                            <Field
                                component={NWDatePicker}
                                label="Invoice Date"
                                name="Date"
                                format="MMM DD, YYYY"
                                getCalendarContainer={triggerNode => triggerNode.parentNode}
                                style={{ width: "100%" }}
                                value={values.Date ? getMoment(values.Date) : null}
                                manipulateValue={datePickerBeforeOnChange}
                                onAfterChange={(value) => {
                                    if (value) {
                                        getInvoiceNumber(value, setFieldValue);
                                    }
                                }}
                            />
                            <Field
                                component={NWInput}
                                className={!isNumberManual ? "read-only" : ""}
                                label="Invoice Number"
                                name="Number"
                                maxLength={50}
                                type="text"
                                placeholder="input a date above to get a suggested invoice number"
                                readOnly={!isNumberManual}
                                value={values.Number}
                            />
                            <NwFormButtonsBar
                                marginTop="4rem"
                                left={
                                    <NwCancelButton
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            setTouchForm(false);
                                            onClose();
                                        }}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        htmlType="submit"
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                    />
                                }
                            />
                            <div className="error-message-container">
                                {errorMessage &&
                                    <Alert message={errorMessage} type="error" showIcon />
                                }
                            </div>
                        </NwForm>
                    )}
                </Formik>
            </Wrapper>
        </NwDrawer>
    );
}

export default InvoiceConfirmDrawer;