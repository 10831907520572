import React, { useState } from "react";
import { Formik, Field } from "formik";
import NwDrawer from "Components/Gui/NwDrawer";
import { NWPaymentTermSelector } from 'Components/Gui/NwForm/NwFormItems';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import Axios from "axios";
import { forceReloadInvoice } from "Hooks/Invoices/UseInvoices";

const EditPaymentTermsDrawer = ( { onClose, invoiceData }) => {
    const [touchForm, setTouchForm] = useState(false);
    
    const initialValues = {
        PaymentTermCode: invoiceData.PaymentTermCode ? invoiceData.PaymentTermCode : null,
    }

    const savePaymentTermsStatus = async (values, setSubmitting) => {
        setSubmitting(true);
        try {
            await Axios.put(`/invoices/paymentterms`, {
                InvoiceID: invoiceData.ID,
                PaymentTermCode: values.PaymentTermCode
            });
            forceReloadInvoice(invoiceData.ID);
            onClose();
        } catch (e) {
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title="Change Payment Terms"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    savePaymentTermsStatus(values, setSubmitting);
                }}
            >
            {({ isSubmitting, values, handleSubmit, }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit} 
                    >
                        <Field
                            component={NWPaymentTermSelector}
                            label="Payment Terms"
                            name="PaymentTermCode"
                            value={values.PaymentTermCode}
                        />
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    );
}

export default EditPaymentTermsDrawer;