import React from 'react';
import styled from 'styled-components';
import NwSlickSlider from "Components/Gui/NwSlickSlider";

const isAddressEmpty = (address) => {
  return (
    address.Street === null || address.Street === '' || address.Street === ' ')
    && (address.City === null || address.City === '' || address.City === ' ')
    && (address.Country === null || address.Country === '' || address.Country === ' ');
}

const compareAddress = (address, addressToCompare) => {
  if (!addressToCompare) {
    return false;
  }
  return address.Street === addressToCompare.Street && address.City === addressToCompare.City && address.Country === addressToCompare.Country;
}

const generateProjectAddresses = (eventData) => {
  let addresses = [];
  if (eventData) {
      eventData.EventDates.forEach(eventDate => {
        if (!addresses.find(a => compareAddress(a, eventDate.Address)) && !isAddressEmpty(eventDate.Address)) {
          addresses.push(eventDate.Address);
        }
        eventDate.EventDatesModels.map(eventDateModel => {
          if (!addresses.find(a => compareAddress(a, eventDateModel.Address)) && !isAddressEmpty(eventDateModel.Address)) {
            addresses.push(eventDateModel.Address);
          }
          return null;
        })
      })
  }
  return addresses;
}

const Container = styled.div`
  padding: ${props => props.eventData ? '30px 0px' : '30px 40px'};
  .slick-arrow {
    top: 20px;
  }
  .slick-track {
    .slick-slide {
      padding: 0 8px;
	    box-sizing: border-box;
    }
  }
`;

const Slide = styled.div`
  padding: 10px;
  border: ${props => props.theme.DefaultBorder};
  min-height: 100px;
  cursor: pointer;
`;

const AddressesByProject = ({ eventData, onChange }) => {

  const addresses = generateProjectAddresses(eventData);
  return (
    <Container eventData={eventData}>
      <NwSlickSlider slidesToShow={3} slidesToScroll={3} variableWidth={false} adaptiveHeight>
        {
          addresses.map((address, index) => (
            <Slide key={index} index={index} onClick={() => onChange(address)}>
              <div>{address.Street || ''}</div>
              <div>{address.City ? `${address.City},` : ''} {address.Country || ''}</div>
            </Slide>
          ))
        }
      </NwSlickSlider>
    </Container>
  )
}

export default AddressesByProject;