import React, { useContext } from "react";
import styled from "styled-components";
import Money from "Components/Gui/Money";
import { ListContext } from "Contexts/ListContext";
import useConfigurations from "Hooks/UseConfigurations";
import NwIcon from "Components/Gui/NwIcon";
import { Popover } from "antd";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import NwLink from "Components/Gui/NwLink";
import { useUserPermission } from "Contexts/UserContext";


const NoTaxes = styled.small`
    display: block;
    color: ${props => props.theme.TextColorMuted};
    font-size: ${props => props.theme.FontSizeSm};
    text-transform: uppercase;
`

const TaxLine = styled.div`
    dl {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: ${props => props.theme.FontSizeSm};
        margin: 0;

        dt {
            padding-right: .5rem;
            text-transform: uppercase;
            font-weight: 300;
            color: ${props => props.theme.TextColorMuted};
        }
        dd {
            margin: 0;
        }
    }
`

const StatementTaxesDetails = ({ statement, onUpdate, statementCurrency, disabled }) => {

    const { cachedList } = useContext(ListContext);
    const vatOptions = cachedList.taxmethods.items.VatCodes;
    const taxOptions = cachedList.taxmethods.items.Taxes;
    const configuration = useConfigurations();
    
    const ChangeTaxes = useUserPermission('ChangeTaxes');

    const isReadOnly = !onUpdate || !ChangeTaxes;

    const noTaxes = (statement) => {
        return statement.TaxAmount === 0 && statement.TaxAmount2 === 0 && statement.VatAmount === 0;
    }

    const getTaxName = (code) => {
        if (!code) return "";
        const _tax = taxOptions.find(tax => tax.Code === code);
        if (_tax) return _tax.Name;
        return code;
    }

    const getVatName = (code) => {
        if (!code) return "";
        const _vat = vatOptions.find(tax => tax.Code === code);
        if (_vat) return _vat.Name;
        return code;
    }

    const taxCodesList = (statement) => {
        let taxCodes = [];
        if (statement.TaxAmount !== 0) {
            taxCodes.push(statement.TaxCode);
        }
        if (statement.TaxAmount2 !== 0) {
            taxCodes.push(statement.TaxCode2);
        }
        if (statement.VatAmount !== 0) {
            taxCodes.push(statement.VatCode);
        }
        return taxCodes;
    }

    const TaxesSummary = () => (
        <>
            {statement.TaxAmount !== 0 &&
                <dl className="tax-line-container">
                    <dt>{getTaxName(statement.TaxCode)}</dt>
                    <dd>
                        <Money redIfNegative amount={(statement.ExchangeRate > 0) ? statement.TaxDef : statement.TaxAmount} currency={(statement.ExchangeRate > 0) ? configuration.defaultCurrency : statementCurrency} />
                    </dd>
                </dl>
            }
            {statement.TaxAmount2 !== 0 &&
                <dl className="tax-line-container">
                    <dt>{getTaxName(statement.TaxCode2)}</dt>
                    <dd>
                        <Money redIfNegative amount={(statement.ExchangeRate > 0) ? statement.TaxDef2 : statement.TaxAmount2} currency={(statement.ExchangeRate > 0) ? configuration.defaultCurrency : statementCurrency} />
                    </dd>
                </dl>
            }
            {statement.VatAmount !== 0 &&
                <dl className="tax-line-container">
                    <dt>{getVatName(statement.VatCode)}</dt>
                    <dd>
                        <Money redIfNegative amount={(statement.ExchangeRate > 0) ? statement.VatDef : statement.VatAmount} currency={(statement.ExchangeRate > 0) ? configuration.defaultCurrency : statementCurrency} />
                    </dd>
                </dl>
            }
        </>
    )

    if (!isReadOnly) {
        return (
            noTaxes(statement)
                ?
                <NwIcon disabled={disabled} pointer onClick={onUpdate(statement)} primary icon={solid('circle-plus')} />
                :
                <Popover
                    zIndex={9999}
                    placement="left"
                    trigger='hover'
                    title="Taxes Summary"
                    content={<TaxesSummary />}
                >
                    <NwLink disabled={disabled} onClick={onUpdate(statement)}>
                        {taxCodesList(statement).join(', ')}
                    </NwLink>
                </Popover>
        )
    }

    return (
        noTaxes(statement)
            ?
            <NoTaxes>no taxes</NoTaxes>
            :
            <TaxLine><TaxesSummary /></TaxLine>
    )
}

export default StatementTaxesDetails