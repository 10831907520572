import React, { createContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { UserContextProvider } from 'Contexts/UserContext';
import { ListContextProvider } from 'Contexts/ListContext';
import NavigationContext from 'Contexts/NavigationContext'
import NavigationReducer from 'Contexts/NavigationReducer'
import { StateProvider } from 'Contexts/StateProvider'
import { UserGuiConfigContextProvider } from 'Contexts/UserGuiConfigContext';
import { FoldersProvider } from 'Contexts/FoldersContext';

import App from 'App';

export const UpdateCalendarContext = createContext(null);

const GlobalContext = () => {
    const [updateCalendar, setUpdateCalendar] = useState(false);

    return (
        <BrowserRouter>
            <StateProvider initialState={NavigationContext} reducer={NavigationReducer}>
                <ListContextProvider>
                    <UserContextProvider>
                        <FoldersProvider>
                            <UserGuiConfigContextProvider>
                                <UpdateCalendarContext.Provider value={{ updateCalendar, setUpdateCalendar }}>
                                    <App />
                                </UpdateCalendarContext.Provider>
                            </UserGuiConfigContextProvider>
                        </FoldersProvider>
                    </UserContextProvider>
                </ListContextProvider>
            </StateProvider>
        </BrowserRouter>
    )
}
const GlobalContextProvider = GlobalContext//process.env.NODE_ENV === "development" ? hot(GlobalContext) : GlobalContext
export default GlobalContextProvider
