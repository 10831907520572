import React, { useState, useMemo } from 'react';
import styled from 'styled-components';

import NwDrawer from 'Components/Gui/NwDrawer';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { NwAddButton } from 'Components/Gui/NwButton';
import ContactUserForm from 'Components/ContactUsersDrawer/ContactUserForm';
import ContactUsers from 'Components/ContactUsersDrawer/ContactUsers';
import { Loading } from 'Components/Gui/Loading';
import { useContact } from 'Hooks/Contact/UseContact';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";

const Wrapper = styled.div`
    .ant-card {
        border-right: ${props => props.isMobile && 'none'};
        border-left: ${props => props.isMobile && 'none'};
        border-radius: ${props => props.isMobile && 0};

        .ant-card-head {
            padding-right: ${props => props.isMobile && 0};
            padding-left: ${props => props.isMobile && 0};

            h1 {
                position: ${props => props.isMobile && 'unset'};
                left: ${props => props.isMobile && 'unset'};
            }
        }

        .ant-card-body-content {
            padding: ${props => props.isMobile && '8px 0'};

            .ant-list-item {
                padding: ${props => props.isMobile && 0};
            }
        }
    }
`;

const ContactUsersDrawer = ({ onClose, disable, contactID }) => {
    const { data: currentContact } = useContact(contactID)
    const [viewMode, setViewMode] = useState('LISTING');
    const [touchForm, setTouchForm] = useState(false);
    const breakpoints = useNwBreakPoints();

    const handleCloseUserForm = () => {
        setViewMode('LISTING');
    };

    const { bookerUsers, scouterUsers } = useMemo(() => {
        let bookerUsers = [];
        let scouterUsers = [];
        if (currentContact) {
            bookerUsers = currentContact.Users.filter(user => user.RoleName === 'Booker');
            scouterUsers = currentContact.Users.filter(user => user.RoleName === 'Scouter');
        }
        return { bookerUsers, scouterUsers };
    }, [currentContact]);

    const isModel = useMemo(() => {
        if (currentContact) {
            return currentContact.ContactType === 'Model'
        }
        return false
    }, [currentContact]);

    return (
        <NwDrawer title={`Bookers ${isModel ? " / Scouters" : ""}`} onClose={onClose}>
            {currentContact ?
                <>
                    {!disable &&
                        <div className='drawer-toolbar-container'>
                            <NwAddButton primary onClick={() => setViewMode("INSERTING")} label={`Add a new booker ${isModel ? " / scouter" : ""}`} />
                        </div>
                    }
                    <div className='drawer-content standard'>
                        {isModel ?
                            <>
                                <Wrapper isMobile={breakpoints === 1}>
                                    <NwCard title='Bookers'>
                                        <ContactUsers isModel users={bookerUsers} readOnly={disable} contactID={contactID} />
                                    </NwCard>
                                </Wrapper>
                                <Wrapper isMobile={breakpoints === 1}>
                                    <NwCard title='Scouters'>
                                        <ContactUsers isModel users={scouterUsers} readOnly={disable} contactID={contactID} />
                                    </NwCard>
                                </Wrapper>
                            </>
                            :
                            <Wrapper isMobile={breakpoints === 1}>
                                <NwCard title='Bookers'>
                                    <ContactUsers users={currentContact.Users} readOnly={disable} contactID={contactID} />
                                </NwCard>
                            </Wrapper>
                        }
                    </div>
                </>
                : <Loading textBlack />
            }
            {viewMode === 'INSERTING' &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Add Contact User'
                    onClose={handleCloseUserForm}>
                    <ContactUserForm
                        isModel={isModel}
                        contactID={currentContact.ID}
                        onCancel={handleCloseUserForm}
                        onUpdated={handleCloseUserForm}
                        onTouchForm={setTouchForm} />
                </NwDrawer>
            }
        </NwDrawer>
    );
};

export default ContactUsersDrawer;