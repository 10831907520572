import React, { useContext, useState, useMemo, useEffect } from "react";
import { Modal, Checkbox, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Axios from 'axios';
import ls from 'local-storage';
import { useQueryClient } from '@tanstack/react-query';

import { UserContext } from "Contexts/UserContext";
import { ListContext } from 'Contexts/ListContext';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";
import { deleteUserLogs } from 'Libs/NwLogs';
import { fetchModelsByArea, fetchCustomersByArea, prefetchContacts, forceReloadModelsWall } from 'Hooks/Contact/UseContactsList';
import { useStateValue } from "Contexts/StateProvider";
import { NwTag } from "Components/Gui/NwTag";
import NwIcon from "Components/Gui/NwIcon";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';


const ModalWrapper = styled.div`
    padding: 1rem 0;

    h2 {
        font-size: 1.1rem;
    }

    .agencies-wrapper {
        margin-bottom: 2rem;
        padding: 0 2rem;
    }
    .departments-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 2rem;

        .department-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;
            
            &.default {
                font-weight: bold;
            }
        }
    }
    .buttons-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 1rem;
        margin-top: 2rem;
        border-top: ${props => props.theme.DefaultBorder};
    }
    .error-wrapper {
        display: flex;
        justify-content: flex-end;
        padding: 0 1rem 1rem 0;
        color: ${props => props.theme.DangerColor};
        p {
            font-size: ${props => props.theme.FontSizeXSm};
            text-align: right;
            margin: 0;
            padding: 0;
            max-width: 200px;
        }
    }
`;

const UserContextModal = ({ onCancel }) => {
    const { currentUser, UpdateCurrentUser } = useContext(UserContext);
    const { ActiveContext, NetworkAgencies, Departments } = currentUser;

    const { cachedList, LoadData } = useContext(ListContext);

    const getAvailable = () => {
        //filter structure on the basis of NetworkAgencies and Departments
        let availableStructure = [];
        const fullStructure = [...cachedList.accountingGroups.items];
        for (const accountingGroup of fullStructure) {
            //check if accountingGroup has any NetworkAgency included in currentUser.NetworkAgencies
            if (NetworkAgencies.find(agencyId => accountingGroup.NetworkAgencies.find(agency => agency.ID === agencyId))) {
                //filter NetworkAgencies on the basis of currentUser.NetworkAgencies
                const networkAgencies = accountingGroup.NetworkAgencies.filter(agency => NetworkAgencies.find(agencyId => agencyId
                    === agency.ID));
                //filter Departments on the basis of currentUser.Departments
                for (const networkAgency of networkAgencies) {
                    //check in activeContext if it's active networkAgency
                    if (ActiveContext.nwAgId === networkAgency.ID) {
                        networkAgency.Active = true
                        accountingGroup.Active = true;
                    }
                    networkAgency.Departments = networkAgency.Departments.filter(department => !department.Disabled && Departments.find(departmentId => departmentId === department.ID));
                    for (const department of networkAgency.Departments) {
                        //check in activeContext if it's active department
                        if (ActiveContext.activeDepartments.find(departmentId => departmentId === department.ID)) {
                            department.Active = true;
                        }
                        //check in activeContext if it's default department
                        if (ActiveContext.depId === department.ID) {
                            department.Default = true;
                        }
                    }
                }
                //keep same data structure of accountingGroup
                availableStructure.push({
                    ...accountingGroup,
                    NetworkAgencies: networkAgencies
                });
            }
        }
        return availableStructure;
    }

    const [structure, setStructure] = useState([]);

    useEffect(() => {
        setStructure(getAvailable());
    }, [cachedList, NetworkAgencies, Departments, ActiveContext]);

    //memoize active networkagency, return only the first active networkagency
    const currentNetworkAgency = useMemo(() => {
        const currentAccountingGroup = structure.find(accGroup => accGroup.Active);
        if (currentAccountingGroup) {
            return currentAccountingGroup.NetworkAgencies.find(agency => agency.Active);
        }
        return null;
    }, [structure]);

    const NetworkAgenciesList = useMemo(() => {
        //return a flat list of networkagencies from structure only with ID and name
        return structure.map(accGroup => accGroup.NetworkAgencies).flat().map(agency => {
            return {
                ID: agency.ID,
                Name: agency.Name,
                AccountingGroupID: agency.AccountingGroupID,
                Active: agency.Active
            }
        })
    }, [structure]);

    const onChangeNetworkAgency = (networkAgencyID) => {
        //update structure with new active networkagency
        const selectedAccountingGroup = structure.find(accGroup => accGroup.NetworkAgencies.find(agency => agency.ID === networkAgencyID));
        setStructure(structure.map(accGroup => {
            if (accGroup.ID === selectedAccountingGroup.ID) {
                accGroup.Active = true;
            } else {
                accGroup.Active = false;
            }
            accGroup.NetworkAgencies = accGroup.NetworkAgencies.map(agency => {
                if (agency.ID === networkAgencyID) {
                    agency.Active = true;
                } else {
                    agency.Active = false;
                }
                //if there's only a department set it as active and default, otherwise set all as inactive and not default
                if (agency.Active && agency.Departments.length === 1) {
                    agency.Departments[0].Active = true;
                    agency.Departments[0].Default = true;
                } else {
                    agency.Departments = agency.Departments.map(department => {
                        department.Active = false;
                        department.Default = false;
                        return department;
                    });
                    //agency.Departments[0].Default = true;
                }
                return agency;
            });
            return accGroup;
        }));
    }

    const onChangeDepartment = (departmentId, value) => {
        //update structure with new active department
        setStructure(structure.map(accGroup => {
            accGroup.NetworkAgencies = accGroup.NetworkAgencies.map(agency => {
                agency.Departments = agency.Departments.map(department => {
                    if (department.ID === departmentId) {
                        department.Active = value.target.checked;
                        if (!department.Active) {
                            department.Default = false;
                        }
                    }
                    //if it's the only department active set it as default
                    if (department.Active && agency.Departments.filter(dep => dep.Active).length === 1) {
                        department.Default = true;
                    }
                    return department;
                });
                return agency;
            });
            return accGroup;
        }));
    }

    const isValid = useMemo(() => {
        //at least one department must be active and one department must be default
        const activeDepartments = structure.map(accGroup => accGroup.NetworkAgencies).flat().map(agency => agency.Departments).flat().filter(department => department.Active);
        const defaultDepartment = structure.map(accGroup => accGroup.NetworkAgencies).flat().map(agency => agency.Departments).flat().find(department => department.Default);
        return activeDepartments.length > 0 && defaultDepartment;
    }, [structure]);

    const [, dispatch] = useStateValue();

    const [isUpdating, setIsUpdating] = useState(false);
    const queryClient = useQueryClient();

    const handleSaveUserContext = async () => {
        //get in a flat array all active departments
        const activeNetworkAgency = structure.map(accGroup => accGroup.NetworkAgencies).flat().find(agency => agency.Active);
        const activeDepartments = activeNetworkAgency.Departments.filter(department => department.Active).map(department => department.ID)
        //get the default department
        const defaultDepartment = structure.map(accGroup => accGroup.NetworkAgencies).flat().map(agency => agency.Departments).flat().find(department => department.Default)


        const userContextData = {
            depId: defaultDepartment.ID,
            nwAgId: currentNetworkAgency.ID,
            activeDepartments: activeDepartments,
        };

        try {
            setIsUpdating(true);
            await Axios.post('/userconfigurations/setActiveContext', userContextData);
            //update local user context: I have to add accGroupId because in the ActiveContext saved in the db it's added by the API endpoint
            UpdateCurrentUser({
                ...currentUser,
                ActiveContext: {
                    ...userContextData,
                    accGroupId: currentNetworkAgency.AccountingGroupID
                }
            });
            // clear user logs, cachedlist, query cache
            deleteUserLogs();
            Object.keys(cachedList).map(key => {
                ls.remove(`nw_cachedlist_${key}`);
                ls.remove(`nw_cachedlist_${key}_lastupdate`);
                return null;
            })
            queryClient.clear();
            // reload cachedlist, prefetch contacts
            LoadData();
            prefetchContacts();
            await fetchModelsByArea('booking');
            await fetchCustomersByArea('booking');
            //forceReloadModelsWall();
            setIsUpdating(false);
            onCancel();
        }
        catch (error) {
            console.log('ant : Set Active Context error => ', error);
            //notify error
            notification.error({
                message: 'Error',
                description: 'An error occurred while saving the user context',
                duration: 5
            });
            setIsUpdating(false);
        }
    };

    const onSetDepartmentAsDefault = (departmentId) => {
        setStructure(structure.map(accGroup => {
            accGroup.NetworkAgencies = accGroup.NetworkAgencies.map(agency => {
                agency.Departments = agency.Departments.map(department => {
                    if (department.ID === departmentId) {
                        department.Default = true;
                    } else {
                        department.Default = false;
                    }
                    return department;
                });
                return agency;
            });
            return accGroup;
        }));
    }

    return (
        <Modal
            width={520}
            footer={null}
            onCancel={onCancel}
            open
            zIndex={1040}
            title="Change Context"
            bodyStyle={{ padding: 0 }}
        >
            {structure && currentNetworkAgency &&
                <ModalWrapper>
                    <div className="agencies-wrapper">
                        <h2>Agency</h2>
                        <RadioButtonGroup
                            value={currentNetworkAgency.ID}
                            onChange={onChangeNetworkAgency}
                            options={NetworkAgenciesList.map(agency => (
                                { label: agency.Name, value: agency.ID }
                            ))}
                        />
                    </div>
                    <div className="departments-wrapper">
                        <h2>Departments</h2>
                        {currentNetworkAgency.Departments.map(department => {
                            return (
                                <div className={`department-item ${department.Default ? 'default' : ''}`} key={`department-${department.ID}`}>
                                    <Checkbox
                                        checked={department.Active}
                                        onChange={(val) => onChangeDepartment(department.ID, val)}
                                    >
                                        {department.Name}

                                    </Checkbox>
                                    {department.Default ?
                                        <NwTag type="primary">default</NwTag>
                                        :
                                        (department.Active &&
                                            <NwTag pointer clickaction={() => onSetDepartmentAsDefault(department.ID)}>
                                                set as default
                                            </NwTag>
                                        )
                                    }
                                </div>
                            );
                        })}
                    </div>
                    <div className="buttons-wrapper">
                        <NwCancelButton disabled={isUpdating} onClick={onCancel} />
                        <NwSaveButton primary disabled={isUpdating || !isValid} loading={isUpdating} onClick={handleSaveUserContext} />
                    </div>

                    {!isValid &&
                        <div className="error-wrapper">
                            <NwIcon icon={light("exclamation-triangle")} size="2x" />
                            <p>Please select at least an active departments and a default department</p>
                        </div>
                    }

                </ModalWrapper>
            }
        </Modal>
    );
};

export default UserContextModal;
