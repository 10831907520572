import React, { useState } from "react";
import { Alert, Select, Switch } from 'antd';
import { getMoment, getTime, isSecondTimeEqualOrLater, isSecondTimeLater, timeArrayToString } from "Libs/NwMoment";
import styled from 'styled-components';
import { Formik, Field } from "formik";
import { NwCancelButton, NwLinkButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import TimeRangePicker from '../../../EventDateTimePicker/TimeRangePicker';
import SelectModelsIds from './SelectModelsIds';
import { onChangeEventDateModels, onChangeEventDate } from 'Hooks/Event/UseEvent';
import NwDivider from "Components/Gui/NwDivider";
import { NWTextAreaCharCounter, NWLocationSelector } from "Components/Gui/NwForm/NwFormItems";
import DoubleTimeRangePicker from "../../../EventDateTimePicker/DoubleTimeRangePicker";

const allViewOptions = {
    all: 'time, address and notes',
    timeOnly: 'time only',
    addressOnly: 'address only',
    notesOnly: 'notes only',
};

const { Option } = Select;

const Container = styled.div`
  padding: 0px 16px;
  .ant-legacy-form-item {
    margin-bottom: 16px;
    .ant-legacy-form-item-label {
        line-height: 1.2rem;
    }
  }
`;

const ChangeOnContainer = styled.div`
  margin-top: 10px;
  .changes-line {
    display: flex;
    margin-bottom: 10px;
    .changes-text {
      margin-right: 10px;
    }
    button {
      margin-right: 10px;
    }
  }
`;

const getAllModelsBySelectedId = (eventData, modelID) => {
    let models = []
    eventData.EventDates.forEach(eventDate => {
        if (eventDate.EventDatesModels.find(m => m.ID === modelID)) {
            models = eventDate.EventDatesModels.map(m => m);
        }
    });
    return models;
}

const getAllModelsBySelectedModels = (eventData, selectedModels) => {
    const models = [];
    eventData.EventDates.forEach(eventDate => eventDate.EventDatesModels.forEach(eventDateModel => {
        if (selectedModels.find(model => model.ModelID === eventDateModel.ModelID)) {
            models.push(eventDateModel)
        }
    }))
    return models;
}

const getAllModelsAmount = (eventData) => {
    const modelIds = [];
    eventData.EventDates.forEach(eventDate => eventDate.EventDatesModels.forEach(eventDateModel => {
        if (!modelIds.includes(eventDateModel.ModelID)) {
            modelIds.push(eventDateModel.ModelID)
        }
    }))
    return modelIds.length;
}

const getAllModelsIds = (eventData) => {
    const modelIds = [];
    eventData.EventDates.forEach(eventDate => eventDate.EventDatesModels.forEach(eventDateModel => {
        modelIds.push(eventDateModel.ID);
    }))
    return modelIds;
}

const getModelsByIds = (modelIds, eventData) => {
    const models = [];
    eventData.EventDates.forEach(eventDate => eventDate.EventDatesModels.forEach(eventDateModel => {
        if (modelIds.find(modelId => modelId === eventDateModel.ID) && !models.includes(eventDateModel)) {
            models.push(eventDateModel)
        }
    }))
    return models;
}

const EditModelDatesForm = ({ onClose, onSave, date, eventData, onTouchForm }) => {
    const [viewOptions, setViewOptions] = useState(allViewOptions.all);
    const [selectedModels, setSelectedModels] = useState(date.models);
    const [changesOptions, setChangesOptions] = useState({ allModels: false, allDays: false });
    
    const isOriginallyDoubleTime = date.StartBreak && date.EndBreak;
    const isOriginallyAllDay = getMoment(date.FromDate).format('HH:mm') === '00:00' && getMoment(date.ToDate).format('HH:mm') === '23:59';
    const [timeErrorMessage, setTimeErrorMessage] = useState('');
    const [doubleTime, setDoubleTime] = useState(isOriginallyDoubleTime);
    const [allDay, setAllDay] = useState(isOriginallyAllDay);

    const hasBreak = (values) => {
        if (!values.StartBreak || !values.EndBreak) {
            return false;
        }
        if ((values.StartBreak[0] === 0 && values.StartBreak[1] === 0) || (values.EndBreak[0] === 0 && values.EndBreak[1] === 0)) {
            return false;
        }
        return true;
    }

    const initialValues = {
        FromDate: getTime(date.FromDate),
        ToDate: getTime(date.ToDate),
        StartBreak: date.StartBreak ? getTime(date.StartBreak) : null,
        EndBreak: date.EndBreak ? getTime(date.EndBreak) : null,
        
        modelIds: date.models.map(m => m.ID),
        EventAddress: date.Address,
        Notes: date.Notes ? date.Notes : '',
        allDay: isOriginallyAllDay
    };

    const onSubmit = async (values) => {
        const fromHourValue = timeArrayToString(values.FromDate)
        const toHourValue = timeArrayToString(values.ToDate)
        const hasBreakValues = hasBreak(values);
        const startBreakHourValue = hasBreakValues ? timeArrayToString(values.StartBreak) : null;
        const endBreakHourValue = hasBreakValues ? timeArrayToString(values.EndBreak) : null;
        
        let timeError = false
        if (hasBreakValues) {
            if (!isSecondTimeEqualOrLater(values.FromDate, values.StartBreak)) {
                timeError = true
            }
            if (!isSecondTimeLater(values.StartBreak, values.EndBreak)) {
                timeError = true
            }
            if (!isSecondTimeEqualOrLater(values.EndBreak, values.ToDate)) {
                timeError = true
            }
        } else {
            if (!isSecondTimeEqualOrLater(values.FromDate, values.ToDate)) {
                timeError = true
            }
        }
        if (timeError) {
            setTimeErrorMessage('Invalid time range')
            document.querySelector('.event-edit-drawers .ant-drawer-body').scrollTop = 0;
            return
        } else {
            setTimeErrorMessage('')
            let data = {
                fromHour: fromHourValue,
                toHour: toHourValue,
                startBreakHour: startBreakHourValue,
                endBreakHour: endBreakHourValue,
                
                Address: values.EventAddress,
                Notes: values.Notes,
                UpdateTime: viewOptions === allViewOptions.all || viewOptions === allViewOptions.timeOnly,
                UpdateAddress: viewOptions === allViewOptions.all || viewOptions === allViewOptions.addressOnly,
                UpdateNotes: viewOptions === allViewOptions.all || viewOptions === allViewOptions.notesOnly
            }
            //console.log('data', data)
            if (date.models.length === 0) {
                data = {
                    ...data,
                    eventDatesIDs: [date.eventDateId],
                }
                await onChangeEventDate(data);
            } else {
                data = {
                    ...data,
                    eventDatesModelIDs: values.modelIds,
                }
                await onChangeEventDateModels(data);
            }
    
            if (onTouchForm) {
                onTouchForm(false);
            }
            onSave();
        }
    }

    const onToggleAllModels = (form) => {
        const allModels = !changesOptions.allModels;
        setChangesOptions({ ...changesOptions, allModels });
        if (allModels && changesOptions.allDays) {
            const allModelsIds = getAllModelsIds(eventData);
            form.setFieldValue('modelIds', [...allModelsIds]);
            return;
        }
        if (allModels) {
            const models = getAllModelsBySelectedId(eventData, date.models[0].ID);
            setSelectedModels(models);
            form.setFieldValue('modelIds', [...form.values.modelIds, ...models.map(m => m.ID)]);
            return;
        }
        if (!allModels && changesOptions.allDays) {
            const ids = date.models.map(m => m.ID);
            const models = getAllModelsBySelectedModels(eventData, selectedModels);
            setSelectedModels(models);
            form.setFieldValue('modelIds', [...ids, ...models.map(m => m.ID)]);
            return;
        }
        form.setFieldValue('modelIds', date.models.map(m => m.ID));
    }

    const onToggleAllDays = (form) => {
        const allDays = !changesOptions.allDays;
        setChangesOptions({ ...changesOptions, allDays });
        if (allDays && changesOptions.allModels) {
            const allModelsIds = getAllModelsIds(eventData);
            form.setFieldValue('modelIds', [...allModelsIds]);
            return;
        }
        if (allDays) {
            const models = getAllModelsBySelectedModels(eventData, selectedModels);
            setSelectedModels(models);
            form.setFieldValue('modelIds', [...form.values.modelIds, ...models.map(m => m.ID)]);
            return;
        }
        if (!allDays && changesOptions.allModels) {
            const ids = date.models.map(m => m.ID);
            const models = getAllModelsBySelectedId(eventData, date.models[0].ID);
            setSelectedModels(models);
            form.setFieldValue('modelIds', [...ids, ...models.map(m => m.ID)]);
            return;
        }
        form.setFieldValue('modelIds', date.models.map(m => m.ID));
    }

    const onSelectModels = (models) => {
        const allSelectedModels = getModelsByIds(models, eventData);
        setSelectedModels(allSelectedModels);
    }

    const switchToSingleTime = (setFieldValue) => {
        setFieldValue('StartBreak', [0,0]);
        setFieldValue('EndBreak', [0,0]);
        setDoubleTime(false);
    }

    const switchToDoubleTime = (setFieldValue) => {
        setFieldValue('StartBreak', [0,0]);
        setFieldValue('EndBreak', [0,0]);
        setDoubleTime(true);
    }

    return (
        <>
            <Container>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {(form) => (
                        <NwForm
                            values={form.values}
                            onTouchForm={onTouchForm}
                            onFinish={form.handleSubmit}
                            layout="vertical">
                            {
                                (viewOptions === allViewOptions.all || viewOptions === allViewOptions.timeOnly) && (
                                    <>
                                        <NwDivider>Time</NwDivider>
                                        {doubleTime
                                            ?
                                            <>
                                                <DoubleTimeRangePicker
                                                    fields={{
                                                        startTimeFieldName: 'FromDate',
                                                        endTimeFieldName: 'ToDate',
                                                        startBreakFieldName: 'StartBreak',
                                                        endBreakFieldName: 'EndBreak'
                                                    }}
                                                    values={{
                                                        startTime: form.values.FromDate,
                                                        endTime: form.values.ToDate,
                                                        startBreak: form.values.StartBreak,
                                                        endBreak: form.values.EndBreak
                                                    }}
                                                    methods={{
                                                        setFieldValue: form.setFieldValue
                                                    }}
                                                />
                                                <NwLinkButton label="single" onClick={() => switchToSingleTime(form.setFieldValue)} />
                                            </>
                                            :
                                            <>
                                                <TimeRangePicker 
                                                    fields={{
                                                        startTimeFieldName: 'FromDate',
                                                        endTimeFieldName: 'ToDate',
                                                        allDayFieldName: 'allDay'
                                                    }}
                                                    values={{
                                                        startTime: form.values.FromDate,
                                                        endTime: form.values.ToDate
                                                    }}
                                                    methods={{
                                                        setFieldValue: form.setFieldValue,
                                                        onSetAllDay: setAllDay
                                                    }}
                                                />
                                                {!allDay &&
                                                    <NwLinkButton label="dual time slot" onClick={() => switchToDoubleTime(form.setFieldValue)} />
                                                }
                                            </>
                                        }
                                        {timeErrorMessage && <Alert type="error" message={timeErrorMessage} />}
                                    </>
                                )
                            }
                            {
                                (viewOptions === allViewOptions.all || viewOptions === allViewOptions.addressOnly) && (
                                    <>
                                        <NwDivider>Address</NwDivider>
                                        <Field
                                            component={NWLocationSelector}
                                            eventData={eventData}
                                            name="EventAddress"
                                            value={form.values.EventAddress}
                                        />
                                    </>
                                )
                            }
                            <NwDivider>Notes</NwDivider>
                            <Field
                                component={NWTextAreaCharCounter}
                                name="Notes"
                                type="text"
                                value={form.values.Notes}
                                maxLength={2000}
                                autoSize={{ minRows: 3, maxRows: 14 }}
                            />
                            {date.models.length > 0 &&
                                <>
                                    <ChangeOnContainer>
                                        <NwDivider>Change on</NwDivider>
                                        <div className="changes-container">
                                            <div className="changes-line">
                                                <div className="changes-text">Apply on:</div>
                                                {
                                                    getAllModelsAmount(eventData) > 1 && (
                                                        <>
                                                            <Switch checked={changesOptions.allModels} onChange={() => onToggleAllModels(form)} />
                                                            <div onClick={() => onToggleAllModels(form)} className="changes-text">all models</div> {' '}
                                                        </>

                                                    )
                                                }
                                                {
                                                    eventData.EventDates.length > 1 && (
                                                        <>
                                                            <Switch checked={changesOptions.allDays} onChange={() => onToggleAllDays(form)} /> {' '}
                                                            <div onClick={() => onToggleAllDays(form)} className="changes-text">all days</div> {' '}
                                                        </>
                                                    )
                                                }
                                            </div>
                                            <div className="changes-line">
                                                <div className="changes-text">
                                                    Update:
                                                </div>
                                                <Select
                                                    autoComplete='off'
                                                    value={viewOptions}
                                                    style={{ width: 215 }}
                                                    onChange={(value) => setViewOptions(value)}>
                                                    {Object.values(allViewOptions).map(viewOption => (
                                                        <Option value={viewOption} key={viewOption}>{viewOption}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                    </ChangeOnContainer>
                                    <SelectModelsIds eventDates={eventData.EventDates} form={form} onChange={onSelectModels} modelProperty="ID" />
                                </>
                            }
                            <NwFormButtonsBar
                                left={
                                    <NwCancelButton
                                        disabled={form.isSubmitting}
                                        onClick={() => {
                                            onTouchForm(false);
                                            onClose();
                                        }}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        disabled={form.isSubmitting || (form.values.modelIds.length === 0 && date.models.length > 0)}
                                        loading={form.isSubmitting}
                                        label="Save"
                                        loadingLabel="Updating..."
                                    />
                                }
                            />
                        </NwForm>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default EditModelDatesForm;
