import { useContext } from "react"
import { UserContext } from "Contexts/UserContext";
import { replacePlaceholder } from "Components/Filters/FilterForm/FilterUtils";

const useDataPlaceholder = () => {
    //This hook manage the default placeholder replacement, it returns the funcion to use for replacement.
    const { currentUser } = useContext(UserContext)
    const replace = (value) => {
        return replacePlaceholder(value, currentUser.UserID)
    }
    return replace
}

export default useDataPlaceholder