import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { List } from 'antd';

import { Loading } from 'Components/Gui/Loading';
import ModelCard from 'Components/ContactsSelector/ModelsSelector/ModelsWall/ModelsCards/ModelCard';
import EmptyState from 'Components/Gui/EmptyState';
import CenteredBlock from 'Components/Gui/CenteredBlock';
import useDimensions from "Hooks/UseDimensions";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { WallContainer } from './ModelCardUI';



const ModelCardsWrapper = ({
    footer,
    isLoading,
    models,
    modelEventsInfo,
}) => {
    
    const [ref, { width }] = useDimensions();
    const [pagedList, setPagedList] = useState([]);
    const [loadMore, setLoadMore] = useState(false);
    const breakpoints = useNwBreakPoints();
    const pageCardCount = useMemo(() => {
        switch (breakpoints) {
            case 1:
                return 4;
            case 2:
                return 8;
            case 3:
                return 16;
            default:
                return 24;
        }
    }, [breakpoints]);

    useEffect(() => {
        setPagedList(models.slice(0, pageCardCount));
    }, [models]);

    const loader = useRef(null);

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setLoadMore(true);
        }
    }, []);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "10px",
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) {
            observer.observe(loader.current);
        }
    }, [handleObserver]);

    useEffect(() => {
        setPagedList(models.slice(0, pageCardCount))
    }, [models]);

    useEffect(() => {
        if (loadMore) {
            if (models.length > pagedList.length) {
                const newShowedList = models.slice(pagedList.length, pagedList.length + pageCardCount);
                setPagedList([...pagedList, ...newShowedList]);
            }
            setLoadMore(false)
        }
    }, [loadMore]);

    return (
        <>
            <WallContainer ref={ref}>
                {/* {header} */}
                {isLoading ?
                    <Loading loadingType="centered" />
                    :
                    (models.length ?
                        <>
                            <List
                                style={{ padding: "1rem 0" }}
                                grid={{
                                    gutter: 8, column: Math.floor(width / 250)
                                }}
                                dataSource={pagedList}
                                renderItem={model => {
                                    // let disabled = false;
                                    // if (disabledItems) {
                                    //     const findModelIndex = disabledItems.findIndex(modelID => modelID === model.ID);
                                    //     if (findModelIndex > -1) disabled = true;
                                    // }
                                    return (
                                        <List.Item key={model.ID}>
                                            <ModelCard
                                                //addModelProps={addModelProps}
                                                //connections={connections}
                                                //displayConnections={displayConnections}
                                                //disabled={disabled}
                                                //isSelected={selectedList.includes(model.ID)}
                                                model={model}
                                                modelEventsInfo={modelEventsInfo}
                                                //packageBooks={packageBooks}
                                                //packageId={packageId}
                                            />
                                        </List.Item>
                                    )
                                }}
                            />
                            
                        </>
                        : <CenteredBlock>
                            <EmptyState message="no models fitting your search" />
                        </CenteredBlock>
                    )
                }
                <div ref={loader}>
                    {loadMore && <Loading textBlack small />}
                </div>
            </WallContainer>
            {footer}
        </>
    )
};

export default ModelCardsWrapper;
