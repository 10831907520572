import { Divider } from 'antd';
import { NwTag } from 'Components/Gui/NwTag';
import React from 'react'
import styled from 'styled-components';


const WallLineItemContainer = styled.div`
    padding: .4em .25em 0 .75em;
    border-bottom: ${props => props.theme.DefaultBorder};
    cursor: pointer;
    overflow: hidden;
    height: ${(props) => props.showSmallText ? 42 : 34}px;
    text-transform: uppercase;
    font-size: .9rem;
    line-height: 1.3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    em {
        display: inline-block;
        color: ${props => props.theme.TextColorMuted};
        font-size: .75rem;
        text-transform: uppercase;
        padding: 0 .15em;
        font-style: normal;
        font-weight: normal;
        min-width: 2.5rem;
    }

    small.small-text {
        display: block;
        font-size: .75rem;
        text-transform: uppercase;
        line-height: 1.1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 10px;
        opacity: .6;
    }

    &.list-item-selected {
        font-weight: bold;
        color: #000;

        small {
            font-weight: normal;
        }
    }

    &.list-item-empty {
        color: ${props => props.theme.DangerColor};
        font-style: italic;
        
        small {
            font-style: normal;
        }
    }
    
    &.list-item-smaller-font {
        font-size: .85em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    &:last-of-type {
        border-bottom: none;
    }

    .minor-tag-container {
        position: absolute;
        top: .5rem;
        right: .25rem;
        height: 20px;

        small {
            height: auto;
            padding: .25rem;
            opacity: 1;
        }
    }
`


export const WallLineItem = ({
    isMinor,
    isSelected,
    onClick,
    prefix,
    showSmallText,
    smallText,
    text,
}) => {

    const getItemClass = (item) => {
        let classN = "list-item"
        if (isSelected) {
            classN += " list-item-selected"
        }
        if (text.length > 20) {
            classN += " list-item-smaller-font"
        }
        if (text.length === 0) {
            classN += " list-item-empty"
        }
        return classN
    }

    return (
        <WallLineItemContainer
            showSmallText={showSmallText}
            className={getItemClass(text)}
            onClick={onClick}>
            {prefix &&
                <>
                    <em>{prefix}</em>
                    <Divider type="vertical" />
                </>
            }
            <div>
                {text || 'No Name'}
                {showSmallText &&
                    <small className="small-text">{smallText}</small>
                }
            </div>
            {isMinor &&
                <div className="minor-tag-container">
                    <NwTag type="danger">&lt; 18</NwTag>
                </div>
            }
        </WallLineItemContainer>
    )
};

