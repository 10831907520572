import React, { useContext } from "react";
import styled from "styled-components";
import { getMoment, showDateNoTime } from "Libs/NwMoment";

import { placeholderToLabel } from "Configs/FILTERS_PLACEHOLDERS";
import { getDisplayValues, groupConversionValues } from "Libs/NWFeatures";
import { ListContext } from "Contexts/ListContext";
import { getContactName } from 'Hooks/Contact/UseContactsList';
import useDataPlaceholder from "Hooks/UseDataPlaceholder";
import { showDate, toISODate } from "Libs/NwMoment";
import { findFeature, findFilterInFiltersBySection } from "../../Filters/FilterForm/FilterUtils";
import { useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/SelectorContext";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import NwIcon from "Components/Gui/NwIcon";
import { useUserGuiConfig } from "Contexts/UserGuiConfigContext";

const Wrapper = styled.div`
    display: flex;
    font-size: 0.8em;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    .texts-wrapper {
        flex: 1 1 0;
        .label {
            font-weight: bold;
        }
        .value {
            padding-left: 1rem;
            span {
                display: block;
            }
        }
    }
    .remove-button-wrapper {
        font-size: 1rem;
    }
`;


const ContactsSelectedFilterItem = ({ filter }) => {

    const { cachedList } = useContext(ListContext);

    const { global, selection } = useContactsSelector()
    const cs_dispatch = useContactsSelectorDispatch()
    const { filters } = selection
    const { featuresList, filtersBySection } = global;

    const userGuiConfig = useUserGuiConfig()
    const replacePlaceHolder = useDataPlaceholder();

    const removeFilterFromWorkingFilters = () => {
        let newWorkingFilters = filters.active.filter(f => f.name !== filter.name);

        if (filter.name === "AgeSince") {
            const ageUntil = filters.active.find(f => f.name === "AgeUntil");
            if (ageUntil) {
                newWorkingFilters = newWorkingFilters.filter(f => f.name !== "AgeUntil");
            }
        }
        if (filter.name === "AgeUntil") {
            const ageSince = filters.active.find(f => f.name === "AgeSince");
            if (ageSince) {
                newWorkingFilters = newWorkingFilters.filter(f => f.name !== "AgeSince");
            }
        }
        if (filter.name === "AddedSince") {
            const addedUntil = filters.active.find(f => f.name === "AddedUntil");
            if (addedUntil) {
                newWorkingFilters = newWorkingFilters.filter(f => f.name !== "AddedUntil");
            }
        }
        if (filter.name === "AddedUntil") {
            const addedSince = filters.active.find(f => f.name === "AddedSince");
            if (addedSince) {
                newWorkingFilters = newWorkingFilters.filter(f => f.name !== "AddedSince");
            }
        }
        
        cs_dispatch({ type: 'ChangeFilters', filters: newWorkingFilters })
        cs_dispatch({ type: 'ApplyWorkingFilters' })
    };


    const formatFilterValue = (type, value) => {
        switch (type) {
            case "period":
                if (typeof value === "string") {
                    const range = replacePlaceHolder(value);
                    const toRenderrange = range[0] === range[1]
                        ? getMoment(range[0]).format('DD MMM YYYY')
                        : `${getMoment(range[0]).format('DD MMM YYYY')} - ${getMoment(range[1]).format('DD MMM YYYY')}`;
                    const match = value.match(/\{(\w+)\}/);
                    let periodstring = match ? placeholderToLabel(match[0]) : value;
                    return `${periodstring} (${toRenderrange})`
                } else {
                    return `(${getMoment(value[0]).format('DD MMM YYYY')} - ${getMoment(value[1]).format('DD MMM YYYY')})`;
                }
            case "date":
                if (typeof value === "string") {
                    const match = value.match(/\{(\w+)\}/);
                    return match ? placeholderToLabel(match[0]) : value;
                } else {
                    return value ? getMoment(value).format('MMM DD YYYY') : '';
                }
            case "booker-selector":
                const booker = cachedList.users.items.find(el => el.ID === value);
                if (booker) {
                    return `${booker.Name} ${booker.Surname}`;
                }
                return "";
            case "model-selector":
            case "model-selector-including-scouting":
            case "customer-selector":
            case "service-selector":
            case "contact-selector":
            case "client-selector":
            case "agency-selector":
                return getContactName(value);
            case "filter-yes-no-all":
                return value === true ? 'Yes' : value === false ? 'No' : 'All';
            case "gender":
                return value;
            case "models-tag":
            case "customers-tag":
                const tags = [];
                const cachedtags = cachedList.publictags.items.concat(cachedList.privatetags.items)
                value.forEach(t => {
                    const tag = cachedtags.find(el => el.ID === t);
                    tags.push(tag.Name);
                });
                return tags.join(', ');
            case "countries-selector":
                const countries = [];
                value.forEach(c => {
                    const country = cachedList.countries.items.find(el => el.Code === c);
                    countries.push(country.Name);
                });
                return countries.join(', ');
            case "cities-selector":
                return value.join(', ');
            case "intown-selector":
                if (value) {
                    return "In Town"
                } else {
                    return "Out of Town"
                }
            case "boolean":
                return value ? "yes" : "no";
            default:
                return value;
        }
    };

    const filterObj = findFilterInFiltersBySection(filter.name, filtersBySection);

    if (filterObj) {
        if (filterObj.source === 'customField') {
            let value = filter.value;
            if (filterObj.type === "MultipleChoice" || filterObj.type === "Choice") {
                value = value.join(', ');
            } else if (filterObj.type === 'Numeric') {
                value = value.join(' - ');
            } else if (filterObj.type === 'Boolean') {
                value = filter.value ? 'True' : 'False';
            } else if (filterObj.type === 'Date') {
                if (Array.isArray(filter.value)) {
                    value = `${toISODate(filter.value[0])} / ${toISODate(filter.value[1])}`;
                } else {
                    value = filter.value ? showDate(filter.value) : '';
                }
            }
            return (
                <Wrapper>
                    <div className="texts-wrapper">
                        <div className="label">{filter.name.replace(/_/g, ' ')}</div>
                        <div className="value">{value}</div>
                    </div>
                    <div className="remove-button-wrapper">
                        <NwIcon pointer icon={light("times")} onClick={removeFilterFromWorkingFilters} />
                    </div>
                </Wrapper>
            )
        }
        if (filterObj.source === "feature" && featuresList) {
            const feature = findFeature(filter.name, featuresList);
            const valuetowrite = [];
            switch (feature.TypeName) {
                case 'Conversion':
                    const conversion = userGuiConfig.metrics.find(
                        conversion => conversion.ID === feature.ConversionID
                    );
                    const groupedConversionValues = groupConversionValues(conversion.ConversionValues);
                    const conversionValues = groupedConversionValues.map(conversionValue => {
                        return {
                            ...conversionValue,
                            Metrics: conversionValue.Metrics.map(metric => ({ ...metric, metric: metric.Metric, value: metric.Value }))
                        }
                    });
                    const stepMin = conversionValues.find(
                        step => step.Key === filter.value[0]
                    );
                    const stepMax = conversionValues.find(
                        step => step.Key === filter.value[1]
                    );
                    const minVal = getDisplayValues(
                        stepMin.Metrics,
                        conversion.Name,
                        false,
                        userGuiConfig,
                        " - "
                    );
                    const maxVal = getDisplayValues(
                        stepMax.Metrics,
                        conversion.Name,
                        false,
                        userGuiConfig,
                        " - "
                    );
                    valuetowrite.push(`Min: ${minVal}`)
                    valuetowrite.push(`Max: ${maxVal}`)
                    break;
                case 'Numeric':
                    valuetowrite.push(`Min: ${filter.value[0]}`)
                    valuetowrite.push(`Max: ${filter.value[1]}`)
                    break;
                case "Rating":
                    const stringRating = filter.value && "*".repeat(parseInt(filter.value))
                    valuetowrite.push(`at least ${stringRating}`)
                    break;
                case "Multichoice":
                    const multiChoices = feature.Choices.filter(choice => filter.value.choices.includes(choice.ID));
                    const multiChoicesString = multiChoices.map(choice => choice.Value).join(filter.value.operator ? (filter.value.operator === 'and' ? " + " : " or ") : '+')
                    valuetowrite.push(`${feature.Name}:  ${multiChoicesString}`)
                    break;
                case "Choice":
                    const choices = feature.Choices.filter(choice => filter.value.includes(choice.ID));
                    const choicesString = choices.map(choice => choice.Value).join(", ")
                    valuetowrite.push(`${feature.Name}:  ${choicesString}`)
                    break;
                case "Boolean":
                    valuetowrite.push(`${feature.Name}: ${filter.value === null ? "?" : (filter.value ? "Yes" : "No")}`)
                    break;
                default:
                    valuetowrite.push(filter.value)
                    break;
            }
            return (
                <Wrapper>
                    <div className="texts-wrapper">
                        <div className="label">{filterObj.label}</div>
                        <div className="value">
                            {valuetowrite.map((value, index) => (
                                <span key={index}>{value}</span>
                            ))}
                        </div>
                    </div>
                    <div className="remove-button-wrapper">
                        <NwIcon pointer icon={light("times")} onClick={removeFilterFromWorkingFilters} />
                    </div>
                </Wrapper>
            )
        }
        if (filterObj.source === "filter" && filterObj.type === "intown-selector") {
            if (filter.value) {
                return (
                    <Wrapper>
                        <div className="texts-wrapper">
                            <div className="label">In Town</div>
                        </div>
                        <div className="remove-button-wrapper">
                            <NwIcon pointer icon={light("times")} onClick={removeFilterFromWorkingFilters} />
                        </div>
                    </Wrapper>
                )
            } else {
                return (
                    <Wrapper>
                        <div className="texts-wrapper">
                            <div className="label">Out of Town</div>
                        </div>
                        <div className="remove-button-wrapper">
                            <NwIcon pointer icon={light("times")} onClick={removeFilterFromWorkingFilters} />
                        </div>
                    </Wrapper>
                )
            }
        }
        if (filterObj.source === "filter" && filterObj.name === "AgeSince") {
            //if filters includes AgeSince and AgeUntil, we will show the range
            const ageUntil = filters.active.find(f => f.name === "AgeUntil");
            if (ageUntil) {
                return (
                    <Wrapper>
                        <div className="texts-wrapper">
                            <div className="label">Age</div>
                            <div className="value">{filter.value} - {ageUntil.value}</div>
                        </div>
                        <div className="remove-button-wrapper">
                            <NwIcon pointer icon={light("times")} onClick={removeFilterFromWorkingFilters} />
                        </div>
                    </Wrapper>
                )
            }
            return (
                <Wrapper>
                    <div className="texts-wrapper">
                        <div className="label">{filterObj.label}</div>
                        <div className="value">{filter.value}</div>
                    </div>
                    <div className="remove-button-wrapper">
                        <NwIcon pointer icon={light("times")} onClick={removeFilterFromWorkingFilters} />
                    </div>
                </Wrapper>
            )
        }
        if (filterObj.source === "filter" && filterObj.name === "AgeUntil") {
            //if filters includes AgeSince I skip the rendering of AgeUntil
            if (filters.active.some(f => f.name === "AgeSince")) {
                return <></>
            }
            return (
                <Wrapper>
                    <div className="texts-wrapper">
                        <div className="label">{filterObj.label}</div>
                        <div className="value">{filter.value}</div>
                    </div>
                    <div className="remove-button-wrapper">
                        <NwIcon pointer icon={light("times")} onClick={removeFilterFromWorkingFilters} />
                    </div>
                </Wrapper>
            )
        }
        //same for AddedSince and AddedUntil
        if (filterObj.source === "filter" && filterObj.name === "AddedSince") {
            const addedUntil = filters.active.find(f => f.name === "AddedUntil");
            if (addedUntil) {
                return (
                    <Wrapper>
                        <div className="texts-wrapper">
                            <div className="label">Added</div>
                            <div className="value">{showDateNoTime(replacePlaceHolder(filter.value), true)} - {showDateNoTime(replacePlaceHolder(addedUntil.value), true)}</div>
                        </div>
                        <div className="remove-button-wrapper">
                            <NwIcon pointer icon={light("times")} onClick={removeFilterFromWorkingFilters} />
                        </div>
                    </Wrapper>
                )
            }
            return (
                <Wrapper>
                    <div className="texts-wrapper">
                        <div className="label">{filterObj.label}</div>
                        <div className="value">{showDateNoTime(replacePlaceHolder(filter.value), true)}</div>
                    </div>
                    <div className="remove-button-wrapper">
                        <NwIcon pointer icon={light("times")} onClick={removeFilterFromWorkingFilters} />
                    </div>
                </Wrapper>
            )
        }
        if (filterObj.source === "filter" && filterObj.name === "AddedUntil") {
            if (filters.active.some(f => f.name === "AddedSince")) {
                return <></>
            }
            return (
                <Wrapper>
                    <div className="texts-wrapper">
                        <div className="label">{filterObj.label}</div>
                        <div className="value">{showDateNoTime(replacePlaceHolder(filter.value), true)}</div>
                    </div>
                    <div className="remove-button-wrapper">
                        <NwIcon pointer icon={light("times")} onClick={removeFilterFromWorkingFilters} />
                    </div>
                </Wrapper>
            )
        }

        return (
            <Wrapper>
                <div className="texts-wrapper">
                    <div className="label">{filterObj.label}</div>
                    <div className="value">{formatFilterValue(filterObj.type, filter.value)}</div>
                </div>
                <div className="remove-button-wrapper">
                    <NwIcon pointer icon={light("times")} onClick={removeFilterFromWorkingFilters} />
                </div>
            </Wrapper>
        );
    }
    return <></>
};

export default ContactsSelectedFilterItem;
