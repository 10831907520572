import { Tabs, Row } from 'antd';
import styled from 'styled-components';


export const ProjectTransactionFormContainer = styled.div`
    .previsional {
        .label {
            font-size: ${props => props.theme.FontSizeSm};
            margin-bottom: .5rem;
            text-transform: uppercase;
        }
        .transaction-item {
            margin-bottom: .5rem;
        }
        .amount {
            color: ${props => props.theme.PrimaryColor};
            text-decoration: underline;
            cursor: pointer;
            font-weight: bold;
        }
        .description {
            font-size: 14px;
        }
        margin-bottom: 16px;
    }
    .model-wrapper {
        margin-bottom: 16px;

        .model-name {
            margin-bottom: 16px;
            font-size: 18px;
            font-weight: bold;
        }
        .event-name {
            margin-left: 16px;
        }
    }
    .rate-form {
        border-right: ${props => props.theme.LighterBorder};
    }
    .hours-container {
        .nw-form-item-container {
            margin-left: 8px;
            margin-right: 8px;
            .ant-form-item {
                margin-bottom: 0;
            }
        }
    }
    .event-container {
        width: calc(100% - 120px);
    }
    .event-hours {
        margin-left: 50%;
    }
    .inclusive-agency-fee {
        background-color: ${props => props.theme.LighterBorderColor};
        padding: .5rem 1rem .25rem 1rem;
        border-radius: 4px;
        border: ${props => props.theme.DefaultBorder};
        margin: .5rem 0;
        span {
            font-size: ${props => props.theme.FontSizeSm};
            button {
                margin-right: 1rem;
            }
        }
    }
    .payroll {
        background-color: ${props => props.theme.LighterBorderColor};
        padding: .5rem 1rem .75rem 1rem;
        border-radius: 4px;
        border: ${props => props.theme.DefaultBorder};
        margin: .5rem 0;
        span {
            font-size: ${props => props.theme.FontSizeSm};
            button {
                margin-right: 1rem;
            }
        }
        .payroll-amount {
            display: block;
            margin: .5rem 1rem 0 4rem;
        }
        .payroll-not-allowed {
            color: ${props => props.theme.DangerColor};
            font-size: ${props => props.theme.FontSizeSm};
            margin: .15rem 1rem 0 4rem;
        }
    }
`;

export const AddNewLink = styled.div`
    cursor: pointer;
    text-decoration: underline;
    color: ${props => props.theme.PrimaryColor};
`;

export const UsageContainer = styled.div`
    margin-bottom: 24px;

    .ant-form-item {
        margin-bottom: 4px !important;
    }
`;

export const ModelSelectorContainer = styled.div`
    margin: .5rem 0;

    h1 {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    h2 {
        font-size: 1.2rem;
        margin-bottom: .5rem;
    }

    .button-bar {
        margin-bottom: 1rem;
        padding: .5rem;
        background-color: ${props => props.theme.LighterBorderColor};
        border-radius: 4px;
        border: ${props => props.theme.DefaultBorder};

        &.button-bar-mini {
            margin-bottom: .5rem;
            padding: .15rem .5rem;
            .ant-btn {
                font-size: ${props => props.theme.FontSizeSm};
                line-height: 1.2em;
            }
        }

        .set-hours {
            margin-top: 1rem;
        }
        .group-days {
            margin-top: .5rem;
        }
    }

    .models {
        .model {
            padding: .5rem 0 .5rem .25rem;

            &.is-cancelled {
                ${props => props.theme.RedLineThrough(2)}
            }
            &.is-confirmed {
               font-weight: bold;
            }

            .model-name-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: .5rem;

                h2 {
                    margin-bottom: 0;
                    line-height: 1.1em;
                }
                div.all-selectors {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    flex-wrap: nowrap;
                }
            }

            .model-general {
                margin-bottom: 1rem;
            }

            .model-date {
                padding: .25rem 0;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;

                .event-details {
                    .event-details-name {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        &.is-cancelled {
                            ${props => props.theme.RedLineThrough(2)}
                        }
                    }
                    .event-details-date {
                        font-weight: bold;
                        span {
                            display: block;
                        }
                    }
                    .event-details-days-quantity {
                        padding: .25rem;
                    }
                    .event-details-hours-quantity {
                        padding: .25rem;
                    }
                }
            }
        }
    }

    
`;

export const TransactionDrawerContent = styled.div`
    padding-bottom: 10rem;
    
    .ant-segmented {
        margin-bottom: 2rem;
        .ant-segmented-item-label {
            div {
                padding: .75rem .25rem .5rem .25rem;
                h2 {
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 1rem;
                    line-height: 1.25em;
                    margin: 0;
                    padding: 0;

                    span {
                        font-size: .75rem;
                        vertical-align: top;
                        padding: 0 0.5rem;
                    }
                }
                small {
                    margin: 0;
                    padding: 0;
                    line-height: 1.1em;
                    overflow: none;
                    text-overflow: none;
                    white-space: normal;
                    display: block;
                }
            }
        }
    }
`;

export const StyledTabs = styled(Tabs)`
    padding-bottom: 4rem;
    
    .ant-tabs-nav-list {    
        justify-content: flex-start !important;
    }

    .ant-tabs-nav {
        position: initial !important;
        right: unset !important;
        top: unset !important;
        width: unset !important;
        z-index: unset !important;
        margin: unset !important;

        .ant-tabs-nav-wrap {
            padding-left: 4rem;
            border-bottom: 1px solid #f0f0f0;
        }
        .ant-tabs-extra-content {
            padding: .75rem .75rem 0 0;
            border-bottom: 1px solid #f0f0f0;
            min-height: 3.2rem;
        }
    }
`

export const ProjectTransactionListContainer = styled.div`
    .families-selector {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: .75rem 1rem 0 .75rem;
    }

    .billable-trigger {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 1.5rem 1rem 0 0;
    }


    .subject-container {
        
        border-bottom: ${props => props.theme.DefaultBorder};
        margin-top: 2rem;

        &:first-of-type {
            margin-top: 0;
        }

        h2 {
            padding: 1rem 0 .25rem 1.5rem;
            font-size: 1.15rem;
            font-weight: 600;
            text-transform: capitalize;
            margin-bottom: 0;
        }

        .date-container {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            border-top: ${props => props.theme.DefaultBorder};

            .vertical-date-container {
                width: 5rem;
                padding-top: .75rem;
            }

            .dates-without-transactions {
                padding: 1rem 1.5rem 1rem .25rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 1rem;
                
                div.icon-wrapper {
                    font-size: 2rem;
                    padding-left: 1rem;
                }

                p {
                    margin: 0;
                    font-weight: bold;
                    max-width: 11rem;
                }
                div.dates-wrapper {
                    flex: 1 1 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    .date-item {
                        padding: .15rem .5rem;
                        margin: 0 .25rem .25rem 0;
                        background-color: ${props => props.theme.LighterBorderColor};
                        border-radius: 4px;
                        white-space: nowrap;
                        display: inline-block;
                    }
                }
            }
        }

        .billinginfo-line {
            display: block;
            
            .billinginfo-header {
                //margin-top: .75rem;
                padding: 0 1rem .75rem 2rem;
                //border-top: ${props => props.theme.DefaultBorder};
                border-bottom: ${props => props.theme.DefaultBorder};
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .billinginfo-header-left {
                    padding-right: 2rem;
                }
                .billinginfo-header-middle {
                    flex: 1 1 0;
                    h2 {
                        padding: 0;
                        margin: 0;
                    }
                }
                .billinginfo-header-right {
                    padding-right: 1rem;
                    
                }
            }
        }

        .transactions-container {
            flex: 1 1 0;
            border-left: ${props => props.theme.DefaultBorder};
            padding: 0;
            min-height: 4rem;

            .ant-table {
                table {
                    border-collapse: collapse;
                    tr {

                        &.cancelled {
                            td {
                                .transaction-title, .transaction-left, .total-fee {
                                    ${props => props.theme.RedLineThrough(1)};
                                }
                            }
                        }
                        &.not-confirmed {
                            td {
                                opacity: .5;
                            }
                        }

                        td {
                            vertical-align: top;
                            border-bottom: none;
                            padding: 0;
                            cursor: zoom-in;
                            border-top: ${props => props.theme.LighterBorder};

                            &.cell-border-left {
                                border-left: ${props => props.theme.DefaultBorder};
                            }
                            &.cell-border-right {
                                border-right: ${props => props.theme.DefaultBorder};
                            }

                            &.ant-table-selection-column {
                                vertical-align: middle;
                                padding: 0 1rem 0 1.5rem;
                                cursor: default;

                                .selection-wrapper {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    min-width: 2.5rem;
                                }
                            }
                            .transaction-date {
                                min-width: 3rem;
                                min-height: 4rem;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;

                                &.commission-row, &.advance-row {
                                    min-height: 2.7rem;
                                    display: block;
                                    margin-top: -2px;
                                    background-color: white;
                                }
                            }
                            .transaction-status {
                                display: flex;
                                justify-content: space-around;
                                align-items: center;
                                min-height: 3.5rem;
                                width: 3rem; 
                                margin: 0 auto;
                            }
                            .transaction-left {
                                padding: .75rem;
                                small {
                                    display: block;
                                    line-height: 1.5;
                                    text-transform: uppercase;
                                }
                                .transaction-model {
                                    text-transform: uppercase;
                                }
                            }
                            .transaction-middle {
                                padding: .75rem;
                            }
                            .transaction-right {
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                text-align: right;
                                
                                .transaction-fee {
                                    border-right: ${props => props.theme.DefaultBorder};
                                    padding: .5rem .75rem .5rem .5rem;
                                    min-height: 4rem;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-end;
                                    justify-content: space-between;
                                    min-width: 15rem;
                                    height: 100%;
                                    white-space: nowrap;

                                    small {
                                        display: block;
                                    }

                                    .total-fee {
                                        font-size: 1rem;
                                        font-weight: bold;
                                    }
                                    .statement-fee {
                                        font-weight: bold;
                                    }
                                }

                                .edit-button {
                                    cursor: pointer;
                                    min-width: 50px;
                                }

                                &.commission-row, &.advance-row {
                                    .transaction-fee {
                                        padding: .25rem .75rem .25rem .5rem;
                                        min-height: 2.5rem;
                                    }
                                    .edit-button {
                                        margin-top: -2px;
                                        background-color: white;
                                        min-height: 2.5rem;
                                    }
                                }
                            }
                        }

                        &.ant-table-row-selected > td {
                            .transaction-date {
                                &.commission-row, &.advance-row {
                                    background-color: ${props => props.theme.HoverablesBackground};
                                }
                            }
                            .transaction-right {
                                &.commission-row, &.advance-row {
                                    .edit-button {
                                        background-color: ${props => props.theme.HoverablesBackground};
                                    }
                                }
                            }
                        }

                        :nth-of-type(1) {
                            td {
                                border-top: none;
                            }
                        }
                    }
                }
            }

            p {
                font-size: 1rem;
                margin-bottom: 0;
            }

            &.transactions-container-statements {
                table {
                    tr {
                        td {
                            border-bottom: ${props => props.theme.LighterBorder};

                            &:nth-of-type(2) {
                                vertical-align: middle;
                            }
                        }
                        &:nth-of-type(2) {
                            td {
                                border-top: none;
                            }
                        }
                    }
                }

                &.read-only {
                    .ant-table {
                        table {
                            tr {
                                td {
                                    .transaction-date {
                                        min-width: 6rem;
                                        padding-left: 2rem;
                                        border-left: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .transactions-list-for-event-drawer {
        
        h1 {
            padding: 1rem 0 .5rem 1.25rem;
            font-size: 1.25rem;
            font-weight: 600;
            text-transform: capitalize;
            margin: 0;
        }
        
        .subject-container {
            h2 {
                font-size: 1.1rem;
            }

            .transactions-container {
                table {
                    tr {
                        td {
                            .transaction-left {

                                .transaction-title {
                                    small {
                                        display: inline-block;
                                        padding-bottom: .15rem;
                                        padding-right: .2rem;
                                        vertical-align: middle;
                                    }
                                }

                               
                            }
                        }
                    }
                }
            }
        }
    }

    .actions-on-selected-lines {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 2rem;

        .selected-lines-counter {
            margin-right: 2rem;
            text-transform: uppercase;
        }
    }

    .transactions-status-legend-container {
        margin: 4rem 1rem 1rem 1rem;
    }
`

export const StyledRow = styled(Row)`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const CostContainer = styled.div`
  margin-bottom: 24px;
`;

export const EventLabelPanel = styled.div`
  display: flex;
  align-items: center;
`;

export const EventName = styled.div`
  margin-left: 8px;
`;