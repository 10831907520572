import React from "react";
import { Form } from 'antd';
import {
  Input,
  Select,
} from "antd";

import WithCharCounter from "Components/Gui/WithCharCounter";
import { SelectCountry } from "Components/Gui/NwForm/SelectCountry";
import DepartmentSelector from "Components/Gui/DepartmentSelector";
import { SelectCountryMulti } from "Components/Gui/NwForm/SelectCountryMulti";
import { GenderSelector } from "Components/Gui/NwForm/GenderSelector";
import { AgencyTypeSelector } from "Components/Gui/NwForm/AgencyTypeSelector";
//import CurrencySelector from "Components/Gui/NWForm/CurrencySelector";
import QuantitySelector from "Components/Gui/NwForm/QuantitySelector";
import BooleanSelector from "Components/Gui/NwForm/BooleanSelector";
import ContactDropdown from "Components/ContactDropdown/ContactDropdown";
import BookerSelector from "Components/BookerSelector/BookerSelector";
import FilterDateInput from "Components/Filters/FilterDateInput";
import FilterPeriodInput from "Components/Filters/FilterPeriodInput";
import InputTag from "Components/Gui/NwForm/InputTag";
import FeaturesRangeSlider from "Components/Gui/NwForm/FeaturesRangeSlider";
import NumericRangeSelector from "Components/Gui/NwForm/NumericRangeSelector";
import FeaturesMultichoice from "Components/Gui/NwForm/FeaturesMultichoice";
import FeaturesChoice from "Components/Gui/NwForm/FeaturesChoice";
import FeaturesTrans from "Components/Gui/NwForm/FeaturesTrans";
import FeaturesBool from "Components/Gui/NwForm/FeaturesBool";
import CitySelector from "Components/CitySelector/CitySelector";
import CitySelectorMulti from "Components/CitySelectorMulti/CitySelectorMulti";
import InTownSelector from "Components/Gui/NwForm/InTownSelector";
import PaymentTermSelector from "Components/Gui/NwForm/PaymentTermSelector";
import BankAccountsSelector from "Components/Gui/NwForm/BankAccountsSelector";
import ProjectSelector from "Components/ProjectSelector/ProjectSelector";
import TransactionInvoiceTypeSelector from "Components/Gui/NwForm/TransactionInvoiceTypeSelector";
import ModelAgencyTypeSelector from "Components/Gui/NwForm/ModelAgencyTypeSelector";
import ColorPicker from "Components/Gui/NwForm/ColorPicker";
import MultiSelect from "Components/Gui/NwForm/MultiSelect";
import Rating from "Components/Gui/NwForm/Rating";
import EditFolder from "Components/Gui/NwForm/EditFolder";
import FollowUpLabel from "Components/Gui/NwForm/FollowUpLabel";
import EventDateTimePicker from "Components/EventDateTimePicker/EventDateTimePicker";
import PrivacyLevelSelector from "Components/Gui/NwForm/PrivacyLevelSelector";
import RatingSelector from "Components/Gui/NwForm/RatingSelector";
import SelectTransport from "Components/Gui/NwForm/SelectTransport";
import BirthDatePicker from "./BirthDatePicker";
import EventTypeSelector from 'Components/Gui/NwForm/EventTypeSelector';
import AddressSelector from 'Components/Gui/NwForm/AddressSelector';
import AgencyDepartmentSelector from 'Components/AgencyDepartmentSelector/AgencyDepartmentSelector';
import RateTypeSelector from 'Components/Gui/NwForm/RateTypeSelector';
import FilterYesNoAllSelector from 'Components/Gui/NwForm/FilterTypeSelector';
import ColorSelector from 'Components/Gui/NwForm/ColorSelector';
import AreaSelector from 'Components/Gui/NwForm/AreaSelector';
import GoogleCities from 'Components/GoogleCities/GoogleCities';
import VisibilitySelector from 'Components/Gui/NwForm/VisibilitySelector';
import RadioButtonGroup from 'Components/Gui/NwForm/RadioButtonGroup';
import DisableDatePicker from 'Components/EventDateTimePicker/DisableDatePicker';
import SwatchPicker from 'Components/Gui/NwForm/SwatchPicker';
import CustomSwitch from 'Components/Gui/CustomSwitch';
import PrioritySelector from 'Components/Gui/NwForm/PrioritySelector';
import LocationDateSelector from 'Components/Gui/NwForm/LocationDateSelector';
import PeriodSelector from 'Components/Gui/NwForm/PeriodSelector';
import ChoicesSelector from 'Components/Gui/NwForm/ChoicesSelector';
import Checkbox from 'Components/Gui/NwForm/Checkbox';
import EventDatesPicker from "Components/EventDatesPicker/EventDatesPicker";
import EventPeriodPicker from 'Components/EventDatesPicker/EventPeriodPicker';
import LongEventPeriodPicker from 'Components/EventDatesPicker/LongEventPeriodPicker';
import CheckDatePeriodPicker from 'Components/EventDatesPicker/CheckDatePeriodPicker';
import MultiInput from 'Components/Gui/NwForm/MultiInput';
import MultiChoice from 'Components/Gui/NwForm/MultiChoices';
import ConversionSelector from 'Components/Gui/NwForm/ConversionSelector';
import CustomDatePicker from 'Components/Gui/NwForm/CustomDatePicker';
import AddressForm from 'Components/Gui/NwForm/AddressForm';
import DateRangePicker from 'Components/Gui/NwForm/DateRangePicker';
import LocationSelector from 'Components/Gui/NwForm/LocationSelector/LocationSelector';
import { FormInput, FormInputNumber, /*FormSelect,*/ FormAutoComplete, FormTimePicker, FormRangePicker, FormTextArea } from 'Components/Gui/NwForm/FormInputs';
import CustomSelect from 'Components/Gui/NwForm/CustomSelect';
import InputTransaction from "Components/Gui/NwForm/InputTransaction";
import CategorySelect from "Components/Gui/NwForm/CategorySelect";
import CurrencyRange from "Components/Gui/NwForm/CurrencyRange";
import TransactionFilter from "Components/Gui/NwForm/TransactionFilter";
import EmptyDateInput from "Components/Gui/NwForm/EmptyDateInput";
import PaymentStatusSelector from "Components/Gui/NwForm/PaymentStatusSelector";
import TransactionStatusSelector from "Components/Gui/NwForm/TransactionStatusSelector";
import StatementContactSelector from "Components/Gui/NwForm/StatementContactSelector";
import FamilySelector from "Components/Gui/NwForm/FamilySelector";
import LabelSelector from "Components/Gui/NwForm/LabelSelector";
import TaxSelector from "Components/Gui/NwForm/TaxSelector";
import VatSelector from "Components/Gui/NwForm/VatSelector";
import StatusSelector from "Components/Gui/NwForm/StatusSelector";
import PackageDateTypeSelector from "Components/Gui/NwForm/PackageDateTypeSelector";
import ModelPlayListSelector from "Components/Gui/NwForm/ModelPlayListSelector";
import DocumentFilterSelector from "Components/Gui/NwForm/DocumentFilterSelector";
import ContactWithBillingInfo from "Components/Gui/NwForm/ContactWithBillingInfo";
import NonBookingEventSelectorForTransactions from "Components/Transactions/TransactionsCreateForms/Common/NonBookingEventSelectorForTransactions";
import BookingEventSelectorForTransactions from "Components/Transactions/TransactionsCreateForms/Common/BookingEventSelectorForTransactions";
import AmountInput from "./AmountInput";
import FilterFollowUpsOnLabels from "Components/Gui/NwForm/FilterFollowUpsOnLabels";
import FilterEventsOnSubTypes from "Components/Gui/NwForm/FilterEventsOnSubTypes";
import FilterEventsOnSubTypesAndOptionNumber from "Components/Gui/NwForm/FilterEventsOnSubTypesAndOptionNumber";
import FilterTravelsOnTransportTypes from "Components/Gui/NwForm/FilterTravelsOnTransportTypes";
import ContactWithBillingInfoExtended from "./ContactWithBillingInfoExtended";
import SignatureSelector from "./SignatureSelector";
import CustomerTypeSelector from "./CustomerTypeSelector";
import AccountCodeSelector from "Components/Gui/NwForm/AccountCodeSelector";

const FormItem = Form.Item;
const { Option } = Select;
const TextAreaWithCharCounter = WithCharCounter(FormTextArea);

const CreateNWField = AntComponent => ({
  field,
  form,
  hasFeedback = true,
  label,
  inlineLabel,
  selectOptions,
  submitCount,
  type,
  onAfterChange,
  onAfterBlur,
  manipulateValue,
  innerRef,
  ...props
}) => {

  const touched = form.touched[field.name];

  const submitted = submitCount > 0;

  const hasError = form.errors[field.name];

  const submittedError = hasError && submitted;

  const touchedError = hasError && touched;

  const onInputChange = ({ target: { value } }) => {
    form.setFieldValue(field.name, value);
    //form.setFieldTouched(field.name, true);
    if (onAfterChange) onAfterChange(value);
  }

  const onChange = value => {
    let _value = manipulateValue ? manipulateValue(value) : value;
    form.setFieldValue(field.name, _value);
    //form.setFieldTouched(field.name, true);
    if (onAfterChange) onAfterChange(_value);
  }

  const onBlur = () => {
    form.setFieldTouched(field.name, true);
    if (onAfterBlur) onAfterBlur(field.value)
  }

  const GetFormItemContainerClassName = () => {
    let cn = "nw-form-item-container"
    if (inlineLabel) {
      cn += " inline"
    }
    return cn
  }
  return (
    <div className={GetFormItemContainerClassName()}>
      <FormItem
        colon={false}
        label={label}
        className
        required={props.required}
        help={submittedError || touchedError ? hasError : false}
        validateStatus={submittedError || touchedError ? "error" : "success"}
      >
        <AntComponent
          {...field}
          {...props}
          ref={innerRef}
          onChange={type ? onInputChange : onChange}
          onBlur={onBlur}
        >
          {selectOptions &&
            selectOptions.map(opt => (
              <Option key={opt.key} value={opt.value} /*title={opt.label}*/>
                {opt.label}
              </Option>
            ))}
        </AntComponent>
      </FormItem>
    </div>
  );
};

export const NWSelect = CreateNWField(CustomSelect);
export const NWPassword = CreateNWField(Input.Password);
export const NWTimePicker = CreateNWField(FormTimePicker);
export const NWGenderSelector = CreateNWField(GenderSelector);
export const NWContactDropdown = CreateNWField(ContactDropdown);
export const NWAgencyTypeSelector = CreateNWField(AgencyTypeSelector);
export const NWInputNumber = CreateNWField(FormInputNumber);
export const NWDatePicker = CreateNWField(CustomDatePicker);
export const NWSwitch = CreateNWField(CustomSwitch);
export const NWCheckbox = CreateNWField(Checkbox);
export const NWInput = CreateNWField(FormInput);
export const NWSelectCountry = CreateNWField(SelectCountry);
export const NWSelectCountryMulti = CreateNWField(SelectCountryMulti);
export const NWCitySelector = CreateNWField(CitySelector);
export const NWCitySelectorMulti = CreateNWField(CitySelectorMulti);
export const NWTextAreaCharCounter = CreateNWField(TextAreaWithCharCounter);
export const NWAutoComplete = CreateNWField(FormAutoComplete);
export const NWFilterDateInput = CreateNWField(FilterDateInput);
export const NWFilterPeriodInput = CreateNWField(FilterPeriodInput);
export const NWEmptyDateInput = CreateNWField(EmptyDateInput);
export const NWBookerSelector = CreateNWField(BookerSelector);
export const NWAmountInput = CreateNWField(AmountInput);
export const NWQuantitySelector = CreateNWField(QuantitySelector);
export const NWInputTag = CreateNWField(InputTag);
export const NWInputTransaction = CreateNWField(InputTransaction);
export const NWCategorySelect = CreateNWField(CategorySelect);
export const NWCurrencyRange = CreateNWField(CurrencyRange);
export const NWBooleanSelector = CreateNWField(BooleanSelector);
export const NWDepartmentSelector = CreateNWField(DepartmentSelector);
export const NWFeaturesRangeSlider = CreateNWField(FeaturesRangeSlider);
export const NWNumericRangeSelector = CreateNWField(NumericRangeSelector);
export const NWFeaturesMultichoice = CreateNWField(FeaturesMultichoice);
export const NWFeaturesChoice = CreateNWField(FeaturesChoice);
export const NWFeaturesTrans = CreateNWField(FeaturesTrans);
export const NWFeaturesBool = CreateNWField(FeaturesBool);
export const NWInTownSelector = CreateNWField(InTownSelector);
export const NWProjectSelector = CreateNWField(ProjectSelector);
export const NWTransactionInvoiceTypeSelector = CreateNWField(TransactionInvoiceTypeSelector);
export const NWModelAgencyTypeSelector = CreateNWField(ModelAgencyTypeSelector);
export const NWColorPicker = CreateNWField(ColorPicker);
export const NWMultiSelect = CreateNWField(MultiSelect);
export const NWSelectTransport = CreateNWField(SelectTransport);
export const NWEditFolder = CreateNWField(EditFolder);
export const NWRatingSelector = CreateNWField(RatingSelector);
export const NWRate = CreateNWField(Rating);
export const NWFollowUpLabel = CreateNWField(FollowUpLabel);
export const NWEventDateTimePicker = CreateNWField(EventDateTimePicker);
export const NWPrivacyLevelSelector = CreateNWField(PrivacyLevelSelector);
export const NWBirthDatePicker = CreateNWField(BirthDatePicker);
export const NWEventTypeSelector = CreateNWField(EventTypeSelector);
export const NWAddressSelector = CreateNWField(AddressSelector);
export const NWAgencyDepartmentSelector = CreateNWField(AgencyDepartmentSelector);
export const NWRateTypeSelector = CreateNWField(RateTypeSelector);
export const NWFilterYesNoAllSelector = CreateNWField(FilterYesNoAllSelector);
export const NWColorSelector = CreateNWField(ColorSelector);
export const NWAreaSelector = CreateNWField(AreaSelector);
export const NWGoogleCities = CreateNWField(GoogleCities);
export const NWRangePicker = CreateNWField(FormRangePicker);
export const NWVisibilitySelector = CreateNWField(VisibilitySelector);
export const NWRadioButtonGroup = CreateNWField(RadioButtonGroup);
export const NWDisableDatePicker = CreateNWField(DisableDatePicker);
export const NWSwatchPicker = CreateNWField(SwatchPicker);
export const NWPrioritySelector = CreateNWField(PrioritySelector);
export const NWLocationDateSelector = CreateNWField(LocationDateSelector);
export const NWPeriodSelector = CreateNWField(PeriodSelector);
export const NWChoicesSelector = CreateNWField(ChoicesSelector);
export const NWEventDatesPicker = CreateNWField(EventDatesPicker);
export const NWEventPeriodPicker = CreateNWField(EventPeriodPicker);
export const NWLongEventPeriodPicker = CreateNWField(LongEventPeriodPicker);
export const NWCheckDatePeriodPicker = CreateNWField(CheckDatePeriodPicker);
export const NWMultiInput = CreateNWField(MultiInput);
export const NWMultiChoice = CreateNWField(MultiChoice);
export const NWConversionSelector = CreateNWField(ConversionSelector);
export const NWAddressForm = CreateNWField(AddressForm);
export const NWDateRangePicker = CreateNWField(DateRangePicker);
export const NWLocationSelector = CreateNWField(LocationSelector);
export const NWTransactionFilter = CreateNWField(TransactionFilter);
export const NWPaymentTermSelector = CreateNWField(PaymentTermSelector);
export const NWBankAccountsSelector = CreateNWField(BankAccountsSelector);
export const NWPaymentStatusSelector = CreateNWField(PaymentStatusSelector);
export const NWStatementContactSelector = CreateNWField(StatementContactSelector);
export const NWTransactionStatusSelector = CreateNWField(TransactionStatusSelector);
export const NWFamilySelector = CreateNWField(FamilySelector);
export const NWLabelSelector = CreateNWField(LabelSelector);
export const NWTaxSelector = CreateNWField(TaxSelector);
export const NWVatSelector = CreateNWField(VatSelector);
export const NWStatusSelector = CreateNWField(StatusSelector);
export const NWPackageDateTypeSelector = CreateNWField(PackageDateTypeSelector);
export const NWModelPlayListSelector = CreateNWField(ModelPlayListSelector);
export const NWDocumentFilterSelector = CreateNWField(DocumentFilterSelector);
export const NWContactWithBillingInfo = CreateNWField(ContactWithBillingInfo);
export const NWContactWithBillingInfoExtended = CreateNWField(ContactWithBillingInfoExtended);
export const NWNonBookingEventSelectorForTransactions = CreateNWField(NonBookingEventSelectorForTransactions);
export const NWBookingEventSelectorForTransactions = CreateNWField(BookingEventSelectorForTransactions);
export const NWFilterFollowUpsOnLabels = CreateNWField(FilterFollowUpsOnLabels);
export const NWFilterEventsOnSubTypes = CreateNWField(FilterEventsOnSubTypes);
export const NWFilterEventsOnSubTypesAndOptionNumber = CreateNWField(FilterEventsOnSubTypesAndOptionNumber);
export const NWFilterTravelsOnTransportTypes = CreateNWField(FilterTravelsOnTransportTypes);
export const NWSignatureSelector = CreateNWField(SignatureSelector);
export const NWCustomerTypeSelector = CreateNWField(CustomerTypeSelector);
export const NWAccountCodeSelector = CreateNWField(AccountCodeSelector);