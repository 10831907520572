import React, { useState } from "react";
import styled from 'styled-components';
// import { Badge } from "antd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

//import { useContact } from 'Hooks/Contact/UseContact';
import NwIcon from "Components/Gui/NwIcon";
import PermissionBlock from 'Components/PermissionBlock/PermissionBlock';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import BankAccountsDrawer from "Components/BankAccounts/BankAccountsDrawer";

const ContactLegalContainer = styled.p`
    text-transform: uppercase;
    margin-bottom: 0;
`

const ContactDetailsBankAccounts = ({ currentContact, readOnly }) => {
    const [drawer, setDrawer] = useState(false);
    //const { data: currentContact, isFetching } = useContact(contactId);
    const breakpoints = useNwBreakPoints();

    const renderContactBankAccountsContainer = () => {
        return currentContact.BankAccountsCount ?
            <ContactLegalContainer onClick={() => setDrawer(true)}>
                {breakpoints !== 1 &&
                    <>
                        <NwIcon icon={light("piggy-bank")} />
                        &nbsp;&nbsp;
                    </>
                }
                {currentContact.BankAccountsCount} {currentContact.BankAccountsCount > 1 ? 'bank account' : 'bank accounts'}
            </ContactLegalContainer>
            :
            <ContactLegalContainer onClick={() => setDrawer(true)}>
                <NwIcon icon={light("piggy-bank")} danger />&nbsp;&nbsp;no bank accounts
            </ContactLegalContainer>
    }

    return (
        <>
            {currentContact && 
                <div className="header-body-boxes header-body-boxes-hoverable">
                    <PermissionBlock
                        permission={true}
                        content={renderContactBankAccountsContainer()} />
                </div>
            }
            {drawer && <BankAccountsDrawer contactId={currentContact.ID} readOnly={readOnly} onClose={() => setDrawer(false)} />}
        </>
    )
};

export default ContactDetailsBankAccounts;
