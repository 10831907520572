import React, { useContext, useState } from 'react'
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from 'Components/Gui/NwIcon';
import ContactCreatorDrawer from "Components/ContactCreator/ContactCreatorDrawer";
import { StyledMenu } from "Components/_LayoutComponents/Header/NwHeaderUI";
import { HeaderContext } from './NwHeader';
//import { NavBarButtonsContainer, NavBarButtonsMobileContainer } from './NwHeaderUI';
import { useActivePackages } from 'Hooks/Packages/UsePackages';
import ls from 'local-storage';
import { Badge, Menu } from 'antd';
import AddPackageDrawer from 'Components/ActivePackages/AddPackageDrawer';
import styled from 'styled-components';
import PackageDrawer from 'Components/PackageDrawer/PackageDrawer';
import { useNavigate } from 'react-router';

const SubMenu = Menu.SubMenu;

const StyledSubMenu = styled(SubMenu)`
    .ant-menu-vertical {
        max-height: 70vh;
    }
`;


const PlusIconsAndPackages = () => {

    const headerContextValues = useContext(HeaderContext);
    const { device, onCloseEventDrawer, onEventCreated } = headerContextValues;

    const navigate = useNavigate()

    const [creatorType, setCreatorType] = useState("Model")
    const [creatorDrawer, setCreatorDrawer] = useState(false)

    const [isAddNewPackage, setIsAddNewPackage] = useState(false);
    const { data: packages } = useActivePackages();
    const latestPackageId = ls.get('nw_config_latest_package');
    const [isPackageDrawer, setIsPackageDrawer] = useState(false);
    const [packageId, setPackageId] = useState();

    const onClick = (e) => {
        const action = e.key.split("-")[0]
        let param = ""
        if (e.key.split("-").length > 1) {
            param = e.key.split("-")[1]
        }
        switch (action) {
            case "new":
                createNewPackage()
                break
            case "pack":
                setPackageId(parseInt(param))
                setIsPackageDrawer(true)
                break
            case 'sentPackages':
                navigate('/packages')
                break
            case 'activePackages':
                navigate('/packages/active')
                break
            default:
                break
        }
    }

    const handleClosePackageDrawer = () => {
        setIsPackageDrawer(false);
        setPackageId();
    };

    const createNewPackage = () => {
        setIsAddNewPackage(true);
    }

    const getSubMenuIcon = () => {
        let icon = light("book-arrow-right")
        let badge = null
        if (packages && packages.length >= 0) {
            badge = packages.length
        }
        return (
            <div className="packages-icon-holder">
                <NwIcon
                    size="2x"
                    icon={icon}
                />
                {
                    badge &&
                    <span className="packages-badge">
                        {badge}
                    </span>
                }
            </div>
        )
    }

    const handleOpenPackage = packId => {
        setPackageId(packId);
        setIsPackageDrawer(true);
    }

    const latestPackage = packages ? packages.find(pack => pack.ID === latestPackageId) : null;
    const items = [];
    if (latestPackage) {
        items.push({
            type: "group",
            label: <span className="submenu-title-wrapper">Last Used</span>,
            children: [{
                key: `pack-${latestPackage.ID}`,
                className: "small",
                label: <>{latestPackage.Name} <Badge count={latestPackage.Models.length} /></>
            }]
        })
    }
    items.push({
        type: "group",
        label: <span className="submenu-title-wrapper">Active Packages</span>,
        children: (packages && packages.length > 0) ? 
            packages.filter(p => p.ID !== latestPackageId).map((p) => {
                return ({
                    key: `pack-${p.ID}`,
                    label: <>{p.Name} <Badge count={p.Models.length} /></>
                })
            }) : []
    });
    items.push({
        type: "group",
        label: <span className="submenu-title-wrapper">Actions</span>,
        children: [
            { key: "new", className: "small", label: <><NwIcon icon={light("plus")} /> Create new package</>},
            { key: "activePackages", className: "small", label: <><NwIcon icon={light("hand-pointer")} /> Active Packages</>}
        ]
    });
    items.push({
        key: "sentPackages",
        label: <><NwIcon icon={light("paper-plane")} /> Sent packages</>,
        className: "small"
    });


    const handleClick = (e) => {
        const creatortype = e.key.substr(4)
        setCreatorType(creatortype)
        setCreatorDrawer(true)
    }

    const closeCreatorDrawer = () => {
        setCreatorDrawer(false)
    }

    const onCustomerCreated = customer => {
        navigate(`/customer/${customer.ID}`);
        closeCreatorDrawer();
    }
    const onModelCreated = (ID) => {
        navigate(`/model/${ID}`);
        closeCreatorDrawer();
    }

    const onProjectCreated = (ID) => {
        navigate(`/bookings/${ID}`);
        closeCreatorDrawer();
    }

    return (
        <>
            <StyledMenu
                onClick={handleClick}
                selectedKeys={[]}
                mode="horizontal"
                className="menu-booking"
                triggerSubMenuAction='click'
                items={[
                    {
                        popupClassName: `main-nav-submenu ${device === 1 ? 'mobile-main-nav-submenu' : ''}`,
                        key: "add",
                        label: (<NwIcon primary icon={solid('plus-circle')} size="2x" />),
                        children: [
                            {
                                label: (<span className="submenu-title-wrapper">contacts</span>),
                                type: "group",
                                children: [
                                    { key: "add-Model", label: "Model" },
                                    { key: "add-Client", label: "Client" },
                                    { key: "add-Agency", label: "Agency" },
                                    { key: "add-Service", label: "Service" },
                                    { key: "add-ScoutingModel", label: "Scouting Model" },
                                ]
                            },
                            {
                                label: (<span className="submenu-title-wrapper">bookings</span>),
                                type: "group",
                                children: [
                                    { key: "add-Project", label: "Project" },
                                    { key: "add-Job", label: "Job" },
                                    { key: "add-Casting", label: "Casting" },
                                ]
                            },
                            {
                                label: (<span className="submenu-title-wrapper">events</span>),
                                type: "group",
                                children: [
                                    { key: "add-Travel", label: "Travel" },
                                    { key: "add-Accommodation", label: "Accommodation" },
                                    { key: "add-FollowUp", label: "Activity" },
                                    { key: "add-Meeting", label: "Meeting" },
                                    { key: "add-Notice", label: "Notice" },
                                    { key: "add-ToDo", label: "Task" },
                                ]
                            },
                        ]
                    }
                ]}
            >
            </StyledMenu>
            {(packages)
                ?
                <>
                    <StyledMenu
                        onClick={onClick}
                        mode="horizontal"
                        selectable={false}
                        className="menu-booking"
                        triggerSubMenuAction="click"
                        items={[
                            {
                                key: "single-package",
                                label: getSubMenuIcon(),
                                popupClassName: "main-nav-submenu",
                                children: items
                            }
                        ]}
                    >
                    </StyledMenu>
                    {isAddNewPackage &&
                        <AddPackageDrawer onAddedPackage={handleOpenPackage} onClose={() => setIsAddNewPackage(false)} />
                    }
                </>
                :
                <StyledMenu
                    mode="horizontal"
                    selectable={false}
                    className={`menu-booking`}
                    triggerSubMenuAction="click"
                >
                    <StyledSubMenu
                        popupClassName="main-nav-submenu"
                        key="single-package"
                        title={getSubMenuIcon()}
                        style={{ opacity: .4 }}
                    />
                </StyledMenu>
            }
            {isPackageDrawer &&
                <PackageDrawer
                    packageId={packageId}
                    onClose={handleClosePackageDrawer} />
            }
            {creatorDrawer &&
                <ContactCreatorDrawer
                    type={creatorType}
                    onCancel={closeCreatorDrawer}
                    onModelCreated={onModelCreated}
                    onCustomerCreated={onCustomerCreated}
                    onProjectCreated={onProjectCreated}
                    onEventCreated={onEventCreated}
                    onCloseEventDrawer={onCloseEventDrawer}
                />}
        </>
    )
}

export default PlusIconsAndPackages
