import React, { useState, useContext } from "react";
import styled from 'styled-components';
import NwFlagIcon from 'Components/Gui/NwFlagIcon.js'
import NwSelect from 'Components/Gui/NwForm/CustomSelect';
import { ListContext } from "Contexts/ListContext";

const StyledSelect = styled(NwSelect)`
  .ant-select-selection-selected-value .aka {
    display: none;
  }
`
const relevancySortingPartialMatchValue = 1;
const relevancySortingStrictMatchValue = 5;

const SelectCountry = props => {
  const countriesList = [...useContext(ListContext).cachedList.countries.items];
  countriesList.forEach(el => {
    el.Matches = `${el.Name} ${el.AlternateSpelling}`;
  });
  const { onChange, onBlur } = props;
  const [filteredCountries, setFilteredCountries] = useState([
    ...countriesList
  ]);

  const filterCountries = query => {
    // reset filteredCountries
    setFilteredCountries([...countriesList]);
    const matchCountries = countriesList.filter(
      el => el.Matches.toLowerCase().indexOf(query.toLowerCase()) > -1
    );

    

    // Order results
    const split_term = query.split(" ");
    const matchers = [];
    for (let i = 0; i < split_term.length; i++) {
      if (split_term[i].length > 0) {
        const matcher = {};
        matcher.partial = new RegExp(split_term[i], "i");
        matcher.strict = new RegExp("^" + split_term[i], "i");
        matchers.push(matcher);
      }
    }
    matchCountries.forEach(item => {
      let partial_matches = 0;
      let partial_matches_on_name = 0;
      let strict_match = false;
      let strict_match_on_name = false;
      item.Matches = `${item.Name} ${item.AlternateSpelling}`;
      let split_option_matches = item.Matches.split(" ");
      let split_option_name = item.Name.split(" ");
      for (let i = 0; i < matchers.length; i++) {
        if (matchers[i]["partial"].test(item.Matches)) {
          partial_matches++;
        }
        for (let q = 0; q < split_option_matches.length; q++) {
          if (matchers[i]["strict"].test(split_option_matches[q])) {
            strict_match = true;
            break;
          }
        }
      }
      for (let i = 0; i < matchers.length; i++) {
        if (matchers[i]["partial"].test(item.Name)) {
          partial_matches_on_name++;
        }
        for (let q = 0; q < split_option_name.length; q++) {
          if (matchers[i]["strict"].test(split_option_name[q])) {
            strict_match_on_name = true;
            break;
          }
        }
      }
      let option_score = 0;
      option_score += partial_matches * relevancySortingPartialMatchValue;
      if (strict_match) {
        option_score += relevancySortingStrictMatchValue;
      }
      item.OptionScoreBeforeBooster = option_score;
      if (item.RelevancyBooster) {
        option_score = option_score * item.RelevancyBooster;
      }
      item.RelevancyScore = option_score;
      item.PartialMatches = partial_matches;
      item.StrictMatch = strict_match;
      item.PartialMatchesOnName = partial_matches_on_name;
      item.StrictMatchOnName = strict_match_on_name;
    });

    const orderResults = matchCountries.sort(function(a, b) {
      if (b.RelevancyScore === a.RelevancyScore) {
        return b.Name < a.Name ? 1 : -1;
      } else {
        return b.RelevancyScore - a.RelevancyScore;
      }
    });
    setFilteredCountries([...orderResults]);
  };
  return (
    <StyledSelect
      {...props}
      onChange={val => {
        setFilteredCountries([...countriesList]);
        onChange(val);
      }}
      onBlur={() => {
        setFilteredCountries([...countriesList]);
        if (onBlur) {
          onBlur()
        }
      }}
      showSearch={true}
      allowClear={true}
      filterOption={false}
      onSearch={val => filterCountries(val)}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      options={filteredCountries.map(el => ({
          value: el.Code,
          label: <>
            <NwFlagIcon code={el.Code.toLowerCase()} />
            <span> {el.Name}</span>
            <div className="aka">
              <small>{el.Matches}</small>
            </div>
          </>
      }))}
    />
  );
};

// SelectCountry.propTypes = {
//   onChange: PropTypes.func.isRequired,
//   name: PropTypes.string.isRequired
// };

export { SelectCountry };
