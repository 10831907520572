import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Alert from 'antd/lib/alert';
import NwIcon from 'Components/Gui/NwIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import SendProjectDrawer from 'Components/SendProjectDrawer/SendProjectDrawer';
import ProjectTitle from 'Components/Project/ProjectTitle';
import { ListContext } from 'Contexts/ListContext';
import useDepartmentName from 'Hooks/UseDepartmentName';
import ProjectChangeBookerDrawer from 'Components/Project/ProjectChangeBookerDrawer';
import { getUserName } from 'Libs/NwUsers';
import { showPeriodByDay } from 'Libs/NwMoment';
import { useNavigate } from 'react-router';

const Container = styled.div`
    //margin: 1.25rem 0 3rem 2.2rem;
    margin-left: 2.5rem;
    min-height: 8rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    .project-details-wrapper {
        .project-details {
            //cursor: pointer;
            text-overflow: ellipsis;
        }
        .booker-and-department {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            
            small {
                display: block;
                text-transform: uppercase;
                font-size: .75rem;
                margin: 0;
                padding: 0;
                line-height: 1.1em;
            }
            div.booker-and-department-actions {
                padding-left: 1.5rem;
                font-size: 1rem;
            }
        }
        .project-period {
            font-size: ${props => props.theme.FontSizeSm};
            padding-top: .25rem;
            text-transform: uppercase;

            svg {
                margin-right: .25rem;
            }
        }
    }
    .project-send-button {
        font-size: 1.4rem;
        padding-top: .75rem;
    }
    
`

const ProjectCard = ({
    project,
    type,
    modelId,
    event,
    projectErrorMessageProps
}) => {

    const navigate = useNavigate()

    const { cachedList } = useContext(ListContext);
    const allowBookerChange = true;
    //const [sendProjectDrawer, setSendProjectDrawer] = useState(false)
    const [openBookerChangeDrawer, setOpenBookerChangeDrawer] = useState(false);
    const deptname = useDepartmentName(project.DepartmentCreated, project.NetworkAgencyCreated)
    const usersList = cachedList.users.items;

    const onOpenBookerChangeDrawer = () => {
        setOpenBookerChangeDrawer(true)
    }

    const onCloseBookerChangeDrawer = () => {
        setOpenBookerChangeDrawer(false)
    }
    
    const handleMoveToProject = () => {
        if (event) {
            navigate({
                pathname: `/bookings/${project.ID}`,
                data: event && {
                    ...event,
                    elType: type,
                    modelId: modelId
                }
            });
        }
    };

    const getPeriod = () => {
        if (project.FirstDate) {
            return showPeriodByDay(project.FirstDate, project.LastDate)
        }
        return ""
    }

    // const handleShareProject = () => {
    //     setSendProjectDrawer(true)
    // }

    return (
        <Container>
            <div className="project-details-wrapper">
                <div className="project-details">
                    {/* onClick={handleMoveToProject} */}
                    <ProjectTitle 
                        project={project} 
                        hidePeriod 
                        linkToProject={handleMoveToProject} 
                    />
                </div>
                <div className="booker-and-department">
                    <div>
                        <small>BOOKER: {getUserName(project.UserCreated, usersList)}</small>
                        <small>DEPT: {deptname}</small>
                    </div>
                    {allowBookerChange &&
                        <div className="booker-and-department-actions">
                            <NwIcon
                                icon={light("user-pen")}
                                onClick={onOpenBookerChangeDrawer}
                                pointer
                            />
                        </div>
                    }
                </div>
                <p className='project-period'><NwIcon icon={light("calendar")} /> {getPeriod()}</p>
            </div>
            {projectErrorMessageProps && projectErrorMessageProps.message &&
                <Alert
                    message={projectErrorMessageProps.message}
                    type="error"
                    closable
                    onClose={projectErrorMessageProps.onClose}
                />
            }
            {openBookerChangeDrawer &&
                <ProjectChangeBookerDrawer
                    project={project}
                    onClose={onCloseBookerChangeDrawer}
                    eventID={event && event.ID}
                />
            }
        </Container>
    );
};

export default ProjectCard