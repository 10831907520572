import React, { useState } from 'react';
import styled from 'styled-components';
import OptionSelector from 'Components/OptionSelector/OptionSelector';
import { useUpdateOptionNumber } from 'Hooks/Event/UseEvent';
import RadioButtonGroup from 'Components/Gui/NwForm/RadioButtonGroup';
import { showDateNoTime } from 'Libs/NwMoment';
import MiniLoading from 'Components/Gui/MiniLoading';

const OptionPopoverContainer = styled.div`
    padding: 0;
    min-width: 21rem;
    
    .selected-date {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      div:first-child {
        min-width: 5rem;
      }
    }
    .all-dates {
      margin-top: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      div:first-child {
        min-width: 5rem;
      }
    }
`

const EventDateModelOptionNumbers = ({ eventID, eventDates, eventDateInfo, eventDatesModel, afterUpdate, selectedModel, onExtendOptionNumberToAllDates }) => {
  const { mutateAsync: updateOptionNumber } = useUpdateOptionNumber();
  const [isLoading, setIsLoading] = useState(false);

  const onUpdateOptionNumber = async (optionNumber) => {
    setIsLoading(true);
    const data = {
      ModelID: eventDatesModel.ModelID,
      Eventid: eventID,
      EventDateModelID: eventDatesModel.ID,
      OptionNumber: optionNumber
    }
    try {
      await updateOptionNumber({ ID: eventID, data });
      afterUpdate({
        ...eventDatesModel,
        ...data
      });
    } catch (error) {
      console.log('ant : Update Option Number Error => ', error);
    };
  };

  const handleExtendOptionNumber = async (optionNumber) => {
    setIsLoading(true);
    const data = {
      ModelID: eventDatesModel.ModelID,
      EventId: eventDatesModel.EventID,
      OptionNumber: optionNumber
    }
    try {
      await updateOptionNumber({ ID: eventID, data });
      onExtendOptionNumberToAllDates(eventDatesModel.ModelID, eventDatesModel.OptionNumber);
    } catch (error) {
      console.log('ant : Update Option Number Error => ', error);
    };
  }

  const eventModels = eventDates.filter(eventDate => {
    if (eventDate.EventDatesModels.find(item => item.ModelID === eventDatesModel.ModelID)) {
      return true;
    }
    return false;
  });

  return (
    <OptionPopoverContainer>
      {isLoading
        ?
        <MiniLoading />
        :
        <>
          <div className="selected-date">
            <div>{showDateNoTime(eventDateInfo.FromDate)}</div>
            <div>
              <OptionSelector
                eventId={eventID}
                modelId={selectedModel ? selectedModel.ModelID : null}
                dates={[eventDatesModel.FromDate ? eventDatesModel.FromDate : eventDateInfo.FromDate]}
                optionNumber={eventDatesModel.OptionNumber}
                onUpdateOptionNumber={onUpdateOptionNumber} />
            </div>
          </div>
          {eventModels.length > 1 &&
            <div className="all-dates">
              <div>All dates</div>
              <div><RadioButtonGroup
                onChange={value => {
                  handleExtendOptionNumber(value)
                }}
                size='small'
                options={[
                  { value: 10, label: 'job' },
                  ...[...Array(5).keys()].map(key => (
                    { value: key + 1, label: key + 1 }
                  ))
                ]} />
              </div>
            </div>
          }
        </>
      }
    </OptionPopoverContainer>
  )
}

export default EventDateModelOptionNumbers;
