import React, { useEffect, useContext, useState } from 'react';
import { getMoment } from "Libs/NwMoment";
import { notification, Badge } from 'antd';
import styled from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import useLocalStorageState from "Hooks/UseLocalStorageState";
import { useContact } from "Hooks/Contact/UseContact";
import { useStateValue } from "Contexts/StateProvider";
import { UserContext } from 'Contexts/UserContext';
import { ListContext } from 'Contexts/ListContext';
import { getNow, showLogNoTime } from 'Libs/NwMoment';
import NwIcon from 'Components/Gui/NwIcon';
import StyleVariables from "Components/Gui/StyleVariables.js";
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import { saveAlerts } from 'Libs/NwAlerts';

const BadgeRibbon = styled(Badge.Ribbon)`
    z-index: 6;

    height: ${props => props.size === 'small' && '16px'};
    font-size: ${props => props.size === 'small' && '12px'};

    .ribbon-content {
        line-height: ${props => props.size === 'small' && '18px'};
    }
`;

const ContactAlert = ({ currentContact, icon, readOnly, size, children }) => {
    const { GetUserName } = useContext(ListContext);
    const [{ areaContext }] = useStateValue();
    const [readAlerts, setReadAlerts] = useLocalStorageState(`${areaContext.area}-read-alerts`, []);
    const { currentUser } = useContext(UserContext);
    const [alertVisible, setAlertVisible] = useState(false);

    
    useEffect(() => {
        return function cleanup() {
            notification.destroy();
        };
    }, []);


    const checkReadAlert = () => {
        return readAlerts.some((alert) => {
            return (alert.Author === currentUser.UserID && alert.ContactID === currentContact.ID && (getMoment(alert.ExpirationDate) >= getNow() && getMoment(currentContact.AlertCreationDate) <= getMoment(alert.Date))) 
        })
    }

    useEffect(() => {
        //console.log("entrato useEffect contactalert")
        if (currentContact && !readOnly) {
            //console.log("currentContact e !readOnly")
            if (currentContact.Alert) {
                //console.log("il contatto (" + currentContact.ID    + ") ha un alert: ", currentContact.AlertMessage)
                if (!checkReadAlert()) {
                    //console.log("l'alert non è stato letto")
                    if (!alertVisible) {
                        //console.log("l'alert non è visibile quindi lo attivo")
                        handleDisplayNotification();
                        setAlertVisible(true);
                    }
                } else {
                    //console.log("l'alert è stato letto")
                //     notification.destroy();
                }
            } else {
                //console.log("il contatto non ha un alert")
            //     notification.destroy();
            }
        }
    }, [currentContact, readOnly, readAlerts]);

    const handleAlerts = alerts => {
        setReadAlerts(alerts);
        saveAlerts(areaContext.area, alerts);
    };

    const handleDismissNotification = (key, period) => {
        notification.close(key);
        setAlertVisible(false);
        const findIndex = readAlerts.findIndex((alert) => {
            if (
                alert.Author === currentUser.UserID &&
                alert.ContactID === currentContact.ID
            ) {
                return true;
            }
            return false;
        });
        if (findIndex < 0) {
            handleAlerts([
                ...readAlerts,
                {
                    Author: currentUser.UserID,
                    ContactID: currentContact.ID,
                    Date: getNow(),
                    ExpirationDate: period === 'day' ? getNow().endOf('day')
                        : getNow().add(1, period)
                },
            ]);
        } else {
            const updatedReadAlerts = [...readAlerts];
            updatedReadAlerts[findIndex] = {
                ...updatedReadAlerts[findIndex],
                Date: getNow(),
                ExpirationDate: period === 'day' ? getNow().endOf('day')
                    : getNow().add(1, period)
            };
            handleAlerts(updatedReadAlerts);
        }
    };

    const getActions = key => {
        const actions = [
            {
                name: "Mute for 7 days", key: "week", pinned: true, action: (() => {
                    handleDismissNotification(key, 'week');
                })
            },
            {
                name: "Mute for today", type: "day", key: "day", action: (() => {
                    handleDismissNotification(key, 'day');
                })
            },
            {
                name: "Mute for 30 days", type: "month", key: "month", action: (() => {
                    handleDismissNotification(key, 'month');
                })
            }
        ]

        return actions;
    };

    const handleDisplayNotification = () => {
        const key = `open${Date.now()}`;
        notification.warning({
            className: "nw-notification-contact-alert",
            icon: <NwIcon icon={solid("message-exclamation")} />,
            message: (`${GetUserName(currentContact.AlertUser)} ${showLogNoTime(currentContact.AlertCreationDate)}`),
            description: (
                <>
                    <p>{currentContact.AlertMessage}</p>
                    <small>

                    </small>
                </>
            ),
            duration: 0,
            key,
            btn: (
                <NwDropDownMenu
                    actions={getActions(key)}
                />
            ),
            onClose: () => {
                notification.close(key);
                setAlertVisible(false);
            },
            onClick: () => {
                //console.log("Notification Clicked!");
            },
        });
    };

    return (!currentContact ? <div />
        :
        currentContact.Alert ?
            <>
                {icon ?
                    <div onClick={handleDisplayNotification}>
                        {icon}
                    </div>
                    :
                    <BadgeRibbon
                        size={size}
                        color={StyleVariables.DangerColor}
                        text={
                            <div className="ribbon-content" onClick={handleDisplayNotification}>
                                <NwIcon icon={solid('exclamation-circle')} /> Alert
                            </div>
                        }
                        placement="start"
                    >
                        {children}
                    </BadgeRibbon>
                }
            </>
            :
            <>
                {children}
            </>
    );
};

export default ContactAlert;