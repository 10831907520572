import React, { useContext } from "react";
import Select from "Components/Gui/NwForm/CustomSelect";
import { Badge } from 'antd';
import { ListContext } from 'Contexts/ListContext';
import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 5rem;
`

const FilterEventsOnSubTypesAndOptionNumber = ({ onChange, value, eventType, onBlur }) => {
    const { cachedList } = useContext(ListContext);

    const eventSubtypesOptions =
        cachedList.eventSubtypes.items.filter(item => item.Type === eventType && !item.Disabled).map(type => {
            return {
                label:
                    <div style={{ display: 'flex' }}>
                        <Badge color={`#${type.BackColor}`} style={{ marginRight: ".5em" }} />
                        {type.Name}
                    </div>,
                value: type.Code
            };
        })


    const onSubTypeChange = (val) => {
        onChange({
            ...value,
            eventSubtypeCode: [...val]
        });
    }

    const onOptionNumberChange = (val) => {
        onChange({
            ...value,
            optionNumber: val
        });
    }

    return (
        <Wrapper>
            <Select
                value={value.eventSubtypeCode || []}
                style={{ width: '100%' }}
                onChange={onSubTypeChange}
                options={eventSubtypesOptions}
                onBlur={onBlur}
                placeholder={`All ${eventType} types`}
                mode="multiple"
                allowClear
            />
            <RadioButtonGroup
                value={value.optionNumber || null}
                onChange={onOptionNumberChange}
                size='small'
                options={[
                    { value: null, label: 'all' },
                    { value: 10, label: 'job' },
                    ...[...Array(5).keys()].map(key => (
                        { value: key + 1, label: key + 1 }
                    ))
                ]} />
        </Wrapper>
    );
};

export default FilterEventsOnSubTypesAndOptionNumber;
