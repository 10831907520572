import { useQuery } from '@tanstack/react-query';
import Axios from 'axios';
import { queryClient } from 'QueryClientProvider';

const getContactBillingInfosByID = ID => (
    Axios.get(`/contacts/billinginfos/${ID}`)
)

const useContactBillingInfos = (ID) => {
    const intID = Number(ID)
    return useQuery(["contactbillinginfos", intID], () => getContactBillingInfosByID(intID), {
        staleTime: 10000,
        cacheTime: 10000,
        enabled: ID ? true : false
    })
}

const forceReloadContactBillingInfos = ID => {
    const intID = Number(ID)
    queryClient.invalidateQueries(['contactbillinginfos', intID]);
};

// const updateContactDefaultConnection = async (ID) => {
//     try {
//         const connections = await getContactConnectionsByID(ID);
//         queryClient.setQueryData(['contact', ID], (old) => ({
//             ...old,
//             DefaultConnection,
//             //DefaultConnections,
//             Connections: connections
//         }));
//     } catch (error) {
//         console.log('ant : UpdateConnections Error => ', error);
//     }
// }

// CONNECTIONS (ADD, EDIT, DELETE)
// const editContactConnection = (data) => (
//     Axios.put(`/connections`, data)
// )

// const useEditContactConnection = () => {
//     return useMutation(editContactConnection, {
//         onSuccess: (data, variables) => {
//             updateContactConnections(data.ContactID);
//         }
//     })
// }

// const addContactConnection = async ({ data, emails }) => {
//     const response = await Axios.post(`/connections`, data)
//     for (const email of emails) {
//         const emailData = {
//             ConnectionID: response.ID,
//             Address: email,
//         };
//         await addContactConnectionItem("emails", emailData, response.ContactID)
//     }
//     return response
// }

// const useAddContactConnection = () => {
//     return useMutation(addContactConnection, {
//         onSuccess: (data, variables) => {
//             updateContactConnections(data.ContactID);
//         }
//     })
// }

// const deleteContactConnection = ({ connectionID, contactID }) => (
//     Axios.delete(`/connections/${connectionID}`)
// )

// const useDeleteContactConnection = () => {
//     return useMutation(deleteContactConnection, {
//         onSuccess: (data, variables) => {
//             updateContactConnections(variables.contactID);
//         }
//     })
// }

export {
    useContactBillingInfos,
    forceReloadContactBillingInfos
}
