import React, { useState } from "react";
import { getMoment } from "Libs/NwMoment";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { showDateNoTime } from "Libs/NwMoment";
import NwIcon from "Components/Gui/NwIcon";
import ModelInTownSchedulesDrawer from "Components/ModelInTown/ModelInTownSchedulesDrawer";
import { getModel } from 'Hooks/Contact/UseContactsList';
import { useUserPermission } from "Contexts/UserContext";
import PermissionBlock from "Components/PermissionBlock/PermissionBlock";

const InTownContainer = styled.div`
    font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;

    .icon-container {
        width: .75rem;
        text-align: center;
    }

    p {
        margin: 0;
    }

    small {
        font-weight: normal;
        font-size: .75rem;
    }
`

const ModelInTown = ({ modelID, modelInTownSchedule, plainMode, readOnly }) => {
    const [drawer, setDrawer] = useState(false);
    const modelObject = getModel(modelID);
    const viewModelInTownSchedule = useUserPermission('ViewModelInTownSchedule', modelObject && modelObject.U && modelObject.U.map(user => user.UserID));


    const getLocationPeriod = () => {
        if (!modelInTownSchedule.EndDate) {
            return `from ${showDateNoTime(modelInTownSchedule.StartDate)}`
        }
        return `${showDateNoTime(modelInTownSchedule.StartDate)} > ${showDateNoTime(modelInTownSchedule.EndDate)}`
    }

    const getModelInTown = () => {
        const todayDate = getMoment(new Date());

        if (!modelInTownSchedule) {
            return (
                <InTownContainer>
                    <div className="icon-container">
                        <NwIcon icon={light("island-tropical")} />
                    </div>
                    <p>OUT</p>
                </InTownContainer>
            )
        }
        if (modelInTownSchedule && todayDate >= getMoment(modelInTownSchedule.StartDate)) {
            if (modelInTownSchedule.IsInTown) {
                if (!modelInTownSchedule.EndDate) {
                    return (
                        <InTownContainer isBold={true}>
                            <div className="icon-container">
                                <NwIcon icon={light("location-smile")} />
                            </div>
                            <p>IN TOWN</p>
                        </InTownContainer>
                    )
                } else {
                    return (
                        <InTownContainer isBold={true}>
                            <div className="icon-container">
                                <NwIcon icon={light("location-smile")} />
                            </div>
                            <p>IN TOWN <small>until {showDateNoTime(modelInTownSchedule.EndDate)}</small></p>
                        </InTownContainer>
                    )
                }
            } else {
                return (
                    <InTownContainer>
                        <div className="icon-container">
                            <NwIcon icon={light("island-tropical")} />
                        </div>
                        <p>OUT <small>(in {modelInTownSchedule.Location})</small></p>
                    </InTownContainer>
                )
            }
        }
        if (modelInTownSchedule && todayDate < getMoment(modelInTownSchedule.StartDate)) {
            if (modelInTownSchedule.IsInTown) {
                return (
                    <InTownContainer>
                        <div className="icon-container">
                            <NwIcon icon={light("island-tropical")} />
                        </div>
                        <p>OUT <small>next arrival on {showDateNoTime(modelInTownSchedule.StartDate)}</small></p>
                    </InTownContainer>
                )
            } else {

                return (
                    <InTownContainer>
                        <div className="icon-container">
                            <NwIcon icon={light("island-tropical")} />
                        </div>
                        <p>OUT <small>in {modelInTownSchedule.Location} {getLocationPeriod()}</small></p>
                    </InTownContainer>
                )
            }
        }
    };

    const handleOpenSchedulesDrawer = event => {
        event.stopPropagation();
        setDrawer(true);
    };

    return (
        <>
            <div className="header-body-boxes header-body-boxes-hoverable" onClick={(!plainMode && viewModelInTownSchedule) ? handleOpenSchedulesDrawer : null}>
                <PermissionBlock
                    permission={viewModelInTownSchedule}
                    content={getModelInTown()}
                />
            </div>
            {drawer && <ModelInTownSchedulesDrawer readOnly={readOnly} modelID={modelID} onClose={() => setDrawer(false)} />}
        </>
    )
};

export default ModelInTown;
