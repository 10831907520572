import React, { useMemo, useState } from "react";
import { Popover, Menu, Badge } from "antd";
import styled from 'styled-components';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from 'Components/Gui/NwIcon';
import { NwButton, NwAddButton } from 'Components/Gui/NwButton';
import FolderDrawer from 'Components/ContactsFolders/FolderDrawer';
import { useFolders, useFoldersDispatch } from "Contexts/FoldersContext";

const Content = styled.div`    
    padding: 2rem 1rem;
    .ant-menu-vertical {
        border-right: none;
    }

    .ant-menu {
        border-radius: 0 0 6px 6px;
    }
    .ant-menu-item {
        padding: 0 !important;
        margin: 0 !important;
        border-bottom: ${props => props.theme.DefaultBorder};
        height: 2rem;
        line-height: 2rem;

        &:last-child {
            border-bottom: none;
        }

        text-transform: uppercase;
        font-size: .8rem;
        vertical-align: middle;

        &.strong {
            font-weight: bold;
        }

        &.small {
            font-size: .75rem;
        }

        &.list {
            padding: 2px 12px;
        }

        svg  {
            margin: 0 6px;
        }

        .active {
            font-weight: bold;

            svg {
                color: ${props => props.theme.DangerColor};
            }
        }

        .badge {
            float: right;
            color: #fff;
            background-color: ${props => props.theme.DangerColor};
            padding: .25em .4em;
            font-size: 75%;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            margin-top: .2em;
            vertical-align: baseline;
            border-radius: .25rem;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
    }
`;

const EmptyFolders = styled.div`
    padding: 8px 16px;
`;

const FolderBadge = styled.div`
    font-size: 1rem;
    .ant-badge-count {
        background: ${props => props.theme.PrimaryColor};
        margin-top: 2px;
    }
    .ant-badge-multiple-words {
        padding: 0 3px;
    }
`;

const AddToFolderButton = ({ ids, type, placement, size, showBadge, text, onFolderCreated }) => {

    const folders = useFolders()
    const folders_dispatch = useFoldersDispatch()

    const [isNewFolder, setIsNewFolder] = useState(false);
    const isMultiple = (ids.length > 1)

    const isInFolder = folder => {
        return folder.items.findIndex(itemId => itemId === ids[0]) >= 0;
    }

    const onClick = (e) => {
        const action = e.key.split("-")[0]
        let param = ""
        if (e.key.split("-").length > 1) {
            param = e.key.split("-")[1]
        }
        switch (action) {
            case "folderadd":
                folders_dispatch({
                    type: 'AddItemsToFolder',
                    folderID: Number(param),
                    items: ids
                })
                break;
            case "folderremove":
                folders_dispatch({
                    type: 'RemoveItemsFromFolder',
                    folderID: Number(param),
                    items: ids
                })
                break;
            default:
            //do nothing
        }
    }

    const popoverActions = useMemo(() => {
        if (folders) {
            const actionsmenu = [];
            const typefolders = folders.openFolders.filter(folder => folder.type === type);
            for (const p of typefolders) {
                if (isMultiple || !isInFolder(p)) {
                    actionsmenu.push({ name: p.title, key: `folderadd-${p.ID}`, icon: light("user-plus"), added: false })
                } else {
                    actionsmenu.push({ name: p.title, key: `folderremove-${p.ID}`, icon: light("user-times"), added: true })
                }
            }
            return actionsmenu.map((action) => {
                return ({
                    key: action.key,
                    label: (
                        <div>
                            <span className={action.added ? 'active' : ''}>
                                <NwIcon icon={action.icon} />
                                {action.name}
                            </span>
                            {action.badge && <span className="badge">{action.badge}</span>}
                        </div>
                    )
                });
            });
        } else {
            return [];
        }
    }, [folders, isMultiple, isInFolder, ids]);

    const handleAddNewFolder = () => {
        setIsNewFolder(true);
    };

    const handleClose = () => {
        setIsNewFolder(false);
    };

    if (!folders) {
        return null;
    }


    const addedFolders = folders.openFolders.filter(folder => folder.type === type && !isMultiple && isInFolder(folder));

    return (
        <>
            <Popover
                placement={placement}
                overlayClassName='folder-popover'
                trigger='click'
                content={
                    <Content>
                        {folders.openFolders.filter(folder => folder.type === type).length === 0
                            ?
                            <EmptyFolders>no active folders</EmptyFolders>
                            :
                            <Menu
                                onClick={onClick}
                                className="nw-small-context-menu"
                                items={popoverActions}
                            />
                        }
                        <br /><br />
                        <NwAddButton
                            primary
                            icon={solid('folder-plus')}
                            size='small'
                            label='create new folder'
                            onClick={handleAddNewFolder}
                        />
                    </Content>
                }
            >
                {showBadge ?
                    <FolderBadge>
                        <Badge count={addedFolders.length} size='small'>
                            {text
                                ?
                                <NwButton ghost icon={light('folder')} label={text} />
                                :
                                <NwIcon icon={light('folder')} size={size} />
                            }
                        </Badge>
                    </FolderBadge>
                    :
                    (text
                        ?
                        <NwButton ghost icon={light('folder')} label={text} />
                        :
                        <NwIcon icon={light('folder')} size={size} />
                    )
                }
            </Popover>
            {isNewFolder &&
                <FolderDrawer items={ids} type={type} onClose={handleClose} onCreated={onFolderCreated} />
            }
        </>
    )
};

export default AddToFolderButton
