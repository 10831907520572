import React, { useState, useRef } from 'react';
import { Input, List } from 'antd';
import styled from 'styled-components';
import { Formik, Field } from "formik";
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Axios from "axios";
import * as Yup from "yup";

import FlexContainer from 'Components/Gui/FlexContainer';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import NwIcon from 'Components/Gui/NwIcon';
// import EmptyState from "Components/Gui/EmptyState";
// import MiniLoading from 'Components/Gui/MiniLoading';
import { getValidText } from 'Libs/NwUtils';
import { NWInput, NWTextAreaCharCounter, NWSwitch } from 'Components/Gui/NwForm/NwFormItems';
import NwDivider from 'Components/Gui/NwDivider';
// import { MailLine, PhoneLine } from 'Components/Gui/NwContactInfo';
import { NwCancelButton, NwSaveButton, NwLinkButton } from 'Components/Gui/NwButton';
import { Loading } from 'Components/Gui/Loading';

const SearchBoxContainer = styled.div`
    .search-box-field {
        width: 450px;
    }
    .contacts-list {
        padding-top: 0.5rem;
        width: 100%;
        .ant-list-empty-text {
            padding: 0;
            
            .ant-empty {
                margin: 0;
            }
        }

        .ant-list-item {
            .contact-list-item {
                cursor: pointer;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                .contact-list-icon {
                    font-size: 2rem;
                    margin: 0 1rem;
                    line-height: 1em;
                    width: 50px;
                }
                .contact-list-details {
                    flex: 1 1 0;
                    padding-right: 1rem;
                    .contact-connection-name {
                        text-transform: uppercase;
                        font-size: .85rem;
                        line-height: 1em;
                    }
                    .contact-name {
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .new-contact {
        width: 100%;
        padding: 0 1em 1em;

        .ant-list-item {
            cursor: pointer;
            .new-contact-icon {
                font-size: 2em;
                margin: 0 1em;
            }
            .new-contact-text {
                font-weight: bold;
            }
        }
        
    }
`;

const AddCallSheet = ({ projectId, onTouchForm, onClose, onUpdateCallSheet, callSheetType }) => {
    // const { data: project } = useProject(projectId);
    const [contacts, setContacts] = useState([]);
    const [cache, setCache] = useState('');
    const [loading, setLoading] = useState(false);
    const [connection, setConnection] = useState();
    const [loadingConnection, setLoadingConnection] = useState(false);
    const [filter, setFilter] = useState('');
    const [showAllContacts, setShowAllContacts] = useState(false);
    const [initialValues, setInitialValues] = useState();

    const searchRef = useRef();

    const addCallSheet = async (values, setSubmitting) => {
        const callSheetData = connection ? {
            ProjectID: projectId,
            CallSheetTypeID: callSheetType,
            ConnectionID: connection.ID,
            ContactID: connection.ContactID,
            ...values
        }: {
            CallSheetTypeID: callSheetType,
            ProjectID: projectId,
            ...values
        };
        //console.log("callSheetData", callSheetData)
        setSubmitting(true);
        try {
            await Axios.post('/callsheets', callSheetData);
            await onUpdateCallSheet();
            onClose();
        } catch (error) {
            console.log('ant : Add Connection Error => ', error);
        } finally {
            setSubmitting(false);
        }
    }

    const getContacts = async searchTerm => {
        setLoading(true);
        try {
            const contacts = await Axios.get(`/contacts/SearchConnections/${searchTerm}`);
            setContacts(contacts);
            setLoading(false);
        } catch (error) {
            console.log('ant : Search Contact Error => ', error);
            setLoading(false);
        }
    }

    const handleSearch = (event) =>  {
        const inputValue = event.target.value;
        setFilter(inputValue);
        if (inputValue.length < 3) {
            setContacts([]);
            setCache(inputValue);
        } else {
            if (inputValue >= 3 && inputValue.includes(cache)) {
                const updatedcontacts = contacts.filter(contact => {
                    if (contact.ContactName.includes(inputValue) || contact.Name.includes(inputValue) || contact.Role.includes(inputValue)) {
                        return true;
                    }
                    return false;
                });
                setContacts(updatedcontacts);
                setCache(inputValue);
            } else {
                setCache(inputValue);
                getContacts(inputValue);
            }
        }
    };

    const getFullName = (connection) => {
        let fn = getValidText(connection.Name)
        if (connection.Surname) {
            if (fn.length) {
                fn += " " + connection.Surname
            }
        }
        return fn
    }

    const handleClickConnection = async (simpleConnection) => {
        setLoadingConnection(true);
        setCache('');
        try {
            const connection = await Axios.get(`/connections/${simpleConnection.ID}`);
            setConnection(connection);
            if (connection) {
                const { Phones, Emails } = connection;
                setInitialValues({
                    Name: getFullName(connection),
                    Phone: (Phones && Phones.length) ? Phones[0].Number : "",
                    Email: (Emails && Emails.length) ? Emails[0].Address : "",
                    Note: "",
                    Internal: false,
                });
            }
        } catch (error) {
            console.log('ant : Get Connection Error => ', error);
        } finally {
            setLoadingConnection(false);
        }
    };

    const getRoleText = (connection) => {
        const role = getValidText(connection.Role);
        if (role) return `${role}@`;
        return role;
    }

    return (
        <>
            {!initialValues && <SearchBoxContainer>
                <p>Type a <strong>contact name</strong> or a <strong>role</strong></p>
                <FlexContainer mg={'0 0 16px'} ref={searchRef}>
                    <Input
                        className="search-box-field" 
                        size="large"
                        id='search-input'
                        value={filter}
                        autoComplete='off'
                        placeholder="contact name / role" 
                        onChange={handleSearch}
                    />
                </FlexContainer>
                <div className="contacts-list">
                    <NwDivider textSize="big" bold dark>
                        Select an existing contact
                    </NwDivider>
                    <List
                        loading={loading}
                        itemLayout="horizontal"
                        dataSource={showAllContacts ? contacts : [...contacts].splice(0, 4)}
                        renderItem={(connection, index) => {
                            return (
                                <List.Item
                                    className='ant-list-item-top'
                                    key={index}
                                    onClick={() => handleClickConnection(connection)}
                                >
                                    <div className='contact-list-item'>
                                        <div className='contact-list-icon'>
                                            <NwIcon icon={connection.TypeName === 'Place' ? light('home-alt') : light('user')} />
                                        </div>
                                        {connection.TypeName === 'People' ? 
                                            <div className="contact-list-details">
                                                <div className='contact-connection-name'>{getRoleText(connection).toUpperCase()}{getValidText(connection.ContactName).toUpperCase()}</div>
                                                <div className='contact-name'>{`${getValidText(connection.Name)} ${getValidText(connection.Surname)}`}</div>
                                            </div>
                                            : <div className="contact-list-details">
                                                <div className='contact-connection-name'>{getValidText(connection.ContactName).toUpperCase()}</div>
                                                <div className='contact-name'>{getValidText(connection.Name)}</div>
                                            </div>
                                        }
                                    </div>
                                </List.Item>
                            )
                        }}
                    />
                    {!showAllContacts && (contacts.length > 4) &&
                        <FlexContainer justifyContent='center'>
                            <NwLinkButton onClick={() => setShowAllContacts(true)} label='Load More' />
                        </FlexContainer>
                    }
                    {filter && <div className="new-contact">
                        <NwDivider textSize="big" bold dark>or</NwDivider>
                        <List itemLayout="horizontal">
                            <List.Item
                                className="ant-list-item-top"
                                onClick={() => {
                                    setInitialValues({
                                        Name: filter,
                                        Phone: "",
                                        Email: "",
                                        Note: "",
                                        Internal: false,
                                    })
                                }}>
                                <FlexContainer>
                                    <div className="new-contact-icon">
                                        <NwIcon icon={solid('plus-circle')} />
                                    </div>
                                    <FlexContainer column alignItems='flex-start'>
                                        <div className='new-contact-text'>create a new "{filter}" client</div>
                                    </FlexContainer>
                                </FlexContainer>
                            </List.Item>
                        </List>
                    </div>}
                </div>
            </SearchBoxContainer>}
            {initialValues && 
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        addCallSheet(values, setSubmitting);
                    }}
                    validationSchema={Yup.object().shape({
                        Name: Yup.string().required("Name is required")
                    })}
                    render={({
                        isSubmitting,
                        values,
                        handleSubmit,
                    }) => (
                        <NwForm 
                            values={values}
                            onTouchForm={onTouchForm}
                            onFinish={handleSubmit} 
                            layout="vertical"
                        >
                            {loadingConnection ? <Loading textBlack/>:
                            <>
                                <Field
                                    component={NWInput}
                                    label="Name"
                                    name="Name"
                                    maxLength={100}
                                    type="text"
                                    value={values.Name}
                                />
                                <Field
                                    component={NWInput}
                                    label="Phone"
                                    name="Phone"
                                    maxLength={50}
                                    type="text"
                                    value={values.Phone}
                                />
                                <Field
                                    component={NWInput}
                                    label="E-Mail"
                                    name="Email"
                                    maxLength={100}
                                    type="text"
                                    value={values.Email}
                                />
                                <Field
                                    component={NWTextAreaCharCounter}
                                    label="Note"
                                    name="Note"
                                    type="text"
                                    value={values.Note}
                                    maxLength={300}
                                    autoSize={{ minRows: 5, maxRows: 5 }}
                                />
                                <Field
                                    component={NWSwitch}
                                    label="Hidden to models"
                                    name="Internal"
                                    defaultChecked={false}
                                    checked={values.Internal}
                                />
                            </>
                            }
                            <NwFormButtonsBar
                                left={
                                    <NwCancelButton
                                        disabled={isSubmitting}
                                        onClick={onClose}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        htmlType="submit"
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                    />
                                }
                            />
                        </NwForm>
                    )}
                />}
        </>        
    );
};

export default AddCallSheet;