import React, { useContext } from "react";
import { Select } from 'antd';
import FlexContainer from 'Components/Gui/FlexContainer';
import { ListContext } from 'Contexts/ListContext';
import { NwButton } from "../NwButton";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    /* width: 100%; */

    .ant-btn:hover, .ant-btn:focus {
        border-color: rgba(0,0,0,0.25) !important;
    }

    .ant-btn:hover {
        color: ${props => props.theme.DangerColor} !important;
    }
`;

const StyledSelect = styled(Select)`
    min-width: 200px;
    .ant-select-selection {
        border-radius: 4px 0 0 4px
    }

    .ant-select-selector {
        border-radius: ${props => props.$closable ? '6px 0 0 6px !important' : '6px !important' };
    }
`;

const ClearButtonStyle = {
    padding: '0 8px',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0 4px 4px 0',
    color: 'rgba(0,0,0,0.25)',
    borderLeft: 'none'
};

const DocumentFilterSelector = ({ onChange, value, filterType, onBlur, emptyValue }) => {
    const { cachedList } = useContext(ListContext);

    const getOptions = () => {
        if (filterType === "document") {
            const documentsFilter = cachedList.enumerators.items.find(item => item.Name === "ModelWallDocumentsFilterEnum");
            if (documentsFilter && documentsFilter.Values) {
                return [
                    ...documentsFilter.Values.filter(item => item.Name !== "NoFilter").map(item => ({
                        value: item.Name,
                        label: item.Display
                    }))
                ]
            }
        } else if (filterType === "playlist") {
            const documentsFilter = cachedList.enumerators.items.find(item => item.Name === "ModelWallPlaylistsFilterEnum");
            if (documentsFilter && documentsFilter.Values) {
                return [
                    ...documentsFilter.Values.filter(item => item.Name !== "NoFilter").map(item => ({
                        value: item.Name,
                        label: item.Display
                    }))
                ]
            }
        } else {
            const documentsFilter = cachedList.enumerators.items.find(item => item.Name === "ModelWallPublishFilterEnum");
            if (documentsFilter && documentsFilter.Values) {
                return [
                    ...documentsFilter.Values.filter(item => item.Name !== "NoFilter").map(item => ({
                        value: item.Name,
                        label: item.Display
                    }))
                ]
            }
        }
        return [];
    }

    return (
        <Container >
            <StyledSelect
                $closable={emptyValue ? true : false} 
                autoComplete='off'
                value={value}
                style={{ display: 'block' }}
                allowClear={true}
                placeholder='Select a condition'
                filterOption={false}
                onChange={onChange}
                onBlur={onBlur}
                options={getOptions()}
            />
            {emptyValue &&
                <NwButton icon={light('times')} style={ClearButtonStyle} onClick={event => onChange(null)} />
            }
        </Container>
    );
};

export default DocumentFilterSelector;
