import React, { useContext, useState } from "react"
import { ListContext } from "Contexts/ListContext";
import { showPeriodByDay } from "Libs/NwMoment";
import { Tooltip } from "antd";
import { getContactName } from "Hooks/Contact/UseContactsList";
import NwDivider from "Components/Gui/NwDivider";
import { ProjectTitleCompactContainer, ProjectTitleContainer, ProjectTitlePopoverContainer } from "./ProjectTitleUI";
import { NwLinkButton } from "Components/Gui/NwButton";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CustomerDrawer from "Components/ContactDetails/CustomerDetails/Drawer/CustomerDrawer";

const ProjectTitle = ({
    project,
    compact,
    hidePeriod,
    linkToProject,
    noProjectText,
    pointer,
    popover,
    small,
}) => {

    const [showPreview, setShowPreview] = useState(null);
    const { cachedList } = useContext(ListContext);
    const category = project.ProjectCategoryID ? cachedList.projectCategories.items.find(category => category.ID === project.ProjectCategoryID) : null;

    const getProjectCustomerName = () => {
        if (project.Contact) {
            return project.Contact.Name
        }
        if (project.CustomerName) {
            return project.CustomerName
        }
        if (project.CustomerID) {
            return getContactName(project.CustomerID)
        }
        return ""
    }

    const setContactPreview = () => {
        let cID = null;
        if (project.Contact) {
            cID = project.Contact.ID
        }
        if (project.CustomerID) {
            cID = project.CustomerID
        }
        if (cID) {
            setShowPreview(cID)
        }
    }

    const handleCloseContactPreview = () => {
        setShowPreview(null)
    }

    const getProjectName = () => {
        if (project.Name) {
            return project.Name
        }
        if (project.ProjectName) {
            return project.ProjectName
        }
        return ""
    }



    const getPeriod = () => {
        if (project.FirstDate) {
            return showPeriodByDay(project.FirstDate, project.LastDate)
        }
        return ""
    }

    const ProductText = () => {
        if (!project.Product) {
            return null
        }
        if (project.Product.length > 60) {
            return (
                <Tooltip title={project.Product}>
                    <span>{project.Product.substring(0, 59)}(...)</span>
                </Tooltip>
            )
        } else {
            return (<span>{project.Product}</span>)
        }
    }

    const ProjectContent = () => {
        const pn = getProjectName();
        const pcn = getProjectCustomerName();
        if (pn && (pn.toLowerCase() !== pcn.toLowerCase())) {
            if (project.Product) {
                return (
                    <>
                        <h1>
                            <strong>{pn}</strong>
                            <em>/</em>
                            {project.Product}
                        </h1>
                        <p>client: <strong style={{ cursor: "pointer" }} onClick={setContactPreview}>{pcn}</strong>
                            {!hidePeriod &&
                                <>, period: <strong>{getPeriod()}</strong></>
                            }
                        </p>
                    </>
                )
            } else {
                return (
                    <>
                        <h1>
                            <strong style={{ cursor: "pointer" }} onClick={setContactPreview}>{pcn}</strong>
                            <em>/</em>
                            {pn}
                        </h1>
                        {!hidePeriod && <p>{getPeriod()}</p>}
                    </>
                )
            }
        }
        return (
            <>
                <h1>
                    <strong style={{ cursor: "pointer" }} onClick={setContactPreview}>{pn}</strong>
                    {project.Product &&
                        <>
                            <em>/</em>
                            <ProductText />
                        </>
                    }
                </h1>
                {!hidePeriod && <p>{getPeriod()}</p>}
            </>
        )
    }

    return (
        <>
            {compact
                ?
                <ProjectTitleCompactContainer $pointer={pointer}>
                    <ProjectContent />
                </ProjectTitleCompactContainer>
                :
                (popover
                    ?
                    <ProjectTitlePopoverContainer >
                        <NwDivider>PROJECT {category && <> / {category.Name}</>}</NwDivider>
                        <ProjectContent />
                    </ProjectTitlePopoverContainer>
                    :
                    <ProjectTitleContainer $small={small} $pointer={pointer} $noProjectText={noProjectText}>
                        {noProjectText
                            ?
                            (category && <div className="first-line"><code>{category.Name}</code></div>)
                            :
                            <div className="first-line">
                                <p>PROJECT</p>
                                {category && <code>{category.Name}</code>}
                                {linkToProject &&
                                    <NwLinkButton
                                        icon={solid('expand-wide')}
                                        size="small"
                                        primary
                                        onClick={linkToProject}
                                        label="open project"
                                    />
                                }
                            </div>
                        }
                        <ProjectContent />
                    </ProjectTitleContainer>
                )
            }
            {showPreview &&
                <CustomerDrawer
                    customerID={showPreview}
                    onClose={handleCloseContactPreview}
                />
            }
        </>
    )
}

export default ProjectTitle