import React from "react";

import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";

const PrioritySelector = props => {
    return (
        <RadioButtonGroup 
            value={props.value} 
            disabled={props.disabled}
            onChange={props.onChange} 
            defaultValue={0}
            size='small' 
            options={[
                { label: 'Low', value: 0 },
                { label: 'High', value: 1 }
            ]} />
    );
};

export default PrioritySelector;





