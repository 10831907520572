import React from 'react';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { Loading } from 'Components/Gui/Loading';
import ModelsTable from 'Components/ContactsSelector/ModelsSelector/ModelsWall/ModelsTable/ModelsTable';
import EmptyState from 'Components/Gui/EmptyState';
import CenteredBlock from 'Components/Gui/CenteredBlock';
import { WallContainer } from './ModelsTableUI';
import { useState } from 'react';
import RadioButtonGroup from 'Components/Gui/NwForm/RadioButtonGroup';
import { useContactsSelector } from 'Components/ContactsSelector/Common/SelectorContext';

const ModelsTableWrapper = ({
    footer,
    isLoading,
    isMobile,
    models,
    //packageGroup
}) => {

    const { context, layout } = useContactsSelector();
    const { currentEvent, scope } = context;

    const isEventJob = currentEvent && currentEvent.TypeName === 'Job'

    const [addModelsAs, setAddModelsAs] = useState((isEventJob && currentEvent.Confirmed === 2) ? 10 : 0);

    return (
        <>
            <WallContainer>
                {isLoading ?
                    <Loading loadingType="centered" />
                    :
                    (models.length ?
                        <NwCard className="modeltable-card-wrapper" padding={layout.drawer ? "no" : ""}>
                            {(scope === 'event' && isEventJob) &&
                            <div className="add-models-as">
                                add models as
                                &nbsp;&nbsp; 
                                <RadioButtonGroup 
                                    buttonStyle="solid"
                                    value={addModelsAs}
                                    options={[
                                        {key: 0, value: 0, label: 'option'},
                                        {key: 10, value: 10, label: 'confirmed'},
                                    ]}
                                    onChange={value => {
                                        setAddModelsAs(value)
                                    }}
                                />
                            </div>
                            }
                            <ModelsTable
                                isMobile={isMobile}
                                list={models}
                                //packageGroup={packageGroup}
                                addModelsAs={addModelsAs}
                            />
                        </NwCard>
                        : <CenteredBlock>
                            <EmptyState message="no models fitting your search" />
                        </CenteredBlock>
                    )
                }
            </WallContainer>
            {footer}
        </>
    )
};

export default ModelsTableWrapper;
