import { getMoment } from "Libs/NwMoment";
import React, { useState, useEffect } from "react";
import { Tag } from "antd";
import * as Yup from "yup";
import styled from 'styled-components';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Loading } from 'Components/Gui/Loading';
import EditEvent from "Components/EventDrawer/EditEvent";
import {
    NWInput,
    NWContactDropdown,
    NWSwitch,
    NWTextAreaCharCounter,
    NWLongEventPeriodPicker
} from "Components/Gui/NwForm/NwFormItems";
import CancelEvent from "Components/EventButtons/CancelEvent";
import NwDropDownMenu from "Components/Gui/NwDropDownMenu";
import { EventTitleText, EventContainer, ObjectHistoryContainer, EventExternalJobAgencyName } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import { EventCommentsDocuments } from 'Components/EventDrawer/EventDrawerUi/EventCommentsDocuments';
import ObjectHistory from "Components/CreatedBy/ObjectHistory";
import { getNow, getTime, isAllDay } from "Libs/NwMoment";
import {
    useExternalJob,
    useDeleteExternalJob,
    useUpdateExternalJob,
    useAddExternalJob,
    forceReloadExternalJob
} from 'Hooks/Event/UseExternalJob';
import { getContactName } from "Hooks/Contact/UseContactsList";
import FlexContainer from "Components/Gui/FlexContainer";
import NwDrawer from 'Components/Gui/NwDrawer';
import ExtJobDetails from 'Components/EventDrawer/ExtJobsEvent/ExtJobDetails';
import { NwAddButton } from "Components/Gui/NwButton";
import AddExternalJobTransactionDrawer from "Components/Transactions/TransactionsDrawer/AddExternalJobTransactionDrawer";

const StyledTag = styled(Tag)`
    margin-bottom: 0.5rem;
`;

const Wrapper = styled.div`
    .event-details-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .event-details-header-texts {

        }
        .event-details-header-buttons {
            display: flex;
            align-items: flex-end;
            gap: 1rem;
        }
    }
    
`

const ExtJobsEvent = ({
    afterRemove,
    afterUpdate,
    allDay,
    dateSelected,
    ID,
    modelId,
    onClose,
    onTouchForm,
    viewMode,
}) => {
    const [isEdit, setIsEdit] = useState(false);
    const [externalJobID, setExternalJobID] = useState();
    const { data: externalJobData, isFetching } = useExternalJob(externalJobID);
    const { mutateAsync: onAddExternalJob } = useAddExternalJob();
    const { mutateAsync: onDeleteExternalJob } = useDeleteExternalJob();
    const { mutateAsync: onUpdateExternalJob } = useUpdateExternalJob();
    const [touchForm, setTouchForm] = useState(false);
    const [transactionDrawer, setTransactionDrawer] = useState(false);

    useEffect(() => {
        setExternalJobID(ID);
    }, [ID]);


    useEffect(() => {
        setIsEdit(viewMode ? true : false);
    }, [viewMode]);

    if (!externalJobData && externalJobID) {
        return (<Loading textBlack />);
    }

    let dataSource = [
        {
            component: NWLongEventPeriodPicker,
            label: 'Period',
            name: 'Period',
            Col: 24
        },
        {
            component: NWContactDropdown,
            label: "Agency",
            activeTypes: ["age"],
            activableTypes: [],
            name: "AgencyID",
        },
        {
            component: NWSwitch,
            label: 'Confirmed',
            name: 'Confirmed',
        },
        {
            component: NWInput,
            label: 'TimeSheet',
            name: 'TimeSheet',
            type: 'text'
        },
        {
            component: NWInput,
            label: 'Location',
            name: 'Location',
            type: 'text'
        },
        {
            component: NWInput,
            label: 'Client Name',
            name: 'ClientName',
            type: 'text'
        },
        {
            component: NWInput,
            label: 'Product Name',
            name: 'ProductName',
            type: 'text'
        },
        {
            component: NWTextAreaCharCounter,
            label: 'Description',
            name: 'Description',
            type: 'text',
            Col: 24,
            maxLength: 2000,
            autoSize: { minRows: 6, maxRows: 6 }
        },

    ];

    if (!modelId) {
        dataSource.splice(0, 0, {
            component: NWContactDropdown,
            label: "Model",
            activeTypes: ["mod"],
            activableTypes: [],
            name: "ModelID",
            required: true,
            Col: 24
        });
    }


    let initialValues = {
        NetworkAgencyCreated: externalJobData ? externalJobData.NetworkAgencyCreated : false,
        Confirmed: externalJobData ? externalJobData.Confirmed : false,
        TimeSheet: externalJobData ? externalJobData.TimeSheet : '',
        Location: externalJobData ? externalJobData.Location : '',
        ClientName: externalJobData ? externalJobData.ClientName : '',
        ProductName: externalJobData ? externalJobData.ProductName : '',
        ModelID: externalJobData ? externalJobData.ModelID : modelId,
        AgencyID: externalJobData ? externalJobData.AgencyID : '',
        UserCreated: externalJobData ? externalJobData.UserCreated : null,
        Description: externalJobData ? externalJobData.Description : '',
        StartDate: externalJobData ? externalJobData.StartDate : null,
        EndDate: externalJobData ? externalJobData.EndDate : null,
        Period: {
            startDate: externalJobData ? getMoment(externalJobData.StartDate).startOf('day')
                : dateSelected ? getMoment(dateSelected).startOf('day')
                    : getNow(true),
            endDate: externalJobData ? getMoment(externalJobData.EndDate).startOf('day')
                : dateSelected ? getMoment(dateSelected).startOf('day')
                    : getNow(true),
            startTime: getTime(externalJobData ? getMoment(externalJobData.StartDate)
                : dateSelected ? allDay ? getMoment(dateSelected).startOf('day')
                    : dateSelected : getNow().hour(9).minute(0)),
            endTime: getTime(externalJobData ? getMoment(externalJobData.EndDate) :
                dateSelected ? allDay ? getMoment(dateSelected).endOf('day')
                    : dateSelected.clone().add(2, 'hours') : getNow().hour(11).minute(0)),
            allDay: externalJobData ? isAllDay(getMoment(externalJobData.StartDate), getMoment(externalJobData.EndDate)) : allDay || false,
            dates: []
        },
    };

    const submitForm = async (values, setStatus, setSubmitting) => {
        const newJobData = {
            ...values,
            StartDate: getMoment(values.Period.startDate).hour(values.Period.startTime[0]).minute(values.Period.startTime[1]),
            EndDate: getMoment(values.Period.endDate).hour(values.Period.endTime[0]).minute(values.Period.endTime[1])
        };
        if (!externalJobData) {
            try {
                const response = await onAddExternalJob({ externalJobData: newJobData });
                if (onTouchForm) {
                    onTouchForm(false);
                }
                if (touchForm) {
                    setTouchForm(false);
                }
                if (afterUpdate) {
                    afterUpdate({
                        ...response,
                        TypeName: "ExtJob"
                    });
                }
                setExternalJobID(response.ID);
                setIsEdit(false);
            } catch (error) {
                console.log("ant : Create External Job Error", error);
            }
            setSubmitting(false);
        }
        else {
            newJobData.ID = externalJobData.ID;
            try {
                await onUpdateExternalJob({ externalJobData: newJobData });
                if (onTouchForm) {
                    onTouchForm(false);
                }
                if (touchForm) {
                    setTouchForm(false);
                }
                if (afterUpdate) {
                    afterUpdate();
                }
                if (!viewMode || viewMode === 'create') {
                    setIsEdit(false);
                }
            } catch (error) {
                console.log("ant : Edit External Job Error", error);
            }
            setSubmitting(false);
        }
    };

    let validationSchema = Yup.object().shape({
        ClientName: Yup.string().required("Client Name is required"),
        ProductName: Yup.string().required("Product Name is required"),
        AgencyID: Yup.string().required("Agency is required"),
        ModelID: Yup.string().required("Model is required"),
    });

    const handleRemoveEvent = async eventId => {
        try {
            await onDeleteExternalJob({ ID: eventId, travelId: externalJobID });
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    };

    const onAfterAddingExpense = () => {
        forceReloadExternalJob(externalJobData.ID);
        setTransactionDrawer(false)
    }

    if (isFetching) {
        return (<Loading />)
    } else {
        return (
            <EventContainer hasProject={false} noProjectHeader={true}>
                <div className="ant-drawer-header ant-drawer-header-sticky ">
                    {externalJobData ?
                        <FlexContainer mg='0 0 0 32px' justifyContent="space-between">
                            External Job
                        </FlexContainer>
                        :
                        <div className="ant-drawer-title">Add New External Job</div>
                    }
                </div>
                {externalJobData ?
                    <Wrapper>
                        <div className="event-content-wrapper">
                            <div className="event-details">
                                <div className="event-details-header">
                                    <div className="event-details-header-texts">
                                        <EventTitleText className={externalJobData.Cancel ? 'is-cancelled' : null}>{externalJobData.ModelID ? getContactName(externalJobData.ModelID) : 'No contact'}</EventTitleText>
                                        <EventExternalJobAgencyName className={externalJobData.Cancel ? 'is-cancelled' : null}>
                                            {externalJobData.AgencyID && <h2>{getContactName(externalJobData.AgencyID)}</h2>}
                                            <StyledTag color={externalJobData.Confirmed ? '#f5222d' : '#bfbfbf'}>{externalJobData.Confirmed ? 'CONFIRMED' : 'OPTION'}</StyledTag>
                                        </EventExternalJobAgencyName>
                                    </div>
                                    <div className="event-details-header-buttons">
                                        <CancelEvent
                                            button
                                            onStatusChanged={() => {
                                                forceReloadExternalJob(ID);
                                                afterUpdate();
                                            }}
                                            cancelValue={externalJobData.Cancel} type="ExtJob" eventId={ID} />
                                        <NwDropDownMenu
                                            actions={[
                                                { name: "", key: "edit", pinned: true, icon: light("pen"), action: (() => setIsEdit(!isEdit)) },
                                                {
                                                    name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (async () => {
                                                        await handleRemoveEvent(externalJobData.ID);
                                                        afterRemove(true);
                                                    })
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <ExtJobDetails extJobData={externalJobData} />
                            <ObjectHistoryContainer>
                                <ObjectHistory asLine object={externalJobData} />
                            </ObjectHistoryContainer>
                            <EventCommentsDocuments
                                event={externalJobData}
                                api='ExternalJobID'
                                type='ExtJob'
                                drawerMode
                                fetchEvent={() => forceReloadExternalJob(externalJobData.ID)}
                                onTouchForm={onTouchForm}
                                onTransactionCreatedOrUpdated={() => forceReloadExternalJob(externalJobData.ID)}
                                extra={
                                    <NwAddButton
                                        ghost
                                        onClick={() => { setTransactionDrawer(true) }}
                                        label="add commissions"
                                        icon={solid("comment-plus")}
                                    />
                                }
                            />
                        </div>
                        {transactionDrawer &&
                            <AddExternalJobTransactionDrawer
                                event={externalJobData}
                                onClose={() => setTransactionDrawer(false)}
                                onSave={onAfterAddingExpense}
                            />
                        }
                        {isEdit &&
                            <NwDrawer
                                padding="0px"
                                width={740}
                                touchForm={touchForm}
                                onTouchForm={setTouchForm}
                                title='Edit Follow up'
                                onClose={() => {
                                    if (touchForm) {
                                        setTouchForm(false);
                                    }
                                    setIsEdit(false);
                                }}
                            >
                                <EventContainer>
                                    <EditEvent
                                        submitForm={submitForm}
                                        mg={'0'}
                                        validationSchema={validationSchema}
                                        dataSource={dataSource}
                                        initialValues={initialValues}
                                        onTouchForm={setTouchForm}
                                        onCancel={() => {
                                            if (externalJobData) {
                                                setIsEdit(false)
                                            } else {
                                                onClose();
                                            }
                                            if (touchForm) {
                                                setTouchForm(false);
                                            }
                                        }} />
                                </EventContainer>
                            </NwDrawer>
                        }
                    </Wrapper>
                    :
                    <div className="event-content-wrapper">
                        <EditEvent
                            submitForm={submitForm}
                            mg={'0'}
                            validationSchema={validationSchema}
                            dataSource={dataSource}
                            initialValues={initialValues}
                            onTouchForm={onTouchForm}
                            onCancel={() => {
                                if (externalJobData) {
                                    setIsEdit(false)
                                } else {
                                    onClose();
                                }
                                if (onTouchForm) {
                                    onTouchForm(false);
                                }
                            }} />
                    </div>
                }
            </EventContainer>
        );
    }
};

export default ExtJobsEvent;
