import { useQuery, useMutation } from '@tanstack/react-query';
import Axios from 'axios';

import { queryClient } from 'QueryClientProvider';

const getContactLegalsByContactID = ID => (
    Axios.get(`/contactlegals/getlegalsbycontact/${ID}`)
)

const deleteLegal = ({ LegalID, ContactID }) => (
    Axios.delete(`/contactlegals/${LegalID}`)
)

const addLegal = (data) => (
    Axios.post(`/contactlegals`, data)
)

const editLegal = (data) => (
    Axios.put(`/contactlegals`, data)
)

const useContactLegals = (ID) => {
    const intID = Number(ID)
    return useQuery(["contactLegals", intID], () => getContactLegalsByContactID(intID), {
        staleTime: 60000,
        cacheTime: 60000
    })
}

const useRemoveLegal = () => {
    return useMutation(deleteLegal, {
        onSuccess: (data, variables) => {
            const intID = Number(variables.ContactID)
            queryClient.invalidateQueries(['contactLegals', intID])
            const model = queryClient.getQueryData(['contact', intID])
            const newLegalsCount = model.LegalsCount - 1;
            queryClient.setQueryData(['contact', intID], (old) => ({
                ...old,
                LegalsCount: newLegalsCount,
            }))
        }
    })
}

const useAddLegal = () => {
    return useMutation(addLegal, { 
        onSuccess: (data, variables) => {
            const intID = Number(variables.ContactID)
            queryClient.invalidateQueries(['contactLegals', intID])
            const model = queryClient.getQueryData(['contact', intID])
            const newLegalsCount = model.LegalsCount + 1;
            queryClient.setQueryData(['contact', intID], (old) => ({
                ...old,
                LegalsCount: newLegalsCount,
            }))
        }
    })
}

const useEditLegal = () => {
    return useMutation(editLegal, { 
        onSuccess: (data, variables) => {
            const intID = Number(variables.ContactID)
            queryClient.invalidateQueries(['contactLegals', intID])
        }
    })
}

const onUploadLegal = (ContactID) => {
    const intID = Number(ContactID)
    queryClient.invalidateQueries(['contactLegals', intID])
}

export {
    useContactLegals,
    useRemoveLegal,
    useAddLegal,
    useEditLegal,
    onUploadLegal
}
