import React, { createContext, useContext, useMemo, useReducer } from 'react';
import ls from 'local-storage';
import Axios from 'axios';
import { UserContext } from 'Contexts/UserContext';
import { createCustomerFilterList, createModelFilterList, findFilterInFiltersBySection, formatContactsFilterValues } from 'Components/Filters/FilterForm/FilterUtils';
import { useGlobalFeatures, useGlobalFeatureSets } from 'Hooks/Features/UseFeatures';
import { ListContext } from 'Contexts/ListContext';
import { Loading } from 'Components/Gui/Loading';
import _ from 'lodash';
import { getMoment } from 'Libs/NwMoment';

import { MODELS_FILTERS } from 'Configs/Filters/MODELS_FILTERS';
import { MODELS_FILTERS_SET } from 'Configs/Filters/MODELS_FILTERS_SETS';
import { CUSTOMERS_FILTERS } from 'Configs/Filters/CUSTOMERS_FILTERS';
import { useUserGuiConfig } from 'Contexts/UserGuiConfigContext';
//import { CUSTOMERS_FILTERS_SET } from 'Configs/Filters/CUSTOMERS_FILTERS_SETS';


export const SelectorContext = createContext();

const ContactsSelectorContext = createContext(null);

const ContactsSelectorDispatchContext = createContext(null);


const getWorkingSets = (sets, currentUserID, contactType) => {
    const FILTERS_LIST = contactType === 'model' ? MODELS_FILTERS : CUSTOMERS_FILTERS;
    return sets.map(set => {
        const setFilters = [...set.filters]
        return {
            ...set,
            filters: setFilters.map(subFilter => {
                const filterItem = FILTERS_LIST.filters.find(item => item.name === subFilter.name);
                if (filterItem) {
                    if (filterItem.type === 'date') {
                        let value = subFilter.value;
                        if (subFilter.value && typeof subFilter.value === "string") {
                            const match = subFilter.value.match(/\{(\w+)\}/);
                            if (!match) {
                                value = getMoment(subFilter.value);
                            }
                        }
                        return {
                            ...subFilter,
                            value: value
                        }
                    }
                    if (filterItem.type === 'booker-selector') {
                        if (subFilter.value === '{me}') {
                            return {
                                ...subFilter,
                                value: currentUserID
                            }
                        }
                    }
                }
                return subFilter;
            })
        }
    });
};

const getHydratedActiveFilters = (activefilters, fulllist) => {
    const hafs = []
    if (activefilters && activefilters.length > 0) {
        for (const filter of activefilters) {
            const filterObj = findFilterInFiltersBySection(filter.name, fulllist)
            if (filterObj) {
                hafs.push({
                    name: filter.name,
                    area: filterObj.area,
                    areaIndex: filterObj.areaIndex,
                    value: filter.value,
                    source: filterObj.source,
                    //fieldTypeName: null,
                })
            }
        }
    }
    return hafs
}

const getFilterSectionsWithCounter = (sections, activefilters) => {
    if (!activefilters || activefilters.length === 0) {
        return sections.map(section => {
            return {
                ...section,
                counter: 0
            }
        })
    }
    const sectionsWithCounters = sections.map(section => {
        const sectionFilters = activefilters.filter(filter => filter.area === section.areaID)
        return {
            ...section,
            counter: sectionFilters.length
        }
    })
    return sectionsWithCounters
}

export function ContactsSelectorProvider({
    dataContext,
    dataSelection,
    dataActions,
    dataLayout,
    dataAdditional,
    //featuresList,
    //values, 
    startListType,
    startActiveSet,
    children
}) {
    const { currentUser } = useContext(UserContext);
    const currentUserID = currentUser.UserID;
    const { data: featuresList, isFetching: isFetchingGF } = useGlobalFeatures();
    const { data: globalFeatureSets, isFetching: isFetchingGFS } = useGlobalFeatureSets();

    // const folders = useFolders()
    // const folder_dispatch = useFoldersDispatch()

    const { cachedList } = useContext(ListContext);
    const allCustomFields = cachedList.customFields.items;

    const customFields = useMemo(() => {
        if (dataContext.itemType === 'model') {
            return [...allCustomFields['Model']]
        }
        return [...allCustomFields['Client'], ...allCustomFields['Agency'], ...allCustomFields['Service']]
    }, [allCustomFields])

    const userGuiConfig = useUserGuiConfig()

    const workingSets = []
    const allListsFromCookie = ls.get(`nw_config_${dataContext.itemType}_smartlists`)
    if (allListsFromCookie && allListsFromCookie.length > 0) {
        workingSets.push(...allListsFromCookie)
    } else {
        const staticSetsWithOriginals = MODELS_FILTERS_SET.staticSets.map(set => (
            {
                ...set,
                originalFilters: [...set.filters],
                unsaved: false
            }
        ))
        workingSets.push(...staticSetsWithOriginals)
    }

    const savedWallIDs = null
    const selectedItemsIDs = []
    const modelAvailability = null

    const currentSet = useMemo(() => {
        if (startListType && startActiveSet && startListType === "smart-lists") {
            const ws = workingSets.find(item => item.id.toString() === startActiveSet)
            return _.cloneDeep(ws)
        }
        return null
    }, [startListType, startActiveSet, workingSets])

    const fullFiltersListBySection = useMemo(() => {
        if (!isFetchingGFS && !isFetchingGF) {
            if (dataContext.itemType === 'model') {
                return createModelFilterList(globalFeatureSets, featuresList, userGuiConfig, customFields)
            } else {
                return createCustomerFilterList(userGuiConfig, customFields)
            }
        }
        return []
    }, [isFetchingGFS, isFetchingGF, globalFeatureSets, featuresList, userGuiConfig, customFields]) 

    const currentHydratedFilters = useMemo(() => {
        if (currentSet && currentSet.filters && currentSet.filters.length > 0) {
            return getHydratedActiveFilters(currentSet.filters, fullFiltersListBySection)
        }
        return []
    }, [currentSet, fullFiltersListBySection])

    const filtersSections = useMemo(() => {
        if (!isFetchingGFS && fullFiltersListBySection && fullFiltersListBySection.length > 0) {
            const sectionsOnly = [...fullFiltersListBySection].map(section => ({ areaName: section.areaName, areaID: section.areaID, areaFilters: [], counter: 0 }))
            return sectionsOnly
        }
        return []
    }, [fullFiltersListBySection, isFetchingGFS])

    // const filtersSectionsWithCounter = useMemo(() => {
    //     console.log("useMemo > filtersSectionsWithCounter")
    //     const sectionsWithCounter = getFilterSectionsWithCounter(filtersSections, currentHydratedFilters)
    //     return sectionsWithCounter
    // }, [filtersSections, currentHydratedFilters])

    const filtersSectionsWithCounter = getFilterSectionsWithCounter(filtersSections, currentHydratedFilters)

    const activeSetName = useMemo(() => {
        if (currentSet) {
            return currentSet.name
        }
        if (dataSelection.listType === "folders") {
            return "Folders"
        }
        return "all-models"
    }, [currentSet, dataSelection.listType])

    const activeSetFixed = useMemo(() => {
        if (currentSet) {
            return currentSet.fixed
        }
        return false
    }, [currentSet])

    const contextData = useMemo(() => ({
        context: {
            ...dataContext,
            currentUserID: currentUserID,
        },
        selection: {
            ...dataSelection,
            activeSetName,
            activeSetFixed,
            filters: {
                active: currentHydratedFilters,
                working: currentSet ? [...currentSet.filters] : null,
                openSection: null
            }
        },
        layout: {
            ...dataLayout,
        },
        actions: {
            ...dataActions,
            multipleSelectionActive: false,
            selectedItemsIDs,
        },
        additional: {
            ...dataAdditional
        },
        cache: {
            savedWallIDs,
            modelAvailability
        },
        global: {
            filters: MODELS_FILTERS,
            filtersSections: filtersSectionsWithCounter,
            filtersBySection: fullFiltersListBySection,
            sets: workingSets,
            featuresList,
        },
    }), [
        dataContext,
        currentUserID,
        dataSelection,
        activeSetName,
        activeSetFixed,
        currentHydratedFilters,
        currentSet,
        dataLayout,
        dataActions,
        selectedItemsIDs,
        dataAdditional,
        savedWallIDs,
        modelAvailability,
        filtersSectionsWithCounter,
        fullFiltersListBySection,
        workingSets,
        featuresList
    ]);

    const [data, dispatch] = useReducer(customSetsReducer, contextData);

    if (filtersSections && filtersSections.length > 0) {
        return (
            <ContactsSelectorContext.Provider value={data}>
                <ContactsSelectorDispatchContext.Provider value={dispatch}>
                    {children}
                </ContactsSelectorDispatchContext.Provider>
            </ContactsSelectorContext.Provider>
        );
    }
    return <Loading />
}

export function useContactsSelector() {
    return useContext(ContactsSelectorContext);
}

export function useContactsSelectorDispatch() {
    return useContext(ContactsSelectorDispatchContext);
}

const saveSmartList = async (customlists, itemType) => {
    const onlycustoms = customlists.filter(item => !item.fixed).map(item => {
        return {
            id: item.id,
            name: item.name,
            filters: item.filters,
            fixed: item.fixed
        }
    })
    try {
        await Axios.post('/userclientconfigurations', {
            Name: `${itemType}-custom-smart-lists`,
            JsonData: JSON.stringify({ customSmartLists: [...onlycustoms] })
        });
    } catch (error) {
        console.log('ant : Save Smart List Error => ', error);
    }
};

const customSetsReducer = (state, action) => {
    const { global, context, layout, actions, additional, cache, selection, internal } = state;
    const { itemType, area, currentUserID, scope } = context;
    const { sets, filtersBySection, filtersSections } = global;
    const localStorageSL = `nw_config_${itemType}_smartlists`
    const localStorageName = `nw_config_${area}_${scope.replace("-", "")}`
    const localStorageNameNoScope = `nw_config_${area}`

    switch (action.type) {
        case "UpdateCurrentEvent":
            return {
                ...state,
                context: {
                    ...context,
                    currentEvent: action.eventUpdated
                }
            };
        case "UpdateModelAvailability":
            return {
                ...state,
                cache: {
                    ...cache,
                    modelAvailability: action.data
                }
            };
        case "ChangeShowOnlyModelsWithBooks":
            return {
                ...state,
                additional: {
                    ...additional,
                    showOnlyModelsWithBooks: action.value
                }
            };
        case "ToggleMultipleSelection":
            return {
                ...state,
                actions: {
                    ...actions,
                    multipleSelectionActive: action.value,
                    selectedItemsIDs: []
                }
            };
        case "ToggleSelectedItem":
            let newSelectedItemsList = [...state.actions.selectedItemsIDs];
            if (newSelectedItemsList.includes(action.ID)) {
                newSelectedItemsList = newSelectedItemsList.filter(item => item !== action.ID);
            } else {
                newSelectedItemsList = [...newSelectedItemsList, action.ID];
            }
            return {
                ...state,
                actions: {
                    ...actions,
                    selectedItemsIDs: newSelectedItemsList
                }
            };
        case "UpdateSelectedItems":
            return {
                ...state,
                actions: {
                    ...actions,
                    selectedItemsIDs: action.newlist
                }
            };
        case "ClearSelectedItems":
            return {
                ...state,
                actions: {
                    ...actions,
                    selectedItemsIDs: []
                }
            };
        case "ChangeView":
            ls.set(`${localStorageName}_view`, action.view);
            return {
                ...state,
                layout: {
                    ...layout,
                    view: {
                        ...layout.view,
                        current: action.view
                    }
                }
            };

        //update the savedWallIDs
        case "UpdateSavedWallIDs":
            return {
                ...state,
                cache: {
                    ...cache,
                    savedWallIDs: action.savedWallIDs
                }
            };

        //switch between list types: 'all-models', 'smart-lists', 'folders'
        case "ChangeListType":
            if (action.listType === "smart-lists") {
                const firstset = _.cloneDeep(sets[0]);
                const filtersToSetFirstSet = formatContactsFilterValues(firstset.filters, currentUserID, itemType);
                const hydratedFiltersToSetFirstSet = getHydratedActiveFilters(filtersToSetFirstSet, filtersBySection);
                const sectionsWithCounterFirstSet = getFilterSectionsWithCounter(filtersSections, hydratedFiltersToSetFirstSet);
                return {
                    ...state,
                    selection: {
                        ...selection,
                        listType: action.listType,
                        activeSet: firstset.id,
                        activeSetName: firstset.name,
                        activeSetFixed: firstset.fixed,
                        filters: {
                            active: hydratedFiltersToSetFirstSet,
                            working: [...filtersToSetFirstSet],
                            //original: [...filtersToSetFirstSet],
                        }
                    },
                    actions: {
                        ...actions,
                        selectedItemsIDs: []
                    },
                    global: {
                        ...global,
                        filtersSections: sectionsWithCounterFirstSet
                    }
                };
            } else {
                return {
                    ...state,
                    selection: {
                        ...selection,
                        listType: action.listType,
                        filters: {
                            active: [],
                            working: [],
                        }
                    },
                    actions: {
                        ...actions,
                        selectedItemsIDs: []
                    }
                };
            }
        case "ChangeQuickFilters":
            // if (action.quickFilters && action.quickFilters.sort) {
            //     ls.set(`${localStorageNameNoScope}_sort`, action.quickFilters.sort);
            // }
            // if (action.quickFilters && action.quickFilters.sortFolder) {
            //     ls.set(`${localStorageNameNoScope}_sortFolder`, action.quickFilters.sortFolder);
            // }
            ls.set(`${localStorageNameNoScope}_quickFilters`, action.quickFilters);

            return {
                ...state,
                selection: {
                    ...selection,
                    quickFilters: action.quickFilters
                },
                actions: {
                    ...actions,
                    selectedItemsIDs: []
                }
            };

        //Select activeSet: could be the ID of a smart list or the ID of a folder
        case "ChangeActiveSet":
            const newset = sets.find(item => item.id.toString() === action.activeSet.toString());
            const clonedNewSet = _.cloneDeep(newset);
            const filtersToSet = formatContactsFilterValues(clonedNewSet.filters, currentUserID, itemType);
            const hydratedFiltersToSet = getHydratedActiveFilters(filtersToSet, filtersBySection);
            const sectionsWithCounter = getFilterSectionsWithCounter(filtersSections, hydratedFiltersToSet);
            return {
                ...state,
                selection: {
                    ...selection,
                    activeSet: action.activeSet,
                    activeSetName: clonedNewSet.name,
                    activeSetFixed: clonedNewSet.fixed,
                    filters: {
                        active: hydratedFiltersToSet,
                        working: [...filtersToSet],
                    }
                },
                actions: {
                    ...actions,
                    selectedItemsIDs: []
                },
                global: {
                    ...global,
                    filtersSections: sectionsWithCounter
                }
            };
        case "OpenFiltersSection":
            return {
                ...state,
                selection: {
                    ...selection,
                    filters: {
                        ...selection.filters,
                        openSection: action.sectionID
                    }
                }
            };
        case "CloseFiltersSection":
            return {
                ...state,
                selection: {
                    ...selection,
                    filters: {
                        ...selection.filters,
                        openSection: null
                    }
                }
            };
        case "ChangeFilters":
            const changedFiltersToSet = formatContactsFilterValues(action.filters, currentUserID, itemType);
            return {
                ...state,
                selection: {
                    ...selection,
                    filters: {
                        ...selection.filters,
                        working: changedFiltersToSet,
                    }
                },
                actions: {
                    ...actions,
                    selectedItemsIDs: []
                },
            };
        case "ApplyWorkingFilters":
            const workingFiltersToActiveFilters = getHydratedActiveFilters(selection.filters.working, filtersBySection);
            const sectionsWithCounter2 = getFilterSectionsWithCounter(filtersSections, workingFiltersToActiveFilters);

            const customSetsUpdatedTemp = [...sets].map(item => {
                if (item.id === selection.activeSet) {
                    return {
                        ...item,
                        filters: selection.filters.working,
                        unsaved: true
                    }
                }
                return item
            })
            ls.set(localStorageSL, customSetsUpdatedTemp)

            const workingSetsUpdated5 = getWorkingSets([...customSetsUpdatedTemp], currentUserID, itemType)

            return {
                ...state,
                selection: {
                    ...selection,
                    filters: {
                        ...selection.filters,
                        active: workingFiltersToActiveFilters,
                        openSection: null
                    }
                },
                actions: {
                    ...actions,
                    selectedItemsIDs: []
                },
                global: {
                    ...global,
                    sets: workingSetsUpdated5,
                    filtersSections: sectionsWithCounter2
                }
            };
        case "RevertChanges":
            const currentSetOriginalFilters = [...sets].find(item => item.id === selection.activeSet).originalFilters;
            const originalFiltersToActiveFilters = getHydratedActiveFilters([...currentSetOriginalFilters], filtersBySection);
            const sectionsWithCounter3 = getFilterSectionsWithCounter(filtersSections, originalFiltersToActiveFilters);
            
            const customSetsUpdatedTemp2 = [...sets].map(item => {
                if (item.id === selection.activeSet) {
                    return {
                        ...item,
                        filters: [...currentSetOriginalFilters],
                        unsaved: false
                    }
                }
                return item
            })
            ls.set(localStorageSL, customSetsUpdatedTemp2)
            const workingSetsUpdated = getWorkingSets([...customSetsUpdatedTemp2], currentUserID, itemType)
            return {
                ...state,
                selection: {
                    ...selection,
                    filters: {
                        ...selection.filters,
                        active: originalFiltersToActiveFilters,
                        working: [...currentSetOriginalFilters]
                    }
                },
                actions: {
                    ...actions,
                    selectedItemsIDs: []
                },
                global: {
                    ...global,
                    sets: workingSetsUpdated,
                    filtersSections: sectionsWithCounter3
                }
            };
        case "AddNewSet":
            const customSetCopy = [...sets].map(item => {
                if (item.id === selection.activeSet) {
                    return {
                        ...item,
                        filters: [...item.originalFilters],
                        unsaved: false
                    }
                }
                return item
            })
            const newid = `sl-${Date.now().toString()}`;
            customSetCopy.push({
                id: newid,
                name: action.name,
                filters: [...selection.filters.working],
                originalFilters: [...selection.filters.working],
                fixed: false,
                unsaved: false
            })
            ls.set(localStorageSL, customSetCopy);
            saveSmartList(customSetCopy, itemType);
            const workingSetsUpdated1 = getWorkingSets([...customSetCopy], currentUserID, itemType)
            return {
                ...state,
                global: {
                    ...global,
                    sets: workingSetsUpdated1
                },
                selection: {
                    ...selection,
                    activeSet: newid,
                    activeSetName: action.name,
                    activeSetFixed: false,
                }
            };
        case "SaveSetFilters":
            const customSetsUpdated = [...sets].map(item => {
                if (item.id === selection.activeSet) {
                    return {
                        ...item,
                        filters: [...selection.filters.working],
                        originalFilters: [...selection.filters.working],
                        unsaved: false
                    }
                }
                return item
            })
            ls.set(localStorageSL, customSetsUpdated);
            saveSmartList(customSetsUpdated, itemType);
            const workingSetsUpdated2 = getWorkingSets([...customSetsUpdated], currentUserID, itemType)
            return {
                ...state,
                global: {
                    ...global,
                    sets: workingSetsUpdated2
                }
            };
        case "RenameSet":
            const customSetsUpdated2 = [...sets].map(item => {
                if (item.id === selection.activeSet) {
                    return {
                        ...item,
                        name: action.newName
                    }
                }
                return item
            })
            ls.set(localStorageSL, customSetsUpdated2);
            saveSmartList(customSetsUpdated2, itemType);
            const workingSetsUpdated3 = getWorkingSets([...customSetsUpdated2], currentUserID, itemType)
            return {
                ...state,
                selection: {
                    ...selection,
                    activeSetName: action.newName
                },
                global: {
                    ...global,
                    sets: workingSetsUpdated3
                }
            }
        case "DeleteSet":
            const customSetsFilteredForDelete = sets.filter(
                el => el.id !== action.setID
            );
            ls.set(localStorageSL, customSetsFilteredForDelete);
            saveSmartList(customSetsFilteredForDelete, itemType);
            window.history.replaceState(null, "", "/models/smart-lists/all")

            const defaultSet = sets[0];
            const workingSetsUpdated4 = getWorkingSets([...customSetsFilteredForDelete], currentUserID, itemType)
            const sectionsWithCounter4 = getFilterSectionsWithCounter(filtersSections, [...defaultSet.filters]);
            const defaultSetFiltersToActive = getHydratedActiveFilters([...defaultSet.filters], filtersBySection);
            
            return {
                ...state,
                selection: {
                    ...selection,
                    activeSet: 'all',
                    activeSetName: defaultSet.name,
                    filters: {
                        working: [...defaultSet.filters],
                        active: [...defaultSetFiltersToActive],
                    }
                },
                global: {
                    ...global,
                    sets: workingSetsUpdated4,
                    filtersSections: sectionsWithCounter4
                }
            };

        //FOLDERS
        case "SelectFolder":
            return {
                ...state,
                selection: {
                    ...selection,
                    listType: "folders",
                    activeSet: action.folderID,
                    activeSetName: action.folderName,
                    activeSetFixed: true,
                    filters: {
                        active: [],
                        working: [],
                    }
                },
                actions: {
                    ...actions,
                    selectedItemsIDs: []
                }
            };

        default:
            return state;
    }
};
