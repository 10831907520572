import { getMoment, getTodayISO, getTodayEndOfTheDayISO, getLastYearISO, getNextYearISO, getStartCurrentISO, getEndCurrentISO, getStartLastISO, getEndLastISO } from "Libs/NwMoment";
import { getLastLog } from "Libs/NwLogs";
import { MODELS_FILTERS } from "Configs/Filters/MODELS_FILTERS";
import { STATEMENTS_FILTERS } from 'Configs/Filters/STATEMENTS_FILTERS';
import { groupConversionValues } from "Libs/NWFeatures";
import { CUSTOMERS_FILTERS } from "Configs/Filters/CUSTOMERS_FILTERS";


//non-hook version of useDataPlaceholder
const replacePlaceholder = (value, currentUserID) => {
    var newval = null;
    switch (value) {
        case "{today_today}":
            newval = [getTodayISO(), getTodayISO()];
            break;
        case "{tomorrow_tomorrow}":
            newval = [getTodayISO(1), getTodayISO(1)];
            break;
        case "{yesterday_yesterday}":
            newval = [getTodayISO(-1), getTodayISO(-1)];
            break;
        case "{currentweek}":
            newval = [getStartCurrentISO('week'), getEndCurrentISO('week')];
            break;
        case "{currentmonth}":
            newval = [getStartCurrentISO('month'), getEndCurrentISO('month')];
            break;
        case "{currentyear}":
            newval = [getStartCurrentISO('year'), getEndCurrentISO('year')];
            break;
        case "{lastweek}":
            newval = [getStartLastISO('week'), getEndLastISO('week')];
            break;
        case "{lastmonth}":
            newval = [getStartLastISO('month'), getEndLastISO('month')];
            break;
        case "{lastyear}":
            newval = [getStartLastISO('year'), getEndLastISO('year')];
            break;
        case "{last7days}":
            newval = [getTodayISO(-7), getTodayISO()];
            break;
        case "{last30days}":
            newval = [getTodayISO(-30), getTodayISO()];
            break;
        case "{last365days}":
            newval = [getLastYearISO(), getTodayEndOfTheDayISO()];
            break;
        case "{next7days}":
            newval = [getTodayISO(), getTodayISO(7)];
            break;
        case "{next30days}":
            newval = [getTodayISO(), getTodayISO(30)];
            break;
        case "{next365days}":
            newval = [getTodayISO(), getNextYearISO()];
            break;
        case "{today}":
            newval = getTodayISO();
            break;
        case "{tomorrow}":
            newval = getTodayISO(1);
            break;
        case "{yesterday}":
            newval = getTodayISO(-1);
            break;
        case "{amonthago}":
            newval = getTodayISO(-30);
            break;
        case "{aweekago}":
            newval = getTodayISO(-7);
            break;
        case "{ayearago}":
            newval = getLastYearISO();
            break;
        case "{me}":
            newval = currentUserID;
            break;
        case "{lastmodel}":
            newval = getLastLog("model");
            break;
        case "{lastclient}":
            newval = getLastLog("client");
            break;
        case "[]":
            newval = [];
            break;
        default:
            newval = value;
    }
    return newval;
}

const getInitialValue = (typeName, value) => {
    switch (typeName) {
        // case 'MultipleChoice':
        //     return value ? value.split(',') : [];
        case 'Boolean':
            return value ? value : false;
        // case 'Numeric':
        //     return value ? value : 0;
        case 'Date':
            return value ? getMoment(value) : ''
        default:
            return value ? value : '';
    };
};

const createModelFilterList = (globalFeatureSets, globalFeatures, userGuiConfig, customFields) => {
    const sections = []
    const usedSections = []
    for (const models_filter of MODELS_FILTERS.filters) {
        if (models_filter.area && !usedSections.includes(models_filter.area)) {
            usedSections.push(models_filter.area)
            sections.push({ areaName: models_filter.area, areaID: models_filter.area, areaFilters: [] })
        }
    }

    MODELS_FILTERS.filters.forEach(filter => {
        //find the section that the filter belongs to
        const section = sections.find(section => section.areaName === filter.area)
        //add filter to the section
        section.areaFilters.push({
            ...filter,
            id: filter.name,
            value: filter.defaultval,
            source: 'filter',
            options: []
        })
    });

    //add custom fields to filters
    if (customFields && customFields.length > 0) {
        const customFieldSection = { areaName: "additional info", areaID: "additionalInfo", areaFilters: [] }
        for (const customField of customFields) {
            const customFieldFilter = {
                name: customField.Name,
                label: customField ? customField.Name.replace(/_/g, ' ') : '',
                type: customField.TypeName,
                value: getInitialValue(customField.TypeName),
                area: 'additionalInfo',
                areaIndex: 4,
                source: 'customField',
                options: customField.Options ? customField.Options : []
            }
            customFieldSection.areaFilters.push(customFieldFilter)
        }
        sections.push(customFieldSection)
    }

    //add a new section for each featureset and add the features filters to the section
    if (globalFeatures && globalFeatureSets && userGuiConfig) {
        for (const featureSet of globalFeatureSets) {
            const newSection = { areaName: `features`, areaID: featureSet.ID, areaFilters: [] }
            if (globalFeatureSets.length > 1) {
                newSection.areaName = `features (${featureSet.Name})`
            }
            for (const featureGroup of featureSet.FeatureGroups) {
                for (const groupFeature of featureGroup.GroupFeatures) {
                    const feature = globalFeatures.find(feature => feature.ID === groupFeature.FeatureID);
                    let featureFilter = {
                        name: feature.ID,
                        label: feature.Name,
                        type: feature.TypeName,
                        area: featureSet.ID,
                        areaIndex: 5,
                        featureSetName: featureSet.Name,
                        source: 'feature',
                        options: []
                    };
                    switch (feature.TypeName) {
                        case "Conversion":
                            const conversion = userGuiConfig.metrics.find(metric => metric.ID === feature.ConversionID);
                            if (conversion) {
                                const groupedConversionValues = groupConversionValues(conversion.ConversionValues);
                                featureFilter.value = [groupedConversionValues[0].Key, groupedConversionValues[groupedConversionValues.length - 1].Key];
                            } else {
                                featureFilter.value = []
                            }
                            break;
                        case "Multichoice":
                            featureFilter.value = {
                                choices: [],
                                operator: 'and'
                            };
                            break;
                        case "Choice":
                        case "Trans":
                            featureFilter.value = [];
                            break;
                        case 'Boolean':
                            featureFilter.value = false;
                            break;
                        case 'Numeric':
                            featureFilter.value = [0, 0];
                            break;
                        default:
                            featureFilter.value = "";
                            break;
                    }
                    newSection.areaFilters.push(featureFilter)
                }
            }
            sections.push(newSection)
        }
    }
    return sections;
};

const createCustomerFilterList = (userGuiConfig, customFields) => {
    const sections = []
    const usedSections = []
    for (const customers_filter of CUSTOMERS_FILTERS.filters) {
        if (customers_filter.area && !usedSections.includes(customers_filter.area)) {
            usedSections.push(customers_filter.area)
            sections.push({ areaName: customers_filter.area, areaID: customers_filter.area, areaFilters: [] })
        }
    }

    CUSTOMERS_FILTERS.filters.forEach(filter => {
        //find the section that the filter belongs to
        const section = sections.find(section => section.areaName === filter.area)
        //add filter to the section
        section.areaFilters.push({
            ...filter,
            id: filter.name,
            value: filter.defaultval,
            source: 'filter',
            options: []
        })
    });

    //add custom fields to filters
    if (customFields && customFields.length > 0) {
        const customFieldSection = { areaName: "additional info", areaID: "additionalInfo", areaFilters: [] }
        for (const customField of customFields) {
            const customFieldFilter = {
                name: customField.Name,
                label: customField ? customField.Name.replace(/_/g, ' ') : '',
                type: customField.TypeName,
                value: getInitialValue(customField.TypeName),
                area: 'additionalInfo',
                areaIndex: 3,
                source: 'customField',
                options: customField.Options ? customField.Options : []
            }
            customFieldSection.areaFilters.push(customFieldFilter)
        }
        sections.push(customFieldSection)
    }

    return sections;
};

const flattenFilters = (filtersBySection) => {
    const filters = [];
    if (filtersBySection && filtersBySection.length > 0) {
        filtersBySection.forEach(section => {
            if (section.areaFilters && section.areaFilters.length > 0) {
                section.areaFilters.forEach(filter => {
                    filters.push(filter);
                });
            }
        });
    }
    return filters;
}

const findFilterInFiltersBySection = (filterName, filterList) => {
    //search in all sections for the filter with the given name
    for (const section of filterList) {
        const filter = section.areaFilters.find(f => f.name === filterName)
        if (filter) {
            return filter
        }
    }
    return null
}

const findFeature = (featureID, featuresList) => {
    //search in all sections for the filter with the given name
    return featuresList.find(f => f.ID === featureID)
}

const convertFiltersToParams = (filters, featuresList, currentUserID) => {
    let configToSend = {}
    let flatFilters = []
    if (filters.length > 0) {
        if (filters[0].areaFilters) {
            flatFilters = flattenFilters([...filters])
        } else {
            flatFilters = [...filters]
        }
    }
    for (const filter of flatFilters) {
        switch (filter.source) {
            case 'customField':
                configToSend = {
                    ...configToSend,
                    customFields: {
                        ...configToSend.customFields,
                        [filter.name]: filter.value
                    }
                }
                break;
            case 'feature':
                const feature = findFeature(filter.name, featuresList)
                const FeatID = filter.name
                const FeatType = feature.TypeName
                const currentConfigFeatures = configToSend.Features ? configToSend.Features : []
                const featureFilterObj = {
                    FeatID: FeatID,
                    FeatType: FeatType
                };
                switch (feature.TypeName) {
                    case "Conversion":
                    case "Numeric":
                        featureFilterObj.MinVal = filter.value[0]
                        featureFilterObj.MaxVal = filter.value[1]
                        break;
                    // case "Trans":
                    //     featureFilterObj.Choices = filter.value.map(el => Number(el))    
                    //     break;
                    case "Multichoice":
                        featureFilterObj.Choices = filter.value.choices
                        featureFilterObj.Operator = filter.value.operator || 'and'
                        break;
                    case "Choice":
                        featureFilterObj.Choices = filter.value.length === 0 ? [] : filter.value
                        break;
                    case "Boolean":
                        featureFilterObj.Checked = filter.value ? true : (filter.value === "" ? null : false)
                        break;
                    case "Rating":
                        featureFilterObj.MinVal = filter.value
                        featureFilterObj.MaxVal = 5
                        break;
                    default:
                        featureFilterObj.TextVal = filter.value
                        break;
                }
                currentConfigFeatures.push(featureFilterObj)
                configToSend = {
                    ...configToSend,
                    Features: [...currentConfigFeatures]
                }
                break;
            default:
                //case 'filter':
                if (filter.name.startsWith('Agency')) {
                    if (configToSend.Agency === undefined) {
                        configToSend.Agency = {}
                    }
                    configToSend.Agency = {
                        ...configToSend.Agency,
                        [filter.name]: replacePlaceholder(filter.value)
                    }
                    if (filter.name === 'AgencyID') {
                        configToSend.Agency = {
                            ...configToSend.Agency,
                            AgencyID: filter.value
                        }
                    }
                    if (filter.name === 'AgencyType') {
                        configToSend.Agency = {
                            ...configToSend.Agency,
                            AgencyType: filter.value
                        }
                    }
                    if (filter.name === "AgencyNotIn") {
                        configToSend.Agency = {
                            ...configToSend.Agency,
                            AgencyNotIn: filter.value
                        }
                    }
                } else {
                    configToSend = {
                        ...configToSend,
                        [filter.name]: replacePlaceholder(filter.value, currentUserID)
                    }
                }
                break;
        }
    }
    return configToSend
}

const getSectionCustomFields = (section, globalCustomFields) => {
    const cf = { ...globalCustomFields.items }
    const sectionCustomFields = [];
    if (section.showCustomFields) {
        if (section.customFieldsObject instanceof Array) {
            for (const cfo of section.customFieldsObject) {
                const filteredCustomFields = cf[cfo].filter(item => !item.Disabled)
                for (const filteredCustomField of filteredCustomFields) {
                    if (!sectionCustomFields.find(item => item.Name === filteredCustomField.Name)) {
                        sectionCustomFields.push(filteredCustomField)
                    }
                }
                //sectionCustomFields.push(...filteredCustomFields)
            }
        } else {
            if (section.customFieldsObject && typeof section.customFieldsObject === "string") {
                const filteredCustomFields = cf[section.customFieldsObject].filter(item => !item.Disabled);
                //sectionCustomFields.push(...filteredCustomFields)
                for (const filteredCustomField of filteredCustomFields) {
                    if (!sectionCustomFields.find(item => item.Name === filteredCustomField.Name)) {
                        sectionCustomFields.push(filteredCustomField)
                    }
                }
            }
        }
    }
    return sectionCustomFields;
}

const createCommonFilterList = (filters, currentUser, globalCustomFields) => {
    const iv = {
        ID: Date.now(),
        Name: "My custom list"
    };
    filters.filters.forEach(filter => {
        iv[filter.name] = {
            active: false,
            value: filter.defaultval,
            area: filter.area,
            areaIndex: filter.areaIndex,
            isFeature: false
        };
        if (filter.type === "booker-selector" && filter.defaultval === "{me}") {
            iv[filter.name].value = currentUser.UserID;
        }
    });
    for (const section of filters.sections) {
        if (section.showCustomFields) {
            const sectionCustomFields = getSectionCustomFields(section, globalCustomFields);
            for (const customField of sectionCustomFields) {
                iv[`custom_fields_${customField.Name}`] = {
                    active: false,
                    value: getInitialValue(customField.TypeName),
                    area: section.name,
                    areaIndex: 4
                };
            };
        }
    }
    return iv;
}

const formatCommonFilterValues = (filtersList, filtersSets, currentUser) => {
    return filtersSets.map(filterSet => {
        return {
            ...filterSet,
            filters: filterSet.filters.map(filterInSet => {
                const filterItem = filtersList.filters.find(item => item.name === filterInSet.name);
                if (!filterItem) return filterInSet;
                if (filterItem.type === 'date') {
                    let value = filterInSet.value;
                    if (filterInSet.value && typeof filterInSet.value === "string") {
                        const match = filterInSet.value.match(/\{(\w+)\}/);
                        if (!match) {
                            value = getMoment(filterInSet.value);
                        }
                    }
                    return {
                        ...filterInSet,
                        value: value
                    }
                }
                if (filterItem.type === 'booker-selector') {
                    if (filterInSet.value === '{me}') {
                        return {
                            ...filterInSet,
                            value: currentUser.UserID
                        }
                    }
                }
                return filterInSet;
            })
        }
    });
};

const createStatementFilterList = (currentUser, customFields) => {
    const iv = {
        ID: Date.now(),
        Name: "My custom list"
    };
    STATEMENTS_FILTERS.filters.forEach(filter => {
        iv[filter.name] = {
            active: false,
            value: filter.defaultval,
            area: filter.area,
            isFeature: false
        };
        if (filter.name === "UserID")
            iv[filter.name].value = currentUser.UserID;
    });
    for (const customField of customFields) {
        iv[`custom_fields_${customField.Name}`] = {
            active: false,
            value: getInitialValue(customField.TypeName),
            area: 'content and tags'
        };
    };
    return iv;
};

const mapSetToInitialValues = (initialValues, itemSet) => {
    initialValues.ID = itemSet.id;
    initialValues.Name = itemSet.name;
    itemSet.filters.forEach(filter => {
        if (filter.name === "Features") {
            filter.value.forEach(el => {
                if (initialValues["Features"][`${el.FeatID}`]) {
                    initialValues["Features"][`${el.FeatID}`].active = true;
                    switch (el.FeatType) {
                        case "Conversion":
                            initialValues["Features"][`${el.FeatID}`].value = [
                                el.MinVal,
                                el.MaxVal
                            ];
                            break;
                        case "Numeric":
                            initialValues["Features"][`${el.FeatID}`].value = [
                                el.MinVal,
                                el.MaxVal
                            ];
                            break;
                        case "Multichoice":
                            initialValues["Features"][`${el.FeatID}`].value = {
                                choices: el.Choices,
                                operator: el.Operator
                            };
                            break;
                        case "Trans":
                            initialValues["Features"][`${el.FeatID}`].value = el.Choices;
                            break;
                        case "Choice":
                            initialValues["Features"][`${el.FeatID}`].value = el.Choices;
                            break;
                        case "Boolean":
                            initialValues["Features"][`${el.FeatID}`].value = el.Checked;
                            break;
                        case 'Rating':
                            initialValues["Features"][`${el.FeatID}`].value = el.MinVal;
                            break;
                        default:
                            initialValues["Features"][`${el.FeatID}`].value = el.TextVal;
                            break;
                    }
                }
            }
            );
        } else {
            if (initialValues[`${filter.name}`]) {
                initialValues[`${filter.name}`].active = true;
                initialValues[`${filter.name}`].value = filter.value;
            }
        }
    });
};

const createFilters = obj => {
    const filters = [];
    const features = [];
    for (let [key, value] of Object.entries(obj)) {
        if (key === "Features") {
            for (let [key_f, value_f] of Object.entries(value)) {
                if (value_f.active) {
                    switch (value_f.type) {
                        case "Conversion":
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                MinVal: value_f.value[0],
                                MaxVal: value_f.value[1]
                            });
                            break;
                        case "Trans":
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                Choices: value_f.value.map(el => Number(el))
                            });
                            break;
                        case "Multichoice":
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                Choices: value_f.value.choices,
                                Operator: value_f.value.operator
                            });
                            break;
                        case "Choice":
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                Choices: value_f.value.length === 0 ? [] : value_f.value
                            });
                            break;
                        case "Boolean":
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                Checked: value_f.value
                                    ? true
                                    : value_f.value === ""
                                        ? null
                                        : false
                            });
                            break;
                        case "Rating":
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                MinVal: value_f.value,
                                MaxVal: 5
                            });
                            break;
                        case "Numeric":
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                MinVal: value_f.value[0],
                                MaxVal: value_f.value[1]
                            });
                            break;
                        default:
                            features.push({
                                FeatID: key_f,
                                FeatType: value_f.type,
                                TextVal: value_f.value
                            });
                            break;
                    }
                }
            }
        } else {
            if (value.active) {
                filters.push({ name: key, value: value.value, fieldTypeName: value.fieldTypeName });
            }
        }
    }

    if (features.length > 0) {
        filters.push({ name: "Features", value: features });
    }
    return filters;
};

const formatContactsFilterValues = (filters, currentUserID, contactType) => {
    const FILTERS_LIST = contactType === 'model' ? MODELS_FILTERS : CUSTOMERS_FILTERS;
    return filters.map(subFilter => {
        const filterItem = FILTERS_LIST.filters.find(item => item.name === subFilter.name);
        if (filterItem) {
            if (filterItem.type === 'date') {
                let value = subFilter.value;
                if (subFilter.value && typeof subFilter.value === "string") {
                    const match = subFilter.value.match(/\{(\w+)\}/);
                    if (!match) {
                        value = getMoment(subFilter.value);
                    }
                }
                return {
                    ...subFilter,
                    value: value
                }
            }
            if (filterItem.type === 'booker-selector') {
                if (subFilter.value === '{me}') {
                    return {
                        ...subFilter,
                        value: currentUserID
                    }
                }
            }
        }
        return subFilter;
    })
};

const formatModelsFilterValues = (filters, currentUserID) => {
    return filters.map(filter => {
        return {
            ...filter,
            filters: filter.filters.map(subFilter => {
                const filterItem = MODELS_FILTERS.filters.find(item => item.name === subFilter.name);
                if (filterItem) {
                    if (filterItem.type === 'date') {
                        let value = subFilter.value;
                        if (subFilter.value && typeof subFilter.value === "string") {
                            const match = subFilter.value.match(/\{(\w+)\}/);
                            if (!match) {
                                value = getMoment(subFilter.value);
                            }
                        }
                        return {
                            ...subFilter,
                            value: value
                        }
                    }
                    if (filterItem.type === 'booker-selector') {
                        if (subFilter.value === '{me}') {
                            return {
                                ...subFilter,
                                value: currentUserID
                            }
                        }
                    }
                }
                return subFilter;
            })
        }
    });
};

const formatCustomersFilterValues = (filters, currentUser) => {
    return filters.map(filter => {
        return {
            ...filter,
            filters: filter.filters.map(subFilter => {
                const filterItem = CUSTOMERS_FILTERS.filters.find(item => item.name === subFilter.name);
                if (filterItem) {
                    if (filterItem.type === 'date') {
                        let value = subFilter.value;
                        if (subFilter.value && typeof subFilter.value === "string") {
                            const match = subFilter.value.match(/\{(\w+)\}/);
                            if (!match) {
                                value = getMoment(subFilter.value);
                            }
                        }
                        return {
                            ...subFilter,
                            value: value
                        }
                    }
                    if (filterItem.type === 'booker-selector') {
                        if (subFilter.value === '{me}') {
                            return {
                                ...subFilter,
                                value: currentUser.UserID
                            }
                        }
                    }
                }
                return subFilter;
            })
        }
    });
};

const formatStatementFilterValues = (filters, currentUser) => {
    return filters.map(filter => {
        return {
            ...filter,
            filters: filter.filters.map(subFilter => {
                const filterItem = STATEMENTS_FILTERS.filters.find(item => item.name === subFilter.name);
                if (!filterItem) return subFilter;
                if (filterItem.type === 'date') {
                    let value = subFilter.value;
                    if (subFilter.value && typeof subFilter.value === "string") {
                        const match = subFilter.value.match(/\{(\w+)\}/);
                        if (!match) {
                            value = getMoment(subFilter.value);
                        }
                    }
                    return {
                        ...subFilter,
                        value: value
                    }
                }
                if (filterItem.type === 'booker-selector') {
                    if (subFilter.value === '{me}') {
                        return {
                            ...subFilter,
                            value: currentUser.UserID
                        }
                    }
                }
                return subFilter;
            })
        }
    });
};

export {
    replacePlaceholder,
    createFilters,
    createModelFilterList,
    createCustomerFilterList,
    flattenFilters,
    findFilterInFiltersBySection,
    findFeature,
    convertFiltersToParams,
    formatContactsFilterValues,
    //formatContactsFiltersSetValues,
    formatModelsFilterValues,
    formatCustomersFilterValues,
    getSectionCustomFields,
    createCommonFilterList,
    formatCommonFilterValues,
    mapSetToInitialValues,
    formatStatementFilterValues,
    createStatementFilterList,
}