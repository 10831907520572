import React from "react";
import { getMoment } from "Libs/NwMoment";
import { toISODate } from "Libs/NwMoment";
import NumericRangeSelector from "Components/Gui/NwForm/NumericRangeSelector";
import BooleanSelector from "Components/Gui/NwForm/BooleanSelector";
import CustomSelect from "Components/Gui/NwForm/CustomSelect";
import { DatePicker, Input } from "antd";
import { findFilterInFiltersBySection } from "../../Filters/FilterForm/FilterUtils";
import styled from "styled-components";
import FilterClearButton from "./FilterClearButton";
import { useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/SelectorContext";

const FieldWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
`

const convertToMoment = (value) => {
    if (Array.isArray(value)) {
        const newarray = value.map(v => getMoment(v))
        return newarray
    }
    return value
}

const convertToDates = (value) => {
    if (Array.isArray(value)) {
        const newarray = value.map(v => toISODate(v))
        return newarray
    }
    return value
}

const getFromArray = (array) => {
    if (Array.isArray(array)) {
        return array[0]
    }
    return array
}

const singleValueArray = (value) => {
    return [value]
}

const { RangePicker } = DatePicker;

const ContactsFilterItemCustomField = ({ filter }) => {

    const { selection, global } = useContactsSelector()
    const cs_dispatch = useContactsSelectorDispatch()
    const { filters } = selection
    const { filtersBySection } = global

    let filterValue = filter.defaultval

    const workingFilter = filters.working.find(f => f.name === filter.name)
    if (workingFilter) {
        filterValue = workingFilter.value
    }

    const filterData = findFilterInFiltersBySection(filter.name, filtersBySection)

    let parsedValue = filterValue

    switch (filter.type) {
        case 'Date':
            parsedValue = filterValue ? (Array.isArray(filterValue) ? filterValue : [filterValue]) : [];
            break;
        case 'Choice':
        case 'MultipleChoice':
            parsedValue = filterValue ? (Array.isArray(filterValue) ? filterValue : [filterValue]) : [];
            break;
        case 'Numeric':
            parsedValue = filterValue ? (Array.isArray(filterValue) ? filterValue : [filterValue, filterValue]) : [0, 0];
            break;
        default:
            parsedValue = getFromArray(filterValue)
            break;
    }

    const onValueChange = (val) => {
        let newWorkingFilters = [...filters.working]
        const filterIndex = newWorkingFilters.findIndex(f => f.name === filter.name)
        if (val) {
            if (Array.isArray(val)) {
                if (filter.type === "Numeric" && val.length === 2 && (val[0] === 0 && val[1] === 0)) {
                    if (filterIndex !== -1) {
                        newWorkingFilters.splice(filterIndex, 1)
                    }
                } else {
                    if (val.length > 0) {
                        if (filterIndex === -1) {
                            newWorkingFilters.push({ name: filter.name, value: val, source: 'customField' })
                        } else {
                            newWorkingFilters[filterIndex].value = val
                        }
                    } else {
                        if (filterIndex !== -1) {
                            newWorkingFilters.splice(filterIndex, 1)
                        }
                    }
                }
            } else {
                const valueAsArray = singleValueArray(val)
                if (filterIndex === -1) {
                    newWorkingFilters.push({ name: filter.name, value: valueAsArray, source: 'customField' })
                } else {
                    newWorkingFilters[filterIndex].value = valueAsArray
                }
            }
        } else {
            if (filterIndex !== -1) {
                newWorkingFilters.splice(filterIndex, 1)
            }
        }
        //updateWorkingFilters(newWorkingFilters)
        cs_dispatch({ type: 'ChangeFilters', filters: newWorkingFilters })
    }

    const onClearField = () => {
        switch (filter.type) {
            case 'Boolean':
                onValueChange(null)
                break;
            case 'Numeric':
                onValueChange([0, 0])
                break;
            case 'Date':
                onValueChange([])
                break;
            case 'Choice':
            case 'MultipleChoice':
                onValueChange([])
                break;
            default:
                onValueChange('')
                break;
        }
        let newWorkingFilters = [...filters.working]
        const filterIndex = newWorkingFilters.findIndex(f => f.name === filter.name)
        if (filterIndex !== -1) {
            newWorkingFilters.splice(filterIndex, 1)
            //updateWorkingFilters(newWorkingFilters)
            cs_dispatch({ type: 'ChangeFilters', filters: newWorkingFilters })
        }
    }

    const renderComponent = () => {
        switch (filter.type) {
            case 'String':
            case 'MultipleLineString':
                return (
                    <FieldWrapper>
                        <Input
                            type='text'
                            value={parsedValue}
                            onChange={event => {
                                onValueChange(event.target.value)
                            }}
                        />
                        <FilterClearButton onClick={onClearField} />
                    </FieldWrapper>
                )
            case 'Choice':
                return (
                    <FieldWrapper>
                        <CustomSelect
                            mode='multiple'
                            value={parsedValue}
                            options={filterData.options.map(option => {
                                return { key: option, label: option, value: option };
                            })}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            onChange={onValueChange}
                        />
                        <FilterClearButton onClick={onClearField} />
                    </FieldWrapper>
                )
            case 'MultipleChoice':
                return (
                    <FieldWrapper>
                        <CustomSelect
                            value={parsedValue}
                            mode='multiple'
                            options={filterData.options.map(option => {
                                return { key: option, label: option, value: option };
                            })}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            onChange={onValueChange}
                        />
                        <FilterClearButton onClick={onClearField} />
                    </FieldWrapper>
                )
            case 'Boolean':
                return (
                    <FieldWrapper>
                        <BooleanSelector
                            emptyValue
                            value={getFromArray(parsedValue)}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            onChange={value => {
                                onValueChange(singleValueArray(value))
                            }}
                        />
                        <FilterClearButton onClick={onClearField} />
                    </FieldWrapper>
                )
            case 'Numeric':
                return (
                    <FieldWrapper>
                        <NumericRangeSelector
                            value={parsedValue}
                            onChange={value => {
                                onValueChange(value)
                            }}
                        />
                        <FilterClearButton onClick={onClearField} />
                    </FieldWrapper>
                )
            case 'Date':
                return (
                    <FieldWrapper>
                        <RangePicker
                            value={convertToMoment(parsedValue)}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            onChange={value => {
                                onValueChange(convertToDates(value))
                            }}
                        />
                        <FilterClearButton onClick={onClearField} />
                    </FieldWrapper>
                )
            default:
                return (<div />)
        }
    }
    return (
        <>
            {renderComponent()}
        </>
    );
};

export default ContactsFilterItemCustomField;