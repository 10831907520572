import React from 'react';
import styled from 'styled-components';
import { List, Divider } from 'antd';
import _ from "lodash";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import AvatarBg from 'Components/Gui/AvatarBg';
import { getBookCover, findPath } from 'Libs/NwUtils';
import CreatedBy from 'Components/CreatedBy/CreatedBy';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import AddBookToPackageButton from 'Components/ActivePackages/AddBookToPackageButton';
import NwIcon from 'Components/Gui/NwIcon';
import { useUserPermission } from 'Contexts/UserContext';
import StyleVariables from 'Components/Gui/StyleVariables.js';
import PrintPlaylistButton from 'Components/Playlists/PrintPlaylistButton';
import { useState } from 'react';
import MiniLoading from 'Components/Gui/MiniLoading';

const BookItemWrapper = styled.div`
    padding: .5rem 1rem;
    border-bottom: ${props => props.theme.DefaultBorder};

    &:hover {
        background-color: ${props => props.theme.SelectedBackgroundColor};
    }
    cursor: pointer;



    .book-item-content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: .25rem;

        .book-item-content-left {
        }
        .book-item-content-right {
            flex: 1;

            .book-title {
                display: flex;
                flex-direction: row;
                margin-right: auto;
                align-items: center;
                gap: .5rem;
                
                .badge {
                    width: .5rem;
                    height: .5rem;
                    min-width: 0.5rem;
                    min-height: 0.5rem;
                    background: ${StyleVariables.DangerColor};
                    border-radius: 100%;
                }
                .book-name {
                    font-weight: ${props => props.$fastpackage ? "700" : "500"};
                    font-size: .85rem;
                    color: ${props => props.$exist ? props.theme.PrimaryColor : props.theme.TextColor};
                }
            }

            .book-author {
                font-size: 0.6rem;
                text-transform: uppercase;
                margin: .25rem 0;
                display: flex;
                align-items: center;
                color: ${props => props.$isPrivate ? props.theme.DangerColor : props.theme.TextColorMuted};

                svg {
                    margin-right: .25rem;
                }
            }

            .book-features {
                display: flex;
                flex-direction: column;
                gap: .25rem;

                .book-last-line {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    small.fast-package {
                        color: ${props => props.theme.TextColor};
                    }
                }
            }
        }
    }

    .actions-container {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        

        .icons-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 2rem;
        }
    }
`;

const AvatarLoadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 80px;
    height: 80px;
    font-size: 2rem;
`

const BookItemMobile = ({
    addedPlaylists,
    book,
    //onAddBook,
    onChangeFastPackageStatus,
    onChangeCompCardStatus,
    //onDeleteBook,
    //onEditBook,
    //onRemoveBook,
    //readOnly,
    //showBookInfo,
    showBookPreview,
    showPublishBook,
    websiteData,
}) => {

    const [isAdding, setIsAdding] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const userPermission = useUserPermission('DeleteBooks', [book.UserCreated]);
    
    // let hasCover = false
    // if (book.Covers && book.Covers.length > 0) {
    //     const standardcover = _.find(book.Covers, { CoverFormat: "Standard" });
    //     if (standardcover) {
    //         hasCover = true;
    //     }
    // }

    const Actions = () => {
        const actions = [
            { name: "", key: "preview", pinned: true, tooltip: "view book", icon: light("images-user"), action: (() => showBookPreview(book)) },
            // { name: "", key: "info", pinned: true, tooltip: "info", icon: light("circle-info"), action: (() => showBookInfo(book)) },
            { name: "", pinned: true, key: "publish", tooltip: "publish on website", icon: light("globe"), action: (() => showPublishBook(book)) },
            // { name: "delete", type: "delete", key: "delete", tooltip: "delete book", icon: light("trash-alt"), confirmationPlacement: "left", disabled: !userPermission, action: (() => onDeleteBook(book)) }
        ]

        let fpaction = null;
        let compaction = null;
        if (book.FastPackage) {
            fpaction = { name: "remove as fast package book", key: "fast", tooltip: "fast package", icon: light("truck-fast"), action: (() => onChangeFastPackageStatus(book, false)) }
        } else {
            fpaction = { name: "set as fast package book", key: "fast", tooltip: "fast package", icon: light("truck-fast"), action: (() => onChangeFastPackageStatus(book, true)) }
        }
        if (book.CompCard) {
            compaction = { name: "set as not compcard", key: "comp", tooltip: "not compcard", icon: light("image-polaroid-user"), action: (() => onChangeCompCardStatus(book, false)) }
        } else {
            compaction = { name: "set as compcard", key: "comp", tooltip: "compcard", icon: light("image-polaroid-user"), action: (() => onChangeCompCardStatus(book, true)) }
        }
        actions.splice(2, 0, fpaction, compaction);

        return (
            <div className="actions-container">
                <NwDropDownMenu
                    actions={actions}
                />
                <div className="icons-container">
                    <PrintPlaylistButton
                        playlistID={book.ID}
                        placement="leftTop"
                    />
                    <AddBookToPackageButton
                        activePackageOnly
                        book={book}
                        placement="leftTop"
                    />
                </div>
            </div>
        )
    }

    const getLinkedWebsites = (book) => {
        const linkedSections = [];
        if (websiteData && websiteData.length && book.Divisions) {
            book.Divisions.map((item) => {
                linkedSections.push(findPath(websiteData, item.WebsiteCode, item.DivisionCode, ""));
                return null;
            })
        }
        return [...linkedSections];
    }

    const linkedSections = getLinkedWebsites(book)
    const exist = addedPlaylists ? addedPlaylists.find(playlistId => playlistId === book.ID) : false;

    const handleBookClick = () => {
        showBookPreview(book)
    }

    return (
        <BookItemWrapper
            $fastpackage={book.FastPackage}
            $exist={exist}
            $isPrivate={book.PrivacyLevelName === "User"}
        >
            <div className="book-item-content" onClick={handleBookClick}>
                <div className="book-item-content-left">
                    {(isAdding || isRemoving)
                        ?
                        <AvatarLoadingContainer><MiniLoading /></AvatarLoadingContainer>
                        :
                        <AvatarBg size={80} src={getBookCover(book)} />
                    }
                </div>
                <div className="book-item-content-right">
                    <div className="book-title">
                        {(linkedSections.length > 0) &&
                            <div className="badge" />
                        }
                        {book.CompCard &&
                            <NwIcon icon={light("image-polaroid-user")} />
                        }
                        <div className="book-name">{book.Name}</div>
                    </div>
                    {isAdding &&
                        <small>adding book to package...</small>
                    }
                    {isRemoving &&
                        <small>removing book from package...</small>
                    }
                    {!isAdding && !isRemoving &&
                        <div className="book-features">
                            <div className="book-author">
                                {book.PrivacyLevelName === "User" &&
                                    <NwIcon icon={light("lock")} />
                                }
                                <CreatedBy object={book} short textonly />
                            </div>
                            {linkedSections.length
                                ?
                                <small>
                                    <NwIcon icon={light("globe")} /> published {linkedSections.length} in website{linkedSections.length > 1 ? "s" : ""}
                                </small>
                                :
                                <small>
                                    <NwIcon icon={light("globe")} /> not published
                                </small>
                            }
                            <div className="book-last-line">
                                {book.FastPackage &&
                                    <>
                                        <small className="fast-package">
                                            <NwIcon icon={light("truck-fast")} /> fast package
                                        </small>
                                        <Divider type="vertical" />
                                    </>
                                }
                                {(book.Layout && book.Layout === "Sequence")
                                    ?
                                    <small>
                                        <NwIcon icon={light("file-user")} /> 1 pic x page
                                    </small>
                                    :
                                    <small>
                                        <NwIcon icon={light("book-open")} /> 2 pics x page
                                    </small>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Actions />
        </BookItemWrapper>
    )
}

export default BookItemMobile;
