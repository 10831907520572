import React, { useEffect } from 'react';
import { DatePicker, Row, Col } from 'antd';
import styled from 'styled-components';
import { Field } from "formik";

import { NWInput, NWSignatureSelector, NWTextAreaCharCounter } from 'Components/Gui/NwForm/NwFormItems';
import CustomSwitch from 'Components/Gui/CustomSwitch';
import FlexContainer from 'Components/Gui/FlexContainer';
import { getMoment } from "Libs/NwMoment";
import { toISODate } from 'Libs/NwMoment';

const { RangePicker } = DatePicker;

const StyledRangePicker = styled(RangePicker)`
    margin: auto 0;
    width: 100%;
`;

const Wrapper = styled.div`
    .ant-form-item {
        margin-bottom: 2rem;
    }
    .ant-picker {
        max-width: 20rem;
    }
    div.line-container {
        margin-bottom: .75rem;
        p.line-label {
            margin: 0;
            font-size: ${props => props.theme.FontSizeSm};
            color: ${props => props.theme.TextColorMuted};
        }
        div.line-field {
            .ant-form-item {
                margin-bottom: 0;
            }
        }
    }
`;

const SendAgendaDrawerForm = ({
    modelId,
    values,
    filters,
    title,
    onUpdateFilters,
    setFieldValue,
    acknowledged,
    onChangeAcknowledged,
    onSelectSignature,
    twoColumns
}) => {

    const handleChangePeriod = period => {
        onUpdateFilters({
            ...filters,
            FDate: toISODate(period[0]),
            TDate: toISODate(period[1])
        })
    };

    useEffect(() => {
        setFieldValue('Subject', title);
    }, [title])

    // const FormRow = ({ label, children }) => {
    //     if (!twoColumns) {
    //         return (
    //             <Row>
    //                 <Col span={8}>{label}</Col>
    //                 <Col span={16}>{children}</Col>
    //             </Row>
    //         );
    //     } else {
    //         return (
    //             <div className="line-container">
    //                 <p className="line-label">{label}</p>
    //                 <div className="line-field">{children}</div>
    //             </div>
    //         );
    //     }
    // }

    if (twoColumns) {
        return (
            <Wrapper>
                <div className="line-container">
                    <p className="line-label">PERIOD</p>
                    <div className="line-field">
                        <p><StyledRangePicker autoComplete='off' format="D MMM YYYY" value={[getMoment(filters.FDate), getMoment(filters.TDate)]} onChange={handleChangePeriod} /></p>
                        <p><CustomSwitch checked={acknowledged} onChange={onChangeAcknowledged} text='Set events as acknowledged' /></p>
                    </div>
                </div>
                <div className="line-container">
                    <p className="line-label">E-MAIL SUBJECT</p>
                    <div className="line-field">
                        <Field
                            component={NWInput}
                            name="Subject"
                            type="text"
                            placeholder='Subject'
                            id='Subject'
                            value={values.Subject}
                        />
                    </div>
                </div>
                <div className="line-container">
                    <p className="line-label">MESSAGE</p>
                    <div className="line-field">
                        <Field
                            component={NWTextAreaCharCounter}
                            name="Description"
                            type="text"
                            placeholder='Description'
                            value={values.Description}
                            autoSize={{ minRows: 4, maxRows: 6 }}
                        />
                    </div>
                </div>
                <div className="line-container">
                    <p className="line-label">SIGNATURE</p>
                    <div className="line-field">
                        <Field
                            component={NWSignatureSelector}
                            name="SignatureID"
                            value={values.SignatureID}
                            onSelectSignature={onSelectSignature}
                        />
                    </div>
                </div>
            </Wrapper>
        )
    } else {
        return (
            <Wrapper>
                <Col span={8}>
                    PERIOD
                </Col>
                <Col span={16}>
                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
                        <StyledRangePicker autoComplete='off' format="D MMM YYYY" value={[getMoment(filters.FDate), getMoment(filters.TDate)]} onChange={handleChangePeriod} />
                        <FlexContainer mg='0 0 0 10px' style={{ whiteSpace: "nowrap" }}>
                            <CustomSwitch checked={acknowledged} onChange={onChangeAcknowledged} text='Set events as acknowledged' />
                        </FlexContainer>
                    </div>
                </Col>
                <Col span={8}>
                    E-MAIL SUBJECT
                </Col>
                <Col span={16}>
                    <Field
                        component={NWInput}
                        name="Subject"
                        type="text"
                        placeholder='Subject'
                        id='Subject'
                        value={values.Subject}
                    />
                </Col>
                <Col span={8}>
                    MESSAGE
                </Col>
                <Col span={16}>
                    <Field
                        component={NWTextAreaCharCounter}
                        name="Description"
                        type="text"
                        placeholder='Description'
                        value={values.Description}
                        autoSize={{ minRows: 3, maxRows: 3 }}
                    />
                </Col>
                <Col span={8}>
                    SIGNATURE
                </Col>
                <Col span={16}>
                    <Field
                        component={NWSignatureSelector}
                        name="SignatureID"
                        value={values.SignatureID}
                        onSelectSignature={onSelectSignature}
                    />
                </Col>
            </Wrapper>
        )
    }
};

export default SendAgendaDrawerForm;