export const CUSTOMERS_FILTERS_SET = { //was CUSTOMER_FILTER_SET
    customSets: ([]),
    staticSets: [
        {
            id: "all",
            name: "all customers",
            fixed: true,
            filters: []
        },
        {
            id: "workingtoday",
            name: "customers working today",
            fixed: true,
            filters: [
                { name: "WorkingOn", value: "{today_today}"}
            ]
        }
    ]
};

// export const CUSTOMERS_FILTERS_SET = {
//     booking: CUSTOMER_FILTER_SET,
//     scouting: CUSTOMER_FILTER_SET,
//     accounting: CUSTOMER_FILTER_SET,
//     management: CUSTOMER_FILTER_SET
// };