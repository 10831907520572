import React, { useContext, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Popover, Tag } from 'antd';
import { ListContext } from 'Contexts/ListContext';
import ContactUsersDrawer from 'Components/ContactUsersDrawer/ContactUsersDrawer';
import StyleVariables from 'Components/Gui/StyleVariables';
import NwIcon from 'Components/Gui/NwIcon';
// import { useUserPermission } from 'Contexts/UserContext';
// import { useContact } from 'Hooks/Contact/UseContact';
// import { useModelsList, useCustomersList } from 'Hooks/Contact/UseContactsList';
import PermissionBlock from 'Components/PermissionBlock/PermissionBlock';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwDividerVertical from 'Components/Gui/NwDividerVertical';

const ContactUsersLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;

    .icon-container {
        width: .75rem;
        text-align: center;
    }

    p {
        margin: 0;
    }
`

const ContactUserPopoverContent = styled.div`
    em {
        font-style: normal;
        text-transform: uppercase;
        font-size: .8rem;
    }
`

const ContactUsers = ({ users, readOnly, viewMode, contactID }) => {

    const { cachedList, GetUserName } = useContext(ListContext);
    const [showContactUsersDrawer, setShowContactUsersDrawer] = useState(false);
    
    // const { data: contact } = useContact(readOnly ? null : contactID);
    // const { data: fullModelList } = useModelsList(); 
    // const { data: fullCustomerList } = useCustomersList(); 
    // let simpleContact = fullModelList && fullModelList.find(model => model.ID === contactID);
    // if (!simpleContact) {
    //     simpleContact = fullCustomerList && fullCustomerList.find(customer => customer.ID === contactID);
    // }
    // const viewContactUsers = useUserPermission('ViewContactUsers', readOnly ? 
    //     simpleContact && simpleContact.U.map(user => user.UserID)
    //     : contact && contact.Users.map(user => user.UserID));
    
    const viewContactUsers = true

    const GetAgencyDepartment = (NetworkAgencyID, DepartmentID) => {
        if (!NetworkAgencyID) {
            return null;
        }
        let agencies = [];
        cachedList.accountingGroups.items.map(data => {
            agencies = [...agencies, ...data.NetworkAgencies]
            return null;
        });
        const networkAgency = agencies.find(agency => agency.ID === NetworkAgencyID);
        if (!networkAgency) {
            return null;
        }
        const department = networkAgency.Departments.find(department => department.ID === DepartmentID);
        if (!department) {
            return networkAgency.Name;
        } else  {
            return `${networkAgency.Name} / ${department.Name}`
        }
    }

    const handleOpenContactUsersDrawer = event => {
        setShowContactUsersDrawer(true);
        event.stopPropagation();
    };

    const handleCloseContactUsersDrawer = () => {
        setShowContactUsersDrawer(false);
    };

    const displayLabels = useMemo(() => {
        return users.length === 1 ? GetUserName(users[0].UserID) : `${users.length} agents`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);

    return (
        viewContactUsers ? 
            <>
                {users.length === 0 ?
                    !readOnly && <p onClick={handleOpenContactUsersDrawer}><NwIcon icon={light("user-circle")} danger /> no bookers</p>
                    :
                    <Popover
                        title='Bookers / Scouters'
                        mouseEnterDelay={.6}
                        content={
                            <>
                                {users.map((contactUser, index) => (
                                    <ContactUserPopoverContent key={`${contactUser.UserID}-${index}`}>
                                        <Tag color={StyleVariables.PrimaryColor}>
                                            {contactUser.NetworkAgencyID ? GetAgencyDepartment(contactUser.NetworkAgencyID, contactUser.DepartmentID) : "Global"}
                                        </Tag>
                                        <strong>{GetUserName(contactUser.UserID)}</strong>
                                        <NwDividerVertical /> 
                                        <em>{contactUser.RoleName}</em>
                                    </ContactUserPopoverContent>
                                ))}
                            </>
                        }
                    > 
                        <ContactUsersLine onClick={handleOpenContactUsersDrawer}>
                            <div className="icon-container">
                                <NwIcon icon={light("user-circle")} />
                            </div>
                            <p>{displayLabels}</p>
                        </ContactUsersLine>
                    </Popover>
                }
                {showContactUsersDrawer && !readOnly && <ContactUsersDrawer contactID={contactID} disable={viewMode} onClose={handleCloseContactUsersDrawer} />}
            </>
        : 
        <PermissionBlock
            permission={viewContactUsers}
            content={
                <>
                    {users.length === 0 ?
                        !readOnly && 
                        <ContactUsersLine onClick={handleOpenContactUsersDrawer}>
                            <div className="icon-container">
                                <NwIcon icon={light("user-circle")} danger />
                            </div>
                            <p>no bookers</p>
                        </ContactUsersLine>
                        
                        :
                        <ContactUsersLine onClick={handleOpenContactUsersDrawer}>
                            <div className="icon-container">
                                <NwIcon icon={light("user-circle")} danger />
                            </div>
                            <p>{displayLabels}</p>
                        </ContactUsersLine>
                    }
                </>
            } />
    );
};

export default ContactUsers;