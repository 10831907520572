import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Field } from "formik";
import { Alert, Modal } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { showDateNoTime } from 'Libs/NwMoment';
import {
    NWInput,
    NWSwitch,
    NWTextAreaCharCounter,
} from "Components/Gui/NwForm/NwFormItems";
import NwDrawer from 'Components/Gui/NwDrawer';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import { forceReloadTransaction } from 'Hooks/Transaction/UseTransaction';
import { Loading } from 'Components/Gui/Loading';
import TransactionTitle from 'Components/Transactions/TransactionTitle';
import TransactionEvent from 'Components/Transactions/TransactionEvent';
import Axios from 'axios';
import TransactionStatusBreadcrumbs from 'Components/Transactions/TransactionStatusBreadcrumbs';
import { prepareTransactionData } from 'Components/Project/ProjectTransactions/Transactions/TransactionCreateFormUtils';
import { isLocked } from 'Components/Transactions/TransactionsUtils';

const Container = styled.div`
    padding: 8px 0;
    //border-bottom: 1px solid ${props => props.theme.LighterBorderColor};

    .transaction-details {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 1rem;

        .transaction-details-left {
            flex: 1 1 0;

            .transaction-title {
                margin-bottom: .5rem;
            }
            p {
                margin-bottom: .5rem;
            }
        }
        .transaction-details-right {
            flex: 0 0 4rem;
        }
    }
`;


const TransactionItemDetailForm = ({ transaction, onUpdateTransaction, onTransactionRemoved }) => {
    const [editable, setEditable] = useState(false);
    const [touchForm, setTouchForm] = useState(false);

    const submitForm = async (values, setStatus, setSubmitting) => {
        setSubmitting(true);
        let data = {
            transactionId: transaction.ID,
            title: values.Title,
            description: values.Description,
            updateStatements: values.UpdateStatements
        };

        try {
            await Axios.put(`/transactions/${transaction.Family}`, data)
            forceReloadTransaction(transaction.ID);
            onUpdateTransaction();
            setTouchForm(false);
            setSubmitting(false);
            setEditable(false);
        } catch (error) {
            //TODO: manage error on api call, may be by adding an ErrorMessage to the state and a condition to show it in render.
            setSubmitting(false);
            console.log("errore", error);
        }
    };

    const handleDeleteTransaction = async () => {
        try {
            await Axios.delete(`/transactions/${transaction.Family}/${transaction.ID}`)
            onTransactionRemoved();
        } catch (error) {
            Modal.error({
                title: error.response.data.Message
            });
            console.log('ant : Delete transaction error => ', error);
        }
    };

    const descriptions = []
    if (transaction) {
        descriptions.push(transaction.Description)
        transaction.ClientStatements.forEach(statement => {
            if (statement.Description) {
                descriptions.push(statement.Description)
            }
        })
        transaction.ModelStatements.forEach(statement => {
            if (statement.Description) {
                descriptions.push(statement.Description)
            }
        })
        transaction.AgencyStatements.forEach(statement => {
            if (statement.Description) {
                descriptions.push(statement.Description)
            }
        })
        transaction.ServiceStatements.forEach(statement => {
            if (statement.Description) {
                descriptions.push(statement.Description)
            }
        })
    }
    const uniquedescriptions = [...new Set(descriptions)]
    
    let initialValues = {
        Title: transaction ? transaction.Title : '',
        Label: transaction ? transaction.Label : '',
        Description: transaction ? transaction.Description : '',
        WorkDate: transaction ? transaction.WorkDate : '',
        UpdateStatements: (uniquedescriptions.length <= 1 ? true : false),
    };

    const getTransactionStatus = () => {
        if (transaction.ClientStatements.length > 0) {
            const mainLine = transaction.ClientStatements.find(statement => !statement.isCommission)
            if (mainLine)  {
                return mainLine.Status
            }
            return transaction.ClientStatements[0].Status
        }
        if (transaction.ModelStatements.length > 0) {
            const mainLine = transaction.ModelStatements.find(statement => !statement.isCommission)
            if (mainLine)  {
                return mainLine.Status
            }
            return transaction.ModelStatements[0].Status
        }
        if (transaction.AgencyStatements.length > 0) {
            return transaction.AgencyStatements[0].Status
        }
        if (transaction.ServiceStatements.length > 0) {
            return transaction.ServiceStatements[0].Status
        }
        return 'Billable'
    }

    const transactionLockStatus = isLocked(transaction)

    return (
        transaction
            ?
            <Container>
                <TransactionEvent transaction={transaction} />
                <div className="transaction-details">
                    <div className="transaction-details-left">
                        <TransactionTitle style={{marginTop: ".5rem"}} transaction={transaction} />
                        <p>{transaction.Description}</p>
                        <p><strong>{transaction.WorkDate ? `work date: ${showDateNoTime(transaction.WorkDate)} ` : ''}</strong></p>
                    </div>
                    <div className="transaction-details-right">
                        <NwDropDownMenu
                            size='small'
                            actions={[
                                { name: "edit", key: "edit", pinned: true, icon: light("pen"), action: () => setEditable(true) },
                                { name: "delete", pinned: true, disabled: transactionLockStatus.locked, type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: handleDeleteTransaction }
                            ]}
                        />
                    </div>
                </div>
                {transaction.ClientStatements.length > 0 &&
                <TransactionStatusBreadcrumbs onStatusChange={onUpdateTransaction} status={getTransactionStatus()} transactionFamily={transaction.Family} transactionID={transaction.ID} />
                }
                {editable &&
                    <NwDrawer
                        touchForm={touchForm}
                        onTouchForm={setTouchForm}
                        title='Edit Transaction'
                        onClose={() => {
                            setEditable(false);
                        }}>
                        {/* {isLocked
                        ?
                        <Alert message="This transaction is locked (as it's been imported from Netwalk 3 and included in a payment) and cannot be edited." type="warning" showIcon />
                        : */}
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                setStatus();
                                submitForm(values, setStatus, setSubmitting);
                            }}
                        >
                        {({ isSubmitting, values, handleSubmit }) => {
                                return (
                                    <NwForm
                                        values={values}
                                        onTouchForm={setTouchForm}
                                        onFinish={handleSubmit}
                                        layout="vertical">
                                        <Field
                                            component={NWInput}
                                            label="Title"
                                            name="Title"
                                            value={values.Title}
                                            required
                                            type="text"
                                        />
                                        <Field
                                            component={NWTextAreaCharCounter}
                                            label="Description"
                                            name="Description"
                                            type="text"
                                            value={values.Description}
                                            maxLength={2000}
                                            autoSize={{ minRows: 3, maxRows: 5 }}
                                        />
                                        <Field
                                            component={NWSwitch}
                                            label=""
                                            text="Update description on all statement lines"
                                            name="UpdateStatements"
                                            checked={Boolean(values.UpdateStatements)}                                            
                                        />
                                        <NwFormButtonsBar
                                            left={
                                                <NwCancelButton
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        setTouchForm(false);
                                                        setEditable(false);
                                                    }}
                                                />
                                            }
                                            right={
                                                <NwSaveButton
                                                    htmlType="submit"
                                                    disabled={isSubmitting}
                                                    loading={isSubmitting}
                                                />
                                            }
                                        />
                                    </NwForm>
                                )
                            }}
                        </Formik>
                        {/* } */}
                    </NwDrawer>
                }
            </Container>
            :
            <Loading />
    );
};

export default TransactionItemDetailForm; 