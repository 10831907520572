import React, { useContext } from 'react';
import { Formik, Field } from "formik";
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NWInput, NWPrivacyLevelSelector } from "Components/Gui/NwForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { useEditPlaylist } from 'Hooks/Contact/UseContactPlaylists';
import { UserContext } from 'Contexts/UserContext';
import CustomSwitch from 'Components/Gui/CustomSwitch';

const PlayListInfoForm = ({ playlist, onUpdatePlaylists, onTouchForm, onClose }) => {

    const { currentUser } = useContext(UserContext);

    const { mutateAsync: editPlaylist } = useEditPlaylist();
    const initialValues = {
        Name: '',
        PrivacyLevel: 'Public',
        Published: false
    };

    if (playlist) {
        initialValues.Name = playlist.Name;
        initialValues.Published = playlist.IsPublished;
        initialValues.PrivacyLevel = playlist.PrivacyLevelName;
    }

    const bookIsMine = () => {
        if (!playlist || (playlist.UserCreated === currentUser.UserID)) {
            return true
        }
        return false
    }

    const handleSavePlayList = async (values, setSubmitting) => {
        setSubmitting(true);

        const updatedPlaylist = {
            ID: playlist.ID,
            Name: values.Name,
            PrivacyLevel: values.PrivacyLevel,
            IsPublished: initialValues.Published,
            Type: 'Book'
        };

        if (playlist) {
            updatedPlaylist.ContactID = playlist.ContactID;
        } else {
            updatedPlaylist.CompCard = values.CompCard;
        }
        try {
            await editPlaylist({ playlist: updatedPlaylist, contactID: playlist && playlist.ContactID });
            onTouchForm(false);
            onClose();
            if (onUpdatePlaylists) {
                onUpdatePlaylists();
            }
            setSubmitting(false);
        } catch (error) {
            console.log('ant : error => ', error);
            setSubmitting(false);
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                handleSavePlayList(values, setSubmitting);
            }}
        >
            {({ isSubmitting, values, handleSubmit }) => (
                <NwForm
                    values={values}
                    onTouchForm={onTouchForm}
                    onFinish={handleSubmit}
                    layout="vertical">
                    <Field
                        component={NWInput}
                        label="Name"
                        name="Name"
                        type="text"
                        value={values.Name}
                    />
                    <br /><br />
                    {bookIsMine() &&
                        <Field
                            component={NWPrivacyLevelSelector}
                            label="Privacy Level"
                            name="PrivacyLevel"
                            value={values.PrivacyLevel}
                        />
                    }
                    <br /><br />
                    <NwFormButtonsBar
                        left={
                            <NwCancelButton
                                disabled={isSubmitting}
                                onClick={() => {
                                    onTouchForm(false);
                                    onClose();
                                }}
                            />
                        }
                        right={
                            <NwSaveButton
                                htmlType="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                            />
                        }
                    />
                </NwForm>
            )}
        </Formik>
    );
};

export default PlayListInfoForm;