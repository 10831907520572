import React, { useMemo } from "react";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from "Components/Gui/NwIcon";
import PermissionBlock from "Components/PermissionBlock/PermissionBlock";
import { useUserPermission } from 'Contexts/UserContext';

const ContactDetailsHeaderStatement = ({ type, onOpenStatements, iconSize }) => {

    const ViewAgencyStatement = useUserPermission('ViewAgencyStatement');
    const ViewClientStatement = useUserPermission('ViewClientStatement');
    const ViewModelStatement = useUserPermission('ViewModelStatement');
    const ViewServiceStatement = useUserPermission('ViewServiceStatement');
    const ViewAgencyCommission = useUserPermission('ViewAgencyCommission');
    const ViewMotherAgencyCommission = useUserPermission('ViewMotherAgencyCommission');
    const ViewAgencyFee = useUserPermission('ViewAgencyFee')
    
    const statementAccess = useMemo(() => {
        switch (type) {
            case "model":
                if (ViewModelStatement && ViewAgencyCommission) {
                    return true
                }
                break;
            case "client":
                if (ViewClientStatement && ViewAgencyFee) {
                    return true
                }
                break;
            case "agency":
                if (ViewAgencyStatement && ViewMotherAgencyCommission) {
                    return true
                }
                break;
            case "service":
                if (ViewServiceStatement) {
                    return true
                }
                break;
            default:
                return false;
        }
        return false;
    }, [type])

    return (
        <PermissionBlock
            permission={statementAccess}
            placement='bottomLeft'
            content={
                <NwIcon
                    size={iconSize}
                    icon={light("balance-scale")}
                    onClick={statementAccess && onOpenStatements}
                    pointer={statementAccess}
                />
            }
        />
    )
};

export default ContactDetailsHeaderStatement;
