import React from "react";

import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";

const FeaturesBool = ({ onChange, value, disabled }) => {
    return (
        <RadioButtonGroup 
            value={value}
            disabled={disabled}
            defaultValue={null}
            onChange={onChange}
            options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false }
            ]} />
    );
};

export default FeaturesBool;
