import React from "react";
import { Slider } from "antd";
import styled from 'styled-components';
import { useGlobalFeatures, useGlobalConversions } from 'Hooks/Features/UseFeatures';
import { useUserGuiConfig } from "Contexts/UserGuiConfigContext";

const ConversionSlider = styled(Slider)`
    margin-top: 6px;
    
    .ant-slider-mark-text {
        font-size: .65rem;
        font-weight: bold;
    }

    
    .ant-slider-track {
        background-color: ${props => props.$disabledStyle && 'rgba(0, 0, 0, 0.25) !important'};
    }
  
    .ant-slider-handle, .ant-slider-dot {
        border-color: ${props => props.$disabledStyle && 'rgba(0, 0, 0, 0.25) !important'};
    }
`;

const FeaturesRangeSlider = ({
  onChange,
  value,
  disabled,
  itemid
}) => {
  const { data: globalFeatures } = useGlobalFeatures();
  const feature = globalFeatures.find(feature => feature.ID === itemid);
  const { data: globalConversions } = useGlobalConversions();
  const userGuiConfig = useUserGuiConfig()
  const conversion = globalConversions && globalConversions.find(conversion => conversion.ID === feature.ConversionID);
  
  const metric = userGuiConfig.metrics.find(metric => metric.ID === conversion.ID);  
  const conversionValues = conversion ? conversion.ConversionValues.filter(conversionValue => conversionValue.Metric === metric.metrics[0]) : [];

  const handleChange = index => {
    const val1 = conversionValues[index[0]].Key;
    const val2 = conversionValues[index[1]].Key;
    onChange([val1, val2]);
  };

  const filterValue = val => {
    const index1 = conversionValues.findIndex(el => el.Key === val[0]);
    const index2 = conversionValues.findIndex(el => el.Key === val[1]);
    return [index1, index2];
  };

  let marks = {};
    const interval = Math.round(conversionValues.length / 10);
    conversionValues.map((conversionValue, index) => {
        if ((index % interval === 0 && conversionValues.length - index >= interval)
            || index === conversionValues.length-1) {
            marks = {
                ...marks,
                [index]: conversionValue.Value
            }
        } 
        return null;
    });

  return conversionValues.length > 0 &&
    <ConversionSlider
        min={0}
        range
        marks={marks}
        max={conversionValues.length - 1}
        getTooltipPopupContainer={triggerNode => triggerNode.parentNode}
        style={{ width: "100%" }}
        value={filterValue(value)}
        onChange={handleChange}
        disabled={disabled}
        $disabledStyle={disabled}
        tooltipVisible
        tipFormatter={value => `${conversionValues[value].Value} ${metric.metrics[0]}`}
    />
};

export default FeaturesRangeSlider;
