import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";
import Axios from 'axios';
import { List, Tag } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCardContent, NwCard } from "Components/Gui/NwCard/NwCard";
import { NwCardTabsContent } from 'Components/Gui/NwCard/NwCardTabs';
import { NwAddButton } from 'Components/Gui/NwButton';
import EmptyState from "Components/Gui/EmptyState";
import { useStateValue } from 'Contexts/StateProvider';
import { sliceData, checkValidPage } from "Libs/NwUtils";
import NwDropDownMenu from "Components/Gui/NwDropDownMenu";
import ObjectLogHistories from 'Components/ObjectLogHistories/ObjectLogHistories';
import NwDrawer from 'Components/Gui/NwDrawer';
import NoteAddEditDrawer from 'Components/ContactDetailsNotes/NoteAddEditDrawer';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import NwEllipsis from 'Components/Gui/NwEllipsis';
import Visibilities from 'Components/Gui/Visibilities';
import { useUserPermission } from "Contexts/UserContext";
import { ListContext } from 'Contexts/ListContext';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import CreatedBy from "Components/CreatedBy/CreatedBy";
import { forceReloadContact } from "Hooks/Contact/UseContact";

// const DetailsCardContent = styled.div`
//     display: flex;
//     flex: 1;
//     flex-direction: column;
//     justify-content: flex-start;
//     .tabs-content {
//         justify-content: ${props => !props.viewNotes && 'center'}
//     }
// `;

const LockContainer = styled(FlexContainer)`
    height: 100%;
    padding: ${props => props.$drawerMode && '32px 0'};
    svg {
        font-size: ${props => props.$drawerMode ? '80px' : '120px'};
    }
`;

const Wrapper = styled.div`
    .ant-list-item {
        align-items: flex-start;
    }
    .note-description-container {
        .tag-container {
            min-width: 90px;
            padding-bottom: .5rem;

            .ant-tag {
                color: white;
                border-color: ${props => props.theme.PrimaryDarkLight};
                background-color: ${props => props.theme.PrimaryDarkLight};
            }
        }

        .created-by {
            font-size: ${props => props.theme.FontSizeSm};
            color: ${props => props.theme.TextColorMuted};
            text-transform: uppercase;
        }
    }
`

const ContactDetailsNotes = ({ currentContact, readOnly, isInCardTab, drawerMode, createNotes, afterNotesCreation, noPagination }) => {
    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 3;
    const [editingMode, setEditingMode] = useState(false);
    const [itemToModify, setItemToModify] = useState(null);
    const [createMode, setCreateMode] = useState(createNotes);
    //const { data: currentContact } = useContact(contactID)
    const [{ areaContext }] = useStateValue();
    const { cachedList } = useContext(ListContext);
    const { Notes } = currentContact;
    const breakpoints = useNwBreakPoints();

    const viewModelNotes = useUserPermission('ViewModelNotes', currentContact && currentContact.Users.map(user => user.UserID));
    const viewClientNotes = useUserPermission('ViewClientNotes', currentContact && currentContact.Users.map(user => user.UserID));
    const viewAgencyNotes = useUserPermission('ViewAgencyNotes', currentContact && currentContact.Users.map(user => user.UserID));
    const viewServiceNotes = useUserPermission('ViewServiceNotes', currentContact && currentContact.Users.map(user => user.UserID));
    
    const viewNotes = useMemo(() => {
        if (currentContact) {
            if (currentContact.SubType === 1) return viewClientNotes;
            if (currentContact.SubType === 2) return viewAgencyNotes;
            if (currentContact.SubType === 3) return viewServiceNotes;
            return viewModelNotes
        }
        return false;
    }, [currentContact, viewClientNotes, viewAgencyNotes, viewServiceNotes, viewModelNotes]);

    useEffect(() => {
        setCreateMode(createNotes)
    }, [createNotes]);

    const notes = useMemo(() => {
        let notes = [...currentContact.Notes];
        notes.sort((note1, note2) => getMoment(note1.DateCreated) < getMoment(note2.DateCreated) ? 1 : -1);
        notes = notes.filter(note => {
            if (note.Visibility) {
                if (note.Visibility.Areas.length === 0 || note.Visibility.Areas.find(area => area === areaContext.area)) {
                    return true;
                }
                return false;
            }
            return true;
        });
        return notes;
    }, [activePage, itemsPerPage, currentContact]);
    
    const notestorender = (isInCardTab && !noPagination) ? sliceData(activePage, itemsPerPage, notes) : notes;

    const handleCreated = () => {
        setItemToModify(itemToModify);
        setCreateMode(false);
        setEditingMode(false);
        afterNotesCreation();    
    };

    const handlePageChange = page => {
        setActivePage(page);
    };

    const pagination = noPagination ? false : {
        total: notes.length,
        current: activePage,
        pageSize: itemsPerPage,
        onChange: handlePageChange
    };

    useEffect(() => {
        checkValidPage(activePage, itemsPerPage, Notes, handlePageChange);
    });

    const deleteNote = noteId => {
        Axios.delete(`/notes/${noteId}`)
        .then(() => {
            forceReloadContact(currentContact.ID)
        })
        .catch(error => {
            console.log("errore", error);
        });
    };
    
    

    const renderActions = note => {
        return ([
            <NwDropDownMenu
                size='small'
                actions={[
                    { name: "", key: "edit", pinned: true, icon: light("pen"), action: () => {
                        setItemToModify(note);
                        setEditingMode(true);
                    }},
                    { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: () => deleteNote(note.ID)}
                ]}
            />
        ]);
    };

    const renderNoteList = notes => {
        return (
            <Wrapper>
            <List
                size='small'
                itemLayout="horizontal"
                dataSource={notes}
                renderItem={note => {
                    const { Text, NoteTypeCode, Visibility } = note;
                    return (
                        <List.Item actions={!readOnly && renderActions(note)}>
                            <List.Item.Meta
                                description={
                                    <div className="note-description-container">
                                        <div className="tag-container">
                                            <Tag className='tag'>
                                                {cachedList.noteTypes.items.find(el => el.Code === NoteTypeCode).Name}
                                            </Tag>
                                        </div>
                                        <div>
                                            <NwEllipsis popover popovertitle={'Note'} rows={2} text={Text} />                
                                            <CreatedBy object={note} />
                                            {Visibility.Areas.length > 0 &&
                                                <Visibilities visibilities={Visibility.Areas} />
                                            }
                                        </div>
                                    </div>
                                }
                            />
                        </List.Item>
                    )}
                }
            />
            </Wrapper>
        )
    }

    const renderCardContent = () => {
        return (
            <NwCardTabsContent>
                {viewNotes ? 
                    <>
                        <NwCardContent pagination={(breakpoints !== 1 && isInCardTab) ? pagination : null}>
                            {notestorender && notestorender.length > 0 ? (
                                renderNoteList(notestorender)
                            ) : (
                                <EmptyState message="no notes" />
                            )}
                        </NwCardContent>
                        
                        {(editingMode || createMode) && (
                            <NoteAddEditDrawer note={itemToModify} contactID={currentContact.ID} afterNotesCreation={handleCreated} />
                        )}
                    </>
                    :
                    <LockContainer justifyContent="center" alignItems="center" $drawerMode={drawerMode}>
                        <NwIcon icon={light("lock-alt")} />
                    </LockContainer>
                }
            </NwCardTabsContent>
        )
    };

    return (
        // <DetailsCardContent viewNotes={viewNotes}>
            //{
            isInCardTab ? renderCardContent()
            : 
                <NwCard
                    title="Notes"
                    fullheight
                    icon={light("sticky-note")}
                    extra={
                        !readOnly &&
                        <NwAddButton
                            ghost
                            disabled={!viewModelNotes}
                            size="small"
                            onClick={() => {
                                setCreateMode(true)
                            }}
                            label="add new"
                        />
                    }>
                    {renderCardContent()}
                </NwCard>
            //}
        // </DetailsCardContent>
    );
};

export default ContactDetailsNotes;
