import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
// import Axios from 'axios';

import { NwSaveButton, NwCancelButton } from "Components/Gui/NwButton";
import {
  NWInput,
  NWTextAreaCharCounter
} from "Components/Gui/NwForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { useEditContactBankAccount, useAddContactBankAccount } from "Hooks/Contact/UseContactBankAccounts";

const BankAccountForm = ({ bankAccount, ContactID, onCancel, onTouchForm }) => {
    
    const initialValues = {
        BankName: bankAccount ? bankAccount.BankName : '',
        IBAN: bankAccount ? bankAccount.IBAN :'',
        BIC: bankAccount ? bankAccount.BIC :'',
        Description: bankAccount ? bankAccount.Description :'',
        InternalNote: bankAccount ? bankAccount.InternalNote :'',
    };

    const { mutateAsync: editContactBankAccount } = useEditContactBankAccount()
    const { mutateAsync: addContactBankAccount } = useAddContactBankAccount()

    const saveBankAccount = async (values, setSubmitting) => {
        const data = {
            ...values,
            ContactID: ContactID
        };

        if (bankAccount) {
            try {
                await editContactBankAccount({data: data, contactID: ContactID, bankId: bankAccount.ID})
                // await Axios.put(`/ContactBankAccounts/${bankAccount.ID}`, data)
                // forceReloadContactBankAccounts(ContactID)
                // forceReloadContact(ContactID)
                onCancel()
            } catch (error) {
                console.log('ant : Save Billing Account Error => ', error)
            }
        } else {
            try {
                await addContactBankAccount({data: data, contactID: ContactID})
                // await Axios.post(`/ContactBankAccounts`, data)
                // forceReloadContactBankAccounts(ContactID)
                onCancel()
            } catch (error) {
                console.log('ant : Add Billing Account Error => ', error)
            }
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
                BankName: Yup.string().required("Bank name is required"),
                IBAN: Yup.string().required("IBAN is required")
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                saveBankAccount(values, setSubmitting);
            }}>
            {({ isSubmitting, values, setFieldValue, handleSubmit }) => (
                <NwForm 
                    values={values}
                    onTouchForm={onTouchForm}
                    onFinish={handleSubmit} 
                    layout="vertical">
                    <Field
                        component={NWInput}
                        label="Bank Name"
                        name="BankName"
                        type="text"
                        value={values.BankName}
                    />
                    <Field
                        component={NWInput}
                        label="IBAN"
                        name="IBAN"
                        type="text"
                        value={values.IBAN}
                    />
                    <Field
                        component={NWInput}
                        label="BIC"
                        name="BIC"
                        type="text"
                        value={values.BIC}
                    />
                    <Field
                        component={NWTextAreaCharCounter}
                        label="Description"
                        name="Description"
                        type="text"
                        value={values.Description}
                        maxLength={2000}
                        autoSize={{ minRows: 2, maxRows: 10 }}
                    />
                    <Field
                        component={NWTextAreaCharCounter}
                        label="Internal Note"
                        name="InternalNote"
                        type="text"
                        value={values.InternalNote}
                        maxLength={2000}
                        autoSize={{ minRows: 2, maxRows: 10 }}
                    />
                    <NwFormButtonsBar
                        left={
                            <NwCancelButton
                                disabled={isSubmitting}
                                onClick={() => {
                                    onTouchForm(false);
                                    onCancel();
                                }}
                            />
                        }
                        right={
                            <NwSaveButton
                                htmlType="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                            />
                        }
                    />
                </NwForm>
            )}
        </Formik>
    );
};

export default BankAccountForm;
