import React, { useState, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Tag, Popover } from 'antd';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import MiniLoading from 'Components/Gui/MiniLoading';
import FlexContainer from 'Components/Gui/FlexContainer';
import { ListContext } from 'Contexts/ListContext';
import { sortTags, mapTags } from 'Libs/NwUtils';
import NwIcon from 'Components/Gui/NwIcon';
import StyleVariables from 'Components/Gui/StyleVariables';

const TagsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    .ant-tag {
        margin: 0 0 .75rem 0;
        cursor: pointer;
    }
`

const ProjectTags = ({ objectTags, onAddTag, onRemoveTag, objectType }) => {
    const [adding, setAdding] = useState(false);
    const [removing, setRemoving] = useState(false);
    const { cachedList } = useContext(ListContext);

    const cachedtags = cachedList.publictags.items.concat(cachedList.privatetags.items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const allTags = useMemo(() => sortTags(mapTags(cachedtags, objectType)), [cachedtags])
    const tags = objectTags ? objectTags : [];

    const doRemoveTag = async (tag) => {
        setRemoving(true)
        await onRemoveTag(tag)
        setRemoving(false)
    }

    const doAddTag = async (tag) => {
        setAdding(true)
        await onAddTag(tag)
        setAdding(false)
    }

    const addedTags = allTags.filter(tag => tags.find(item => item.ID === tag.ID));
    const addableTags = allTags.filter(tag => !tags.find(item => item.ID === tag.ID));

    return (
        <tr>
            <td className="title-cell" style={{ verticalAlign: "middle", paddingTop: "1rem" }}>
                <NwIcon icon={light('tag')} /> Tags
            </td>
            <td className="content-cell" style={{ verticalAlign: "middle", paddingTop: "1rem" }}>
                <FlexContainer>
                    {removing ?
                        <MiniLoading />
                        :
                        <div>
                            {(addedTags && addedTags.length)
                                ?
                                addedTags.map(tag => (
                                    <Tag
                                        key={tag.ID}
                                        closable
                                        color={StyleVariables.PrimaryDark}
                                        onClose={() => doRemoveTag(tag)}
                                    >
                                        {tag.Name}
                                    </Tag>
                                ))
                                :
                                <em>no tags</em>
                            }
                        </div>
                    }
                    {(addableTags.length > 0) &&
                        <Popover
                            title='Tags'
                            trigger='click'
                            overlayStyle={{ maxWidth: 300, minWidth: 250 }}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            content={adding ?
                                <MiniLoading />
                                : <TagsContainer>
                                    {addableTags.map(tag => (
                                        <Tag key={tag.ID} onClick={() => doAddTag(tag)}>+ {tag.Name}</Tag>
                                    ))}
                                </TagsContainer>
                            }>

                            <Tag key="add-tag" style={{ cursor: "pointer" }}><NwIcon icon={solid("plus-circle")} /> add a tag</Tag>
                        </Popover>
                    }
                </FlexContainer>
            </td>
        </tr>
    );
};

export default ProjectTags;