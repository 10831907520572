import React from "react";
import { Slider } from "antd";
import styled from 'styled-components';

import { useGlobalConversions } from 'Hooks/Features/UseFeatures';
import { useUserGuiConfig } from "Contexts/UserGuiConfigContext";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 6px;
    
    .slider-wrapper {
        //take all available flex space
        flex: 1;
    }
    .clear-wrapper {
        //take only needed space
        flex: 0;
        margin-left: .5rem;
    }

    .ant-slider-mark-text {
        font-size: .65rem;
        font-weight: bold;
    }
`;

const FeaturesRangeSliderFilter = ({
    onChange,
    value,
    feature
}) => {

    const { data: globalConversions } = useGlobalConversions();
    const userGuiConfig = useUserGuiConfig()
    const conversion = globalConversions && globalConversions.find(conversion => conversion.ID === feature.ConversionID);

    const metric = userGuiConfig.metrics.find(metric => metric.ID === conversion.ID);
    const metricToUse = metric ? metric.metrics[0] : conversion.AvailableMetrics.split(',')[0];
    const conversionValues = conversion ? conversion.ConversionValues.filter(conversionValue => conversionValue.Metric === metricToUse) : [];

    const handleChange = index => {
        const val1 = conversionValues[index[0]].Key;
        const val2 = conversionValues[index[1]].Key;
        onChange([val1, val2]);
    };

    const filterValue = val => {
        const index1 = conversionValues.findIndex(el => el.Key === val[0]);
        const index2 = conversionValues.findIndex(el => el.Key === val[1]);
        return [index1, index2];
    };

    let marks = {};
    const interval = Math.round(conversionValues.length / 10);
    conversionValues.map((conversionValue, index) => {
        if ((index % interval === 0 && conversionValues.length - index >= interval)
            || index === conversionValues.length - 1) {
            marks = {
                ...marks,
                [index]: conversionValue.Value
            }
        }
        return null;
    });

    return conversionValues.length > 0 &&
        <Wrapper>
            <div className="slider-wrapper">
                <Slider
                    min={0}
                    range
                    marks={marks}
                    max={conversionValues.length - 1}
                    tooltip={{
                        getPopupContainer: triggerNode => triggerNode.parentNode,
                        formatter: (val) => {
                            if (value.length === 0)  {
                                return 'off';
                            } else {
                                return (`${conversionValues[val].Value} ${metricToUse}`)
                            }
                        },
                        open: true
                    }}
                    style={{ width: "100%" }}
                    value={filterValue(value)}
                    onChange={handleChange}
                    tip
                />
            </div>
        </Wrapper>
};

export default FeaturesRangeSliderFilter;
