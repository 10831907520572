import React, { useContext, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styled from 'styled-components';
import Axios from 'axios';
import arrayMove from 'array-move';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import AvatarBg from 'Components/Gui/AvatarBg';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import { NwButton } from 'Components/Gui/NwButton';
import { getBookCover } from 'Libs/NwUtils';
import { useDeletePlaylistFromPackage, useActivePackages } from 'Hooks/Packages/UsePackages';
import PackagePlayListDrawer from "../PackagePlayListDrawer";
import PackageMediaDrawer from "../PackageMediaDrawer";
import ModelMedia from 'Components/ModelMedia/ModelMedia';
import NwDrawer from "Components/Gui/NwDrawer";
import { PackageContext } from '../PackageContext';
import { ModelContextProvider } from 'Contexts/ModelContext';
//TODO: update PlaylistEditor
//import PlaylistEditor from 'Components/ModelMedia/PlaylistEditor/PlaylistEditor';

const DragItemWrapper = styled.div`
    margin-bottom: 8px;
    margin-right: 16px;
    display: flex;
    z-index: 2100;
`;

const DragItem = styled.div`
    position: relative;
    animation-name: ${props => props.sorting && 'keyframes1'};
    animation-iteration-count: infinite;
    transform-origin: 50% 10%;

    animation-delay: -.75s; 
    animation-duration: .25s;

    @keyframes keyframes1 {
        0% {
          transform: rotate(-0.75deg);
          animation-timing-function: ease-in;
        }
  
        50% {
          transform: rotate(0.75deg);
          animation-timing-function: ease-out;
        }
    }

    .book-wrapper-top {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: ${props => `${props.size}px`};
        
        .book-wrapper-left {
            position: relative;

            .media {
                position: absolute;
                left: .5rem;
                top: .25rem;
                color: #fff;
                font-size: 2rem;
                background-color: transparent;
                z-index: 3;
                opacity: .6;
                line-height: 1em;
            }

            .hover-container {
                width: 100%;
                position: absolute;
                overflow: hidden;
                left: 0;
                top: 0;
                height: ${props => `${props.size}px`};
                transition: all 0.4s ease-in-out;
                z-index: 4;
                color: #fff;
                line-height: 1.2;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; 
                background-color: rgba(0, 0, 0, 0.5);
                opacity: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hint-text {
                    top: 0;
                    left: 0;
                    width: 100%;
                    text-transform: uppercase;
                    font-size: .7rem;
                    padding: 0 10px;
                    color: #fff;
                }
            }

            &:hover {
                .hover-container {
                    opacity: 1;
                }
            }
        }

        .book-wrapper-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: .5rem 0;
            width: 2.5rem;
            font-size: 1.1rem;
            height: ${props => `${props.size}px`};
            border-left: 1px solid #fff;
            background-color: ${props => props.theme.LayoutBodyBackground};
        }
    }

    .book-wrapper-bottom {
        .book-title {
            width: 100%;
            font-size: .8rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0;
            text-align: left;
        }
    }
`;

const SortableListContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media only screen and (max-width: 720px) {
        flex-direction: column;
    }
`

const BookItem = ({ item, size, onBookSelect }) => {
    return (
        <DragItemWrapper>
            <DragItem size={size}>
                <AvatarBg
                    size={size}
                    src={getBookCover(item)}
                />
                <FlexContainer justifyContent="space-between" style={{ width: `${size}px` }}>
                    <p>
                        {item.Name}
                    </p>
                    <NwButton icon={light("edit")} ghost onClick={() => onBookSelect(item)} />
                </FlexContainer>
            </DragItem>
        </DragItemWrapper>
    );
};

const SortableItem = SortableElement(({
    isActivePackage,
    item,
    onBookSelect,
    onPreviewBook,
    packageId,
    size,
    sorting,
}) => {
    const { mutateAsync: onDeletePlaylistFromPackage } = useDeletePlaylistFromPackage();

    const packageContextValue = useContext(PackageContext);
    const { afterUpdatePackage } = packageContextValue;

    const removeFromPackage = async () => {
        try {
            await onDeletePlaylistFromPackage({ packageID: packageId, packagePlaylistId: item.ID, isActivePackage: isActivePackage });
            afterUpdatePackage();
        } catch (error) {
            console.log('ant : Delete Playlist To Package Error => ', error);
        }
    };

    return (
        <DragItemWrapper>
            <DragItem sorting={sorting} size={size}>
                <div className="book-wrapper-top">
                    <div className="book-wrapper-left">
                        <AvatarBg
                            onClick={() => onPreviewBook(item)}
                            size={size}
                            src={getBookCover(item)}
                        />
                        {item.Type.toLowerCase() === "media" &&
                            <div className="media">
                                <NwIcon icon={light("folder-user")} />
                            </div>
                        }
                        <div className="hover-container" onClick={() => onPreviewBook(item)}>
                            <small className="hint-text">
                                click to view book<br />hold and drag to sort
                            </small>
                        </div>
                    </div>
                    <div className="book-wrapper-right">
                        <NwIcon pointer icon={solid('times')} onClick={removeFromPackage} />
                        <NwIcon pointer icon={light("edit")} onClick={() => onBookSelect(item)} />
                    </div>
                </div>
                <div className="book-wrapper-bottom">
                    <div className="book-title">{item.Name}</div>
                </div>
            </DragItem>

        </DragItemWrapper>
    )
});

const SortableList = SortableContainer(({
    items,
    sorting,
    packageId,
    isActivePackage,
    onPreviewBook,
    size,
    onBookSelect,
    onShowMediaPreview
}) => {
    return (
        <SortableListContainer>
            {items.map((item, index) => {
                return (
                    <SortableItem
                        key={`item-${index}`}
                        sorting={sorting}
                        packageId={packageId}
                        index={index}
                        item={item}
                        isActivePackage={isActivePackage}
                        size={size}
                        onPreviewBook={() => {
                            if (item.Type.toLowerCase() === "media") {
                                onShowMediaPreview();
                            }
                            onPreviewBook(item.ID);
                        }}
                        onBookSelect={onBookSelect}
                    />
                )
            })}
        </SortableListContainer>
    );
});

const PackagePlaylists = ({
    contactID,
    onSetIsUpdatingOrder,
    onUpdatePlaylistOrder,
    packageModelId,
    playlists,
    readOnly,
    smallView,
}) => {
    const [selectedPackagePlaylistID, setSelectedPackagePlaylistID] = useState();
    const [isBookDrawer, setIsBookDrawer] = useState(false);
    const [isSorting, setIsSorting] = useState(false);
    const { data: activePackages } = useActivePackages();
    const [selectedBook, setSelectedBook] = useState();
    const [showMediaPreview, setShowMediaPreview] = useState(false);

    const packageContextValue = useContext(PackageContext);
    const { groupedPackage, afterUpdatePackage} = packageContextValue;
    const { ID: packageId } = groupedPackage;

    const size = smallView ? 80 : 120;

    const onSortEnd = async ({ oldIndex, newIndex }) => {
        setIsSorting(false);
        if (oldIndex !== newIndex) {
            const newPlaylists = arrayMove(playlists, oldIndex, newIndex);
            onUpdatePlaylistOrder(newPlaylists);
            onSetIsUpdatingOrder(true);
            try {
                await Axios.put(`/PackagePlaylists/SetOrder/${packageId}/${packageModelId}`, newPlaylists.map(pl => pl.ID));
                afterUpdatePackage();
                onSetIsUpdatingOrder(false);
            } catch (error) {
                console.log('ant : Update Playlist Order Error => ', error);
                onSetIsUpdatingOrder(false);
            }
        }
    };

    const onPreviewBook = id => {
        //setBookPreview(book);
        setSelectedPackagePlaylistID(id)
        setIsBookDrawer(true);
    };

    return (
        <>
            {readOnly ?
                <>
                    {playlists.map(playlist => {
                        return (
                            <BookItem
                                size={size}
                                key={`playlist-${playlist.ID}`}
                                item={playlist}
                                onBookSelect={setSelectedBook}
                            />
                        )
                    })}
                </>
                :
                <SortableList
                    items={playlists}
                    onSortStart={() => setIsSorting(true)}
                    sorting={isSorting}
                    packageId={packageId}
                    isActivePackage={activePackages && activePackages.find(pack => pack.ID === packageId)}
                    onSortEnd={onSortEnd}
                    onPreviewBook={onPreviewBook}
                    onShowMediaPreview={() => setShowMediaPreview(true)}
                    size={size}
                    pressDelay={200}
                    onBookSelect={setSelectedBook}
                    axis='xy' />
            }

            {showMediaPreview &&
                <PackageMediaDrawer
                    packageplaylistid={selectedPackagePlaylistID}
                    onClose={() => {
                        setIsBookDrawer(false);
                        setShowMediaPreview(false);
                    }}
                />
            }

            {(!showMediaPreview && isBookDrawer) &&
                <PackagePlayListDrawer
                    packageplaylistid={selectedPackagePlaylistID}
                    onClose={() => {
                        setIsBookDrawer(false);
                        setShowMediaPreview(false);
                    }}
                />
            }

            {selectedBook &&
                <NwDrawer
                    width="95vw"
                    title='Edit Book'
                    padding="0"
                    onClose={() => setSelectedBook()}

                >
                    {/* <PlaylistEditor
                        selectedModel={contactID}
                        packageBook={{...selectedBook, PackageID: packageId}}
                        onClose={() => setSelectedBook()}
                    /> */}
                    <ModelContextProvider modelSelected={contactID}>
                        <ModelMedia
                            packageBook={{ ...selectedBook, PackageID: packageId }}
                            onCloseBookEditor={() => setSelectedBook()}
                            isDrawer
                        />
                    </ModelContextProvider>
                </NwDrawer>
            }
        </>
    );
};

export default PackagePlaylists;