import React from "react";
import { getMoment } from "Libs/NwMoment";
import * as Yup from "yup";

import EditEvent from "Components/EventDrawer/EditEvent";
import {
    NWInput,
    NWSelectTransport,
    NWContactDropdown,
    NWTextAreaCharCounter,
    NWLocationDateSelector,
    NWSwitch,
    NWBookerSelector
} from "Components/Gui/NwForm/NwFormItems";
import {
    useUpdateScoutingTravel,
    useAddScoutingTravel,
    useUpdateGroupedIndividualScoutingTravel,
    useUpdateGroupedCommonScoutingTravel
} from 'Hooks/Event/UseScoutingTravel';
// import { ListContext } from 'Contexts/ListContext';
import { getTime, isSameDate, timeArrayToString, isAllDay, getNow } from 'Libs/NwMoment';
import { getEndTime } from 'Libs/NwUtils';

const validateLocationDate = value => {
    let error;
    if (value.Place === '') {
        error = 'Place is required';
    }
    return error;
};

const validateForm = (values, props) => {
    const errors = {};

    if (values.Departure.Date > values.Arrival.Date) {
        errors.Arrival = 'Arrival date should be after than departure date';
    }
    return errors;
};

const ScoutingTravelForm = props => {
    const { 
        travelsData,
        viewMode, 
        userId,
        travelPlanId,
        editModelEvent,
        afterUpdate, 
        dateSelected,
        allDay,
        onCancel,
        onTouchForm,
        onUpdateScoutingTravelData
    } = props;
    const { mutateAsync: onAddScoutingTravel } = useAddScoutingTravel();
    const { mutateAsync: onUpdateScoutingTravel } = useUpdateScoutingTravel();
    const { mutateAsync: onUpdateGroupedIndividualScoutingTravel } = useUpdateGroupedIndividualScoutingTravel();
    const { mutateAsync: onUpdateGroupedCommonScoutingTravel } = useUpdateGroupedCommonScoutingTravel();
    
    const submitForm = async (values, setStatus, setSubmitting) => {
        const newScoutingTravelData = {
            ...initialValues,
            ...values,
            StartLocation: values.Departure.Place,
            StartDateTime: values.Departure.Date,
            ArrivalLocation: values.Arrival.Place,
            ArrivalDateTime: values.Arrival.Date
        };

        if (!travelsData) {
            try {
                const newScoutingTravel = await onAddScoutingTravel({ scoutingTravelData: newScoutingTravelData });
                onTouchForm(false);
                if (afterUpdate){
                    afterUpdate({
                        ...newScoutingTravel,
                        TypeName: "Travel"
                    });
                }
                onUpdateScoutingTravelData(newScoutingTravel.ID);
                onCancel();
            } catch (error) {
                console.log("ant : Create ScoutingTravel Error", error);
            }
            setSubmitting(false);
        } 
        else {
            newScoutingTravelData.ID = travelsData.ID;

            try {
                if (travelsData.GroupGuid) {
                    if (editModelEvent) {
                        newScoutingTravelData.ChangeOnAllBookers = values.ChangeOnAllBookers;
                        await onUpdateGroupedIndividualScoutingTravel({ travelData: newScoutingTravelData });
                    } else {
                        newScoutingTravelData.GroupGuid = travelsData.GroupGuid;
                        delete newScoutingTravelData.ID;
                        await onUpdateGroupedCommonScoutingTravel({ scoutingTravelData: newScoutingTravelData });
                    }
                } else {
                    console.log()
                    await onUpdateScoutingTravel({ scoutingTravelData: newScoutingTravelData });
                }

                // await onUpdateScoutingTravel({ travelData: newScoutingTravelData });
                if (afterUpdate) {
                    afterUpdate();
                }
                onTouchForm(false);
                onUpdateScoutingTravelData();
                if (!viewMode || viewMode === 'create') {
                    onCancel();
                }
            } catch (error) {
                console.log("ant : Update ScoutingTravel Error", error);
            }
            setSubmitting(false);
        }
    };

    const departureDate = 
        (dateSelected 
        ?
            (allDay 
            ? 
                getMoment(dateSelected).hour(0).minute(0)
            :
                getMoment(dateSelected)
            )
        : 
            getNow().hour(9).minute(0)
        )

    let initialValues = {
        TravelPlanID: travelPlanId,
        Title: travelsData ? travelsData.Title : '',
        UserID: travelsData ? travelsData.UserID : userId,
        StartDateTime: travelsData ? getMoment(travelsData.StartDateTime) : null,
        ArrivalDateTime: travelsData ? getMoment(travelsData.ArrivalDateTime) : null,
        BookingReference: travelsData ? travelsData.BookingReference : '',
        TicketID: travelsData ? travelsData.TicketID : '',
        TransportReference: travelsData ? travelsData.TransportReference : '',
        Transport: travelsData ? travelsData.Transport : 'Plane',
        ServiceID: travelsData ? travelsData.ServiceID : null,
        StartLocation: travelsData ? travelsData.StartLocation : '',
        ArrivalLocation: travelsData ? travelsData.ArrivalLocation : '',
        Description: travelsData && travelsData.Description ? travelsData.Description : '',
        Departure: travelsData ? {
            Place: travelsData.StartLocation,
            Date: getMoment(travelsData.StartDateTime),
            allDay: isAllDay(travelsData.StartDateTime, travelsData.ArrivalDateTime) ? true : false,
            disableTime: isAllDay(travelsData.StartDateTime, travelsData.ArrivalDateTime) ? true : false
        } : {
            Place: '',
            Date: departureDate,
            allDay: dateSelected && allDay ? true : false,
            disableTime: dateSelected && allDay ? true : false
        },
        Arrival: travelsData ? {
            Place: travelsData.ArrivalLocation,
            Date: getMoment(travelsData.ArrivalDateTime),
            disableTime: isAllDay(travelsData.StartDateTime, travelsData.ArrivalDateTime) ? true : false,
            beforeTime: isSameDate(travelsData.StartDateTime, travelsData.ArrivalDateTime) && timeArrayToString(getTime(travelsData.StartDateTime)),
            beforeDate: getMoment(travelsData.StartDateTime)
        } : {
            Place: '',
            Date: 
                (dateSelected
                ?
                    (allDay
                    ? 
                        getMoment(dateSelected).hour(23).minute(59)
                    : 
                        getMoment(dateSelected).add(2, 'hours')
                    )
                : 
                    getNow().hour(11).minute(0)
                ),
            disableTime: dateSelected && allDay ? true : false,
            beforeTime: null,
            beforeDate: getMoment(departureDate)
        },
        ChangeOnAllBookers: true
    };

    let generalDataSource = [
        {
            component: NWSelectTransport,
            label: 'Transport',
            name: 'Transport',
            required: true
        },
        {
            component: NWContactDropdown,
            label: "Service",
            activeTypes: ["srv"],
            activableTypes: ["cli"],
            name: "ServiceID"
        },
        {
            component: NWLocationDateSelector,
            label: 'Departure',
            name: 'Departure',
            Col: 24,
            allDaySelectable: true,
            onAfterChange: (values, setFieldValue, value) => {
                if (values.Departure.allDay !== value.allDay) {
                    if (value.allDay) {
                        setFieldValue('Arrival', {
                            ...values.Arrival,
                            Date: getMoment(values.Arrival.Date).hour(23).minute(59),
                            disableTime: true,
                        })
                        setFieldValue('Departure', {
                            ...values.Departure,
                            Date: getMoment(values.Departure.Date).hour(0).minute(0),
                            disableTime: true,
                            allDay: true
                        })
                    } else {
                        setFieldValue('Arrival', {
                            ...values.Arrival,
                            Date: getMoment(values.Arrival.Date).hour(11).minute(0),
                            disableTime: false,
                            beforeTime: "09:00"
                        })
                        setFieldValue('Departure', {
                            ...values.Departure,
                            Date: getMoment(values.Departure.Date).hour(9).minute(0),
                            disableTime: false,
                            allDay: false,
                        })
                    }
                } else if (values.Departure.Date !== value.Date) {
                    if (!isSameDate(value.Date, values.Departure.Date)) {
                        const endTime = getEndTime(getTime(value.Date), value.Date.hour() >= 22 ? [23, 59] : getTime(value.Date.clone().add(2, 'hours')), getTime(value.Date));

                        setFieldValue('Arrival', {
                            ...values.Arrival,
                            Date: 
                                (allDay
                                ?
                                    getMoment(value.Date).hour(23).minute(59)
                                :   
                                    getMoment(value.Date).hour(parseInt(endTime / 60)).minute(endTime % 60)
                              ),
                            beforeTime: timeArrayToString(getTime(value.Date)),
                            beforeDate: value.Date
                        });
                    } else {
                        if (isSameDate(value.Date, values.Arrival.Date)) {
                            const endTime = getEndTime(getTime(values.Departure.Date), getTime(values.Arrival.Date), getTime(value.Date));
                            setFieldValue('Arrival', {
                                ...values.Arrival,
                                Place: values.Arrival.Place,
                                Date: getMoment(value.Date).hour(parseInt(endTime / 60)).minute(endTime % 60),
                                beforeTime: timeArrayToString(getTime(value.Date)),
                                beforeDate: value.Date
                            })
                        } else {
                            setFieldValue('Arrival', {
                                ...values.Arrival,
                                beforeDate: value.Date
                            })
                        }
                    }
                }  else if (value.Place !== values.Departure.Place) {
                    if (values.Arrival.Place || value.Place) {
                        setFieldValue('Title', `${value.Place} to ${values.Arrival.Place}`)
                    }
                }
            },
            validate: validateLocationDate
        },
        {
            component: NWLocationDateSelector,
            label: 'Arrival',
            name: 'Arrival',
            Col: 24,
            onAfterChange: (values, setFieldValue, value) => {
                if (values.Arrival.Date !== value.Date) {
                    if (!isSameDate(value.Date, values.Departure.Date)) {
                        setFieldValue('Arrival', {
                            ...values.Arrival,
                            Date: value.Date,
                            beforeTime: null
                        });
                    }
                } else if (values.Arrival.Place || value.Place) {
                    setFieldValue('Title', `${values.Departure.Place} to ${value.Place}`)
                }
            },
            validate: validateLocationDate
        },
        {
            component: NWInput,
            label: 'Title',
            name: 'Title',
            type: 'text',
            Col: 24
        }
    ];

    let modelDataSource = [
        {
            component: NWTextAreaCharCounter,
            label: 'Description',
            name: 'Description',
            type: 'text',
            maxLength: 2000,
            Col: 24
        },        
        {
            component: NWInput,
            label: 'Ticket #',
            name: 'TicketID',
            type: 'text',
            Col: 24
        },
        {
            component: NWInput,
            label: 'Booking #',
            name: 'BookingReference',
            type: 'text',
        },
        {
            component: NWInput,
            label: 'Flight or Train Code',
            name: 'TransportReference',
            type: 'text',
        }  
    ];

    let dataSource = editModelEvent ? [...modelDataSource] : travelsData && travelsData.GroupGuid ? [...generalDataSource] : [...generalDataSource, ...modelDataSource]; 

    if (!userId) {
        dataSource.splice(1, 0, {
            component: NWBookerSelector,
            label: "Booker",
            name: "UserID",
            required: true,
            Col: 24
        });
    }

    if (editModelEvent) {
        dataSource.push({
            component: NWSwitch,
            text: 'apply these changes on all models in this follow-up',
            name: 'ChangeOnAllBookers',
            Col: 24
        });
    }

    let validationSchema = editModelEvent ? null : Yup.object().shape({
        Title: Yup.string().required("Title is required"),
        Transport: Yup.string().required("Transport is required"),
        UserID: Yup.string().required("User is required"),
    });

    return (
        <EditEvent 
            submitForm={submitForm} 
            mg={0}
            validationSchema={validationSchema} 
            dataSource={dataSource} 
            initialValues={initialValues}
            onCancel={onCancel}
            validate={validateForm}
            disableReinitialize
            onTouchForm={onTouchForm}
        />
    );
};

export default React.memo(ScoutingTravelForm);
