import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { light, thin } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwLinkButton } from "Components/Gui/NwButton";
import NwIcon from "Components/Gui/NwIcon";
import ProjectDrawer from 'Components/Project/ProjectDrawer';
import useCountryName from "Hooks/UseCountryName";
import CustomerDrawer from "Components/ContactDetails/CustomerDetails/Drawer/CustomerDrawer";
import { forceReloadInvoice } from "Hooks/Invoices/UseInvoices";
import InvoiceCustomFields from "Components/Invoices/InvoicePage/InvoiceCustomFields";

const SiderContent = styled.div`
    width: 300px;
    border-right: ${props => props.theme.DefaultBorder};
    font-size: 13px;
    padding-left: 1rem;
    .company-name {
        font-size: 18px;
        font-weight: bold;
        margin: 1rem 0;
    }
    .contact-info {
        display: flex;
        flex-direction: row;
        margin: 1rem 0;
        .contact-detail {
            margin-left: 1rem;
            width: 220px;
            overflow-wrap: break-word;
        }
    }
    .addressNote {
        margin: 1rem 0 2rem 0;
    }
    .projects-list {
        border-top: ${props => props.theme.DefaultBorder};
        padding-top: 1rem;
        margin: 1rem .5rem 0 0;
    }
    .title {
        font-weight: bold;
        margin-bottom: .5rem;
    }
    .ant-btn.ant-btn-link {
        white-space: wrap;
        text-align: left;
    }
`;

const InvoicePageSider = ({ invoiceData, onShowEditDrawer, locked }) => {

    const [projectPreviewID, setProjectPreviewID] = useState();
    const { BillingInfo } = invoiceData;
    const [showContactDrawer, setShowContactDrawer] = useState(false);
    const BillingCountryName = useCountryName(BillingInfo.Country);

    const handleOpenModelDrawer = projectId => {
        setProjectPreviewID(projectId);
    };

    const handleClosePreviewDrawer = () => {
        setProjectPreviewID();
    }

    const projects = useMemo(() => {
        const projects = [];
        if (invoiceData) {
            const { Rows } = invoiceData;
            if (Rows)
                Rows.map((row) => {
                    const { Statement } = row;
                    if (Statement && Statement.Transaction && Statement.Transaction.Project) {
                        const project = Statement.Transaction.Project;
                        if (project.ID) {
                            if (!projects.find(el => el.ID === project.ID)) projects.push(project);
                        }
                    }
                    return null;
                });
        }
        return projects;
    }, [invoiceData]);

    const onShowContactDrawer = () => {
        setShowContactDrawer(true);
    }

    const onCloseContactDrawer = () => {
        setShowContactDrawer(false);
    }

    const onSaveCustomFields = () => {
        forceReloadInvoice(invoiceData.ID);
    }

    const AddressLine2 = () => {
        //compose address line 2 with zip code, city and state
        //add comma and space only if city and state are present
        let addressLine2 = '';
        if (BillingInfo.Zip) addressLine2 += BillingInfo.Zip;
        if (BillingInfo.Zip && (BillingInfo.City || BillingInfo.State)) addressLine2 += ' ';
        if (BillingInfo.City) addressLine2 += BillingInfo.City;
        if (BillingInfo.City && BillingInfo.State) addressLine2 += ', ';
        if (BillingInfo.State) addressLine2 += BillingInfo.State;
        return addressLine2;
    }

    return (
        <>
            <SiderContent>
                <div className="company-name">{BillingInfo.CompanyName}</div>
                <div>{BillingInfo.Street}</div>
                <div>{AddressLine2()}</div>
                <div>{BillingCountryName}</div>
                {(BillingInfo.ContactName || BillingInfo.ContactPhone || BillingInfo.ContactEmail) &&
                    <div className="contact-info">
                        <NwIcon size="3x" icon={thin("address-book")} />
                        <div className="contact-detail">
                            <div>{BillingInfo.ContactName}</div>
                            <div>{BillingInfo.ContactPhone}</div>
                            <div>{BillingInfo.ContactEmail}</div>
                        </div>
                    </div>
                }
                {(BillingInfo.ContactName2 || BillingInfo.ContactPhone2 || BillingInfo.ContactEmail2) &&
                    <div className="contact-info">
                        <NwIcon size="3x" icon={thin("address-book")} />
                        <div className="contact-detail">
                            <div>{BillingInfo.ContactName2}</div>
                            <div>{BillingInfo.ContactPhone2}</div>
                            <div>{BillingInfo.ContactEmail2}</div>
                        </div>
                    </div>
                }
                <NwLinkButton onClick={() => onShowContactDrawer()} label={`View ${invoiceData.TypeName.toLowerCase()} details`} />
                <div className="addressNote">
                    <div>{invoiceData.AddressNote}</div>
                    <NwLinkButton
                        disabled={locked}
                        icon={light("message-lines")}
                        onClick={() => onShowEditDrawer()}
                        label={`${invoiceData.AddressNote ? "Edit" : "Add"} an Address Additional Text`}
                    />
                </div>
                <div>{`VAT # ${BillingInfo.VatNumber}`}</div>
                <div>{`Billing Type: ${BillingInfo.InvoiceType}`}</div>
                <div>{`Vat Code: ${BillingInfo.VatCode}`}</div>
                {projects.length > 0 &&
                    <div className="projects-list">
                        <div className="title">Projects</div>
                        {projects.map((project, index) => {
                            return (
                                <NwLinkButton
                                    key={index}
                                    onClick={() => handleOpenModelDrawer(project.ID)}
                                    label={project.Name} />
                            )
                        })}
                    </div>
                }
                <InvoiceCustomFields
                    invoice={invoiceData}
                    afterEditing={onSaveCustomFields}
                />
            </SiderContent>
            {projectPreviewID &&
                <ProjectDrawer
                    projectId={projectPreviewID}
                    onClose={handleClosePreviewDrawer}
                />
            }
            {showContactDrawer &&
                <CustomerDrawer customerID={invoiceData.ContactID} onClose={onCloseContactDrawer} />
            }
        </>
    )
}

export default InvoicePageSider;