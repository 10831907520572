import React, { useContext } from 'react';
import { HeaderContext } from 'Components/_LayoutComponents/Header/NwHeader';
import { NavMenu, NwNavIcon } from 'Components/_LayoutComponents/Header/NwHeaderUI';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { UserContext } from 'Contexts/UserContext';
import { Popover } from 'antd';
import { getNavClassName, getNavMenuMode } from './NwNavUtils';



const NwNavBarManagement = ({
    allLogs,
    createNewItemMenu,
    current,
    drawer,
    handleChangeOpenKeys,
    handleClick,
    isLoaded,
    isOpen,
    lastSeenMenu,
    openKeys,
}) => {

    const { currentUser } = useContext(UserContext);
    const userManagementAllowed = currentUser.UserProfile.UserManagementAllowed;

    const headerContextValues = useContext(HeaderContext);
    const { device } = headerContextValues;
    
    const navItems = [
        // { key: "/management", label: "Dashboard", disabled: !isLoaded },
        { key: "/management/models", label: "Models", disabled: !isLoaded },
        { key: "/management/customers", label: "Contacts", disabled: !isLoaded },
        {
            label: "Reports",
            popupClassName: "main-nav-submenu",
            disabled: !isLoaded,
            children: [
                { key: "/email-report", label: "E-mail Report", disabled: !isLoaded },
            ]
        },
        {
            label: "Settings & Tools",
            popupClassName: "main-nav-submenu",
            disabled: !isLoaded,
            children: [
                { key: "/management/structure", label: "Agencies / Departments management", disabled: !isLoaded },
                { key: "/management/website", label: "Website Management", disabled: !isLoaded },
                { key: "/management/configuration/tags", label: "Netwalk Configurations", disabled: !isLoaded },
                {
                    key: "/management/users",
                    disabled: !userManagementAllowed,
                    label: userManagementAllowed ? "User Management" :
                        <Popover
                            zIndex={1050}
                            content="Your current Netwalk profile is not allowed to access this section">
                            <div style={{ opacity: 0.4 }}>
                                <NwNavIcon icon={light('ban')} />&nbsp;User Management
                            </div>
                        </Popover>
                },
                {
                    key: "/management/user-policies/profiles",
                    disabled: !userManagementAllowed,
                    label: userManagementAllowed ? "User Policies" :
                        <Popover
                            zIndex={1050}
                            content="Your current Netwalk profile is not allowed to access this section">
                            <div style={{ opacity: 0.4 }}>
                                <NwNavIcon icon={light('ban')} />&nbsp;User Policies
                            </div>
                        </Popover>
                },
                { key: "/management/import/models", label: "Data Import", disabled: !isLoaded }
            ]
        }
    ]

    const keysWithSubmenus = navItems.filter(item => item.children).map(item => item.key);

    return (
        <NavMenu
            $device={device}
            className={getNavClassName(device, navItems.length)}
            inlineIndent={10}
            items={navItems}
            mode={getNavMenuMode(drawer, device)}
            onClick={handleClick}
            onOpenChange={(k) => handleChangeOpenKeys(k, keysWithSubmenus)}
            openKeys={openKeys}
            selectedKeys={[current]}
        />
    )
}

export default NwNavBarManagement;