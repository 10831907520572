import styled from 'styled-components'

export const ProjectTitleContainer = styled.div`
    cursor: ${props => props.$pointer ? 'pointer' : 'inherit'};
   
    div.first-line {
        display: flex;
        align-items: center;
        flex-direction: flex-start;
        flex-wrap: wrap;
        gap: 1rem;
        padding-left: .1rem;
        margin-bottom: .35rem;
        font-size: ${props => props.theme.FontSizeSm};
            
        p {
            font-size: ${props => props.theme.FontSizeSm};
            line-height: 1.5em;
            font-weight: 300;
            margin: 0;
        }
        code {
            padding: 0 .4em;
            font-size: 85%;
            background: rgba(150, 150, 150, .1);
            border: 1px solid rgba(100, 100, 100, .2);
            border-radius: 3px;
            line-height: 1.75em;
        }
    }
    
    h1 {
        font-size: ${props => props.$small ? '1.1rem' : '1.4rem'};
        line-height: 1.25;
        margin-bottom: .5rem;
        font-weight: 400;
        strong {
            font-weight: 600;
        }
        em {
            padding: 0 .35em;
            opacity: .5;
            font-weight: 100;
        }
    }

    p {
        margin: .25rem 0 0 0;
        font-size: ${props => props.$small ? '.75rem' : '1rem'};
        line-height: 1.1em;
        font-weight: 400;
    }
`

export const ProjectTitleCompactContainer = styled.div`
    cursor: ${props => props.$pointer ? 'pointer' : 'inherit'};
   
    h1 {
        font-size: ${props => props.theme.FontSizeBase};
        line-height: 1.15;
        margin-bottom: .25rem;
        font-weight: 400;
        
        strong {
            font-weight: 600;
        }
        
        em {
            padding: 0 .35em;
            opacity: .5;
            font-weight: 100;
        }
    }

    p {
        margin: .25rem 0 0 0;
        font-size: ${props => props.theme.FontSizeSm};
        line-height: 1.1em;
        font-weight: 400;
    }
`

export const ProjectTitlePopoverContainer = styled.div`
    
    p.first-line {
        font-size: ${props => props.theme.FontSizeXSm};
        line-height: 1.5em;
        padding-left: .1rem;
        font-weight: 300;
        margin-bottom: .15rem;
    }

    h1 {
        font-size: ${props => props.theme.FontSizeSm};
        line-height: 1.15;
        margin-bottom: .25rem;
        font-weight: 300;
        text-transform: uppercase;

        strong {
            font-weight: 300;
        }
        
        em {
            padding: 0 .35em;
            opacity: .5;
            font-weight: 100;
        }
    }

    p {
        font-size: ${props => props.theme.FontSizeXSm};
        line-height: 1.1em;
    }
`