import { useQuery, useMutation } from '@tanstack/react-query';
import Axios from 'axios';
import { queryClient } from 'QueryClientProvider';
import { forceReloadContact } from "Hooks/Contact/UseContact";

const getContactBankAccountsByID = ID => (
    Axios.get(`/contacts/bankaccounts/${ID}`)
)

const useContactBankAccounts = (ID) => {
    const intID = Number(ID)
    return useQuery(["contactbankaccounts", intID], () => getContactBankAccountsByID(intID), {
        staleTime: 10000,
        cacheTime: 10000,
        enabled: ID ? true : false
    })
}

const forceReloadContactBankAccounts= ID => {
    const intID = Number(ID)
    queryClient.invalidateQueries(['contactbankaccounts', intID]);
};

const editContactBankAccount = async ({data, bankId, contactID}) => (
    Axios.put(`/contactbankaccounts/${bankId}`, data)
)

const useEditContactBankAccount = () => {
    return useMutation(editContactBankAccount, { 
        onSuccess: (data, variables) => {
            onUpdateContactBankAccounts(variables.contactID)
        }
    })
}

const onUpdateContactBankAccounts = (ID) => {
    forceReloadContactBankAccounts(ID)
    forceReloadContact(ID)
}

const addContactBankAccount = ({data, contactID}) => (
    Axios.post(`/contactbankaccounts`, data)
)

const useAddContactBankAccount = () => {
    return useMutation(addContactBankAccount, {
        onSuccess: (data, variables) => {
            onUpdateContactBankAccounts(variables.contactID)
        }
    })
}

const removeContactBankAccount = async ({ID, contactID}) => (
    Axios.delete(`/contactbankaccounts/${ID}`)
)

const useRemoveContactBankAccount = () => {
    return useMutation(removeContactBankAccount, {
        onSuccess: (data, variables) => {
            onUpdateContactBankAccounts(variables.contactID)
        }
    })
}

export {
    useContactBankAccounts,
    forceReloadContactBankAccounts,
    useAddContactBankAccount,
    useEditContactBankAccount,
    useRemoveContactBankAccount
}
