import React, { useContext } from "react";
import { getMoment } from "Libs/NwMoment";
import * as Yup from "yup";

import EditEvent from "Components/EventDrawer/EditEvent";
import {
    NWContactDropdown,
    NWFollowUpLabel,
    NWTextAreaCharCounter,
    NWProjectSelector,
    NWInput,
    NWPeriodSelector,
    NWSwitch,
    NWLongEventPeriodPicker
} from "Components/Gui/NwForm/NwFormItems";
import { ListContext } from 'Contexts/ListContext';
import {
    useUpdateFollowup,
    useAddFollowup,
    useUpdateGroupedIndividualFollowUp,
    useUpdateGroupedCommonFollowUp
} from 'Hooks/Event/UseFollowup'
import { validatePeriod } from 'Libs/NwUtils';
import { getNow, getTime, isAllDay } from "Libs/NwMoment";
import { getContactName } from "Hooks/Contact/UseContactsList";

const FollowUpForm = ({
    afterUpdate,
    allDay,
    dateSelected,
    editModelEvent,
    modelId,
    onCancel,
    onTouchForm,
    onUpdateFollowupData,
    projectId,
    followUpData,
    viewMode,
    eventInfo,
    
    
}) => {
    const { cachedList } = useContext(ListContext);
    const followUpLabels = cachedList.followUpLabels.items || [];
    const followUpLabelCode = eventInfo ? eventInfo.followUpLabelCode : '';
    const defaultFollowUp = followUpLabelCode ? followUpLabels.find(followupLabel => followupLabel.Code === followUpLabelCode)
        : followUpLabels.find(followupLabel => followupLabel.Default);
    const { mutateAsync: onAddFollowup } = useAddFollowup();
    const { mutateAsync: onUpdateFollowup } = useUpdateFollowup();
    const { mutateAsync: onUpdateGroupedIndividualFollowUp } = useUpdateGroupedIndividualFollowUp();
    const { mutateAsync: onUpdateGroupedCommonFollowUp } = useUpdateGroupedCommonFollowUp();

    const submitForm = async (values, setStatus, setSubmitting) => {
        const newFollowUpData = {
            ...initialValues,
            ...values,
            StartDate: editModelEvent ? values.Period.startDate
                : getMoment(values.Period.startDate).hour(values.Period.startTime[0]).minute(values.Period.startTime[1]),
            EndDate: editModelEvent ? values.Period.endDate
                : getMoment(values.Period.endDate).hour(values.Period.endTime[0]).minute(values.Period.endTime[1]),
        };
        if (!followUpData) {
            try {
                const response = await onAddFollowup({ followupData: newFollowUpData });
                onTouchForm(false);
                if (afterUpdate) {
                    afterUpdate({
                        ...response,
                        TypeName: "FollowUp"
                    });
                }
                onUpdateFollowupData(response.ID);
                onCancel();
            } catch (error) {
                console.log('ant : Add ToDo Error => ', error);
            }
            setSubmitting(false);
        } else {
            newFollowUpData.ID = followUpData.ID;
            try {
                if (followUpData.GroupGuid) {
                    if (editModelEvent) {
                        newFollowUpData.ChangeOnAllModels = values.ChangeOnAllModels;
                        await onUpdateGroupedIndividualFollowUp({ followupData: newFollowUpData });
                    } else {
                        newFollowUpData.GroupGuid = followUpData.GroupGuid;
                        delete newFollowUpData.ID;
                        await onUpdateGroupedCommonFollowUp({ followupData: newFollowUpData });
                    }
                } else {
                    await onUpdateFollowup({ followupData: newFollowUpData });
                }
                if (afterUpdate) {
                    afterUpdate();
                }
                onTouchForm(false);
                onUpdateFollowupData();
                if (!viewMode || viewMode === 'create') {
                    onCancel();
                }
            } catch (error) {
                console.log('ant : Update Followup Error => ', error);
            }
            setSubmitting(false);
        }
    };

    let initialValues = {
        Acknowledgment: followUpData ? followUpData.Acknowledgment : 'NotChecked',
        AcknowledgmentName: followUpData ? followUpData.AcknowledgmentName : '',
        ModelID: followUpData ? followUpData.ModelID : modelId,
        ProjectID: followUpData && followUpData.Project ? followUpData.Project.ID : projectId,
        ServiceID: followUpData ? followUpData.ServiceID : null,
        Description: followUpData ? followUpData.Description : '',
        StartDate: followUpData ? getMoment(followUpData.StartDate) : null,
        EndDate: followUpData ? getMoment(followUpData.EndDate) : null,
        FollowUpLabelCode: followUpData ?
            followUpData.FollowUpLabelCode :
            followUpLabelCode ? followUpLabelCode :
                defaultFollowUp ? defaultFollowUp.Code : '',
        ChangeOnAllModels: true,
        Title: followUpData ? followUpData.Title : '',
        Period: {
            startDate: followUpData ? getMoment(followUpData.StartDate) :
                dateSelected ? getMoment(dateSelected).startOf('day')
                    : getNow(true),
            endDate: followUpData ? getMoment(followUpData.EndDate) :
                dateSelected ? getMoment(dateSelected).startOf('day')
                    : getNow(true),
            startTime: getTime(followUpData ? getMoment(followUpData.StartDate) :
                dateSelected ? allDay ? getMoment(dateSelected).startOf('day')
                    : dateSelected : getNow().hour(9).minute(0)),
            endTime: getTime(followUpData ? getMoment(followUpData.EndDate) :
                dateSelected ? allDay ? getMoment(dateSelected).endOf('day')
                    : dateSelected.clone().add(2, 'hours') : getNow().hour(11).minute(0)),
            allDay: followUpData ? isAllDay(getMoment(followUpData.StartDate), getMoment(followUpData.EndDate)) : allDay || false,
            dates: []
        },
        ModelNotAvailable: followUpData ? followUpData.ModelNotAvailable : defaultFollowUp ? defaultFollowUp.ModelNotAvailable : true
    };

    let generalDataSource = [
        {
            component: NWFollowUpLabel,
            label: 'Activity Type',
            name: 'FollowUpLabelCode',
            type: '',
            onAfterChange: (values, setFieldValue, value) => {
                const followup = followUpLabels.find(item => item.Code === value);
                if (!values.Title)
                    setFieldValue('Title', `${followup ? followup.Label : ''}${values.ServiceID ? ` - ${getContactName(values.ServiceID)}` : ''}`)
                setFieldValue('ModelNotAvailable', followup && followup.ModelNotAvailable);
            },
        },
        {
            component: NWContactDropdown,
            label: "Service",
            activeTypes: ["srv"],
            activableTypes: [],
            name: "ServiceID",
            onAfterChange: (values, setFieldValue, value) => {
                if (!values.Title) {
                    const followup = followUpLabels.find(item => item.Code === values.FollowUpLabelCode);
                    setFieldValue('Title', `${followup.Label} - ${getContactName(value)}`);
                }
            },
        },
        {
            component: NWLongEventPeriodPicker,
            label: 'Period',
            name: 'Period',
            Col: 24
        },
        {
            component: NWInput,
            label: 'Title',
            name: 'Title',
            type: 'text',
            Col: 24
        },
    ];

    let modelDataSource = [
        {
            component: NWTextAreaCharCounter,
            label: 'Description',
            name: 'Description',
            type: 'text',
            maxLength: 2000,
            Col: 24
        }
    ];

    let dataSource = editModelEvent ? [...modelDataSource] : followUpData && followUpData.GroupGuid ? [...generalDataSource] : [...generalDataSource, ...modelDataSource];

    if (!followUpData) {
        dataSource.splice(0, 0, {
            component: NWProjectSelector,
            name: 'ProjectID',
            readOnly: projectId ? true : false,
            Col: 24
        });
    }

    if (!modelId) {
        dataSource.splice(1, 0, {
            component: NWContactDropdown,
            label: "Model",
            activeTypes: ["mod"],
            activableTypes: [],
            name: "ModelID",
            required: true,
            Col: 24
        });
    }

    if (!editModelEvent) {
        dataSource.push({
            component: NWSwitch,
            text: 'set model as not available',
            name: 'ModelNotAvailable',
            required: true,
            Col: 24
        });
    } else {
        dataSource.push({
            component: NWPeriodSelector,
            label: 'Time',
            name: 'Period',
            Col: 24,
            validate: validatePeriod,
            timeOnly: true
        });
        dataSource.push({
            component: NWSwitch,
            text: 'apply these changes on all models in this travel',
            name: 'ChangeOnAllModels',
            Col: 24
        });
    }

    let validationSchema = editModelEvent ? null : Yup.object().shape({
        Title: Yup.string().required("Title is required"),
        ModelID: Yup.string().required("Model is required"),
        FollowUpLabelCode: Yup.string().required("Follow up label is required"),
    });

    return (
        <EditEvent
            submitForm={submitForm}
            dataSource={dataSource}
            mg={followUpData && '80px 0 0'}
            initialValues={initialValues}
            onCancel={onCancel}
            disableReinitialize
            onTouchForm={onTouchForm}
            validationSchema={validationSchema} />
    )
};

export default React.memo(FollowUpForm);
