import { useQuery, useMutation } from '@tanstack/react-query';
import Axios from 'axios';

import { queryClient } from 'QueryClientProvider';
import { forceReloadContact } from "Hooks/Contact/UseContact";

const onContactMediaUpdate = async (ID, callback) => {
    forceReloadContact(ID)
    forceReloadContactDocuments(ID)
    callback && callback()
};

const onDeleteMediaList = ({ documentIDs }) => {
    let idQuery = '';
    documentIDs.map((documentID, index) => {
        idQuery += `ids=${documentID}`;
        if (index < documentIDs.length - 1) {
            idQuery += '&';
        }
        return null;
    });
    return Axios.delete(`/documents?${idQuery}`);
}

const useDeleteMediaList = () => {
    return useMutation(onDeleteMediaList, {
        onSuccess: (data, variables) => {
            //const { documentIDs } = variables;
            // const documents = queryClient.getQueryData(['contactDocuments', variables.contactID]);
            // queryClient.setQueryData(['contactDocuments', variables.contactID], 
            //     documents ? documents.filter(document => !documentIDs.find(documentID => documentID === document.ID)) : []);
            //forceReloadContactDocuments(variables.contactID)
        }
    })
};

const getPicturesAndVideos = async contactID => (
    Axios.get(`/contacts/getpicturesandvideos/${contactID}`)
);

const useContactPicturesAndVideos = contactID => {
    const intID = Number(contactID)
    return (
        useQuery(["contactDocuments", intID], () => getPicturesAndVideos(intID), {
            staleTime: 60000,
            cacheTime: 60000,
            enabled: contactID ? true : false
        })
    )
}

const forceReloadContactDocuments = contactID => {
    const intID = Number(contactID)
    queryClient.invalidateQueries(['contactDocuments', intID]);
}

export {
    onContactMediaUpdate,
    useContactPicturesAndVideos,
    useDeleteMediaList,
    forceReloadContactDocuments
}