import React from "react";
import styled from "styled-components";
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/SelectorContext";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import NwIcon from "Components/Gui/NwIcon";

const Wrapper = styled.div`
    display: flex;
    font-size: 0.8em;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    .texts-wrapper {
        flex: 1 1 0;
        .label {
            font-weight: bold;
        }
        .value {
            padding-left: 1rem;
            span {
                display: block;
            }
        }
    }
    .remove-button-wrapper {
        font-size: 1rem;
    }
`;


const ModelAgencySelectedFilterItems = ({ }) => {

    const { selection } = useContactsSelector()
    const cs_dispatch = useContactsSelectorDispatch()
    const { filters } = selection

    const removeAllAgencyFiltersFromWorkingFilters = () => {
        const newWorkingFilters = filters.active.filter(f => f.area !== "agency");
        cs_dispatch({ type: 'ChangeFilters', filters: newWorkingFilters })
        cs_dispatch({ type: 'ApplyWorkingFilters' })
    };

    const removeRepresentedFilterFromWorkingFilters = () => {
        const newWorkingFilters = filters.active.filter(f => f.name !== "Represented");
        cs_dispatch({ type: 'ChangeFilters', filters: newWorkingFilters })
        cs_dispatch({ type: 'ApplyWorkingFilters' })
    };

    let agencytext = "";
    const agencynotin = filters.active.find(f => f.name === "AgencyNotIn")
    const agencyid = filters.active.find(f => f.name === "AgencyID")
    const agencytype = filters.active.find(f => f.name === "AgencyType")
    const represented = filters.active.find(f => f.name === "Represented")

    const AgencyText = ({ }) => {
        if (agencytype || agencyid) {
            if (agencynotin && agencynotin.value) {
                agencytext = "Models not linked to "
            } else {
                agencytext = "Models linked to "
            }
            if (agencyid && agencyid.value) {
                agencytext += getContactName(agencyid.value)
            } else {
                agencytext += " any agency"
            }
            if (agencytype && agencytype.value) {
                agencytext += ` as ${agencytype.value}`
            }
            return (
                <Wrapper>
                    <div className="texts-wrapper">
                        <div className="label">Agencies</div>
                        <div className="value">{agencytext}</div>
                    </div>
                    <div className="remove-button-wrapper">
                        <NwIcon pointer icon={light("times")} onClick={removeAllAgencyFiltersFromWorkingFilters} />
                    </div>
                </Wrapper>
            )
        }
        return (<></>)
    }

    const RepresentedText = ({ }) => {
        if (represented) {
            return (
                <Wrapper>
                    <div className="texts-wrapper">
                        <div className="label">Represented</div>
                        <div className="value">{represented.value ? "yes" : "no"}</div>
                    </div>
                    <div className="remove-button-wrapper">
                        <NwIcon pointer icon={light("times")} onClick={removeRepresentedFilterFromWorkingFilters} />
                    </div>
                </Wrapper>
            )
        }
        return (<></>)
    };

    return (
        <>
            <AgencyText />
            <RepresentedText />
        </>
    )
}

export default ModelAgencySelectedFilterItems;
