import React from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';

const ActionContent = styled.div`
    opacity: ${props => props.permission ? 1 : 0.4};
    cursor: ${props => props.permission ? 'pointer' : 'not-allowed !important' };

    div, svg, p, a {
        cursor: ${props => props.permission ? 'pointer' : 'not-allowed !important' };
    }
`;

const PermissionBlock = ({ content, permission, placement }) => {
    return permission ? content
        : <Popover
            placement={placement ? placement : 'bottom'}
            content={
                <FlexContainer>
                    <NwIcon icon={light('lock-alt')} />&nbsp;
                    <div>Your current Netwalk profile is not allowed to access this section</div>
                </FlexContainer>
            }>
            <ActionContent permission={permission}>
                {content}
            </ActionContent>
        </Popover>
};

export default PermissionBlock;