import React, { useState } from 'react';
import styled from 'styled-components';
//import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import ContactDetailsHeaderStatement from "Components/ContactDetails/CommonComponents/ContactDetailsHeaderStatement";
import FullFeatures from "Components/Features/FullFeatures";
import ContactDetailsActions from 'Components/ContactDetails/ContactDetailsHeader/ContactDetailsActions';
import PermissionBlock from 'Components/PermissionBlock/PermissionBlock';
import ObjectHistory from 'Components/CreatedBy/ObjectHistory';
import { useUserPermission } from 'Contexts/UserContext';
import NwDrawer from 'Components/Gui/NwDrawer';
import ObjectLogHistories from 'Components/ObjectLogHistories/ObjectLogHistories';
// import NwIcon from 'Components/Gui/NwIcon';
// import PermissionBlock from 'Components/PermissionBlock';
// import { useUserPermission } from 'Contexts/UserContext';

const Actions = styled.div`
    display: flex;
    align-items: center;
    margin-right: 0.5rem;

    svg {
        font-size: 1.3rem;
        margin: 0 .75rem;
        cursor: pointer;
    }
`;

const ModelDetailsHeaderActions = ({
    currentContact,
    disableStatement,
    onOpenStatements
}) => {

    const [isOpenHistory, setIsOpenHistory] = useState(false);
    const LogsOnContacts = useUserPermission('LogsOnContacts', currentContact && currentContact.Users.map(user => user.UserID));
    

    const handleOpenHistory = () => {
        setIsOpenHistory(true);
    };

    const handleCloseHistory = () => {
        setIsOpenHistory(false);
    };

    return (
        <>
            <Actions>
                <FullFeatures
                    iconSize="lg"
                    hideIfEmpty
                    currentContact={currentContact}
                />
                {!disableStatement &&
                    <ContactDetailsHeaderStatement
                        contact={currentContact}
                        type='model'
                        iconSize="lg"
                        onOpenStatements={onOpenStatements}
                    />
                }
                <ContactDetailsActions
                    contact={currentContact}
                />
                <PermissionBlock
                    permission={LogsOnContacts}
                    content={
                        <ObjectHistory onIconClick={handleOpenHistory} iconOnly object={currentContact} />
                    }
                />
            </Actions>
            {isOpenHistory && (
                <NwDrawer title="Model Logs" onClose={handleCloseHistory}>
                    <ObjectLogHistories
                        objectId={currentContact.ID}
                        type="model"
                    />
                </NwDrawer>
            )}
        </>
    );
};

export default ModelDetailsHeaderActions;