import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Axios from "axios";
import { getMoment } from "Libs/NwMoment";
import ModelsTableColumnEventDate from "./ModelsTableColumnEventDate";
import CalendarIconDate from "Components/Dates/CalendarIconDate";
import ModelsTableColumnNonBookingEvent from "./ModelsTableColumnNonBookingEvent";
import NwIcon from "Components/Gui/NwIcon";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import NwDividerVertical from "Components/Gui/NwDividerVertical";
import { useContactsSelector } from "Components/ContactsSelector/Common/SelectorContext";


const DatesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const VerticalDateButton = styled.div`
    cursor: ${props => props.inEvent ? 'not-allowed' : 'pointer'};
    opacity: ${props => props.inEvent ? '.5' : '1'};
    margin: 0 .5rem .5rem 0;
`
const EllipsisContainer = styled.div`
    position: absolute;
    top: 1.25em;
    left: 3.5em;
    
    svg {
        font-size: 1.5rem;
        --fa-primary-color: white;
        --fa-secondary-color: ${props => props.theme.DarkerBorderColor};
        --fa-primary-opacity: 1;
        --fa-secondary-opacity: 1;
        cursor: pointer;
    }
`

const ModelsTableColumnEvent = ({
    addModelsAs,
    availability,
    isFetchingAvailability,
    model,
}) => {

    const { context } = useContactsSelector();
    const { currentEvent, scope } = context;

    const [expandDates, setExpandDates] = useState(false);
    const [eventsAvailability, setEventsAvailability] = useState();

    const isInConflict = (event, eventDate) => {
        const firstDate = getMoment(event.FromDate).startOf('day');
        const lastDate = getMoment(event.ToDate).endOf('day');
        return firstDate.isSameOrBefore(eventDate.ToDate) && lastDate.isSameOrAfter(eventDate.FromDate)
    }

    const datesToAdd = currentEvent.EventDates.filter(ed => ed.EventDatesModels.every(edm => Number(edm.ModelID) !== Number(model.ID))).map(ed => ed.ID)

    useEffect(() => {
        if (expandDates) {
            let newValues = {
                FDate: currentEvent.EventDates[0].FromDate,
                TDate: currentEvent.EventDates[currentEvent.EventDates.length - 1].ToDate,
                idmodels: [model.ID],
                inclJobs: true,
                inclCastings: true,
                checkConflict: true,
                inclTravels: true,
                inclAccommodations: true,
                inclExternalJobs: true,
                inclFollowUps: true,
                inclMeetings: true,
                inclNotices: true,
                General: false,
                Warning: false
            };
            Axios.post("/calendar/getevents", newValues).then(response => {
                let eventAvailability = {}
                const responseData = response
                eventAvailability = []
                for (const ed of currentEvent.EventDates) {
                    const dateEvents = responseData.Events.filter(event => event.ID !== currentEvent.ID && isInConflict(event, ed));
                    const modelDateAvailability = {
                        inEvent: false,
                        available: dateEvents.length > 0 ? false : true,
                        otherEvents: [...dateEvents]
                    }
                    if (ed.EventDatesModels.some(edm => Number(edm.ModelID) === Number(model.ID))) {
                        modelDateAvailability.inEvent = true;
                        modelDateAvailability.available = false;
                    }
                    eventAvailability.push(modelDateAvailability);
                }
                setEventsAvailability(eventAvailability);
            }).catch(error => {
                console.log("error", error);
            });
        }
    }, [expandDates, currentEvent]);

    const isModelInEvent = useMemo(() => {
        return currentEvent.EventDates.some(ed => ed.EventDatesModels.some(edm => Number(edm.ModelID) === Number(model.ID)));
    }, [currentEvent, model]);


    return (
        scope === "event"
            ?
            // column with dates for adding models to a booking event
            <DatesContainer>
                {(currentEvent && currentEvent.EventDates && currentEvent.EventDates.length === 1)
                    ?
                    <ModelsTableColumnEventDate
                        eventDate={currentEvent.EventDates[0]}
                        model={model}
                        availability={availability}
                        isFetchingAvailability={isFetchingAvailability}
                        currentEvent={currentEvent}
                        addModelsAs={addModelsAs}
                    />
                    :
                    ((expandDates && eventsAvailability)
                        ?
                        <>
                            {currentEvent.EventDates.map((ed, i) => (
                                <ModelsTableColumnEventDate
                                    key={ed.ID}
                                    eventDate={ed}
                                    model={model}
                                    availability={eventsAvailability[i]}
                                    currentEvent={currentEvent}
                                    addModelsAs={addModelsAs}
                                />
                            ))}
                            {datesToAdd.length > 0 &&
                                <>
                                    <NwDividerVertical style={{ height: "4em", paddingRight: "0.5em", top: "0.5em" }} />
                                    <ModelsTableColumnEventDate
                                        model={model}
                                        currentEvent={currentEvent}
                                        addModelsAs={addModelsAs}
                                    />
                                </>
                            }
                        </>
                        :
                        <VerticalDateButton
                            available={true}
                            inEvent={false}
                            onClick={() => setExpandDates(true)}
                        >
                            <CalendarIconDate
                                date={currentEvent.EventDates[0].FromDate}
                                stacked
                            />
                            {isModelInEvent &&
                                <EllipsisContainer>
                                    <NwIcon icon={duotone("circle-ellipsis")} />
                                </EllipsisContainer>
                            }
                        </VerticalDateButton>
                    )
                }
            </DatesContainer>
            :
            // column with buttons to add models to a non-booking event
            <DatesContainer>
                <ModelsTableColumnNonBookingEvent
                    model={model}
                    availability={availability}
                    currentEvent={currentEvent}
                />
            </DatesContainer>
    )
};

export default ModelsTableColumnEvent;
