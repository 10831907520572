import React, { useState, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { Rate, List } from 'antd';
import { getMoment } from "Libs/NwMoment";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { Loading } from 'Components/Gui/Loading';
import MiniLoading from 'Components/Gui/MiniLoading';
import { StyledRateContainer } from 'Components/Gui/NwForm/Rating';
import UserIcon from "Components/UserIcon/UserIcon";
import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import { ListContext } from 'Contexts/ListContext';
import useAreaAccentColor from 'Hooks/UseAreaAccentColor';
import { useRating, useUpdateRating } from 'Hooks/Rating/UseRating';
import { showLog } from 'Libs/NwMoment';

const Container = styled.div`
    display: flex;
    
    .col {
        flex: 1;
        padding: 0 1em 1em;
    }

    .ratings {
        border-right: 1px solid ${props => props.theme.LighterBorderColor};
    }

    .ant-list-item {
        cursor: pointer;
    }
`;

const RatingContainer = styled(StyledRateContainer)`
    margin-bottom: 1rem;
`;

const ModelRating = ({ modelId }) => {
    const ratingColor = useAreaAccentColor();
    const { data: rating } = useRating(modelId, 'Contact');
    const { mutateAsync: onUpdateRating } = useUpdateRating();
    const { cachedList, cachedListLoaded } = useContext(ListContext);
    const [loading, setLoading] = useState(false);

    const getUserInfoByUserId = (userId) =>  {
        let user = null;
        let userName = "";
        let userCoverGuid = "";
        let letters = "";
        if (cachedListLoaded) {
            user = cachedList.users.items.find(usr => usr.ID === userId);
            if (user) {
                userName = user.Name + " " + user.Surname;
                userCoverGuid = user.CoverGuid;
                letters = user.Name.toUpperCase()[0] + " " + user.Surname.toUpperCase()[0]
            } else {
                userName = "Utente Farlocco";
                letters = "UF"
            }
        }
        return {userName, userCoverGuid, letters, userId};
    };

    const handleUserRate = async rate => {
        const data = {
            ContactID: modelId,
            Rating: rate
        };

        setLoading(true);
        try {
            await onUpdateRating({ data: data, type: 'Contact', ID: modelId });
            setLoading(false);
        } catch (error) {
            console.log('ant : User Rate Error => ', error);
        }
    };

    const ratingHistories = useMemo(() => {
        let ratingHistories = [];
        if (rating && rating.ratings) {
            ratingHistories = rating.ratings.map(rfi => ({
                ...rfi,
                key: rfi.ID,
                createdBy: getUserInfoByUserId(rfi.UserCreated),
                dateCreated: showLog(rfi.DateModified || rfi.DateCreated)
        
            }));
        }
        return ratingHistories;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rating]);

    return (
        <NwCard title="Ratings" icon={light("star")}>
            {rating ?
                <Container>
                    <div className='col ratings'>
                        <div>Average Rating</div>
                        <RatingContainer ratingColor={ratingColor}>
                            <Rate
                                disabled
                                style={{fontSize:"16px"}}
                                value={rating.averageRating}
                            />
                        </RatingContainer>
                        <div>Your Rating</div>
                        <RatingContainer ratingColor={ratingColor}>
                            <Rate
                                style={{fontSize:"16px", marginRight: '0.5rem'}}
                                disabled={loading}
                                value={rating.currentUserRating}
                                onChange={handleUserRate}
                            />
                            {loading && <MiniLoading />}
                        </RatingContainer>
                        {rating.currentUserRatingDate && 
                            <>
                                <div><NwIcon className='icon' icon={light('history')} size='1x' /> Last Updated On</div>
                                <div>{showLog(rating.currentUserRatingDate)}</div>
                            </>
                        }
                    </div>
                    <div className='col'>
                        <List
                            itemLayout="horizontal"
                            dataSource={ratingHistories}
                            renderItem={rating => {
                                const { createdBy, dateCreated, Rating } = rating;    
                                return (
                                    <List.Item className="ant-list-item-top">
                                        <FlexContainer justifyContent='space-between' fullWidth>
                                            <FlexContainer>
                                                <UserIcon userId={createdBy.userId} coverGuid={createdBy.userCoverGuid} letters={createdBy.letters} size="medium" />
                                                <FlexContainer column alignItems='flex-start' mg='0 16px'>
                                                    <div>{createdBy.userName}</div>
                                                    <div>{dateCreated}</div>
                                                </FlexContainer>
                                            </FlexContainer>
                                            <StyledRateContainer ratingColor={ratingColor}>
                                                <Rate
                                                    disabled
                                                    style={{fontSize:"16px"}}
                                                    value={Rating}
                                                />
                                            </StyledRateContainer>
                                        </FlexContainer>
                                    </List.Item>
                                )}
                            }
                        />
                    </div>
                </Container>
                : <Loading />
            }
        </NwCard>
    );
};

export default ModelRating;