import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Axios from 'axios';

import { NWTextAreaCharCounter } from 'Components/Gui/NwForm/NwFormItems';
import { NwCancelButton, NwSaveButton, NwRemoveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import NwModal from 'Components/Gui/NwModal';

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoteModal = ({ isShowModal, onClose, packageModel }) => {
    
    const [touchForm, setTouchForm] = useState(false);
    let initialValues = {
        Text: "",
    };
    
    if (packageModel.Note) {
        initialValues.Text = packageModel.Note;
    }

    const handleSaveNote = (text, setSubmitting) => {
        setSubmitting(true);
        Axios.put('/PackageModels/EditNote', {
            packageModelID: packageModel.ID,
            Note: text
        })
        .then((response) => {
            setTouchForm(false);
            setSubmitting(false);
            onClose(true, text);
        })
        .catch(error => {
            //TODO: manage error on api call, may be by adding an ErrorMessage to the state and a condition to show it in render.
            console.log("error", error);
            setSubmitting(false);
            onClose(false);
        });
    }

    const handleDeleteNote = () => {
        Axios.put('/PackageModels/EditNote', {
            packageModelID: packageModel.ID,
            Note: ''
        })
        .then((response) => {
            setTouchForm(false);
            onClose(true, null);
        })
        .catch(error => {
            //TODO: manage error on api call, may be by adding an ErrorMessage to the state and a condition to show it in render.
            console.log("error", error);
            onClose(false);
        });
    }

    return (
        <NwModal
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            title={
                <ModalHeader>
                    <Typography>Package Model Note</Typography>
                    {packageModel && packageModel.Note && <NwRemoveButton ghost style={{marginRight: '32px'}} danger iconOnly size="small" onClick={handleDeleteNote} />}
                </ModalHeader>
            }
            visible={isShowModal}
            footer={null}
            onCancel={() => {
                onClose()
            }}>   
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Text: Yup.string().required("Content is required")
                })}
                onSubmit={({ Text }, { setStatus, setSubmitting }) => {
                    setStatus();
                    handleSaveNote(Text, setSubmitting);
                }}
                render={({
                    isSubmitting,
                    values,
                    handleSubmit
                }) => (
                <NwForm 
                    values={values}
                    onTouchForm={setTouchForm}
                    onFinish={handleSubmit} 
                    layout="vertical">
                    <Field
                        component={NWTextAreaCharCounter}
                        label="Note"
                        name="Text"
                        type="text"
                        value={values.Text}
                        maxLength={2000}
                        autoSize={{ minRows: 5, maxRows: 10 }}
                    />  
                    <div className="form-group">
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </div>
                </NwForm>
                )}
            />      
        </NwModal>
    )
};

export default NoteModal;