import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import ModelDetailsHeaderFeatures from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsHeaderFeatures";
import ModelDetailsHeaderActions from 'Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsHeaderActions';
import NwIcon from 'Components/Gui/NwIcon';
import PermissionBlock from 'Components/PermissionBlock/PermissionBlock';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { useFeatures } from 'Hooks/Contact/UseContactFeatures';
import { useUserPermission } from 'Contexts/UserContext';

const ModelDetailsHeaderFooter = ({
    currentContact,
    disableStatement,
    onOpenStatements,
    hideActions
}) => {

    const navigate = useNavigate();
    const ViewModelFeatures = useUserPermission('ViewModelFeatures', currentContact && currentContact.Users.map(user => user.UserID));
    const features = currentContact.FeatNwMain || currentContact.F
    const filteredFeatures = useFeatures(features ? JSON.parse(features) : null);

    const handleMoveSimilarModels = () => {
        navigate(`/models/actions/find-similar/${currentContact.ID}`)
    };

    return (
        <div className="header-footer">
            <div className="features-container">
                <ModelDetailsHeaderFeatures
                    contact={currentContact}
                    hasFeatureFilter
                />&nbsp;&nbsp;
                {filteredFeatures.length > 0 &&
                    <div>
                        {ViewModelFeatures ?
                            <Popover content={`Find models similar to ${getContactName(currentContact.ID)}`} onClick={handleMoveSimilarModels}>
                                <NwIcon icon={light('people-arrows')} pointer size='lg' />
                            </Popover>
                            :
                            <PermissionBlock
                                permission={ViewModelFeatures}
                                content={<NwIcon icon={light('people-arrows')} pointer size='lg' />}
                            />
                        }
                    </div>
                }
            </div>
            {!hideActions &&
                <ModelDetailsHeaderActions
                    currentContact={currentContact}
                    disableStatement={disableStatement}
                    onOpenStatements={onOpenStatements} />
            }
        </div>
    );
};

export default ModelDetailsHeaderFooter;