import React from "react";
import { Formik, Field } from "formik";
import { getMoment } from "Libs/NwMoment";
import styled from "styled-components";
import { NwButton } from "Components/Gui/NwButton";
import {
  NWDatePicker,
  NWInput,
  NWTextAreaCharCounter,
  NWContactDropdown,
  NWVisibilitySelector
} from "Components/Gui/NwForm/NwFormItems";
import { NwForm } from 'Components/Gui/NwForm/NwFormWrapper';
import { validateVisibility } from "Libs/NwUtils";
import { useStateValue } from 'Contexts/StateProvider';
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';
import Axios from "axios";
import { onContactMediaUpdate } from "Hooks/Contact/UseContactMedia";
import { datePickerBeforeOnChange } from "Libs/NwMoment";
import { Alert, notification } from "antd";
import NwIcon from "Components/Gui/NwIcon";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const Wrapper = styled.div`
`;

const MultiMediaInfoForm = ({ mediaList, contactID, onTouchForm, onSave }) => {
    useFormFieldFocus('Title')
    const [{ areaContext }] = useStateValue()
    const initialValues = {
        Title: '',
        ShootingDate: null,
        Location: '',
        Notes: '',
        Author: '',
        PhotographerID: null,
        PublicDescription: '',
        Visibility: {
            isGlobal: true,
            Areas: []
        }
    }

    const handleSaveMediaDetail = async (values, setSubmitting) => {
        setSubmitting(true)
        const updatedMediaInfo = {
            ...values,
            VisibilityAreas: values.Visibility.Areas,
            DocumentsToUpdate: mediaList.map(media => media.ID)
        }
        try {
            await Axios.put(`/documents/updatemetadata`, updatedMediaInfo)
            onContactMediaUpdate(contactID)
            onTouchForm(false)
            setSubmitting(false)
            onSave()
        } catch (error) {
            notification.error({
                duration: 0,
                message: 'Error',
                description: `Error updating media info: ${error.message}`,
                icon: <NwIcon icon={solid('exclamation-circle')} />
            });
            setSubmitting(false)
        }
    }

    return (
        <Wrapper>
            <Alert message="Save & Overwrite: Previous data will be replaced, and empty fields will erase existing records." type="error" showIcon />
            <br/>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    handleSaveMediaDetail(values, setSubmitting)
                }}
            >
                {({ isSubmitting, values, handleSubmit }) => (
                    <NwForm
                        values={values}
                        onTouchForm={onTouchForm}
                        onFinish={handleSubmit}
                        layout="vertical">
                        <Field
                            component={NWInput}
                            label="Title"
                            name="Title"
                            type="text"
                            value={values.Title}
                            id='Title'
                        />
                        <Field
                            component={NWInput}
                            label="Location"
                            name="Location"
                            type="text"
                            value={values.Location}
                        />
                        <Field
                            component={NWInput}
                            label="Author"
                            name="Author"
                            type="text"
                            value={values.Author}
                        />
                        <Field
                            component={NWDatePicker}
                            label="Shooting date"
                            name="ShootingDate"
                            format="MMM DD, YYYY"
                            getCalendarContainer={triggerNode => triggerNode.parentNode}
                            style={{ width: "100%" }}
                            value={values.ShootingDate ? getMoment(values.ShootingDate) : null}
                            manipulateValue={datePickerBeforeOnChange}
                        />
                        <Field
                            component={NWContactDropdown}
                            label="Photographer"
                            name="PhotographerID"
                            activeTypes={["srv", 'cli']}
                            // activableTypes={['cli', 'srv']}
                            value={values.PhotographerID}
                        />
                        <Field
                            component={NWInput}
                            label="Public Description (credits)"
                            name="PublicDescription"
                            type="text"
                            value={values.PublicDescription}
                        />
                        <Field
                            component={NWTextAreaCharCounter}
                            label="Notes"
                            name="Notes"
                            value={values.Notes}
                            maxLength={2000}
                            type="text"
                            autoSize={{ minRows: 2, maxRows: 5 }}
                        />
                        <Field
                            component={NWVisibilitySelector}
                            label="Visibility"
                            inlineLabel
                            name="Visibility"
                            value={values.Visibility}
                            validate={validateVisibility(values.Visibility, areaContext.area)}
                        />
                        <br />
                        {/* <Button disabled={isSubmitting} onClick={() => onCancel(null)}>
                        Cancel
                    </Button> */}
                        &nbsp;
                        <NwButton
                            htmlType="submit"
                            primary
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            label="Save"
                            loadingLabel="Updating..."
                        />
                    </NwForm>
                )}
            </Formik>
        </Wrapper>
    );
};

export default MultiMediaInfoForm;
