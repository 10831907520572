import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { NwButton } from 'Components/Gui/NwButton';
import StyleVariables from 'Components/Gui/StyleVariables';
import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import EmptyState from 'Components/Gui/EmptyState';
import CenteredBlock from 'Components/Gui/CenteredBlock';
import { getContactName, getSimpleContacts, getContact } from 'Hooks/Contact/UseContactsList';
import {  useContactsSelector } from 'Components/ContactsSelector/Common/SelectorContext';
import ContactsQuickAdd from 'Components/ContactsQuickAdd/ContactsQuickAdd';
import NwDivider from 'Components/Gui/NwDivider';
import { useFolders, useFoldersDispatch } from 'Contexts/FoldersContext';


const Wrapper = styled.div`
    padding: 1rem;
`;

const NameContainer = styled.div`
    display: flex;
    align-items: center;
    ${props => props.isInStorage ? props.theme.RedLineThrough(1) : 'text-decoration: none;'}
    opacity: ${props => props.isNotAccessible && 0.5};
`;

const Label = styled.div`
    margin-left: 2rem;
    text-transform: uppercase;
`;

const SortableList = styled.div`
    opacity: ${props => props.isLoading ? ".25" : "1"};
    position: relative;
    cursor: pointer;
`;

const SortableListItem = styled.div`
    background: ${props => props.isDragging ? StyleVariables.PrimaryColor : "#fff"};
    color: ${props => props.isDragging ? "#fff" : StyleVariables.TextColor };
    margin-bottom: 4px;
    padding: 0.5rem;
    box-shadow: ${props => props.isDragging ? '0 1px 6px rgba(57,73,76,.35)' : null};
    border-bottom: 0.5px solid ${props => props.theme.LighterBorderColor};
    display: flex;
    align-items: center;
    width: 100%;

    .sortable-list-item-icon {
        margin: 0 2rem 0 0;
    }

    .sortable-list-item-text {
        h6, p {
            padding: 0;
            margin: 0;
        }
    }
`;

const FolderContactsList = () => {
    
    const { selection, context } = useContactsSelector();
    const { activeSet } = selection;
    const { itemType } = context;
    //const { folders } = global;

    const folders = useFolders()
    const folders_dispatch = useFoldersDispatch()

    const folder = folders.openFolders.find(folder => folder.ID === Number(activeSet));

    const onItemDelete = (itemID) => {
        folders_dispatch({
            type: 'RemoveItemsFromFolder',
            folderID: folder.ID,
            items: [itemID]
        })
    };

    const renderItemIcon = item => {
        switch (item.type) {
            case 'model': 
                return <NwIcon icon={light('walking')} />;
            case 'customer':
                return <NwIcon icon={light('id-card')} />;
            case 'project':
                return <NwIcon icon={light('briefcase')} />
            default:
                return <></>
        }
    };

    const items = useMemo(() => {
        const folderItems = folder.items.map(itemID => {
            return {
                Name: getContactName(itemID),
                ID: itemID
            }
        })
        return folderItems;
    }, [folder.items]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = async result => {
        if (result.source.index !== result.destination.index) {
            const reorderedFolderItems = reorder([...folder.items], result.source.index, result.destination.index)
            folders_dispatch({
                type: 'UpdateOrderFolderItems',
                folder: {
                    ...folder,
                    items: reorderedFolderItems
                }
            })
        }
    };

    const handleAddContact = (itemID) => {
        folders_dispatch({
            type: 'AddItemsToFolder',
            folderID: folder.ID,
            items: [itemID]
        })
    };

    const simpleContacts = getSimpleContacts();

    return (
        <Wrapper>
            <h2><NwIcon icon={light("folder")}/> {folder.title}</h2>
            <p>{folder.items ? (folder.items.length || 'no') : 'no'} models</p>
            <br/>
            <NwDivider>Add a contact</NwDivider>
            <ContactsQuickAdd
                onSelect={handleAddContact}
                sources={itemType === "model" ? ["models"] : ["clients", "agencies", "services"]}
                added={folder.items}
            />
            <br/>
            {items.length > 0 ? 
                <NwCard>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <SortableList
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {items.map((item, index) => {
                                        const simpleContact = simpleContacts.find(contact => contact.ID === item.ID);
                                        const contact = getContact(item.ID);
                                        const isInStorage = (!contact && simpleContact && simpleContact.SB) ? true : false;
                                        const isNotAccessible = (!contact && simpleContact && !simpleContact.SB) ? true : false;
                                        return (
                                            <Draggable key={item.ID} draggableId={item.ID} index={index}>
                                                {(provided, snapshot) => (
                                                    <SortableListItem 
                                                        isDragging={snapshot.isDragging}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <FlexContainer
                                                            fullWidth 
                                                            justifyContent='space-between' 
                                                            className="sortable-list-item-text">
                                                            <FlexContainer>
                                                                <NwIcon icon={light('arrow-down-arrow-up')}/>
                                                                <NameContainer isInStorage={isInStorage} isNotAccessible={isNotAccessible}>
                                                                    {renderItemIcon(item)}
                                                                    <Label>{item.Name}</Label>
                                                                </NameContainer>
                                                            </FlexContainer>
                                                            {isInStorage && <div>model in storage</div>}
                                                            {isNotAccessible && <div>not accessible</div>}
                                                            <NwButton 
                                                                style={{marginRight: "1rem"}} 
                                                                iconOnly
                                                                ghost
                                                                icon={light("times")}
                                                                size="small" 
                                                                onClick={() => onItemDelete(item.ID)} 
                                                            />
                                                        </FlexContainer>
                                                    </SortableListItem>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </SortableList>
                            )}
                        </Droppable>
                    </DragDropContext>
                </NwCard>
                :
                <CenteredBlock>
                    <EmptyState message="no contacts fitting your search" />
                </CenteredBlock>
            }
        </Wrapper>
    );
};

export default FolderContactsList