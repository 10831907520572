import React, { useState, useMemo, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';

import { Loading } from 'Components/Gui/Loading';
import ModelMediaPanel from 'Components/ModelMedia/ModelMediaPanel/ModelMediaPanel';
import { onContactMediaUpdate, useContactPicturesAndVideos } from "Hooks/Contact/UseContactMedia";
import useDataPlaceholder from "Hooks/UseDataPlaceholder";
import { useContactPlaylists } from 'Hooks/Contact/UseContactPlaylists';
import { usePackagePlaylist } from "Hooks/Packages/UsePackagePlaylist";
import { useModelContext } from 'Contexts/ModelContext';
// import ModelContext from "Contexts/ModelContext_OLD";

const { Content } = Layout;

const LayoutContainer = styled.div`
    background: ${props => props.loading && 'black'};
`;

const MainLayout = styled(Layout)`
    opacity: ${props => props.$loading ? .4 : 1};
`;

const StyledContent = styled(Content)`
    padding: 1rem 1.5rem 2rem .5rem;
`;


const ModelMedia = ({
    bookToEdit,
    isDrawer,
    newBook,
    onBookTouched,
    onCloseBookEditor,
    packageBook,
}) => {

    // const modelContextValues = useContext(ModelContext);
    // const { selectedModel } = modelContextValues;
    const { selectedModel } = useModelContext();

    const replacePlaceHolder = useDataPlaceholder();
    const { data: documents } = useContactPicturesAndVideos(selectedModel);
    const [showMedia, setShowMedia] = useState({
        isShowPictures: true,
        isShowVideos: true
    });
    const [tags, setTags] = useState([]);
    const [uploader, setUploader] = useState();
    const [uploadedSince, setUploadedSince] = useState();
    const [uploadedUntil, setUploadedUntil] = useState();
    const [loading, setLoading] = useState(false);
    const { data: contactPlaylists } = useContactPlaylists(!packageBook ? selectedModel : null);
    const { data: packagePlaylist } = usePackagePlaylist(packageBook ? packageBook.ID : null)
    const [playlists, setPlaylists] = useState();
    const [selectedPackageBook, setSelectedPackageBook] = useState();

    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (contactPlaylists) setPlaylists(contactPlaylists);
    }, [contactPlaylists]);

    useEffect(() => {
        if (packagePlaylist)
            setSelectedPackageBook({
                ...packagePlaylist,
                PackageID: packageBook.PackageID
            });
    }, [packagePlaylist]);

    const handleUpdateShowMedia = updatedShowMedia => {
        setShowMedia(updatedShowMedia);
    };

    const handleUpdateTags = updatedTags => {
        setTags(updatedTags);
    };

    const handleChangeUploader = value => {
        setUploader(value);
    };

    const handleUploadedSinceChange = value => {
        setUploadedSince(value);
    };

    const handleUploadedUntilChange = value => {
        setUploadedUntil(value);
    };

    const handleClearFilters = () => {
        setShowMedia({
            isShowPictures: true,
            isShowVideos: true
        });
        setTags([]);
        setUploader();
        setUploadedSince();
        setUploadedUntil();
    };

    const displayedDocuments = useMemo(() => {
        console.log("processing displayed documents in useMemo in ModelMedia.js")
        let documentList = [];
        if (documents && documents.length) {
            documentList = documents.filter(document => {
                if ((document.Type === 'Photo' && showMedia.isShowPictures) || (document.Type === 'Video' && showMedia.isShowVideos)) {
                    return true;
                };
                return false;
            });

            if (uploader) {
                documentList = documentList.filter(document => document.UserCreated === uploader);
            }

            documentList = documentList.filter(document => {
                for (const tag of tags) {
                    if (!document.Tags.find(item => item.ID === tag)) {
                        return false;
                    }
                }
                return true;
            });

            const uploadedSinceDate = replacePlaceHolder(uploadedSince);
            const uploadedUntilDate = replacePlaceHolder(uploadedUntil);
            documentList = documentList.filter(document => {
                if (uploadedSince) {
                    if (new Date(uploadedSinceDate) > new Date(document.DateCreated))
                        return false;
                }
                if (uploadedUntil) {
                    if (new Date(uploadedUntilDate) < new Date(document.DateCreated))
                        return false;
                }
                return true;
            })
        }
        return documentList;
    }, [uploader, uploadedSince, uploadedUntil, showMedia, documents, replacePlaceHolder, tags]);

    if (loading) {
        return <Loading text="Loading page" textBlack fullHeight />
    }
    if (!documents) {
        return <Loading text="Loading media" textBlack fullHeight />
    }

    return (
        <>
            <LayoutContainer>
                <MainLayout>
                    <StyledContent>
                        <ModelMediaPanel
                            bookToEdit={bookToEdit}
                            contactID={selectedModel}
                            contactPlaylists={playlists}
                            displayedDocuments={displayedDocuments}
                            isDrawer={isDrawer}
                            newBook={newBook}
                            onBookTouched={onBookTouched}
                            onChangeUploadedSince={handleUploadedSinceChange}
                            onChangeUploadedUntil={handleUploadedUntilChange}
                            onChangeUploader={handleChangeUploader}
                            onClearFilters={handleClearFilters}
                            onCloseBookEditor={onCloseBookEditor}
                            onMediaUpdate={onContactMediaUpdate}
                            onUpdateShowMedia={handleUpdateShowMedia}
                            onUpdateTags={handleUpdateTags}
                            packageBook={selectedPackageBook}
                            showMedia={showMedia}
                            tags={tags}
                            uploadedSince={uploadedSince}
                            uploadedUntil={uploadedUntil}
                            uploader={uploader}
                        />
                    </StyledContent>
                </MainLayout>
            </LayoutContainer>
        </>
    )
};

export default ModelMedia;