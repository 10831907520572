import React, { useState, useEffect} from 'react';
import { Avatar } from 'antd';
import { getUrlCover } from 'Libs/NwUtils';
import { useModelsList, useCustomersList } from 'Hooks/Contact/UseContactsList';


const ContactAvatar = ({ contactID, ...rest }) => {
    const { data: models } = useModelsList();
    const { data: customers } = useCustomersList();
    const [bg, setBg] = useState(null)
    const [contact, setContact] = useState(null)

    useEffect(() => {
        //if (contactID && models && customers && (models.length > 0 || customers.length > 0)) {
        if (models && customers) {
        const contacts = [...models, ...customers]
            const mycontact = contacts.find((c) => (c.ID.toString() === contactID.toString()))
            setContact(null);
            setBg(null);
            if (mycontact) {
                setContact(mycontact)
                if (mycontact.CG) {
                    const urlbg = getUrlCover(mycontact.CG)
                    setBg(urlbg)
                }
            }
        }
    }, [models, customers, contactID]);

    return (
        (bg) ?
            <Avatar src={bg} {...rest} /> //shape="square" 
        :
            contact ?
                <Avatar {...rest}>{contact.N.substring(0,1)} {contact.S && contact.S.substring(0,1)}</Avatar>
            :
                <Avatar {...rest}>...</Avatar>
    )
}

export default ContactAvatar;