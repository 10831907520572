import { useQuery, useMutation } from '@tanstack/react-query';
import Axios from 'axios';

import { queryClient } from 'QueryClientProvider';

const getContactPlaylists = async ID => {
    if (ID === null) return null;
    const playlists = await Axios.get(`/models/getPlaylists/${ID}`)
    return playlists
}

const onUpdatePlaylists = (ID) => {
    const intID = Number(ID)
    getContactPlaylists(intID).then((playlists) => {
        queryClient.setQueryData(['contact', intID], (old) => ({
            ...old,
            Playlists: playlists
        }));
        queryClient.invalidateQueries(['contact', intID]);
        queryClient.setQueryData(['contactPlaylist', intID], playlists);
    })
} 

const useContactPlaylists = (ID, disable) => {
    const intID = Number(ID)
    return (useQuery(["contactPlaylist", intID], () => getContactPlaylists(intID), {
        staleTime: 60000,
        cacheTime: 60000,
        retry: false,
        enabled: !disable
    }))
}

const forceContactPlaylists = contactID => {
    const intID = Number(contactID)
    queryClient.invalidateQueries(['contactPlaylist', intID]);
}

const addPlaylist = ({ playlist, contactID }) => ( 
    Axios.post("/playlists", playlist)
);

const useAddPlaylist = () => {
    return useMutation(addPlaylist, {
        onSuccess: (data, variables) => {
            onUpdatePlaylists(variables.contactID)
        }
    })
}

const editPlaylist = ({ playlist, contactID }) => (
    Axios.put("/playlists", playlist)
);

const useEditPlaylist = () => {
    return useMutation(editPlaylist, {
        onSuccess: (data, variables) => {
            if (variables.contactID) {
                onUpdatePlaylists(variables.contactID)
            }
        }
    })
};

const editPlaylistDocuments = ({ playlist, contactID }) => (
    Axios.put("/playlists/Documents", playlist)
);

const useEditPlaylistDocuments = () => {
    return useMutation(editPlaylistDocuments, {
        onSuccess: (data, variables) => {
            if (variables.contactID) {
                onUpdatePlaylists(variables.contactID)
            }
        }
    })
}

const changeLayoutPlaylist = ({ playlistID, layout, contactID }) => (
    Axios.put("/playlists/layout", {PlaylistID: playlistID, Layout: layout})
);

const useChangeLayoutPlaylist = () => {
    return useMutation(changeLayoutPlaylist, {
        onSuccess: (data, variables) => {
            if (variables.contactID) {
                onUpdatePlaylists(variables.contactID)
            }
        }
    })
}

export {
    onUpdatePlaylists,
    useAddPlaylist,
    useEditPlaylist,
    useContactPlaylists,
    useEditPlaylistDocuments,
    useChangeLayoutPlaylist,
    forceContactPlaylists
}