import React, { useContext } from "react";

import { ListContext } from "Contexts/ListContext";
import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import styled from "styled-components";
import { Segmented } from "antd";
import NwIcon from "Components/Gui/NwIcon";

const StyledSegmented = styled(Segmented)`
    .label {
        padding: .5rem;

        .label-icon {
            font-size: 1.2rem;
            min-height: 1.9rem;
        }
        .label-text {
            font-size: 1rem;
            text-transform: uppercase;
        }
    }
    .ant-segmented-item-selected {
        .label {
            .label-icon {
                color: ${props => props.theme.PrimaryColor};
            }
            &.empty {
                .label-icon {
                    color: ${props => props.theme.TextColorMuted};
                }
                .label-text {
                    font-weight: normal;
                    color: ${props => props.theme.TextColorMuted};
                }
            }
        }
    }
`

const SegmentedOptionContent = ({ text, icon, empty }) => (
    <div className={`label ${empty ? 'empty' : ''}`}>
        <div className="label-icon">
            {icon &&
                <NwIcon icon={icon} />
            }
        </div>
        <div className="label-text">{text}</div>
    </div>
)

const AgencyTypeSelector = ({
    asSegmented,
    emptyValue,
    hideMother,
    onChange,
    value,
}) => {
    const { cachedList } = useContext(ListContext);

    const modelAgenciesTypesOptions = []
    if (cachedList && cachedList.enumerators && cachedList.enumerators.items) {
        const modelAgenciesTypesEnum = cachedList.enumerators.items.find(el => {
            return el.Name === "ModelAgencyTypeEnum";
        }).Values;
        const modelAgenciesTypesEnumToOptions = modelAgenciesTypesEnum.map(el => {
            const disabled = (el.Name === "Mother" && hideMother);
            return { disabled: disabled, value: el.Name, label: el.Name }
        });
        modelAgenciesTypesOptions.push(...modelAgenciesTypesEnumToOptions);
        if (emptyValue) {
            modelAgenciesTypesOptions.unshift({ value: null, label: "Any Type" });
        }
    }

    const optionsAsSegmented = modelAgenciesTypesOptions.map(el => {
        switch (el.value) {
            case null:
                return {
                    label: <SegmentedOptionContent text="Any" icon={light('asterisk')} empty />,
                    value: null,
                }
            case "Mother":
                return {
                    label: <SegmentedOptionContent text="Mother" icon={light('medal')} />,
                    value: "Mother",
                }
            case "Placement":
                return {
                    label: <SegmentedOptionContent text="Placement" icon={light('handshake')} />,
                    value: "Placement",
                }
            default:
                return {
                    label: <SegmentedOptionContent text={el.label} icon={light('globe')} />,
                    value: el.value,
                }
        }
    })

    if (asSegmented || emptyValue) {
        return (
            <StyledSegmented
                value={value}
                onChange={onChange}
                options={optionsAsSegmented}
                defaultValue={emptyValue ? null : false}
            />
        );
    }
    return (
        <RadioButtonGroup
            value={value}
            onChange={onChange}
            options={modelAgenciesTypesOptions}
        />
    );
};

export { AgencyTypeSelector };


