import React, {useContext} from 'react';
import {Table, Rate, Layout} from 'antd';
import styled from 'styled-components';

import { ListContext } from "Contexts/ListContext";
import UserIcon from "Components/UserIcon/UserIcon";
import useAreaAccentColor from 'Hooks/UseAreaAccentColor';
import { showLog } from 'Libs/NwMoment';

const StyledRate = styled(Rate)`
    .ant-rate-star-full {
        color: ${props => props.ratingColor && props.ratingColor.mainColor}
    }
    .ant-rate-star-zero {
        .ant-rate-star-first, .ant-rate-star-second {
            color: ${props => props.ratingColor && props.ratingColor.lightColor} !important;
        }
    }
`;

const RatingsTable = ({ ratings }) => {
    const ratingColor = useAreaAccentColor();
    const { cachedList, cachedListLoaded } = useContext(ListContext);

    const getUserInfoByUserId = (userId) =>  {
        let user = null;
        let userName = "";
        let userCoverGuid = "";
        let letters = "";
        if (cachedListLoaded) {
            user = cachedList.users.items.find(usr => usr.ID === userId);
            if (user) {
                userName = user.Name + " " + user.Surname;
                userCoverGuid = user.CoverGuid;
                letters = user.Name.toUpperCase()[0] + " " + user.Surname.toUpperCase()[0]
            } else {
                userName = "Utente Farlocco";
                letters = "UF"
            }
        }

        return {userName, userCoverGuid, letters, userId};

    };

    let ratingListForItem = ratings.map(rfi => ({
        ...rfi,
        key: rfi.ID,
        createdBy: getUserInfoByUserId(rfi.UserCreated),
        DateCreated: showLog(rfi.DateModified || rfi.DateCreated)
    }));

    const columns = [
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            rowSpan: 2,
            key: 'createdBy',
            render: (createdBy) => {
                return (
                    <>
                        <UserIcon userId={createdBy.userId} coverGuid={createdBy.userCoverGuid} letters={createdBy.letters} size="medium" />
                        <span style={{marginLeft: 5}}>{createdBy.userName}</span>
                    </>
                )
            }

        },
        {
            title: 'Date Created',
            dataIndex: 'DateCreated',
            key: 'DateCreated',
        },
        {
            title: 'Rating',
            dataIndex: 'Rating',
            key: 'Rating',
            render: (rate) => <StyledRate ratingColor={ratingColor} value={rate} disabled={true}/>
        },

    ];

    return (
        <Layout style={{flexDirection: 'column'}}>
            <>
                <React.Fragment>
                    <Table
                        pagination={false}
                        columns={columns}
                        style={{pointerEvents: "none"}}
                        dataSource={ratingListForItem}
                    />
                </React.Fragment>
            </>
        </Layout>
    );
};

export default RatingsTable
