import React from "react";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from "Components/Gui/NwIcon";
import PermissionBlock from 'Components/PermissionBlock/PermissionBlock';
import { useUserPermission } from 'Contexts/UserContext';


const ModelAgenciesContainer = styled.div`
    font-weight: ${(props) => (props.$isBold ? "bold" : "normal")};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    text-transform: uppercase;

    .icon-container {
        width: .75rem;
        text-align: center;
    }

    p {
        margin: 0;
    }

    small {
        font-weight: normal;
        font-size: .75rem;
    }
`
const ModelDetailsHeaderAgencies = ({ model, readOnly }) => {
    const viewModelAgencies = useUserPermission('ViewModelAgencies', model && (model.Users || model.U).map(user => user.UserID));

    return (
        <PermissionBlock
            content={
                ((model.MotherAgencyID || model.MAID)
                    ?
                    <ModelAgenciesContainer>
                        <div className="icon-container">
                            <NwIcon icon={light("globe")} />
                        </div>
                        <p>{model.MotherAgencyName || model.MAN}</p>
                    </ModelAgenciesContainer>
                    :
                    ((model.Represented || model.R)
                        ?
                        <ModelAgenciesContainer $isBold>
                            <div className="icon-container">
                                <NwIcon icon={light("medal")} />
                            </div>
                            <p>represented</p>
                        </ModelAgenciesContainer>
                        :
                        <ModelAgenciesContainer>
                            <div className="icon-container">
                                <NwIcon icon={light("globe")} />
                            </div>
                            <p>no mother ag.</p>
                        </ModelAgenciesContainer>
                    )
                )

            }
            permission={viewModelAgencies}
        />

    )

};

export default ModelDetailsHeaderAgencies;
