import React from "react";
import styled from "styled-components";

import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";
import ContactDropdown from "Components/ContactDropdown/ContactDropdown";

const ContactSelectorContent = styled.div`
    position: absolute;
    top: 35px;
    left: -100px;
    width: 465px;
    .type-selector {
        margin-left: auto;
    }
    .statement-row {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        .title {
            margin: auto 10px auto 0;
            width: 230px;
        }
    }
`

const StatementContactSelector = ({ onChange, value, disabled, onBlur }) => {

    const onTypeChange = (val) => {
      onChange({
        ...value,
        StatementType: val
      });
    }

    const onContactIdChange = (val) => {
        onChange({
          ...value,
          ContactID: val
        });
    }

    return (
        <ContactSelectorContent>
            <div className="type-selector">
                <RadioButtonGroup 
                    value={value.StatementType}
                    disabled={disabled}
                    onChange={onTypeChange}
                    defaultValue='Female'
                    options={[
                        { label: 'Model', value: 'Model'},
                        { label: 'Client', value: 'Client'},
                        { label: 'Agency', value: 'Agency'},
                        { label: 'Service', value: 'Service'},
                        { label: 'Selected', value: 'Selected'},
                    ]} />
            </div>

            {value.StatementType === "Selected" &&
                <div className="statement-row">
                    <div className="title">Selected Contact</div>
                    <ContactDropdown
                        activeTypes={['mod', 'cli', 'age', 'srv']}
                        value={value.ContactID}
                        onChange={onContactIdChange}
                        onBlur={onBlur}
                        style={{ width: '100% '}}
                    />
                </div>
            }
        </ContactSelectorContent>
    );
};

export default StatementContactSelector;
