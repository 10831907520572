import { useQuery, useMutation } from '@tanstack/react-query';
import Axios from 'axios';

import { forceReloadContact } from "Hooks/Contact/UseContact";
import { forceReloadModels } from "Hooks/Contact/UseContactsList";
import { queryClient } from 'QueryClientProvider';
import { forceReloadAgencyModels } from './UseAgencyModels';

const getContactAgencies = ID => (
    Axios.get(`/models/getAgencies/${ID}`)
)

const deleteAgency = ({ ModelAgencyID, AgencyID, ModelID }) => (
    Axios.delete(`/modelagencies/${ModelAgencyID}`)
)

const addAgency = (data) => (
    Axios.post("/modelagencies", data)
)

const editAgency = (data) => (
    Axios.put(`/modelagencies`, data)
)

const useContactAgencies = (ID) => {
    const intID = Number(ID)
    return useQuery(["contactAgencies", intID], () => getContactAgencies(intID), {
        staleTime: 60000,
        cacheTime: 60000
    })
}

const forceReloadContactAgencies = (ID) => {
    const intID = Number(ID)
    queryClient.invalidateQueries(['contactAgencies', intID])
}

const useRemoveAgency = () => {
    return useMutation(deleteAgency, {
        onSuccess: (data, variables) => {
            forceReloadContact(variables.ModelID)
            forceReloadContactAgencies(variables.ModelID)
            forceReloadAgencyModels(variables.AgencyID)
            forceReloadModels()
        }
    })
}

const useAddAgency = () => {
    return useMutation(addAgency, { 
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['contactAgencies', data.ModelID])
            forceReloadContact(data.ModelID)
            forceReloadContactAgencies(data.ModelID)
            forceReloadAgencyModels(data.AgencyID)
            forceReloadModels()
        }
    })
}

const useEditAgency = () => {
    return useMutation(editAgency, { 
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['contactAgencies', data.ModelID])
            forceReloadContact(data.ModelID)
            forceReloadContactAgencies(data.ModelID)
            forceReloadAgencyModels(data.AgencyID)
            forceReloadModels()
        }
    })
}

export {
    useContactAgencies,
    useRemoveAgency,
    useAddAgency,
    useEditAgency
}
