import React, { useContext, useMemo, useState } from 'react';
import NwDrawer from 'Components/Gui/NwDrawer';
import ModelsSelectorWrapper from 'Components/ContactsSelector/ModelsSelector/ModelsSelectorWrapper';
import NewGroupModal from 'Components/PackageDrawer/PackageGroupModals/NewGroupModal';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import PackageGroup from './PackageGroup';
import GroupModal from './PackageGroupModals/GroupModal';
import { NwButton, NwLinkButton } from 'Components/Gui/NwButton';
import { PackageContext } from './PackageContext';
import Axios from 'axios';
import { Dropdown } from 'antd';
import NwIcon from 'Components/Gui/NwIcon';



const PackageContent = ({ compact }) => {
    const [addModels, setAddModels] = useState(false);
    const [packageGroup, setPackageGroup] = useState("");
    const [openNewGroupModal, setOpenNewGroupModal] = useState(false);
    const [isOpenGroupModal, setIsOpenGroupModal] = useState(false);
    const [isSort, setIsSort] = useState(false);
    const [selectedModels, setSelectedModels] = useState([]);

    const packageContextValue = useContext(PackageContext);
    const { groupedPackage, afterUpdatePackage } = packageContextValue;

    const packageProjectID = groupedPackage.Project ? groupedPackage.Project.ID : null;

    const hasGroups = groupedPackage.GroupNames.length > 1 || (groupedPackage.GroupNames.length === 1 && groupedPackage.GroupNames[0] !== '');

    console.log("hasGroups", hasGroups)

    const handleOpenGroupModal = () => {
        setIsOpenGroupModal(true);
    };

    const handleCloseGroupModal = () => {
        setIsOpenGroupModal(false);
    };

    const getAddModelOptions = useMemo(() => {
        const actions = [
            { name: "Add models", icon: solid("person-circle-plus"), key: "add-nogroup", pinned: true, action: (() => { handleAddModelsDrawer(''); }) }
        ]
        console.log("groupnames", groupedPackage.GroupNames)
        if (groupedPackage.GroupNames.length > 0) {
            groupedPackage.GroupNames.forEach(group => {
                if (group) {
                    actions.push({ name: `Add models to ${group}`, key: `add-${group}`, pinned: false, action: (() => { handleAddModelsDrawer(group); }) })
                }
            })
        }
        actions.push({ name: "Add models to a new group", key: "add-newgroup", pinned: false, action: (() => { handleAddModelsDrawer('new-group'); }) })
        return actions;
    }, [groupedPackage.GroupNames]);

    const getAddModelsMenuProps = () => {
        const onItemClick = (e) => {
            const act = e.key.split('-')[1];
            switch (act) {
                case 'nogroup':
                    handleAddModelsDrawer('');
                    break;
                case 'newgroup':
                    handleAddModelsDrawer('new-group');
                    break;
                default:
                    handleAddModelsDrawer(act);
                    break;
            }
        }
        const items = []
        if (groupedPackage.GroupNames.length > 0) {
            groupedPackage.GroupNames.forEach(group => {
                if (group) {
                    items.push(
                        {
                            label: <span>ADD MODELS TO <strong>{group.toUpperCase()}</strong></span>,
                            key: `add-${group}`,
                            icon: <NwIcon icon={light("people-group")} />,
                        }
                    )
                }
            })
        }
        items.push(
            {
                label: <span>ADD MODELS TO <strong>A NEW GROUP</strong></span>,
                key: "add-newgroup",
                icon: <NwIcon icon={light("square-dashed-circle-plus")} />,
            }
        )
        items.push(
            {
                label: <span>ADD <strong>UNGROUPED</strong> MODELS</span>,
                key: `add-nogroup`,
                icon: <NwIcon icon={light("user-plus")} />,
            }
        )
        return { onClick: onItemClick, items: items }
    };


    const handleAddModelsDrawer = (pg) => {
        //onUpdatePackage()
        if (pg === 'new-group') {
            setPackageGroup("");
            setOpenNewGroupModal(true);
        } else {
            setPackageGroup(pg);
            setAddModels(true);
        }
    };

    const handleCloseNewGroupModal = (groupName) => {
        setOpenNewGroupModal(false);
        if (groupName) {
            setPackageGroup(groupName);
            setAddModels(true);
        }
    }

    const handleCloseAddModels = () => {
        afterUpdatePackage()
        setAddModels(false);
    }

    const removeModels = async () => {
        const querystring = [...selectedModels].map(id => `ModelIDs=${id}`).join('&')
        await Axios.delete(`/packagemodels/delete?${querystring}`)
        setSelectedModels([])
        afterUpdatePackage()
    }

    const SelectedModelActions = () => {
        return (
            <div className="selected-models-actions">
                <div>
                    <small>{selectedModels.length} selected models</small>
                    <NwLinkButton size="small" primary label="clear selection" onClick={() => setSelectedModels([])} />
                </div>
                <NwButton primary icon={light("shuffle")} label="change group" onClick={handleOpenGroupModal} />
                <NwButton primary icon={light("user-xmark")} label="remove models" onClick={removeModels} />
            </div>
        )
    }

    return (
        <div className='package-drawer-models-container'>
            <NwCard
                icon={light("images")}
                title="Package Content"
                padding="0"
                transparent
                extra={
                    <div className="actions-group-holder">
                        {
                            (selectedModels && selectedModels.length > 0)
                                ?
                                <SelectedModelActions />
                                :
                                null
                        }
                        {hasGroups
                            ?
                            <Dropdown menu={getAddModelsMenuProps()}>
                                <NwButton primary icon={light("chevron-down")} label="add models" />
                            </Dropdown>
                            :
                            <NwDropDownMenu primary actions={getAddModelOptions} />
                        }

                    </div>

                }
            >
                {groupedPackage.GroupedModels.map((group, groupIndex) => (
                    <PackageGroup
                        compact={compact}
                        key={group.groupLabel}
                        groupName={group.groupName}
                        groupIndex={groupIndex}
                        isSort={isSort}
                        selectedModels={selectedModels}
                        setSelectedModels={setSelectedModels}
                    />
                ))}
            </NwCard>
            {openNewGroupModal &&
                <NewGroupModal onClose={handleCloseNewGroupModal} />
            }
            {isOpenGroupModal &&
                <GroupModal
                    packageModelIDs={[...selectedModels]}
                    show={isOpenGroupModal}
                    onClose={handleCloseGroupModal}
                    clearSelected={(() => setSelectedModels([]))}
                />
            }
            {addModels &&
                <NwDrawer
                    id="add-models-drawer"
                    padding="0px"
                    width='80vw'
                    title='Add Models to Package'
                    onClose={handleCloseAddModels}>
                    <ModelsSelectorWrapper
                        currentPackage={groupedPackage}
                        defaultView="table"
                        fixedView
                        isDrawer={true}
                        layouttype="fullpage"
                        itemClickAction="preview-drawer"
                        multipleSelection={false}
                        packageGroup={packageGroup}
                        projectId={packageProjectID}
                        scope="package"
                        startOnProjectModels={packageProjectID ? true : false}
                    />
                </NwDrawer>
            }
        </div>
    )
};

export default PackageContent;