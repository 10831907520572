import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Layout, Checkbox } from 'antd';
import Axios from 'axios';
import CKEditor from '@ckeditor/ckeditor5-react';
import StyleVariables from 'Components/Gui/StyleVariables.js';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwButton } from 'Components/Gui/NwButton';
import { Loading } from 'Components/Gui/Loading';
import NwLogo from 'Components/_LayoutComponents/Header/NwLogo';
import DocsView from 'Containers/Booking/DocEditor/DocsView';
import SendDocuments from 'Containers/Booking/DocEditor/SendDocuments';
import DocumentLayout from 'Components/_LayoutComponents/Layout/DocumentLayout';
import { UserContext } from "Contexts/UserContext";
import { ListContext } from "Contexts/ListContext";
import SiderStyled from 'Components/_LayoutComponents/Layout/Sider';
import { useMatch } from 'react-router';

const { Header, Content } = Layout;

const ActionButtons = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 16px 12px 0;
`;

const StyledHeader = styled(Header)`
    position: fixed;
    z-index: 10; 
    width: 100%;
    padding: 0;
    box-shadow: 0 1px 6px rgba(57,73,76,.35);
    a {
        color: ${StyleVariables.TextColor};
    }
`;

const NwHeader = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LogoHolder = styled.div`
    padding: 0 20px;
`;

const StyledContent = styled(Content)`
    margin-left: 300px;
`;

const DocContainer = styled.div`
    margin-top: 70px;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 24px;
    height: calc( 100% - 80px);
    box-shadow: 0 1px 6px rgba(57,73,76,.35);
    background: white;
    @media only screen and (max-width: 992px) {
        margin-left: 15%;
        margin-right: 15%;
    }
    @media only screen and (max-width: 768px) {
        margin-left: 10%;
        margin-right: 10%;
    }
    @media only screen and (max-width: 600px) {
        margin-left: 0;
        margin-right: 0;
    }
`;

const StyledLayout = styled(Layout)`
    min-height: 100vh;
`;

const DocEditor = () => {

    const match = useMatch();

    const { currentUser } = useContext(UserContext);
    const [document, setDocument] = useState();
    const [contentData, setContentData] = useState();
    const [firstPart, setFirstPart] = useState();
    const [lastPart, setLastPart] = useState();
    const [loading, setLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [documentList, setDocumentList] = useState([]);
    const [isEmailDrawer, setEmailDrawer] = useState(false);
    const { templates } = useContext(ListContext).cachedList;
    const [selectedTemplate, setSelectedTemplate] = useState();

    useEffect(() => {
        getDocumentById();
        getDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDocumentById = async () => {
        const documentId = parseInt(match.params.documentId); 
        try {
            const docResponse = await Axios.get(`/docVersions/${documentId}`);
            setDocument(docResponse);
            const template = templates.items.find(template => template.id === docResponse.Template);
            setSelectedTemplate(template);
            getContents(docResponse);
            await getDocuments(docResponse.Area);
        } catch (error) {
            console.log('ant : Get FollowUp Document error => ', error);
        }
    };

    const getDocuments = async documentArea => {
        const params = getFilterParams(documentArea);
        try {
            const response = await Axios.post('/docVersions/getFiltered', params);
            setDocumentList(response);
        } catch (error) {
            console.log('ant : Get all documents error => ', error);
        }
    };

    const getFilterParams = documentArea => {
        let filterIdName = '';
        switch (documentArea) {
            case "ExternalJob": 
                filterIdName = 'ExtJobID';
                break;
            case "FollowUp": 
                filterIdName = 'FollowUpID';
                break;
            case "ToDo": 
                filterIdName = 'ToDoID';
                break;
            default:
                filterIdName = 'FollowUpID';
                break;
        };
        return {
            [filterIdName]: match.params.objectId
        }
    }

    const getContents = document => {
        const htmlParts = document.Html.split('<section id="body">');
        if(htmlParts.length === 1) {
            setFirstPart();
            setContentData(htmlParts[0]);
            setLastPart();
        } else {
            setFirstPart(htmlParts[0]);
            if (htmlParts.length > 1) {
                const bottomHtmls = htmlParts[1].split('</section>');
                setContentData(bottomHtmls[0]);
                bottomHtmls.splice(0, 1);
                const lastHtml = bottomHtmls.join('</section>');
                setLastPart(lastHtml);
            }
        }
    }

    const handleSelectDocument = updatedDocument => {
        setDocument(updatedDocument);
        getContents(updatedDocument);
    }

    const handleChangeDocument = updatedContent => {
        setContentData(updatedContent);
    };

    const handleSaveDocument = async () => {
        const savedDocument = firstPart + '<section id="body">' + contentData + '</section>' + lastPart;
        setLoading(true);
        const saveDocumentData = {
            ID: document.ID,
            Html: savedDocument
        };
        try {
            await Axios.put('/docVersions/UpdateHtml', saveDocumentData);
            setLoading(false);
            const updatedDocumentList = [...documentList];
            const findIndex = updatedDocumentList.findIndex(item => item.ID === document.ID);
            if (findIndex > -1) {
                updatedDocumentList[findIndex].Html = savedDocument;
            }
            setDocumentList(updatedDocumentList);
        } catch (error) {
            console.log('ant : Save document error => ', error);
            setLoading(false);
        }
    };

    const handleLoadDocument = async () => {
        setPdfLoading(true);
        try {
            const url = await Axios.get(`/docVersions/pdf/${document.DocGuid}`);
            //const documentUrl = new URL(url, Constants.tenanturl);
            const documentUrl = new URL(url);
            window.open(documentUrl.href, '_blank');
            setPdfLoading(false);
        } catch (error) {
            console.log('ant : Load pdf error => ', error);
            setPdfLoading(false);
        }
    };

    const handleChangeDocumentConfirmed = async event => {
        const updatedConfrimed = event.target.checked;
        try {
            await Axios.put(`/docVersions/SetConfirmation/${document.ID}/${updatedConfrimed}`);
            setDocument({
                ...document,
                Confirmed: updatedConfrimed
            });
        } catch (error) {
            console.log('ant : Change document confirmed error => ', error);
        }
    };

    const handleOpenEmailsDrawer = () => {
        setEmailDrawer(true);
    };

    const handleCloseEmailsDrawer = () => {
        setEmailDrawer(false);
    };

    return contentData ? (
        <StyledLayout className="nw-page">
            <StyledHeader>                        
                <NwHeader>
                    <LogoHolder><NwLogo width={120} /></LogoHolder>

                    {currentUser.UserID === document.UserCreated && 
                        <Checkbox checked={document.Confirmed} onChange={handleChangeDocumentConfirmed}>Confirmed</Checkbox>
                    }
                    <ActionButtons>
                        <NwButton label='Send to email' primary size='small' style={{fontSize: 12}} onClick={handleOpenEmailsDrawer} loading={loading} loadingLabel='Saving...' />
                        &nbsp;
                        {currentUser.UserID === document.UserCreated && 
                            <>
                                <NwButton label='SAVE' primary size='small' style={{fontSize: 12}} onClick={handleSaveDocument} loading={loading} loadingLabel='Saving...' />
                                &nbsp;
                            </>
                        }
                        <NwButton label='PDF' danger style={{fontSize: 12}} size='small' icon={light('file-pdf')} onClick={handleLoadDocument} loading={pdfLoading} loadingLabel='Loading...' />
                    </ActionButtons>
                </NwHeader>
            </StyledHeader>
            <SiderStyled width={300}>
                {document && 
                    <DocsView
                        documents={documentList}
                        onSelectDocument={handleSelectDocument}
                        selectedTemplate={selectedTemplate}
                        onTemplateChange={setSelectedTemplate} /> 
                }
            </SiderStyled>
            <StyledContent>
                <DocContainer>
                    {/* <div dangerouslySetInnerHTML={{ __html: firstPart }} /> */}
                    <CKEditor
                        editor={ DecoupledEditor }
                        data={contentData}
                        config={{
                            toolbar: [ 'heading', '|', 'fontSize', 'fontFamily', '|', 'bold', 'italic', 'underline', 'strikeThrough', 'highlight', '|', 'alignment', '|', 'numberedList', 'bulletedList', 'link', 'table'],
                        }}
                        disabled={currentUser.UserID === document.UserCreated ? false : true}
                        onInit={ editor => {

                            editor.ui.getEditableElement().parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                            );
                        } }
                        onChange={ ( event, editor ) => {
                            handleChangeDocument(editor.getData());
                    }} />
                    {/* <div dangerouslySetInnerHTML={{ __html: lastPart }} /> */}
                </DocContainer>
            </StyledContent> 
            {isEmailDrawer && document && 
                <SendDocuments document={document} onClose={handleCloseEmailsDrawer} />
            }
        </StyledLayout>
    ) : <DocumentLayout><Loading loadingType="box" /></DocumentLayout>;
};

export default DocEditor