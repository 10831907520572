import React, { useEffect, useRef } from "react";
import { Input, Switch } from "antd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useStateValue } from "Contexts/StateProvider";
import NwIcon from 'Components/Gui/NwIcon';
import NwDivider from "Components/Gui/NwDivider";
import FilterTagSelector from 'Components/FilterTagSelector/FilterTagSelector';
import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";
import { useNavigate } from "react-router";
import { QuickFilterContainer } from "../../Filters/FilterForm/FilterFormUI";
import { useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/SelectorContext";

const CustomerQuickFilters = ({
    tags,
    onAfterChangeFilters,
    isMini
}) => {

    const { selection, context } = useContactsSelector();
    const cs_dispatch = useContactsSelectorDispatch();
    const { quickFilters, } = selection;
    const { area } = context;

    const nameRef = useRef();

    const sortOptions = [
        { label: 'Name', value: 'Name' },
        { label: 'ID', value: 'ID' }
    ];

    const handleChangeFilter = (name, value) => {
        cs_dispatch({
            type: "ChangeQuickFilters",
            quickFilters: {
                ...quickFilters,
                [name]: value
            }
        })
        if (onAfterChangeFilters) {
            onAfterChangeFilters()
        }
    }

    const customersTypeOptions = [
        { label: '*', value: 0 },
        { label: 'clients', value: 1 },
        { label: 'agencies', value: 2 },
        { label: 'services', value: 3 },
    ]
    if (area === 'scouting') {
        customersTypeOptions.splice(1, 1)
    }



    return (
        <QuickFilterContainer $isMini={isMini}>
            {!isMini &&
                <NwDivider>quick search</NwDivider>
            }
            <div className="quick-filter-line">
                <Input
                    ref={nameRef}
                    allowClear
                    autoComplete="off"
                    placeholder="Filter by name"
                    prefix={<NwIcon icon={light("search")} />}
                    onChange={(e) => {
                        handleChangeFilter('nameFilter', e.target.value)
                    }}
                    value={quickFilters.nameFilter}
                />
            </div>
            <div className="quick-filter-line">
                <div className="quick-filter-item">
                    <FilterTagSelector
                        tags={tags}
                        selectedOptions={quickFilters.tagFilter}
                        onChange={(_, _tagFilter) => {
                            handleChangeFilter('tagFilter', _tagFilter)
                        }}
                    />
                </div>
            </div>
            <div className="quick-filter-line">
                <div className="quick-filter-item">
                    <RadioButtonGroup
                        value={quickFilters.category}
                        onChange={(value) => {
                            handleChangeFilter('category', value)
                        }}
                        size='small'
                        options={customersTypeOptions}
                    />
                </div>

            </div>
            {isMini &&
                <div className="quick-filter-line">
                    <div className="quick-filter-item">
                        <span className="before">sort by</span>
                        <RadioButtonGroup
                            value={quickFilters.sort}
                            onChange={(value) => handleChangeFilter('sort', value)}
                            size='small'
                            options={sortOptions}
                        />
                    </div>
                </div>
            }
        </QuickFilterContainer>
    )
}

export default CustomerQuickFilters;
