import React, { useContext } from 'react';
import { HeaderContext } from 'Components/_LayoutComponents/Header/NwHeader';
import { NavMenu, NwNavIcon } from 'Components/_LayoutComponents/Header/NwHeaderUI';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getNavClassName, getNavMenuMode } from './NwNavUtils';



const NwNavBarScouting = ({
    allLogs,
    createNewItemMenu,
    current,
    drawer,
    handleChangeOpenKeys,
    handleClick,
    isLoaded,
    isOpen,
    lastSeenMenu,
    openKeys,
}) => {

    const headerContextValues = useContext(HeaderContext);
    const { device } = headerContextValues;
    const { modelLogs, customerLogs, travelPlanLogs } = allLogs;

    const navItems = [
        { key: "/scouting", label: "Dashboard", disabled: !isLoaded },
        {
            key: "/menu-models",
            label: "Models",
            popupClassName: !isOpen('/menu-models') ? 'main-nav-submenu menu-disabled' : 'main-nav-submenu menu-double',
            disabled: !isLoaded,
            onTitleClick: () => handleClick({ key: '/models' }),
            children: [
                lastSeenMenu(modelLogs, 'scouting-model', 'no models'),
                {
                    type: "group",
                    key: `/menu-tools-models`,
                    label: (
                        <span className="submenu-title-wrapper">
                            <NwNavIcon icon={light("bolt")} />Tools
                        </span>
                    ),
                    children: [
                        {
                            key: '/models',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("radar")} />Browse Models
                                </div>
                            )
                        },
                        {
                            key: '/models-archive',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("archive")} />Models Archive
                                </div>
                            )
                        },
                    ]
                },
                createNewItemMenu('Model')
            ]
        },
        {
            key: "/menu-contacts",
            label: "Contacts",
            popupClassName: 'main-nav-submenu',
            disabled: !isLoaded,
            onTitleClick: () => handleClick({ key: '/customers' }),
            children: [
                lastSeenMenu(customerLogs, 'customer', 'no contacts'),
                {
                    type: "group",
                    key: `/menu-tools-contacts`,
                    label: (
                        <span className="submenu-title-wrapper">
                            <NwNavIcon icon={light("bolt")} />Tools
                        </span>
                    ),
                    children: [
                        {
                            key: '/customers',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("radar")} />Browse Contacts
                                </div>
                            )
                        }
                    ]
                },
                createNewItemMenu('Customer')
            ]
        },
        {
            key: "/menu-travels",
            label: "Trips & Meetings",
            popupClassName: !isOpen('/menu-travels') ? 'main-nav-submenu menu-disabled' : 'main-nav-submenu menu-double',
            disabled: !isLoaded,
            onTitleClick: () => handleClick({ key: '/scouting/travels' }),
            children: [
                lastSeenMenu(travelPlanLogs, "scouting/travel", "no trips", "scouterName"),
                {
                    type: "group",
                    key: `/menu-tools-trips`,
                    label: (
                        <span className="submenu-title-wrapper">
                            <NwNavIcon icon={light("bolt")} />Tools
                        </span>
                    ),
                    children: [
                        {
                            key: '/scouting/travels',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("plane")} />Browse Trips
                                </div>
                            )
                        },
                        {
                            key: '/scouting/meetings',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("handshake")} />Meeting Search
                                </div>
                            )
                        },
                    ]
                },
                createNewItemMenu('Trip')
            ]
        }
    ]

    const keysWithSubmenus = navItems.filter(item => item.children).map(item => item.key);

    return (
        <NavMenu
            $device={device}
            className={getNavClassName(device, navItems.length)}
            inlineIndent={10}
            items={navItems}
            mode={getNavMenuMode(drawer, device)}
            onClick={handleClick}
            onOpenChange={(k) => handleChangeOpenKeys(k, keysWithSubmenus)}
            openKeys={openKeys}
            selectedKeys={[current]}
        />
    )
}

export default NwNavBarScouting;