
import React from 'react';
import { getMoment } from "Libs/NwMoment";
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { isAllDay } from 'Libs/NwMoment';

export const isAddressEmpty = (address) => {
    return (
        !address.Street || address.Street === null || address.Street === '' || address.Street === ' ')
        && (!address.City || address.City === null || address.City === '' || address.City === ' ')
        && (!address.Country || address.Country === null || address.Country === '' || address.Country === ' ');
}

export const getModelNames = (models) => {
    const getModelNameClass = (model) => {
        let classNames = '';
        if (model.Cancel) {
            classNames += 'is-cancelled ';
        }
        if (model.OptionNumber === 10) {
            classNames += 'is-confirmed';
        }
        return classNames.trim();
    }

    return (
        <div className="models-names-list">
            {
                models.map((m, index) => (
                    <>
                        <span 
                            className={getModelNameClass(m)} 
                            key={m.ModelID}
                        >
                            {getContactName(m.ModelID).toLowerCase()}
                        </span>
                        <em>{index < models.length - 1 && ', '}</em>
                    </>
                ))
            }
        </div>
    )
}

const compareAddress = (address, addressToCompare) => {
    let status = true;
    if (!addressToCompare) {
        return false;
    }
    Object.keys(address).forEach(addressKey => {
        if (address[addressKey] !== addressToCompare[addressKey]) {
            status = false;
        }
    })
    return status;
}

export const groupModels = (models, groupingData) => {
    let groupedModels = [{
        groupingData,
        models: []
    }]

    for (const model of models) {
        const modelData = {
            // FromDate: model.FromDate ? model.FromDate : groupingData.FromDate,
            // ToDate: model.ToDate ? model.ToDate : groupingData.ToDate,
            //StartBreak: model.StartBreak ? model.StartBreak : groupingData.StartBreak,
            //EndBreak: model.EndBreak ? model.EndBreak : groupingData.EndBreak,
            // Address: model.Address ? model.Address : groupingData.Address,
            // Notes: model.Notes ? model.Notes : groupingData.Notes
            FromDate: model.FromDate,
            ToDate: model.ToDate,
            StartBreak: model.StartBreak,
            EndBreak: model.EndBreak,
            Address: model.Address,
            Notes: model.Notes
        };

        const groupIndex = groupedModels.findIndex(groupedModel => {
            if (
                getMoment(modelData.FromDate).format('YYYY-MM-DD hh:mm a') === getMoment(groupedModel.groupingData.FromDate).format('YYYY-MM-DD hh:mm a')
                && getMoment(modelData.ToDate).format('YYYY-MM-DD hh:mm a') === getMoment(groupedModel.groupingData.ToDate).format('YYYY-MM-DD hh:mm a')
                && getMoment(modelData.StartBreak).format('YYYY-MM-DD hh:mm a') === getMoment(groupedModel.groupingData.StartBreak).format('YYYY-MM-DD hh:mm a')
                && getMoment(modelData.EndBreak).format('YYYY-MM-DD hh:mm a') === getMoment(groupedModel.groupingData.EndBreak).format('YYYY-MM-DD hh:mm a')
                && modelData.Notes === groupedModel.groupingData.Notes
                && compareAddress(modelData.Address, groupedModel.groupingData.Address)
            ) {
                return true;
            }
            return false;
        })

        if (groupIndex > -1) {
            groupedModels[groupIndex].models.push(model);
        } else {
            groupedModels.push({
                groupingData: modelData,
                models: [model]
            })
        }
    }

    return groupedModels;
}

export const sortGroupedModelsByCurrentModel = (groupedModels, currentModelId) => {
    const currentModelGroup = groupedModels.find(gm => gm.models.find(m => m.ModelID === currentModelId));
    let otherGroups = groupedModels.filter(gm => !gm.models.find(m => m.ModelID === currentModelId));
    otherGroups.sort((group1, group2) => {
        if (isAllDay(group1.groupingData.FromDate, group1.groupingData.ToDate)) {
            return -1;
        }
        if (group1.groupingData.FromDate < group2.groupingData.FromDate) {
            return -1;
        }
        return 1;
    })
    if (currentModelGroup) {
        return [currentModelGroup, ...otherGroups];
    }
    return [...otherGroups];
}

export const findModelInEventData = (eventData, modelId) => {
    let model;
    eventData.EventDates.forEach(eventDate => {
        if (model) return;
        model = eventDate.EventDatesModels.find(m => m.ID === modelId);
    });
    return model;
}

export const getModelActionName = (model, eventDateInfo) => {
    return `Remove ${getContactName(model.ModelID)} from ${getMoment(eventDateInfo.FromDate).format('ddd D MMM')}`
}