import React, { useContext, useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { ListContext } from "Contexts/ListContext";
import NwDrawer from "Components/Gui/NwDrawer";
import { NWInput, NWPaymentTermSelector, NWBankAccountsSelector, NWSwitch, NWSelect } from 'Components/Gui/NwForm/NwFormItems';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { useAddNewInvoice } from "Hooks/Invoices/UseInvoices";
import StyleVariables from 'Components/Gui/StyleVariables.js';
import { Alert } from "antd";
import { getCustomField, getCustomFieldInitialValue } from "Libs/NwCustomFields";
import { toISODate } from "Libs/NwMoment";
import NwDivider from "Components/Gui/NwDivider";
import useConfigurations from "Hooks/UseConfigurations";
import { checkTenant } from "Libs/NwUtils";

const InvoiceContent = styled.div`
    
    .contact-name {
        font-weight: bold;
        font-size: ${props => props.theme.FontSizeXLg};
        margin-bottom: 2rem;
    }

    .bold-text {
        font-weight: bold;
    }

    .statements-content {
        margin-bottom: 2rem;
        .contact-row {
            display: flex;
            flex-direction: row;
            font-size: ${props => props.theme.FontSizeSm};
            .select-all {
                color: ${StyleVariables.PrimaryColor};
                margin-left: 1rem;
                cursor: pointer;
                user-select: none;
            }
        }
        .tx-details {
            font-size: ${props => props.theme.FontSizeSm};
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .ant-checkbox-wrapper {
                font-size: ${props => props.theme.FontSizeSm};
            }
        }
    }
`
const CreateInvoiceDrawer = ({ onClose, data, onReload, onInvoiceCreated, projectCustomFields }) => {
    const { cachedList } = useContext(ListContext);
    const [touchForm, setTouchForm] = useState(false);
    const { mutateAsync: onAddNewInvoice } = useAddNewInvoice();
    const [errorCreating, setErrorCreating] = useState(null);

    const configurations = useConfigurations()

    const schemas = [];
    if (configurations && configurations.COA) {
        const COAjs = JSON.parse(configurations.COA);
        if (COAjs.schemas && COAjs.schemas.length > 0) {
            schemas.push(...COAjs.schemas);
        }

    }
    const enableCOA = schemas.length > 0;

    //get all custom fields
    const customFields = cachedList.customFields.items["Invoice"]
    const availableCustomFields = customFields.filter(field => !field.Disabled);

    let clientHasDefaultPaymentTerm = false;

    if (!cachedList || (!cachedList.paymentTerms || !cachedList.paymentTerms.items || cachedList.paymentTerms.items.length === 0) || (!cachedList.bankAccounts || !cachedList.bankAccounts.items || cachedList.bankAccounts.items.length === 0)) {
        return (
            <h1>Before creating your first invoice, please make sure that in the "settings" area you have configured at least a payment term and a bank account</h1>
        )
    }

    let initialPaymentTerm = cachedList.paymentTerms.items.find(el => el.IsDefault);
    let initialBankAccount = cachedList.bankAccounts.items.find(el => el.IsDefault);
    //if no default payment term or bank account, select the first one
    if (!initialPaymentTerm) {
        //TODO: check if the billing info has a default payment term
        initialPaymentTerm = cachedList.paymentTerms.items[0];
    }
    if (!initialBankAccount) {
        initialBankAccount = cachedList.bankAccounts.items[0];
    }
    //if no default payment term or bank account return error message
    if (!initialPaymentTerm || !initialBankAccount) {
        return (
            <h1>Before creating your first invoice, please make sure that in the "settings" area you have configured at least a payment term and a bank account</h1>
        )
    }

    if (data.BillingInfoDefaultPaymentTermCode) {
        initialPaymentTerm = cachedList.paymentTerms.items.find(el => el.Code === data.BillingInfoDefaultPaymentTermCode);
        clientHasDefaultPaymentTerm = true;
    }

    let initialCustomFieldsValues = {};
    availableCustomFields.map(customField => {
        let value = null
        if (projectCustomFields && projectCustomFields[customField.Name]) {
            value = projectCustomFields[customField.Name];
        }
        if (!customField.Disabled) {
            initialCustomFieldsValues = {
                ...initialCustomFieldsValues,
                [`CF_${customField.Name}`]: getCustomFieldInitialValue(customField.TypeName, value)
            };
        }
        return null;
    })

    const groupCommissionsDefault = checkTenant('elitemodels') ? false : true;

    const initialValues = {
        InvoiceName: data.ProjectName ? `Invoice for ${data.ProjectName}` : 'New Invoice',
        PaymentTermCode: initialPaymentTerm.Code,
        BankAccountID: initialBankAccount.ID,
        COASchema: null,
        GroupCommissions: (data.AgencyFeeCount && data.AgencyFeeCount) > 1 ? groupCommissionsDefault : false,
        ...initialCustomFieldsValues
    }

    const saveInvoice = async (values, setSubmitting) => {
        setSubmitting(true);
        try {
            let customFieldsData = {};
            Object.keys(values).map(key => {
                if (key.startsWith('CF_')) {
                    const newKey = key.replace('CF_', '');
                    if (values[key] !== '' && JSON.stringify(values[key]) !== JSON.stringify([]) && values[key]) {
                        const customField = availableCustomFields.find(item => item.Name === newKey);
                        if (customField.TypeName === 'MultipleChoice') {
                            customFieldsData = {
                                ...customFieldsData,
                                [newKey]: values[key].join(',')
                            }
                        } else if (customField.TypeName === 'Date') {
                            customFieldsData = {
                                ...customFieldsData,
                                [newKey]: toISODate(values[key])
                            }
                        } else {
                            customFieldsData = {
                                ...customFieldsData,
                                [newKey]: values[key]
                            }
                        }
                    }
                }
                return null;
            })

            const StatementRows = data.SelectedStatementObjects.map(item => {
                return (
                    { StatementID: item.ID, AccountCode: '' }
                )
            })

            if (enableCOA && values.COASchema) {
                const selectedSchema = schemas.find(schema => schema.code === values.COASchema);
                if (selectedSchema) {
                    StatementRows.forEach(item => {
                        //get the statement info to map the account code
                        const statement = data.SelectedStatementObjects.find(statement => statement.ID === item.StatementID);
                        item.AccountCode = selectedSchema.defaultAccountCode
                        if (statement && selectedSchema.overrides && selectedSchema.overrides.length > 0) {
                            selectedSchema.overrides.forEach(override => {
                                if (override.family === statement.Family) {
                                    if (typeof override.lineType === 'undefined') {
                                        item.AccountCode = override.accountCode
                                    } else {
                                        if (override.lineType === 'commission' && statement.isCommission) {
                                            item.AccountCode = override.accountCode
                                        }
                                        if (override.lineType === 'advance' && statement.isAdvance) {
                                            item.AccountCode = override.accountCode
                                        }
                                        if (override.lineType === 'standard' && !statement.isAdvance && !statement.isCommission) {
                                            item.AccountCode = override.accountCode
                                        }
                                    }
                                }
                            })
                        }
                    })
                }
            }

            const invoiceDataToPost = {
                ContactID: data.ContactID,
                BillingInfoID: data.BillingInfoID,
                Name: values.InvoiceName,
                PaymentTermCode: values.PaymentTermCode,
                BankAccountID: values.BankAccountID,
                Type: data.Type,
                Currency: data.Currency,
                StatementRows: StatementRows,
                GroupCommissions: values.GroupCommissions,
                CustomFields: customFieldsData
            }

            const invoiceId = await onAddNewInvoice({ data: invoiceDataToPost });
            onInvoiceCreated(invoiceId);
            if (onReload) {
                onReload();
            }

            setSubmitting(false);
        } catch (e) {
            setErrorCreating(`Error creating invoice: ${e.message}`)
            setSubmitting(false);
        }
    }

    const writeTerm = (term) => {
        let stringTerms = term.Code;
        if (term.Name !== term.Code) {
            stringTerms = `${stringTerms} - ${term.Name}`;
        }
        return stringTerms;
    }

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title="Create Invoice"
        >
            <InvoiceContent>
                <h3>Invoice Currency: {data.Currency}</h3>
                <p>Client default payment terms: {clientHasDefaultPaymentTerm ? writeTerm(initialPaymentTerm) : 'n.d.'}</p>
                <br />
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        saveInvoice(values, setSubmitting);
                    }}
                    validationSchema={Yup.object().shape({
                        InvoiceName: Yup.string().required('Title is required'),
                    })}
                >
                    {({ isSubmitting, values, handleSubmit, }) => (
                        <NwForm
                            values={values}
                            onTouchForm={setTouchForm}
                            onFinish={handleSubmit}
                        >
                            <Field
                                component={NWPaymentTermSelector}
                                label="Payment Terms"
                                name="PaymentTermCode"
                                value={values.PaymentTermCode}
                            />
                            <Field
                                component={NWBankAccountsSelector}
                                label="Bank Account"
                                name="BankAccountID"
                                value={values.BankAccountID}
                            />
                            <Field
                                component={NWInput}
                                label="Invoice Description"
                                name="InvoiceName"
                                maxLength={50}
                                type="text"
                                value={values.InvoiceName}
                            />
                            {(data.AgencyFeeCount && data.AgencyFeeCount > 1)
                                ?
                                <Field
                                    component={NWSwitch}
                                    label=""
                                    name="GroupCommissions"
                                    text='group agency fees in a single line'
                                    value={values.GroupCommissions}
                                />
                                : null}
                            {
                                errorCreating &&
                                <Alert style={{ margin: '1rem 0' }} message={errorCreating} type="error" />
                            }
                            {enableCOA &&
                                <>
                                    <br />
                                    <NwDivider>Chart of Account</NwDivider>
                                    <br />
                                    <Field
                                        component={NWSelect}
                                        label="Account Schema"
                                        name="COASchema"
                                        options={schemas.map(schema => ({ label: schema.code, value: schema.code }))}
                                        value={values.COASchema}
                                    />
                                </>
                            }
                            <br />
                            {(availableCustomFields.length > 0) &&
                                <>
                                    <NwDivider>Additional Info</NwDivider>
                                    <br />
                                    {availableCustomFields.map((customField, index) => {
                                        return <div key={`${customField.Name}-${index}`}>
                                            {getCustomField(values, customField, "CF_")}
                                        </div>
                                    })}
                                </>
                            }
                            <NwFormButtonsBar
                                marginTop="4rem"
                                left={
                                    <NwCancelButton
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            setTouchForm(false);
                                            onClose();
                                        }}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        htmlType="submit"
                                        loading={isSubmitting}
                                    //disabled={currencyValidation()}
                                    />
                                }
                            />
                        </NwForm>
                    )}
                </Formik>
            </InvoiceContent>
        </NwDrawer>
    );
}

export default CreateInvoiceDrawer;