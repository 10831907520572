import React from "react";

import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";

const FilterYesNoAllSelector = ({ onChange, value, disabled, size }) => {
    const onTypeChange = (val) => {
        if (val === "yes") onChange(true);
        if (val === "no") onChange(false);
        if (val === "all") onChange(null);
    }
    const getVal = () => {
        if (value === true) return "yes";
        if (value === false) return "no";
        if (value === null) return "all";
    }
    return (
        <RadioButtonGroup 
            value={getVal()}
            disabled={disabled}
            defaultValue={true}
            size={size}
            onChange={onTypeChange}
            options={[
                { label: 'Yes', value: "yes" },
                { label: 'No', value: "no" },
                { label: 'All', value: "all" }
            ]} />
    );
};

export default FilterYesNoAllSelector;

