import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Axios from 'axios';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
    NWTextAreaCharCounter,
    NWInput
} from "Components/Gui/NwForm/NwFormItems";
import { NwButton } from 'Components/Gui/NwButton';
import EmailSearch from 'Components/SendDrawer/EmailSearch';
import { NwForm } from 'Components/Gui/NwForm/NwFormWrapper';
import NwDrawer from 'Components/Gui/NwDrawer';
import NwDivider from 'Components/Gui/NwDivider';
import { NWList, NWListItem } from "Components/Gui/NWList";
import FlexContainer from 'Components/Gui/FlexContainer';
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';
import { Alert } from 'antd';

const EmailsContainer = styled.div`
    max-height: calc( 100vh - 400px );
    margin-bottom: 16px;
    overflow: auto;
`;

const EmailItem = styled(FlexContainer)`
    cursor: pointer;
    
    .email {
        font-weight: bold;
        text-transform: lowercase;
    }
`;

const Wrapper = styled.div`
    padding: 16px;
    .field-container {
        .ant-form-item {
            margin-bottom: 8px;
            padding: 0;
        }
    }
`;

const SendDocuments = ({ document, onClose }) => {
    useFormFieldFocus('Subject');
    const [recommendEmails, setRecommendEmails] = useState([]);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [errorSending, setErrorSending] = useState(null);

    const initialValues = {
        Subject: '',
        Message: ''
    };
    const [touchForm, setTouchForm] = useState(false);

    const handleSelectEmail = value => {
        handleAddEmail(value);
    };

    const handleUpdateRecommendEmails = emails => {
        setRecommendEmails(emails);
    };

    const handleGetRecommendEmails = async filter => {
        try {
            const response = await Axios.get(`/connections/emails/search/${filter}`);
            setRecommendEmails(response);
        } catch (error) {
            console.log('ant : Get recommend emails error => ', error);
        }
    };

    const handleAddEmail = value => {
        if (!selectedEmails.find(email => email.Email === value.Email)) {
            const addedEmail = recommendEmails.find(email => email.Email === value.Email);
            if (addedEmail) {
                setSelectedEmails([...selectedEmails, addedEmail]);
            } else {
                setSelectedEmails([...selectedEmails, value]);
            }
        }
    };

    const handleDeleteEmail = value => () => {
        setSelectedEmails(selectedEmails.filter(email => email.Email !== value));
    };

    const handleSendEmail = async (values, setSubmitting) => {
        setSubmitting(true);
        const recipients = selectedEmails.map(email => {
            if (email.ContactID) {
                return {
                    ContactID: email.ContactID,
                    ConnectionID: email.ConnectionID,
                    ContactDetails: [email.Email]
                }
            } else {
                return {
                    ContactDetails: [email.Email]
                }
            }
        });
        const data = [
            {
                Recipients: recipients,
                DocVersionGUID: document.DocGuid,
                SendMethod: "SendGrid",
                Subject: values.Subject,
                AsAttachment: true,
                Message: values.Message
            }
        ];
        try {
            await Axios.post('/docVersions/sendmessage', data);
            setTouchForm(false);
            setSubmitting(false);
        } catch (error) {
            //console.log('ant : Send document to emails error => ', error);
            setErrorSending("There was an error sending the message");
            setSubmitting(false);
        }
    }

    return (
        <NwDrawer
            padding="0px"
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            width={800}
            onClose={onClose}
            title={'E-mail Addresses'}>
            <EmailSearch
                options={recommendEmails}
                selectedItems={selectedEmails}
                addable={true}
                fullHeight={false}
                onSetOptions={handleUpdateRecommendEmails}
                onGetOptions={handleGetRecommendEmails}
                onSelectOption={handleSelectEmail}
                onAddItem={handleAddEmail} />
            <div>
                <NwDivider>Recipients</NwDivider>
                <EmailsContainer>
                    <NWList>
                        {selectedEmails.map((email, index) => (
                            <NWListItem
                                noPadding
                                key={`email-${index}`}
                                renderContent={() => (
                                    <EmailItem pd='8px 16px' justifyContent='space-between'>
                                        <div className='email'>{email.Email}</div>
                                        <NwButton ghost danger iconOnly size="small" icon={light("trash-alt")} onClick={handleDeleteEmail(email.Email)} />
                                    </EmailItem>
                                )}
                            />
                        ))}
                    </NWList>
                </EmailsContainer>
                <Wrapper>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={Yup.object().shape({
                            Subject: Yup.string().required("Content is required"),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            handleSendEmail(values, setSubmitting);
                        }}
                    >
                        {({ isSubmitting, values, handleSubmit }) => (
                            <NwForm
                                values={values}
                                onTouchForm={setTouchForm}
                                noValidate
                                onFinish={handleSubmit}
                                layout="vertical">
                                <Field
                                    component={NWInput}
                                    label="Subject"
                                    name="Subject"
                                    type="text"
                                    id='Subject'
                                    value={values.Subject}
                                    required
                                />
                                <Field
                                    component={NWTextAreaCharCounter}
                                    label="Message"
                                    name="Message"
                                    type="Message"
                                    value={values.Message}
                                    maxLength={2000}
                                    autoSize={{ minRows: 2, maxRows: 10 }}
                                />
                                <NwButton
                                    danger
                                    icon={light('envelope-open')}
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    label="Send"
                                    loadingLabel="Sending..."
                                />
                            </NwForm>
                        )}
                    </Formik>
                    {errorSending &&
                        <Alert style={{marginTop: "1rem"}} message="Error" description={errorSending} type="error" showIcon />
                    }
                </Wrapper>
            </div>
        </NwDrawer>
    )
};

export default SendDocuments;