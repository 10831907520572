import _ from 'lodash'

function sortByRender(events) {
    const sorted = _.sortBy(events, ['sortOrder'])
    return sorted
}

export default function dayRenderAlgorithm({
    events,
    minimumStartDifference,
    slotMetrics,
    accessors,
}) {
    const eventsInRenderOrder = sortByRender(events)
    // Return the original events, along with their styles.
    const eventsToShow = eventsInRenderOrder.map((ev) => ({
        event: {...ev},
        style: {
            top: "auto",
            height: "auto",
            width: 100,
            xOffset: 1
        },
    }))
    return eventsToShow
}