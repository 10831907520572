import React from 'react';
import styled from 'styled-components';
import NwIcon from 'Components/Gui/NwIcon';
import NwSlickSlider from "Components/Gui/NwSlickSlider";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const AddressSelectContainer = styled.div`
  padding: 30px 0px;
  .slick-arrow {
    top: 30px;
  }
  .slick-track {
    .slick-slide {
      padding: 0 8px;
	    box-sizing: border-box;
    }
  }
`;

const Slide = styled.div`
  padding: 10px;
  border: ${props => props.theme.DefaultBorder};
  min-height: 120px;
  cursor: pointer;
`;

const AddressFromContacts = ({
    connections,
    onChange,
}) => {

    const renderIcon = connectionType => {
        //to check: maybe to update
        switch (connectionType) {
            case "Place":
                return <NwIcon icon={light('home-alt')} />
            case "People":
                return <NwIcon icon={light('user')} />
            case "Room":
                return <NwIcon icon={light('suitcase-rolling')} />
            default:
                return <NwIcon icon={light('home-alt')} />
        }
    };

    return (
        <AddressSelectContainer>
            <NwSlickSlider slidesToShow={3} slidesToScroll={3} variableWidth={false} adaptiveHeight>

                {
                    connections.map(connection => connection.Addresses.map((address, index) => (
                        <Slide key={index} index={index} onClick={() => onChange(address)}>
                            <div>{renderIcon(connection.TypeName)} {connection.Name}</div>
                            <div>{address.Street || ''}</div>
                            <div>{address.City ? `${address.City},` : ''} {address.Country || ''}</div>
                        </Slide>
                    )))
                }
            </NwSlickSlider>
        </AddressSelectContainer>
    );
};

export default AddressFromContacts;