import React, { useContext, useState } from "react";
import { Formik, Field } from "formik";
import Axios from 'axios';

import {
    NWProjectSelector
} from "Components/Gui/NwForm/NwFormItems";
import { NwSaveButton, NwCancelButton } from "Components/Gui/NwButton";
import NwDrawer from 'Components/Gui/NwDrawer';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { PackageContext } from "../PackageContext";

const PackageLinkToProjectDrawer = ({ onClose }) => {
    const [touchForm, setTouchForm] = useState(false);

    const initialValues = { ProjectID: null, };
    
    const packageContextValue = useContext(PackageContext);
    const {groupedPackage, afterUpdatePackage} = packageContextValue;

    if (groupedPackage) {
        initialValues.ProjectID = groupedPackage.ProjectID;
    }
    
    const savePackage = (values, setSubmitting) => {
        setSubmitting(true);
        Axios.put(`/packages/linktoproject/${groupedPackage.ID}/${values.ProjectID}`)
        .then(() => {
            afterUpdatePackage() 
            setTouchForm(false);
            setSubmitting(false);
            onClose();           
        })
        .catch(error => {
            setSubmitting(false)
            //TODO: manage error on api call, may be by adding an ErrorMessage to the state and a condition to show it in render.
            console.log("ant : Update Package error => ", error);
        });
    };

    return (
        <NwDrawer 
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            title='Link Package To a Project' 
            onClose={onClose}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    savePackage(values, setSubmitting);
                }}
            >
                {({ values, isSubmitting, handleSubmit }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit} 
                        layout="vertical">
                        <Field
                            component={NWProjectSelector}
                            showSecondLevel
                            name="ProjectID"
                            value={values.ProjectID} />
                        <br/><br/>
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    )
}

export default PackageLinkToProjectDrawer

