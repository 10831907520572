import React from "react";
import NwDrawer from "Components/Gui/NwDrawer";
import ModelsSelectorWrapper from "Components/ContactsSelector/ModelsSelector/ModelsSelectorWrapper";
import { ModelContextProvider } from "Contexts/ModelContext";

const AddModelsToNonBookingEvent = ({ event, projectId, eventType, onClose, onUpdateEvent }) => {
    return (
        <NwDrawer
            id="add-models-drawer"
            padding="0px"
            width='80vw'
            title='Add Models To Non-Booking Event'
            onClose={onClose}>
            <ModelContextProvider modelSelected={null}>
                <ModelsSelectorWrapper
                    currentEvent={{ ...event, TypeName: eventType }}
                    defaultView="table"
                    fixedView
                    isDrawer={true}
                    layouttype="fullpage"
                    itemClickAction="preview-drawer"
                    multipleSelection={false}
                    projectId={projectId}
                    scope="nonbooking-event"
                />
            </ModelContextProvider>
        </NwDrawer>
    );
}

export default AddModelsToNonBookingEvent;
