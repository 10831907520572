import React, { useContext } from 'react';
import Select from 'Components/Gui/NwForm/CustomSelect';
import { ListContext } from "Contexts/ListContext";

const FilterFollowUpsOnLabels = ({ value, onChange }) => {
    const {cachedList} = useContext(ListContext)
    const followUpLabels = cachedList.followUpLabels.items.filter(item => !item.Disabled) || []
    const options = []
    
    if (followUpLabels) {
        for (const followupLabel of followUpLabels) {
            options.push({
                value: followupLabel.Code,
                label: followupLabel.Label
            })
        }
    }
    
    const onLabelChange = (val) => {
        onChange({
            ...value,
            labels: [...val]
        });
    }
    return (
        <Select
            width='100%'
            value={value.labels || []}
            placeholder={'All'} 
            allowClear
            onChange={onLabelChange}
            options={options}
            mode="multiple"
        />
    );

}
export default FilterFollowUpsOnLabels;
