import React, { useContext, useMemo } from 'react';
import { Popover } from 'antd';
import { ListContext } from 'Contexts/ListContext';
import NwIcon from 'Components/Gui/NwIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

const ContactDepartments = ({ departments }) => {
    const { cachedList } = useContext(ListContext);
    
    const DepartmentAndAgencyName = ({ departmentID }) => {
        for (const accGroup of cachedList.accountingGroups.items) {
            for (const nwAg of accGroup.NetworkAgencies) {
                for (const department of nwAg.Departments) {
                    if (department.ID === departmentID) {
                        return (<p>{nwAg.Name} / <strong>{department.Name}</strong></p>)
                    }
                }
            }
        }
        return null;
    }

    const displayLabels = useMemo(() => {
        return(`${departments.length} dept${(departments.length > 1) ? 's' : ''}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departments]);

    return (
        <Popover
            title='Departments'
            mouseEnterDelay={.6}
            content={
                <>
                    {departments.map((dept, index) => (
                        <DepartmentAndAgencyName key={index} departmentID={dept.DepartmentID} />
                    ))}
                </>
            }
        >
            <p>
                <NwIcon icon={light("people-group")} />&nbsp;&nbsp;{displayLabels}
            </p>
        </Popover>

    )
};

export default ContactDepartments;