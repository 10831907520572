import React, { createContext, useContext, useMemo, useReducer } from 'react';
import Axios from 'axios';
import _ from 'lodash';
import ls from 'local-storage';


export const UserGuiConfigContext = createContext();
const UserGuiConfigDispatchContext = createContext();

export function UserGuiConfigContextProvider({ children }) {
    const contextData = useMemo(() => ({
        calendar: {
            timeFormat: "24",
            weekFormat: "monday"
        },
        formSettings: {},
        metrics: [],
        numbers: {
            format: "USD0.00", 
            locale: "en-US"
        },
    }), []);

    const [data, dispatch] = useReducer(userGuiConfigReducer, contextData);

    return (
        <UserGuiConfigContext.Provider value={data}>
            <UserGuiConfigDispatchContext.Provider value={dispatch}>
                {children}
            </UserGuiConfigDispatchContext.Provider>
        </UserGuiConfigContext.Provider>
    );
}

export function useUserGuiConfig() {
    return useContext(UserGuiConfigContext);
}

export function useUserGuiConfigDispatch() {
    return useContext(UserGuiConfigDispatchContext);
}

const saveSettings = async (updatedSettings) => {
    const localStorageName = "nw_config_userguiconfig"
    ls.set(localStorageName, updatedSettings)
    
    try {
        await Axios.post('/userclientconfigurations', {
            Name: 'settings',
            JsonData: JSON.stringify({
                settings: {...updatedSettings}
            })
        });
    } catch (error) {
        console.log('ant : Update User Gui Config Error => ', error);
    }
};

const userGuiConfigReducer = (state, action) => {
    
    switch (action.type) {
        case "Update":
            saveSettings(action.value);
            return {
                ...action.value
            };
        case "Clear":
            saveSettings({})
            return {};
        default:
            return state;
    }
};
