import React from 'react';
import { Field } from "formik";
import { NWSelect } from "Components/Gui/NwForm/NwFormItems";

const TransactionLabelsSelector = ({ values, setFieldValue, transactionProperties, onChange, updateTitle}) => {
    
    const getTransactionLabel = transactionLabelID => transactionProperties.labels.find(label => label.ID === transactionLabelID);
    
    const getEventName = (values) => {
        if (values.SelectedEvent) {
            if (values.SelectedEvent.Name) {
                return " for " + values.SelectedEvent.Name
            }
            if (values.SelectedEvent.Title) {
                return " for " + values.SelectedEvent.Title
            }
        }
        return ""
    }

    return (
        <Field
            component={NWSelect}
            label="Label"
            name="TransactionLabelID"
            value={values.TransactionLabelID}
            options={transactionProperties.labels.filter(l => !l.Disabled).map(transactionLabel => {
                return { key: transactionLabel.ID, label: transactionLabel.Label, value: transactionLabel.ID };
            })}
            onAfterChange={(value) => {
                const transactionLabel = getTransactionLabel(value)
                if (transactionLabel.TypeQuantityName) {
                    setFieldValue('TypeQuantity', transactionLabel.TypeQuantityName)
                }
                if (transactionLabel.FixedTarif) {
                    setFieldValue('Amount', [values.Amount[0], transactionLabel.TotalFee])
                }
                if (updateTitle) {
                    setFieldValue('Title', `${transactionLabel.Label}${getEventName(values)}`)
                }
                if (onChange) {
                    onChange()
                }
            }}
        />
    )
}

export default TransactionLabelsSelector;

