import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import NwIcon from 'Components/Gui/NwIcon';
import NwDrawer from 'Components/Gui/NwDrawer';
import FlexContainer from 'Components/Gui/FlexContainer';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import InvoicePage from './InvoicePage/InvoicePage';
import { forceReloadBillingProjectsSearch } from 'Hooks/Project/UseProject';
import { forceReloadSearchInvoices } from 'Hooks/Invoices/UseInvoices';

const Actions = styled(FlexContainer)`
    svg {
        margin-left: 8px;
    }
`;

const InvoiceDrawer = ({invoiceId, onClose}) => {
    
    const breakpoints = useNwBreakPoints();
    const navigate = useNavigate();

    const handleMoveInvoiceDetails = () => {
        navigate(`/invoice/${invoiceId}`)
    }

    const handleClose = () => {
        forceReloadSearchInvoices()
        forceReloadBillingProjectsSearch()
        onClose()
    }

    return (
        <NwDrawer
            width={"90vw"}
            padding="1rem"
            title={
                <FlexContainer justifyContent='space-between'>
                    <div>Invoice Details</div>
                    {breakpoints > 3 &&
                        <Actions>
                            <NwIcon icon={solid('expand-wide')} pointer onClick={handleMoveInvoiceDetails} size='lg' />
                        </Actions>
                    }
                </FlexContainer>
            }
            onClose={handleClose}
            whiteBg={false}
        >
            <InvoicePage invoiceId={invoiceId} onClose={handleClose} inDrawer />
        </NwDrawer>
    )
}

export default InvoiceDrawer;