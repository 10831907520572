import React, { useEffect, useState } from "react";
import { Select } from 'antd';
import Axios from 'axios';

import ContactDropdown from "Components/ContactDropdown/ContactDropdown";
import MiniLoading from 'Components/Gui/MiniLoading';

const ModelPlayListSelector = ({ onChange, value, onBlur }) => {
    const [isFetchingBooks, setIsFetchingBooks] = useState(false);
    const [modelBooks, setModelBooks] = useState([{
        value: 'anybook',
        label: 'any book'
    }]);

    useEffect(() => {
        if (value.modelID) {
            getModelBooks(value.modelID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangeModel = modelID => {
        getModelBooks(modelID);
    };

    const getModelBooks = async modelID => {
        setIsFetchingBooks(true);
        if (!modelID) return;
        try {
            const playlists = await Axios.get(`/models/getPlaylists/${modelID}`);
            let modelBooks = [{
                value: null,
                label: 'any book'
            }];
            for(const playlist of playlists){
                modelBooks.push({
                    value: playlist.ID,
                    label: playlist.Name
                });
            }
            setModelBooks(modelBooks);
            setIsFetchingBooks(false);
        } catch (error) {
            console.log('ant : Get Model Playlists Error => ', error);
            setIsFetchingBooks(false);
        }
    }

    const onModelIDChange = (val) => {
        handleChangeModel(val);
        onChange({
            modelID: val,
            playlistID: null
        })
    }

    const onPlaylistIDChange = (val) => {
        onChange({
            ...value,
            playlistID: val
        })
    }

    return (
        <div>
            <div style={{marginBottom: "30px"}}>
                <ContactDropdown
                    activeTypes={['mod']}
                    value={value.modelID}
                    onChange={onModelIDChange}
                    style={{ width: '100% '}}
                />
            </div>
            {value.modelID && (
                isFetchingBooks ? 
                        <MiniLoading />
                    :
                    <div>
                        <div style={{marginBottom: "10px"}}>Select Playlist</div>
                        <Select
                            autoComplete='off'
                            value={value.playlistID}
                            onChange={onPlaylistIDChange}
                            options={modelBooks} 
                            onBlur={onBlur}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default ModelPlayListSelector;
