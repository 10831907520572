import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Field } from "formik";

import { NwCancelButton, NwButton } from "Components/Gui/NwButton";
import { NwForm } from 'Components/Gui/NwForm/NwFormWrapper';
import { NWInputNumber } from "Components/Gui/NwForm/NwFormItems";
import NwDrawer from 'Components/Gui/NwDrawer';
import { showLogNoTime } from 'Libs/NwMoment';
import { forceReloadTransaction } from 'Hooks/Transaction/UseTransaction';
import useConfigurations from 'Hooks/UseConfigurations';
import { forceReloadProjectTransactions } from 'Hooks/Project/UseProjectTransactions';
import Axios from 'axios';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Popover } from 'antd';
import Money from 'Components/Gui/Money';
import NwLink from 'Components/Gui/NwLink';

const ExchangeFormContainer = styled.div`
    padding-top: 2rem;

    p {
        font-size: .85rem;
        font-weight: 400;
        color: ${props => props.theme.TextColorMuted};
        text-transform: uppercase;

        svg {
            color: ${props => props.theme.TextColor};
        }
    }

    .form-line {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
            padding: 0 .5rem .75rem .5rem;
        }
    }

   
`

const ExchangeRateForm = ({ statement, transaction, asCurrency, disabled }) => {
    const [editable, setEditable] = useState(false);
    const [touchForm, setTouchForm] = useState(false);

    const configuration = useConfigurations();

    let initialValues = {
        ExchangeRate: (statement && statement.ExchangeRate && statement.ExchangeRate > 0) ? statement.ExchangeRate : 1
    };

    const callApi = async ( exchangeRate ) => {
        if (statement) {
            try {
                const data = {
                    StatementID: statement.ID,
                    ExchangeRate: exchangeRate,
                    TransactionID: transaction.ID
                }
                await Axios.put(`/transactions/${transaction.Family}/modifystatementexchangerate`, data)
                forceReloadTransaction(transaction.ID)
                if (transaction.ProjectID) {
                    forceReloadProjectTransactions(transaction.ProjectID)
                }
                setTouchForm(false);
                setEditable(false);
            } catch (error) {
                console.log('ant : Modify Exchange Rate Error => ', error);
            }
        } else {
            try {
                const data = {
                    ExchangeRate: exchangeRate,
                    TransactionID: transaction.ID
                }
                await Axios.put(`/transactions/${transaction.Family}/modifyexchangerate`, data)
                forceReloadTransaction(transaction.ID)
                if (transaction.ProjectID) {
                    forceReloadProjectTransactions(transaction.ProjectID)
                }
                setTouchForm(false);
                setEditable(false);
            } catch (error) {
                console.log('ant : Modify Exchange Rate Error => ', error);
            }
        }
    }

    const submitForm = async (values, setSubmitting) => {
        setSubmitting(true);
        await callApi(values.ExchangeRate)
        setSubmitting(false);
    };

    const clearConversion = async () => {
        await callApi(0)
    };

    const FormTrigger = ({ }) => {
        if (!statement) {
            return (
                <NwLink disabled={disabled} onClick={() => { setEditable(true) }} icon={light("money-bill-transfer")}>Set Statements Exchange Rate</NwLink>
            )
        }
        if (asCurrency) {
            return (
                (statement && (statement.ExchangeRate > 0)
                    ?
                    <Popover
                        zIndex={9999}
                        placement="left"
                        trigger='hover'
                        title="Foreign Currency"
                        content={
                            <>
                                <p><Money redIfNegative amount={statement.Amount} currency={transaction.Currency} /></p>
                                <p>Exchange Rate:&nbsp;{statement.ExchangeRate} [{showLogNoTime(statement.ExchangeDate)}]</p>
                            </>
                        }
                    >
                        <NwLink disabled={disabled} onClick={() => { setEditable(true) }}>{configuration.defaultCurrency}</NwLink>
                    </Popover>
                    :
                    <NwLink disabled={disabled} onClick={() => { setEditable(true) }}>{transaction.Currency}</NwLink>
                )
            )
        }
    }

    return (
        <>
            <FormTrigger />
            {editable &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    onClose={() => {
                        setEditable(false);
                        setTouchForm(false);
                    }}
                    title='Edit Exchange Rate'>
                    <ExchangeFormContainer>
                        <p>Exchange rate</p>
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                setStatus();
                                submitForm(values, setStatus, setSubmitting);
                            }}
                        >
                            {({ isSubmitting, values, handleSubmit }) => {
                                return (
                                    <NwForm
                                        values={values}
                                        onTouchForm={setTouchForm}
                                        onFinish={handleSubmit}
                                        >
                                        <div className="form-line">
                                            <span>1 <strong>{transaction.Currency}</strong> = </span>
                                            <Field
                                                component={NWInputNumber}
                                                label=""
                                                name="ExchangeRate"
                                                min={0}
                                                step={0.0001}
                                                value={values.ExchangeRate}
                                                style={{ width: '10rem' }}
                                            />
                                            <span> <strong>{configuration.defaultCurrency}</strong></span>
                                            <span>
                                                <NwButton
                                                    htmlType="submit"
                                                    disabled={isSubmitting}
                                                    loading={isSubmitting}
                                                    icon={solid("money-bill-transfer")}
                                                    label="Convert"
                                                />
                                            </span>
                                        </div>
                                        <br/><br/>
                                        <NwLink onClick={clearConversion}>Clear Conversion and Switch Back To {transaction.Currency}</NwLink>
                                        <br/><br/><br/>
                                        <NwCancelButton
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                setTouchForm(false);
                                                setEditable(false)
                                            }}
                                        />
                                        <br/><br/>
                                    </NwForm>
                                )
                            }}
                        </Formik>
                    </ExchangeFormContainer>
                </NwDrawer>
            }
        </>
    );
};

export default ExchangeRateForm;