import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { getMoment, getNow } from "Libs/NwMoment";

import { NwSaveButton, NwCancelButton } from "Components/Gui/NwButton";
import {
    NWSelect,
    NWDatePicker,
    NWInput,
    NWSelectCountry,
    NWTextAreaCharCounter
} from "Components/Gui/NwForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
//import { LEGAL_TYPES } from "Configs/LEGAL_TYPES";
import { ListContext } from 'Contexts/ListContext';
import { useContact } from 'Hooks/Contact/UseContact';
import { useAddLegal, useEditLegal } from 'Hooks/Contact/UseContactLegals'

const LegalForm = ({ ContactID, Doc, onCancel, onTouchForm }) => {
    const { data: currentContact } = useContact(ContactID);
    const { mutateAsync: addLegal } = useAddLegal()
    const { mutateAsync: editLegal } = useEditLegal()
    const [isYearlyRequired, setIsYearlyRequired] = useState(false);
    const { cachedList } = useContext(ListContext);
    const legalTypes = cachedList.legalTypes.items;

    //console.log("legalTypes", legalTypes);

    useEffect(() => {
        let currentType = Doc ? legalTypes.find(el => el.Code === Doc.Type) : legalTypes[0];
        setIsYearlyRequired(currentType && currentType.YearlyRequired);
    }, [Doc, legalTypes]);

    const initialValues = {
        ID: 0,
        ContactID: ContactID,
        CountryCode: Doc ? (currentContact ? (currentContact.NationalityCode ? currentContact.NationalityCode : currentContact.NAT) : null) : null,
        Type: legalTypes[0].Code,
        DocNumber: "",
        StartDate: null,
        ExpirationDate: null,
        RemindDate: null,
        Details: "",
        YearRef: getNow().year(),
        // "Documents": []
    };

    if (Doc) {
        initialValues.ID = Doc.ID;
        initialValues.CountryCode = Doc.CountryCode;
        initialValues.Type = Doc.Type;
        initialValues.DocNumber = Doc.DocNumber;
        initialValues.StartDate = Doc.StartDate && getMoment(Doc.StartDate);
        initialValues.ExpirationDate = Doc.ExpirationDate && getMoment(Doc.ExpirationDate);
        initialValues.Details = Doc.Details;
        initialValues.YearRef = Doc.YearRef;
        if (Doc.RemindDate) {
            const d1 = getMoment(Doc.ExpirationDate);
            const d2 = getMoment(Doc.RemindDate);
            initialValues.RemindDate = `${d1.diff(d2, "months")}`;
        }
    }

    const saveLegal = async (values, setSubmitting) => {
        const ID = initialValues.ID;
        const data = {
            CountryCode: values.CountryCode,
            Type: values.Type,
            DocNumber: values.DocNumber,
            StartDate: values.StartDate ? getMoment(values.StartDate) : null,
            ExpirationDate: values.ExpirationDate ? getMoment(values.ExpirationDate) : null,
            Details: values.Details,
            ContactID
        };

        const rd = parseInt(values.RemindDate);
        if (rd) {
            data.RemindDate = getMoment(values.ExpirationDate).subtract(rd, "months").format();
        }
        if (isYearlyRequired) {
            data.YearRef = values.YearRef;
        }
        if (ID) {
            data.ID = ID;
            await editLegal(data);
            onTouchForm(false);
            setSubmitting(false);
            onCancel();
        } else {
            await addLegal(data);
            onTouchForm(false);
            setSubmitting(false);
            onCancel();
        }
    };

    const getYearOptions = () => {
        const currentYear = getNow().year();
        const options = [];
        for (let i = currentYear < 20; i < currentYear + 5; i++) {
            options.push({ key: i, value: i, label: i });
        }
        return options;
    }

    return (
        <Formik
            initialValues={initialValues}
            // validationSchema={null}
            validationSchema={Yup.object().shape({
                Type: Yup.string().required("Content is required"),
                DocNumber: Yup.string().required("Content is required"),
                // CountryCode: Yup.string().required("Content is required")
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                saveLegal(values, setSubmitting);
            }}
        >
            {({ isSubmitting, values, handleSubmit }) => (
                <NwForm
                    values={values}
                    onTouchForm={onTouchForm}
                    onFinish={handleSubmit}
                    layout="vertical">
                    <Row gutter={16}>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            {/* Type */}
                            <Field
                                component={NWSelect}
                                label="Document type"
                                name="Type"
                                value={values.Type}
                                options={legalTypes.map(el => {
                                    return { key: el.Code, value: el.Code, label: el.Name };
                                })}
                                onAfterChange={(value) => {
                                    const type = legalTypes.find(el => el.Code === value);
                                    setIsYearlyRequired(type && type.YearlyRequired);
                                }}
                            />
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            {/* Document number */}
                            <Field
                                component={NWInput}
                                label="Document number"
                                name="DocNumber"
                                type="text"
                                // required
                                value={values.DocNumber}
                            />
                        </Col>
                    </Row>
                    {/* CountryCode */}
                    <Field
                        component={NWSelectCountry}
                        label="Enter issuing country"
                        name="CountryCode"
                        value={values.CountryCode}
                    />
                    <Row gutter={16}>
                        {isYearlyRequired &&
                            <Col lg={8} md={8} sm={12} xs={24}>
                                {/* Yearly */}
                                <Field
                                    component={NWSelect}
                                    width="8rem"
                                    label="Year"
                                    name="YearRef"
                                    value={values.YearRef}
                                    options={getYearOptions()}
                                />
                            </Col>
                        }
                        <Col lg={8} md={8} sm={12} xs={24}>
                            {/* Issue date */}
                            <Field
                                component={NWDatePicker}
                                label="Issue date"
                                name="StartDate"
                                format="MMM DD, YYYY"
                                style={{ width: "100%" }}
                                value={values.StartDate ? getMoment(values.StartDate) : null}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            {/* Expiration date */}
                            <Field
                                component={NWDatePicker}
                                label="Expiration date"
                                name="ExpirationDate"
                                format="MMM DD, YYYY"
                                style={{ width: "100%" }}
                                value={values.ExpirationDate ? getMoment(values.ExpirationDate) : null}
                            />
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={24}>
                            {/* RemindDate */}
                            <Field
                                component={NWSelect}
                                label="Reminder"
                                name="RemindDate"
                                disabled={!values.ExpirationDate}
                                value={values.RemindDate}
                                options={[
                                    { label: "No Reminder", key: "0", value: "0" },
                                    { label: "1 Month", key: "1", value: "1" },
                                    { label: "3 Months", key: "3", value: "3" },
                                    { label: "6 Months", key: "6", value: "6" }
                                ]}
                            />
                        </Col>
                    </Row>
                    {/* Details */}
                    <Field
                        component={NWTextAreaCharCounter}
                        label="Details"
                        name="Details"
                        value={values.Details}
                        maxLength={2000}
                        type="text"
                        autoSize={{ minRows: 5, maxRows: 10 }}
                    />
                    <br />
                    <NwFormButtonsBar
                        left={
                            <NwCancelButton
                                disabled={isSubmitting}
                                onClick={() => {
                                    onTouchForm(false);
                                    onCancel();
                                }}
                            />
                        }
                        right={
                            <NwSaveButton
                                htmlType="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                            />
                        }
                    />
                </NwForm>
            )}
        </Formik>
    );
};

export default LegalForm;
