import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { List } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwEditButton } from "Components/Gui/NwButton";
import NwIcon from 'Components/Gui/NwIcon';
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import ContactConnectionDrawer from "Components/Connection/ContactConnectionDrawer";
import { PhoneLine, MailLine, ContactAddressLine } from "Components/Gui/NwContactInfo";
import NwDivider from 'Components/Gui/NwDivider';
import NwEllipsis from 'Components/Gui/NwEllipsis';
import { useContactConnections } from "Hooks/Contact/UseContactConnections";
import { useContact } from 'Hooks/Contact/UseContact';
import { useUserPermission } from 'Contexts/UserContext';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { showDateNoTime } from 'Libs/NwMoment';
import { Loading } from 'Components/Gui/Loading';
import { NwTag } from 'Components/Gui/NwTag';
import { NotAllowedText } from 'Libs/NwTexts';

const Wrapper = styled.div`
    height: ${props => !props.fluid && '100%'};
    .ant-card {
        height: ${props => !props.fluid && '100%'};
    }
    .ant-card-body-content {
        padding: ${props => !props.fluid && 0} !important;
    }

    .content-wrapper {
        padding: 1rem;
        .default-connections-bar {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }
        .lists-container {
            padding: ${props => props.$fullWidth ? '1rem 2rem' : '0'};
            display: flex;
            flex-direction: ${props => props.$fullWidth ? 'row' : 'column'};

            .lists-container-addresses {
                padding: ${props => props.$fullWidth ? '0 1rem 0 0' : '0'};
                
                flex: 1 1 0;

                .ant-list {
                    border-right: ${props => props.$fullWidth ? props.theme.DefaultBorder : 'none'};
                }
            }
            .lists-container-staff {
                margin: ${props => props.$fullWidth ? '0 0 0 1rem' : '0'};
                flex: 1 1 0;
            }
        }
    }
`;

const StyledListItem = styled(List.Item)`
    padding: .5rem 0;
    .ant-list-item-meta {
        align-items: flex-start;
        .ant-list-item-meta-avatar {
 
        }
        .ant-list-item-meta-content {
            .ant-list-item-meta-title {
                margin-bottom: 0;
            }
            .ant-list-item-meta-description {
                color: ${props => props.theme.TextColor};
                font-size: ${props => props.theme.FontSize};

                .addresses-and-phones {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .addresses {
                        padding-top: .25rem;
                        padding-right: 2rem;
                        line-height: 1.1em;
                        flex: 1 1 0;
                    }

                    .phones {
                        flex: 1 1 0;
                    }
                }

                .notes-and-phones {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .notes {
                        padding-top: .25rem;
                        padding-right: 2rem;
                        line-height: 1.1em;
                        flex: 1 1 0;
                    }

                    .phones {
                        flex: 1 1 0;
                    }
                }
            }
        }
    }
`

const LockContainer = styled.div`
    display: flex;
    align-items: middle;
    opacity: .6;
    margin: 2rem auto 1rem auto;
    gap: 1rem;
    .icon-container {
        font-size: 2rem;
    }
    p {
        font-size: .9rem;
        max-width: 20rem;
        text-transform: uppercase;
    }
`;

const PhoneWrapper = styled.div`
    cursor: pointer;
`;

const CustomerDetailsConnections = ({ currentContact, currentContactConnections, contactID, readOnly, fluid, isInCardTab, fullWidth }) => {
    const [selectedEmail, setSelectedEmail] = useState();
    const [isAddConnectionModal, setAddConnectionModal] = useState(false);
    const [isEditConnection, setIsEditConnection] = useState(false);
    const [connectionId, setConnectionId] = useState();
    const breakpoints = useNwBreakPoints();

    const viewClientConnections = useUserPermission('ViewClientConnections', currentContact && currentContact.Users.map(user => user.UserID));
    const viewAgencyConnections = useUserPermission('ViewAgencyConnections', currentContact && currentContact.Users.map(user => user.UserID));
    const viewServiceConnections = useUserPermission('ViewServiceConnections', currentContact && currentContact.Users.map(user => user.UserID));

    const viewConnections = useMemo(() => {
        if (currentContact) {
            if (currentContact.SubType === 1) return viewClientConnections;
            if (currentContact.SubType === 2) return viewAgencyConnections;
            return viewServiceConnections;
        }
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentContact, currentContactConnections, viewClientConnections, viewAgencyConnections, viewServiceConnections]);

    const { staff, addresses } = useMemo(() => {
        return {
            staff: currentContactConnections ? currentContactConnections.filter(connection => connection.TypeName === 'People') : [],
            addresses: currentContactConnections ? currentContactConnections.filter(connection => connection.TypeName === 'Place') : []
        };
    }, [currentContactConnections]);

    // const handleCloseModal = () => {
    //     setSelectedEmail();
    //     setAddConnectionModal(false);
    // };

    // const handleUpdateEmail = email => {
    //     setSelectedEmail(email);
    // };

    const handleOpenConnection = connection => () => {
        setConnectionId(connection.ID);
        setIsEditConnection(true);
    };

    const handleEditConnection = () => {
        setIsEditConnection(true);
    };

    const handleCloseEditConnection = () => {
        setIsEditConnection(false);
        if (connectionId) {
            setConnectionId();
        }
    };

    const renderConnections = () => {
        return (
            <>
                {viewConnections ?
                    <div className='content-wrapper'>
                        {currentContact.DefaultConnection &&
                            <div className='default-connections-bar'>
                                {currentContact.DefaultConnection.Mobile &&
                                    <PhoneLine
                                        justify='flex-start'
                                        noIcon
                                        mode='button'
                                        phoneNumber={currentContact.DefaultConnection.Mobile}
                                        phoneType="Mobile" />
                                }
                                {currentContact.DefaultConnection.Phone &&
                                    <PhoneLine
                                        justify='flex-start'
                                        noIcon
                                        mode='button'
                                        phoneNumber={currentContact.DefaultConnection.Phone}
                                        phoneType="Land" />
                                }
                                {currentContact.DefaultConnection.Email &&
                                    <MailLine
                                        justify='flex-start'
                                        noIcon
                                        mode='button'
                                        mailAddress={currentContact.DefaultConnection.Email}
                                        mailCC={currentContact.DefaultConnection.CC}
                                    />
                                }
                            </div>
                        }
                        <div className='lists-container'>
                            <div className='lists-container-addresses'>
                                <NwDivider>Addresses</NwDivider>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={addresses}
                                    renderItem={connection => (
                                        <StyledListItem>
                                            <List.Item.Meta
                                                title={
                                                    <div style={{ cursor: "pointer" }} onClick={handleOpenConnection(connection)}>
                                                        {(connection.TypeName === 'Place')
                                                            ?
                                                            <strong>{connection.Name}</strong>
                                                            :
                                                            <>
                                                                <strong>{connection.Name}</strong>
                                                                <br />
                                                                {`${connection.SubTypeName}${connection.Role ? ` / ${connection.Role}` : ''}`}
                                                            </>
                                                        }
                                                    </div>
                                                }
                                                description={
                                                    <div className="addresses-and-phones">
                                                        <div className="addresses">
                                                            {connection.Addresses.map(address => (
                                                                <ContactAddressLine justify={'flex-start'} key={`address-${address.ID}`} address={address} noIcon />
                                                            ))}
                                                        </div>
                                                        <div className="phones">
                                                            {connection.Emails.map(email => (
                                                                <div key={email.ID}>
                                                                    <a href={`mailto:${email.Address}`}><NwIcon icon={light('envelope')} /> {email.Address}</a>
                                                                </div>
                                                            ))}
                                                            {connection.Phones.map(phone => (
                                                                <PhoneWrapper key={phone.ID}>
                                                                    <PhoneLine justify={'flex-start'} phoneNumber={phone.Number} phoneType={phone.Type} noIcon />
                                                                </PhoneWrapper>
                                                            ))}
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </StyledListItem>
                                    )}
                                />
                            </div>
                            <div className='lists-container-staff'>
                                <NwDivider>Staff</NwDivider>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={staff}
                                    renderItem={connection => (
                                        <StyledListItem>
                                            <List.Item.Meta
                                                title={
                                                    <div style={{ cursor: "pointer" }} onClick={handleOpenConnection(connection)}>
                                                        <strong>{connection.Name} {connection.Surname}</strong>
                                                        <NwTag style={{ marginLeft: "1rem" }} >{connection.Role || 'No Role'}</NwTag>
                                                    </div>
                                                }
                                                description={
                                                    <div className="notes-and-phones">
                                                        <div className="notes">
                                                            {connection.Birthday &&
                                                                <p><NwIcon icon={light('birthday-cake')} />&nbsp;{showDateNoTime(connection.Birthday)}</p>
                                                            }
                                                            {connection.Note &&
                                                                <NwEllipsis noFontSize text={connection.Note} popover rows={2} />
                                                            }
                                                        </div>
                                                        <div className="phones">
                                                            {connection.Emails.map(email => (
                                                                <div key={email.ID}>
                                                                    <a href={`mailto:${email.Address}`}><NwIcon icon={light('envelope')} /> {email.Address}</a>
                                                                </div>
                                                            ))}
                                                            {connection.Phones.map(phone => (
                                                                <PhoneWrapper key={phone.ID}>
                                                                    <PhoneLine justify={'flex-start'} phoneNumber={phone.Number} phoneType={phone.Type} noIcon />
                                                                </PhoneWrapper>
                                                            ))}
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </StyledListItem>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    :
                    <LockContainer justifyContent='center' alignItems='center'>
                        <div className='icon-container'>
                            <NwIcon icon={light('lock-alt')} />
                        </div>
                        <p>{NotAllowedText}</p>
                    </LockContainer>
                }
            </>
        )
    }

    return (
        <Wrapper fluid={fluid} $fullWidth={fullWidth}>
            {isInCardTab ? renderConnections()
                :
                <NwCard
                    title="Contacts"
                    icon={light("address-card")}
                    padding={fluid && 'reduced'}
                    extra={
                        <NwEditButton disabled={!viewConnections} ghost onClick={handleEditConnection} />
                    }
                >
                    {renderConnections()}
                </NwCard>
            }
            {/* {isAddConnectionModal &&
                        <AddToMailingListDrawer
                            isAddConnectionModal={isAddConnectionModal}
                            onCloseModal={handleCloseModal}
                            selectedEmail={selectedEmail}
                            fetchData={updateContactConnections}
                            onUpdateEmail={handleUpdateEmail} />} */}
            {isEditConnection &&
                <ContactConnectionDrawer connectionId={connectionId} contactSubType={currentContact.SubType} contactId={contactID} readOnly={readOnly} onClose={handleCloseEditConnection} />
            }
        </Wrapper>
    );
};

export default CustomerDetailsConnections;