import React, { useState } from 'react';
import { NwAddButton } from 'Components/Gui/NwButton';
import { EventActionsToolbar } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import { forceReloadAccommodation } from 'Hooks/Event/UseAccommodation';
import NwUpload from "Components/Gui/NwUpload";
import AddNonBookingEventTransactionDrawer from 'Components/Transactions/TransactionsDrawer/AddNonBookingEventTransactionDrawer';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { checkTenant } from 'Libs/NwUtils';

const AccommodationActions = ({ plainMode, accommodationData, onAddModels }) => {

    const [transactionDrawer, setTransactionDrawer] = useState(false);

    const getUploadEndpoint = () => {
        let endpoint = `documents/upload/1?eventGroupGuid=${accommodationData.GroupGuid}`;
        if (accommodationData.Project) {
            endpoint = endpoint + `&projectID=${accommodationData.Project.ID}`
        }
        return endpoint;
    };

    const onAfterAddingExpense = () => {
        forceReloadAccommodation(accommodationData.ID);
        setTransactionDrawer(false)
    }

    const isElite = checkTenant('elitemodels', true)

    return (
        <>
            <EventActionsToolbar plainMode={plainMode}>
                {accommodationData.GroupGuid &&
                    <NwUpload
                        size='small'
                        label='add document'
                        maxSize={20}
                        showList={false}
                        types={["all"]}
                        onUploadEnd={() => {
                            forceReloadAccommodation(accommodationData.ID);
                        }}
                        endpoint={getUploadEndpoint()}
                    />
                }
                <NwAddButton size="small" ghost onClick={onAddModels} label="add models" />
                {(!accommodationData.ProjectID && !isElite) &&
                    <NwAddButton
                        ghost
                        onClick={() => { setTransactionDrawer(true) }}
                        label="add expense"
                        icon={solid("comment-plus")}
                    />
                }
            </EventActionsToolbar>
            {transactionDrawer &&
                <AddNonBookingEventTransactionDrawer
                    event={accommodationData}
                    eventType="accommodation"
                    onClose={() => setTransactionDrawer(false)}
                    onSave={onAfterAddingExpense}
                />
            }
        </>
    )
};

export default AccommodationActions;