import React, { useContext, useEffect, useState } from 'react'; //useMemo
import { CSVLink } from 'react-csv';
//import { PDFDownloadLink } from '@react-pdf/renderer';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import { NwButton } from 'Components/Gui/NwButton';
import Switch from 'Components/Gui/CustomSwitch';
//import CustomersPdf from 'Components/ContactsExporter/CustomersPdf';
//import ModelsPdf from 'Components/ContactsExporter/ModelsPdf';
//import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";
import { ListContext } from 'Contexts/ListContext';
import Axios from 'axios';
import _ from 'lodash';
import { useUserPermission } from 'Contexts/UserContext';


const Wrapper = styled.div`
    padding: 2rem;
    
    p {
        margin: .5rem 0;
    }

    .button-container {
        text-align: center;
        margin-top: 4rem;
    }
`

const ContactsExporter = ({ headers, type, data }) => {
    const { cachedList } = useContext(ListContext);
    const customFields = type === 'model' ? cachedList.customFields.items["Model"] : [...cachedList.customFields.items['Client'], ...cachedList.customFields.items['Agency'], ...cachedList.customFields.items['Service']]
    const validCustomFields = (customFields && customFields.length > 0) ? customFields.filter(field => !field.Disabled).map(field => field.Name) : [];

    let headersWithCustomFields = [ ...headers]
    validCustomFields.forEach(field => {
        const fieldname = field.replace(/_/g, ' ')
        const fieldNameCapitalize = fieldname.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
        headersWithCustomFields.push({ key: field, label: fieldNameCapitalize})
    });
    
    const [csvHeaders, setCSVHeaders] = useState(headersWithCustomFields.map(header => ({ ...header, checked: true })));
    //const [documentType, setDocumentType] = useState('csv');
    //const [showPictures, setShowPictures] = useState(false);
    //const [showPhones, setShowPhones] = useState(true);
    //const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
    //const [startGeneratingPdf, setStartGeneratingPdf] = useState(false);
    const [dataWithCustomFields, setDataWithCustomFields] = useState([]);

    const getCustomFieldsValues = async () => {
        const contactsWithCustomFields = [];
        const customFieldsValues = await Axios.post("/contacts/customfields", { ContactIDs: data.map(contact => contact.ID) });
        for (const cfv of customFieldsValues) {
            const contact = data.find(contact => contact.ID === cfv.ContactID);
            const customFields = cfv.CustomFields;
            if (customFields) {
                contactsWithCustomFields.push({ ...contact, ...customFields });
            } else {
                contactsWithCustomFields.push(contact);
            }
        }
        const sortFields = ['name']
        if (type === 'model') {
            sortFields.push('surname')
        }
        setDataWithCustomFields(_.orderBy(contactsWithCustomFields, sortFields));
    }

    useEffect(() => {
        getCustomFieldsValues();
    }, []);

    const handleCSVHeaderChange = header => checked => {
        const csvHeaderIndex = csvHeaders.findIndex(item => item.key === header.key);
        const newCSVHeaders = [...csvHeaders];
        newCSVHeaders[csvHeaderIndex].checked = checked;
        setCSVHeaders(newCSVHeaders);
    };

    // const onChangePdf = () => {
    //     setStartGeneratingPdf(false);
    // }

    // const handleChangePdfPictures = (value) => {
    //     onChangePdf();
    //     setShowPictures(value);
    // }

    // const handleChangePdfPhones = (value) => {
    //     onChangePdf();
    //     setShowPhones(value);
    // }

    // const handleChangePdfAdditionalInfo = (value) => {
    //     onChangePdf();
    //     setShowAdditionalInfo(value);
    // }

    return (
        <Wrapper>
            <p><strong>Export a csv file</strong></p>
            <br/>
            {/* <p>
                <RadioButtonGroup
                    value={documentType}
                    onChange={setDocumentType}
                    options={[
                        { label: 'PDF', value: 'pdf' },
                        { label: 'CSV', value: 'csv' }
                    ]} />
            </p> */}
            {/* {documentType === 'csv' ? */}
                <>
                    {headersWithCustomFields.map((header, index) => {
                        const csvHeader = csvHeaders.find(item => item.key === header.key);
                        return (
                            <p key={`${header.key}-${index}`}>
                                <Switch
                                    text={header.label}
                                    checked={csvHeader.checked}
                                    onChange={handleCSVHeaderChange(header)} />
                            </p>
                        )
                    })}
                    <div className="button-container">
                        <CSVLink
                            filename={type === 'model' ? 'models.csv' : 'customers.csv'}
                            data={dataWithCustomFields}
                            headers={csvHeaders.filter(header => header.checked)}>
                            <NwButton
                                primary
                                icon={light('file-spreadsheet')}
                                label='download file' 
                            />
                        </CSVLink>
                    </div>
                </>
                {/* : <>
                    {type === 'model' &&
                        <p>
                            <Switch
                                text={'Show pictures'}
                                checked={showPictures}
                                onChange={handleChangePdfPictures} 
                            />
                        </p>
                    }
                    <p>
                        <Switch
                            text={type === 'model' ? 'Show phones and e-mail' : 'Show phones'}
                            checked={showPhones}
                            onChange={handleChangePdfPhones} />
                    </p>
                    <p>
                        <Switch
                            text={'Show additional info'}
                            checked={showAdditionalInfo}
                            onChange={handleChangePdfAdditionalInfo} />
                    </p>
                    <div className="button-container">
                        {startGeneratingPdf
                            ?
                            <PDFDownloadLink
                                document={type === 'model' ?
                                    <ModelsPdf
                                        showPictures={showPictures}
                                        showPhones={showPhones}
                                        showAdditionalInfo={showAdditionalInfo}
                                        contacts={data} />
                                    : <CustomersPdf
                                        showPhones={showPhones}
                                        showAdditionalInfo={showAdditionalInfo}
                                        contacts={data} />
                                }
                                fileName={type === 'model' ? `models.pdf` : 'customers.pdf'}>
                                {({ loading }) => (
                                    loading 
                                    ? 
                                        <NwButton loading primary icon={light('file-pdf')} label='generate file' />
                                    : 
                                        <NwButton primary icon={light('cloud-arrow-down')} label='download file' />
                                    )
                                }
                            </PDFDownloadLink>
                            :
                            <NwButton primary icon={light('file-pdf')} label='generate file' onClick={() => setStartGeneratingPdf(true)} />
                        }
                    </div>
                </>
            } */}
        </Wrapper>
    )
};

export default ContactsExporter;