import React from 'react';
import styled from 'styled-components';

import ContactsFilterItem from 'Components/ContactsSelector/Common/ContactsFilterItem';
import ContactsFilterItemCustomField from './ContactsFilterItemCustomField';
import ModelFilterItemFeature from '../ModelsSelector/ModelFilterForm/ModelFilterItemFeature';
import { useContactsSelector } from 'Components/ContactsSelector/Common/SelectorContext';
import ModelFilterDrawerAgencySection from 'Components/ContactsSelector/ModelsSelector/ModelFilterForm/ModelFilterDrawerAgencySection';

const FilterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .ant-form-item {
      margin-bottom: 1rem;
  }

  margin-bottom: 1rem;
`;
const FilterLabel = styled.div`
    width: 11rem;
    text-align: left;
    line-height: 1.1em;

    &.active {
        font-weight: bold;
    }

    small {
        display: block;
        font-size: ${props => props.theme.FontSizeSm};
        line-height: 1em;
        padding-top: .25rem;
    }
`;

const FilterWrapper = styled.div`
    flex: 1 0 0;
    padding: 0 2rem 0 1rem;
`;


const FilterItemSelector = ({ filterItem }) => {
    switch (filterItem.source) {
        case 'filter':
            return (<ContactsFilterItem
                key={filterItem.name}
                filter={filterItem}
            />)

        case 'customField':
            return (<ContactsFilterItemCustomField
                key={filterItem.name}
                filter={filterItem}
            />)
        case 'feature':
            return (<ModelFilterItemFeature
                key={filterItem.name}
                filter={filterItem}
            />)
        default:
            return (<p>caso non trovato</p>)
    }
}


const ContactsFilterDrawerSections = () => {

    const { selection, global } = useContactsSelector()
    const { filters } = selection
    
    const isFilterActive = (name) => {
        const filter = filters.working.find(f => f.name === name)
        if (filter) {
            return true
        }
        return false
    }
    
    const section = global.filtersBySection.find(el => el.areaID === filters.openSection);
    
    if (section.areaID.toLowerCase() === 'agency') {
        return (
            <ModelFilterDrawerAgencySection 
                section={section} 
            />
        )
    } else {
        return (
            <div>
                {section.areaFilters.map((el, index) => (
                    <FilterRow key={el.index}>
                        <FilterLabel className={isFilterActive(el.name) ? 'active' : ''}>
                            {el.label}
                            {el.description && <small>{el.description}</small>}
                        </FilterLabel>
                        <FilterWrapper>
                            <FilterItemSelector filterItem={el} />
                        </FilterWrapper>
                    </FilterRow>
                ))}
            </div>
        )
    }
};

export default ContactsFilterDrawerSections;