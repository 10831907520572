import React, { useState } from "react";
import {
    ObjectHistoryContainer
} from 'Components/EventDrawer/EventDrawerUi/EventUI';
import { EventCommentsDocuments } from 'Components/EventDrawer/EventDrawerUi/EventCommentsDocuments';
import {
    useDeleteTravel,
    useDeleteTravelGroup,
    useCloneModelsTravel,
    forceReloadTravel
} from 'Hooks/Event/UseTravel';
import EventDocuments from "Components/EventDocuments/EventDocuments";
import TravelActions from 'Components/EventDrawer/TravelsEvent/TravelActions';
import TravelDetails from 'Components/EventDrawer/TravelsEvent/TravelDetails';
import TravelModelDetails from 'Components/EventDrawer/TravelsEvent/TravelModelDetails';
import ObjectHistory from "Components/CreatedBy/ObjectHistory";
import AddModelsToNonBookingEvent from 'Components/EventDrawer/BookingEvent/AddModels/AddModelsToNonBookingEvent';
import FlexContainer from 'Components/Gui/FlexContainer';
import { EventAddModelQuestion } from "Components/EventDrawer/EventDrawerUi/EventAddModelQuestion";
import { EventTitle } from "Components/EventDrawer/EventDrawerUi/EventTitle";
import { forceReloadProject } from 'Hooks/Project/UseProject';
import styled from "styled-components";
import Axios from "axios";

const Wrapper = styled.div`
    .travel-models-details-wrapper {
        padding-bottom: 3rem;
    }
`

const TravelContent = ({
    afterRemove,
    afterUpdate,
    filterModel,
    handleSelectProject,
    isEdit,
    onTouchForm,
    plainMode,
    projectId,
    setEditModelEvent,
    setIsEdit,
    setViewDetails,
    travelID,
    travelsData,
    viewDetails,
}) => {
    const [showAddModelDrawer, setShowAddModelDrawer] = useState(false);

    const { mutateAsync: onDeleteTravel } = useDeleteTravel();
    const { mutateAsync: onDeleteTravelGroup } = useDeleteTravelGroup();
    const { mutateAsync: onCloneModelsTravel } = useCloneModelsTravel();

    const travelRelatedEvents = travelsData.RelatedEvents ? [travelsData, ...travelsData.RelatedEvents] : [travelsData]

    const isIncludeFilterModel = () => {
        let modelEvents = [travelsData];
        if (travelsData.RelatedEvents && travelsData.RelatedEvents.length > 0) {
            modelEvents = [...modelEvents, ...travelsData.RelatedEvents];
        };

        if (!filterModel) {
            return true;
        } else {
            if (modelEvents.find(modelEvent => modelEvent.ModelID === Number(filterModel))) {
                return true;
            }
            return;
        }
    }

    const handleAddModelToEvent = async modelId => {
        const data = {
            EventID: travelsData.ID,
            ModelIDs: [modelId]
        };
        await onCloneModelsTravel({ data });
        afterUpdate();
        if (travelsData.Project) {
            forceReloadProject(travelsData.Project.ID);
        }
    }

    const handleRemoveEvent = async eventId => {
        try {
            await onDeleteTravel({ ID: eventId, travelId: travelID });
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    };

    const handleRemoveEventGroup = async groupGuid => {
        try {
            await onDeleteTravelGroup({ GroupGUID: groupGuid });
        } catch (error) {
            console.log('ant : Remove Event Group Error => ', error);
        }
    };

    const handleUnlinkEvent = async eventId => {
        try {
            //await onUnlinkTravel({ ID: eventId, travelId: travelID });
            await Axios.put(`/travels/UnLink/${travelID}`)
            forceReloadTravel(travelID)
            if (travelsData.RelatedEvents && travelsData.RelatedEvents.length > 0) {
                travelsData.RelatedEvents.forEach(e => forceReloadTravel(e.ID));
            };
        } catch (error) {
            console.log('ant : Unlink Event Error => ', error);
        }
    }

    const getEventCommentsDocumentsProps = (extra, showOnlyContent, showOnlyActions) => {
        return {
            event: { ...travelsData, elType: 'Travel', uniqID: `Travel-${travelsData.ID}` },
            api: 'TravelID',
            type: 'Travel',
            contactId: !travelsData.GroupGuid && travelsData.ModelID,
            drawerMode: !plainMode,
            fetchEvent: () => { forceReloadTravel(travelsData.ID) },
            extra: extra,
            showOnlyContent: showOnlyContent,
            showOnlyActions: showOnlyActions,
            onTouchForm: onTouchForm
        }
    }

    const getEventTitleContentProps = () => {
        const singleEvent = (!travelsData.RelatedEvents || travelsData.RelatedEvents.length === 0);
        const props = {
            actions: {
                onEditGeneral: (() => {
                    setIsEdit(!isEdit)
                }),
                onEdit: (() => {
                    if (!singleEvent) {
                        setEditModelEvent(travelsData)
                    }
                    setIsEdit(!isEdit)
                }),
                onDelete: (async () => {
                    await handleRemoveEvent(travelsData.ID)
                    if (travelsData.ID === travelID) {
                        afterRemove(true)
                    } else {
                        afterUpdate()
                    }
                }),
                onDeleteGroup: (async () => {
                    await handleRemoveEventGroup(travelsData.GroupGuid)
                    afterRemove(true);
                }),
                onUnlink: (() => handleUnlinkEvent(travelsData.ID)),
                onProjectUnlink: (() => handleSelectProject()),
                onAcknowledgmentUpdate: (() => {
                    forceReloadTravel(travelsData.ID)
                    afterUpdate()
                }),
                onCancelUpdate: (() => {
                    forceReloadTravel(travelsData.ID)
                    afterUpdate()
                }),
            },
            eventProperties: {
                eventType: "Travel",
                isSingle: singleEvent,
                hasExport: true
            },
            event: travelsData
        }
        return props;
    }

    return (<>
        {isIncludeFilterModel() || viewDetails ?
            <Wrapper>
                <EventTitle {...getEventTitleContentProps()} />
                <TravelDetails travelsData={travelsData} />
                {travelsData.GroupGuid && travelsData.GroupDocuments.length > 0 &&
                    <FlexContainer pd='0 20px' column alignItems='flex-start'>
                        <EventDocuments showDocumentsByDefault event={travelsData} type={'eventGroup'} updateEvent={() => forceReloadTravel(travelsData.ID)} />
                    </FlexContainer>
                }
                {!travelsData.GroupGuid && <EventCommentsDocuments {...getEventCommentsDocumentsProps(null, true)} />}
                <ObjectHistoryContainer>
                    <ObjectHistory asLine object={travelsData} />
                </ObjectHistoryContainer>
                {!travelsData.GroupGuid ?
                    <EventCommentsDocuments {
                        ...getEventCommentsDocumentsProps(
                            <TravelActions
                                plainMode={plainMode}
                                travelsData={travelsData}
                                onAddModels={() => setShowAddModelDrawer(true)}
                            />,
                            false,
                            true
                        )}
                    />
                    :
                    <TravelActions
                        plainMode={plainMode}
                        travelsData={travelsData}
                        onAddModels={() => setShowAddModelDrawer(true)}
                    />
                }
                <div className="travel-models-details-wrapper">
                    {(travelRelatedEvents.length > 1)
                        ?
                        travelRelatedEvents.map(event =>
                            <TravelModelDetails
                                key={`event-${event.ID}`}
                                plainMode={plainMode}
                                event={event}
                                afterUpdate={afterUpdate}
                                afterRemove={afterRemove}
                                onEditModelEvent={() => {
                                    setEditModelEvent(event);
                                    setIsEdit(!isEdit);
                                }}
                                onUpdateTravels={() => {
                                    forceReloadTravel(travelsData.ID);
                                    travelRelatedEvents.forEach(e => forceReloadTravel(e.ID));
                                }}
                                openedEventID={travelsData.ID}
                                onTouchForm={onTouchForm}
                            />
                        )
                        :
                        <></>
                    }
                </div>
            </Wrapper>
            :
            <EventAddModelQuestion
                eventData={travelsData}
                model={filterModel}
                onAddModel={(m) => handleAddModelToEvent(m)}
                onShowEvent={() => setViewDetails(true)}
            />
        }
        {showAddModelDrawer &&
            <AddModelsToNonBookingEvent
                event={travelsData}
                projectId={projectId || (travelsData.Project && travelsData.Project.ID)}
                onUpdateEvent={() => {
                    forceReloadTravel(travelsData.ID)
                    afterUpdate()
                }}
                eventType='Travel'
                onClose={() => setShowAddModelDrawer(false)} />
        }
    </>
    )
}

export default TravelContent
