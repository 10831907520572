import React from "react";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useFeatures } from 'Hooks/Contact/UseContactFeatures';
import NwIcon, { NwBoolIcon } from 'Components/Gui/NwIcon';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { useUserGuiConfig } from "Contexts/UserGuiConfigContext";

const MainFeatures = ({ contact, empty }) => {
    const features = contact.FeatNwMain || contact.F
    const filteredFeatures = useFeatures(features ? JSON.parse(features) : null);
    const userGuiConfig = useUserGuiConfig()
    const breakpoints = useNwBreakPoints();

    if (filteredFeatures.length) {
        return (
            <React.Fragment>
                {filteredFeatures && filteredFeatures.map(({ DisplayName, DisplayValues, Value, ConversionName, TypeName }, index) => {
                    if (index > 7) {
                        return null
                    }
                    if (ConversionName) {
                        const conversion = ConversionName && userGuiConfig.metrics.find(metric => metric.Name === ConversionName);
                        const displayMetric = conversion && conversion.metrics[0];
                        const displayValue = DisplayValues.find(displayValue => displayValue.metric === displayMetric);
                        return (
                            <dl key={`feat${index}`}>
                                <dt>{DisplayName}{breakpoints === 1 && ':'}</dt>
                                <dd>{displayValue ? displayValue.value : DisplayValues[0].value}</dd>
                            </dl>
                        )
                    }
                    if (TypeName === "Boolean") {
                        return (
                            <dl key={`feat${index}`}>
                                <dt>{DisplayName}</dt>
                                <dd style={{ textAlign: "center" }}>
                                    {Value.toLowerCase() === "true" ?
                                        <NwBoolIcon value={true} />
                                        :
                                        <NwBoolIcon value={false} />
                                    }
                                </dd>
                            </dl>
                        )
                    }
                    if (TypeName === 'Rating') {
                        return (
                            <dl key={`feat${index}`}>
                                <dt>{DisplayName}</dt>
                                <dd style={{ textAlign: "center" }}>
                                    {Array(parseInt(Value)).fill(null).map((rating, key) => {
                                        return (
                                            <NwIcon key={`star-${key}`} icon={solid('star')} style={{ fontSize: 11 }} />
                                        )
                                    })}
                                </dd>
                            </dl>
                        )
                    }
                    if (DisplayValues && DisplayValues.length && DisplayValues[0].value && DisplayValues[0].value.length > 30) {
                        return null;
                    }
                    return (
                        <dl key={`feat${index}`}>
                            <dt>{DisplayName}{breakpoints === 1 && ':'}</dt>
                            <dd>{DisplayValues[0].value}</dd>
                        </dl>
                    )
                })}
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>{empty ? empty : ""}</React.Fragment>
    );
};

export default MainFeatures;