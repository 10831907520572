import React, { useContext } from "react";
import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";
import ModelQuickFilters from "Components/ContactsSelector/ModelsSelector/ModelQuickFilters";
import { QuickFilterContainer } from "Components/Filters/FilterForm/FilterFormUI";
import { SelectorContext, useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/SelectorContext";


const ModelsWallMiniHeader = ({
    onAfterChangeFilters,
    tags
}) => {

    //const selectorContextValues = useContext(SelectorContext);
    const { selection } = useContactsSelector();
    const cs_dispatch = useContactsSelectorDispatch();
    const { listType, quickFilters } = selection; //changeQuickFilters

    let sortOptionsFolder = [
        { label: 'Name', value: 'Name' },
        { label: 'Manual', value: 'Manual' }
    ];

    const sortOptions = [
        { label: 'Name', value: 'Name' },
        { label: 'ID', value: 'ID' },
        { label: 'Agency', value: 'MotherAgency' },
    ];

    if (listType === 'all-models') {
        return (
            <ModelQuickFilters
                tags={tags}
                onAfterChangeFilters={onAfterChangeFilters}
                isMini
            />
        )
    }
    if (listType === 'folders') {
        return (
            <QuickFilterContainer $isMini $topPadded>
                <div className="quick-filter-item">
                    <span className="before">sort by</span>
                    <RadioButtonGroup
                        value={quickFilters.sortFolder}
                        onChange={(value) => {
                            // changeQuickFilters({
                            //     ...quickFilters,
                            //     sortFolder: value
                            // })
                            cs_dispatch({
                                type: "ChangeQuickFilters",
                                quickFilters: {
                                    ...quickFilters,
                                    sortFolder: value
                                }
                            })
                            if (onAfterChangeFilters) {
                                onAfterChangeFilters()
                            }
                        }}
                        size='small'
                        options={sortOptionsFolder} />
                </div>
            </QuickFilterContainer>
        )
    }
    return (
        <QuickFilterContainer $isMini $topPadded>
            <div className="quick-filter-item">
                <span className="before">sort by</span>
                <RadioButtonGroup
                    value={quickFilters.sort}
                    onChange={(value) => {
                        // changeQuickFilters({
                        //     ...quickFilters,
                        //     sort: value
                        // })
                        cs_dispatch({
                            type: "ChangeQuickFilters",
                            quickFilters: {
                                ...quickFilters,
                                sort: value
                            }
                        })
                        if (onAfterChangeFilters) {
                            onAfterChangeFilters()
                        }
                    }}
                    size='small'
                    options={sortOptions} />
            </div>
        </QuickFilterContainer>
    )
};

export default ModelsWallMiniHeader;