import React, { useEffect, useState } from 'react';

import ContactScheduleCard from "Components/Schedule/ContactScheduleCard";
import useLocalStorageState from 'Hooks/UseLocalStorageState';
import { getCalendarStyle, getCurrentMonthRangeDates, getCurrentWeekRange } from 'Components/Schedule/ScheduleUtils';
import { Loading } from 'Components/Gui/Loading';
import {  getTodayISO, toISODate } from 'Libs/NwMoment';

//TODO: GET LAST DATE SAVED IN LOCAL STORAGE AND PASS TO THE AGENDACONTAINER AS STARTDATE?

const ModelDetailsCalendar = ({
    isMobileDrawer,
    idmodel,
    previewMode,
    calendarStyle,
    onChangeCalendarStyle
}) => {

    let defaultSchedulePeriod = { start: getTodayISO(), end: getTodayISO() }
    if (calendarStyle === 'week') {
        defaultSchedulePeriod = { start: getCurrentWeekRange().start, end: getCurrentWeekRange().end }
    }
    if (calendarStyle === 'month') {
        defaultSchedulePeriod = { start: getCurrentMonthRangeDates().start, end: getCurrentMonthRangeDates().end }
    }

    const [scheduleStartRange, setScheduleStartRange] = useState(defaultSchedulePeriod.start)
    const [scheduleEndRange, setScheduleEndRange] = useState(defaultSchedulePeriod.end)
    const [scheduleRange, setScheduleRange] = useState(null)
    // week|month|agenda based on the scheduleRange timespan

    const [filters, setFilters] = useLocalStorageState('contact-schedule-filters', {
        filterMine: 'all',
        filterAcknowledgement: 'all',
        hideCancelled: false,
        hideBilling: false
    })

    useEffect(() => {
        if (scheduleStartRange && scheduleEndRange) {
            const dateObject = { start: new Date(scheduleStartRange), end: new Date(scheduleEndRange) }
            
            setScheduleRange(dateObject)
            onChangeCalendarStyle(getCalendarStyle(dateObject))
        }
    }, [scheduleStartRange, scheduleEndRange])

    const handleScheduleRangeChange = (start, end) => {
        setScheduleStartRange(toISODate(start))
        setScheduleEndRange(toISODate(end))
    }

    return (
        scheduleRange
            ?
            <ContactScheduleCard
                type='model'
                contactId={idmodel}
                filters={filters}
                setFilters={setFilters}
                scheduleRange={scheduleRange}
                onScheduleRangeChange={handleScheduleRangeChange}
                calendarStyle={calendarStyle}
                previewMode={previewMode}
                isMobileDrawer={isMobileDrawer}
            />
            :
            <Loading />
    )
}

export default ModelDetailsCalendar
