import React, { useState, useEffect } from 'react';
import { Popover, List } from "antd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import StyleVariables from 'Components/Gui/StyleVariables.js';
import EventTypeTag from 'Components/EventDetails/EventTypeTag';
import NwEllipsis from "Components/Gui/NwEllipsis";
import NwIcon from "Components/Gui/NwIcon";
import { showDateNoTime, showPeriod } from 'Libs/NwMoment';
import ProjectLine from 'Components/ProjectLine/ProjectLine';
import { AgendaEventContent } from 'Components/Schedule/Agenda/AgendaEventStyle';
import { useContactsSelector } from 'Components/ContactsSelector/Common/SelectorContext';

const ModelCalendarEvents = ({
    modelId,
    isFetched,
    isBusy,
    size
}) => {
    const [visible, setVisible] = useState(false);
    const [modelEvents, setModelEvents] = useState([]);
    const [dates, setDates] = useState();

    const { cache: { modelAvailability } } = useContactsSelector();
    

    useEffect(() => {
        if (modelAvailability && modelAvailability.ModelsInvolved.find(idModel => idModel === modelId)) {
            const modelEvents = modelAvailability.Events.filter(event => event.ModelID === modelId);
            setDates(modelAvailability.period);
            setModelEvents(modelEvents);
        } else {
            setDates();
            setModelEvents([]);
        }
    }, [modelAvailability]);

    const handleVisibleChange = visible => {
        setVisible(visible);
    };

    return (
        <div>
            {isFetched ?
                //isBusy ?
                    <Popover
                        placement="topRight"
                        content={
                            (modelEvents.length > 0)
                            ?
                            <List
                                size="small"
                                itemLayout="horizontal"
                                dataSource={modelEvents}
                                renderItem={event => (
                                    <AgendaEventContent $cancel={event.Cancel}>
                                        <EventTypeTag event={event} /> {event.Name}
                                        {event.Description && <small><NwEllipsis text={event.Description} rows={2} popover /></small>}
                                        <small>{showDateNoTime(event.FromDate)} - {showDateNoTime(event.ToDate)}</small>
                                        {event.Project &&
                                            <ProjectLine project={event.Project} />
                                        }
                                    </AgendaEventContent>
                                )}
                            />
                            :
                            <p>no events</p>
                        }
                        title={dates ? showPeriod(dates.startdate, dates.enddate) : ''}
                        trigger="hover"
                        open={visible}
                        onOpenChange={handleVisibleChange}
                    >
                        <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            {isBusy
                            ?
                            <NwIcon
                                size={size}
                                icon={light("calendar-exclamation")}
                                style={{ color: StyleVariables.DangerColor }}
                            />
                            :
                            <NwIcon
                                size={size}
                                icon={light("calendar-check")}
                                style={{ color: StyleVariables.SuccessColor }}
                            />
                            }
                        </div>
                    </Popover>
                :
                <p>&nbsp;</p>
            }
        </div>
    )
};

export default ModelCalendarEvents;