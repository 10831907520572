//import moment from 'moment'
import { getMoment } from 'Libs/NwMoment'
import _ from 'lodash'
import { getDatesInRange, isAllDay } from 'Libs/NwMoment';
import { getContactName } from 'Hooks/Contact/UseContactsList';

export const getSortOrder = event => {
    let sortOrder = 0;
    if (event.Cancel) {
        sortOrder += 10000;
    }
    switch (event.TypeName) {
        case "Notice":
            break;
        case "Job":
            switch (event.Confirmed) {
                case 0:
                    sortOrder += 3000;
                    break;
                default:
                    sortOrder += 1000;
                    break;
            }
            break;
        case "ExtJob":
                switch (event.Confirmed) {
                    case 0:
                        sortOrder += 4000;
                        break;
                    default:
                        sortOrder += 2000;
                        break;
                }
                break;
        case "Casting":
            sortOrder += 5000;
            break;
        default:
            sortOrder += 10000;
            break;
    }
    return sortOrder
}

export const generalCalendarParseEvents = (events) => {
    
    const isGroupable = (event, events) => {
        if (event.GroupGuid) {
            const theresanother = events.filter((ev) => (ev.GroupGuid === event.GroupGuid && ev.ID !== event.ID))
            if (theresanother) {
                return event
            }
        }
    }
    
    const isLongDay = (event) => {
        const [fromDate] = event.FromDate.split('T')
        const [toDate] = event.ToDate.split('T')
        if (fromDate !== toDate && event.FirstDate === event.FromDate && event.LastDate === event.ToDate) {
            return true
        } else {
            return false
        }
    }
    
    const groupNonBookingEvents = events => {
        return [
            ...events
                .reduce(
                    (
                        r,
                        {
                            GroupGuid,
                            ID,
                            ContactID,
                            Description,
                            FirstDate,
                            LastDate,
                            FromDate,
                            ToDate,
                            ModelID,
                            Name,
                            Project,
                            TypeName,
                            SubTypeCode,
                            Confirmed,
                            Acknowledgment,
                            Cancel,
                            UserCreated,
                            DateCreated,
                            UserModified,
                            DateModified,
                            DepartmentID
                        }
                    ) => {
                        r.has(GroupGuid) ||
                            r.set(GroupGuid, {
                                GroupGuid,
                                ID,
                                ContactID,
                                Description,
                                FirstDate,
                                LastDate,
                                FromDate,
                                ToDate,
                                Name,
                                Project,
                                TypeName,
                                SubTypeCode,
                                Confirmed,
                                grouped: [],
                                Acknowledgment,
                                Cancel,
                                UserCreated,
                                DateCreated,
                                UserModified,
                                DateModified,
                                DepartmentID
                            })
                        r.get(GroupGuid).grouped.push({ FromDate, ToDate, ModelID, Acknowledgment, Cancel })
                        return r
                    },
                    new Map()
                )
                .values()
        ]
    }
    
    const isNonBooking = (typeName) => {
        switch (typeName) {
            case "Casting":
            case "Job":
            case "ExtJob":
            case "Notice":
                return false
            default:
                return true
        }
    }
    
    const getModelsNumberFromModelsDates = modelsDates => {
        let models = [];
        for (const modelDate of modelsDates) {
            for (const modelId of modelDate.Models) {
                if (!models.find(id => modelId === id)) {
                    models.push(modelId);
                }
            }
        }
        return models.length;
    };

    const getModelsNumber = (event) => {
       if (event.TypeName === "Casting" || event.TypeName === "Job") {
            if (event.ModelsDates) {
                return getModelsNumberFromModelsDates(event.ModelsDates)
            }
            return 0
       } else {
            if (event.grouped) {
                return event.grouped.length
            } else {
                return event.ModelID ? 1 : 0
            }
        }
    }

    const parsedEvents = []
    const bookingEventTypes = ['job','casting']
    const groupableEvents = _.partition(events, (ev) => isGroupable(ev, events))
    const groupedEvents = groupNonBookingEvents(groupableEvents[0])
    const eventsGrouped = [...groupedEvents, ...groupableEvents[1]]
    
    const getEventTitle = (event, modelsNumber) => {
        let title = "";
        if (isNonBooking(event.TypeName) && modelsNumber === 1) {
            title += getContactName(event.ModelID) + " - "
        }
        if (event.Name) {
            title += event.Name
        } else {
            title += event.Title
        }
        return title
    }

    const parseEventByType = (event, durationtype) => {
        const modelsNumber = getModelsNumber(event)
        const eventTitle = getEventTitle(event, modelsNumber)
        
        return ({
            ...event,
            modelsNumber: modelsNumber,
            eventType: durationtype,
            calendar: false,
            title: eventTitle,
            //allDay always false for booking events, always true for non booking events
            allDay: durationtype === "long-day" || durationtype === "multi-day" || event.TypeName === "Accommodation" || isAllDay(event.FromDate, event.ToDate),
            onAllDaySection: isNonBooking(event.TypeName),
            start: getMoment(event.FromDate).startOf("d").toDate(),
            end: getMoment(event.ToDate).startOf("d").add(1,"h").toDate(),
            sortOrder: getSortOrder(event)
        })
    }

    const parseExtJobLongEvent = (event) => {
        const extJobEventsSplitted = []
        const extJobDates = getDatesInRange(getMoment(event.FromDate), getMoment(event.ToDate))
        for (const extJobDate of extJobDates) {
            const extJobDateInstance = {
                ...event,
                modelsNumber: 1,
                eventType: "single-day",
                calendar: false,
                title: event.Name ? event.Name : event.Title,
                allDay: false,
                start: getMoment(extJobDate).startOf("d").toDate(),
                end: getMoment(extJobDate).startOf("d").add(1,"h").toDate(),
                sortOrder: getSortOrder(event)
            }
            extJobEventsSplitted.push(extJobDateInstance)
        }
        return extJobEventsSplitted
    }
    
    
    for (const event of eventsGrouped) {
        if (event.TypeName === "ExtJob") {
            const splittedExtJobs = parseExtJobLongEvent(event)
            parsedEvents.push(...splittedExtJobs)
        } else {
            if (bookingEventTypes.includes(event.TypeName)) {
                parsedEvents.push(
                    parseEventByType(event, "single-day", true)
                )
            } else {
                //non booking events
                let evduration = "single-day"
                if (isLongDay(event)) {
                    evduration = "long-day"
                }
                parsedEvents.push(
                    parseEventByType(event, evduration)
                )
            }
        }
    }
    const sortedParsedEvents = _.sortBy(parsedEvents, ['sortOrder', 'FromDate', 'ToDate'])
    return [...sortedParsedEvents];
}

export const modelCalendarParseEvents = (events) => {
    
    // const getSortOrder = event => {
    //     let sortOrder = 0;
    //     if (event.Cancel) {
    //         sortOrder += 10000;
    //     }
    //     return sortOrder
    // }
    
    const isLongDay = (event) => {
        const [fromDate] = event.FromDate.split('T')
        const [toDate] = event.ToDate.split('T')
        if (fromDate !== toDate && event.FirstDate === event.FromDate && event.LastDate === event.ToDate) {
            return true
        } else {
            return false
        }
    }
    
    const isNonBooking = (typeName) => {
        switch (typeName) {
            case "Casting":
            case "Job":
            case "ExtJob":
            case "Notice":
                return false
            default:
                return true
        }
    }
    
    const parsedEvents = []
    const bookingEventTypes = ['job','casting']
    
    const getEventTitle = (event) => {
        let title = "";
        if (event.Name) {
            title += event.Name
        } else {
            title += event.Title
        }
        return title
    }

    const parseEventByType = (event, durationtype) => {
        const eventTitle = getEventTitle(event)
        return ({
            ...event,
            eventType: durationtype,
            calendar: false,    
            title: eventTitle,
            //allDay always false for booking events, always true for non booking events
            allDay: durationtype === "long-day" || durationtype === "multi-day" || event.TypeName === "Accommodation" || isAllDay(getMoment(event.FromDate), getMoment(event.ToDate)),
            onAllDaySection: isNonBooking(event.TypeName),
            start: getMoment(event.FromDate).startOf("d").toDate(),
            end: getMoment(event.ToDate).startOf("d").add(1,"h").toDate(),
            //sortOrder: getSortOrder(event)
        })
    }

    const parseExtJobLongEvent = (event) => {
        const extJobEventsSplitted = []
        const extJobDates = getDatesInRange(getMoment(event.FromDate), getMoment(event.ToDate))
        for (const extJobDate of extJobDates) {
            const extJobDateInstance = {
                ...event,
                modelsNumber: 1,
                eventType: "single-day",
                calendar: false,
                title: event.Name ? event.Name : event.Title,
                allDay: false,
                start: getMoment(extJobDate).startOf("d").toDate(),
                end: getMoment(extJobDate).startOf("d").add(1,"h").toDate(),
                onAllDaySection: false,
                //sortOrder: getSortOrder(event)
            }
            extJobEventsSplitted.push(extJobDateInstance)
        }
        return extJobEventsSplitted
    }
    
    
    for (const event of events) {
        if (event.TypeName === "ExtJob") {
            const splittedExtJobs = parseExtJobLongEvent(event)
            parsedEvents.push(...splittedExtJobs)
        } else {
            if (bookingEventTypes.includes(event.TypeName)) {
                parsedEvents.push(
                    parseEventByType(event, "single-day", true)
                )
            } else {
                //non booking events
                let evduration = "single-day"
                if (isLongDay(event)) {
                    evduration = "long-day"
                }
                parsedEvents.push(
                    parseEventByType(event, evduration)
                )
            }
        }
    }
    const sortedParsedEvents = _.sortBy(parsedEvents, ['FromDate', 'ToDate']) //'sortOrder', 
    return [...sortedParsedEvents];
}

export const modelCalendarParseEventsTime = (events) => {
    
    const isLongDay = (event) => {
        const [fromDate] = event.FromDate.split('T')
        const [toDate] = event.ToDate.split('T')
        if (fromDate !== toDate && event.FirstDate === event.FromDate && event.LastDate === event.ToDate) {
            return true
        } else {
            return false
        }
    }

    const parseEvent = (event, durationtype) => ( //isModelsCalendar, isAgendaEvents
        {
            ...event,
            modelsNumber: 1,
            eventType: durationtype,
            title: event.Name ? event.Name : event.Title,
            allDay: durationtype === "long-day" || durationtype === "multi-day" || event.TypeName === "Accommodation" || isAllDay(getMoment(event.FromDate), getMoment(event.ToDate)),
            start: getMoment(event.FromDate).toDate(),
            end: getMoment(event.ToDate).toDate()
        }
    )
    const parsedEvents = events.map((event) => {
        return parseEvent(event, isLongDay(event) ? 'long-day' : 'single-day') //isModelsCalendar
    })
    return parsedEvents;
}

export const eventPropGetter = (event, { className, style }) => {
    let eventClassName = ''
    switch (event.Type) {
        case "Casting":
            eventClassName = 'type-casting'
            break
        case "Job":
            eventClassName = 'type-job'
            break
        case "":
            eventClassName = 'type-empty'
            break
        case "ToDo":
            eventClassName = 'type-to-dos'
            break
        case "FollowUp":
            eventClassName = 'type-follow-up'
            break
        case "Travel":
            eventClassName = 'type-travels'
            break
        case "ExtJob":
            eventClassName = 'type-external-jobs'
            break
        case "Meeting":
            eventClassName = 'type-meetings'
            break
        case "no":
            eventClassName = 'type-no-job'
            break
        default:
            eventClassName = 'type-job'
    }
    const strClassCancelled = event.Cancel ? " cancelled" : ""
    const strClassProject = event.Project ? ` project-${event.Project.ID}` : ``
    const strClassPosition = event.eventPosition ? ` ${event.eventPosition}` : ``
    return {
        className: `${eventClassName}${strClassProject}${strClassPosition}${strClassCancelled}`
    }
}