import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Switch } from 'antd';
import { getMoment } from "Libs/NwMoment";
import MiniLoading from 'Components/Gui/MiniLoading';
import FlexContainer from 'Components/Gui/FlexContainer';
import ModelAvailability from 'Components/EventDatesPicker/ModelAvailability';
import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";
import { enumerateDaysBetweenDates } from 'Libs/NwUtils';
import { onGetOptionNumber } from 'Hooks/Event/UseEvent';
import { getContactName } from 'Hooks/Contact/UseContactsList';

const ModelItem = styled.div`
    display: flex;
    align-items: center;
    margin-right: 8px;
`;

const ModelName = styled.span`
    margin-left: 8px;
`;

const Confirm = styled.div`
    color: red;
`;

const EventDateModel = ({ modelId, models, eventId, eventType, eventDate, period, onChange }) => {
    const [loading, setLoading] = useState(false);
    const [recalculated, setRecalculated] = useState(false);
    const [isCalled, setIsCalled] = useState(false);

    useEffect(() => {
        if (models[modelId].checked && models[modelId].optionNumber < 10 && eventType !== 'Casting') {
            onCheckOptionNumber()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [models[modelId].checked, eventDate]);

    const onCheckOptionNumber = async () => {
        const data = {
            EventID: eventId,
            ModelID: modelId,
            Dates: eventDate ? [getMoment(eventDate)] : enumerateDaysBetweenDates(period[0], period[1])
        };

        setLoading(true);
        try {
            const optionNumber = await onGetOptionNumber(data);
            if (models[modelId].optionNumber && isCalled) {
                setRecalculated(true);
            }
            if (!isCalled) {
                setIsCalled(true);
            }
            setLoading(false);
            return onChange(modelId, modelChecked, optionNumber[0].SuggestedOptionNumber);
        } catch (error) {
            console.log('ant : Update Option Number Error => ', error);
            setLoading(false);
        };
    };

    const modelChecked = models[modelId] ? models[modelId].checked : false;
    const modelOptionNumber = models[modelId] ? models[modelId].optionNumber : null;

    return (
        <FlexContainer mg='0 0 8px' column alignItems='flex-start'>
            <FlexContainer justifyContent='space-between' mg='0 0 4px' fullWidth>
                <ModelItem key={modelId}>
                    <Switch checked={modelChecked} onChange={checked => {
                        onChange(modelId, checked, modelOptionNumber);
                        if (!checked) {
                            setRecalculated(false);
                            setIsCalled(false);
                        }
                    }} />
                    <ModelName>{getContactName(modelId)}</ModelName>
                </ModelItem>

                {modelChecked && eventType !== 'Casting' && (
                    loading ? <MiniLoading />
                        : 
                        <div>
                            {recalculated && <Confirm>The option number has been re-calculated</Confirm>}
                            &nbsp;
                            <RadioButtonGroup 
                                value={modelOptionNumber} 
                                onChange={value => {
                                    if (value === 'auto') {
                                        onCheckOptionNumber();
                                    } else {
                                        onChange(modelId, modelChecked, value)
                                    }
                                }} 
                                size='small' 
                                options={[
                                    { value: 10, label: 'job' },
                                    ...[...Array(5).keys()].map(key => (
                                        { value: key + 1 , label: key + 1 }
                                    )),
                                    { value: 'auto', label: 'auto' }
                                ]} />
                        </div>
                )}
            </FlexContainer>
            {modelChecked &&
                <ModelAvailability startDate={period[0]} endDate={period[1]} modelId={modelId} />
            }
        </FlexContainer>
    );
};

export default EventDateModel;