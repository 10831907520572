import React, { useState } from "react";
import { Formik, Field } from "formik";
import Axios from 'axios';
import { NwSaveButton, NwCancelButton } from "Components/Gui/NwButton";
import { NWTextAreaCharCounter } from 'Components/Gui/NwForm/NwFormItems';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import NwDrawer from 'Components/Gui/NwDrawer';

const CheckListItemNoteDrawer = ({ checkListItem, objectType, objectID, onUpdate, onClose }) => {
    const [touchForm, setTouchForm] = useState(false);
    
   
    let initialValues = {
        Note: checkListItem.Note ? checkListItem.Note : ""
    };

    const updateNote = (values, setStatus, setSubmitting) => {
        setSubmitting(true);
        
        const data = {
            CheckListItemID: checkListItem.CheckListItemID,
            Note: values.Note
        }
        
        let urlbase = "/contacts/updatechecklistitemnote";
        
        if (objectType === "Project") {
            data.ProjectID =  objectID;
            urlbase = "/projects/updatechecklistitemnote";
        } else {
            data.ContactID = objectID;
        }

        Axios.put(urlbase, data)
            .then(() => {
                setTouchForm(false)
                onUpdate()
            })
            .catch(error => {
                console.log("errore", error);
            });
    };

    return (
        <NwDrawer         
            onTouchForm={setTouchForm}
            touchForm={touchForm} 
            title={'Edit Checklist Item Note'} 
            onClose={onClose}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    updateNote(values, setStatus, setSubmitting);
                }}
            >
                {({
                    isSubmitting,
                    handleSubmit,
                    values
                }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit} 
                        layout="vertical">
                        <Field
                            component={NWTextAreaCharCounter}
                            label="Note"
                            name="Note"
                            type="text"
                            required
                            value={values.Note}
                            maxLength={2000}
                            style={{width:"100%"}}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                        <NwFormButtonsBar 
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose()
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    );
};

export default CheckListItemNoteDrawer;
