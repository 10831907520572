import React, { useState } from 'react';
import { Alert, Input } from 'antd';
import NwDrawer from 'Components/Gui/NwDrawer';
import { NwCancelButton, NwSaveButton } from 'Components/Gui/NwButton';
import { NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';
import { useContactsSelector, useContactsSelectorDispatch } from 'Components/ContactsSelector/Common/SelectorContext';

const ContactsFilterSetNameDrawer = ({ isSaving, fromTop, fromLeft, onClose, placement, zIndex, action }) => {

    const { selection } = useContactsSelector(); 

    const [name, setName] = useState(action === 'Rename' ? (selection.activeSetName ? selection.activeSetName : "") : "new list");
    const [validationMessage, setValidationMessage] = useState(null);
    
    const cs_dispatch = useContactsSelectorDispatch();
    
    useFormFieldFocus('ListName');

    const handleRenameSet = () => {
        if (!name) {
            setValidationMessage("Name is required");
            return;
        }
        cs_dispatch({ type: 'RenameSet', newName: name });
        onClose();
    }

    const handleCreateSet = () => {
        if (!name) {
            setValidationMessage("Name is required");
            return;
        }
        cs_dispatch({ type: 'AddNewSet', name: name });
        onClose();
    }

    return (
        <NwDrawer
            width={640}
            placement={placement}
            fromTop={fromTop}
            fromLeft={fromLeft}
            onClose={onClose}
            zIndex={zIndex}
            title={action === "AddNew" ? "Add New List" : "Rename List"}
        >
            <p>List Name</p>
            <Input
                value={name}
                type="text"
                onChange={(e) => setName(e.target.value)}
                id='ListName'
            />
            {validationMessage && <Alert style={{ margin: '.25rem 0' }} type="error" message={validationMessage} showIcon />}
            <NwFormButtonsBar
                left={
                    <NwCancelButton
                        onClick={onClose}
                    />
                }
                right={
                    <NwSaveButton
                        style={{marginTop: '1rem'}}
                        onClick={action === "AddNew" ? handleCreateSet : handleRenameSet}
                        disabled={isSaving}
                        loading={isSaving}
                        label="Save"
                        loadingLabel="Saving..."
                    />
                }
            />
        </NwDrawer>
    )
};

export default ContactsFilterSetNameDrawer;