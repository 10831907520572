import React, {useState} from "react";
import styled from 'styled-components';
import { Select } from 'antd';
import EventTypeTag from "Components/EventDetails/EventTypeTag";
import { NwFormItem } from "Components/Gui/NwForm/NwFormWrapper";

const { Option } = Select;

const StyledSelect = styled(Select)`
  .ant-select-selection-item > div > div {
    &.name {
      display: block;
    }
  }
  .event-selector-option {
    display: flex;
    align-items: center;
    height: 100%;
    .name {
      margin-left: 10px;
    }
  }
`;


const NonBookingEventSelectorForTransactions = ({ label, eventSelectedUniqID, events, onChange, onBlur, disabled, name }) => {

  const [selectedEventUniqID, setSelectedEventUniqID] = useState(eventSelectedUniqID);

  const filteredEvents = events.filter(event => event.elType !== 'Event');

  const onSelectChange = (value) => {
    const eventUniqID = value
    setSelectedEventUniqID(value)
    if (eventUniqID) {
      const event = events.find(e => e.uniqID === eventUniqID)
      onChange(event)
    } else {
      onChange(null)
    }
  }

  return (
    <NwFormItem
      label={label}
      control={
        <StyledSelect
          autoComplete='off'
          onChange={onSelectChange}
          defaultValue={null}
          value={selectedEventUniqID}
          dropdownMatchSelectWidth={false}
          //labelInValue
          disabled={disabled}
          onBlur={onBlur}
        >
          <Option value={null} key="all-projects">No selected event</Option>
          {filteredEvents.map(event => (        //groupedEvents.map(event => (
            <Option value={event.uniqID} key={event.uniqID}>
              <div className="event-selector-option">
                <EventTypeTag event={event} />
                <div className="name">{event.Name || event.Title}</div>
              </div>
            </Option>
          ))}
        </StyledSelect>}
    />);
};


export default NonBookingEventSelectorForTransactions;
