import { createContext, useContext } from "react";

const emptyMailingListContextValue = {
    mailingListID: null,
    mailingListEmails: [],
    onAddItem: () => { },
};

export const MailingListContext = createContext(emptyMailingListContextValue);

export function useMailingListContext() {
    return useContext(MailingListContext);
}