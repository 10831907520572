export const EVENTS_FILTERS_SETS = {
    activeSet: ("v1"),
    customSets: ([]),
    staticSets: [
        {
            id: "v1",
            name: "last 30 days",
            fixed: true,
            filters: [
                { name: "WorkingOn", value: "{last30days}"},
                { 
                    name: 'casting',
                    value: {
                        eventSubtypeCode: null
                    },
                    active: false
                },
                {
                    name: 'job',
                    value: {
                        eventSubtypeCode: null
                    },
                    active: false
                },
                {
                    name: 'travel',
                    value: {
                        travelTypes: null
                    },
                    active: false
                },
                {
                    name: 'followup',
                    value: {
                        labels: null
                    },
                    active: true
                },
                { name: 'roomLodging', value: true, active: false},
                { name: 'extjob', value: true, active: false},
                { name: 'meeting', value: true, active: false},
                { name: 'accommodation', value: true, active: false},
            ]
        },
        {
            id: "v2",
            name: "my confirmed jobs",
            fixed: true,
            filters: [
                {
                    name: 'Booker',
                    value: '{me}',
                    active: true
                },
                {
                    name: 'job',
                    value: {
                        eventSubtypeCode: null,
                        optionNumber: 10
                    },
                    active: true
                }
            ]
        }
    ]
}