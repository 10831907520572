import React, { useMemo, useState, useEffect } from "react";
import { Card, Spin } from 'antd';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwTag } from "Components/Gui/NwTag.js"
import AddToFolderButton from "Components/ContactsFolders/AddToFolderButton";
import AddToPackageButton from 'Components/ActivePackages/AddToPackageButton'
import AvatarBg from 'Components/Gui/AvatarBg';
import NwIcon from "Components/Gui/NwIcon";
import ContactUsers from 'Components/ContactUsers/ContactUsers';
import ModelInTown from 'Components/ModelInTown/ModelInTown';
import { useStateValue } from 'Contexts/StateProvider';
import ModelCalendarEvents from 'Components/ContactsSelector/ModelsSelector/ModelsWall/ModelComponents/ModelCalendarEvents';
import ModelDetailsHeaderAgencies from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsHeaderAgencies";
import MainFeatures from "Components/Features/MainFeatures";
import ModelDrawer from 'Components/ContactDetails/ModelDetails/Drawer/ModelDrawer';
import usePlaceholderImageFixed from "Hooks/UsePlaceholderImageFixed";
import { modelFullName } from "Hooks/Contact/UseContactsList";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import Title from "antd/lib/typography/Title";
import ContactAlertPopover from "Components/ContactAlert/ContactAlertPopover";
import { ContactContainer, InTownPanel, StyledCard, AgePanel } from "./ModelCardUI";
import { isModelBusy } from "Libs/ModelAvailability";
import { useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/SelectorContext";
import { useNavigate } from "react-router";
import ModelDetailsAge from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsAge";

const selectionIconstyle = {
    position: 'absolute',
    left: '8px',
    top: '8px',
    fontSize: '24px',
    zIndex: 1
}

const ModelCard = ({ model, }) => {
    const navigate = useNavigate();
    const { additional, actions, cache, layout, selection } = useContactsSelector();
    const {
        disabledItems,
    } = additional;
    const {
        selectedItemsIDs,
        multipleSelectionActive,
        itemClickAction,
    } = actions;
    const {
        listType,
        activeSet,
        quickFilters,
    } = selection;
    const {
        modelAvailability,
    } = cache;

    const cs_dispatch = useContactsSelectorDispatch();

    const showID = quickFilters && quickFilters.sort && quickFilters.sort === 'ID'

    const disabled = useMemo(() => disabledItems.includes(model.ID), [disabledItems, model.ID]);
    const isSelected = useMemo(() => selectedItemsIDs.includes(model.ID), [selectedItemsIDs, model.ID]);

    const [loading, setLoading] = useState(false);
    const { Meta } = Card;
    const [{ areaContext }] = useStateValue();
    const { CG, ID, T, ST, G } = model;
    const [urlbg, isPlaceholder] = usePlaceholderImageFixed(CG, ST)

    const [isBusy, setIsBusy] = useState(false);
    const [isFetched, setFetched] = useState(false);
    const breakpoints = useNwBreakPoints();
    const [isOpenModelDrawer, setIsOpenModelDrawer] = useState(false);

    const onModelClick = () => {
        console.log("itemClickAction", itemClickAction)
        if (itemClickAction === 'navigate') {
            window.scrollTo(0, 0)
            if (listType && activeSet) {
                navigate(`/model/${model.ID}/${listType}/${activeSet}`)
            } else {
                navigate(`/model/${model.ID}`)
            }
        }
        if (itemClickAction === 'preview-drawer') {
            setIsOpenModelDrawer(true)
        }
    }

    const handleModelMultipleSelection = () => {
        cs_dispatch({ type: "ToggleSelectedItem", ID: model.ID })
    }

    useEffect(() => {
        if (modelAvailability && modelAvailability.ModelsInvolved.find(idModel => idModel === ID)) {
            setIsBusy(isModelBusy(modelAvailability.Events, ID))
            setFetched(true);
        } else {
            if (modelAvailability) {
                setFetched(true);
            }
            setIsBusy(false);
        }
    }, [modelAvailability]);

    const handleOpenModelDrawer = () => {
        setIsOpenModelDrawer(true);
    };

    const handleCloseModelDrawer = () => {
        setIsOpenModelDrawer(false);
    };

    const getCardActions = () => {
        const actions = []
        if (!layout.drawer) {
            actions.push(<AddToFolderButton ids={[ID]} type='model' showBadge />)
            actions.push(<AddToPackageButton model={model} activePackageOnly ><NwIcon icon={light("book-arrow-right")} /></AddToPackageButton>)
        }
        actions.push(<NwIcon icon={light('sidebar-flip')} onClick={handleOpenModelDrawer} />);
        return actions
    };

    const ModelNameText = ({ arrayname }) => (
        <>
            {arrayname[0]}
            <br />
            {arrayname[1].trim() ? arrayname[1] : <br />}
        </>
    )

    const ModelNameCard = ({ model }) => {
        const arrayname = modelFullName(model, false, true)
        if (modelAvailability) {
            return (
                <div className="model-name-and-calendar">
                    <ModelCalendarEvents
                        modelId={model.ID}
                        isFetched={isFetched}
                        isBusy={isBusy}
                    />
                    <Title><ModelNameText arrayname={arrayname} /></Title>
                </div>
            )
        }
        return (<Title><ModelNameText arrayname={arrayname} /></Title>)
    }

    return (
        <Spin spinning={loading}>
            <StyledCard
                $status={isFetched ? (isBusy ? 'busy' : 'no-busy') : ''}
                hoverable
                className={(multipleSelectionActive && isSelected) ? "selected" : null}
                cover={
                    <div className='model-card-top'>
                        {(isSelected && multipleSelectionActive) &&
                            <NwIcon
                                icon={solid('check-circle')}
                                style={selectionIconstyle}
                                primary
                            />
                        }
                        <div
                            className="model-cover"
                            onClick={multipleSelectionActive ? handleModelMultipleSelection : onModelClick}>
                            <AvatarBg
                                src={urlbg}
                                isPlaceholder={isPlaceholder} size={breakpoints < 4 ? '18rem' : '18rem'}
                                disabled={disabled}
                            />
                            {!multipleSelectionActive &&
                                <div className='hover-container'>
                                    <MainFeatures contact={model} />
                                </div>
                            }
                        </div>

                        <div className="tag-container">
                            {T.map((tag, index) => {
                                return <NwTag type="dark" key={`${tag.ID}-${index}`}>{tag.Name}</NwTag>
                            })}
                        </div>
                        {showID &&
                            <div className="id-container">
                                <NwTag type="primary">{model.ID}</NwTag>
                            </div>
                        }
                    </div>
                }
                actions={!multipleSelectionActive && getCardActions()}
            >
                <Meta
                    title={
                        <div className="model-name-container">
                            <ModelNameCard model={model} />
                            {model.AL &&
                                <ContactAlertPopover big contact={model} />
                            }
                        </div>
                    }
                    description={
                        <>
                            <ModelDetailsHeaderAgencies readOnly={multipleSelectionActive || layout.drawer} model={model} />
                            <ContactContainer>
                                <ContactUsers users={model.U} contactID={model.ID} readOnly={true} selected={isSelected} />
                            </ContactContainer>
                            <InTownPanel onClick={event => {
                                if (!multipleSelectionActive && !layout.drawer) {
                                    event.stopPropagation();
                                }
                            }}>
                                <ModelInTown plainMode={multipleSelectionActive || layout.drawer} modelID={model.ID} modelInTownSchedule={model.ITS} />
                            </InTownPanel>
                            {model.B &&
                                <AgePanel>
                                    <ModelDetailsAge birthday={model.B} />
                                </AgePanel>
                            }
                        </>
                    }
                    onClick={multipleSelectionActive ? handleModelMultipleSelection : onModelClick}
                />
                {isOpenModelDrawer &&
                    <ModelDrawer
                        modelID={model.ID}
                        hideCalendar={areaContext.area === 'scouting'}
                        showRating={areaContext.area === 'scouting'}
                        onClose={handleCloseModelDrawer} />
                }
            </StyledCard>
        </Spin>
    )
}

export default ModelCard;
