import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import EventModelActions from 'Components/EventDrawer/EventDrawerUi/EventModelActions';
import OptionNumber from 'Components/EventDrawer/EventDrawerUi/OptionNumber';
import FlexContainer from 'Components/Gui/FlexContainer';
import NwIcon from 'Components/Gui/NwIcon';
import { getModelNames } from './eventDatesAndModelsUtils';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { BookingEventSingleModelNameContainer } from 'Components/EventDrawer/EventDrawerUi/EventUI';

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
    .ant-collapse-arrow {
        margin-right: 0 !important;
    }
`;

const CollapseHandle = styled(FlexContainer)`
    cursor: pointer;
    margin-top: 8px;
`;

const CollapsibleModels = ({
    models,
    eventData,
    collapsed,
    eventDateInfo,
    currentModel,
    reloadGroupedModels,
    onExtendOptionNumberToAllDates,
    afterUpdate
}) => {
    const [isExpanded, setIsExpanded] = useState(collapsed || false);

    const renderSingleModel = (model, withMarginTop = false) => {
        return (
            <BookingEventSingleModelNameContainer key={model.ModelID} withMarginTop={withMarginTop}>
                <div className="model-name-container">
                    <span className={`model-name${model.Cancel ? ' is-cancelled' : ''}${(model.OptionNumber === 10) ? ' is-confirmed' : ''}`}>
                        {getContactName(model.ModelID).toLowerCase()}
                    </span>
                    <div onClick={e => e.stopPropagation()}>
                        <OptionNumber
                            key={model.OptionNumber}
                            number={model.OptionNumber}
                            event={eventData}
                            eventDateInfo={eventDateInfo}
                            selectedModel={model}
                            eventDatesModel={model}
                            reloadGroupedModels={reloadGroupedModels}
                            onExtendOptionNumberToAllDates={onExtendOptionNumberToAllDates}
                        />
                    </div>
                </div>
                <EventModelActions
                    model={model}
                    eventDateInfo={eventDateInfo}
                    eventData={eventData}
                    onReloadEventStatus={reloadGroupedModels}
                    afterUpdate={afterUpdate}
                />
            </BookingEventSingleModelNameContainer>
        )
    }

    const handleCollapse = event => {
        event.stopPropagation();
        setIsExpanded(isExpanded ? null : '1')
    }

    const sortedModels = useMemo(() => {
        const sortedModels = [...models];
        sortedModels.sort((a, b) => {
            if (a.Cancel !== b.Cancel) {
                return a.Cancel ? 1 : -1;
            }
            if (a.Cancel && b.Cancel) {
                if (a.OptionNumber === 10 && b.OptionNumber !== 10) {
                    return -1;
                }
                if (a.OptionNumber !== 10 && b.OptionNumber === 10) {
                    return 1;
                }
            }
            if (a.OptionNumber === 10 && b.OptionNumber !== 10) {
                return -1;
            }
            if (a.OptionNumber !== 10 && b.OptionNumber === 10) {
                return 1;
            }
            return getContactName(a.ModelID).localeCompare(getContactName(b.ModelID));
        });
        return sortedModels;
    }, [models]);

    return (
        <StyledCollapse
            activeKey={isExpanded ? '1' : null}
            onChange={() => setIsExpanded(isExpanded ? null : '1')}
            ghost
            showArrow={false}
            accordion={true}
            expandIcon={() => <div />}
            bordered={false}
            className="event-info-collapse"
        >
            <Panel
                header={isExpanded
                    ?
                    renderSingleModel(sortedModels[0], 0)
                    :
                    getModelNames(sortedModels)
                }
                key="1"
            >
                {sortedModels.map((model, index) => {
                    if (index === 0) {
                        return null
                    }
                    return renderSingleModel(model, index, true)
                })
                }
                <CollapseHandle onClick={handleCollapse}>
                    <NwIcon icon={light('chevron-up')} />&nbsp;COLLAPSE LIST
                </CollapseHandle>
            </Panel>
        </StyledCollapse>
    );
}

export default CollapsibleModels;