import React, { createContext, useContext, useReducer } from 'react';
import ls from 'local-storage';
import Axios from 'axios';


const FoldersContext = createContext(null);

const FoldersDispatchContext = createContext(null);

export const FOLDERS_EMPTY = {
    lastupdate: null,
    openFolders: []
};

export function FoldersProvider({ children }) {
    const folders = ls.get("nw_config_openfolders") || { ...FOLDERS_EMPTY };

    const [data, dispatch] = useReducer(foldersReducer, folders);

    return (
        <FoldersContext.Provider value={data}>
            <FoldersDispatchContext.Provider value={dispatch}>
                {children}
            </FoldersDispatchContext.Provider>
        </FoldersContext.Provider>
    );
}

export function useFolders() {
    return useContext(FoldersContext);
}

export function useFoldersDispatch() {
    return useContext(FoldersDispatchContext);
}


const persistsOpenFolders = async (value) => {
    ls.set("nw_config_openfolders", value)
    console.log("persistsOpenFolders")
    await Axios.post('/userclientconfigurations', {
        Name: 'folders',
        JsonData: JSON.stringify({
            openFolders: value.openFolders
        })
    });
}

const foldersReducer = (state, action) => {
    const draft = { ...state }
    switch (action.type) {
        case 'UpdateFolders': 
            if (action.lastupdate) {
                draft.lastupdate = action.lastupdate;
            }
            if (action.openFolders.length > 0) {
                draft.openFolders = [...action.openFolders];
            }
            ls.set("nw_config_openfolders", draft)
            return draft
        case 'CreateFolder':
            draft.openFolders.push(action.newFolder)
            persistsOpenFolders(draft)
            return draft
        case 'EditFolder':
            const folder = draft.openFolders.find(p => p.ID === Number(action.folder.folderID));
            folder.title = action.folder.folderName;
            folder.color = action.folder.color;
            folder.type = action.folder.type;
            persistsOpenFolders(draft)
            return draft
        case 'RemoveFolder':
            draft.openFolders = draft.openFolders.filter(p => p.ID !== Number(action.folderID))
            persistsOpenFolders(draft)
            return draft
        case 'UpdateOrderFolderItems':
            draft.openFolders.find(p => p.ID === Number(action.folder.ID)).items = action.folder.items
            persistsOpenFolders(draft)
            return draft
        case 'AddItemsToFolder':
            let newItemslist = [...draft.openFolders.find(p => p.ID === Number(action.folderID)).items]
            action.items.forEach(actionItemId => {
                if (newItemslist.findIndex(itemId => itemId === actionItemId) < 0) {
                    newItemslist.push(actionItemId);
                }
            });
            draft.openFolders.find(p => p.ID === Number(action.folderID)).items = newItemslist
            persistsOpenFolders(draft)
            return draft
        case 'RemoveItemsFromFolder':
            const currentFolder = draft.openFolders.find(p => p.ID === Number(action.folderID))
            const FilteredFolderItems = currentFolder.items.filter(itemId => !action.items.includes(itemId))
            currentFolder.items = FilteredFolderItems
            persistsOpenFolders(draft)
            return draft
        case 'ClearFolder':
            const indfoldertoremove = draft.openFolders.findIndex(p => p.ID === Number(action.folderID))
            draft.openFolders.splice(indfoldertoremove,1)
            persistsOpenFolders(draft)
            return draft
        case 'ClearFolders':
            //ls.set("nw_config_openfolders", null)
            draft.lastupdate = null
            draft.openFolders = []
            persistsOpenFolders(draft)
            return draft
        default:
            //do nothing
    }
};
