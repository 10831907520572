import React, { useMemo } from "react";
import styled from 'styled-components';
import EventTypeTag from "Components/EventDetails/EventTypeTag";
import { NWSelect } from "Components/Gui/NwForm/NwFormItems";
import { Field } from "formik";


const StyledField = styled(Field)`
  .ant-select-selection-item > div > div {
    &.name {
      display: block;
    }
  }
  .event-selector-option {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;


const EventSelector = ({ form, name, events, onChange, label, typesToExclude, disabled, required, withZeroOption = true }) => {

    const onSelectChange = ({ value }) => {
        const event = events.find(e => e.uniqID === value);
        form.setFieldValue('EventID', event ? event.uniqID : null);
        form.setFieldValue('ContactID', null);
        onChange(event, form.setFieldValue)
    }

    const eventsOptions = useMemo(() => {
        let filteredEvents = []
        if (withZeroOption) {
            filteredEvents.push({ uniqID: null, Name: 'Select an event' })
        }
        if (typesToExclude) {
            const notExcludedEvents = events.filter(event => !typesToExclude.includes(event.elType))
            filteredEvents.push(...notExcludedEvents);
        } else {
            filteredEvents.push(...events);
        }
        return filteredEvents
    }, [events]);

    return (
        <StyledField
            component={NWSelect}
            value={form.values[name]}
            label={label}
            name={name}
            onAfterChange={onSelectChange}
            disabled={disabled}
            placeholder='Select an event'
            labelInValue
            dropdownMatchSelectWidth={false}
            options={eventsOptions.map(ev => {
                return { key: ev.uniqID, value: ev.uniqID, label: <div className="event-selector-option"><EventTypeTag event={ev} /><div className="name">{ev.Name || ev.Title}</div></div> }
            })}
            required={required}
        />
    )
};

export default EventSelector;
