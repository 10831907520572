import React from 'react';
import { Field } from "formik";
import { Row, Col } from 'antd';
import { NWAmountInput, NWContactDropdown } from "Components/Gui/NwForm/NwFormItems";

const ServiceLine = ({ values, setFieldValue, hasAgencyCost, gutter = 16 }) => {
    return (
        <Row gutter={gutter}>
            <Col flex="auto">
                <Field
                    component={NWContactDropdown}
                    label="Service"
                    activeTypes={['srv']}
                    activableTypes={['cli', 'age']}
                    name="ServiceID"
                    value={values.ServiceID}
                    onAfterChange={
                        (value) => {
                            if (value) {
                                setFieldValue("AgencyCost", [values.Amount[0], values.Amount[1]])
                            }
                        }
                    }
                />
            </Col>
            {hasAgencyCost &&
                <Col>
                    {values.ServiceID ?
                        <Field
                            component={NWAmountInput}
                            value={values.AgencyCost}
                            label="Cost for the agency"
                            name="AgencyCost"
                            validate={(value) => {
                                let error;
                                if (value[1] === 0) {
                                    error = 'agency cost is required if a service is selected';
                                } else {
                                    if (value[1] > values.Amount[1]) {
                                        error = 'agency cost cannot be higher than expense amount';
                                    }
                                }
                                return error;
                            }}
                            currencyReadOnly
                            fixedCurrency={values.Amount[0]}
                        />
                    :
                    <div style={{width: '10rem'}}>&nbsp;</div>
                    }
                </Col>
            }
        </Row>
    )
}

export default ServiceLine;