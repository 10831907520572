import React, { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import NwDrawer from "Components/Gui/NwDrawer";
import { NWInput, } from 'Components/Gui/NwForm/NwFormItems';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { useGroupRows } from "Hooks/Invoices/UseInvoices";

const GroupDrawer = (props) => {
    const { onClose, invoiceID, invoiceRowIDs } = props;
    const [touchForm, setTouchForm] = useState(false);
    const { mutateAsync: onGroupRows } = useGroupRows();

    const initialValues = {
        text: "",
    }

    const saveGroupRows = async (values, setSubmitting) => {
        setSubmitting(true);
        try {
            await onGroupRows({
                ID: invoiceID,
                data: {
                    invoiceRowIDs: invoiceRowIDs,
                    text: values.text
                }
            });
            onClose();
        } catch (e) {
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title="Group selected lines"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    saveGroupRows(values, setSubmitting);
                }}
                validationSchema={Yup.object().shape({
                    text: Yup.string().required('Content is required'),
                })}
                render={({
                    isSubmitting,
                    values,
                    handleSubmit,
                }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit} 
                    >
                        <Field
                            component={NWInput}
                            label="Text"
                            name="text"
                            maxLength={50}
                            type="text"
                            value={values.text}
                        />
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            />
        </NwDrawer>
    );
}

export default GroupDrawer;