import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import {Calendar, momentLocalizer, Views} from 'react-big-calendar'
import { getMoment } from "Libs/NwMoment";
import { modelCalendarParseEventsTime } from 'Libs/Calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import EventCard from 'Components/Schedule/Calendar/EventCard';
import CalendarWrapper from 'Components/Schedule/Calendar/CalendarUI';
import { eventPropGetter } from 'Libs/Calendar';
import NwDrawer from 'Components/Gui/NwDrawer';
import { Loading } from 'Components/Gui/Loading';
import { getScheduleEvents } from 'Components/Schedule/ScheduleUtils';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { UserContext } from 'Contexts/UserContext';
import { saxonize } from 'Libs/NwUtils';
import moment from 'moment';
import { useUserGuiConfig } from 'Contexts/UserGuiConfigContext';

const GeneralBigCalendar = Calendar;
const localizer = momentLocalizer(moment);

const convertEvent = event => {
    let newEvent = { ...event };

    if (event.grouped) {
        if (event.grouped.find(model => model.Acknowledgment === 'NotChecked')) {
            newEvent.Acknowledgment = 'NotChecked';
        } else {
            newEvent.Acknowledgment = 'Checked';
        }

        if (event.grouped.find(model => !model.Cancel)) {
            newEvent.Cancel = false;
        } else {
            newEvent.Cancel = true;
        }
    }
    return newEvent;
}

const DailyCalendarDrawer = ({
    params,
    filters,
    scheduleRange,
    // reload,
    // onReload,
    // afterReload,
    // selectedEvent,
    // onSelectEvent,
    onClose
}) => {

    const [isFetching, setIsFetching] = useState(false)
    const userGuiConfig = useUserGuiConfig()
    const [events, setEvents] = useState([])
    const { currentUser } = useContext(UserContext);

    // useEffect(() => {
    //     if (userGuiConfig.calendar.weekFormat === 'monday') {
    //         moment.locale('us', {
    //             week: {
    //                 dow: 1,
    //                 doy: 1
    //             }
    //         });
    //     } else {
    //         moment.locale('us', {
    //             week: {
    //                 dow: 0,
    //                 doy: 1
    //             }
    //         });
    //     }
    // }, [userGuiConfig.calendar.weekFormat])

    moment.locale('us', {
        week: {
            dow: 1,
            doy: 1
        }
    });

    useEffect(() => {
        if (scheduleRange) {
            fetchEvents()
        }
    }, [params, scheduleRange])

    // useEffect(() => {
    //     if (scheduleRange && reload) {
    //         fetchEvents()
    //     }
    // }, [reload])

    const fetchEvents = async () => {
        setIsFetching(true)
        const scheduleEvents = await getScheduleEvents(params, scheduleRange)
        setEvents(modelCalendarParseEventsTime(scheduleEvents))
        setIsFetching(false)
        //afterReload()
    }

    const displayedEvents = useMemo(() => {
        if (events) {
            let filteredEvents = [...events];
            filteredEvents = filteredEvents.map(event => convertEvent(event));

            if (filters.filterAcknowledgement === 'checked') {
                filteredEvents = filteredEvents.filter(event => event.Acknowledgment === 'Checked');
            } else if (filters.filterAcknowledgement === 'tocheck') {
                filteredEvents = filteredEvents.filter(event => event.Acknowledgment === 'NotChecked');
            }
            if (filters.filterMine === 'mine') {
                //filteredEvents = filteredEvents.filter(event => event.UserCreated === currentUser.UserID);
                
                filteredEvents = filteredEvents.filter(event => event.UserCreated === currentUser.UserID || (event.Project && event.Project.UserCreated === currentUser.UserID));
                
                //possible change: filter by project user created, and event user created (if project is null)
                //filteredEvents = filteredEvents.filter(event => event.Project ? event.Project.UserCreated === currentUser.UserID : event.UserCreated === currentUser.UserID);
            }

            if (filters.hideCancelled) {
                filteredEvents = filteredEvents.filter(event => !event.Cancel);
            }
            if (filters.hideBilling) {
                filteredEvents = filteredEvents.filter(event => !event.Locked && event.TypeName === 'Job' && event.Confirmed > 0);
            }
            return filteredEvents
        }
        return []
    }, [events, scheduleRange, filters]);


    const getEditDrawerTitle = () => {
        let title = getMoment(scheduleRange.start).format("ddd Do MMM YYYY")
        if (params.General) {
            title += " - Agency Calendar"
        } else {
            if (params.idmodels && params.idmodels.length === 1) {
                title += ` - ${saxonize(getContactName(params.idmodels[0]))} Calendar`
            }
        }
        return title
    }

    const scrollRef = useRef(null);

    const scrollToBottom = () => {
        console.log("scrollRef", scrollRef.current)
        if (scrollRef && scrollRef.current) {
            const timeHeaderDiv = scrollRef.current.querySelector('.rbc-time-header');
            const timeHeaderDivHeight = timeHeaderDiv.offsetHeight;
            console.log("timeHeaderDivHeight", timeHeaderDivHeight)
            const timeColumn = scrollRef.current.querySelector('.rbc-time-column');
            const timeSlotToScroll = timeColumn.querySelectorAll('.rbc-timeslot-group')[5];
            const timeSlotToScrollHeight = timeSlotToScroll.offsetHeight;
            console.log("timeSlotToScrollHeight", timeSlotToScrollHeight);
            if (timeSlotToScroll) {
                timeSlotToScroll.scrollIntoView({behavior: 'smooth'});
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            scrollToBottom();
        }, 1000);
    },[])

    return (
        <NwDrawer
            onClose={onClose}
            title={getEditDrawerTitle()}
            width="80vw"
            padding="0"
        >
            {isFetching
                ?
                <Loading />
                :
                <CalendarWrapper>
                    <div className='general-calendar daily-drawer agenda-view'>
                        <div className='calendar' ref={scrollRef}>
                            <GeneralBigCalendar
                                formats={{
                                    timeGutterFormat: userGuiConfig.calendar.timeFormat === '24' ? 'H:mm' : 'h:mm a'
                                }}
                                tooltipAccessor={null}
                                selectable="ignoreEvents"
                                localizer={localizer}
                                defaultDate={getMoment(scheduleRange.start).toDate()}
                                views={{ day: true }}
                                agendaView
                                components={{
                                    event: EventCard,
                                    toolbar: (toolbar) => (
                                        <div />
                                    )
                                }}
                                defaultView={Views.DAY}
                                eventPropGetter={eventPropGetter}
                                events={displayedEvents}
                            />
                        </div>
                    </div>
                </CalendarWrapper>
            }
        </NwDrawer>
    )
}

export default DailyCalendarDrawer;