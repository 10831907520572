import React, { useState } from 'react';
import styled from 'styled-components';
import { PageHeader } from 'antd';

import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import SendProjectDrawer from 'Components/SendProjectDrawer/SendProjectDrawer';
import NwIcon from 'Components/Gui/NwIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import ProjectTitle from 'Components/Project/ProjectTitle';
import AddProjectEventDropdown from './AddProjectEventDropdown';
import IconTemplateSelector from 'Components/TemplateSelector/IconTemplateSelector';
import Axios from 'axios';
import NwDrawer from 'Components/Gui/NwDrawer';
import DocVersionDrawer from 'Components/DocVersionDrawer/DocVersionDrawer';
import { Loading } from 'Components/Gui/Loading';
import ErrorMessage from 'Components/Gui/ErrorMessage';
import ReportCreationDrawer from './ProjectReport/ReportCreationDrawer';

const ProjectHeader = styled.div`
    z-index: 1030;
    top: ${props => props.$isdrawer ? "0" : "80px"};
    width: 100%;
    position: sticky;

    .ant-page-header {
        padding-right: 2.25rem;
        padding-top: .5rem;
        margin: ${props => props.$mobileView ? "0 0 1rem 0" : (props.$isdrawer ? "0 1rem 1rem 1rem" : "1rem")};
        height: 108px;
        border-bottom: ${props => props.theme.DefaultBorder};

        .project-print-button {
            font-size: 1.4rem;
            margin-right: 1rem;
        }
        .project-send-button {
            font-size: 1.4rem;
            margin-right: 1rem;
        }
        .project-addevent-button {
            font-size: 1.4rem;
            margin-right: 1rem;
        }
    }
`;

const ProjectPageHeader = ({ projectId, isdrawer, currentProject }) => {
    //const { data: currentProject } = useProject(projectId);
    const breakpoints = useNwBreakPoints();
    const [sendProjectDrawer, setSendProjectDrawer] = useState(false)
    const [printDrawer, setPrintDrawer] = useState(false)
    const [docVersion, setDocVersion] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleShareProject = () => {
        setSendProjectDrawer(true)
    }

    // const handleOpenPrintDrawer = async (templateID) => {
    //     setLoading(true);
    //     setPrintDrawer(true)
    //     const createDocData = {
    //         ProjectID: projectId,
    //         Area: "ProjectReport",
    //         Template: templateID
    //     };
    //     try {
    //         const document = await Axios.post("/projects/printreport", createDocData);
    //         setDocVersion(document)
    //         setLoading(false)
    //     } catch (error) {
    //         console.log('ant : Create Doc Error => ', error);
    //         setLoading(false)
    //     }
    // };

    const handleOpenCreateReportDrawer = () => {
        setPrintDrawer(true)
    }

    const handleCloseReportDrawer = () => {
        setPrintDrawer(false)
    }

    return (
        <>
            <ProjectHeader $isdrawer={isdrawer} $mobileView={breakpoints < 4}>
                {(currentProject && currentProject.Contact) &&
                    <PageHeader
                        title={
                            <div style={{ marginLeft: "1rem" }}>
                                <ProjectTitle 
                                    project={currentProject} 
                                    showDepartment 
                                    allowBookerChange
                                />
                            </div>
                        }
                        extra={
                            [
                                <div key="project-report-button" className="project-print-button">
                                    {/* <IconTemplateSelector
                                        icon={light("file-pdf")}
                                        onClick={handleOpenPrintDrawer}
                                        placement="bottomRight"
                                        templateArea="ProjectReport"
                                    /> */}
                                    <NwIcon 
                                        icon={light("file-pdf")} 
                                        pointer 
                                        onClick={handleOpenCreateReportDrawer} 
                                    />
                                </div>,
                                <div key="project-send-button" className="project-send-button">
                                    <NwIcon key="share" icon={light("envelope")} pointer onClick={handleShareProject} />
                                </div>,
                                <div key="project-add-event-button" className="project-addevent-button">
                                    <AddProjectEventDropdown projectId={projectId} />
                                </div>
                            ]
                        }
                        subTitle={false}
                    >
                    </PageHeader>
                }
            </ProjectHeader>
            {sendProjectDrawer &&
                <SendProjectDrawer
                    currentProject={currentProject}
                    onClose={() => setSendProjectDrawer(false)}
                />
            }
            {printDrawer &&
                <ReportCreationDrawer
                    projectId={projectId}
                    onClose={handleCloseReportDrawer}
                />
            }
        </>
    )
}

export default ProjectPageHeader
