import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Axios from 'axios';
import { NwForm } from 'Components/Gui/NwForm/NwFormWrapper';
import { NWInput, NWTextAreaCharCounter, NWContactWithBillingInfo, NWDatePicker, NWContactDropdown } from "Components/Gui/NwForm/NwFormItems";
import useConfigurations from 'Hooks/UseConfigurations';
import { ProjectTransactionFormContainer } from 'Components/Project/ProjectTransactions/Transactions/ProjectTransactionsUI';
import { Loading } from 'Components/Gui/Loading';
import AmountLine from './Common/AmountLine';
import useTransactionLabels from 'Hooks/UseTransactionLabels';
import TransactionLabelsSelector from './Common/TransactionLabelsSelector';
import FixedSaveAndCancelBar from './Common/FixedSaveAndCancelBar';
import { getMoment } from "Libs/NwMoment";
import { datePickerToApi, getNow } from 'Libs/NwMoment';
import { forceReloadExternalJob } from 'Hooks/Event/UseExternalJob';
import { getContactBillingInfo } from 'Components/Project/ProjectTransactions/Transactions/TransactionCreateFormUtils';
import { getContactName } from 'Hooks/Contact/UseContactsList';


const CommAsMothAgForm = ({ externalJobData, agencyID, onCloseDrawer, onTransactionCreated }) => {
    const transactionProperties = useTransactionLabels("CommAsMothAg");
    const defaultTypeQuantity = transactionProperties.startLabel.TypeQuantityName ? transactionProperties.startLabel.TypeQuantityName : "Units"
    const configurations = useConfigurations();
    const [clientBillingInfos, setClientBillingInfos] = useState(null);

    useEffect(() => {
        const fetchBillingInfo = async (contactID) => {
            const billingInfos = await getContactBillingInfo(contactID)
            setClientBillingInfos(billingInfos)
        }

        if (externalJobData) {
            fetchBillingInfo(externalJobData.AgencyID)
        }
        if (agencyID) {
            fetchBillingInfo(agencyID)
        }
    }, [externalJobData, agencyID]);

    const saveTransaction = async (values, setSubmitting) => {
        const apiData = {
            ExtJobID: externalJobData ? externalJobData.ID : null,
            AgencyID: externalJobData ? externalJobData.AgencyID : agencyID,
            AgencyBillingInfoID: values.ContactToBill.ContactBillingInfoID,
            ModelID: externalJobData ? externalJobData.ModelID : values.ModelID,
            TransactionLabelID: values.TransactionLabelID,
            Title: values.Title,
            Description: values.Description,
            Quantity: values.Quantity[0],
            TypeQuantity: values.Quantity[1],
            RefDate: values.RefDate && datePickerToApi(values.RefDate),
            Currency: values.Amount[0],
            SingleFee: values.Amount[1]
        }

        try {
            await Axios.post('/transactions/CommAsMothAg', apiData);
            if (externalJobData) {
                forceReloadExternalJob(externalJobData.ID);
            }
            if (onTransactionCreated) {
                onTransactionCreated()
            }
            onCloseDrawer();
            setSubmitting(false);
        } catch (error) {
            console.log('ant : Create Commission Error => ', error);
            setSubmitting(false);
        }

    };


    const clientBillingInfoDefaultID = useMemo(() => {
        if (!clientBillingInfos) return null
        const clientBillingInfoDefault = clientBillingInfos.find(item => item.isDefault)
        let cbiID = null
        if (clientBillingInfoDefault) {
            cbiID = clientBillingInfoDefault.ID
        } else {
            if (clientBillingInfoDefault && clientBillingInfoDefault.length > 0) {
                cbiID = clientBillingInfoDefault[0].ID
            }
        }
        return cbiID
    }, [clientBillingInfos]);

    const initialValues = useMemo(() => {
        return {
            ContactToBill: { ContactID: externalJobData ? externalJobData.AgencyID : agencyID, ContactBillingInfoID: clientBillingInfoDefaultID },
            TransactionLabelID: transactionProperties.startLabel.ID,
            Title: transactionProperties.startLabel.Label,
            Description: '',
            ModelID: externalJobData ? externalJobData.ModelID : null,
            RefDate: ((externalJobData && externalJobData.StartDate) ? getMoment(externalJobData.StartDate) : getNow()),
            Amount: [configurations.defaultCurrency, null],
            Quantity: [1, defaultTypeQuantity]
        }
    }, [clientBillingInfoDefaultID, configurations.defaultCurrency, defaultTypeQuantity, externalJobData, agencyID, transactionProperties.startLabel.ID, transactionProperties.startLabel.Label]);

    const getAgencyIDArray = () => {
        const agID = externalJobData ? externalJobData.AgencyID : agencyID
        if (agID) {
            return [{ ID: agID, Name: getContactName(agID) }]
        }
        return []
    }

    return (
        (clientBillingInfos)
            ?
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Title: Yup.string().required("Title is required"),
                    TransactionLabelID: Yup.string().required("Label is required"),
                    ContactToBill: Yup.string().required("Agency is required")
                })}
                validateOnBlur={true}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    saveTransaction(values, setSubmitting);
                }}
            >
                {({ isSubmitting, values, setFieldValue, handleSubmit, touched, isValid }) => (
                    <NwForm
                        values={values}
                        //onTouchForm={onChangeForm}
                        onFinish={handleSubmit}
                        layout="vertical">
                        <ProjectTransactionFormContainer>
                            {/* <Row gutter={32}>
                                <Col span={14} className='rate-form'> */}
                                    <TransactionLabelsSelector 
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        transactionProperties={transactionProperties} 
                                        updateTitle
                                    />
                                    <Field
                                        component={NWContactWithBillingInfo}
                                        value={values.ContactToBill}
                                        label="Agency"
                                        name="ContactToBill"
                                        contacts={getAgencyIDArray()}
                                        billingInfos={clientBillingInfos}
                                        disabled
                                        required
                                    />
                                    {externalJobData
                                    ?
                                        <div class="nw-form-item-container">
                                            <div class="ant-form-item ant-form-item-with-help true ant-form-item-has-success">
                                                <div class="ant-row ant-form-item-row">
                                                    <div class="ant-col ant-form-item-label">
                                                        <label class="ant-form-item-required ant-form-item-no-colon" title="Model">Model</label>
                                                    </div>
                                                    <div class="ant-col ant-form-item-control">
                                                        <div class="ant-form-item-control-input">
                                                            <div class="ant-form-item-control-input-content">
                                                                <div>
                                                                    <h3>{getContactName(externalJobData.ModelID)}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        <Field
                                            component={NWContactDropdown}
                                            label="Model"
                                            name="ModelID"
                                            activeTypes={['mod', 'sco']}
                                            value={values.ModelID}
                                            required
                                        />
                                    }
                                    <Field
                                        component={NWDatePicker}
                                        label="Date"
                                        name="RefDate"
                                        format="MMM DD, YYYY"
                                        allowClear={false}
                                        value={values.RefDate}
                                    />
                                    <Field
                                        component={NWInput}
                                        label="Title"
                                        name="Title"
                                        type="text"
                                        value={values.Title}
                                    />
                                    <Field
                                        component={NWTextAreaCharCounter}
                                        label="Description"
                                        name="Description"
                                        type="text"
                                        value={values.Description}
                                        maxLength={2000}
                                        autoSize={{ minRows: 3, maxRows: 10 }}
                                    />
                                    <AmountLine
                                        values={values}
                                        touched={touched}
                                        amountLabel="Commission Amount"
                                    />
                                {/* </Col>
                            </Row> */}
                        </ProjectTransactionFormContainer>
                        <FixedSaveAndCancelBar
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                            width="40vw"
                            minWidth="640px"
                            onCancel={() => {
                                //onChangeForm(false);
                                onCloseDrawer();
                            }}
                        />
                    </NwForm>
                )
}
            </Formik >
            :
<Loading />
    )
};

export default CommAsMothAgForm;