import React from "react";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";
import { Segmented } from "antd";
import styled from "styled-components";
import NwIcon from "../NwIcon";

const StyledSegmented = styled(Segmented)`
    .label {
        padding: .5rem;

        .label-icon {
            font-size: 1.2rem;
        }
        .label-text {
            font-size: 1rem;
            text-transform: uppercase;
        }
    }
    .ant-segmented-item-selected {
        .label {
            .label-icon {
                color: ${props => props.theme.PrimaryColor};
            }
            &.empty {
                .label-icon {
                    color: ${props => props.theme.TextColorMuted};
                }
                .label-text {
                    font-weight: normal;
                    color: ${props => props.theme.TextColorMuted};
                }
            }
        }
        
    }
`

export const SegmentedOptionContent = ({ text, icon, empty }) => (
    <div className={`label ${empty ? 'empty' : ''}`}>
        {icon && 
        <div className="label-icon">
            <NwIcon icon={icon} />
        </div>
        }
        <div className="label-text">{text}</div>
    </div>
)

const InTownSelector = ({ value, disabled, onChange, emptyValue, asSegmented }) => {
    
    const optionsAsSegmented = [
        { 
            label: <SegmentedOptionContent text="In Town" icon={light('location-smile')} />, 
            value: true,
        },
        { 
            label: <SegmentedOptionContent text="Out" icon={light('island-tropical')} />, 
            value: false,
        },
    ];
    if (emptyValue) {
        optionsAsSegmented.unshift(
            { 
                label: <SegmentedOptionContent empty text="Any" icon={light('globe')} />, 
                value: null
            }
        );
    }

    const options = [
        { 
            label: "In Town",
            icon: light('location-smile'), 
            value: true,
        },
        { 
            label: "Out",
            icon: light('island-tropical'), 
            value: false,
        },
    ];
    if (emptyValue) {
        options.unshift(
            { 
                label: "Any",
                icon: light('globe'), 
                value: null
            }
        );
    }
    
    if (asSegmented) {
        return (
            <StyledSegmented
                options={optionsAsSegmented}
                value={value}
                disabled={disabled}
                onChange={onChange}
                defaultValue={emptyValue ? null : false}
            />
        );
    }
    return (
        <RadioButtonGroup 
            value={value} 
            disabled={disabled}
            onChange={onChange}
            defaultValue={emptyValue ? null : false}
            options={options} />
    )
};

export default InTownSelector;
