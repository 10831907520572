import React from 'react';
import styled from 'styled-components';

import ProjectCard from 'Components/ProjectCard/ProjectCard';
import TravelPlanCard from 'Components/TravelPlanCard/TravelPlanCard';
import FlexContainer from 'Components/Gui/FlexContainer';
import { ProjectSelectorCard } from 'Components/ProjectCard/ProjectSelectorCard';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import NwIcon from 'Components/Gui/NwIcon';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router';

const Header = styled.div`
    border-bottom: ${props => props.$mobileView && '2px solid #f0f0f0'};
    height: ${props => props.$mobileView ? '80px' : '160px'};
    padding: 8px 24px 16px 24px;

    .expand-icon-container {
        position: absolute;
        right: 2.75rem;
        top: 1.5rem;
    }
`;

const EventDrawerHeader = ({
    typeName,
    data,
    modelID,
    onSelectProject,
    projectErrorMessageProps
}) => {
    const breakpoints = useNwBreakPoints();
    const hasProject = () => data && data.Project
    const navigate = useNavigate();

    const handleMoveToProject = () => {
        if (data && data.Project) {
            navigate(`/bookings/${data.Project.ID}`);
        } else {
            return null;
        };
    }

    const getDrawerHeaderWithProjectCard = () => (
        <Header $mobileView={breakpoints === 1} className={`ant-drawer-header ant-drawer-header-sticky ${breakpoints !== 1 ? 'ant-drawer-header-noborder' : ''}`}>
            <ProjectCard
                project={data ? data.Project : null}
                type={typeName}
                event={data}
                modelId={modelID}
                projectErrorMessageProps={projectErrorMessageProps}
            />
            {data && data.Project &&
                <div className="expand-icon-container">
                    <NwIcon icon={solid('expand-wide')} pointer onClick={handleMoveToProject} size='lg' />
                </div>
            }
        </Header>
    )

    const getDrawerHeaderWithProjectSelector = () => (
        <div className="ant-drawer-header">
            <ProjectSelectorCard
                onSelectProject={onSelectProject}
            />
        </div>
    )

    const getDrawerHeaderNoProject = () => (
        <div className="ant-drawer-header">
            [to do]
        </div>
    )

    const getDrawerHeaderWithTravelPlanCard = () => (
        <div className="ant-drawer-header ant-drawer-header-sticky ant-drawer-header-noborder" style={{ height: "150px" }}>
            <FlexContainer justifyContent="space-between">
                <TravelPlanCard
                    travelPlan={data.TravelPlan}
                    plainMode />
            </FlexContainer>
        </div>
    )

    switch (typeName) {
        case "ScoutingTravel":
            return getDrawerHeaderWithTravelPlanCard()
        case "RoomLodging":
        case "ExtJob":
            return getDrawerHeaderNoProject()
        default:
            if (hasProject()) {
                return getDrawerHeaderWithProjectCard()
            } else {
                return getDrawerHeaderWithProjectSelector()
            }
    }
}

export default EventDrawerHeader;