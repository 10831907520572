import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { getMoment } from "Libs/NwMoment";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import CalendarWrapper from 'Components/Schedule/Calendar/CalendarUI';
import BookingEventCard from 'Components/EventDrawer/BookingEvent/EventDatesAndModels/BookingEventCard';
import { eventPropGetter, modelCalendarParseEventsTime } from 'Libs/Calendar';
import { onChangeEventDateModels, forceReloadEvent } from 'Hooks/Event/UseEvent';
import { isAllDay } from 'Libs/NwMoment';
import moment from 'moment';
import { useUserGuiConfig } from 'Contexts/UserGuiConfigContext';

// const DragAndDropCalendar = withDragAndDrop(BigCalendar);
// const localizer = BigCalendar.momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);

const EventDateModel = ({ eventDateInfo, eventData, afterUpdate }) => {
    const userGuiConfig = useUserGuiConfig()
    const [draggedEvent, setDraggedEvent] = useState();
    const [events, setEvents] = useState([]);

    const handleDragStart = event => {
        setDraggedEvent(event);
    }

    const dragFromOutsideItem = () => {
        return draggedEvent
    };

    const onDropFromOutside = ({ start, end, allDay }) => {
        const event = {
            ID: draggedEvent.ID,
            start,
            end,
            allDay: allDay,
        }

        setDraggedEvent(draggedEvent);
        moveEvent({ event, start, end });
    }

    const moveEvent = async ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
        let allDay = event.allDay

        if (!event.allDay && droppedOnAllDaySlot) {
            allDay = true
        } else if (event.allDay && !droppedOnAllDaySlot) {
            allDay = false
        }

        const nextEvents = events.map(existingEvent => {
            return existingEvent.ID === event.ID
                ? { ...existingEvent, start, end, allDay }
                : existingEvent
        })

        setEvents(nextEvents);
        const data = {
            eventDatesModelIDs: [event.ID],
            fromHour: droppedOnAllDaySlot ? '00:00' : getMoment(start).format('HH:mm'),
            toHour: droppedOnAllDaySlot ? '23:59' : getMoment(end).format('HH:mm'),
            UpdateTime: true
        }

        await onChangeEventDateModels(data);
        forceReloadEvent(event.EventID);
        afterUpdate();
    }

    const resizeEvent = async ({ event, start, end }) => {
        const nextEvents = events.map(existingEvent => {
            return existingEvent.ID === event.ID
                ? { ...existingEvent, start, end }
                : existingEvent
        })

        setEvents(nextEvents);
        const data = {
            eventDatesModelIDs: [event.ID],
            fromHour: getMoment(start).format('HH:mm'),
            toHour: getMoment(end).format('HH:mm'),
            UpdateTime: true
        }

        await onChangeEventDateModels(data);
        forceReloadEvent(event.EventID);
    }

    useEffect(() => {
        const parsedEvents = modelCalendarParseEventsTime(eventDateInfo.EventDatesModels.map(model => ({
            ...model,
            FirstDate: model.FromDate,
            LastDate: model.ToDate,
            eventData: { ...eventData }
        }))).map(event => ({
            ...event,
            allDay: isAllDay(getMoment(event.FromDate), getMoment(event.ToDate))
        }));
        setEvents(parsedEvents);
    }, [eventDateInfo]);

    const hasBreaks = eventDateInfo.EventDatesModels.some(model => model.StartBreak && model.EndBreak);
    if (hasBreaks) {
        return (
            <div>
                <br/><br/>
                <p><strong>Sorry, you can't use this tool with multi-time work days.<br />Please edit the work date details using the pen icon</strong></p>
            </div>
        )
    }

    return (
        <div>
            <CalendarWrapper>
                <div className='general-calendar'>
                    <div className='calendar'>
                        <DragAndDropCalendar
                            formats={{
                                timeGutterFormat: userGuiConfig.calendar.timeFormat === '24' ? 'H:mm' : 'h:mm a'
                            }}
                            selectable
                            // selectable="ignoreEvents"
                            localizer={localizer}
                            defaultDate={getMoment(eventDateInfo.FromDate).toDate()}
                            views={{ day: true }}
                            components={{
                                event: BookingEventCard,
                                toolbar: (toolbar) => <div />
                            }}
                            tooltipAccessor={null}
                            defaultView={Views.DAY}
                            eventPropGetter={eventPropGetter}
                            events={events.length === 0 ? [] : events}
                            onDropFromOutside={onDropFromOutside}
                            handleDragStart={handleDragStart}
                            popup={true}
                            dragFromOutsideItem={dragFromOutsideItem}
                            onDragStart={console.log}
                            onEventDrop={moveEvent}
                            resizable
                            onEventResize={resizeEvent}
                        />
                    </div>
                </div>
            </CalendarWrapper>
        </div>
    )
};

export default EventDateModel;