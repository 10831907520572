import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { List } from 'antd';
import styled from 'styled-components';
import { duotone, light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import NwDrawer from 'Components/Gui/NwDrawer';
import { NwButton } from 'Components/Gui/NwButton';
import { forceReloadContact, useArchiveContact, useUnarchiveContact } from 'Hooks/Contact/UseContact';
import { useStateValue } from 'Contexts/StateProvider';
import { removeCustomerLog, removeModelLog } from 'Libs/NwLogs';
import { UserContext } from 'Contexts/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { forceReloadModels  } from 'Hooks/Contact/UseContactsList'; //forceReloadModelsWall


const ActionsDrawerWrapper = styled.div`
    .stacked-icons {
        font-size: 2rem;
        cursor: pointer;
        svg {
            &.base-icon {
                right: .5rem;
                top: .5rem;
                bottom: auto;
                left: auto;
            }
            &.stacked-icon {
                font-size: 1.25rem;
                top: 0;
                right: 0;
                bottom: auto;
                left: auto;
                --fa-secondary-opacity: 1;
                --fa-primary-opacity: 1;
                --fa-secondary-color: ${props => props.theme.PrimaryColor};;
                --fa-primary-color: #fff;
            }
        }
    }
`

const ListItem = styled(List.Item)`
    cursor: pointer;
    align-items: flex-end !important;
    border-bottom: ${props => props.theme.DefaultBorder};

    .ant-list-item-meta-title {
        font-weight: bold;
        margin-bottom: 0;
    }
`;

const ContactDetailsActions = ({ contact, onClose, fromManagement, fromDrawer }) => {
    const [showActions, setShowActions] = useState(false);
    const { mutateAsync: onArchiveContact } = useArchiveContact();
    const { mutateAsync: onUnarchiveContact } = useUnarchiveContact();
    
    const navigate = useNavigate();

    const { currentUser } = useContext(UserContext);
    const { nwAgId } = currentUser.ActiveContext;

    const [{ areaContext }] = useStateValue();

    const handleShowContactActions = async () => {
        setShowActions(true);
    };

    const handleCloseContactActions = () => {
        if (onClose) {
            onClose();
        }
        setShowActions(false);
    };

    const isArchived = () => {
        if (fromManagement) {
            if (contact.NWAGS) {
                return contact.NWAGS[0].StorageBooking
            }
        }
        if (contact.NetworkAgencies && contact.NetworkAgencies.length > 0) {
            return contact.NetworkAgencies[0].StorageBooking
        }
        return false
    }

    const isModel = contact.ContactType === 'Model';
    const isInBooking = fromManagement ? (contact.ST === 4) : (contact.SubType === 4);

    const handleArchiveContact = async () => {
        try {
            await onArchiveContact({ contactId: contact.ID, networkAgencyId: nwAgId, contactType: contact.ContactType, area: areaContext.area });
            if (contact.ContactType === 'Model') {
                removeModelLog(contact.ID, areaContext.area);
            } else {
                removeCustomerLog(contact.ID, areaContext.area);
            }
            if (!onClose) {
                if (!fromDrawer) {
                    if (areaContext.area === 'management') {
                        navigate(contact.ContactType === 'Model' ? '/management-models' : '/management-customers');
                    } else {
                        navigate(contact.ContactType === 'Model' ? '/models' : '/customers');
                    }
                } else {
                    handleCloseContactActions();
                }
            } else {
                handleCloseContactActions()
            }
        } catch (error) {
            console.log('ant : Store Contact Error => ', error);
        }
    };

    const handleUnarchiveContact = async () => {
        try {
            await onUnarchiveContact({ contactId: contact.ID, networkAgencyId: nwAgId, contactType: contact.ContactType, area: areaContext.area });
        } catch (error) {
            console.log('ant : Unstore Contact Error => ', error);
        }
    }

    const handleMoveToBooking = async () => {
        await Axios.put("/models/setsubtype", {ModelID: contact.ID, SubType: "Booking"})
        forceReloadModels()
        //forceReloadModelsWall()
        forceReloadContact(contact.ID)
    }

    const handleMoveToScouting = async () => {
        await Axios.put("/models/setsubtype", {ModelID: contact.ID, SubType: "Scouting"})
        forceReloadModels()
        //forceReloadModelsWall()
        forceReloadContact(contact.ID)
    }

    return (
        <>
            <NwIcon icon={light("gear")} size='lg' pointer onClick={handleShowContactActions} />
            {showActions &&
                <NwDrawer
                    title={'Actions'}
                    onClose={handleCloseContactActions}>
                    <ActionsDrawerWrapper>
                        {isArchived()
                            ?
                            <ListItem
                                actions={[
                                    <NwButton
                                        primary
                                        label={'unarchive'}
                                        onClick={handleUnarchiveContact} />
                                ]}
                                className="ant-list-item-top">
                                <List.Item.Meta
                                    avatar={
                                        <span className="stacked-icons fa-layers fa-fw fa-lg">
                                            <FontAwesomeIcon className="base-icon" icon={light('address-book')} />
                                            <FontAwesomeIcon className="stacked-icon" icon={duotone('circle-arrow-up')} />
                                        </span>
                                    }
                                    title="Remove from Archive"
                                    description={`Set this model active in the booking again`} />
                            </ListItem>
                            :
                            <ListItem
                                actions={[
                                    <NwButton
                                        danger
                                        label={'archive'}
                                        onClick={handleArchiveContact} />
                                ]}
                                className="ant-list-item-top">
                                <List.Item.Meta
                                    avatar={
                                        <NwIcon size='3x' icon={light('box-archive')} />
                                    }
                                    title={`Archive ${contact.ContactType}`}
                                    description={`You will be able to unarchive this ${contact.ContactType} in the management section`} />
                            </ListItem>
                        }
                        {isModel &&
                            (isInBooking
                                ?
                                <ListItem
                                    actions={[
                                        <NwButton
                                            primary
                                            label={'move to scouting'}
                                            onClick={handleMoveToScouting} />
                                    ]}
                                    className="ant-list-item-top">
                                    <List.Item.Meta
                                        avatar={
                                            <NwIcon size='3x' icon={solid('radar')} />
                                        }
                                        title="Set as Scouting Model"
                                        description={``} />
                                </ListItem>
                                :
                                <ListItem
                                    actions={[
                                        <NwButton
                                            primary
                                            label={'move to booking'}
                                            onClick={handleMoveToBooking} />
                                    ]}
                                    className="ant-list-item-top">
                                    <List.Item.Meta
                                        avatar={
                                            <NwIcon size='3x' icon={light('calendar-check')} />
                                        }
                                        title={`Set as Booking Model`}
                                        description={``} />
                                </ListItem>
                            )
                        }
                    </ActionsDrawerWrapper>
                </NwDrawer>
            }
        </>
    );
};

export default ContactDetailsActions;