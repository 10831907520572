import React from "react";
import _ from "lodash";
import { Select, Input, Rate, InputNumber } from "antd";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";
import NwIcon, { NwBoolIcon } from "Components/Gui/NwIcon";
import StyleVariables from 'Components/Gui/StyleVariables';
import { StyledRateContainer } from 'Components/Gui/NwForm/Rating';

const Option = Select.Option;
const { TextArea } = Input;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
`;

const RatingContainer = styled(StyledRateContainer)`
    display: flex;
    align-items: center;

    .close {
        font-size: 1.3rem;
        margin-left: 8px;
    }
`;

function createFeatureItemForm(item, value, globalConversions, setFieldValue, usermetrics, onUpdateFeature, disabled, setUpdatedFieldIDs, setCurrentUpdatingFieldID, gender) {
    let component;

    const handleUpdateFormItem = async (fieldID, value) => {
        await setFieldValue(fieldID, value);
        await setUpdatedFieldIDs(fieldID);
        await setCurrentUpdatingFieldID(item.ID);
        onUpdateFeature();
    };

    switch (item.Feature.TypeName) {
        case "Conversion":
            const options = [];
            for (const conversionValue of item.Feature.conversion.ConversionValues) {
                if (!options.find(option => option.Key === conversionValue.Key)) {
                    options.push({
                        Key: conversionValue.Key,
                        Metrics: item.Feature.conversion.ConversionValues.filter(value => value.Key === conversionValue.Key).map(conversionValue => {
                            return {
                                ...conversionValue,
                                metric: conversionValue.Metric,
                                value: conversionValue.Value
                            }
                        })
                    })
                }
            }

            component = (
                <Select
                    autoComplete='off'
                    showSearch
                    name={item.Feature.Name}
                    id={item.ID}
                    style={{ width: "100%" }}
                    value={value}
                    disabled={disabled}
                    onChange={val => handleUpdateFormItem(item.ID, val)}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    size="large"
                >
                    <Option
                        key={`0`}
                        value=""
                    >
                        (x) no value
                    </Option>
                    {options.map((option, index) => {
                        return (
                            <Option
                                key={`${option.Key}${index}`}
                                value={String(option.Key)}
                            >
                                {getDisplayValues(option.Metrics, item.Feature.conversion.Name, false, usermetrics, " • ", gender)}
                            </Option>
                        );
                    })}
                </Select>
            );
            break;
        case 'Choice':
            component = (
                <Select
                    autoComplete='off'
                    showSearch
                    name={item.Feature.Name}
                    id={item.ID}
                    style={{ width: "100%" }}
                    value={value}
                    disabled={disabled}
                    onChange={val => handleUpdateFormItem(item.ID, val)}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    size="large"
                >
                    <Option
                        key={`0`}
                        value=""
                    >
                        (x) no value
                    </Option>
                    {item.Feature.Choices.map((choice, i) => {
                        return (
                            <Option
                                key={`${item.Feature.Name}${choice.id}${i}`}
                                value={String(choice.ID)}
                            >
                                {choice.Value}
                            </Option>
                        );
                    })}
                </Select>
            );
            break;
        case "Multichoice":
            component = (
                <Select
                    autoComplete='off'
                    showSearch
                    mode="multiple"
                    name={item.Feature.Name}
                    value={value ? value.split(",") : []}
                    id={item.ID}
                    disabled={disabled}
                    style={{ width: "100%" }}
                    onChange={val => {
                        handleUpdateFormItem(item.ID, val.join(","));
                    }}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    size="large"
                >
                    {item.Feature.Choices.map(el => (
                        <Option key={el.ID} value={String(el.ID)}>
                            {el.Value}
                        </Option>
                    ))}
                </Select>
            );
            break;
        case 'BigText':
            component = (
                <InputContainer>
                    <TextArea
                        rows={4}
                        autoComplete='off'
                        name={item.Feature.Name}
                        id={item.ID}
                        autoSize={true}
                        value={value}
                        disabled={disabled}
                        onBlur={() => {
                            handleUpdateFormItem(item.ID, value);
                        }}
                        onChange={e => {
                            setFieldValue(item.ID, e.target.value);
                        }}
                        type="text"
                        size="large"
                    />
                </InputContainer>
            );
            break;
        case 'Text':
            component = (
                <InputContainer>
                    <Input
                        autoComplete='off'
                        name={item.Feature.Name}
                        id={item.ID}
                        value={value}
                        disabled={disabled}
                        onBlur={() => {
                            handleUpdateFormItem(item.ID, value);
                        }}
                        onChange={e => {
                            setFieldValue(item.ID, e.target.value);
                        }}
                        type="text"
                        size="large"
                    />
                </InputContainer>
            );
            break;
        case "Boolean":
            component = (
                <RadioButtonGroup
                    name={item.Feature.Name}
                    value={value}
                    disabled={disabled}
                    id={item.ID}
                    size="large"
                    onChange={value => {
                        handleUpdateFormItem(item.ID, value);
                    }}
                    options={[
                        { label: <NwBoolIcon />, value: 'true' },
                        { label: <NwBoolIcon value={false} />, value: 'false' },
                        { label: <NwIcon icon={light("times")} />, value: '' }
                    ]} />
            );
            break;
        case "Rating":
            const ratingColor = {
                mainColor: StyleVariables.PrimaryColor,
                lightColor: StyleVariables.PrimaryColorLight
            }
            component = (
                <RatingContainer ratingColor={ratingColor}>
                    <Rate
                        name={item.Feature.Name}
                        disabled={disabled}
                        style={{ fontSize: "20px" }}
                        value={value}
                        id={item.ID}
                        onChange={value => {
                            handleUpdateFormItem(item.ID, value);
                        }}
                    />
                    <NwIcon className='close' icon={light('times')} pointer onClick={() => handleUpdateFormItem(item.ID, null)} />
                </RatingContainer>
            )
            break;
        case "Numeric":
            component = (
                <InputNumber
                    value={value}
                    size="large"
                    step="0.1" 
                    onBlur={() => {
                        handleUpdateFormItem(item.ID, value);
                    }}
                    onChange={e => {
                        setFieldValue(item.ID, e);
                    }}
                    
                    // onStep={value => {
                    //     handleUpdateFormItem(item.ID, value);
                    // }} 
                />
            )
            break;
        default:
            break;
    }
    return component;
}

// function translateGender(genderEnum) {
//   return genderEnum === 2 ? "M" : "F";
// }

function getDisplayValues(
    displayValues,
    conversionname,
    firstonly = false, // false: visualizza tutte le metriche
    metricpreferences,
    separator,
    gender
) {
    //TODO: decidere come disporre i valori nel caso di visualizzazione o nel caso di modifica (al momento richiama la stessa funzione)
    if (displayValues.length > 0) {
        if (conversionname) {
            const confmetric = metricpreferences;
            const sep = separator || "•";
            const ret = [];
            const mymetric = _.find(confmetric.metrics, { name: conversionname });
            if (mymetric) {
                if (firstonly) {
                    let firstmetric = mymetric.metrics[0];
                    let displayvaluemetric = _.find(displayValues, {
                        metric: firstmetric
                    });
                    return displayvaluemetric.value;
                } else {
                    if (mymetric.metrics.length > 0) {
                        // console
                        _.each(mymetric.metrics, function (item) {
                            if (!wrongGenderSpecificMetric(item, gender)) {
                                let displayvaluemetric = _.find(displayValues, {
                                    metric: item
                                });
                                if (displayvaluemetric) {
                                    ret.push(displayvaluemetric.value + " " + item);
                                } else {
                                    return rawValues(displayValues);
                                }
                            }
                        });
                    }
                }
            } else {
                // No mymetric
                return rawValues(displayValues);
            }
            return ret.join(sep);
        } else {
            // No conversionname
            return displayValues[0].value;
        }
    } else {
        return "";
    }
}

function getSortedValues(
    displayValues,
    conversionname,
    metricpreferences
) {
    if (displayValues.length > 0) {
        if (conversionname) {
            const confmetric = metricpreferences;
            const ret = [];
            const mymetric = _.find(confmetric.metrics, { name: conversionname });
            if (mymetric) {
                if (mymetric.metrics.length > 0) {
                    // console
                    _.each(mymetric.metrics, function (item) {
                        let displayvaluemetric = _.find(displayValues, {
                            metric: item
                        });
                        if (displayvaluemetric) {
                            ret.push(displayvaluemetric);
                        } else {
                            return displayValues;
                        }
                    });
                }
            } else {
                return displayValues;
            }
            return ret;
        } else {
            return displayValues;
        }
    } else {
        return "";
    }
}

function rawValues(displayValues) {
    var ret = "";
    displayValues.forEach(function (dv, index) {
        ret += dv.value + " - ";
    });
    const lastDash = ret.lastIndexOf(" - ");
    return (ret = "" ? "" : ret.substring(0, lastDash));
}

const groupConversionValues = conversionValues => {
    let groupedConversionValues = [];
    for (const conversionValue of conversionValues) {
        if (!groupedConversionValues.find(item => item.Key === conversionValue.Key)) {
            groupedConversionValues.push({
                Key: conversionValue.Key,
                Metrics: conversionValues.filter(item => item.Key === conversionValue.Key)
            })
        }
    }
    return groupedConversionValues;
};

const wrongGenderSpecificMetric = (metricName, gender) => {
    if (!gender) {
        return false
    }
    if (metricName === "USW" && gender === 'Male') {
            return true
    }
    if (metricName === "USM" && gender === 'Female') {
            return true
    }
    return false
}

export { createFeatureItemForm, getDisplayValues, getSortedValues, groupConversionValues, wrongGenderSpecificMetric };
