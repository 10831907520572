import React, { useContext, useMemo } from "react";
import { Field } from "formik";
import styled from "styled-components";
import { getMoment } from "Libs/NwMoment";

import { UserContext } from "Contexts/UserContext";

// import { NWFilterPeriodInput, NWEmptyDateInput, NWFilterDateInput, NWSwitch, NWBookerSelector, NWGenderSelector, NWInputNumber, NWInputTag, NWSelectCountryMulti, NWBooleanSelector, NWDepartmentSelector, NWFeaturesRangeSlider, NWNumericRangeSelector, NWFeaturesMultichoice, NWFeaturesChoice, NWFeaturesTrans, NWFeaturesBool, NWCitySelectorMulti, NWInput, NWRatingSelector, NWContactDropdown, NWFilterYesNoAllSelector, NWInputTransaction, NWCurrencyRange, NWCategorySelect, NWTransactionFilter, NWDatePicker, NWStatementContactSelector, NWTransactionStatusSelector, NWFamilySelector, NWLabelSelector, NWTaxSelector, NWVatSelector, NWStatusSelector, NWPackageDateTypeSelector, NWModelPlayListSelector, NWDocumentFilterSelector, NWFilterFollowUpsOnLabels, NWFilterEventsOnSubTypes, NWFilterTravelsOnTransportTypes, NWFilterEventsOnSubTypesAndOptionNumber, NWAgencyTypeSelector, NWInTownSelector, } from "Components/Gui/NWForm/NwFormItems";

import FilterPeriodInput from "../../Filters/FilterPeriodInput";
import FilterDateInput from "../../Filters/FilterDateInput";
import BookerSelector from "Components/BookerSelector/BookerSelector";
import { AgencyTypeSelector } from "Components/Gui/NwForm/AgencyTypeSelector";
import ContactDropdown from "Components/ContactDropdown/ContactDropdown";
import FilterYesNoAllSelector from "Components/Gui/NwForm/FilterTypeSelector";
import InputTag from "Components/Gui/NwForm/InputTag";
import BooleanSelector from "Components/Gui/NwForm/BooleanSelector";
import { SelectCountryMulti } from "Components/Gui/NwForm/SelectCountryMulti";
import CitySelectorMulti from "Components/CitySelectorMulti/CitySelectorMulti";
import DepartmentSelector from "Components/Gui/DepartmentSelector";
import { GenderSelector } from "Components/Gui/NwForm/GenderSelector";
import { Input, InputNumber } from "antd";
import ModelPlayListSelector from "Components/Gui/NwForm/ModelPlayListSelector";
import DocumentFilterSelector from "Components/Gui/NwForm/DocumentFilterSelector";
import InTownSelector from "Components/Gui/NwForm/InTownSelector";
import { useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/SelectorContext";
import _ from "lodash";



const ContactsFilterItem = ({ filter }) => {

    const { selection } = useContactsSelector()
    const cs_dispatch = useContactsSelectorDispatch()
    const { filters } = selection

    let filterValue = filter.defaultval
    const workingFilter = filters.working.find(f => f.name === filter.name)
    if (workingFilter) {
        filterValue = workingFilter.value
    }

    const onChange = (val) => {
        let newWorkingFilters = _.clone(filters.working) //[...filters.working]
        const filterIndex = newWorkingFilters.findIndex(f => f.name === filter.name)
        
        if (val) {
            if (Array.isArray(val)) {
                if (val.length > 0) {
                    if (filterIndex === -1) {
                        newWorkingFilters.push({ name: filter.name, value: val, source: 'filter'})
                    } else {
                        newWorkingFilters[filterIndex].value = val
                    }
                } else {
                    if (filterIndex !== -1) {
                        newWorkingFilters.splice(filterIndex, 1)
                    }
                }
            } else {
                if (filterIndex === -1) {
                    newWorkingFilters.push({ name: filter.name, value: val, source: 'filter' })
                } else {
                    newWorkingFilters[filterIndex].value = val
                }
            }
        } else {
            if (typeof val == "boolean" && val === false) {
                if (filterIndex === -1) {
                    newWorkingFilters.push({ name: filter.name, value: false, source: 'filter' })
                } else {
                    newWorkingFilters[filterIndex].value = false
                }
            } else {
                if (filterIndex !== -1) {
                    newWorkingFilters.splice(filterIndex, 1)
                }
            }
        }
        //updateWorkingFilters(newWorkingFilters)
        cs_dispatch({ type: 'ChangeFilters', filters: newWorkingFilters })
    }
    return (
        <>
            {filter.type === "period" &&
                <FilterPeriodInput value={filterValue} allowClear onChange={onChange} />
            }
            {filter.type === "booker-selector" &&
                <BookerSelector value={filterValue} onChange={onChange} />
            }
            {filter.type === "agency-type-selector" &&
                <AgencyTypeSelector value={filterValue} onChange={onChange} />
            }
            {filter.type === "contact-selector" &&
                <ContactDropdown value={filterValue} onChange={onChange} />
            }
            {filter.type === "agency-selector" &&
                <ContactDropdown value={filterValue} activeTypes={['age']} onChange={onChange} />
            }
            {filter.type === "filter-yes-no-all" &&
                <FilterYesNoAllSelector value={filterValue} allowClear onChange={onChange} />
            }
            {filter.type === "models-tag" &&
                <InputTag value={filterValue} contactType="Model" onChange={onChange} />
            }
            {filter.type === "customers-tag" &&
                <InputTag value={filterValue} contactType="Customer" onChange={onChange} />
            }
            {filter.type === "boolean" &&
                <BooleanSelector value={filterValue} onChange={onChange} />
            }
            {filter.type === "countries-selector" &&
                <SelectCountryMulti value={filterValue} onChange={onChange} />
            }
            {filter.type === "cities-selector" &&
                <CitySelectorMulti value={filterValue} onChange={onChange} />
            }
            {filter.type === "departments-selector" &&
                <DepartmentSelector value={filterValue} allowClear onChange={onChange} />
            }
            {filter.type === "date" &&
                <FilterDateInput value={filterValue} allowClear onChange={onChange} />
            }
            {filter.type === "gender" &&
                <GenderSelector value={filterValue} emptyValue onChange={onChange} />
            }
            {filter.type === "intown-selector" &&
                <InTownSelector asSegmented value={filterValue} emptyValue onChange={onChange} />
            }
            {filter.type === "model-playlist-selector" &&
                <ModelPlayListSelector value={filterValue} onChange={onChange} />
            }
            {filter.type === "document-filter" &&
                <DocumentFilterSelector value={filterValue} filterType="document" emptyValue onChange={onChange} />
            }
            {filter.type === "playlist-filter" &&
                <DocumentFilterSelector value={filterValue} filterType="playlist" emptyValue onChange={onChange} />
            }
            {filter.type === "published-filter" &&
                <DocumentFilterSelector value={filterValue} filterType="published" emptyValue onChange={onChange} />
            }
            {filter.type === "number" &&
                <InputNumber value={filterValue} onChange={onChange} />
            }
            {filter.type === "" &&
                <Input value={filterValue} type="text" onChange={onChange} />
            }
        </>
    );
};

export default ContactsFilterItem;