import React, { useState, useMemo, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from "react-router";
import { List, Card, notification, Modal } from 'antd';
import _ from "lodash";
import Axios from 'axios';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwCard, NwCardContent } from 'Components/Gui/NwCard/NwCard';
import EmptyState from 'Components/Gui/EmptyState';
import { onUpdatePlaylists, useContactPlaylists } from "Hooks/Contact/UseContactPlaylists"
import FlexContainer from 'Components/Gui/FlexContainer';
import CustomSwitch from 'Components/Gui/CustomSwitch';
import NwIcon from 'Components/Gui/NwIcon';
import { UserContext } from 'Contexts/UserContext';
import { useWebsites } from "Hooks/Websites/UseWebsites";
import { Loading } from 'Components/Gui/Loading';
import ModelBookPublishDrawer from "Components/ContactDetails/ModelDetails/CommonComponents/BookPublishing/ModelBookPublishDrawer";
//import PlayListInfoDrawer from 'Components/MediaDrawer/PlayListInfoDrawer';
import PlayListPreviewDrawer from 'Components/MediaDrawer/PlayListPreviewDrawer';
//import ModelMedia from 'Components/ModelMedia/ModelMedia';
//import { useStateValue } from 'Contexts/StateProvider';
import { forceReloadContact } from 'Hooks/Contact/UseContact';
import { useModelContext } from 'Contexts/ModelContext';
import BookItemMobile from 'Components/ContactDetails/ModelDetails/CommonComponents/BookItemMobile';
//import ModelContext from "Contexts/ModelContext_OLD";

//TODO: new playlist editor
//import PlaylistEditor from 'Components/ModelMedia/PlaylistEditor/PlaylistEditor';

const CardWrapper = styled.div`
    height: 100%;
    
    position: relative;
    .ant-card-body-content {
        padding: 0 !important;
        justify-content: ${props => !props.viewModelBooks && 'center !important'}
    }
    .ant-card-bordered {
        border: none !important;
    }
`;

const LockContainer = styled(FlexContainer)`
  min-height: 320px;
  padding: 16px 0;
  svg {
    font-size: ${props => props.$readOnly ? '80px' : '120px'};
  }
`;

const BookFilters = styled.div`
  border-bottom: ${props => props.theme.DefaultBorder};
  padding-bottom: 16px;
`;


const ModelDetailsBooksMobile = ({
    contactID,
    modelPlaylists,
}) => {

    const { activeSet, listType } = useModelContext();
    const navigate = useNavigate()
    const { data: fetchedPlaylists, isFetching: isFetchingPlaylists } = useContactPlaylists(contactID, modelPlaylists)
    const { data: websiteData } = useWebsites();
    const [bookPreview, setBookPreview] = useState(null);
    const [bookInfo, setBookInfo] = useState(null);
    const [isBookDrawer, setIsBookDrawer] = useState(false);
    const [isPublishDrawer, setIsPublishDrawer] = useState(false);
    const [dataforPublishDrawer, setDataforPublishDrawer] = useState(false);
    const [onlyMine, setOnlyMine] = useState(false);
    const { currentUser } = useContext(UserContext);
    const [playlists, setPlaylists] = useState(null);
    //const [editBookDrawer, setEditBookDrawer] = useState(null)
    //const [isBookTouched, setIsBookTouched] = useState(false)

    const viewModelBooks = true

    useEffect(() => {
        if (modelPlaylists) {
            setPlaylists([...modelPlaylists])
        } else {
            if (!isFetchingPlaylists) {
                setPlaylists([...fetchedPlaylists])
            }
        }
    }, [isFetchingPlaylists, modelPlaylists])

    const booksToRender = useMemo(() => {
        let books = [];
        if (playlists) {
            books = [...playlists].filter(book => (!onlyMine || book.UserCreated === currentUser.UserID));
            books = _.orderBy(books, ['FastPackage', 'IsPublished', 'CompCard', 'ID'], ['desc', 'desc', 'desc', 'desc'])
        }
        return books
    }, [playlists, onlyMine, currentUser]);

    const showBookPreview = (book) => {
        setBookPreview(book);
        setIsBookDrawer(true);
    }

    // const showBookInfo = (book) => {
    //     setBookInfo(book);
    //     setIsBookDrawer(true);
    // }

    const publishBook = (book) => {
        if (websiteData && websiteData.length > 0) {
            setDataforPublishDrawer(book)
            setIsPublishDrawer(true)
        } else {
            notification.warning({
                message: 'No Websites Configured',
                description: 'There are no websites configured in the management section. Sorry, this book can\'t be published',
                icon: <NwIcon icon={solid("triangle-exclamation")} />
            });
        }
    }

    // const onEditBook = book => {
    //     if (onSelectBook) {
    //         onSelectBook(book);
    //     }
    //     else
    //         setEditBookDrawer(book.ID)
    // }

    // const onDeleteBook = async book => {
    //     try {
    //         await Axios.delete(`/playlists/${book.ID}`);
    //         onUpdatePlaylists(contactID);
    //     } catch (error) {
    //         console.log("error on deleting book", error);
    //     }
    // }

    const onChangeFastPackageStatus = async (book, value) => {
        try {
            const data = { PlaylistID: book.ID, FastPackage: value }
            await Axios.post("/playlists/setFastPackage", data);
            forceReloadContact(contactID);
            onUpdatePlaylists(contactID);
        } catch (error) {
            console.log("error on set fast package on book", error);
        }
    }

    const onChangeCompCardStatus = async (book, value) => {
        try {
            const data = { PlaylistID: book.ID, CompCard: value }
            await Axios.post("/playlists/setCompCard", data);
            forceReloadContact(contactID);
            onUpdatePlaylists(contactID);
        } catch (error) {
            console.log("error on set comp card on book", error);
        }
    }

    // const goToBooksPage = () => {
    //     if (drawerMode) {
    //         onAddNewBook()
    //     } else {
    //         if (activeSet && listType) {
    //             navigate(`/model/${contactID}/newbook/${listType}/${activeSet}`)
    //         } else {
    //             navigate(`/model/${contactID}/newbook/`)
    //         }
    //     }
    // }

    // const onCloseEditBookDrawer = () => {
    //     setEditBookDrawer();
    // }

    // const goToMediaPage = () => {
    //     if (activeSet && listType) {
    //         navigate(`/model/${contactID}/media/${listType}/${activeSet}`);
    //     } else {
    //         navigate(`/model/${contactID}/media`);
    //     }
    // }

    return (
        playlists
            ?
            <>
                <CardWrapper viewModelBooks={viewModelBooks}>
                    <NwCard
                        title="Books"
                        counter={viewModelBooks ? playlists.length : null}
                        extra={
                            <CustomSwitch
                                checked={onlyMine}
                                onChange={setOnlyMine}
                                text='only mine'
                            />
                        }
                    >
                        {viewModelBooks
                            ?
                            (playlists.length > 0 ?
                                <NwCardContent padding="no">
                                    {(booksToRender && (booksToRender.length > 0)) ?
                                        booksToRender.map((book, index) => (
                                            <List
                                                itemLayout="horizontal"
                                                $mobileView
                                                dataSource={booksToRender}
                                                renderItem={book => {
                                                    return (
                                                        <BookItemMobile
                                                            //addedPlaylists={addedPlaylists}
                                                            book={book}
                                                            //onAddBook={onAddBook}
                                                            onChangeFastPackageStatus={onChangeFastPackageStatus}
                                                            onChangeCompCardStatus={onChangeCompCardStatus}
                                                            //onDeleteBook={onDeleteBook}
                                                            //onEditBook={onEditBook}
                                                            //onRemoveBook={onRemoveBook}
                                                            //packageEditView={packageEditView}
                                                            //readOnly={readOnly}
                                                            //showBookInfo={showBookInfo}
                                                            showBookPreview={showBookPreview}
                                                            //showCoverSelector={showCoverSelector}
                                                            //removeCover={removeCover}
                                                            showPublishBook={publishBook}
                                                            websiteData={websiteData ? websiteData : []}
                                                        />
                                                    )
                                                }}
                                            />
                                        ))
                                        :
                                        <EmptyState message="no books" />
                                    }
                                </NwCardContent>
                                : <EmptyState message="no books" />
                            )
                            :
                            <LockContainer justifyContent='center' alignItems='center'>
                                <NwIcon icon={light('lock-alt')} />
                            </LockContainer>
                        }
                    </NwCard>
                </CardWrapper>
                {isPublishDrawer && dataforPublishDrawer && websiteData &&
                    <ModelBookPublishDrawer
                        onClose={() => {
                            setIsPublishDrawer(false);
                            setDataforPublishDrawer();
                        }}
                        book={dataforPublishDrawer}
                        websiteData={websiteData}
                        books={booksToRender}
                    />
                }
                {isBookDrawer && bookPreview &&
                    <PlayListPreviewDrawer
                        playlist={bookPreview}
                        contactID={contactID}
                        onClose={() => {
                            setIsBookDrawer(false);
                            setBookPreview();
                        }}
                    />
                }
                {/* {isBookDrawer && bookInfo &&
                    <PlayListInfoDrawer
                        playlist={bookInfo}
                        contactID={contactID}
                        //onEditPlaylist={onEditBook}
                        onClose={() => {
                            setIsBookDrawer(false);
                            setBookInfo();
                        }}
                        onUpdatePlaylists={() => {
                            onUpdatePlaylists(contactID);
                        }}
                        onDeletePlaylists={onDeleteBook}
                        onPublishBook={publishBook}
                    />
                } */}
            </>
            :
            <Loading small paddingTop="2rem" textBlack />
    );
}

export default ModelDetailsBooksMobile;
