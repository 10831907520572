import React from "react";
import NwSelect from 'Components/Gui/NwForm/CustomSelect';
import useConfigurations from "Hooks/UseConfigurations";

const AccountCodeSelector = ({value, onChange, onBlur}) => {
    
    const configurations = useConfigurations()

    const accounts = [];
    if (configurations && configurations.COA) {
        const COAjs = JSON.parse(configurations.COA);
        if (COAjs.accounts && COAjs.accounts.length > 0) {
            accounts.push(...COAjs.accounts);
        }
    }

    
    const options = accounts.map(item => ({
        label: `${item.code} - ${item.description}`, value: item.code, key: item.code
    }))

    return (
        <NwSelect
            width="100%"
            mode="single"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            options={options}
            getPopupContainer={triggerNode => triggerNode.parentNode}
        />
    );
}
export default AccountCodeSelector;