import React, { useMemo } from "react";
import { Select } from "antd";
import { useGlobalFeatures } from 'Hooks/Features/UseFeatures';

const Option = Select.Option;

const FeaturesChoice = ({
    onChange,
    value,
    disabled,
    itemid,
}) => {

    const { data: globalFeatures } = useGlobalFeatures();
    const feature = globalFeatures.find(feature => feature.ID === itemid);

    const choices = useMemo(() => {
        let featurechoices = []
        if (feature) {
            featurechoices = feature.Choices.map(choice => {
                return {
                    ...choice,
                    value: choice.ID,
                    label: choice.Value
                }
            })
        }
        return featurechoices;
    }, [feature]);

    return (
        <Select
            autoComplete='off'
            mode="multiple"
            value={value}
            placeholder="Please select"
            onChange={onChange}
            disabled={disabled}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            style={{ width: "100%" }}>
            {choices.map(el => <Option key={el.value} value={el.value}>{el.label}</Option>)}
        </Select>
    );
};

export default FeaturesChoice;
