import React, { useContext } from "react";
import NwSelect from 'Components/Gui/NwForm/CustomSelect';
import { ListContext } from "Contexts/ListContext";

const BankAccountsSelector = ({value, onChange, onBlur}) => {
    const { cachedList } = useContext(ListContext);
    const options = cachedList.bankAccounts.items.map(item => ({
        label: item.BankName, value: item.ID, key: item.ID
    }))

    return (
        <NwSelect
            width="100%"
            mode="single"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            options={options}
            getPopupContainer={triggerNode => triggerNode.parentNode}
        />
    );
}
export default BankAccountsSelector;