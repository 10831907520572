import React from "react";

import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";

const BooleanSelector = ({ onChange, value, disabled, emptyValue }) => {
    const options = [
        { label: 'Yes', value: true, icon: light('thumbs-up') },
        { label: 'No', value: false, icon: light('thumbs-down')}
    ];
    // if (emptyValue) {
    //     options.unshift({ label: 'Any', value: null, icon: light('asterisk')});
    // }
    
    return (
        <RadioButtonGroup 
            value={value} 
            disabled={disabled}
            defaultValue={null}
            onChange={onChange} 
            options={options} />
    );
};

export default BooleanSelector;
