import React, { useContext, useState } from "react";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import styled from 'styled-components';
import { Popover, List } from 'antd';
import { ListContext } from "Contexts/ListContext";
import NwIcon from 'Components/Gui/NwIcon';
import Axios from "axios";
import MiniLoading from "Components/Gui/MiniLoading";

const Wrapper = styled.div`
    .ant-dropdown-menu-item-group-list {
        max-height: unset;
    }
`;

const Content = styled.div`
    padding: .25rem .75rem .5rem .75rem;
    min-width: 22rem;

    .ant-menu-item-group-title {
        padding: 6px 0;
    }

    .ant-list-item {
        padding: 6px 0;

        .ant-list-item-meta {
            align-items: center;
        }

        .ant-list-item-meta-title {
            margin-bottom: 0;
            text-transform: uppercase;
            font-size: .8rem;
            font-weight: normal;
        }
    }

    .loading-message {
        padding: 2rem;
        text-align: center;
    }
`;


const PrintInvoiceButton = ({
    invoiceID,
    invoiceType,
    placement
}) => {

    const getTemplatesByType = () => {
        let templateName = "Invoice"
        switch (invoiceType) {
            case "Model":
                templateName = "ModelInvoice"
                break;
            case "Agency":
                templateName = "AgencyInvoice"
                break;
            default:
                break;
        }
        return templates.items.filter(template => template.area === templateName)
    }

    const { templates } = useContext(ListContext).cachedList;
    const invoiceTemplates = getTemplatesByType();
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [showPopover, setShowPopover] = useState(false);

    const print = async (templateID, download) => {
        setLoadingPdf(true);
        Axios.get(`/invoices/print/?Template=${templateID}&ID=${invoiceID}`, {
            responseType: 'blob',
            timeout: 30000,
        }).then((blob) => {
            const url = URL.createObjectURL(blob);
            // Crea un elemento <a> temporaneo per scaricare o aprire il PDF in un nuovo tab
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            if (download) {
                link.download = 'invoice.pdf'; // Opzionale: specifica un nome di file per il download
            }
            // Simula il click sull'elemento <a> per avviare il download o l'apertura del PDF
            link.click();
            // Rimuovi l'URL oggetto quando il download o l'apertura è completato
            URL.revokeObjectURL(url);
            setLoadingPdf(false);
            setShowPopover(false);
        });
    }

    if (!templates || templates.items.length === 0) {
        return null;
    }

    return (
        <Wrapper>
            <Popover
                zIndex={9999}
                overlayClassName='folder-popover'
                placement={placement}
                trigger='click'
                //open={showPopover}
                content={
                    <Content>
                        {loadingPdf
                            ?
                            <div className="loading-message">
                                <MiniLoading size="lg" /> Generating PDF file, please wait...
                            </div>

                            :
                            <>
                                <div role="presentation" className="ant-menu-item-group-title" title="print book">SELECT AN INVOICE LAYOUT</div>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={invoiceTemplates}
                                    renderItem={item => (
                                        <List.Item
                                            actions={
                                                [
                                                    <a key="download" onClick={() => print(item.id, true)}>
                                                        <NwIcon tooltip="download file" icon={light('cloud-arrow-down')} />
                                                    </a>,
                                                    <a key="open" onClick={() => print(item.id, false)}>
                                                        <NwIcon tooltip="open in a new window" icon={light('arrow-up-right-from-square')} />
                                                    </a>
                                                ]
                                            }
                                        >
                                            <List.Item.Meta
                                                avatar={<NwIcon icon={light('file-pdf')} />}
                                                title={item.name}
                                                description=""
                                            />
                                        </List.Item>
                                    )}
                                />
                            </>
                        }
                    </Content>
                }
            >
                <NwIcon icon={light('file-pdf')} pointer size="2x" onClick={() => setShowPopover(true)} />
            </Popover>
        </Wrapper>
    )
};


export default PrintInvoiceButton;