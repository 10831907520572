import { useQuery, useMutation } from '@tanstack/react-query';
import Axios from 'axios';

import { forceReloadModels, forceReloadCustomers  } from "Hooks/Contact/UseContactsList"; //forceReloadModelsWall
import { queryClient } from 'QueryClientProvider';

const getContactByID = ID => (
    Axios.get(`/contacts/${ID}`)
)

const useContact = (ID) => {
    const intID = Number(ID)
    return useQuery(["contact", intID], 
        () => getContactByID(intID),
        {
            enabled: ID ? true : false,
            staleTime: 60000,
            cacheTime: 60000,
            retry: false,
        })
}

const forceReloadContact = (ID) => {
    const intID = Number(ID)
    queryClient.invalidateQueries(["contact", intID])
}

const name = (contact) => {
    const cn = contact.Surname ? `${contact.Name} ${contact.Surname}` : contact.Name
    return cn
}

const onCheckDuplicates = ({ type, data }) => (
    Axios.post(`${type === 1 ? '/models' : '/customers'}/CheckDuplicates`, data)
);

const useCheckDuplicates = () => {
    return useMutation(onCheckDuplicates, {
        onSuccess: (data, variables) => {
            // forceReloadContact(variables.data.ID);
        }
    })
};

const onUpdateContact = ({ type, ID, data }) => ( 
    Axios.put(`${type === 1 ? '/models' : '/customers'}/${ID}`, data)
);

const useUpdateContact = () => {
    return useMutation(onUpdateContact, {
        onSuccess: (data, variables) => {
            forceReloadContact(variables.ID)
            if (variables.type === 1) {
                forceReloadModels()
            } else {
                forceReloadCustomers()
            }
        }
    })
};

const onUpdateContactCustomFields = ({ contactId, data }) => (
    Axios.put(`/contacts/Customfields/${contactId}`, data)
);

const useUpdateContactCustomFields = () => {
    return useMutation(onUpdateContactCustomFields, {
        onSuccess: (data, variables) => {
            forceReloadContact(variables.contactId);
        }
    })
};

const onRemoveContactCover = ({ contactId }) => (
    Axios.delete(`/contacts/removeCover/${contactId}`)
);

const useRemoveContactCover = () => {
    return useMutation(onRemoveContactCover, {
        onSuccess: (data, variables) => {
            forceReloadContact(variables.contactId);
        }
    })
};

const onArchiveContact = ({ contactId, networkAgencyId }) => {
    return (Axios.post(`/contacts/store`, {
        ContactID: contactId,
        NetworkAgencyID: networkAgencyId
    }))
};

const useArchiveContact = () => {
    return useMutation(onArchiveContact, {
        onSuccess: (data, variables) => {
            forceReloadContact(variables.contactId);
            if (variables.contactType === 'Model') {
                forceReloadModels()
                //forceReloadModelsWall()
                //queryClient.setQueryData(['model-wall-ids', variables.area], null);
            } else { 
                forceReloadCustomers()
                queryClient.setQueryData(['customer-wall-ids', variables.area], null)
            }
        }
    })
};

const onUnarchiveContact = ({ contactId, networkAgencyId }) => (
    Axios.post(`/contacts/unstore`, {
        ContactID: contactId,
        NetworkAgencyID: networkAgencyId
    })
);

const useUnarchiveContact = () => {
    return useMutation(onUnarchiveContact, {
        onSuccess: (data, variables) => {
            forceReloadContact(variables.contactId);
            if (variables.contactType === 'Model') {
                forceReloadModels();
            } else { 
                forceReloadCustomers();
            }
        }
    })
}

export {
    useContact,
    forceReloadContact,
    //getSimpleContactByID,
    name,
    useCheckDuplicates,
    useUpdateContact,
    //useGetCalendarEvents,
    useUpdateContactCustomFields,
    useRemoveContactCover,
    useArchiveContact,
    useUnarchiveContact,
    onUpdateContactCustomFields,
    onCheckDuplicates,
    onUpdateContact
}
