import React from "react";

import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";

const EventTypeSelector = ({ onChange, value, disabled }) => {
    return (
        <RadioButtonGroup 
            value={value}
            disabled={disabled}
            defaultValue={true}
            onChange={onChange} 
            options={[
                { label: 'Casting', value: 'Casting' },
                { label: 'Job', value: 'Job'}
            ]} />
    );
};

export default EventTypeSelector;
