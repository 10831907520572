import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import {
    getThumbnailWidth,
    isLandscape,
    getVideoCover
} from "Libs/NwUtils";
import ModelBookEditorFooter from 'Components/ModelMedia/ModelMediaPanel/ModelMediaList/ModelBookEditorFooter';
import BookEditDrawer from 'Components/ModelMedia/ModelMediaPanel/ModelMediaList/BookEditDrawer';
import { useEditPlaylistDocuments, useChangeLayoutPlaylist } from "Hooks/Contact/UseContactPlaylists";
import { useEditPackagePlaylistDocuments, forceReloadPackagePlaylist  } from "Hooks/Packages/UsePackagePlaylist";
import { forceReloadPackage } from "Hooks/Packages/UsePackages";
import MediaGallery from "./MediaGallery";
import { forceReloadContactDocuments } from 'Hooks/Contact/UseContactMedia';

const MainMediaContent = styled.div`
    min-height: calc( 100vh - 180px );
    @media screen and (max-width: 1300px) {
        min-height: calc( 100vh - 210px );
    }
`;

const getRowHeight = size => {
    switch (size) {
        case 'small':
            return 160;
        case 'large':
            return 300;
        case 'xlarge':
            return 450;
        case 'medium':
        default:
            return 230;
    }
}

const MediaListContainer = ({
    clickedMedia,
    contactID,
    deleteErrors,
    isDrawer,
    isMediaSelectable,
    onBookTouched,
    onCancelBook,
    onClickThumbnail,
    onCloseBookEditor,
    onOpenBookList,
    onUpdateSelectedBook,
    packageBook,
    selectedBook,
    size,
    sortedMediaList,
}) => {
    const [playList, setPlayList] = useState([])
    const location = useLocation()
    const navigate = useNavigate()
    const [isBookDrawer, setIsBookDrawer] = useState(false)
    const [mainBook, setMainBook] = useState()
    const { mutateAsync: editPlaylistDocuments } = useEditPlaylistDocuments()
    const { mutateAsync: editPackagePlaylistDocuments } = useEditPackagePlaylistDocuments()
    const { mutateAsync: changeLayoutPlaylist } = useChangeLayoutPlaylist()
    const [isBookTouched, setBookTouched] = useState(false)
    const [documentList, setDocumentList] = useState([])
    const [layoutToUpdate, setLayoutToUpdate] = useState(false)
    const [savingBook, setSavingBook] = useState(false)
    const [hasEncoding, setHasEncoding] = useState(false)

    const setIsBookTouched = (val) => {
        setBookTouched(val)
        if (onBookTouched) {
            onBookTouched(val)
        }
    }

    const timerRef = useRef(null);

    useEffect(() => {
        if (hasEncoding) {
            timerRef.current = setInterval(() => {
                forceReloadContactDocuments(contactID);
            }, 6000);
        } else {
            clearInterval(timerRef.current);
        }
    }, [hasEncoding]);

    useEffect(() => {
        return () => clearInterval(timerRef.current);
    }, []);

    useEffect(() => {
        setMainBook(selectedBook)
        if (selectedBook && mainBook && selectedBook.ID !== mainBook.ID) {
            setIsBookTouched(false);
        }
    }, [selectedBook])

    useEffect(() => {
        setDocumentList(convertObjects(sortedMediaList));
    }, [sortedMediaList, size, selectedBook, mainBook, playList, deleteErrors])

    useEffect(() => {
        if (selectedBook && selectedBook.DocumentPlaylists && (!mainBook || selectedBook.ID !== mainBook.ID)) {
            initializeBook()
        }
    }, [selectedBook, mainBook])

    const convertObject = item => {
        const findMedia = playList.find(media => media.ID === item.ID)
        const deleteError = deleteErrors.find(error => error.ID === item.ID)
        const deleteErrorString = deleteError ? deleteError.Error : ''
        if (item.Type === 'Photo') {
            return {
                src: item.Standard.Url, //GetUrlPicture(item),
                thumbnail: item.Thumbnail.Url, //GetUrlPicture(item, "t"),
                thumbnailWidth: getThumbnailWidth(item, getRowHeight(size)),
                thumbnailHeight: getRowHeight(size),
                tags: [],
                caption: item.Title || '',
                ID: item.ID,
                disabled: findMedia ? true : false,
                selected: item.isSelected ? true : false,
                typeName: item.Type,
                notes: '',
                removable: true,
                deleteError: deleteErrorString
            }
        } else if (item.Type === 'Video') {
            if (!item.Thumbnail) {
                setHasEncoding(true)
                return {
                    src: null,
                    thumbnail: null,
                    thumbnailWidth: getRowHeight(size) * 1.5,
                    thumbnailHeight: getRowHeight(size),
                    tags: [],
                    caption: 'ENCODING VIDEO',
                    ID: item.ID,
                    disabled: true,
                    selected: false,
                    typeName: item.Type,
                    notes: '',
                    removable: false,
                    deleteError: deleteErrorString
                }
            }
            return {
                src: getVideoCover(item),
                thumbnail: getVideoCover(item),
                thumbnailWidth: getRowHeight(size) * 1.5,
                thumbnailHeight: getRowHeight(size),
                tags: [],
                caption: item.Title || '',
                ID: item.ID,
                disabled: findMedia ? true : false,
                selected: item.isSelected ? true : false,
                typeName: item.Type,
                notes: '',
                removable: true,
                deleteError: deleteErrorString
            }
        }
    };

    const convertObjects = documentList => {
        setHasEncoding(false)
        return documentList.map(item => {
            return convertObject(item);
        });
    };

    const isOdd = (n) => (Math.abs(n % 2) === 1);

    const initializeBook = () => {
        const pictures = []
        setHasEncoding(false)
        if (selectedBook.Layout === "Pages") {
            let lastOrder = 0
            for (let i = 0; i < selectedBook.DocumentPlaylists.length; i++) {
                const mypic = selectedBook.DocumentPlaylists[i]
                if (i === 0 && (mypic.DocumentOrder === 1)) {
                    pictures.push(getPlaceholderPictureForBook())
                } else {
                    if (mypic.DocumentOrder > (lastOrder + 1)) {
                        const nplaceholders = mypic.DocumentOrder - (lastOrder + 1)
                        for (let p = 0; p < nplaceholders; p++) {
                            pictures.push(getPlaceholderPictureForBook())
                        }
                    }
                }
                pictures.push({
                    ...convertObject(mypic),
                    Guid: mypic.Guid,
                    PrivateGuid: mypic.Guid,
                    disabled: false,
                    order: mypic.DocumentOrder,
                    notes: mypic.DocumentPlaylistNotes,
                    removable: true
                })
                lastOrder = mypic.DocumentOrder
            }
            setPageNumber(pictures)
        } else {
            const mappedDocuments = selectedBook.DocumentPlaylists.map(document => {
                return {
                    ...convertObject(document),
                    //Guid: document.Guid,
                    //PrivateGuid: document.Guid,
                    disabled: false,
                    order: document.DocumentOrder,
                    notes: document.DocumentPlaylistNotes,
                    removable: true,
                    page: null
                };
            });
            pictures.push(...mappedDocuments)
        }
        setPlayList(pictures);
        return true
    }

    const removePlaceholders = (pictures) => {
        let updatedPictures = [...pictures]
        return (updatedPictures.filter(p => p.ID))
    }

    const setAsSequence = () => {
        let pictures = [...removePlaceholders(playList)]
        for (const p of pictures) {
            p.page = null   
        }
        return pictures
    }

    const onEditDoublePages = (pictures) => {
        let updatedPictures = []
        if (!pictures) {
            pictures = (playList && playList.length) ? [...playList] : []
        }
        if (pictures.length) {
            loopForPlaceholders(pictures)
            updatedPictures = [...markDoublePlaceholdersAsRemovable(pictures)]
        }
        return updatedPictures
    }

    const setPageNumber = (pictures) => {
        let pages = 0
        for (let i = 0; i < pictures.length; i++) {
            const mypic = pictures[i]
            pages += (isLandscape(mypic) ? 2 : 1)
            mypic.page = pages
        }
        return true
    }

    const loopForPlaceholders = (pictures) => {
        setPageNumber(pictures)
        const changed = addAutoPlaceholders(pictures)
        if (changed) {
            loopForPlaceholders(pictures)
        } else {
            return true
        }
    }

    const addAutoPlaceholders = (pictures) => {
        const irregularIndex = pictures.findIndex((pic) => {
            return isLandscape(pic) && isOdd(pic.page)
        })
        if (irregularIndex >= 0) {
            if (irregularIndex > 0 && !pictures[irregularIndex - 1].ID) {
                pictures.splice(irregularIndex - 1, 1)
            } else {
                pictures.splice(irregularIndex, 0, getPlaceholderPictureForBook())
            }
            return true
        }
        return false
    }

    const markDoublePlaceholdersAsRemovable = (pictures) => {
        let updatedPictures = [...pictures]
        for (let i = 0; i < updatedPictures.length; i++) {
            const mypic = updatedPictures[i]
            if (!mypic.ID) {
                if ((i < updatedPictures.length - 1) && isOdd(mypic.page) && !updatedPictures[i + 1].ID) {
                    mypic.removable = true
                    updatedPictures[i + 1].removable = true
                    i++
                } else {
                    mypic.removable = false
                }
            }
        }
        return updatedPictures
    }

    const fixDoublePlaceholders = (pictures) => {
        let updatedPictures = [...pictures]
        const doubleplaceholders = [];
        //verify if there are 2 subsequent placeholders
        for (let i = 0; i < updatedPictures.length; i++) {
            const mypic = updatedPictures[i]
            if (!mypic.ID) {
                if ((i < updatedPictures.length - 1) && isOdd(mypic.page) && !updatedPictures[i + 1].ID) {
                    //not last picture, page is odd and next picture is not a placeholder
                    //to remove, otherwise it's a full blank page
                    doubleplaceholders.push(i)
                }
            }
        }
        //remove subsequent placeholders
        doubleplaceholders.forEach((item) => {
            updatedPictures.splice(item, 2)
        })
        return updatedPictures
    }

    const getPlaceholderPictureForBook = () => {
        return {
            ID: 0,
            caption: "Placeholder",
            disabled: false,
            index: null,
            marginLeft: 0,
            page: null,
            order: null,
            src: null,
            tags: [],
            thumbnail: null,
            thumbnailHeight: 180,
            thumbnailWidth: 120,
            removable: false
        }
    }

    const addMediaToBook = (sourceposition, position) => {
        let updatedPlaylist = [...playList];
        if (position === 0 || position) {
            updatedPlaylist.splice(position, 0, documentList[sourceposition])
        } else {
            if (mainBook) {
                updatedPlaylist.push(documentList[sourceposition]);
            }
        }
        if (selectedBook.Layout === "Pages") {
            updatedPlaylist = [...onEditDoublePages(updatedPlaylist)]
        }
        setIsBookTouched(true);
        setPlayList(updatedPlaylist)
    }

    const reorderBook = (sourceIndex, destinationIndex) => {
        let updatedPlaylist = [...playList]
        const [removed] = updatedPlaylist.splice(sourceIndex, 1)
        updatedPlaylist.splice(destinationIndex, 0, removed)
        if (selectedBook.Layout === "Pages") {
            updatedPlaylist = [...onEditDoublePages(updatedPlaylist)]
        }
        setIsBookTouched(true)
        setPlayList(updatedPlaylist)
    }

    const removeMediaFromBook = position => {
        let updatedPlaylist = [...playList]
        updatedPlaylist.splice(position, 1)
        if (selectedBook.Layout === "Pages") {
            updatedPlaylist = [...onEditDoublePages(updatedPlaylist)]
        }
        setIsBookTouched(true)
        setPlayList(updatedPlaylist)
    }

    const addPlaceholder = () => {
        if (selectedBook.Layout === "Pages") {
            let updatedPlaylist = [...playList];
            updatedPlaylist.push(getPlaceholderPictureForBook(updatedPlaylist.length))
            updatedPlaylist = [...onEditDoublePages(updatedPlaylist)]
            setIsBookTouched(true)
            setPlayList(updatedPlaylist)
        }
    }

    const getInfoFromDraggableID = (code) => {
        const info = {
            type: "",
            pictureID: null,
            index: null,
            isPlaceholder: false
        }
        if (code) {
            const arrcode = code.split('-')
            info.type = arrcode[0]
            info.pictureID = Number(arrcode[1])
            info.index = Number(arrcode[2])
            info.isPlaceholder = (Number(arrcode[1]) === 0)
        }
        return info
    }

    const replacePicture = (sourceposition, draggableinfo, picture) => {
        let updatedPlaylist = [...playList];
        if (picture) {
            updatedPlaylist.splice(draggableinfo.index, 1, picture)
        } else {
            let oldposition = sourceposition
            let newposition = draggableinfo.index
            const oldMedia = updatedPlaylist[oldposition];
            updatedPlaylist[oldposition] = updatedPlaylist[newposition];
            updatedPlaylist[newposition] = oldMedia;
        }
        if (selectedBook.Layout === "Pages") {
            updatedPlaylist = [...onEditDoublePages(updatedPlaylist)]
        }
        setIsBookTouched(true);
        setPlayList(updatedPlaylist);
    }

    const onDragEnd = (result) => {
        const { combine, source, destination } = result;
        if (combine) {
            const draggableinfo = getInfoFromDraggableID(combine.draggableId)
            if (source.droppableId === 'ModelPicturesContainer') {
                replacePicture(source.index, draggableinfo, documentList[source.index]);
            }
            else {
                replacePicture(source.index, draggableinfo);
            }
            return;
        }

        if (!destination) {
            return;
        }
        if (source.droppableId === 'ModelPicturesContainer') {
            if (destination.droppableId === 'BookPageContainer') {
                addMediaToBook(source.index, destination.index);
            }
        }
        if (source.droppableId === 'BookPageContainer') {
            switch (destination.droppableId) {
                case 'BookPageContainer':
                    reorderBook(source.index, destination.index);
                    break;
                case 'BookTrashBin':
                    removeMediaFromBook(source.index);
                    break;
                default:
                    return;
            }
        }
    }

    const changeBookLayout = (layout) => {
        setLayoutToUpdate(true)
        selectedBook.Layout = layout
        if (layout === "Pages") {
            const newLayoutPages = onEditDoublePages()
            setPlayList([...newLayoutPages])
        } else {
            const newLayoutSequence = setAsSequence()
            setPlayList([...newLayoutSequence])
        }
        setIsBookTouched(true)
    }

    const handleSaveBook = async () => {
        setSavingBook(true)
        let workingPlaylist = [...fixDoublePlaceholders(playList)]
        let updatedBookDocumentPlaylists = []
        let indexOrder = 0;
        for (let media of workingPlaylist) {
            if (media.ID) {
                updatedBookDocumentPlaylists.push({
                    PlaylistID: mainBook.ID,
                    DocumentID: media.ID,
                    Note: media.notes,
                    DocumentOrder: indexOrder
                })
            }
            indexOrder++
        }
        const updatedBook = {
            ID: mainBook.ID,
            DocumentPlaylists: updatedBookDocumentPlaylists
        };
        
        if (layoutToUpdate) {
            if (packageBook) {
                //TODO: appena pronta la chiamata API implementare
                //await useChangeLayoutPlaylist({ playlistID: packageBook.ID, contactID: mainBook.ContactID });
                //forceReloadPackagePlaylist(packageBook.ID);
                //forceReloadPackage(packageBook.PackageID);
            }
            else {
                await changeLayoutPlaylist({ playlistID: mainBook.ID, layout: selectedBook.Layout, contactID: mainBook.ContactID });
            }
        }

        if (packageBook) {
            await editPackagePlaylistDocuments({ playlist: updatedBook, contactID: mainBook.ContactID });
            forceReloadPackagePlaylist(packageBook.ID);
            forceReloadPackage(packageBook.PackageID);
        }
        else {
            await editPlaylistDocuments({ playlist: updatedBook, contactID: mainBook.ContactID });
            forceReloadContactDocuments(mainBook.ContactID);
        }

        if (location.state && location.state.bookId) {
            // this the case of a new book created starting from the model page. 
            // after creation we redirect to the models page
            navigate(`/model/${contactID}`);
        } else {
            if (onCloseBookEditor) {
                // this is the case of the book editor open as a drawer.
                onCloseBookEditor()
            } else {
                // otherwise we should reopen the book list drawer as 
                // for sure user started from there
                onOpenBookList()
            }
        }
        onUpdateSelectedBook()
        setSavingBook(false)
    }

    const handleEditBook = () => {
        setIsBookDrawer(true)
    };

    const handleCloseBooksDrawer = () => {
        setIsBookDrawer(false)
    };

    const handleUpdateBook = book => {
        setMainBook(book)
        setIsBookDrawer(false)
    };

    const renderGallery = () => {
        return (
            <MediaGallery
                size={size}
                mainBook={mainBook}
                images={documentList}
                clickedMedia={clickedMedia}
                isMediaSelectable={isMediaSelectable}
                isEditingBook={mainBook}
                onClickThumbnail={(index) => {
                    if (!mainBook) {
                        onClickThumbnail(index, sortedMediaList)
                    }
                }}
                addMediaToBook={(index) => { addMediaToBook(index) }}
            />
        )
    }
    return (
        <div>
            {clickedMedia || !mainBook ? renderGallery()
                :
                <>
                    {mainBook &&
                        <DragDropContext onDragEnd={onDragEnd}>
                            {documentList.length > 0 &&
                                <Droppable droppableId="ModelPicturesContainer" direction="horizontal" isDropDisabled={true}>
                                    {(provided, snapshot) => (
                                        <MainMediaContent ref={provided.innerRef}>
                                            {renderGallery()}
                                        </MainMediaContent>
                                    )}
                                </Droppable>
                            }
                            <ModelBookEditorFooter
                                book={mainBook}
                                contactID={contactID}
                                playlist={playList}
                                isBookTouched={isBookTouched}
                                onSetBookTouched={setIsBookTouched}
                                onSaveBook={() => {
                                    handleSaveBook();
                                }}
                                onCancelBook={() => {
                                    if (onCloseBookEditor) {
                                        onCloseBookEditor()
                                    } else {
                                        setIsBookTouched(false)
                                        onCancelBook()
                                    }
                                }}
                                onEditBook={handleEditBook}
                                onRemoveMedia={removeMediaFromBook}
                                onAddPlaceholder={addPlaceholder}
                                onSetPlaylist={setPlayList}
                                packageBook={packageBook}
                                //bookLayout={selectedBook ? selectedBook.Layout : ((selectedBook.Type && selectedBook.Type.toLowerCase() === "media") ? "Sequence" : "Pages")}
                                bookLayout={selectedBook ? selectedBook.Layout : "Pages"}
                                onChangeBookLayout={changeBookLayout}
                                isDrawer={isDrawer}
                                savingBook={savingBook}
                            />
                        </DragDropContext>
                    }
                </>
            }
            {isBookDrawer &&
                <BookEditDrawer
                    book={mainBook}
                    contactID={contactID}
                    onUpdate={handleUpdateBook}
                    onClose={handleCloseBooksDrawer}
                />
            }
        </div>
    );
};

export default MediaListContainer;