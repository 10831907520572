import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Field } from "formik";
import Axios from 'axios';

import { NWInputNumber } from "Components/Gui/NwForm/NwFormItems";
import { NwSaveButton } from "Components/Gui/NwButton";
import { NwForm } from 'Components/Gui/NwForm/NwFormWrapper';
import RadioButtonGroup from 'Components/Gui/NwForm/RadioButtonGroup';
import FlexContainer from 'Components/Gui/FlexContainer';
import { forceReloadTransaction } from 'Hooks/Transaction/UseTransaction';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { forceReloadProjectTransactions } from 'Hooks/Project/UseProjectTransactions';

const CommissionContainer = styled.div`
    width: ${props => props.commissionType === 'percentage' ? '130px' : '200px'};
    margin-right: 16px;
    .label {
        margin-top: 2px;
        margin-right: 8px;
    }
    .percentage {
        margin-left: 4px;
    }
    .ant-input-number {
        width: 100%;
        font-size: 1.2rem;
    }
`;

//TODO: ottimizzare props e rimuovere quelle inutili
const TransactionCommissionForm = ({ type, transaction, contactID, currency, commission, statements, transactionId, onCancel, onUpdateTransaction, onTouchForm }) => {
    const [commissionType, setCommissionType] = useState(commission.TransactionPerc ? 'percentage' : 'amount');
    const [defaultTransaction, setDefaultTransaction] = useState();

    const otherStatements = statements.filter(statement => statement.ID !== commission.ID);
    let maxStatement = -1;
    for (const statement of otherStatements) {
        if (maxStatement < Math.abs(statement.Amount)) {
            maxStatement = Math.abs(statement.Amount)
        }
    }

    useEffect(() => {
        const fetchDefaultTransaction = async () => {
            try {
                const defaultTransaction = await Axios.get(`/${type === 'model' ? 'models' : 'customers'}/GetDefaultTransactionData/${contactID}`);
                setDefaultTransaction(defaultTransaction);
            } catch (error) {
                console.log('ant : Fetch Default Transaction Error => ', error);
            }
        };

        fetchDefaultTransaction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let initialValues = {
        Amount: Math.abs(commission.Amount),
        Percentage: commission.TransactionPerc
    };

    const submitForm = async (values, setStatus, setSubmitting) => {
        setSubmitting(true);
        try {

            //TODO: gestire con condizioni varie tutti i casi:
            // percentage / amount, agencyfee / agencycommission / motheragencycommission (in base al tipo di soggetto)
            // utilizzare la family per comporre la chiamata
            // verificare operazione dopo salvataggio dati
            let urlcall = ""
            let data = {}

            data.TransactionID = transaction.ID
            if (commissionType !== 'percentage') {
                data.Amount = values.Amount
            } else {
                data.Perc = values.Percentage
            }
            switch (type) {
                case 'model':
                    if (commissionType !== 'percentage') {
                        urlcall = `/transactions/${transaction.Family}/modifyagencycommissionamount`

                    } else {
                        urlcall = `/transactions/${transaction.Family}/modifyagencycommissionperc`
                    }
                    break;
                case 'client':
                    if (commissionType !== 'percentage') {
                        urlcall = `/transactions/${transaction.Family}/modifyagencyfeeamount`
                    } else {
                        urlcall = `/transactions/${transaction.Family}/modifyagencyfeeperc`
                    }
                    break;
                case 'agency':
                    if (commissionType !== 'percentage') {
                        urlcall = `/transactions/${transaction.Family}/modifymotheragencycommissionamount`
                    } else {
                        urlcall = `/transactions/${transaction.Family}/modifymotheragencycommissionperc`
                    }
                    data.StatementID = commission.ID
                    break;
                default:
                    break;
            }
            await Axios.put(urlcall, data)
            forceReloadTransaction(transaction.ID)
            if (transaction.ProjectID) {
                forceReloadProjectTransactions(transaction.ProjectID)
            }
            onTouchForm(false);
            onCancel();

            // if (commissionType === 'percentage') {
            //     await onUpdatePercentage({ type, transactionId, commissionId: commission.ID, percentage: values.Percentage }); 
            // } else {
            //     await onUpdateAmount({ type, transactionId, commissionId: commission.ID, amount: values.Amount, commisionId: commission.ID })
            // }
            // onTouchForm(false);
            // await onUpdateTransaction();
            // onCancel();

        } catch (error) {
            console.log('ant : Update commission error => ', error);
            setSubmitting(false);
        }
    };

    const validatePercentage = value => {
        if (value < 0) return 'It should be greater than 0';
        if (value > 100) return 'It should be lower than 100';
        const stringValue = value.toString();
        if (stringValue.length >= 6) {
            return 'It should be less than 6 numbers'
        }
        return null;
    };

    const validateAmount = value => {
        if (value < 0) return 'It should be greater than 0';
        if (maxStatement !== -1 && maxStatement < value) {
            return `It should be lower than ${maxStatement}`;
        }
        const stringValue = value.toString();
        if (stringValue.length >= 12) {
            return 'It should be less than 12 numbers'
        }
        return null;
    };



    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus();
                submitForm(values, setStatus, setSubmitting);
            }}
        >
            {({ isSubmitting, values, handleSubmit }) => {
                return (
                    <NwForm
                        values={values}
                        onTouchForm={onTouchForm}
                        onFinish={handleSubmit}
                        layout="vertical">
                        <FlexContainer mg='0 0 16px' column alignItems='flex-start'>
                            {((type === 'model') && defaultTransaction && defaultTransaction.NetworkAgency.ModelCommission)
                                ?
                                <div>
                                    {`${getContactName(contactID)} has a default commission set to `}
                                    <strong>
                                        {defaultTransaction.NetworkAgency.ModelCommission}%
                                    </strong>
                                </div>
                                :
                                null
                            }
                            {((type === 'client') && defaultTransaction && defaultTransaction.NetworkAgency.AgencyFee)
                                ?
                                <div>
                                    {`${getContactName(contactID)} has a default agency fee set to `}
                                    <strong>
                                        {defaultTransaction.NetworkAgency.AgencyFee}%
                                    </strong>
                                </div>
                                :
                                null
                            }
                            {((type === 'agency') && defaultTransaction && defaultTransaction.NetworkAgency.MotherAgencyCommission)
                                ?
                                <div>
                                    {`${getContactName(contactID)} has a default mother agency commission set to `}
                                    <strong>
                                        {defaultTransaction.NetworkAgency.MotherAgencyCommission}%
                                        {defaultTransaction.NetworkAgency.MotherAgencyCommissionOnGross ? ' on gross' : ''}
                                    </strong>
                                </div>
                                :
                                null
                            }
                            {(defaultTransaction && defaultTransaction.NetworkAgency.FeeHint)
                                ?
                                <div>Fee Hint: {defaultTransaction.NetworkAgency.FeeHint}</div>
                                :
                                null
                            }
                        </FlexContainer>
                        <RadioButtonGroup
                            value={commissionType}
                            onChange={setCommissionType}
                            options={[
                                { label: 'Percentage', value: 'percentage' },
                                { label: 'Amount', value: 'amount' }
                            ]} />
                        <FlexContainer fullWidth mg='24px 0 8px'>
                            {`Set commission ${commissionType}`}
                        </FlexContainer>
                        <FlexContainer alignItems='flex-start'>
                            <CommissionContainer commissionType={commissionType}>
                                {commissionType === 'percentage' ?
                                    <FlexContainer alignItems='flex-start'>
                                        <Field
                                            component={NWInputNumber}
                                            // label="Percentage"
                                            name="Percentage"
                                            step={0.01}
                                            value={values.Percentage}
                                            validate={validatePercentage}
                                        />
                                        <div className='percentage'>%</div>
                                    </FlexContainer>
                                    :
                                    <FlexContainer alignItems='flex-start'>
                                        <div className='label'>{currency}</div>
                                        <Field
                                            component={NWInputNumber}
                                            // label="Amount"
                                            name="Amount"
                                            step={0.01}
                                            value={values.Amount}
                                            validate={validateAmount}
                                        />
                                    </FlexContainer>
                                }
                            </CommissionContainer>
                            <NwSaveButton
                                htmlType="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                            />
                        </FlexContainer>
                    </NwForm>
                )
            }}
        </Formik>
    );
};

export default TransactionCommissionForm;
