import React, { useState } from "react";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import CountryLabel from 'Components/Gui/CountryLabel';
import AnagraphicalDrawer from "Components/ContactDetails/CommonComponents/Anagraphical/AnagraphicalDrawer";
import { getAge, isMinor } from "Libs/NwMoment";
import { showDateNoTime } from "Libs/NwMoment";
import NwIcon, { NwGenderIcon } from "Components/Gui/NwIcon";
import PermissionBlock from "Components/PermissionBlock/PermissionBlock";
import { useUserPermission } from 'Contexts/UserContext';
import { NwTag } from "Components/Gui/NwTag";

const ContactDetailsHeaderAnagraphical = ({ contact, iconSize, readOnly, noDrawer }) => {
    const [drawer, setDrawer] = useState(false);

    const userids = (contact && contact.users) ? contact.Users.map(user => user.UserID) : [];

    const contactType = contact.SubType > 3 ? 'model' : 'customer';

    const viewModelAnagraphical = useUserPermission('ViewModelAnagraphical', userids);

    const birthDate = contact.BirthDate || contact.B
    const nationalityCode = contactType === "model" ? (contact.NationalityCode || contact.NAT) : null
    const gender = contactType === "model" ? (contact.GenderName || contact.G) : null

    const getBirthdayContent = () => {
        if (isMinor(birthDate)) {
            return (
                <>
                    <NwTag type="danger">{getAge(birthDate)} y.o.</NwTag>&nbsp;&nbsp;<NwIcon icon={light("birthday-cake")} />&nbsp;&nbsp;{showDateNoTime(birthDate)}&nbsp;&nbsp;minor
                </>
            )
        } else {
            return (
                <>
                    {getAge(birthDate)} y.o.&nbsp;&nbsp;<NwIcon icon={light("birthday-cake")} />&nbsp;&nbsp;{showDateNoTime(birthDate)}
                </>
            )
        }
    }

    return (
        <>
            <div className="header-body-boxes header-body-boxes-hoverable" onClick={() => viewModelAnagraphical && setDrawer(true)}>
                <PermissionBlock
                    permission={contactType === 'model' ? viewModelAnagraphical : true}
                    content={
                        contact.ContactType === 'Customer' ?
                            <>
                                <NwIcon size={iconSize} icon={light("id-card")} />
                                &nbsp;&nbsp;
                                {birthDate ? getBirthdayContent() : `birthday: n.d.`}
                            </>
                            :
                            <>
                                <NwGenderIcon gender={`${gender}`} />
                                &nbsp;&nbsp;
                                {birthDate ? getBirthdayContent() : `birthday: n.d.`}
                                &nbsp;&nbsp;&nbsp;
                                {nationalityCode && <CountryLabel usecode code={nationalityCode} flag />}
                            </>
                    }
                />
            </div>
            {drawer && !noDrawer &&
                <AnagraphicalDrawer currentContact={contact} readOnly={readOnly} type={contact.ContactType === 'Customer' ? 0 : 1} onClose={() => {
                    setDrawer(false)
                }} />
            }
        </>
    )
};

export default ContactDetailsHeaderAnagraphical;
