import React, { useMemo } from "react";
import { Formik } from "formik";

import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import {
    forceReloadContact,
    onUpdateContactCustomFields,
} from 'Hooks/Contact/UseContact';
import { getCustomFieldInitialValue, getEmbeddedCustomField } from 'Libs/NwCustomFields';
import { toISODate } from "Libs/NwMoment";
import Axios from "axios";

const CustomPropertiesForm = ({ touchForm, type, currentContact, customFields, onCancel, onTouchForm }) => {
    
    const availableCustomFields = customFields ? customFields.filter(field => !field.Disabled) : [];

    // type 1: model
    // type 2: customer
    const isModel = type === 1;

    const CustomFieldsInitialValues = useMemo(() => {
        const customFieldsInitialValues = {};
        for (const customField of availableCustomFields) {
            if (!customField.Disabled) {
                customFieldsInitialValues[customField.Name] = getCustomFieldInitialValue(
                    customField.TypeName, 
                    currentContact.CustomFields[customField.Name]
                );
            }
        }
        return customFieldsInitialValues;
    }, [availableCustomFields, currentContact.CustomFields]);

    const initialValues = {
        CustomFields: {
            ...CustomFieldsInitialValues
        }
    };
    

    const submitForm = async (values, setSubmitting) => {
        const isCustomFieldsTouched = JSON.stringify(initialValues.CustomFields) !== JSON.stringify(values.CustomFields);
        if (isCustomFieldsTouched) {
            let customFieldsData = {};
            Object.keys(values.CustomFields).map(key => {
                if (values.CustomFields[key] !== '' && JSON.stringify(values.CustomFields[key]) !== JSON.stringify([]) && values.CustomFields[key]) {
                    const customField = availableCustomFields.find(item => item.Name === key);
                    if (customField.TypeName === 'MultipleChoice') {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: values.CustomFields[key].join(',')
                        }
                    } else if (customField.TypeName === 'Date') {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: toISODate(values.CustomFields[key])
                        }
                    } else {
                        customFieldsData = {
                            ...customFieldsData,
                            [key]: values.CustomFields[key]
                        }
                    }
                }
                return null;
            })

            try {
                //await onUpdateContactCustomFields({ contactId: currentContact.ID, data: customFieldsData });
                await Axios.put(`/contacts/Customfields/${currentContact.ID}`, customFieldsData)
                forceReloadContact(currentContact.ID)
                if (onTouchForm) {
                    onTouchForm(false);
                }
                onCancel();
            } catch (error) {
                console.log('ant : Save Event Custom Fields Error => ', error);
            }
        } else {
            onCancel();
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus();
                submitForm(values, setSubmitting);
            }}
        >
            {({ isSubmitting, values, handleSubmit }) => (
                <NwForm
                    values={values}
                    onTouchForm={onTouchForm}
                    onFinish={handleSubmit}
                    layout="vertical">
                    {availableCustomFields.map((customField, index) => {
                        return <div key={`${customField.Name}-${index}`}>
                            {getEmbeddedCustomField(values, customField)}
                        </div>
                    })}
                    <NwFormButtonsBar
                        left={
                            <NwCancelButton
                                disabled={isSubmitting}
                                onClick={() => {
                                    onTouchForm(false);
                                    onCancel();
                                }}
                            />
                        }
                        right={
                            <NwSaveButton
                                htmlType="submit"
                                disabled={isSubmitting || !touchForm}
                                loading={isSubmitting}
                            />
                        }
                    />
                </NwForm>
            )}
        </Formik>
    );
};

export default CustomPropertiesForm;
