import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwDrawer from 'Components/Gui/NwDrawer';
import { Loading } from 'Components/Gui/Loading';
import FlexContainer from 'Components/Gui/FlexContainer';
import ContactSocials from 'Components/Social/ContactSocials';
import ModelRating from 'Components/ContactDetails/ModelDetails/Drawer/ModelRating';
import { useContact } from 'Hooks/Contact/UseContact';
import { addModelLog } from "Libs/NwLogs";
import { useStateValue } from 'Contexts/StateProvider';
import ModelDetailsMedia from 'Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsMedia/ModelDetailsMedia';
import NwIcon from 'Components/Gui/NwIcon';
import ObjectLogHistories from "Components/ObjectLogHistories/ObjectLogHistories";
import FeaturesDrawer from "Components/Features/FeaturesDrawer";
import ContactDetailsDocuments from 'Components/ContactDetailsDocuments/ContactDetailsDocuments';
import ModelDetailsBooks from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsBooks";
import PermissionBlock from 'Components/PermissionBlock/PermissionBlock';
import { useUserPermission } from 'Contexts/UserContext';
import ModelDetailsHeaderDrawer from 'Components/ContactDetails/ContactDetailsHeader/ModelDetailsHeaderDrawer';
import { ContactDetailsContainer } from 'Components/ContactDetails/CommonStyles/ContactDetailsContainer';
import ContactDetailsActions from 'Components/ContactDetails/ContactDetailsHeader/ContactDetailsActions';
import ReadOnlyAgenda from 'Components/Schedule/Agenda/ReadOnlyAgenda';
import ContactDetailsTasks from '../../CommonComponents/ContactDetailsTasks';
import ModelDetailsCustomPropertiesAndNotesTabs from '../CommonComponents/ModelDetailsCustomPropertiesAndNotesTabs';
import ModelAgenciesCard from 'Components/ModelAgencies/ModelAgenciesCard';
import useNwBreakPoints from 'Hooks/UseNwBreakPoints';
import ModelDetailsIndexMobile from '../ModelDetailsIndexMobile';
import { getContactName } from 'Hooks/Contact/UseContactsList';

const Actions = styled(FlexContainer)`
    svg {
        margin-left: 8px;
    }
`;

const ModelDrawer = ({ modelID, hideCalendar, showRating, scoutingModel, meetingImages, onClose }) => {
    const loading = false;
    const { data: currentModel } = useContact(modelID);
    const [{ areaContext }] = useStateValue();
    const navigate = useNavigate();
    const [isOpenHistory, setIsOpenHistory] = useState(false);
    const [editFeatures, setEditFeatures] = useState(false);
    const viewModelAgencies = useUserPermission('ViewModelAgencies', currentModel && currentModel.Users.map(user => user.UserID));
    const viewModelFeatures = useUserPermission('ViewModelFeatures', currentModel && currentModel.Users.map(user => user.UserID));

    const breakpoints = useNwBreakPoints();
    const isMobile = breakpoints === 1;

    useEffect(() => {
        if (currentModel) {
            addModelLog(currentModel, areaContext.area);
        }
    }, [currentModel]);

    const handleMoveModelDetails = () => {
        navigate(`/model/${modelID}`);
    };

    const handleCloseFeatureDrawer = () => {
        setEditFeatures(false);
    };

    const handleOpenFeatureDrawer = () => {
        setEditFeatures(true);
    };

    // const handleMoveSimilarModels = () => {
    //     onClose();
    //     history({
    //         pathname: areaContext.area === 'booking' ? '/models' : `/${areaContext.area}-models`,
    //         state: {
    //             contactId: currentModel.ID,
    //             type: 'find-similar-models'
    //         }
    //     })
    // };

    const handleOpenHistory = () => {
        setIsOpenHistory(true);
    };

    const handleCloseHistory = () => {
        setIsOpenHistory(false);
    };



    const renderAction = (content, permission) => {
        return (
            <PermissionBlock
                content={content}
                permission={permission} />
        )
    };

    if (isMobile) {
        return (
            <NwDrawer
                width="90vw"
                onClose={onClose}
                whiteBg
                padding='0'
                title={getContactName(modelID)}
            >
                <ModelDetailsIndexMobile isDrawer modelId={modelID} currentContact={currentModel} />
            </NwDrawer>
        )
    } else {
        return (
            <NwDrawer
                width={780}
                title={currentModel ?
                    <FlexContainer justifyContent='space-between'>
                        <div>Model Preview</div>
                        <Actions>
                            <NwIcon icon={solid('expand-wide')} pointer onClick={handleMoveModelDetails} size='lg' />&nbsp;&nbsp;
                            {renderAction(<NwIcon icon={light("weight")} size='lg' onClick={viewModelFeatures && handleOpenFeatureDrawer} />, viewModelFeatures)}&nbsp;&nbsp;
                            <NwIcon icon={light('history')} size='lg' pointer onClick={handleOpenHistory} />
                            <ContactDetailsActions contact={currentModel} fromDrawer onClose={onClose} />
                        </Actions>
                    </FlexContainer>
                    : 'Model Preview'
                }
                onClose={onClose}
                whiteBg={false}
            >
                <div className='drawer-content preview'>
                    {loading || !currentModel ? <Loading />
                        :
                        <ContactDetailsContainer>
                            <ModelDetailsHeaderDrawer
                                selectedModel={currentModel.ID}
                                drawerMode
                                onOpenModelAgencies={() => { }}
                                onOpenStatements={() => { }}
                            />
                            <div className='content-section'>
                                <ContactDetailsTasks currentContact={currentModel} />
                            </div>
                            <div className='content-section'>
                                <ModelDetailsCustomPropertiesAndNotesTabs currentContact={currentModel} drawerMode />
                            </div>
                            {!hideCalendar &&
                                <div className='content-section'>
                                    <ReadOnlyAgenda
                                        type="model"
                                        contactId={currentModel.ID}
                                    />
                                </div>
                            }
                            <div className='content-section'>
                                <ContactSocials previewMode currentContact={currentModel} />
                            </div>
                            <div className='content-section'>
                                <ModelDetailsBooks contactID={modelID} readOnly modelPlaylists={currentModel.Playlists} />
                            </div>
                            <div className='content-section'>
                                <ModelDetailsMedia
                                    noDots
                                    currentContact={currentModel}
                                    scoutingModel={scoutingModel}
                                    previewMode
                                    meetingMediaList={meetingImages} />
                            </div>
                            {viewModelAgencies &&
                                <div className='content-section'>
                                    <ModelAgenciesCard
                                        contactID={currentModel.ID}
                                        represented={currentModel.Represented}
                                        drawerMode
                                    />
                                </div>
                            }
                            <div className='content-section'>
                                <ContactDetailsDocuments currentContact={currentModel} readOnly />
                            </div>
                        </ContactDetailsContainer>
                    }
                    {isOpenHistory && (
                        <NwDrawer title="Model Logs" onClose={handleCloseHistory}>
                            <ObjectLogHistories
                                objectId={currentModel.ID}
                                type="model"
                            />
                        </NwDrawer>
                    )}
                    {editFeatures && (
                        <FeaturesDrawer contact={currentModel} readOnly={true} onClose={handleCloseFeatureDrawer} />
                    )}
                </div>
            </NwDrawer>
        );
    }
};

export default ModelDrawer;