import React, { useContext, useState, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import { groupBy } from 'lodash';
import { AutoComplete, Select } from 'antd';
import { useDebounce } from "react-use";
import NwIcon from 'Components/Gui/NwIcon';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { ModelCover } from 'Components/ModelCover';
import { ListContext } from 'Contexts/ListContext';
import { useModelsList, useCustomersList } from "Hooks/Contact/UseContactsList";
import { deaccentizeandlowerize, getHighlightedText } from 'Libs/NwUtils';
import EmptyState from "Components/Gui/EmptyState";

const Option = AutoComplete.Option;
const OptGroup = Select.OptGroup;

const StyledOption = styled.div`
    display: flex;
    align-items: center;
    text-transform: capitalize;
    gap: .5rem;

    opacity: ${props => props.$added ? '.4' : '1'};

    p {
        margin-bottom: 0;
    }

    .highlight {
        font-weight: bold;
    }

    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .check-icon-wrapper {
        position: absolute;
        top: 10px;
        left: 5px;
    }
`;

const StyledAutocompleteContainer = styled.div`
    height: 4rem;
    padding-left: 1rem;
    padding-right:1rem;
	text-align: left;
	width: 23rem;
	display: flex;
	align-items: center;

	.ant-select {
		width: 100%;
		max-width: 22rem;
		border: none;
		border: none;
		font-size: 1em;
		background-color: transparent;

        .ant-select-selector {
            height: 2rem;
            display: flex;
            align-items: center;
            border-radius: 80px;
            padding: .5rem 1rem .5rem 3rem;
            font-size: .95rem;
            
            .ant-select-selection-search {
                padding-left: 2.25rem;
            }
            input {
                font-size: .95rem;
                height: 100% !important;
                padding-bottom: 1px !important;
            }
        }
		
		.ant-select-selection {
			border: none;
			background-color: transparent;
        }

		input {
			border:none;
			line-height: 1.5em;
			font-size: 1.3em;
			height: auto;
			padding: 0;
			outline:none !important;
			box-shadow: none;

			&:focus, &:focus-within, &:active, &:hover {
				outline:none !important;
				box-shadow: none;
			}
		}
	}

    .select-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
       
        .search-icon-wrapper {
			position: absolute;
            left: 1rem;
            z-index: 1;
            font-size: 1.2rem;
		}

        .close-icon-wrapper {
            position: absolute;
            right: 1rem;
            z-index: 1;
            font-size: 1.2rem;
        }
    }
`;

const Wrapper = styled.div`
    padding-bottom: 2rem;
    .rc-virtual-list-holder {
        max-height: 60vh !important;

        .ant-select-item-group {
            padding-left: 1rem;
        }

        .ant-select-item-option-content {
            span {
                margin: 0 20px 0 0 !important;
            }
        }

        .ant-select-item-option-grouped  {
            padding-left: 2rem !important;
        }
    }
`;

const WrapperContainer = styled.div`
    width: ${props => props.$isMobile && '100%'};
`;

const ContactsQuickAdd = ({
    onSelect,
    sources,
    added
}) => {

    const [filteredDataSource, setFilteredDataSource] = useState([])
    const [currentValue, setCurrentValue] = useState("");
    const searchRef = useRef();
    const { cachedListLoaded } = useContext(ListContext);
    const { data: models, isFetching: loadingModels } = useModelsList();
    const { data: customers, isFetching: loadingCustomers } = useCustomersList();
    const [nameFilter, setNameFilter] = useState('');

    //set focus on search input
    useEffect(() => {
        searchRef.current.focus();
    }, [])

    useDebounce(() => {
        setNameFilter(currentValue);
    }, 500, [currentValue]);

    const getModelSearchName = (model) => {
        return `${model.N.trim()} ${model.S ? model.S.trim() : ''}`;
    }

    const fullDataSource = useMemo(() => {
        if (!loadingModels && !loadingCustomers) {
            const dataSources = sources.map((source) => {
                switch (source.toLowerCase()) {
                    case "models":
                        return models ? (models.filter((model) => (model.ST === 4))
                            .map((model) => ({ ...model, searchValue: getModelSearchName(model), searchGroup: "models" })))
                            : [];
                    case "scouting":
                        return models ? (models.items.filter((model) => (model.ST === 5))
                            .map((model) => ({ ...model, searchValue: getModelSearchName(model), searchGroup: "scouting" })))
                            : [];
                    case "clients":
                        return customers ? (customers.filter((customer) => (customer.ST === 1))
                            .map((customer) => ({ ...customer, searchValue: `${customer.N}`, searchGroup: "clients" })))
                            : [];
                    case "agencies":
                        return customers ? (customers.filter((customer) => (customer.ST === 2))
                            .map((customer) => ({ ...customer, searchValue: `${customer.N}`, searchGroup: "agencies" })))
                            : [];
                    case "services":
                        return customers ? (customers.filter((customer) => (customer.ST === 3))
                            .map((customer) => ({ ...customer, searchValue: `${customer.N}`, searchGroup: "services" })))
                            : [];
                    default:
                        return models ? (models.filter((model) => (model.ST === 4))
                            .map((model) => ({ ...model, searchValue: getModelSearchName(model), searchGroup: "models" })))
                            : [];
                }
            })
            return (dataSources.flat())
        }
        return [];
    }, [loadingModels, loadingCustomers])

    const isAdded = (id) => {
        return added && added.includes(id)
    }

    const renderOptions = () => {
        if (filteredDataSource) {
            let sortedDataSource = [...filteredDataSource];
            sortedDataSource = sortedDataSource.sort((data1, data2) => {
                if (data1.searchValue.toLowerCase().indexOf(currentValue.toLowerCase()) === 0) return -1;
                if (data2.searchValue.toLowerCase().indexOf(currentValue.toLowerCase()) === 0) return 1;
                return 1;
            })
            const grouped = groupBy(sortedDataSource, (item) => item.searchGroup);
            const options = Object.keys(grouped).sort((group1, group2) => {
                if (group1 === 'models') return -1;
                if (group2 === 'models') return 1;
                return 1;
            }).map((group, groupIndex) => (
                <OptGroup key={`opt-group-${groupIndex}`} label={group}>
                    {grouped[group].map((opt, index) => {
                        let item = null
                        switch (group.toLowerCase()) {
                            case "models":
                                item = <StyledOption $added={isAdded(opt.ID)}>
                                    <span style={{ margin: "5px 20px" }}>
                                        <ModelCover visibleByDefault classN="model-cover-small" model={opt} />
                                        {isAdded(opt.ID) &&
                                            <div className="check-icon-wrapper">
                                                <NwIcon icon={light("check")} />
                                            </div>
                                        }
                                    </span>
                                    <p>{getHighlightedText(`${opt.N.toLowerCase()} ${opt.S ? opt.S.toLowerCase() : ''}`, currentValue)}<br /><small>{opt.MAN}</small></p>
                                </StyledOption>
                                break;
                            case "clients":
                            case "services":
                                item = <StyledOption $added={isAdded(opt.ID)}>
                                    {isAdded(opt.ID) &&
                                        <div className="check-icon-wrapper">
                                            <NwIcon icon={light("check")} />
                                        </div>
                                    }
                                    <NwIcon icon={light("address-card")} />
                                    <p>{getHighlightedText(opt.N.toLowerCase(), currentValue)}</p>
                                </StyledOption>
                                break;
                            case "agencies":
                                item = <StyledOption $added={isAdded(opt.ID)}>
                                    {isAdded(opt.ID) &&
                                        <div className="check-icon-wrapper">
                                            <NwIcon icon={light("check")} />
                                        </div>
                                    }
                                    <NwIcon icon={light("globe")} />
                                    <p>{getHighlightedText(opt.N.toLowerCase(), currentValue)}</p>
                                </StyledOption>
                                break;
                            default:
                                break;
                        }

                        return (
                            <Option key={`${groupIndex}-${index}-${opt.ID}`} value={opt.ID}>
                                {item}
                            </Option>
                        )
                    })}
                </OptGroup>
            ))
            return (options)
        } else {
            return ([])
        }
    }

    useEffect(() => {
        if (nameFilter.length >= 2) {
            const normalizedNameFilter = deaccentizeandlowerize(nameFilter);
            const filteredResult = fullDataSource.filter(
                (item) => {
                    const normalizedItem = deaccentizeandlowerize(item.searchValue);
                    return normalizedItem.includes(normalizedNameFilter)
                }
            )
            setFilteredDataSource(filteredResult)
        } else {
            setFilteredDataSource([])
        }
    }, [nameFilter, fullDataSource]);

    const handleSearch = (inputValue) => {
        setCurrentValue(inputValue);
    }

    const resetSearch = () => {
        setCurrentValue("");
        searchRef.current.focus();
    }

    return (
        <WrapperContainer>
            {cachedListLoaded &&
                <StyledAutocompleteContainer
                    openMode
                >
                    <div className='select-wrapper'>
                        <div className="search-icon-wrapper">
                            <NwIcon icon={light('search')} />
                        </div>
                        <AutoComplete
                            autoComplete='off'
                            ref={searchRef}
                            className="global-search"
                            onSelect={(id) => {
                                if (!isAdded(id)) {
                                    onSelect(id);
                                }
                            }}
                            onSearch={handleSearch}
                            placeholder="Search in netwalk"
                            defaultOpen={false}
                            open
                            value={currentValue}
                            notFoundContent={currentValue.length >= 2 && <EmptyState message='no results' />}
                            dropdownAlign={{ offset: [0, 0] }}
                            popupClassName='open-search-dropdown'
                            dropdownMatchSelectWidth={false}
                            dropdownStyle={{ width: '22rem' }}
                            autoFocus
                            dropdownRender={node => {
                                return (
                                    <Wrapper>
                                        {node}
                                    </Wrapper>
                                )
                            }}
                        >
                            {renderOptions()}
                        </AutoComplete>
                        {currentValue.length > 0 &&
                            <div className="close-icon-wrapper">
                                <NwIcon pointer icon={light('times')} onClick={resetSearch} />
                            </div>
                        }
                    </div>
                </StyledAutocompleteContainer>
            }
        </WrapperContainer>
    )
}

export default ContactsQuickAdd;