import React from "react";
import { Formik, Field } from "formik";
import Axios from 'axios';
import { getMoment } from "Libs/NwMoment";
import * as Yup from "yup";

import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NWEventPeriodPicker } from "Components/Gui/NwForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { forceReloadContact } from 'Hooks/Contact/UseContact';
import { NWInput } from 'Components/Gui/NwForm/NwFormItems';
import { NWCheckbox } from 'Components/Gui/NwForm/NwFormItems';
import { forceReloadModels } from "Hooks/Contact/UseContactsList";

const ModelInTownScheduleAddEdit = ({ modelID, schedule, onChange, onCancel, checkOverlapping, onTouchForm }) => {
    const initialValues = {
        scheduleDate: null,
        StartDate: schedule && schedule.StartDate ? getMoment(schedule.StartDate) : null,
        EndDate: schedule && schedule.EndDate ? getMoment(schedule.EndDate) : null,
        ModelID: schedule ? schedule.ModelID : modelID,
        Period: {
            startDate: schedule && schedule.StartDate ? getMoment(schedule.StartDate) : null,
            endDate: schedule && schedule.EndDate ? getMoment(schedule.EndDate) : null,
        },
        Location: schedule ? schedule.Location : "",
        IsInTown: schedule ? schedule.IsInTown : true,
    };

    const submitForm = async (values, setSubmitting) => {
        let newValues = {
            ModelID: values.ModelID,
            StartDate: getMoment(values.Period.startDate),
            EndDate: values.Period.endDate && getMoment(values.Period.endDate),
            Location: values.IsInTown ? "" : values.Location,
            IsInTown: values.IsInTown
        };

        setSubmitting(true);
        if (schedule) {
            newValues.ID = schedule.ID;
            try {
                await Axios.put(`/inTownSchedules`, newValues);
                onTouchForm(false);
                forceReloadContact(values.ModelID)
                forceReloadModels()
                onChange();
                setSubmitting(false);
            } catch (error) {
                console.log("error", error);
            }
        } else {
            try {
                await Axios.post('/inTownSchedules', newValues);
                onTouchForm(false);
                forceReloadContact(values.ModelID);
                forceReloadModels()
                onChange();
                onCancel();
                setSubmitting(false);
            } catch (error) {
                console.log("error", error);
            }
        }
    };

    const validatePeriod = period => {
        if (!period.startDate) {
            return 'Start Date is required';
        }
        // if (period.endDate && period.endDate.diff(period.startDate,"months") > 12) {
        //     return "event longer than a year"
        // }
        return checkOverlapping({
            StartDate: period.startDate,
            EndDate: period.endDate
        });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                submitForm(values, setSubmitting);
            }}
            validationSchema={Yup.object().shape({
                Location: Yup.string().when("IsInTown", {
                    is: false,
                    then: Yup.string().required("Content is required")
                })
            })}>
            {({ isSubmitting, values, handleSubmit, setFieldValue }) => (
                <NwForm
                    values={values}
                    onTouchForm={onTouchForm}
                    onFinish={handleSubmit}
                    layout="vertical">
                    <div>
                        <Field
                            component={NWEventPeriodPicker}
                            name="Period"
                            endNullable
                            dateOnly
                            value={values.Period}
                            validate={validatePeriod}
                            required
                        />
                    </div>
                    <div style={{ paddingLeft: "4rem" }}>
                        <Field
                            component={NWCheckbox}
                            text="IN TOWN"
                            value={values.IsInTown}
                            name="IsInTown"
                            onAfterChange={() => {
                                setFieldValue("Location", "");
                            }}
                        />
                    </div>
                    {!values.IsInTown &&
                        <div>
                            <Field
                                component={NWInput}
                                label="Location"
                                name="Location"
                                type="text"
                                value={values.Location}
                            />
                        </div>
                    }
                    <br /><br />
                    <NwFormButtonsBar
                        left={
                            <NwCancelButton
                                disabled={isSubmitting}
                                onClick={() => {
                                    onTouchForm(false);
                                    onCancel();
                                }}
                            />
                        }
                        right={
                            <NwSaveButton
                                htmlType="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                            />
                        }
                    />
                </NwForm>
            )}
        </Formik>
    );
};

export default ModelInTownScheduleAddEdit;
