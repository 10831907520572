import React, { useContext } from 'react';
import styled from 'styled-components';
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { 
    NWTextAreaCharCounter,
    NWSelect,
    NWContactDropdown
} from 'Components/Gui/NwForm/NwFormItems';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { NwCancelButton, NwSaveButton, NwButton } from 'Components/Gui/NwButton';
import { ListContext } from 'Contexts/ListContext';
//import { onGetTransActionType } from 'Hooks/Project/UseProject';
import { useProjectModels, useAddProjectPrevisionalModel, useUpdateProjectModel } from 'Hooks/Project/UseProjectModels';


const TransactionsContainer = styled.div`
    display: flex !important;
    align-items: center;
`;

const ClearButtonStyle = {
    marginTop: 16
};

const PrevisionalModelForm = ({ projectId, PrevisionalTransactions, model, onCancel, onAfterUpdated, onTouchForm }) => {
    const { mutateAsync: onAddProjectPrevisionalModel } = useAddProjectPrevisionalModel();
    const { mutateAsync: onUpdateProjectModel } = useUpdateProjectModel();
    const { data: projectModels } = useProjectModels(projectId);

    const initialValues = {
        ModelID: model ? model.ModelID : '',
        PrevisionalTransactionID: model && model.PrevisionalTransactionID ? model.PrevisionalTransactionID : null,
        Note: model ? model.Note || '' : '',
    }

    const { cachedList } = useContext(ListContext);
    const transactionFamilies = cachedList.transactionFamilies.items;
    const rateTransaction = transactionFamilies.find(family => family.Code === 'JobFee');
    

    const saveModel = async (values, setSubmitting) => {
        let modelData = {
            ModelID: model ? model.ModelID : values.ModelID,
            ProjectID: projectId,
            PrevisionalTransactionID: values.PrevisionalTransactionID,
            Note: values.Note,
        }
        setSubmitting(true);
        if (model) {
            modelData = {
                ...modelData,
                ID: model.ID
            };
            try {
                await onUpdateProjectModel({ modelData });
                onTouchForm(false);
                onAfterUpdated();
                setSubmitting(false);
            } catch (error) {
                console.log('ant : Update Model error => ', error);
                setSubmitting(false);
            }
        } else {
            try {
                await onAddProjectPrevisionalModel({ modelData });
                onTouchForm(false);
                onAfterUpdated();
                setSubmitting(false);
            } catch (error) {
                console.log('ant : Create Model error => ', error);
                setSubmitting(false);
            }
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
                ModelID: Yup.string().required("Content is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                saveModel(values, setSubmitting);
            }}
        >
            {({ isSubmitting, values, handleSubmit, setFieldValue }) => (
                <NwForm 
                    values={values}
                    onTouchForm={onTouchForm}
                    noValidate 
                    onFinish={handleSubmit} 
                    layout="vertical">
                    {!model &&
                        <Field
                            component={NWContactDropdown}
                            label="Model"
                            name="ModelID"
                            activeTypes={['mod']}
                            value={values.ModelID}
                            exclusions={projectModels ? projectModels.map(model => model.ModelID) : []}
                        />
                    }
                    {PrevisionalTransactions.length > 0 &&
                        <TransactionsContainer>
                            <Field
                                component={NWSelect}
                                label="Estimated Fees"
                                name="PrevisionalTransactionID"
                                value={values.PrevisionalTransactionID}
                                options={PrevisionalTransactions.map(transaction => {
                                    //return { key: transaction.ID, label: `${onGetTransActionType(transaction.TypeValue)} - ${transaction.Amount} ${transaction.Currency}`, value: transaction.ID };
                                    const transactionLabel = rateTransaction.TransactionLabels.find(label => label.ID === transaction.TransactionLabelID).Label;
                                    return { key: transaction.ID, label: `${transactionLabel} - ${transaction.Currency} ${transaction.Amount}`, value: transaction.ID };
                                })}
                            />
                            &nbsp;
                            <NwButton label="Clear" style={ClearButtonStyle} onClick={() => setFieldValue('PrevisionalTransactionID', null)} />
                        </TransactionsContainer>
                    } 
                    <Field
                        component={NWTextAreaCharCounter}
                        label="Note"
                        name="Note"
                        type="text"
                        value={values.Note}
                        maxLength={2000}
                        autoSize={{ minRows: 2, maxRows: 10 }}
                    />
                    <NwFormButtonsBar
                        left={
                            <NwCancelButton
                                disabled={isSubmitting}
                                onClick={() => {
                                    onTouchForm(false);
                                    onCancel();
                                }}
                            />
                        }
                        right={
                            <NwSaveButton
                                htmlType="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                            />
                        }
                    />
                </NwForm>
            )}
        </Formik>
    )
};

export default PrevisionalModelForm;