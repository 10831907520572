import { Card } from 'antd';
import styled from 'styled-components';

export const WallContainer = styled.div`
.infinite-scroll-component {
    overflow: unset !important;
}
`;

export const StyledCard = styled(Card)`
    overflow: hidden; 
    margin-bottom: .5em;
    text-transform: uppercase;

    &.selected {
        box-shadow:  ${props => props.theme.SelectedShadow};
    }


    .model-card-top {
        position: relative;
    
        .model-cover {
            position: relative;
            .model-event-dates {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 4;
                top: 0;
                left: 0;
                padding: 8px;
        
                .ant-tag {
                    margin-bottom: 2px;
                }
            }

            .model-hover-container {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .model-hover-container, .hover-container {
                width: 100%;
                height: 100%;
                position: absolute;
                overflow: hidden;
                top: 0;
                left: 0;
                opacity: 0;
                background-color: ${props => props.theme.PrimaryColor + 'E6'};
                transition: all 0.4s ease-in-out;
                z-index: 4;
                color: #fff;
                padding: 1em;
                text-transform: uppercase;
                font-size: .85rem;
                line-height: 1.2;

                dl {
                    margin: .15em;
                    display: flex;
                    justify-content: space-between;
                    dt {}
                    dd {
                        font-weight: bold;
                        text-align: right;
                        padding-left: 1rem;
                    }
                }

                svg {
                    
                }
            }
        }

        .tag-container {
            position: absolute;
            width: 100%;
            bottom: 2px;
            left: 0;
            height: auto;
            z-index: 4;
            padding-left: 6px;
            text-transform: lowercase;
        }

        .id-container {
            position: absolute;
            top: 2px;
            right: 4px;
            height: auto;
            z-index: 4;
        }

        &:hover {
            .model-cover {
                .model-hover-container, .hover-container {
                    opacity: 1;
                }
            }
            .tag-container {
                display: none;
            }
            .id-container {
                display: none;
            }
        }
    }

    .model-name-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .5em;

        h1.ant-typography {
            margin-bottom: 0 !important;
            font-size: 1.25rem !important;
        }

        .model-name-and-calendar {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 1.3em;

            h1 {
                font-size: 1.15rem !important;
                padding-left: .5em;
                color: ${props => props.$status === 'busy' ? props.theme.DangerColor : props.$status === 'no-busy' && props.theme.SuccessColor};
            }
        }
    }

    .no-footer {
        height: 21rem;
    }

    .ant-card-body {
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 10rem;
        
        .ant-card-meta {
            flex: 1;
            padding: 0.5em;
            overflow: hidden;
            .ant-card-meta-detail {
                .ant-card-meta-title {
                    line-height: 1.2em;
                    margin: .25em 0;
                    h1 {
                        margin-bottom: 0;
                        font-size: 1.2em;
                    }
                }
                .ant-card-meta-description {
                    font-size: .8rem;
                    line-height: .9em;
                    color: rgba(0,0,0,.8);
                }
            }
        }
    }   
`;

export const Counter = styled.div`
    background: rgb(38, 179, 234);
    color: white;
    position: absolute;
    padding: 2px 8px;
    z-index: 4;
`;

export const ContactContainer = styled.div`
    margin-top: .5rem;
`;

export const InTownPanel = styled.div`
    margin-top: .5rem;
`;

export const LoadingContainer = styled.div`
    font-size: 2.4rem;
`;

export const AgePanel = styled.div`
    margin-top: .5rem;
`;