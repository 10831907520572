import { useQuery } from '@tanstack/react-query';
import Axios from 'axios';
import { queryClient } from 'QueryClientProvider';

const getAgencyModels = ID => (
    Axios.get(`/contacts/agencymodels/${ID}`)
)

const useAgencyModels = (ID) => {
    const intID = Number(ID)
    return useQuery(["agencyModels", intID], () => getAgencyModels(intID), {
        staleTime: 60000,
        cacheTime: 60000
    })
}

const forceReloadAgencyModels = (ID) => {
    const intID = Number(ID)
    queryClient.invalidateQueries(['agencyModels', intID])
}


export {
    useAgencyModels,
    forceReloadAgencyModels
}
