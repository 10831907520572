import React from "react";
import RadioButtonGroup from 'Components/Gui/NwForm/RadioButtonGroup';
import StyleVariables from 'Components/Gui/StyleVariables';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

const StatusSelector = ({ onChange, value, disabled, onBlur }) => {
    const onChangeValue = (val) => {
        if (val === 'C')
            onChange(true);
        else if(val === 'TC')
            onChange(false);
        else
            onChange(null);
    }

    const getValue = () => {
        if (value === true) return "C";
        else if (value === false) return "TC";
        return "A";
    }

    return (
        <RadioButtonGroup
            disabled={disabled}
            value={getValue()} 
            onChange={onChangeValue}
            onBlur={onBlur}
            size='small'
            options={[
                { label: 'Completed', value: "C", icon: regular('dot-circle'), color: StyleVariables.SuccessColor },
                { label: 'To Complete', value: "TC", icon: regular('circle') },
                { label: 'All', value: "A" }
            ]} />
    );
};

export default StatusSelector;
