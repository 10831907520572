import React, { useState } from 'react';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { List } from 'antd';
import { useProject, forceReloadProject } from 'Hooks/Project/UseProject';
import FlexContainer from 'Components/Gui/FlexContainer';
import { NwAddButton } from 'Components/Gui/NwButton';
import NwIcon from 'Components/Gui/NwIcon';
import NwDrawer from 'Components/Gui/NwDrawer';
import PrevisionalModelForm from 'Components/Project/PrevisionalModels/PrevisionalModelForm';
import { ModelCover } from 'Components/ModelCover';
import ModelDrawer from 'Components/ContactDetails/ModelDetails/Drawer/ModelDrawer';
import AgendaDrawer from 'Components/Schedule/Agenda/AgendaDrawer';
import { getModel } from 'Hooks/Contact/UseContactsList';


const ModelItem = ({ model, description, showAgenda }) => {
    const [isModelViewDrawer, setIsModelViewDrawer] = useState(false);

    const handleViewModel = () => {
        setIsModelViewDrawer(true);
    };

    const handleCloseModelDrawer = () => {
        setIsModelViewDrawer(false);
    };

    return (
        <>
            <List.Item.Meta
                onClick={handleViewModel}
                avatar={<ModelCover model={model} classN="model-cover-small" />}
                title={`${model.N} ${model.S}`}
                style={{ cursor: 'pointer' }}
                description={model.archived ? <div>Archived</div>
                    : <FlexContainer justifyContent='space-between'>
                        {description ? description : model.MAN}
                    </FlexContainer>
                }>
            </List.Item.Meta>
            {isModelViewDrawer &&
                <>
                    {showAgenda ?
                        <AgendaDrawer modelId={model.ID} onClose={handleCloseModelDrawer} />
                        :
                        <ModelDrawer
                            modelID={model.ID}
                            onClose={handleCloseModelDrawer} />
                    }
                </>
            }
        </>
    )
};

const ProjectModels = ({ projectId }) => {
    const { data: currentProject } = useProject(projectId);
    const [touchForm, setTouchForm] = useState(false);
    const [isAddModel, setIsAddModel] = useState(false);

    const handleAddProjectModel = () => {
        setIsAddModel(true);
    };

    const handleCloseEdit = () => {
        setIsAddModel(false);
    };

    const handleAfterUpdated = () => {
        forceReloadProject(projectId);
        handleCloseEdit();
    };

    return (

        <div className="internal-card">
            {currentProject &&
                <>
                    <div className="header">
                        <div className="title-container">
                            <NwIcon className="icon" icon={light("user")} />
                            <div className="title">Models</div>
                        </div>
                        <NwAddButton ghost onClick={handleAddProjectModel} />
                    </div>
                    <div className="content">
                        <List
                            itemLayout="horizontal"
                            dataSource={currentProject.ProjectModels}
                            renderItem={item => {
                                const model = getModel(item.ModelID);
                                return (
                                    <List.Item className="ant-list-item-top">
                                        {model && <ModelItem model={model} />}
                                    </List.Item>
                                )
                            }}
                        />
                    </div>
                    {isAddModel &&
                        <NwDrawer
                            touchForm={touchForm}
                            onTouchForm={setTouchForm}
                            title={'Add Project Model'}
                            onClose={handleCloseEdit}>
                            <PrevisionalModelForm
                                PrevisionalTransactions={currentProject.PrevisionalTransactions}
                                projectId={projectId}
                                onCancel={handleCloseEdit}
                                onAfterUpdated={handleAfterUpdated}
                                onTouchForm={setTouchForm} />
                        </NwDrawer>
                    }
                </>
            }
        </div>
    )
};

export default ProjectModels;