import React, { useContext, useRef, useState, useEffect } from "react";
import { Row, Col, Alert } from "antd";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Axios from 'axios';

import { NwSaveButton, NwCancelButton } from "Components/Gui/NwButton";
import {
    NWSelect,
    NWInput,
    NWAddressSelector,
    NWPaymentTermSelector
} from "Components/Gui/NwForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { validateAddress } from 'Libs/NwUtils';
import { useContact, forceReloadContact } from 'Hooks/Contact/UseContact';
import { ListContext } from "Contexts/ListContext";
import NwDivider from "Components/Gui/NwDivider";
import { forceReloadContactBillingInfos } from "Hooks/Contact/UseContactBillingInfos";

const BillingInfoForm = ({ billingInfo, type, ContactID, onCancel, onTouchForm }) => {
    const { data: currentContact } = useContact(ContactID);
    const { cachedList } = useContext(ListContext);
    const [errorMessage, setErrorMessage] = useState(null);
    const [newBillingCode, setNewBillingCode] = useState(null);

    const ref = useRef()

    const onError = () => {
        ref.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "start"
        });
    }

    useEffect(() => {
        if (!billingInfo) {
            getNewBillingCode();
        }
    }, []);


    const getNewBillingCode = async () => {
        try {
            const response = await Axios.get(`/BillingInfos/GetNewCode/${type}`);
            setNewBillingCode(response);
        } catch (error) {
            setErrorMessage(error.message)
            onError()
        }
    }

    const initialValues = {
        CompanyName: billingInfo ? billingInfo.CompanyName : type !== 'model' ? currentContact.Name : `${currentContact.Name} ${currentContact.Surname}`,
        VatNumber: billingInfo ? billingInfo.VatNumber : '',
        BillingCode: billingInfo ? billingInfo.BillingCode : newBillingCode,
        Address: billingInfo ?
            {
                County: billingInfo.County,
                Country: billingInfo.Country,
                State: billingInfo.State,
                Street: billingInfo.Street,
                Zip: billingInfo.Zip,
                City: billingInfo.City,
                Access: billingInfo.Access,
            }
            : null,
        FiscalID: billingInfo ? billingInfo.FiscalID : '',
        Invoice: billingInfo ? billingInfo.InvoiceType : '',
        PaymentTermCode: billingInfo ? billingInfo.PaymentTermCode : null,
        //VatCode: billingInfo ? billingInfo.VatCode :'',
        ContactName: billingInfo ? billingInfo.ContactName : '',
        ContactEmail: billingInfo ? billingInfo.ContactEmail : '',
        ContactPhone: billingInfo ? billingInfo.ContactPhone : '',
        ContactName2: billingInfo ? billingInfo.ContactName2 : '',
        ContactEmail2: billingInfo ? billingInfo.ContactEmail2 : '',
        ContactPhone2: billingInfo ? billingInfo.ContactPhone2 : '',
        Pec: billingInfo ? billingInfo.Pec : '',
        SDI: billingInfo ? billingInfo.SDI : ''
    };

    const saveBillingInfo = async (values, setSubmitting) => {
        const data = {
            // ContactID: ContactID,
            InvoiceType: values.Invoice,
            BillingCode: values.BillingCode,
            PaymentTermCode: values.PaymentTermCode,
            CompanyName: values.CompanyName,
            ContactName: values.ContactName,
            ContactEmail: values.ContactEmail,
            ContactPhone: values.ContactPhone,
            ContactName2: values.ContactName2,
            ContactEmail2: values.ContactEmail2,
            ContactPhone2: values.ContactPhone2,
            //VatCode: values.VatCode,
            FiscalID: values.FiscalID,
            VatNumber: values.VatNumber,
            County: values.Address.County,
            Country: values.Address.Country,
            State: values.Address.State,
            Street: values.Address.Street,
            Zip: values.Address.Zip,
            City: values.Address.City,
            Access: values.Address.Access,
            Pec: values.Pec,
            SDI: values.SDI,
            ContactID: ContactID
        };
        let url = `/BillingInfos`;

        if (!billingInfo) {
            try {
                await Axios.post(url, data);
                forceReloadContactBillingInfos(ContactID)
                forceReloadContact(ContactID)
                onCancel();
            } catch (error) {
                setErrorMessage(error.message)
                onError()
                setSubmitting(false);
            }
        } else {
            data.ID = billingInfo.ID
            try {
                const response = await Axios.put(url, data);
                forceReloadContactBillingInfos(ContactID)
                onCancel();
                console.log('ant : billing infos response => ', response);
            } catch (error) {
                if (error.message === 'BillingInfo already used in a Document.') {
                    setErrorMessage(<p>This Billing Profile has been used in an invoice. You can't change the <strong>Invoice Type</strong> or the <strong>Country</strong> fields.</p>)
                } else {
                    setErrorMessage(error.message)
                }
                onError()
                setSubmitting(false);
            }
        }
    };

    return (
        <div>
            {errorMessage &&
                <div ref={ref}>
                    <Alert style={{marginBottom: "1rem"}} message="Error" description={errorMessage} type="error" showIcon />
                </div>
            }
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    Invoice: Yup.string().required("Invoice Type is required"),
                    BillingCode: Yup.string().required("Billing Code is required"),
                    //VatCode: Yup.string().required("Vat code is required"),
                    CompanyName: Yup.string().required("Company Name is required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    saveBillingInfo(values, setSubmitting);
                }}
            >
                {({ isSubmitting, values, setFieldValue, handleSubmit }) => (
                    <NwForm
                        values={values}
                        onTouchForm={onTouchForm}
                        onFinish={handleSubmit}
                        layout="vertical">
                        <Field
                            component={NWInput}
                            label="Company Name"
                            name="CompanyName"
                            type="text"
                            value={values.CompanyName}
                        />
                        <Field
                            component={NWInput}
                            label="Billing Code"
                            name="BillingCode"
                            type="text"
                            value={values.BillingCode}
                        />
                        <Field
                            name='Address'
                            component={NWAddressSelector}
                            value={values.Address}
                            validate={validateAddress} />
                        <Row gutter={16}>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWInput}
                                    label="VAT #"
                                    name="VatNumber"
                                    type="text"
                                    value={values.VatNumber}
                                />
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWInput}
                                    label="SSN / Tax ID / CF"
                                    name="FiscalID"
                                    type="text"
                                    value={values.FiscalID}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Field
                                    component={NWSelect}
                                    label="Billing Type"
                                    name="Invoice"
                                    value={values.Invoice}
                                    options={(type !== 'model' ? cachedList.taxmethods.items.CustomerInvoiceTypes :
                                        cachedList.taxmethods.items.ModelInvoiceTypes).map(invoiceType => {
                                            return { key: invoiceType.Code, value: invoiceType.Code, label: `${invoiceType.Code} - ${invoiceType.Name}` };
                                        })}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Field
                                    component={NWPaymentTermSelector}
                                    label="Default Payment Terms"
                                    name="PaymentTermCode"
                                    value={values.PaymentTermCode}
                                    allowClear
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWInput}
                                    label="PEC"
                                    name="Pec"
                                    type="text"
                                    value={values.Pec}
                                />
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWInput}
                                    label="SDI"
                                    name="SDI"
                                    type="text"
                                    value={values.SDI}
                                />
                            </Col>
                        </Row>
                        <NwDivider dark>Contact</NwDivider>
                        <Field
                            component={NWInput}
                            label="Name"
                            name="ContactName"
                            type="text"
                            value={values.ContactName}
                        />
                        <Field
                            component={NWInput}
                            label="Email"
                            name="ContactEmail"
                            type="text"
                            value={values.ContactEmail}
                        />
                        <Field
                            component={NWInput}
                            label="Phone"
                            name="ContactPhone"
                            type="text"
                            value={values.ContactPhone}
                        />
                        <NwDivider dark>Alternative Contact</NwDivider>
                        <Field
                            component={NWInput}
                            label="Name"
                            name="ContactName2"
                            type="text"
                            value={values.ContactName2}
                        />
                        <Field
                            component={NWInput}
                            label="Email"
                            name="ContactEmail2"
                            type="text"
                            value={values.ContactEmail2}
                        />
                        <Field
                            component={NWInput}
                            label="Phone"
                            name="ContactPhone2"
                            type="text"
                            value={values.ContactPhone2}
                        />
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        onTouchForm(false);
                                        onCancel();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </div>
    );
};

export default BillingInfoForm;
