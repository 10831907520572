import Axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

import { forceReloadContact } from 'Hooks/Contact/UseContact';
import { queryClient } from 'QueryClientProvider';

const useFeatures = (FeatNwMain) => {
    if (FeatNwMain) {
        const features = FeatNwMain ? FeatNwMain : [];
        let filteredFeatures = [];
        for(const item of features) {
            for (const feature of item.Features) {
                filteredFeatures.push(feature);
            }
        }
        return filteredFeatures;
    }
    return []    
}

const getContactFeaturesByID = ID => (
    Axios.get(`/contacts/features/${ID}`)
)

const useContactFeatures = (ID) => {
    const intID = Number(ID)
    return useQuery(["contactfeatures", intID], () => getContactFeaturesByID(intID), {
        staleTime: 60000,
        cacheTime: 60000
    })
}

const editFeatures = ({ data }) => (
    Axios.post(`/ContactFeatures/Save`, data)
)

const useEditFeatures = () => {
    return useMutation(editFeatures, { 
        onSuccess: (data, variables) => {
            const intID = Number(variables.data.ContactID)
            queryClient.invalidateQueries(['contactfeatures', intID])
            if (!variables.disableRefetchContact) {
                forceReloadContact(variables.data.ContactID)
            }
        }
    })
}

export  {
    useFeatures,
    useContactFeatures,
    useEditFeatures
};