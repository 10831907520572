import React from "react";

import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import NwIcon from "Components/Gui/NwIcon";
import { Button, Tooltip } from "antd";



const FilterClearButton = ({ onClick }) => {
    return (
    <Tooltip title="Clear filter">
        <Button size="small" shape="circle" onClick={onClick} icon={<NwIcon icon={light("xmark")} />} />
    </Tooltip>
    )
}

export default FilterClearButton;