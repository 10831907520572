import React, { useMemo } from "react";
import { useAddModelToDate, useUpdateOptionNumber } from 'Hooks/Event/UseEvent';
import NwDrawer from "Components/Gui/NwDrawer";
import ModelsSelectorWrapper from "Components/ContactsSelector/ModelsSelector/ModelsSelectorWrapper";
import { ModelContextProvider } from "Contexts/ModelContext";

const AddModelsToBookingEvent = ({ event, projectId, onClose }) => {
    const { mutateAsync: onAddModelToDate } = useAddModelToDate();
    const { mutateAsync: updateOptionNumber } = useUpdateOptionNumber();

    const onUpdateOptionNumber = async (modelId, eventDateModelId, optionNumber) => {
        const data = {
            ModelID: modelId,
            EventDateModelID: eventDateModelId,
            OptionNumber: optionNumber
        }
        try {
            await updateOptionNumber({ ID: event.ID, data });
        } catch (error) {
            console.log('ant : Update Option Number Error => ', error);
        };
    };

    const handleModelAdd = async (modelIds, eventDateIds) => {
        try {
            for (const modelId of modelIds) {
                for (const eventDate of eventDateIds) {
                    const eventDateModel = await onAddModelToDate({ modelId, eventDateId: eventDate.ID, eventId: event.ID });

                    if (eventDate.optionNumber) {
                        await onUpdateOptionNumber(modelId[0], eventDateModel.ID, eventDate.optionNumber);
                    }
                }
            }
        } catch (error) {
            console.log('ant : Add Models to EventDate Error => ', error)
        }
    };

    const handleAddModelsToEventDates = async eventDates => {
        try {
            for (const eventDate of eventDates) {
                const eventDateModel = await onAddModelToDate({ modelId: eventDate.modelId, eventDateId: eventDate.eventDateId, eventId: event.ID });

                if (eventDate.optionNumber) {
                    await onUpdateOptionNumber(eventDate.modelId, eventDateModel.ID, eventDate.optionNumber);
                }
            }
        } catch (error) {
            console.log('ant : Add Models to EventDate Error => ', error)
        }
    };

    const disabledModels = useMemo(() => {
        let disabledModels = [];
        if (event) {
            for (const eventDate of event.EventDates) {
                for (const model of eventDate.EventDatesModels) {
                    if (!disabledModels.find(modelId => modelId === model.ModelID)) {
                        disabledModels.push(model.ModelID);
                    }
                }
            }
        }
        return disabledModels;
    }, [event]);

    return (
        <NwDrawer
            id="add-models-drawer"
            padding="0px"
            width='80vw'
            title='Add Models To Booking Event'
            onClose={onClose}>
            <ModelContextProvider modelSelected={null}>
                <ModelsSelectorWrapper
                    addModelProps={{
                        addible: true,
                        eventData: event,
                        onAddModelsToEventDates: handleAddModelsToEventDates,
                        onAddModelToEventDates: handleModelAdd,
                    }}
                    currentEvent={event}
                    defaultView="table"
                    disabledModels={disabledModels}
                    fixedView
                    isDrawer={true}
                    layouttype="fullpage"
                    itemClickAction="preview-drawer"
                    multipleSelection={false}
                    projectId={projectId}
                    scope="event"
                    startOnProjectModels
                />
            </ModelContextProvider>
        </NwDrawer>
    );
}

export default AddModelsToBookingEvent
