import styled from "styled-components";
import { Select, Tabs } from 'antd';
import FlexContainer from "Components/Gui/FlexContainer";

export const StyledTabs = styled(Tabs)`
    overflow: unset;    
    .ant-tabs-nav {
        .ant-tabs-nav-wrap {
            &::after {
                box-shadow: none !important;
            }
            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    margin: 0 16px 0 0;

                    .ant-tabs-tab-btn {
                        text-transform: uppercase;
                        font-size: .75rem;
                    }
                }
            }
        }
    }
`;

export const StyledSelect = styled(Select)`
    width: 100%;
    margin-bottom: .5rem;
    .ant-select-selection-item {
        span.is-modified {
            font-size: .75rem;
            font-weight: bold;
            background-color: ${props => props.theme.DangerColor};
            color: #fff;
            border-radius: 4px;
            padding: 2px 4px;
            margin: 0 1em 0 0;
            vertical-align: middle;
        }
    }
`;

export const StyledSelectDrawer = styled(Select)`
    width: 400px;
    font-weight: bold;
`;

export const FiltersWrapper = styled(FlexContainer)`
    width: ${props => props.$breakpoints < 4 && '100%'};
    position: relative;

    .ant-tabs {
        width: 100%;
    }
    
    .back-to-list, .go-to-booking {
        position: absolute;
        top: .4rem;
        right: .2rem;
        cursor: pointer;
    }

    .filters-tab-content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        
        .filters-tab-content-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            @media (max-width: 1280px) {
                margin: 0 0 8px;
            }

            &.active-set-changed {
                .ant-select-selector {
                    border-color: ${props => props.theme.DangerColor};
                }
                span.unsaved-changes {
                    text-align: center;
                    font-size: ${props => props.theme.FontSizeSm};
                    color: ${props => props.theme.DangerColor};
                    padding: 0 0 4px 0;
                    text-transform: uppercase;
                    width: 100%;
                    vertical-align: middle;
                }
            }
        }
        .folders-buttons {
            margin: 16px 0 0;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
`;
