import React, { useEffect, useState } from "react";
import { Select } from "antd";
import styled from 'styled-components';

import { useGlobalConversions } from 'Hooks/Features/UseFeatures';
import { getDisplayValues } from "Libs/NWFeatures";
import { useUserGuiConfig } from "Contexts/UserGuiConfigContext";

const Option = Select.Option;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 6px;

    .selects-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        gap: 1rem;

        .ant-select {
            max-width: 14rem;
        }
    }
`;

const FeaturesRangeFilter = ({
    onChange,
    value,
    feature,
}) => {

    const { data: globalConversions } = useGlobalConversions();
    const userGuiConfig = useUserGuiConfig()
    const conversion = globalConversions && globalConversions.find(conversion => conversion.ID === feature.ConversionID);

    const metric = userGuiConfig.metrics.find(metric => metric.ID === conversion.ID);
    const metricToUse = metric ? metric.metrics[0] : conversion.AvailableMetrics.split(',')[0];
    const conversionValues = conversion ? conversion.ConversionValues.filter(conversionValue => conversionValue.Metric === metricToUse) : [];

    const [selectedValues, setSelectedValues] = useState([null,null]);

    const getNextValue = (value, direction) => {
        const currentIndex = conversionValues.findIndex(conversionValue => conversionValue.Key === Number(value));
        if (direction === 'next') {
            if (currentIndex === conversionValues.length - 1) {
                return value;
            }
            return conversionValues[currentIndex + 1].Key.toString();
        }
        if (direction === 'prev') {
            if (currentIndex === 0) {
                return value;
            }
            return conversionValues[currentIndex - 1].Key.toString();
        }
    }

    const handleChangeMin = val => {
        if (selectedValues[1] === null || Number(val) > Number(selectedValues[1])) {
            const maxVal = getNextValue(val, 'next');
            setSelectedValues([ val, maxVal ]);
        } else {
            setSelectedValues([ val, selectedValues[1] ]);
        }
    };

    const handleChangeMax = val => {
        if (selectedValues[0] === null || Number(val) < Number(selectedValues[0])) {
            const minVal = getNextValue(val, 'prev');
            setSelectedValues([ minVal, val ]);
        } else {
            setSelectedValues([ selectedValues[0], val ]);
        }
    };

    useEffect(() => {
        if (selectedValues && selectedValues[0] !== null && selectedValues[1] !== null) {
            const numericSelectedValues = selectedValues.map(val => parseInt(val));
            onChange([ ...numericSelectedValues ]);
        }
    }, [selectedValues]);

    const options = [];

    for (const conversionValue of conversion.ConversionValues) {
        if (!options.find(option => option.Key === conversionValue.Key)) {
            options.push({
                Key: conversionValue.Key,
                Metrics: conversion.ConversionValues.filter(value => value.Key === conversionValue.Key).map(conversionValue => {
                    return {
                        ...conversionValue,
                        metric: conversionValue.Metric,
                        value: conversionValue.Value
                    }
                })
            })
        }
    }

    return conversionValues.length > 0 &&
        <Wrapper>
            <div className="selects-wrapper">
                <Select
                    autoComplete='off'
                    showSearch
                    //name={feature.Name}
                    //id={item.ID}
                    style={{ width: "100%" }}
                    value={value && value.length > 0 ? value[0].toString() : null}
                    //disabled={disabled}
                    dropdownMatchSelectWidth={false}
                    onChange={handleChangeMin}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select value (min)"
                >
                    {options.map((option, index) => {
                        return (
                            <Option
                                key={`${option.Key}${index}`}
                                value={String(option.Key)}
                            >
                                {getDisplayValues(option.Metrics, conversion.Name, false, userGuiConfig, " • ", null)}
                            </Option>
                        );
                    })}
                </Select>
                <Select
                    autoComplete='off'
                    showSearch
                    style={{ width: "100%" }}
                    value={value && value.length > 0 ? value[1].toString() : null}
                    onChange={handleChangeMax}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownMatchSelectWidth={false}
                    placeholder="Select value (max)"
                >
                    {options.map((option, index) => {
                        return (
                            <Option
                                key={`${option.Key}${index}`}
                                value={String(option.Key)}
                            >
                                {getDisplayValues(option.Metrics, conversion.Name, false, userGuiConfig, " • ", null)}
                            </Option>
                        );
                    })}
                </Select>
            </div>
        </Wrapper>
};

export default FeaturesRangeFilter;
