import React from "react";
import { Formik, Field } from "formik";

import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NWAddressSelector } from "Components/Gui/NwForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { validateAddress } from "Libs/NwUtils";

import {useEditContactConnectionItem, useAddContactConnectionItem} from 'Hooks/Contact/UseContactConnections';

const AddressForm = ({ address, connectionID, contactID, onCancel, onSave, onTouchForm }) => {
  const initialValues = {
    Address: null
  };

  const { mutateAsync: editAddress } = useEditContactConnectionItem() 
  const { mutateAsync: addAddress } = useAddContactConnectionItem()

  if (address) {
    initialValues.ID = address.ID;
    initialValues.Address = address;
  }

  const submitForm = async (values, setSubmitting) => {
    const ID = initialValues.ID;
    const data = {
      ConnectionID: connectionID,
      ...values.Address
    };

    if (ID) {
      data.ID = ID
      await editAddress({itemType:"addresses", data: data, contactID: contactID})
      if (onTouchForm) {
          onTouchForm(false);
      }
      setSubmitting(false)
      onSave()
    } else {
      await addAddress({itemType:"addresses", data: data, contactID: contactID})
      if (onTouchForm) {
        onTouchForm(false);
      }
      setSubmitting(false)
      onSave()
    }
  };

  return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          submitForm(values, setSubmitting);
        }}
        render={({ isSubmitting, values, handleSubmit, setFieldValue }) => (
          <NwForm 
            values={values}
            onTouchForm={onTouchForm}
            noValidate 
            onFinish={handleSubmit} 
            layout="vertical">
            <Field
                name='Address'
                component={NWAddressSelector}
                value={values.Address}
                validate={validateAddress} />
            <NwFormButtonsBar
                left={
                    <NwCancelButton
                        disabled={isSubmitting}
                        onClick={() => {
                            onTouchForm(false);
                            onCancel();
                        }}
                    />
                }
                right={
                    <NwSaveButton
                        htmlType="submit"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                    />
                }
            />
          </NwForm>
        )}
      />
  );
};

export default AddressForm;
