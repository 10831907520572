import React, { useRef } from 'react'
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";
import { Loading } from 'Components/Gui/Loading';
import AgendaDatePicker from './AgendaDatePicker'
import AgendaHeader from './AgendaHeader'
import AgendaEvent from './AgendaEvent'
import { getMonthRangeDates, getWeekRangeDates } from '../ScheduleUtils';
import EmptyState from 'Components/Gui/EmptyState';
import { useAgendaContext } from './AgendaContext';
import { after } from 'lodash';

const EmptyStateContainer = styled.div`
    height: ${props => props.$isModelPage ? '384px' : props.$isBooking ? 'calc(100vh - 300px)' : '304px'};
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

`;

const BookingAgendaContainer = styled.div`
    padding: 0 16px;
    position: relative;

    .agenda-container-body {
        display: flex;
        
        .agenda-container-left {
            max-width: 280px;
        }
        
        .agenda-container-right {
            flex: 1 0 0;
            padding-left: 1rem;
            padding-top: .5rem;

            .events-list-container {
                padding-left: 1rem;
                height: calc(100vh - 250px);
                overflow: auto;

                &.model-selected {
                    height: calc(100vh - 300px);
                }
            }
        }
    }
`;

const ContactAgendaContainer = styled.div`
    padding: 0 .5rem;
    position: relative;
    display: flex;

    .agenda-container-left {
        max-width: 20rem;
    }
    
    .agenda-container-right {
        flex: 1 0 0;
        padding-left: 2rem;

        .events-list-container {
            padding-left: 1rem;
            height: 304px;
            overflow: auto;

            &.model {
                height: 434px;
            }
        }

    }
`;

const AgendaContainer = () => {

    const {
        contactId,
        bookingSection,
        eventsToDisplay,
        isLoadingDay,
        isLoadingMonth,
        onScheduleRangeChange,
        scheduleRange,
        type,
    } = useAgendaContext();

    const eventsScroller = useRef()

    const handleChangeCalendarStyle = (view) => {
        const startdate = getMoment(scheduleRange.start)
        switch (view) {
            case "month":
                const monthdates = getMonthRangeDates(startdate)
                onScheduleRangeChange(monthdates.start, monthdates.end)
                break
            case "week":
                const weekdates = getWeekRangeDates(startdate)
                onScheduleRangeChange(weekdates.start, weekdates.end)
                break
            default:
                console.log("stay in agenda mode")
        }
    }

    const afterDateSelection = () => {
        if (eventsScroller.current) {
            eventsScroller.current.scrollTo(0, 0)
        }
    }

    return (
        (isLoadingMonth
            ?
                <Loading paddingTop="6rem" textBlack />
            :
            <>
                {bookingSection
                    ?
                    <BookingAgendaContainer>
                        <div className="agenda-container-header">
                            <AgendaHeader
                                onChangeCalendarStyle={handleChangeCalendarStyle}
                            />
                        </div>
                        <div className="agenda-container-body">
                            <div className="agenda-container-left">
                                <AgendaDatePicker afterDateSelection={afterDateSelection} />
                            </div>
                            <div className="agenda-container-right">
                                {isLoadingDay
                                    ?
                                    <Loading small paddingTop="6rem" textBlack />
                                    :
                                    ((eventsToDisplay && eventsToDisplay.length)
                                        ?
                                        <div className={`events-list-container ${(contactId !== null) ? 'model-selected' : ''}`} ref=
                                        {eventsScroller}>
                                            {eventsToDisplay.map((ev, index) => (
                                                <AgendaEvent
                                                    key={`${ev.TypeName}-${ev.ID}-${index}`}
                                                    event={ev}
                                                />
                                            ))}
                                        </div>
                                        :
                                        <EmptyStateContainer>
                                            <EmptyState message="no events" />
                                        </EmptyStateContainer>
                                    )}
                            </div>
                        </div>
                    </BookingAgendaContainer>
                    :
                    <ContactAgendaContainer>
                        <div className="agenda-container-left">
                            <AgendaDatePicker />
                        </div>
                        <div className="agenda-container-right">
                            <AgendaHeader
                                onChangeCalendarStyle={handleChangeCalendarStyle}
                            />
                            {isLoadingDay
                                ?
                                <Loading />
                                :
                                ((eventsToDisplay && eventsToDisplay.length)
                                    ?
                                    <div className={`events-list-container ${(type === 'model') ? 'model' : ''}`}>
                                        {eventsToDisplay.map((ev, index) => (
                                            <AgendaEvent
                                                key={`${ev.TypeName}-${ev.ID}-${index}`}
                                                event={ev}
                                            />
                                        ))}
                                    </div>
                                    :
                                    <EmptyStateContainer>
                                        <EmptyState message="no events" />
                                    </EmptyStateContainer>
                                )
                            }
                        </div>
                    </ContactAgendaContainer>
                }
            </>
        )
    )
}

export default AgendaContainer;
