import styled from 'styled-components';

const CalendarWrapper = styled.div`
    position: relative;
    /* General Calendar Component - override on styles */
    .rbc-calendar {
        min-height: ${props => props.minHeight ? props.minHeight : 'calc(100vh - 16rem)'};
        .rbc-time-view {
            border: none;

            //background color for today's date
            .rbc-today {
                background-color: #eaf6ff !important;
            }

            .rbc-time-header {
                position: sticky;
                top: 8rem;
                background-color: #fff;
                z-index: 1002;

                .rbc-time-header-content {
                    border-left: none;
                    .rbc-row.rbc-time-header-cell {
                        background: white;
                        border-top: ${props => props.theme.DefaultBorder};
                        .rbc-header {
                            background: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            padding: .5em 0;
                            text-transform: uppercase;
                            
                            > span {
                                width: 100%;

                                span.week-column-header, span.month-column-header {
                                    width: 100%;
                                    padding: 0 1rem;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    
                                    .calendar-header-date {
                                        padding: .15rem 0;
                                        &.calendar-header-date-clickable {
                                            cursor: pointer;
                                        }
                                    }   

                                    .calendar-header-icons-wrapper {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        gap: 1rem;
                                    }
                                }
                            }

                            

                            &.rbc-today {
                                background-color: ${props => props.theme.PrimaryColor} !important;
                                color: #fff !important;
                                border-color: ${props => props.theme.PrimaryColor} !important;
                            }
                        }
                    }

                    .rbc-allday-cell {
                        .rbc-row {
                            min-height: 2.75rem;
                            .rbc-event {
                                .rbc-event-content {
                                    height: auto;
                                    min-height: 0;
                                    .event {
                                        height: 2.6rem;
                                        overflow:hidden;
                                
                                        margin-top: 2px;
                                        border-radius: 2px !important;
                                        
                                        .event-content {
                                            min-height: 0;
                                            padding: 0 0 .4rem .3rem;
                                            overflow: hidden;
                                            margin-right: .3rem;
                                            
                                            .event-content-top {
                                                padding: .2rem .1rem 0 0;
                                                div {
                                                    font-size: .6rem;
                                                    span {
                                                        display: block;
                                                    }
                                                }
                                                
                                            }
                                            .event-title {
                                                font-size: .8rem;
                                                line-height: 1.1em;
                                                height: .95rem;
                                                padding-top: .1rem;
                                                svg {
                                                    font-size: .75rem;
                                                }
                                            }
                                            .event-subtitle {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .rbc-row-bg {
                            .rbc-day-bg + .rbc-day-bg {
                                border: none;
                            }
                            .rbc-day-bg + .rbc-today {
                                background-color: white !important;
                            }
                        }
                    }
                }
            }
            .rbc-time-content {

                .rbc-day-slot {
                    .rbc-timeslot-group {
                            border-bottom: 1px solid rgba(221, 221, 221, 0.3);
                    }
                }

                .rbc-events-container {
                    .rbc-event {

                        .rbc-event-label {
                            /* font-weight: bold; */
                            /* ant touch */
                            display: none;
                            /* ant touch */
                        }

                        //separate cancelled events with a text line
                        &.cancelled {
                            opacity: .5;
                            ::before {
                                content: 'cancelled events';
                                display: block;
                                text-transform: uppercase;
                                font-size: .6rem;
                                color: ${props => props.theme.TextColor};
                                padding-bottom: .25rem;
                                padding-top: 1rem;
                            }
                        }
                        &.cancelled ~ .cancelled {
                            margin-top: 2px;
                            ::before {
                                content: '';
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .rbc-month-view {
            background: white;
            overflow: auto;
            margin-bottom: 5rem;


            .rbc-month-row {
                overflow: unset;

                .rbc-row-bg {

                }
                .rbc-row-content {
                    min-height: 10rem;
                    
                    .rbc-row {
                        .rbc-row-segment {
                            .rbc-event {
                                //separate cancelled events with a text line
                                &.cancelled {
                                    opacity: .5;
                                }
                            }
                        }
                        .rbc-date-cell {
                                
                            span.month-column-header {
                                width: 100%;
                                padding: 0 1rem;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                border-bottom: ${props => props.theme.DefaultBorder};
                                font-weight: bold;
                                
                                .calendar-header-date {
                                    padding: .15rem 0;
                                    &.calendar-header-date-clickable {
                                        cursor: pointer;
                                    }
                                }   

                                .calendar-header-icons-wrapper {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    gap: 1rem;
                                }
                            }
                        }
                    }
                }
            }
            
            .rbc-month-header {
                position: sticky;
                top: 0;
                z-index: 5;
                background: white;
            }
        }
    }

    .container {
        .rbc-calendar {
            min-height: calc(100vh - 16rem);
        }
    }

    &.nonbooking-collapsed {
        .rbc-calendar {
            .rbc-time-view {
                .rbc-time-header {
                    /* position: sticky;
                    top: 8rem;
                    background-color: #fff;
                    z-index: 1002; */
                    .rbc-time-header-content {
                        .rbc-allday-cell {
                            overflow: hidden;
                            height: 4rem;
                            .rbc-row {
                                min-height:.45rem;
                                .rbc-event {
                                    .rbc-event-content {
                                        .event {
                                            height: .3rem;
                                            .event-content {
                                                height: .3rem;
                                                min-height: .3rem;
                                                padding: 0;
                                                overflow: hidden;
                                                .event-content-top, .event-title, .event-subtitle {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.booking-collapsed {
        .rbc-time-content {
            .rbc-day-slot {
                .rbc-events-container {
                    .rbc-event {
                        margin-top: 2px;
                        border-radius: 0 !important;

                        &:first-child {
                            margin-top: 5px;
                        }

                        .rbc-event-content {
                            height: auto;
                            min-height: 0;
                            .event {
                                border-radius: 2px !important;
                                .event-content {
                                    min-height: 0;
                                    padding: 0 0 .4rem .3rem;
                                    overflow: hidden;
                                    margin-right: .3rem;
                                    
                                    .event-content-top {
                                        padding: .2rem .1rem 0 0;
                                        div {
                                            font-size: .6rem;
                                            span {
                                                display: block;
                                            }
                                        }
                                    }
                                    .event-title {
                                        font-size: .8rem;
                                        line-height: 1.1em;
                                        height: .95rem;
                                        width: 20rem;
                                        padding-top: .1rem;
                                        svg {
                                            font-size: .75rem;
                                        }
                                    }
                                    .event-subtitle {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.nonbooking-expanded {
        .rbc-calendar {
            .rbc-time-view {
                .rbc-time-header {
                    position: relative;
                    top: auto;
                    z-index: auto;
                    //these are on top as default styles
                    /* .rbc-time-header-content {
                        .rbc-allday-cell {
                            .rbc-row {
                                min-height: 2.75rem;
                                .rbc-event {
                                    .rbc-event-content {
                                        height: auto;
                                        min-height: 0;
                                        .event {
                                            height: 2.6rem;
                                            overflow:hidden;
                                    
                                            margin-top: 2px;
                                            border-radius: 2px !important;
                                            
                                            .event-content {
                                                min-height: 0;
                                                padding: 0 0 .4rem .3rem;
                                                overflow: hidden;
                                                margin-right: .3rem;
                                                
                                                .event-content-top {
                                                    padding: .2rem .1rem 0 0;
                                                    small {
                                                        font-size: .6rem;
                                                    }
                                                }
                                                .event-title {
                                                    font-size: .8rem;
                                                    line-height: 1.1em;
                                                    height: .95rem;
                                                    padding-top: .1rem;
                                                    svg {
                                                        font-size: .75rem;
                                                    }
                                                }
                                                .event-subtitle {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } */
                }
            }
        }
    }
    
    .fixed-loading-container {
        width: 100%;
        position: absolute;
        z-index: 9999;
        top: 20rem;
        text-align: center;
    }

    .general-calendar {
        position: relative;
        padding-bottom: 1em;
        width: 100%;
        transition: all 300ms ease-out;
        background: white;

        .calendar {
            width: 100%;
            transition: all 300ms ease-out;

            &.calendar-custom-week {
                .rbc-label {
                    display: none;
                }
                .rbc-time-view {
                    border-left: none; 
                    border-right: ${props => props.theme.DefaultBorder};
                    .rbc-time-header {
                        .rbc-time-header-content {
                            .rbc-allday-cell {
                                border-left: ${props => props.theme.DefaultBorder};
                                border-right: ${props => props.theme.DefaultBorder};
                                padding-bottom: .75rem;

                                .rbc-row-content {
                                    .rbc-row {
                                        .rbc-row-segment {
                                            padding: 3px 1px 0;
                                        }
                                    }
                                }
                            }
                        }
                        .rbc-overflowing {
                            border: none;
                            background: #f7f7f7; 
                        }
                    }
                    
                    .rbc-time-content {
                        background: white;
                        border-top: ${props => props.theme.DefaultBorder};
                        overflow-y: visible;

                        .rbc-time-gutter {
                            display: none;
                        }

                        .rbc-day-slot {
                            padding-top: 0;
                            height: 400vh;

                            .rbc-timeslot-group {
                                display: none;
                            }

                            .rbc-events-container {
                                margin: 0;
                                padding: 0 3px;

                                .rbc-event {
                                    position: static;
                                    width: 100% !important;
                                    height: auto !important;
                                    margin-top: .3rem;
                                    &:first-child {
                                        margin-top: 0.5em; 
                                    }
                                    &.rbc-selected {
                                        background-color: transparent;
                                        color: #ddd; 
                                    }

                                    .rbc-event-label {
                                        margin-bottom: 0.75em; 
                                    }
                                }
                            }
                        }

                        .rbc-current-time-indicator {
                            display: none !important;
                        }
                    }
                }
                
            }
        }

        &.daily-drawer {
            .rbc-calendar {
                .rbc-time-view {
                    .rbc-time-header {
                        box-shadow: 0px 3px 15px rgba(0,0,0,0.4);
                        top: 0;
                        /* padding-top: 5rem; */
                        .rbc-time-header-content {
                            .rbc-allday-cell {
                                .rbc-row {
                                    min-height: 4rem;
                                    .rbc-event {
                                        .rbc-event-content {
                                            .event {
                                                height: 3.8rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .rbc-time-content {
                        overflow-y: visible;
                        max-height: none;

                        .rbc-time-gutter {
                            min-width: 6rem;
                        }

                        .rbc-time-column {
                            .rbc-timeslot-group {
                                min-height: 60px;
                                .rbc-time-slot {
                                    .rbc-label {
                                        font-size: 1.1rem;
                                        font-weight: 600;
                                        display: block;
                                        padding: .25rem .5rem;
                                        line-height: 1em;
                                    }
                                }
                            }
                        }

                        .rbc-events-container {
                            .rbc-event {
                                min-height: 4rem;
                                box-shadow: 0px 3px 15px rgba(0,0,0,0.4);
                                border-radius: .5rem;
                                        
                                .rbc-event-content {
                                    .event {
                                        border: none !important;
                                        
                                    }
                                }

                                &.cancelled {
                                    opacity: .6;
                                    ::before {
                                        content: '';
                                        display: none;
                                        padding-bottom: 0;
                                        padding-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.model-calendar {
            .rbc-calendar {
                .rbc-time-view {

                    .rbc-time-header {
                        .rbc-time-header-content {
                            .rbc-allday-cell {
                                .rbc-row {
                                    min-height: 3.5rem;
                                    .rbc-event {
                                        .rbc-event-content {
                                            .event {
                                                height: 3.25rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .rbc-time-content {
                        .rbc-events-container {
                            .rbc-event {
                                &.cancelled {
                                    ::before {
                                        content: '';
                                        display: none;
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    

    
    .loading-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 4; 
    }


    .rbc-event {
        padding: 0.3em;
        border-radius: 4px;
        border: none !important;
        transition: all 300ms ease-out;
        /* height: 6rem; */
        overflow: hidden;
        background-color: transparent;
        /* ant touch */
        padding: 0;
        font-size: 0.95rem; 

        &.event-fade {
            opacity: 0.4 !important;
        }

        .event {
            border-left: 1px solid #fff;
            &.event-first-day {
                border-top-left-radius: .5rem;
                border-bottom-left-radius: .5rem; 
            }

            &.event-last-day {
                border-top-right-radius: .5rem;
                border-bottom-right-radius: .5rem;
            }
        }
    }
`;

export default CalendarWrapper;


/* .general-calendar .calendar-custom-week .rbc-label,
    .general-calendar .calendar-custom-week .rbc-time-view .rbc-time-gutter {
        display: none;
    } */
    /* .general-calendar .calendar-custom-week .rbc-time-content {
        background: white;
        border-top: ${props => props.theme.DefaultBorder};
        overflow-y: visible;
    } */
    
    /* .general-calendar .calendar-custom-week .rbc-day-slot {
        padding-top: 0; 
    }
    .general-calendar .calendar-custom-week .rbc-day-slot .rbc-event-label {
        margin-bottom: 0.75em; 
    }
    .general-calendar .calendar-custom-week .rbc-day-slot .rbc-event {
        position: static;
        width: 100% !important;
        height: auto !important;
        margin-top: .3rem; 
    } */
    /* .general-calendar .calendar-custom-week .rbc-day-slot .rbc-event:first-child {
        margin-top: 0.5em; 
    }
    .general-calendar .calendar-custom-week .rbc-day-slot .rbc-events-container {
        margin: 0;
        padding: 0 3px; 
    } */
    /* .general-calendar .calendar-custom-week .rbc-row-segment {
        padding: 3px 1px 0; }
    .general-calendar .calendar-custom-week .rbc-timeslot-group {
        border-bottom: 1px solid rgba(221, 221, 221, 0.3); } */
    /* .general-calendar .calendar-custom-week .rbc-time-header.rbc-overflowing {
        border: none;
        background: #f7f7f7; } */
    /* .general-calendar .calendar-custom-week .rbc-allday-cell {
        //overflow-y: auto;
        border-left: ${props => props.theme.DefaultBorder};
        border-right: ${props => props.theme.DefaultBorder};
        padding-bottom: .75rem;
    } */
    /* .general-calendar .calendar-custom-week .rbc-time-view {
        border-left: none; 
    } */
    /* .general-calendar .calendar-custom-week .rbc-event.rbc-selected {
        background-color: transparent;
        color: #ddd; 
    } */

    /* .rbc-addons-dnd.rbc-addons-dnd-is-dragging
    .rbc-event:not(.rbc-addons-dnd-dragged-event):not(.rbc-addons-dnd-drag-preview) {
        opacity: 0.3 !important; }

    .rbc-addons-dnd .rbc-addons-dnd-row-body {
        position: relative; }

    .rbc-addons-dnd .rbc-addons-dnd-drag-row {
        position: absolute;
        top: 0;
        left: 0;
        right: 0; }

    .rbc-addons-dnd .rbc-addons-dnd-over {
        background-color: rgba(0, 0, 0, 0.3); }

    .rbc-addons-dnd .rbc-addons-dnd-dragged-event {
        background: #fff !important;
        border: 1px dashed #111 !important; }

    .rbc-addons-dnd .rbc-event {
        -webkit-transition: opacity 150ms;
        transition: opacity 150ms; } */

// .event-between-day {
//     border-radius: 0 !important;    
// }


    /* .rbc-header {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: .5em 0;
        text-transform: uppercase;
    } */
        

    /* .type-empty {
        background: #111 !important;
        visibility: hidden; 
    } */

    /* .rbc-event.type-no-job {
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        color: #111;
        margin-left: -0.1em;
        margin-right: -0.1em;
        width: calc(100% + 0.2em);
        z-index: -1;
        border-radius: 0;
        border: 0;
        margin-top: 0; 
    }
    
    .type-no-job .rbc-event-content {
        display: none; 
    } */