import React, { useContext } from "react";
import { Select } from "antd";
import { ListContext } from "Contexts/ListContext";
import { sortTags, mapTags } from "Libs/NwUtils";

const Option = Select.Option;

const InputTag = ({ contactType, onChange, value, disabled }) => {

    const { cachedList } = useContext(ListContext);
    const cachedtags = cachedList.publictags.items.concat(cachedList.privatetags.items);
    const allTags = sortTags(mapTags(cachedtags, contactType));

    const handleChange = values => {
        onChange(values);
    };

    return (
        <Select
            autoComplete='off'
            mode="multiple"
            value={value} //getNameValues(value)
            placeholder="Select one or more tags"
            onChange={handleChange}
            disabled={disabled}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            style={{ width: "100%" }}
            filterOption={(input, option) => {
                if (option && option.label) {
                    return option.label.toLowerCase().includes(input.toLowerCase());
                }
            }}
            options={allTags.map(el => ({ label: el.Name, value: el.ID }))}
        />
            
    );
};

export default InputTag;
