import React, { useEffect } from 'react';
import styled from 'styled-components';
import StyleVariables from './StyleVariables';
import { useStateValue } from 'Contexts/StateProvider';
import NwIcon from 'Components/Gui/NwIcon';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';


const LoadingContainer = styled.div`
    font-size: ${props => props.$small ? '1.5rem' : '3rem'};
    color: ${props => props.$textBlack ? 'inherit' : '#fff'};
    width: 100%;
    height: ${props => props.$fullHeight ? '100vh' : 'auto'};
    padding: ${props => props.$paddingTop ? props.$paddingTop : '0'} 0 0 0;
    margin: 0;
    opacity:.5;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-family: 'Chivo Mono', monospace;
        text-transform: uppercase;
        font-weight: 800;
        margin: 0;
        padding: ${props => props.$small ? '0 0 0 .5rem' : '0 0 0 1.5rem'};
        line-height: 1em;
        font-size: ${props => props.$small ? '2rem' : '5rem'};
    }
`

const RouteLoadingContainer = styled.span`
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: ${StyleVariables.PrimaryColor};
    color: #fff;
    font-size: .8rem;
    text-transform: uppercase;
    padding: .5em 2em;
`

const RouteLoading = () => {
    const [, dispatch] = useStateValue();
    useEffect (() => {
        if (dispatch) {
            dispatch({
                type: "UpdatePageStatus",
                status: false
            });
            return () => {
                dispatch({
                    type: "UpdatePageStatus",
                    status: true
                });
            }
        }
    }, [dispatch]);
    return (
        <RouteLoadingContainer>Loading Page...</RouteLoadingContainer>
    )
}

const Loading = ({ text = "loading", textBlack, small, paddingTop, fullHeight }) => {
    return (
        <LoadingContainer $textBlack={textBlack} $small={small} $fullHeight={fullHeight} $paddingTop={paddingTop}>
            <NwIcon icon={regular("circle-notch")} spin={true} />
            <p>{text}</p>
        </LoadingContainer>
    )
};

export { Loading, RouteLoading }