import React, { useEffect, useMemo, useState } from "react";
import { useMailingListContext } from "Components/MailingLists/MailingListContext";
import { NwLinkButton } from "Components/Gui/NwButton";
import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import NwIcon from "Components/Gui/NwIcon";
import StyleVariables from "Components/Gui/StyleVariables";
import Axios from "axios";
import styled from "styled-components";


const Wrapper = styled.div`
    small {
        color: ${props => props.theme.TextColorMuted};
        text-transform: uppercase;
        font-size: ${StyleVariables.FontSizeSmall};
        display: block;
    }

    p {
        margin: 0;
        color: ${props => props.theme.TextColorMuted};
    }

    .other-emails {
        margin-top: 1rem;
    }
`

const ModelsTableColumnEmails = ({ model }) => {

    const { mailingListEmails, onAddItem } = useMailingListContext();

    const [otherEmails, setOtherEmails] = useState([]);
    const [lookingForOtherEmails, setLookingForOtherEmails] = useState(null);
    const [currentEmails, setCurrentEmails] = useState([...mailingListEmails]);

    // useEffect(() => {
    //     setCurrentEmails([...mailingListEmails]);
    // }, [mailingListEmails])

    const loadAddresses = async () => {
        setLookingForOtherEmails("loading");
        const emails = await Axios.post(`/contacts/connections/emails`, { ContactIDs: [model.ID] });
        if (emails && emails.length > 0) {
            setOtherEmails(emails.filter(e => e.EmailID !== model.EI && e.Email && model.E && e.Email.toLowerCase() !== model.E.toLowerCase()));
        }
        setLookingForOtherEmails(true);
    }

    const handleAddEmail = (emailID, emailAddress) => {
        setCurrentEmails([...currentEmails, emailAddress]);
        onAddItem({ EmailID: emailID })
    }

    return (
        <Wrapper>
            <small>default e-mail</small>
            {model.E
                ?
                (currentEmails.includes(model.E)
                    ?
                    <NwLinkButton
                        primary
                        disabled
                        label={model.E}
                        onClick={() => { }}
                        icon={solid("check")}
                    />
                    :
                    <NwLinkButton
                        primary
                        label={model.E}
                        onClick={() => handleAddEmail(model.EI, model.E)}
                        icon={solid("circle-plus")}
                    />
                )
                :
                <p>
                    <NwIcon style={{ color: StyleVariables.DangerColor }} icon={light("ban")} />
                    &nbsp;
                    no default e-mail set
                </p>
            }
            <div className="other-emails">
                {lookingForOtherEmails
                    ?
                    (lookingForOtherEmails === "loading"
                        ?
                        <p>Loading...</p>
                        :
                        <div className="other-emails-list">
                            {otherEmails.length === 0
                                ? <p>No other e-mails found</p>
                                : otherEmails.map(email => (
                                    <div className="other-emails-item" key={email.EmailID}>
                                        {currentEmails.includes(email.Email)
                                            ?
                                            <NwLinkButton
                                                disabled
                                                label={email.Email}
                                                onClick={() => { }}
                                                icon={solid("check")}
                                            />
                                            :
                                            <NwLinkButton
                                                primary
                                                label={email.Email}
                                                onClick={() => handleAddEmail(email.EmailID, email.Email)}
                                                icon={solid("circle-plus")}
                                            />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    )
                    :
                    <NwLinkButton
                        //ghost
                        black
                        icon={light("envelopes")}
                        label="Show all e-mails"
                        onClick={loadAddresses}
                    />
                }
            </div>
        </Wrapper>
    )
}

export default ModelsTableColumnEmails;