import React from 'react';
import styled from 'styled-components';

import { NwButton } from 'Components/Gui/NwButton';
import { FilterDrawerAction } from 'Components/Filters/FilterForm/FilterFormUI';
import ContactsFilterDrawerSections from './ContactsFilterDrawerSections';
import { useContactsSelectorDispatch } from 'Components/ContactsSelector/Common/SelectorContext';


const FormWrapper = styled.div`
    margin-bottom: 6rem;
`;

const ContactsFilterDrawer = ({}) => {
    
    const cs_dispatch = useContactsSelectorDispatch()
    const onSearchClick = () => {
        cs_dispatch({type: 'ApplyWorkingFilters'})
    }

    return (
        <FormWrapper>
            <ContactsFilterDrawerSections />
            <FilterDrawerAction fullWidth justifyContent='center'>
                <NwButton
                    primary
                    label='Search'
                    onClick={onSearchClick} 
                />
            </FilterDrawerAction>
        </FormWrapper>
    )
};

export default ContactsFilterDrawer;