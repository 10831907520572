import React, { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import NwDrawer from "Components/Gui/NwDrawer";
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { NWInput, NWColorSelector, NWRadioButtonGroup } from "Components/Gui/NwForm/NwFormItems";
import {GetTimestampNumeric} from "Libs/NwMoment";
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';
import { useFoldersDispatch } from "Contexts/FoldersContext";

const FolderDrawer = ({ folder, type, onClose, items, onCreated }) => {
    
    const [touchForm, onTouchForm] = useState(false);
    useFormFieldFocus('Name');
    
    const folders_dispatch = useFoldersDispatch()
    
    const initialValues = {
        Name: '',
        Color: 'pink',
        Type: type || 'model'
    };

    if (folder) {
        initialValues.Name = folder.title;
        initialValues.Color = folder.color;
    }

    let validationSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required"),
        Color: Yup.string().required("Color is required"),
    });

    const submitForm = (values) => {
        try {    
            if (folder) {
                folders_dispatch({
                    type: 'EditFolder',
                    folder: {
                        folderID: folder.ID,
                        folderName: values.Name,
                        color: values.Color,
                        type: values.Type
                    }
                });
                onTouchForm(false);
                onClose();
            } else {
                const id = GetTimestampNumeric();
                folders_dispatch({
                    type: 'CreateFolder',
                    newFolder: {
                        ID: id,
                        title: values.Name,
                        color: values.Color,
                        type: values.Type,
                        items: items ? items : []
                    }
                });
                onTouchForm(false);
                onCreated(id, values.Name);
                onClose();
            }
        } catch (error) {
            console.log('ant : Update Folder Error => ', error);
        }
    };


    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={onTouchForm}
            onClose={onClose}
            title={folder ? 'Edit folder' : "Create a new folder"}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setStatus, setSubmitting,resetForm }) => {
                    setStatus();
                    submitForm(values, setStatus, setSubmitting);
                }}
            >
                {({ isSubmitting, values, handleSubmit }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={onTouchForm}
                        onFinish={handleSubmit} 
                        layout="vertical">
                        <Field
                            component={NWInput}
                            label="Name"
                            name="Name"
                            type="text"
                            required
                            id='Name'
                            value={values.Name}
                        />
                        <Field
                            component={NWRadioButtonGroup}
                            name="Type"
                            disabled={folder ? true : false}
                            value={values.Type}
                            buttonStyle="solid"
                            options={[
                                { key: 'model', value: 'model', label: 'Model' },
                                { key: 'customer', value: 'customer', label: 'Customer' }
                            ]}
                        />
                        <Field
                            component={NWColorSelector}
                            label="Color"
                            name="Color"
                            required
                            value={values.Color}
                        />
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        onTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    );
};
 
export default FolderDrawer;
