import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { thin } from '@fortawesome/fontawesome-svg-core/import.macro';

import { ModelCover } from 'Components/ModelCover';
import NwIcon from 'Components/Gui/NwIcon';
import ConnectionDrawer from 'Components/Connection/ConnectionDrawer';
import { PhoneMagnifier, MailLink } from "Components/Gui/NwContactInfo";
import { useModelsList } from 'Hooks/Contact/UseContactsList';
import ModelDrawer from 'Components/ContactDetails/ModelDetails/Drawer/ModelDrawer';
import CustomerDrawer from 'Components/ContactDetails/CustomerDetails/Drawer/CustomerDrawer';


const Wrapper = styled.div`
    display: flex;
    min-width: 13rem;
    white-space: nowrap;
    
    .birthday-cover {
        margin: 0 1rem;
        cursor: pointer;

        svg {
            font-size: ${props => props.$small ? '3rem' : '6rem'};
            opacity: .5;
        }
    }

    .birthday-cover-icon {
        margin: 0 1rem;
        cursor: pointer;
        background-color: ${props => props.theme.PrimaryColor};
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        color: #fff;
        svg {
            font-size: ${props => props.$small ? '3rem' : '6rem'};
            opacity: 1;
        }
    }

    .birthday-details {
        width: 100%;

        h2 {
            font-size: ${props => props.$small ? props.theme.FontSizeSm : props.theme.FontSizeBase};
            font-weight: bold;
            text-transform: capitalize;
            margin: 0 0 1.5rem 0;
            line-height: 1.1em;

            &.no-margin {
                margin: 0;
            }
        }
        h3 {
            font-size: ${props => props.$small ? props.theme.FontSizeSm : props.theme.FontSizeBase};
            font-weight: normal;
            text-transform: capitalize;
            margin: 0 0 .25rem 0;
        }
        .birthday-connections {
            line-height: 1.1em;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            margin-top: .25rem;
            font-size: ${props => props.$small ? props.theme.FontSizeSm : props.theme.FontSizeBase};
        }
        p {
            line-height: 1.1em;
            font-size: ${props => props.$small ? props.theme.FontSizeSm : props.theme.FontSizeBase};
            text-transform: lowercase;
            margin: 0 0 .5rem 0;
        }

        .connection-button {
            display: flex;
            justify-content: flex-end;
        }
    }
`;

const BirthdayItem = ({ birthday, small }) => {
    const { data: fullList } = useModelsList();

    const [openDrawer, setOpenDrawer] = useState(false);

    const isConnection = birthday.Type === 'Connection'
    const isModel = !isConnection && birthday.ContactType === 'Model'

    const model = useMemo(() => {
        let m = null
        if (isModel && fullList) {
            m = fullList.find(model => model.ID === birthday.ID)
        }
        return m
    }, [fullList, birthday, isModel])

    return (
        <>
            {(isModel && model)
                ?
                <Wrapper $small={small}>
                    <div className="birthday-cover" onClick={() => setOpenDrawer(true)}>
                        {small
                            ?
                            <ModelCover maxHeight={100} model={model} />
                            :
                            <ModelCover maxWidth={120} model={model} />
                        }
                    </div>
                    <div className="birthday-details">
                        <div onClick={() => setOpenDrawer(true)}>
                            <h2>{birthday.Name} {birthday.Surname}</h2>
                        </div>
                        <p>{`${birthday.Age} years`}</p>
                        <div className="birthday-connections">
                            {birthday.DefaultConnection && birthday.DefaultConnection.Mobile &&
                                <PhoneMagnifier linkText="call" phone={birthday.DefaultConnection.Mobile} />
                            }
                            {birthday.DefaultConnection && birthday.DefaultConnection.Email &&
                                <MailLink linkText="e-mail" email={birthday.DefaultConnection.Email} />
                            }
                        </div>
                    </div>
                </Wrapper>
                :
                <Wrapper $small={small}>
                    <div className="birthday-cover-icon" onClick={() => setOpenDrawer(true)}>
                        {birthday.ContactSubType === 'Client' || birthday.ContactSubType === 'Service' ?
                            <NwIcon icon={thin("address-card")} />
                            : <NwIcon icon={thin("globe")} />
                        }
                    </div>
                    <div className="birthday-details">
                        <div onClick={() => setOpenDrawer(true)}>
                            <h2 className="no-margin">{`${birthday.Name} ${birthday.Surname ? birthday.Surname : ''}`}</h2>
                        </div>
                        {isConnection &&
                            <h3>{`${birthday.ContactName} - ${birthday.ConnectionRole}`}</h3>
                        }
                        <p>{`${birthday.Age} years`}</p>
                        <div className="birthday-connections">
                            {birthday.Phones && birthday.Phones.length > 0 &&
                                <PhoneMagnifier key={birthday.Phones[0].ID} phone={birthday.Phones[0].Number} linkText="call" />
                            }
                            {birthday.Emails && birthday.Emails.length > 0 &&
                                <MailLink key={birthday.Emails[0].ID} email={birthday.Emails[0].Address} linkText="e-mail" />
                            }
                        </div>
                    </div>
                </Wrapper>
            }
            {openDrawer &&
                (isConnection
                    ?
                    <ConnectionDrawer connectionId={birthday.ConnectionID} onClose={() => setOpenDrawer(false)} />
                    :
                    (isModel
                        ?
                        <ModelDrawer modelID={birthday.ID} onClose={() => setOpenDrawer(false)} />
                        :
                        <CustomerDrawer customerID={birthday.ID} onClose={() => setOpenDrawer(false)} />
                    )
                )
            }
        </>
    )
}

export default BirthdayItem;