
import React from "react";
import styled from 'styled-components';
import { getModelAge, isBirthday, isMinor } from "Libs/NwMoment";
import NwIcon from "Components/Gui/NwIcon";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NwTag } from "Components/Gui/NwTag";

const ModelAgeContainer = styled.div`
    font-weight: ${(props) => (props.$isBold ? "bold" : "normal")};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    text-transform: uppercase;

    @media only screen and (max-width: 720px) {
        font-size: .75rem;
    }

    .icon-container {
        width: .75rem;
        text-align: center;
    }

    p {
        margin: 0;
    }

    small {
        font-weight: normal;
        font-size: .75rem;
    }
`

const ModelDetailsAge = ({ birthday, nofixedspace }) => {

    if (!birthday) return null

    const isBD = birthday ? isBirthday(birthday) : false
    const isM = birthday ? isMinor(birthday) : false
    const modelAge = birthday ? getModelAge(birthday) : null

    return (
        <ModelAgeContainer $isBold={isBD}>
            {(!nofixedspace || isBD) &&
            <div className="icon-container">
                {isBD && <NwIcon icon={light("birthday-cake")} />}
            </div>
            }
            <p>
                {isM
                    ?
                    <NwTag type="danger">{modelAge}</NwTag>
                    :
                    <>{modelAge}</>
                }
            </p>
        </ModelAgeContainer>
    )
}


export default ModelDetailsAge;