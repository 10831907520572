import React, { useState, useEffect } from 'react';
import { Popover, Input, notification } from 'antd';
import styled from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import StyleVariables from 'Components/Gui/StyleVariables';
import MiniLoading from "Components/Gui/MiniLoading";
import { useCancelMeeting } from 'Hooks/Event/UseMeeting';
import { useCancelTravel } from 'Hooks/Event/UseTravel';
import { useCancelFollowup } from 'Hooks/Event/UseFollowup';
import { useCancelExternalJob } from 'Hooks/Event/UseExternalJob';
import { useCancelEvent, useCancelEventDateModel } from 'Hooks/Event/UseEvent';
import { useCancelScoutingTravel } from 'Hooks/Event/UseScoutingTravel';
import { NwButton, NwRemoveLinkButton } from "Components/Gui/NwButton";
import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";
import NwDivider from 'Components/Gui/NwDivider';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { useDeleteEventDatesModels } from 'Hooks/Event/UseEvent';

const CancellationWrapper = styled.div`
    width: 240px;

    .ant-btn {
        border-radius: 6px !important;
        //border-right: 1px solid ${props => props.theme.LighterBorderColor} !important;
        &.ant-btn-primary:hover {
            color: #fff;
        }
        &.ant-btn-link.ant-btn-dangerous {
            &:hover {
                border: none;
                color: ${props => props.theme.DangerColor};
            }
        }
    }
`;



const CancelEvent = ({
    eventId,
    eventData,
    button,
    type,
    previewMode,
    // eventDateInfo,
    // scoutingTravel,
    cancelValue,
    modelID,
    eventDateModelID,
    onStatusChanged
}) => {
    const [isLoading, setLoading] = useState(false);
    const [cancel, setCancel] = useState(false);
    const { mutateAsync: onCancelMeeting } = useCancelMeeting();
    const { mutateAsync: onCancelTravel } = useCancelTravel();
    const { mutateAsync: onCancelFollowup } = useCancelFollowup();
    const { mutateAsync: onCancelExternalJob } = useCancelExternalJob();
    const { mutateAsync: onCancelEvent } = useCancelEvent();
    const { mutateAsync: onCancelEventDateModel } = useCancelEventDateModel();
    const { mutateAsync: onCancelScoutingTravel } = useCancelScoutingTravel();
    const [customerType, setCustomerType] = useState('Client');
    const [notes, setNotes] = useState('');
    const [visible, setVisible] = useState(false);
    const { mutateAsync: onDeleteEventDatesModels } = useDeleteEventDatesModels();

    useEffect(() => {
        setCancel(cancelValue)
    }, [cancelValue])

    const handleRemoveDate = async () => {
        try {
            await onDeleteEventDatesModels({ ID: eventData.ID, eventDateModelId: eventDateModelID, projectID: eventData.Project.ID });
            onStatusChanged();
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
            
            if (error.errorCode === 'DateWithTransactions') {
                notification.error({
                    duration: 0,
                    message: 'Error',
                    description: 'We’re sorry, but you cannot delete this job date because there are associated expenses or fees. Please review the linked expenses or fees before attempting to modify this entry.',
                    icon: <NwIcon icon={solid('exclamation-circle')} />
                });
                setVisible(false);
            }
        }
    };

    useEffect(() => {
        return function cleanup() { }
    })

    const onCancelEvents = async () => {
        let endpoint = "";

        //console.log("event type on cancel button", type)

        switch (type) {
            case 'ExtJob':
                await onCancelExternalJob({ ID: eventId });
                break;
            case 'ScoutingTravel':
                await onCancelScoutingTravel({ ID: eventId });
                break;
            case 'Travel':
                await onCancelTravel({ ID: eventId });
                break;
            case 'FollowUp':
                await onCancelFollowup({ ID: eventId });
                break;
            case 'Meeting':
                await onCancelMeeting({ ID: eventId });
                break;
            case 'Event':
                await onCancelEvent({ ID: eventId, cancel: !cancelValue });
                break;
            case 'Casting':
            case 'Job':
                await onCancelEventDateModel({
                    EventDateModelsIDs: [eventDateModelID],
                    Cancel: {
                        Cancel: !cancelValue,
                        CancelledBy: !cancelValue ? customerType : '',
                        CancellationNote: cancelValue ? '' : notes
                    },
                    eventId: eventId
                });
                setCustomerType('Client');
                setNotes('');
                break;
            default:
                return null;
        }
        return endpoint
    };

    const toggleCancel = async (e) => {
        if (!previewMode) {
            setLoading(true);
            e.stopPropagation();
            try {
                const data = await onCancelEvents();
                if (onStatusChanged) {
                    onStatusChanged()
                }
                setCancel(data);
                setLoading(false);
                setVisible(false);
            } catch (error) {
                console.log("error", error);
                setLoading(false);
            }
        }
    };

    const getStyleIcon = { fontSize: "1.5rem", opacity: cancel ? "1" : ".25" }
    //const getStyleButton = { backgroundColor: cancel ? StyleVariables.DangerColor : "inherit", color: cancel ? "#fff" : "inherit", borderColor: cancel ? StyleVariables.DangerColor : StyleVariables.DarkerBorder }
    const color = cancel ? StyleVariables.DangerColor : StyleVariables.GreyedColor

    return (
        button
            ?
            (type === 'Casting' || type === 'Job') ? (
                !cancelValue ?
                    <Popover
                        overlayClassName="nw-popover"
                        trigger={'click'}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        onClick={e => e.stopPropagation()}
                        onOpenChange={setVisible}
                        open={visible}
                        placement="left"
                        content={
                            <CancellationWrapper onClick={e => e.stopPropagation()}>
                                <div>Cancelled by</div>
                                <RadioButtonGroup
                                    value={customerType}
                                    onChange={setCustomerType}
                                    size='small'
                                    options={[
                                        { label: 'Client', value: 'Client' },
                                        { label: 'Agency', value: 'Agency' },
                                        { label: 'Model', value: 'Model' }
                                    ]} />
                                <div style={{marginTop: '1rem'}}>
                                    <small>NOTES ABOUT CANCELLATION</small>
                                    <Input autoComplete='off' value={notes} onChange={e => setNotes(e.target.value)} />
                                </div>
                                <div style={{marginTop: '1.5rem'}}>
                                    <NwButton primary size='small' onClick={toggleCancel} label='Save' />
                                </div>
                                {modelID && eventData &&
                                    <div style={{marginTop:'2rem'}}>
                                        <NwDivider>DELETE</NwDivider>
                                        <div>
                                            or
                                            &nbsp;&nbsp;&nbsp;
                                            <NwRemoveLinkButton onClick={handleRemoveDate} needConfirmation={false} label='Delete Permanently' />
                                        </div>
                                        <div>{`${getContactName(modelID)} for this date`}</div>
                                    </div>
                                }
                            </CancellationWrapper>
                        }>
                        <NwButton
                            loading={isLoading}
                            icon={solid('times')}
                            iconOpacity={cancel ? 1 : .25}
                            className={cancel ? 'event-cancelled-button' : ''}
                            //danger={cancel}
                        />
                    </Popover>
                    :
                    <NwButton
                        loading={isLoading}
                        icon={solid('times')}
                        onClick={toggleCancel}
                        iconOpacity={cancel ? 1 : .25}
                        className={cancel ? 'event-cancelled-button' : ''}
                        //style={getStyleButton}
                        //danger={cancel}
                    />
            )
                : 
                <NwButton
                    loading={isLoading}
                    icon={solid('times')}
                    onClick={toggleCancel}
                    iconOpacity={cancel ? 1 : .25}
                    className={cancel ? 'event-cancelled-button' : ''}
                    //style={getStyleButton}
                    //danger={cancel}
                />
            : (isLoading
                ?
                <MiniLoading />
                :
                <NwIcon onClick={toggleCancel} style={getStyleIcon} color={color} icon={solid('times')} />
            )
    );
};
export default CancelEvent;
