import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Select } from 'antd';

import useAreaAccentColor from 'Hooks/UseAreaAccentColor';
const {Option, OptGroup} = Select;

const RatingSelector = ({ value, onChange, simpleMode, fullWidth }) => {
    const ratingColor = useAreaAccentColor();

    const ratingCategories = [
        {
            label : "User Rating",
            value: 'currentUserRating'
        },
        {
            label : "Average Rating",
            value: 'averageRating'
        }
    ];

    return (
        <Select
            autoComplete='off'
            style={{width: (fullWidth ? '100%' : 200), minWidth: 170}}
            placeholder="Any Rating"
            onChange={value => {
                onChange(value === 'anyrating' ? undefined : value);
            }}
            value={value}
            // allowClear={true}
        >
            <Option value={'anyrating'}>
                <span>Any Rating</span>
            </Option>
            {simpleMode ? 
                <>
                    <Option value={1}>
                        <LegacyIcon type="star" theme="twoTone" twoToneColor={ratingColor.mainColor} />
                        <span style={{marginLeft: 5}}>or more</span>
                    </Option>
                    <Option value={2}>
                        {[1,2].map(el => (<LegacyIcon key={el} type="star" theme="twoTone" twoToneColor={ratingColor.mainColor} />))}
                        <span style={{marginLeft: 5}}>or more</span>
                    </Option>
                    <Option value={3}>
                        {[1,2,3].map(el => (<LegacyIcon key={el} type="star" theme="twoTone" twoToneColor={ratingColor.mainColor} />))}
                        <span style={{marginLeft: 5}}>or more</span>
                    </Option>
                    <Option value={4}>
                        {[1,2,3,4].map(el => (<LegacyIcon key={el} type="star" theme="twoTone" twoToneColor={ratingColor.mainColor} />))}
                        <span style={{marginLeft: 5}}>or more</span>
                    </Option>
                    <Option value={5}>
                        {[1,2,3,4,5].map(el => (<LegacyIcon key={el} type="star" theme="twoTone" twoToneColor={ratingColor.mainColor} />))}
                    </Option>
                </>
                : <>
                    {ratingCategories.map(option => (
                        <OptGroup label={option.label} key={option.label}>
                            <Option value={`${option.value}-1`}>
                                <LegacyIcon type="star" theme="twoTone" twoToneColor={ratingColor.mainColor} />
                                <span style={{marginLeft: 5}}>or more</span>
                            </Option>
                            <Option value={`${option.value}-2`}>
                                {[1,2].map(el => (<LegacyIcon key={el} type="star" theme="twoTone" twoToneColor={ratingColor.mainColor} />))}
                                <span style={{marginLeft: 5}}>or more</span>
                            </Option>
                            <Option value={`${option.value}-3`}>
                                {[1,2,3].map(el => (<LegacyIcon key={el} type="star" theme="twoTone" twoToneColor={ratingColor.mainColor} />))}
                                <span style={{marginLeft: 5}}>or more</span>
                            </Option>
                            <Option value={`${option.value}-4`}>
                                {[1,2,3,4].map(el => (<LegacyIcon key={el} type="star" theme="twoTone" twoToneColor={ratingColor.mainColor} />))}
                                <span style={{marginLeft: 5}}>or more</span>
                            </Option>
                            <Option value={`${option.value}-5`}>
                                {[1,2,3,4,5].map(el => (<LegacyIcon key={el} type="star" theme="twoTone" twoToneColor={ratingColor.mainColor} />))}
                            </Option>
                        </OptGroup>
                    ))}
                </>
            }
        </Select>
    );

};

export default RatingSelector;
