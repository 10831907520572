import React, { Children, useState } from 'react';
import { Dropdown, Menu, Tabs } from 'antd';
import { NwCard } from "Components/Gui/NwCard/NwCard";
import { NwAddButton } from 'Components/Gui/NwButton';
import ProjectGroupedTransactionsList from 'Components/Project/ProjectTransactions/Transactions/ProjectGroupedTransactionsList';
import AddProjectTransactionDrawer from 'Components/Project/ProjectTransactions/Transactions/AddProjectTransactionDrawer';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { StyledTabs } from './ProjectTransactionsUI';
import CustomSwitch from 'Components/Gui/CustomSwitch';
import ProjectGroupedStatements from './ProjectGroupedStatements';
import { GetTimestamp } from 'Libs/NwMoment';
import useUserDepartments from 'Hooks/UseUserDepartments';
import { useUserPermission } from 'Contexts/UserContext';

const { TabPane } = Tabs;

const ProjectTransactionsCard = ({ projectId, modelId, readOnly }) => {
    //const { data: currentProject } = useProject(projectId);
    const [transactionGroup, setTransactionGroup] = useState();
    const [transactionDrawer, setTransactionDrawer] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [tab, setTab] = useState('summary');
    const [lastUpdate, setLastUpdate] = useState(GetTimestamp());

    const handleShowTransactionDrawer = ({ key }) => {
        setTransactionGroup(key);
        setTransactionDrawer(true);
    };

    const ViewAgencyFee = useUserPermission("ViewAgencyFee")
    const ViewAgencyCommission = useUserPermission("ViewAgencyCommission")
    const ViewModelStatement = useUserPermission("ViewModelStatement")
    const ViewAgencyStatement = useUserPermission("ViewAgencyStatement")
    const ViewClientStatement = useUserPermission("ViewClientStatement")
    const ViewServiceStatement = useUserPermission("ViewServiceStatement")
    const ViewMotherAgencyCommission = useUserPermission('ViewMotherAgencyCommission');


    const canAccessTab = (type) => {
        switch (type) {
            case 'models':
                return ViewAgencyCommission && ViewModelStatement;
            case 'clients':
                return ViewAgencyFee && ViewClientStatement;
            case 'agencies':
                return ViewAgencyStatement && ViewMotherAgencyCommission;
            case 'services':
                return ViewServiceStatement;
            default:
                return false;
        }
    }

    const renderAddTransaction = () => {
        return [
            <Dropdown
                key='addTransaction'
                menu={{
                    onClick: handleShowTransactionDrawer,
                    items: [
                        { key: "Fee", label: "Fee" },
                        { key: "Expense", label: "Expense" },
                        { key: "Credit", label: "Credit" }
                    ]
                }}
            >
                <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <NwAddButton ghost size='small' label="add a transaction" />
                </div>
            </Dropdown>
        ];
    };

    const ExpandSwitch = ({ }) => (
        <CustomSwitch value={isExpanded} text="expand details" onChange={setIsExpanded} />
    )

    const handleCloseTransactionDrawer = () => {
        setTransactionDrawer(false)
        setTransactionGroup()
        setLastUpdate(GetTimestamp())
    }

    return (
        <NwCard
            title='Transactions'
            icon={light('message-dollar')}
            extra={!readOnly && renderAddTransaction()}>
            <StyledTabs
                activeKey={tab}
                onChange={setTab}
                tabBarExtraContent={<ExpandSwitch />}
                items={[
                    {
                        key: 'summary',
                        label: 'Summary',
                        children: <ProjectGroupedTransactionsList
                            projectId={projectId}
                            modelId={modelId}
                            readOnly={readOnly}
                            isExpanded={isExpanded}
                            lastUpdate={lastUpdate}
                        />
                    },
                    {
                        key: 'clients',
                        label: 'Clients',
                        disabled: !canAccessTab('clients'),
                        children: <ProjectGroupedStatements
                            readOnly
                            projectId={projectId}
                            statementType='Client'
                            isExpanded={isExpanded}
                        />
                    },
                    {
                        key: 'models',
                        label: 'Models',
                        disabled: !canAccessTab('models'),
                        children: <ProjectGroupedStatements
                            readOnly
                            projectId={projectId}
                            statementType='Model'
                            isExpanded={isExpanded}
                        />
                    },
                    {
                        key: 'services',
                        label: 'Services',
                        disabled: !canAccessTab('services'),
                        children: <ProjectGroupedStatements
                            readOnly
                            projectId={projectId}
                            statementType='Service'
                            isExpanded={isExpanded}
                        />
                    },
                    {
                        key: 'agencies',
                        label: 'Agencies',
                        disabled: !canAccessTab('agencies'),
                        children: <ProjectGroupedStatements
                            readOnly
                            projectId={projectId}
                            statementType='Agency'
                            isExpanded={isExpanded}
                        />
                    }
                ]}
            />
            {
                transactionDrawer &&
                <AddProjectTransactionDrawer
                    transactionGroup={transactionGroup}
                    projectId={projectId}
                    onClose={handleCloseTransactionDrawer}
                />
            }
        </NwCard >
    );
};

export default ProjectTransactionsCard;