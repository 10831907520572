import React, { useState } from "react";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from "Components/Gui/NwIcon";
import PermissionBlock from 'Components/PermissionBlock/PermissionBlock';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import BillingInfoDrawer from "Components/BillingInfos/BillingInfoDrawer";

const ContactBillingInfoContainer = styled.p`
    text-transform: uppercase;
    margin-bottom: 0;
`

const ContactHeaderBillingInfos = ({ currentContact, readOnly, type }) => {
    const [drawer, setDrawer] = useState(false);
    const breakpoints = useNwBreakPoints();

    const renderContactBillingInfosContainer = () => {
        return currentContact.BillingInfosCount ?
            <ContactBillingInfoContainer onClick={() => setDrawer(true)}>
                {breakpoints !== 1 &&
                    <>
                        <NwIcon icon={light("file-invoice-dollar")} />
                        &nbsp;&nbsp;
                    </>
                }
                {currentContact.BillingInfosCount} {currentContact.BillingInfosCount > 1 ? 'billing profiles' : 'billing profile'}
            </ContactBillingInfoContainer>
            :
            <ContactBillingInfoContainer onClick={() => setDrawer(true)}>
                <NwIcon icon={light("file-invoice-dollar")} danger />&nbsp;&nbsp;no billing profiles
            </ContactBillingInfoContainer>
    }

    return (
        <>
            {currentContact && 
                <div className="header-body-boxes header-body-boxes-hoverable">
                    <PermissionBlock
                        permission={true}
                        content={renderContactBillingInfosContainer()} />
                </div>
            }
            {drawer && <BillingInfoDrawer type={type} subtype={currentContact.SubType} contactId={currentContact.ID} readOnly={readOnly} onClose={() => setDrawer(false)} />}
        </>
    )
};

export default ContactHeaderBillingInfos;
