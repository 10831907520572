import React, { useState, useEffect } from 'react';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwAddButton } from 'Components/Gui/NwButton';
import TaskCard from 'Components/TaskList/TaskCard';
import NwIcon from 'Components/Gui/NwIcon';
import { useProject, forceReloadProject } from 'Hooks/Project/UseProject';
import { useStateValue } from 'Contexts/StateProvider';
import EventDrawer from 'Components/EventDrawer/EventDrawer';
import { Loading } from 'Components/Gui/Loading';
import EmptyState from 'Components/Gui/EmptyState';

const ProjectPageTaskList = ({ projectId, previewMode }) => {    //onReloadTodos

    const [addDrawer, setAddDrawer] = useState(false);
    const [{ areaContext }] = useStateValue();

    const { data: currentProject, isFetching } = useProject(projectId);

    const [projectTasks, setProjectTasks] = useState([])

    useEffect(() => {
        if (!isFetching && currentProject) {
            //const te = onGetOrderedEventList(currentProject, areaContext, false, false)
            //setProjectEvents(te)

            const tds = currentProject.ToDos.filter(todo => {
                if (todo.Visibility.Areas.length === 0 || todo.Visibility.Areas.find(area => area === areaContext.area)) {
                    return true;
                }
                return false;
            })
            setProjectTasks(tds)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProject, isFetching])

    const handleOpenAddTodoDialog = () => {
        setAddDrawer(true);
    };

    const handleCloseAddDrawer = () => {
        setAddDrawer(false);
    }

    const onReloadTodos = () => {
        forceReloadProject(projectId)
    }

    const filteredTasks = projectTasks.filter(todo => (todo.GlobalStatus !== "Completed"));

    return (
        isFetching ? <Loading /> :
            <div className="internal-card first-line">
                <div className="header">
                    <div className="title-container">
                        <NwIcon className="icon" icon={light("list-check")} />
                        <div className="title">Tasks</div>
                    </div>
                    {!previewMode && <NwAddButton ghost size="small" label='new task' onClick={handleOpenAddTodoDialog} />}
                </div>
                <div className="content">
                    <div className="content">
                        {(filteredTasks.length) > 0
                            ?
                            filteredTasks.map((todo, index) => {
                                return (
                                    <div className='todo-item' key={index}>
                                        <TaskCard
                                            todoItem={todo}
                                            showAll={true}
                                            todoType={'public'}
                                            projectId={projectId}
                                            onUpdate={onReloadTodos} />
                                    </div>
                                );
                            })
                            :
                            <EmptyState message="No tasks" />
                        }
                    </div>
                </div>
                {addDrawer &&
                    <EventDrawer
                        viewMode='create'
                        onClose={handleCloseAddDrawer}
                        afterUpdate={onReloadTodos}
                        eventType="todo"
                        projectId={projectId}
                    />
                }
            </div>
    );
};

export default ProjectPageTaskList;