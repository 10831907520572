import React from 'react'
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from 'Components/Gui/NwIcon';
import { isBirthday } from 'Libs/NwMoment';
import { useMemo } from 'react';
import AddEventDropdown from 'Components/Schedule/AddEventDropdown';

const CalendarDateHeader = ({
    month,
    date,
    birthdays,
    modellist,
    onBirthdayIconClick,
    onDateClick,
    onReload,
    onSendAgendaIconClick,
    modelID
}) => {
    
    const birthdaysForCurrentDates = useMemo(() => {
        if (birthdays && birthdays.length > 0) {
            if (modellist && modellist.length > 0) {
                return birthdays.filter((b) => {
                    if (isBirthday(b.BirthDate, date.date)) {
                        if (b.ContactType === "Model" && modellist && modellist.length > 0) {
                            const model = modellist.find((m) => m.ID === b.ID);
                            if (model) {
                                return true
                            } else {
                                return false
                            }
                        } else {
                            return true
                        }
                    }
                    return false
                })
            }
        }
        return []
    }, [birthdays, date.date, modellist]);

    return (
        <span className={month ? 'month-column-header' : 'week-column-header'} role="columnheader" aria-sort="none">
            <span className="calendar-header-icons-wrapper">
                {!date.isOffRange &&
                    <AddEventDropdown
                        date={date.date}
                        modelID={modelID}
                        onAddEvent={onReload}
                    />
                }
            </span>
            {onDateClick && !date.isOffRange
                ?
                <div className="calendar-header-date calendar-header-date-clickable" onClick={() => onDateClick(date.date)}>{date.label}</div>
                :
                <div className="calendar-header-date">{date.label}</div>
            }
            <span className="calendar-header-icons-wrapper">
                {(!modelID && birthdaysForCurrentDates.length > 0 && onBirthdayIconClick && !date.isOffRange) &&
                    <NwIcon
                        icon={solid('birthday-cake')}
                        onClick={() => onBirthdayIconClick(date.date, birthdaysForCurrentDates)}
                        pointer
                    />
                }
                {modelID && onSendAgendaIconClick && !date.isOffRange &&
                    <NwIcon
                        icon={light('share')}
                        onClick={() => onSendAgendaIconClick(date.date)}
                        pointer
                    />
                }
            </span>
        </span>
    )
};

export default CalendarDateHeader;
