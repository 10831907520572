import React, { useEffect, useMemo, useState } from "react";
import { MODELS_WALL_QUICKFILTER_DEFAULT } from 'Configs/WALLS_CONFIG';
import { useGlobalFeatures } from "Hooks/Features/UseFeatures";
import { ContactsSelectorProvider } from "../Common/SelectorContext";
import ModelsSelector from "./ModelsSelector";
import { Loading } from "Components/Gui/Loading";
import { useModelContext } from "Contexts/ModelContext";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import ls from 'local-storage';
import { useStateValue } from "Contexts/StateProvider";
import { checkTenant } from "Libs/NwUtils";

const ModelsSelectorWrapper = ({
    action, //action in URL: find similar models (?)
    addModelProps,
    currentEvent,
    currentPackage,
    defaultView, //view to start with
    disabledModels,
    disabledModelAvailability,
    fixedView, //view can't be changed
    isDrawer,
    layouttype, // left || fullpage
    itemClickAction, // preview-drawer || navigate
    modelToCompare, //find similar models
    multipleSelection,
    packageGroup,
    projectId,
    scope,
    startOnProjectModels,
}) => {

    const [{ areaContext }] = useStateValue();

    const breakpoints = useNwBreakPoints();
    const isMobile = breakpoints < 4;

    const modelContextValues = useModelContext();
    const { listType: defaultListType, activeSet: defaultActiveSet } = modelContextValues;

    const startListType = defaultListType || "all-models"
    const startActiveSet = defaultActiveSet || "all"

    //const { data: globalFeatures, isFetching } = useGlobalFeatures();

    const lastUsedView = ls.get(`nw_config_${areaContext.area}_modelswall_view`)
    
    const currentView = useMemo(() => {
        if (isMobile) {
            return 'table'
        }
        if (fixedView) {
            return defaultView ? defaultView : 'cards'
        }
        if (lastUsedView) {
            return lastUsedView
        }
        if (defaultView) {
            return defaultView
        }
        return 'cards'
    }, [defaultView, fixedView, isMobile, lastUsedView])
    
    let defaultQuickFilters = MODELS_WALL_QUICKFILTER_DEFAULT

    //const localStorageName = `nw_config_${areaContext.area}_${scope.replace("-", "")}`
    const localStorageNameNoScope = `nw_config_${areaContext.area}`
    const savedQuickFilters = ls.get(`${localStorageNameNoScope}_quickFilters`)
    if (savedQuickFilters) {
        defaultQuickFilters = savedQuickFilters
    }
    
    //const savedSort = ls.get(`${localStorageName}_sort`)
    //const savedSortFolder = ls.get(`${localStorageName}_sortFolder`)
    //if (savedSort) {
    //    defaultQuickFilters.sort = savedSort
    //}
    //if (savedSortFolder) {
    //    defaultQuickFilters.sortFolder = savedSortFolder
    //}

    const [quickFilters, setQuickFilters] = useState(defaultQuickFilters)

    const [activeSet, setActiveSet] = useState(startActiveSet || "all")
    const [listType, setListType] = useState(startListType || "all-models");  //all-models, smart-lists, project, folders
    const [selectorAction, setSelectorAction] = useState(action || null)
    const [selectorActionID, setSelectorActionID] = useState(modelToCompare || null)
    const [showOnlyModelsWithBooks, setShowOnlyModelsWithBooks] = useState(currentPackage ? true : false)
    const enableShowOnlyModelsWithBooks = currentPackage ? true : false
    const enableModelAvailability = !disabledModelAvailability
    const itemType = "model"

    //const [eventForSelectorContext, setEventForSelectorContext] = useState(currentEvent)

    // useEffect(() => {
    //     setEventForSelectorContext(currentEvent)
    // }, [currentEvent])

    const data_context = useMemo(() => ({
        addModelProps,
        area: areaContext.area,
        currentEvent,
        currentPackage,
        itemType,
        packageGroup,
        projectId,
        scope,
        selectorAction,
        selectorActionID,
    }), [
        addModelProps,
        areaContext.area,
        currentEvent,
        currentPackage,
        itemType,
        packageGroup,
        projectId,
        scope,
        selectorAction,
        selectorActionID,
    ]);

    const data_selection = useMemo(() => ({
        activeSet,
        listType,
        quickFilters,
    }), [
        activeSet,
        listType,
        quickFilters,
    ]);

    const data_actions = useMemo(() => ({
        itemClickAction,
        multipleSelection,
    }), [
        itemClickAction,
        multipleSelection,
    ]);

    const data_layout = useMemo(() => ({
        drawer: isDrawer,
        type: layouttype,
        leftMargin: null, //integer (px) for left margin
        view: {
            default: defaultView,
            current: currentView,
            fixed: fixedView ? true : false
        }
    }), [
        currentView,
        defaultView,
        fixedView,
        isDrawer,
        layouttype,
    ]);

    //const startOnProjectModelsValue = checkTenant('guysmgmt') ? false : startOnProjectModels

    const data_additional = useMemo(() => ({
        disabledItems: disabledModels ? disabledModels : [],
        enableModelAvailability,
        enableShowOnlyModelsWithBooks,
        showOnlyModelsWithBooks,
        startOnProjectModels
    }), [
        disabledModels,
        enableModelAvailability,
        enableShowOnlyModelsWithBooks,
        showOnlyModelsWithBooks,
        startOnProjectModels,
    ]);

    return (
        // (isFetching || !globalFeatures)
        //     ?
        //     <Loading />
        //     :
            <ContactsSelectorProvider
                dataContext={data_context}
                dataSelection={data_selection}
                dataActions={data_actions}
                dataLayout={data_layout}
                dataAdditional={data_additional}
                //featuresList={globalFeatures}
                startListType={startListType}
                startActiveSet={startActiveSet}
            >
                <ModelsSelector />
            </ContactsSelectorProvider>
    );
};

export default ModelsSelectorWrapper
