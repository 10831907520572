export const CUSTOMERS_FILTERS = {
    filters: [
        {
            name: 'CustomerType',
            label: 'Type',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            type: 'customers-type'
        },
        {
            name: 'WorkingOn',
            label: 'Working On',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: 'period',
            type: 'period'
        },
        {
            name: 'NotWorkingOn',
            label: 'NOT working On',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: 'period',
            type: 'period'
        },
        {
            name: 'Booker',
            label: 'Booker',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: 'booker',
            type: 'booker-selector'
        },
        {
            name: 'Tags',
            label: 'Tags',
            area: 'work',
            areaIndex: 1,
            defaultval: [],
            commontype: 'tag',
            type: 'customers-tag'
        },
        {
            name: 'AddedSince',
            label: 'Added Since',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: 'date',
            type: 'date'
        },
        {
            name: 'AddedUntil',
            label: 'Added Until',
            area: 'work',
            areaIndex: 1,
            defaultval: null,
            commontype: 'date',
            type: 'date'
        },
        {
            name: 'Countries',
            label: 'Nationality',
            area: 'demographic',
            areaIndex: 2,
            defaultval: [],
            commontype: 'array',
            type: 'countries-selector'
        },
        {
            name: 'Cities',
            label: 'City',
            area: 'demographic',
            areaIndex: 2,
            defaultval: [],
            commontype: 'array',
            type: 'cities-selector'
        },
    ]
};