import React, { useContext } from 'react';
import { Row, Col } from "antd";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { 
    NWTextAreaCharCounter,
    NWSelect,
    NWAmountInput
} from 'Components/Gui/NwForm/NwFormItems';
import { NwCancelButton, NwSaveButton } from 'Components/Gui/NwButton';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
// import { TRANSACTION_TYPES } from 'Hooks/Project/UseProject';
import { useUpdatePrevisionalTransaction, useAddPrevisionalTransaction } from 'Hooks/Project/UsePrevisionalTransactions';
import { ListContext } from 'Contexts/ListContext';
import useConfigurations from 'Hooks/UseConfigurations';

const AddEditEstimatedFee = ({ projectId, transaction, onCancel, onAfterUpdated, onTouchForm }) => {
    const { mutateAsync: updatePrevisionalTransaction } = useUpdatePrevisionalTransaction();
    const { mutateAsync: addPrevisionalTransaction } = useAddPrevisionalTransaction();
    const { cachedList } = useContext(ListContext);
    const configurations = useConfigurations();
    const transactionFamilies = cachedList.transactionFamilies.items;
    const rateTransaction = transactionFamilies.find(family => family.Code === 'JobFee');

    const initialValues = {
        Type: transaction ? transaction.TypeValue : null,
        TransactionLabelID: transaction ?  transaction.TransactionLabelID : '',
        Salary: transaction ? [transaction.Currency, transaction.Amount] : [configurations.defaultCurrency, 0],
        Description: transaction ? transaction.Description : ''
    }

    const saveTransaction = async (values, setSubmitting) => {
        let transactionData = {
            TransactionLabelID: values.TransactionLabelID,
            ProjectID: projectId,
            Amount: values.Salary[1],
            Currency: values.Salary[0],
            Description: values.Description,
            Family: 'JobFee'
        }
        setSubmitting(true);
        if (transaction) {
            transactionData = {
                ...transactionData,
                ID: transaction.ID
            };
            try {
                await updatePrevisionalTransaction({ transactionData });
                onTouchForm(false);
                onAfterUpdated();
                setSubmitting(false);
            } catch (error) {
                console.log('ant : Update Transaction error => ', error);
                setSubmitting(false);
            }
        } else {
            try {
                await addPrevisionalTransaction({ transactionData });
                onTouchForm(false);
                onAfterUpdated();
                setSubmitting(false);
            } catch (error) {
                console.log('ant : Create Transaction error => ', error);
                setSubmitting(false);
            }
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
                TransactionLabelID: Yup.string().required("Content is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                saveTransaction(values, setSubmitting);
            }}
            render={({ isSubmitting, values, handleSubmit }) => (
                <NwForm 
                    values={values} 
                    onTouchForm={onTouchForm} 
                    noValidate 
                    onFinish={handleSubmit} 
                    layout="vertical">
                    <Row gutter={16}>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Field
                                component={NWSelect}
                                label="Label"
                                name="TransactionLabelID"
                                value={values.TransactionLabelID}
                                options={rateTransaction.TransactionLabels.filter(label => !label.FixedTarif).map(transactionLabel => {
                                    return { key: transactionLabel.ID, label: transactionLabel.Label, value: transactionLabel.ID };
                                })}
                            />
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Field
                                component={NWAmountInput}
                                value={values.Salary}
                                label="Fee"
                                name="Salary"
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Field
                                component={NWTextAreaCharCounter}
                                label="Description"
                                name="Description"
                                type="text"
                                value={values.Description}
                                maxLength={2000}
                                autoSize={{ minRows: 2, maxRows: 10 }}
                            /> 
                        </Col>
                    </Row>
                    <NwFormButtonsBar
                        left={
                            <NwCancelButton
                                disabled={isSubmitting}
                                onClick={() => {
                                    onTouchForm(false);
                                    onCancel();
                                }}
                            />
                        }
                        right={
                            <NwSaveButton
                                htmlType="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                            />
                        }
                    />
                </NwForm>
            )}
        />
    )
};

export default AddEditEstimatedFee;