import React, { useState } from "react";
import { ObjectHistoryContainer } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import { EventCommentsDocuments } from 'Components/EventDrawer/EventDrawerUi/EventCommentsDocuments';
import { useDeleteFollowup, useDeleteFollowupGroup, forceReloadFollowup, useCloneModelsFollowup } from 'Hooks/Event/UseFollowup'
import EventDocuments from "Components/EventDocuments/EventDocuments";
import FollowUpDetails from 'Components/EventDrawer/FollowUpEvent/FollowUpDetails';
import ObjectHistory from "Components/CreatedBy/ObjectHistory";
import FollowUpActions from 'Components/EventDrawer/FollowUpEvent/FollowUpActions';
import FollowUpModelDetails from 'Components/EventDrawer/FollowUpEvent/FollowUpModelDetails';
import FlexContainer from 'Components/Gui/FlexContainer';
import { EventAddModelQuestion } from "Components/EventDrawer/EventDrawerUi/EventAddModelQuestion";
import { EventTitle } from "Components/EventDrawer/EventDrawerUi/EventTitle";
import AddModelsToNonBookingEvent from 'Components/EventDrawer/BookingEvent/AddModels/AddModelsToNonBookingEvent';
import { forceReloadProject } from 'Hooks/Project/UseProject';
import styled from "styled-components";
import Axios from "axios";

const Wrapper = styled.div`
    .followup-models-details-wrapper {
        padding-bottom: 3rem;
    }
`

const FollowUpContent = ({
    afterRemove,
    afterUpdate,
    filterModel,
    followUpData,
    followUpID,
    handleSelectProject,
    isEdit,
    onTouchForm,
    plainMode,
    projectId,
    setEditModelEvent,
    setIsEdit,
    setViewDetails,
    viewDetails,
}) => {

    const [showAddModelDrawer, setShowAddModelDrawer] = useState(false);
    const { mutateAsync: onDeleteFollowup } = useDeleteFollowup();
    const { mutateAsync: onDeleteFollowupGroup } = useDeleteFollowupGroup();
    const { mutateAsync: onCloneModelsFollowup } = useCloneModelsFollowup();

    const FollowUpRelatedEvents = followUpData.RelatedEvents ? [followUpData, ...followUpData.RelatedEvents] : [followUpData]

    const isIncludeFilterModel = () => {
        let modelEvents = [followUpData];
        if (followUpData.RelatedEvents && followUpData.RelatedEvents.length > 0) {
            modelEvents = [...modelEvents, ...followUpData.RelatedEvents];
        };

        if (!filterModel) {
            return true;
        } else {
            if (modelEvents.find(modelEvent => modelEvent.ModelID === Number(filterModel))) {
                return true;
            }
            return;
        }
    }

    const handleAddModelToEvent = async modelId => {
        const data = {
            EventID: followUpData.ID,
            ModelIDs: [modelId]
        };
        await onCloneModelsFollowup({ data });
        afterUpdate();
        if (followUpData.Project) {
            forceReloadProject(followUpData.Project.ID);
        }
    };

    const handleRemoveEvent = async eventId => {
        try {
            await onDeleteFollowup({ ID: eventId, followupId: followUpID });
        } catch (error) {
            console.log('ant : Remove Event Date Model Error => ', error);
        }
    };

    const handleRemoveEventGroup = async groupGuid => {
        try {
            await onDeleteFollowupGroup({ GroupGUID: groupGuid });
        } catch (error) {
            console.log('ant : Remove Event Group Error => ', error);
        }
    };

    const handleUnlinkEvent = async eventId => {
        try {
            await Axios.put(`/followups/UnLink/${followUpID}`)
            forceReloadFollowup(followUpID)
            if (followUpData.RelatedEvents && followUpData.RelatedEvents.length > 0) {
                followUpData.RelatedEvents.forEach(e => forceReloadFollowup(e.ID));
            };
        } catch (error) {
            console.log('ant : Unlink Event Error => ', error);
        }
    };


    const getEventCommentsDocumentsProps = (extra, showOnlyContent, showOnlyActions) => {
        return {
            event: { ...followUpData, elType: 'FollowUp', uniqID: `FollowUp-${followUpData.ID}` },
            api: 'FollowUpID',
            type: 'FollowUp',
            contactId: !followUpData.GroupGuid && followUpData.ModelID,
            drawerMode: !plainMode,
            fetchEvent: () => { forceReloadFollowup(followUpData.ID) },
            extra: extra,
            showOnlyContent: showOnlyContent,
            showOnlyActions: showOnlyActions,
            onTouchForm: onTouchForm
        }
    }

    const getEventTitleContentProps = () => {
        const singleEvent = (!followUpData.RelatedEvents || followUpData.RelatedEvents.length === 0);
        const props = {
            actions: {
                onEditGeneral: (() => {
                    setIsEdit(!isEdit)
                }),
                onEdit: (() => {
                    if (!singleEvent) {
                        setEditModelEvent(followUpData)
                    }
                    setIsEdit(!isEdit)
                }),
                onDelete: (async () => {
                    await handleRemoveEvent(followUpData.ID)
                    if (followUpData.ID === followUpID) {
                        afterRemove(true)
                    } else {
                        afterUpdate()
                    }
                }),
                onDeleteGroup: (async () => {
                    await handleRemoveEventGroup(followUpData.GroupGuid)
                    afterRemove(true);
                }),
                onUnlink: (() => handleUnlinkEvent(followUpData.ID)),
                onProjectUnlink: (() => handleSelectProject()),
                onAcknowledgmentUpdate: (() => {
                    forceReloadFollowup(followUpData.ID)
                    afterUpdate()
                }),
                onCancelUpdate: (() => {
                    forceReloadFollowup(followUpData.ID)
                    afterUpdate()
                }),
            },
            eventProperties: {
                eventType: "FollowUp",
                isSingle: singleEvent,
                hasExport: true
            },
            event: followUpData
        }
        return props;
    }


    return (
        <>
            {isIncludeFilterModel() || viewDetails ?
                <Wrapper>
                    <EventTitle {...getEventTitleContentProps()} />
                    <FollowUpDetails followUpData={followUpData} />
                    {followUpData.GroupGuid && followUpData.GroupDocuments.length > 0 &&
                        <FlexContainer pd='0 20px' column alignItems='flex-start'>
                            <EventDocuments showDocumentsByDefault event={followUpData} type={'eventGroup'} updateEvent={() => forceReloadFollowup(followUpData.ID)} />
                        </FlexContainer>
                    }
                    {!followUpData.GroupGuid && <EventCommentsDocuments {...getEventCommentsDocumentsProps(null, true)} />}
                    <ObjectHistoryContainer>
                        <ObjectHistory asLine object={followUpData} />
                    </ObjectHistoryContainer>
                    {!followUpData.GroupGuid ?
                        <EventCommentsDocuments {
                            ...getEventCommentsDocumentsProps(
                                <FollowUpActions
                                    plainMode={plainMode}
                                    followUpData={followUpData}
                                    onAddModels={() => setShowAddModelDrawer(true)}
                                />,
                                false,
                                true
                            )}
                        />
                        :
                        <FollowUpActions
                            plainMode={plainMode}
                            followUpData={followUpData}
                            onAddModels={() => setShowAddModelDrawer(true)}
                        />
                    }
                    <div className="followup-models-details-wrapper">
                        {(FollowUpRelatedEvents.length > 1)
                            ?
                            FollowUpRelatedEvents.map(event =>
                                <FollowUpModelDetails
                                    key={`event-${event.ID}`}
                                    plainMode={plainMode}
                                    event={event}
                                    afterUpdate={afterUpdate}
                                    afterRemove={afterRemove}
                                    onEditModelEvent={() => {
                                        setEditModelEvent(event);
                                        setIsEdit(!isEdit);
                                    }}
                                    onUpdateFollowUps={() => {
                                        forceReloadFollowup(followUpData.ID);
                                        FollowUpRelatedEvents.forEach(e => forceReloadFollowup(e.ID));
                                    }}
                                    openedEventID={followUpData.ID}
                                    onTouchForm={onTouchForm}
                                />
                            )
                            :
                            <></>
                        }
                    </div>
                </Wrapper>
                :
                <EventAddModelQuestion
                    eventData={followUpData}
                    model={filterModel}
                    onAddModel={(m) => handleAddModelToEvent(m)}
                    onShowEvent={() => setViewDetails(true)}
                />
            }
            {showAddModelDrawer &&
                <AddModelsToNonBookingEvent
                    event={followUpData}
                    projectId={projectId || (followUpData.Project && followUpData.Project.ID)}
                    onUpdateEvent={() => {
                        forceReloadFollowup(followUpData.ID)
                        afterUpdate()
                    }}
                    eventType='FollowUp'
                    onClose={() => setShowAddModelDrawer(false)} />
            }
        </>
    )


};

export default FollowUpContent;
