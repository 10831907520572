import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, Badge } from 'antd';

import AvatarBg from 'Components/Gui/AvatarBg';
import PackageDrawer from 'Components/PackageDrawer/PackageDrawer';
//import { GetBookCover } from 'Libs/NwUtils';
// import { ListContext } from 'Contexts/ListContext';
import CreatedBy from 'Components/CreatedBy/CreatedBy';
import ProjectLine from 'Components/ProjectLine/ProjectLine';
import _ from 'lodash';

const StyledCard = styled(Card)`
    .ant-card-body {
        padding: 0 !important;
        
        background-color: ${props => props.selected && props.theme.HoverablesBackground};
        box-shadow: ${props => props.selected && props.theme.SelectedShadow};
    }
    
    margin-bottom: ${props => props.$small ? '.5rem' : '1rem'};

    .container {
        border-radius: 4px;
        display: flex;
        
        .content {
            width: 100%;
            padding: ${props => props.$small ? '.5rem 1rem' : '1rem'};

            .package-models-list {
                margin: 8px 0 0;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: flex-start;

                .package-models-nameonly {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    justify-content: flex-start;
                    
                    span {
                        &:not(:last-child) {
                            &:after {
                                content: ', ';
                                margin-right: .5rem;
                            }
                        }
                    }
                }
            }
        }

        .pack-name {
            font-size: ${props => props.$small ? '1rem' : '1.25rem'};
            font-weight: bold;
        }

        .book-cover {
            margin-right: 16px;
            margin-bottom: 8px;
            position: relative;

            .ant-badge-count {
                background-color: ${props => props.theme.PrimaryColor};
                z-index: 5;
            }

            p {
                text-transform: capitalize;
                font-weight: 600;
                padding-top: .25rem;
                font-size: ${props => props.$small ? props.theme.FontSizeSm : props.theme.FontSizeBase};
            }
        }

        .action {
            border-left: ${props => props.theme.DefaultBorder};
        }
    }
`;

const PackageCard = ({
    isActive,
    isSelected,
    onCloseDrawer,
    pack,
    page,
    small,
}) => {
    // const { GetUserName } = useContext(ListContext);
    const [isPackageDrawer, setIsPackageDrawer] = useState(false);
    const [sortedModels, setSortedModels] = useState(false)


    // const handleMovePackage = () => {
    //     setIsPackageDrawer(true);
    //     // history(`/package/${pack.ID}`);
    // };

    useEffect(() => {
        if (pack && pack.Models) {
            const sm = _.sortBy(pack.Models, ['Order'])
            setSortedModels(sm)
        }
    }, [pack])

    const handleOpenPackageDrawer = () => {
        setIsPackageDrawer(true)
    };

    const handleClosePackageDrawer = () => {
        if (onCloseDrawer) {
            onCloseDrawer()
        }
        setIsPackageDrawer(false)
    }

    const isProjectPage = (page && page === 'project')

    return (
        <>
            <StyledCard selected={isSelected} $small={small} hoverable>
                <div className='container'>
                    <div className="content" onClick={handleOpenPackageDrawer}>
                        <div>
                            <div className='pack-name'>{pack.Name}</div>
                            {(pack.Project && !isProjectPage) && <div className="pack-projectline-container"><ProjectLine project={pack.Project} ellipsis /></div>}
                        </div>
                        <div className="package-models-list">
                            <p className="package-models-nameonly">
                                {sortedModels && sortedModels.map(model => (
                                    <span>{model.Name} {model.Surname}</span>
                                ))}
                            </p>
                            {/* {isActive
                            ?
                                <p className="package-models-nameonly">
                                    {sortedModels && sortedModels.map(model => ( 
                                        <span>{model.Name} {model.Surname}</span> 
                                    ))}
                                </p>
                            :
                                (sortedModels && sortedModels.map(model => {
                                    return (
                                        <div className='book-cover' key={`package-${pack.ID}-model-${model.ID}`}>
                                            {model.PlaylistsCount && model.PlaylistsCount > 0 &&
                                                <Badge count={model.PlaylistsCount}>
                                                    <AvatarBg
                                                        size={small ? 80 : 140}
                                                        src={model.FirstPlaylistCoverUrl}
                                                    />
                                                    <p>{model.Name.toLowerCase()} {model.Surname && model.Surname.toLowerCase()}</p>
                                                </Badge>
                                            }
                                        </div>
                                    )
                                }))
                            } */}
                        </div>
                        <div>
                            {`sent to: ${pack.Recipients.length > 0 ? (pack.Recipients.length > 5 ? `${pack.Recipients.length} recipients` : pack.Recipients.map(recipient => recipient.Email).join(', ')) : 'no recipient'}`}
                        </div>
                        <CreatedBy middleText=" • last sent on " author={pack.UserCreated} date={pack.LastSentDate} />
                    </div>
                </div>
            </StyledCard>
            {isPackageDrawer && <PackageDrawer
                packageId={pack.ID}
                onClose={handleClosePackageDrawer} />}
        </>
    );
};

export default PackageCard