import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Row, Col, Input, List } from "antd";
import Axios from 'axios';
import { Formik, Field } from "formik";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import * as Yup from "yup";

import { ListContext } from 'Contexts/ListContext';
import { NwCancelButton, NwSaveButton, NwButton, NwLinkButton } from 'Components/Gui/NwButton';
import FlexContainer from 'Components/Gui/FlexContainer';
import { NWSelect, NWSwitch, NWInput, NWTextAreaCharCounter } from 'Components/Gui/NwForm/NwFormItems';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { getValidText } from 'Libs/NwUtils';
import NwDivider from 'Components/Gui/NwDivider';
import NwIcon from 'Components/Gui/NwIcon';
import { getContactName } from 'Hooks/Contact/UseContactsList';

const CallSheetDetail = styled(FlexContainer)`
    line-height: 1.2;
    .connection-icon {
        margin-top: 4px;
        margin-left: 8px;
        margin-right: 16px;
        width: 32px;        
        display: flex;
        justify-content: center;
    }
    svg {
        margin-right: 4px;
        margin-bottom: 2px;
        font-size: .8rem;
    }
    .name {
        font-weight: bold;
        font-size: 1.2rem;
        color: ${props => props.invisible && props.theme.DangerColor};
    }
    .description {
        text-transform: uppercase;
        font-size: .8rem;
        margin-top: 4px;
    }
`;

const SearchBoxContainer = styled.div`
    .search-box-field {
        width: 450px;
    }
    .contacts-list {
        padding-top: 0.5rem;
        width: 100%;
        .ant-list-empty-text {
            padding: 0;
            
            .ant-empty {
                margin: 0;
            }
        }

        .ant-list-item {
            .contact-list-item {
                cursor: pointer;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                .contact-list-icon {
                    font-size: 2rem;
                    margin: 0 1rem;
                    line-height: 1em;
                    width: 50px;
                }
                .contact-list-details {
                    flex: 1 1 0;
                    padding-right: 1rem;
                    .contact-connection-name {
                        text-transform: uppercase;
                        font-size: .85rem;
                        line-height: 1em;
                    }
                    .contact-name {
                        font-weight: bold;
                    }
                }
            }
        }
    }
`;

const EditCallSheet = ({ callsheet, mode, onClose, onUpdateCallSheet, onTouchForm }) => {
    const { cachedList } = useContext(ListContext);
    const { callsheetTypes } = cachedList;    
    const [connection, setConnection] = useState();
    const [showContactSelect, setShowContactSelect] = useState(false);
    const [showAllContacts, setShowAllContacts] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState('');
    const [cache, setCache] = useState('');
    const [contacts, setContacts] = useState([]);
    const searchRef = useRef();

    const callsheetConnection = connection ? connection : callsheet.Connection;

    useEffect(() => {
        if (callsheet && callsheet.Connection) {
            getConnectionDetails(callsheet.Connection.ID);
        }
        return (() => {
        });
    }, [callsheet]);


    const getConnectionDetails = async (ID, setFieldValue) => {
        try {
            const connection = await Axios.get(`/connections/${ID}`);
            setConnection(connection);
            if (setFieldValue) {
                setFieldValue('Email', connection.Emails.length > 0 ? connection.Emails[0].ID : null);
                setFieldValue('Phone', connection.Phones.length > 0 ? connection.Phones[0].ID : null);
            }
        } catch (error) {
            console.log('ant : Get Simple Connections error => ', error);
        }
    };

    const saveCallSheet = async (values, setSubmitting) => {
        const data = {
            ID: callsheet.ID,
            ConnectionID: callsheetConnection ? callsheetConnection.ID : null,
            ...values
        };
        try {
            await Axios.put('/callsheets', data);
            await onUpdateCallSheet();
            setSubmitting(false);
            onClose();
        } catch (error) {
            console.log('nat : Update Callsheet Error => ', error);
            setSubmitting(false);
        }
    };

    const initialValues = {
        CallSheetTypeID: callsheet.CallSheetType.ID,
        Internal: callsheet.Internal?true:false,
        Name: callsheet.Name?callsheet.Name:'',
        Phone: callsheet.Phone?callsheet.Phone:'',
        Email: callsheet.Email?callsheet.Email:'',
        Note: callsheet.Note?callsheet.Note:'',
    };

    const getRoleText = (Role) => {
        const role = getValidText(Role);
        if (role) return `${role}@`;
        return role;
    }

    const getContacts = async searchTerm => {
        setLoading(true);
        try {
            const contacts = await Axios.get(`/contacts/SearchConnections/${searchTerm}`);
            setContacts(contacts);
            setLoading(false);
        } catch (error) {
            console.log('ant : Search Contact Error => ', error);
            setLoading(false);
        }
    }

    const handleSearch = (event) =>  {
        const inputValue = event.target.value;
        setFilter(inputValue);
        if (inputValue.length < 3) {
            setContacts([]);
            setCache(inputValue);
        } else {
            if (inputValue >= 3 && inputValue.includes(cache)) {
                const updatedcontacts = contacts.filter(contact => {
                    if (contact.ContactName.includes(inputValue) || contact.Name.includes(inputValue) || contact.Role.includes(inputValue)) {
                        return true;
                    }
                    return false;
                });
                setContacts(updatedcontacts);
                setCache(inputValue);
            } else {
                setCache(inputValue);
                getContacts(inputValue);
            }
        }
    };

    const handleClickConnection = async (simpleConnection, setFieldValue) => {
        setCache('');
        try {
            const connection = await Axios.get(`/connections/${simpleConnection.ID}`);
            setConnection(connection);
            if (connection) {
                const { Phones, Emails } = connection;
                setFieldValue("Name", getValidText(connection.Name));
                setFieldValue("Phone", (Phones && Phones.length) ? Phones[0].Number : "");
                setFieldValue("Email", (Emails && Emails.length) ? Emails[0].Address : "");
            }
        } catch (error) {
            console.log('ant : Get Connection Error => ', error);
        } finally {
            setShowContactSelect(false);
        }
    };

    return (
        <>
            <FlexContainer 
                mg='0 0 32px'
                justifyContent='space-between' 
                alignItems='flex-start'>
                {(callsheetConnection) && <CallSheetDetail>
                    {callsheetConnection.TypeName === 'People' ? 
                        <div>
                            <div className='name'>
                                {`${getValidText(callsheetConnection.Name)} ${getValidText(callsheetConnection.Surname)}`}
                            </div>
                            <div className='description'>{getRoleText(callsheetConnection.Role)}{callsheet.Contact?getValidText(callsheet.Contact.Name):getContactName(callsheetConnection.ContactID)}</div>
                        </div>
                        : <div>
                            <div className='name'>
                                {getValidText(callsheet.Contact.Name)}
                            </div>
                            <div className='description'>{getValidText(callsheetConnection.Name)}</div>
                        </div>
                    }
                </CallSheetDetail>}
                <NwButton
                    icon={light('pen')}
                    ghost
                    label='change contact'
                    onClick={() => setShowContactSelect(true)}
                    primary
                />
            </FlexContainer>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    saveCallSheet(values, setSubmitting);
                }}
                validationSchema={Yup.object().shape({
                    Name: Yup.string().required("Name is required")
                })}>
                {({ isSubmitting, values, setFieldValue, handleSubmit, }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={onTouchForm}
                        onFinish={handleSubmit} 
                        layout="vertical">
                        {showContactSelect &&
                            <SearchBoxContainer>
                                <p>Type a <strong>contact name</strong> or a <strong>role</strong></p>
                                <FlexContainer mg={'0 0 16px'} ref={searchRef}>
                                    <Input
                                        className="search-box-field" 
                                        size="large"
                                        id='search-input'
                                        value={filter}
                                        autoComplete='off'
                                        placeholder="contact name / role" 
                                        onChange={handleSearch}
                                    />
                                </FlexContainer>
                                <div className="contacts-list">
                                    <NwDivider textSize="big" bold dark>
                                        Select an existing contact
                                    </NwDivider>
                                    <List
                                        loading={loading}
                                        itemLayout="horizontal"
                                        dataSource={showAllContacts ? contacts : [...contacts].splice(0, 4)}
                                        renderItem={(connection, index) => {
                                            return (
                                                <List.Item
                                                    className='ant-list-item-top'
                                                    key={index}
                                                    onClick={() => handleClickConnection(connection, setFieldValue)}
                                                >
                                                    <div className='contact-list-item'>
                                                        <div className='contact-list-icon'>
                                                            <NwIcon icon={connection.TypeName === 'Place' ? light('home-alt') : light('user')} />
                                                        </div>
                                                        {connection.TypeName === 'People' ? 
                                                            <div className="contact-list-details">
                                                                <div className='contact-connection-name'>{getRoleText(connection.Role).toUpperCase()}{getValidText(connection.ContactName).toUpperCase()}</div>
                                                                <div className='contact-name'>{`${getValidText(connection.Name)} ${getValidText(connection.Surname)}`}</div>
                                                            </div>
                                                            : <div className="contact-list-details">
                                                                <div className='contact-connection-name'>{getValidText(connection.ContactName).toUpperCase()}</div>
                                                                <div className='contact-name'>{getValidText(connection.Name)}</div>
                                                            </div>
                                                        }
                                                    </div>
                                                </List.Item>
                                            )
                                        }}
                                    />
                                    {!showAllContacts && (contacts.length > 4) &&
                                        <FlexContainer justifyContent='center'>
                                            <NwLinkButton onClick={() => setShowAllContacts(true)} label='Load More' />
                                        </FlexContainer>
                                    }
                                </div>
                                <br/>
                            </SearchBoxContainer>
                        }
                        <Row gutter={16}>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWSelect}
                                    label="Callsheet Type"
                                    name="CallSheetTypeID"
                                    placeholder='Select a callsheet type'
                                    value={values.CallSheetTypeID}                
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    options={
                                        callsheetTypes.items.map(callsheet => {
                                            return { key: callsheet.ID, label: callsheet.Name, value: callsheet.ID };
                                        })}
                                />
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Field
                                    component={NWSwitch}
                                    label="Hidden to models"
                                    name="Internal"
                                    defaultChecked={false}
                                    checked={values.Internal}
                                />
                            </Col>
                        </Row>
                        <Field
                            component={NWInput}
                            label="Name"
                            name="Name"
                            maxLength={100}
                            type="text"
                            value={values.Name}
                        />
                        <Field
                            component={NWInput}
                            label="Phone"
                            name="Phone"
                            maxLength={50}
                            type="text"
                            value={values.Phone}
                        />
                        <Field
                            component={NWInput}
                            label="E-Mail"
                            name="Email"
                            maxLength={100}
                            type="text"
                            value={values.Email}
                        />
                        <Field
                            component={NWTextAreaCharCounter}
                            label="Note"
                            name="Note"
                            type="text"
                            value={values.Note}
                            maxLength={300}
                            autoSize={{ minRows: 5, maxRows: 5}}
                        />
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={onClose}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </>
    );
};

export default EditCallSheet;