import React, { useState } from "react";
import { Formik, Field } from "formik";
// import * as Yup from "yup";
import NwDrawer from "Components/Gui/NwDrawer";
import { NWBankAccountsSelector } from 'Components/Gui/NwForm/NwFormItems';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import Axios from "axios";

const EditBankDetailsDrawer = (props) => {
    const { onClose, invoiceData } = props;
    const [touchForm, setTouchForm] = useState(false);

    const initialValues = {
        BankAccountID: invoiceData.BankAccountID ? invoiceData.BankAccountID : null,
    }

    const saveBankAccount = async (values, setSubmitting) => {
        setSubmitting(true);
        try {
            await Axios.put(`/invoices/bankaccount`, {
                InvoiceID: invoiceData.ID,
                BankAccountID: values.BankAccountID
            });
            onClose();
        } catch (e) {
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title="Change Bank Details"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    saveBankAccount(values, setSubmitting);
                }}
            >
                {({ isSubmitting, values, handleSubmit, }) => (
                    <NwForm
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit}
                    >
                        <Field
                            component={NWBankAccountsSelector}
                            label="Bank Account"
                            name="BankAccountID"
                            value={values.BankAccountID}
                        />
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    );
}

export default EditBankDetailsDrawer;