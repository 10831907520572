import React, { useEffect, useState } from "react";
import StyleVariables from "Components/Gui/StyleVariables";
import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import NwIcon from "Components/Gui/NwIcon";
import { useCloneModelsFollowup } from 'Hooks/Event/UseFollowup';
import { useCloneModelsTravel } from 'Hooks/Event/UseTravel';
import { useCloneModelsTask } from 'Hooks/Event/UseTask';
import { useCloneModelsAccommodation } from 'Hooks/Event/UseAccommodation';
import { NwAddButton } from "Components/Gui/NwButton";
import { useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/SelectorContext";
import Axios from "axios";


const ModelsTableColumnNonBookingEvent = ({
    model,
    availability
}) => {

    useEffect(() => {
        console.log("availability", availability)
    }, [availability])

    const { context } = useContactsSelector();
    const { currentEvent } = context;
    const cs_dispatch = useContactsSelectorDispatch();

    const { mutateAsync: onCloneModelsFollowup } = useCloneModelsFollowup();
    const { mutateAsync: onCloneModelsTravel } = useCloneModelsTravel();
    const { mutateAsync: onCloneModelsToDo } = useCloneModelsTask();
    const { mutateAsync: onCloneModelsAccommodation } = useCloneModelsAccommodation();
    const [addingModel, setAddingModel] = useState(false);

    const tn = currentEvent.TypeName
    const evid = currentEvent.ID
    
    const onAddModelsToEvent = async () => {
        setAddingModel(true);
        const data = {
            EventID: evid,
            ModelIDs: [model.ID]
        };
        try {
            switch (tn) {
                case 'FollowUp':
                    await onCloneModelsFollowup({ data });
                    break;
                case 'Travel':
                    await onCloneModelsTravel({ data });
                    break;
                case 'ToDo':
                    await onCloneModelsToDo({ data });
                    break;
                case 'Accommodation':
                    await onCloneModelsAccommodation({ data });
                    break;
            }
            const eventUpdated = await Axios.get(`/${tn}s/${evid}`)
            eventUpdated.TypeName = tn;
            cs_dispatch({ type: 'UpdateCurrentEvent', eventUpdated: eventUpdated });
            setAddingModel(false);
        } catch (error) {
            console.log('ant : Add Model to EventDates Error => ', error);
            setAddingModel(false);
        }
    };

    return (
        availability
            ?
            (!availability.inEvent
                ?
                <NwAddButton ghost primary label="add model" loading={addingModel} onClick={onAddModelsToEvent} />
                :
                <p><strong><NwIcon icon={solid("check")} color={StyleVariables.SuccessColor} />&nbsp;added</strong></p>
            ) :
            <p><NwIcon icon={light("spinner-third")} spin /></p>
    )
};

export default ModelsTableColumnNonBookingEvent;
